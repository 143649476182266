<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>顾客管理</el-breadcrumb-item>
            <el-breadcrumb-item>邮箱管理</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 查询表单 -->
        <el-form class="queryForm" :inline="true" :gutter="20" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.userName" placeholder="邮箱名称"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="querySearch">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="openAdd">新增邮箱</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表 -->
        <el-table border stripe :data="tableData" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="userName" label="邮箱名称" min-width="160"></el-table-column>
            <el-table-column prop="intervalTime" label="邮件间隔时间（秒）" width="120">
            </el-table-column>
            <el-table-column label="发送邮件模板" min-width="200">
                <template slot-scope="scope">
                    {{ getDistTemplate(scope.row.noticeList) }}
                </template>
            </el-table-column>
            <el-table-column label="启用状态" width="100">
                <template slot-scope="scope">
                    <el-switch active-text="启用" active-value="1" inactive-value="0" v-model="scope.row.isEnabled" @change="changeEnabled(scope.row, $event)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="editThis(scope.row)">编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-document" @click="openDistribution(scope.row)">分配邮件模板</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="deleteThis(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-dialog top="3%" :title="dialogAddForm.id ? '编辑邮箱' : '新增邮箱'" :visible.sync="dialogAdd" width="500px" :close-on-click-modal="false">
            <el-form :model="dialogAddForm" :rules="dialogRules" status-icon ref="dialogAddForm" label-position="top" size="medium">
                <el-form-item label="邮箱名称" prop="userName">
                    <el-input maxlength="50" show-word-limit placeholder="请输入邮箱名称" autocomplete="off" v-model="dialogAddForm.userName"></el-input>
                </el-form-item>
                <el-form-item label="密钥" prop="password">
                    <el-input show-password placeholder="请输入密钥" v-model="dialogAddForm.password"></el-input>
                </el-form-item>
                <el-form-item label="smtp服务器地址" prop="host">
                    <el-input placeholder="请输入smtp服务器地址" v-model="dialogAddForm.host"></el-input>
                </el-form-item>
                <el-form-item label="smtp服务器端口" prop="port">
                    <el-input-number v-model.number="dialogAddForm.port" :precision="0" :min="0" placeholder="请输入端口">
                    </el-input-number>
                </el-form-item>
                <el-form-item label="邮件间隔时间(秒)" prop="intervalTime">
                    <el-input-number v-model.number="dialogAddForm.intervalTime" :precision="2" :min="0" :max="60" style="width: 250px !important;" placeholder="支持填入两位小数，最长不超过60秒">
                    </el-input-number>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                <el-button @click="dialogAdd = false">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog top="3%" title="分配邮件模板" :visible.sync="dialogDist" width="500px" :close-on-click-modal="false">
            <el-form :model="dialogDistForm" :rules="dialogDistRules" status-icon ref="dialogDistForm" label-position="top" size="medium">
                <el-form-item label="选择邮件模板" prop="userName">
                    <el-tree :data="noticeIdsOptions" show-checkbox ref="tree" node-key="id" @check-change="changeTree" :default-expanded-keys="[1000, 1001, 1002]" :default-checked-keys="dialogDistForm.customerNoticeIds" :props="defaultProps">
                    </el-tree>
                    <!-- <el-select v-model="dialogDistForm.customerNoticeIds" multiple placeholder="请选择">
				  <el-option
					v-for="item in noticeIdsOptions"
					:key="item.value"
					:label="item.label"
					:value="item.value">
				  </el-option>
			  </el-select> -->
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="distLoading" @click="distData">确定</el-button>
                <el-button @click="dialogDist = false">取消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: 'MailConfig',
    data() {
        return {
            queryForm: {
                userName: null,
                pageNo: 1,
                pageSize: 10,
            },
            loading: false,
            tableData: [],
            total: 0,

            dialogAdd: false,
            addLoading: false,
            dialogAddForm: {
                userName: null,
                password: null,
                host: null,
                port: undefined,
                intervalTime: undefined,
            },
            dialogRules: {
                userName: [
                    {
                        required: true,
                        message: '请输入邮箱名称',
                        trigger: 'blur',
                    },
                ],
                password: [
                    { required: true, message: '请输入秘钥', trigger: 'blur' },
                ],
                host: [
                    {
                        required: true,
                        message: '请输入smtp服务器地址',
                        trigger: 'blur',
                    },
                ],
                port: [
                    { required: true, message: '请输入端口', trigger: 'blur' },
                ],
                intervalTime: [
                    {
                        required: true,
                        message: '请输入邮件间隔时间',
                        trigger: 'blur',
                    },
                ],
            },

            dialogDist: false,
            distLoading: false,
            dialogDistForm: {
                id: null,
                customerNoticeIds: [],
            },
            dialogDistRules: {},
            noticeIdsOptions: [],
            defaultProps: {
                children: 'list',
                label: 'noticeName',
            },
        };
    },
    mounted() {
        this.queryData();
        this.getDistOptions();
    },
    methods: {
        openAdd() {
            this.dialogAddForm = {
                userName: null,
                password: null,
                host: null,
                port: undefined,
                intervalTime: undefined,
            };
            this.addLoading = false;
            this.dialogAdd = true;
        },
        editThis(row) {
            this.dialogAddForm = {
                id: row.id,
                userName: row.userName,
                password: row.password,
                host: row.host,
                port: row.port,
                intervalTime: row.intervalTime,
            };
            this.addLoading = false;
            this.dialogAdd = true;
        },
        addData() {
            this.$refs['dialogAddForm'].validate((valid) => {
                if (valid) {
                    this.addLoading = true;
                    let url;
                    if (this.dialogAddForm.id) {
                        url = '/common/mail/edit';
                    } else {
                        url = '/common/mail/add';
                    }
                    this.$http
                        .post(url, this.dialogAddForm)
                        .then((response) => {
                            this.addLoading = false;
                            if (response.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message({
                                    message: this.dialogAddForm.id
                                        ? '编辑成功'
                                        : '新增成功',
                                    type: 'success',
                                    duration: 1000,
                                });
                                this.queryData();
                            }
                        })
                        .catch((err) => {
                            this.addLoading = false;
                        });
                }
            });
        },
        deleteThis(id) {
            this.$confirm('是否删除邮箱？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .delete('/common/mail/delete?id=' + id)
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message.success('删除成功');
                            this.queryData();
                        }
                    });
            });
        },
        changeTree(data, checked, indeterminate) {
            // console.log(data, checked, indeterminate)
        },
        openDistribution(row) {
            this.loading = true;
            let keys = [];
            for (let i = 0; i < row.noticeList.length; i++) {
                keys.push(row.noticeList[i].id);
            }
            (this.dialogDistForm = {
                id: row.id,
                customerNoticeIds: keys,
            }),
                this.$http
                    .get('/customer/notice/getListByType')
                    .then((r) => {
                        this.loading = false;
                        if (r.data.code === 200) {
                            this.noticeIdsOptions = r.data.data;
                            for (
                                let i = 0;
                                i < this.noticeIdsOptions.length;
                                i++
                            ) {
                                let item = this.noticeIdsOptions[i];
                                item.id = i + 1000;
                                switch (i) {
                                    case 0:
                                        item.noticeName = '订单通知';
                                        break;
                                    case 1:
                                        item.noticeName = '订单召回通知';
                                        break;
                                    case 2:
                                        item.noticeName = '顾客业务通知';
                                        break;
                                    case 3:
                                        item.noticeName = '礼品卡';
                                        break;
                                    default:
                                        break;
                                }
                            }

                            this.dialogDist = true;
                            this.distLoading = false;
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                    });
        },
        distData() {
            let keys = this.$refs.tree.getCheckedKeys();
            for (let i = 0; i < keys.length; i++) {
                if (keys[i] >= 1000) {
                    keys.splice(i, 1);
                }
            }
            // console.log(keys)
            this.dialogDistForm.customerNoticeIds = keys;
            this.distLoading = true;
            this.$http
                .post('/common/mail/editNotice', this.dialogDistForm)
                .then((response) => {
                    this.addLoading = false;
                    if (response.data.code === 200) {
                        this.dialogDist = false;
                        this.distLoading = false;
                        this.$message({
                            message: '分配成功',
                            type: 'success',
                            duration: 1000,
                        });
                        this.queryData();
                    }
                })
                .catch((err) => {
                    this.distLoading = false;
                });
        },
        changeEnabled(scope, value) {
            console.log(scope);
            this.$confirm(`确认要${value == 0 ? '取消启用' : '启用'}吗？`, {
                type: 'warning',
            })
                .then(() => {
                    this.$http
                        .post('/common/mail/setIsEnable', { id: scope.id })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '设置成功',
                                    duration: 1000,
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            scope.isEnabled = value == 0 ? '1' : '0';
                        })
                        .finally(() => {
                            this.queryData();
                        });
                })
                .catch(() => (scope.isEnabled = value == 0 ? '1' : '0'));
        },
        querySearch() {
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        queryData() {
            this.loading = true;

            this.$http
                .get('/common/mail/getPage', { params: this.queryForm })
                .then((r) => {
                    this.loading = false;

                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        for (let i = 0; i < this.tableData.length; i++) {
                            let item = this.tableData[i];
                            item.isEnabled = item.isEnabled.toString();
                        }
                        this.total = r.data.count;
                    }
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        getDistOptions() {
            this.$http
                .get('/customer/notice/getListByType')
                .then((r) => {
                    if (r.data.code === 200) {
                        this.noticeIdsOptions = r.data.data;
                        for (let i = 0; i < this.noticeIdsOptions.length; i++) {
                            let item = this.noticeIdsOptions[i];
                            item.id = i + 1000;
                            switch (i) {
                                case 0:
                                    item.noticeName = '订单通知';
                                    break;
                                case 1:
                                    item.noticeName = '订单召回通知';
                                    break;
                                case 2:
                                    item.noticeName = '顾客业务通知';
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        getDistTemplate(list) {
            let dom = [];
            if (list && list.length > 0) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    let item = list[i];
                    let first = true;
                    for (let k = 0; k < this.noticeIdsOptions.length; k++) {
                        let db = this.noticeIdsOptions[k];
                        if (item.noticeType == db.noticeType) {
                            // console.log(i, first)
                            // if(first){
                            // 	dom += `<p class="rowtemple">${db.noticeName} => `
                            // 	first = false
                            // }
                            dom.push(item.noticeName);
                        }
                    }
                }
                return dom.toString();
            }
        },
    },
};
</script>

<style scoped>
.rowtemple {
    margin: 3px 0;
}
</style>