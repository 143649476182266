<template>
    <div class="main-wrap" v-loading="loading">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>店铺管理</el-breadcrumb-item>
            <el-breadcrumb-item>订单小费</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="between-center">
            <h2><b>订单小费</b> <span class="word">在支付页面新增可供消费者在结账时给小费的选项</span></h2>
        </div>

        <h3>迄今为止，订单小费功能累计帮您赚取 {{ totalCount.allTipOrderNum }} 笔小费, 共计 ${{ totalCount.allTipPrice }}</h3>

        <br>

        <div class="mainbox">
            <div class="title">核心指标</div>

            <el-form :inline="true" :model="queryForm" class="demo-form-inline">
                <el-form-item>
                    <el-date-picker type="daterange" range-separator="至" :clearable="false" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="date" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :default-value="new Date()"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="querySearch">查询</el-button>
                    <el-button icon="el-icon-refresh-left" @click="resetCondition">重置</el-button>
                </el-form-item>
            </el-form>

            <div class="list">
                <div class="listbox box">
                    <h4 class="h4">
                        小费订单数
                        <el-tooltip class="item" effect="dark" content="单位时间内，一共收到多少笔小费" placement="top">
                            <i class="el-icon-warning"></i>
                        </el-tooltip>
                    </h4>
                    <p class="p">{{ totalCount.tipOrderNum }}</p>
                </div>

                <div class="listbox box">
                    <h4 class="h4">
                        小费总金额
                        <el-tooltip class="item" effect="dark" content="单位时间内，一共收到多少小费" placement="top">
                            <i class="el-icon-warning"></i>
                        </el-tooltip>
                    </h4>
                    <p class="p">$ {{ totalCount.tipPrice }}</p>
                </div>

                <div class="listbox box">
                    <h4 class="h4">
                        小费订单占比
                        <el-tooltip class="item" effect="dark" content="单位时间内，支付小费的订单数/所有已支付订单数" placement="top">
                            <i class="el-icon-warning"></i>
                        </el-tooltip>
                    </h4>
                    <p class="p">{{ (totalCount.rate * 100).toFixed(2) }} %</p>
                </div>
            </div>

            <div class="title">当前小费功能情况</div>

            <div class="middle">
                <div class="item left">
                    <div class="h4">当前状态</div>
                    <div class="center">
                        <el-tag type="success" v-if="dialogAddForm.isEnabled == 1">已开启</el-tag>
                        <el-tag type="info" v-else>已关闭</el-tag>
                    </div>
                </div>

                <div class="item mid">
                    <div class="project">
                        <div class="name">选项1</div>
                        <div class="val">{{ dialogAddForm.type == 1 ? '$' : '' }}{{ dialogAddForm.tipNum1 }}{{ dialogAddForm.type != 1 ? '%' : '' }}</div>
                    </div>
                    <div class="project">
                        <div class="name">选项2</div>
                        <div class="val">{{ dialogAddForm.type == 1 ? '$' : '' }}{{ dialogAddForm.tipNum2 }}{{ dialogAddForm.type != 1 ? '%' : '' }}</div>
                    </div>
                    <div class="project">
                        <div class="name">选项3</div>
                        <div class="val">{{ dialogAddForm.type == 1 ? '$' : '' }}{{ dialogAddForm.tipNum3 }}{{ dialogAddForm.type != 1 ? '%' : '' }}</div>
                    </div>
                </div>

                <div class="item right center">
                    <el-button type="primary" size="small" icon="el-icon-setting" @click="getTips(true)">配置小费</el-button>
                </div>
            </div>

        </div>

        <!-- 数据表 -->
        <el-table border stripe :data="tableData" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" max-height="600" @sort-change="changeSort($event, 'queryForm')">
            <el-table-column prop="orderNo" label="订单号" width="200px"></el-table-column>
            <el-table-column prop="tipPrice" label="小费金额" sortable="1">
                <template slot-scope="scope">
                    ${{ scope.row.tipPrice }}
                </template>
            </el-table-column>
            <el-table-column prop="tipType" label="小费选项" width="100px">
                <template slot-scope="scope">
                    {{ getTipType(scope.row.tipType, scope.row.tipNum) }}
                </template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="订单金额" sortable="2">
                <template slot-scope="scope">
                    ${{ scope.row.totalPrice }}
                </template>
            </el-table-column>
            <el-table-column label="顾客姓名">
                <template slot-scope="scope">
                    {{ scope.row.shippingFirstName }} {{ scope.row.shippingLastName }}
                </template>
            </el-table-column>
            <el-table-column prop="financialTime" label="支付时间" sortable="3"></el-table-column>
        </el-table>
        <el-pagination background :current-page.sync="queryForm.pageNo" :page-sizes="[20, 50, 100]" :page-size.sync="queryForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-dialog top="3%" width="600px" title="小费配置" :visible.sync="dialogAdd" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
            <el-form :model="dialogAddForm" :rules="dialogRules" status-icon class="addform" ref="dialogAddForm">
                <el-form-item label="是否开启小费功能">
                    <el-switch active-value="1" inactive-value="0" v-model="dialogAddForm.isEnabled"></el-switch>
                    <p>客户可以在他们的在线购买中新增小费，以表示他们对您业务的支持</p>
                </el-form-item>
                <el-form-item label="小费金额类型">
                    <el-radio-group v-model="dialogAddForm.type">
                        <el-radio v-for="(item, index) in typeOptions" :key="index" :label="item.value">{{ item.name }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <div class="between itemrow">
                        <div class="item">
                            <p>选项1</p>
                            <el-input v-model="dialogAddForm.tipNum1" @input="changeNum($event, 'tipNum1')">
                                <template slot="append">
                                    {{ dialogAddForm.type == 1 ? '$' : '%' }}
                                </template>
                            </el-input>
                        </div>

                        <div class="item">
                            <p>选项2</p>
                            <el-input v-model="dialogAddForm.tipNum2" @input="changeNum($event, 'tipNum2')">
                                <template slot="append">
                                    {{ dialogAddForm.type == 1 ? '$' : '%' }}
                                </template>
                            </el-input>
                        </div>

                        <div class="item">
                            <p>选项3</p>
                            <el-input v-model="dialogAddForm.tipNum3" @input="changeNum($event, 'tipNum3')">
                                <template slot="append">
                                    {{ dialogAddForm.type == 1 ? '$' : '%' }}
                                </template>
                            </el-input>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="小费最大值($)" prop="tipNumMax">
                    <el-input-number size="small" :precision="2" :min="0.01" v-model="dialogAddForm.tipNumMax" placeholder="请输入最大值"></el-input-number>
                </el-form-item>
                <el-form-item label="感谢语">
                    <el-input v-model="dialogAddForm.tips" placeholder="名称"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                <el-button @click="closeTips">取消</el-button>
            </div>
        </el-dialog>

    </div>
</template>


<script>
export default {
    name: 'SetTips',
    data() {
        return {
            tableData: [],
            total: 0,
            loading: false,
            date: new Date(),
            queryForm: {
                startTime: null,
                endTime: null,
                pageNo: 1,
                pageSize: 20,
                sortField: null,
                sortType: null,
            },
            option: { prop: 'tipPrice', order: 'descending' },
            sort: null,
            sortOptions: [
                { name: 'tipPrice', value: 1 },
                { name: 'totalPrice', value: 2 },
                { name: 'financialTime', value: 3 },
            ],
            pickerOptions: {
                disabledDate: (time) => {
                    return time.getTime() > Date.now();
                },
            },
            dialogAdd: false,
            addLoading: false,
            dialogAddForm: {
                isEnabled: 1,
                tipNum1: null,
                tipNum2: null,
                tipNum3: null,
                tipNumMax: undefined,
                tips: 'Thank you, we appreciate it.',
                type: 0,
            },
            dialogRules: {
                codeName: [
                    {
                        required: true,
                        message: '请输入代码名称',
                        trigger: 'blur',
                    },
                ],
                code: [
                    { required: true, message: '请输入代码', trigger: 'blur' },
                ],
                tipNumMax: [
                    {
                        required: true,
                        message: '请输入最大值',
                        trigger: 'blur',
                    },
                ],
            },
            typeOptions: [
                { name: '商品总金额比例', value: 0 },
                { name: '固定值', value: 1 },
            ],
            totalCount: {
                allTipOrderNum: null,
                allTipPrice: null,
                rate: null,
                tipOrderNum: null,
                tipPrice: null,
            },
        };
    },
    mounted() {
        this.getToday();
        this.queryData();
        this.getTips();
    },
    methods: {
        getTotal() {
            let startTime = this.queryForm.startTime;
            let endTime = this.queryForm.endTime;
            this.$http
                .get(
                    '/setting/tip/getCount?startTime=' +
                        startTime +
                        '&endTime=' +
                        endTime
                )
                .then((response) => {
                    if (response.data.code == 200) {
                        this.totalCount = response.data.data;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        changeSort(column, form) {
            let name = column.prop;
            let order = column.order;
            for (let i = 0; i < this.sortOptions.length; i++) {
                let item = this.sortOptions[i];
                if (name == item.name) {
                    switch (order) {
                        case 'ascending':
                            this[form].sortField = item.value;
                            this[form].sortType = 1;
                            break;
                        case 'descending':
                            this[form].sortField = item.value;
                            this[form].sortType = 2;
                            break;
                        default:
                            this[form].sortField = null;
                            this[form].sortType = null;
                            break;
                    }
                }
            }
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        getToday() {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }
            let result = year + '-' + month + '-' + day;
            this.queryForm.startTime = result;
            this.queryForm.endTime = result;
            this.date = [result, result];
        },
        resetCondition() {
            this.getToday();
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        querySearch() {
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        queryData() {
            this.loading = true;
            this.queryForm.startTime = this.date[0];
            this.queryForm.endTime = this.date[1];
            this.$http
                .get('/setting/tip/getPage', { params: this.queryForm })
                .then((response) => {
                    if (response.data.code == 200) {
                        this.tableData = response.data.data;
                        this.total = response.data.count;
                        this.getTotal();
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getTips(open) {
            this.loading = true;
            this.$http
                .get('/setting/tip/getTipConfig')
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data;
                        this.dialogAddForm = {
                            isEnabled: String(data.isEnabled),
                            tipNum1: data.tipNum1,
                            tipNum2: data.tipNum2,
                            tipNum3: data.tipNum3,
                            tipNumMax: data.tipNumMax,
                            tips: data.tips,
                            type: data.type,
                        };

                        if (open) {
                            this.addLoading = false;
                            this.dialogAdd = true;
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        closeTips() {
            this.dialogAdd = false;
            this.getTips();
        },
        addData() {
            this.$refs['dialogAddForm'].validate((valid) => {
                if (valid) {
                    this.$http
                        .post('/setting/tip/editTipConfig', this.dialogAddForm)
                        .then((response) => {
                            this.addLoading = false;
                            if (response.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message({
                                    message: '配置成功',
                                    type: 'success',
                                    duration: 1000,
                                });
                                this.getTips();
                            }
                        })
                        .catch((err) => {
                            this.addLoading = false;
                        });
                }
            });
        },
        changeNum(e, form) {
            if (
                parseFloat(e).toString() == 'NaN' ||
                Number(e).toString() == 'NaN'
            ) {
                this.$message.warning('请输入数字。');
                this.dialogAddForm[form] = '';
                return false;
            }

            if (e.indexOf('.') > -1) {
                this.$message.warning('仅限整数。');
                this.dialogAddForm[form] = '';
                return false;
            }
        },
        getTipType(type, num) {
            let str = '';
            switch (type) {
                case 0:
                    str = num + '%';
                    break;
                case 1:
                    str = '$' + num;
                    break;
                case 2:
                    str = '自定义';
                    break;
                default:
                    break;
            }
            return str;
        },
    },
};
</script>

<style scoped>
* {
    /* margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif; */
    /* color: #333; */
}
h2,
h3,
h4,
p {
    margin: 0;
    color: #333;
}
body /deep/ .el-main {
    background-color: #fff;
}
.main-wrap {
    height: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}
h2 {
    font-size: 14px;
    margin: 20px 0;
}
h2 b {
    font-size: 24px;
    display: inline-block;
    margin-right: 15px;
}
h2 .word {
    font-weight: 500;
}
.mainbox {
    /* display: flex;
		flex-wrap: wrap; */
}
.mainbox > .title {
    font-size: 20px;
    font-weight: 600;
    margin: 15px 0;
}
.list {
    display: flex;
    justify-content: space-between;
}
.list .listbox {
    margin-bottom: 20px;
    height: 100px;
    width: 30%;
    flex-basis: 30%;
    border: 1px solid #ddd;
    padding: 14px;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.h4 {
    font-size: 18px;
    margin-bottom: 18px;
    font-weight: 500;
    color: #333;
}
.list .listbox .p {
    font-size: 20px;
    font-weight: 600;
}
.middle {
    display: grid;
    grid-template-columns: 200px 1fr 200px;
    grid-gap: 20px;
    margin-bottom: 20px;
}
.middle .item {
    height: 100px;
    padding: 10px;
    box-sizing: border-box;
}
.middle .left {
    border-right: 1px solid #ddd;
}
.middle .left .h4 {
    text-align: center;
}
.middle .mid {
    display: flex;
    justify-content: space-around;
}
.middle .mid .project {
    padding: 10px;
    min-width: 100px;
}
.middle .mid .project .name,
.middle .mid .project .val {
    text-align: center;
}
.middle .mid .project .name {
    font-size: 16px;
    margin-bottom: 10px;
}
.middle .mid .project .val {
    font-size: 18px;
}
.middle .right {
    border-left: 1px solid #ddd;
}
.itemrow .item {
    flex-basis: 30%;
    width: 30%;
}
</style>	