export default {
  functional: true,
  render(createElement, { props }) {
    return props.menus.map((menu) => {
      if (menu.children && menu.children.length) {
        return createElement(
          "el-submenu",
          {
            props: {
              index: menu.id.toString(),
            },
          },
          [
            createElement(
              "template",
              {
                slot: "title",
              },
              [
                createElement("i", {
                  attrs: {
                    class: menu.menuIcon,
                  },
                }),
                createElement("span", menu.menuName),
              ]
            ),
            createElement("MenuTree", {
              props: {
                menus: menu.children,
              },
            }),
          ]
        );
      } else {
        return createElement(
          "el-menu-item",
          {
            props: {
              index: menu.menuUrl,
            },
          },
          [
            createElement("i", {
              attrs: {
                class: menu.menuIcon,
              },
            }),
            createElement("span", menu.menuName),
          ]
        );
      }
    });
  },
  props: {
    menus: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
