<template>
    <div class="main-wrap">

        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>顾客管理</el-breadcrumb-item>
            <el-breadcrumb-item>邮件数据看板</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 导航栏 end -->

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm">
            <el-form-item label="日期">
                <el-date-picker type="daterange" range-separator="-" style="width: 240px;" :picker-options="pickerOptions" :clearable="false" :editable="false" start-placeholder="开始时间" end-placeholder="结束时间" value-format="yyyy-MM-dd" :disabled="overviewLoading || tableLoading" v-model="queryForm.timeRange" @change="querySearch"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <span class="fs12" style="color: #909399;">邮件数据自2022年10月28日开始统计</span>
            </el-form-item>
        </el-form>
        <!-- 查询表单 end -->

        <!-- 邮件数据概览 -->
        <h2 class="mt_0 mb_2" style="color: #303133; font-weight: 400;">邮件数据概览</h2>
        <div class="dg jc_s p_3" style="grid-template-columns: repeat( 4, 1fr ); gap: 0 30px; border-radius: 4px; height: 100px; color: #303133; background-color: #F2F6FC;" v-loading="overviewLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading">
            <section class="p_3" style="background-color: #FFF;">
                <header class="fs14">
                    已发送次数
                    <el-tooltip placement="top" effect="light" class="ol_n" :open-delay="500">
                        <div class="fs14" style="line-height: 16px;" slot="content">站点累积邮件发送次数</div>
                        <i class="el-icon-info fs16"></i>
                    </el-tooltip>
                </header>
                <p class="mt_3 mb_0" style="font-size: 24px;">{{ overviewData.sendCountPrint }}</p>
            </section>
            <section class="p_3" style="background-color: #FFF;">
                <header class="fs14">
                    打开率
                    <el-tooltip placement="top" effect="light" class="ol_n" :open-delay="500">
                        <div class="fs14" style="line-height: 16px;" slot="content">被打开过的邮件 / 邮件发送次数</div>
                        <i class="el-icon-info fs16"></i>
                    </el-tooltip>
                </header>
                <p class="mt_3 mb_0" style="font-size: 24px;">{{ overviewData.openRatePrint }}</p>
            </section>
            <section class="p_3" style="background-color: #FFF;">
                <header class="fs14">
                    点击率
                    <el-tooltip placement="top" effect="light" class="ol_n" :open-delay="500">
                        <div class="fs14" style="line-height: 16px;" slot="content">有链接被点击的邮件 / 邮件发送次数</div>
                        <i class="el-icon-info fs16"></i>
                    </el-tooltip>
                </header>
                <p class="mt_3 mb_0" style="font-size: 24px;">{{ overviewData.clickRatePrint }}</p>
            </section>
            <section class="p_3" style="background-color: #FFF;">
                <header class="fs14">
                    收益
                    <el-tooltip placement="top" effect="light" class="ol_n" :open-delay="500">
                        <div class="fs14" style="line-height: 16px;" slot="content">通过邮件点击进入网站进而完成订单的总金额</div>
                        <i class="el-icon-info fs16"></i>
                    </el-tooltip>
                </header>
                <p class="mt_3 mb_0" style="font-size: 24px;">{{ overviewData.totalPricePrint }}</p>
            </section>
        </div>
        <!-- 邮件数据概览 end -->

        <!-- 数据列表 -->
        <h2 class="mt_4 mb_2" style="color: #303133; font-weight: 400;">各类邮件数据详情</h2>
        <el-tabs style="min-height: 200px;" v-model="noticeType" v-loading="tableLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading">

            <el-tab-pane label="订单通知" name="1">
                <div v-for="( group, groupItem ) of tableData.orderNotice" :key="groupItem">
                    <h3 style="color: #303133; font-weight: 400;">{{ group.noticeName }}</h3>
                    <el-table border stripe class="mb_5_i" :data="group.list">
                        <el-table-column label="模板名称" min-width="200" #default="{ row }">
                            {{ row.noticeTmplName }}
                            <el-tag type="primary" size="mini" v-if="row.isDefault">默认</el-tag>
                        </el-table-column>
                        <el-table-column label="已发送" prop="sendCountPrint" min-width="100"></el-table-column>
                        <el-table-column label="打开率" prop="openRatePrint" min-width="100"></el-table-column>
                        <el-table-column label="点击率" prop="clickRatePrint" min-width="100"></el-table-column>
                        <el-table-column label="收益" prop="totalPricePrint" min-width="100"></el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>

            <el-tab-pane label="订单召回通知" name="2">
                <div v-for="( group, groupItem ) of tableData.orderRecallNotice" :key="groupItem">
                    <h3 style="color: #303133; font-weight: 400;">{{ group.noticeName }}</h3>
                    <el-table border stripe class="mb_5_i" :data="group.list">
                        <el-table-column label="模板名称" min-width="200" #default="{ row }">
                            {{ row.noticeTmplName }}
                            <el-tag type="primary" size="mini" v-if="row.isDefault">默认</el-tag>
                        </el-table-column>
                        <el-table-column label="已发送" prop="sendCountPrint" min-width="100"></el-table-column>
                        <el-table-column label="打开率" prop="openRatePrint" min-width="100"></el-table-column>
                        <el-table-column label="点击率" prop="clickRatePrint" min-width="100"></el-table-column>
                        <el-table-column label="收益" prop="totalPricePrint" min-width="100"></el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>

            <el-tab-pane label="顾客业务通知" name="3">
                <div v-for="( group, groupItem ) of tableData.customerNotice" :key="groupItem">
                    <h3 style="color: #303133; font-weight: 400;">{{ group.noticeName }}</h3>
                    <el-table border stripe class="mb_5_i" :data="group.list">
                        <el-table-column label="模板名称" min-width="200" #default="{ row }">
                            {{ row.noticeTmplName }}
                            <el-tag type="primary" size="mini" v-if="row.isDefault">默认</el-tag>
                        </el-table-column>
                        <el-table-column label="已发送" prop="sendCountPrint" min-width="100"></el-table-column>
                        <el-table-column label="打开率" prop="openRatePrint" min-width="100"></el-table-column>
                        <el-table-column label="点击率" prop="clickRatePrint" min-width="100"></el-table-column>
                        <el-table-column label="收益" prop="totalPricePrint" min-width="100"></el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>

            <el-tab-pane label="礼品卡通知" name="4">
                <div v-for="( group, groupItem ) of tableData.giftCardNotice" :key="groupItem">
                    <h3 style="color: #303133; font-weight: 400;">{{ group.noticeName }}</h3>
                    <el-table border stripe class="mb_5_i" :data="group.list">
                        <el-table-column label="模板名称" min-width="200" #default="{ row }">
                            {{ row.noticeTmplName }}
                            <el-tag type="primary" size="mini" v-if="row.isDefault">默认</el-tag>
                        </el-table-column>
                        <el-table-column label="已发送" prop="sendCountPrint" min-width="100"></el-table-column>
                        <el-table-column label="打开率" prop="openRatePrint" min-width="100"></el-table-column>
                        <el-table-column label="点击率" prop="clickRatePrint" min-width="100"></el-table-column>
                        <el-table-column label="收益" prop="totalPricePrint" min-width="100"></el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>

        </el-tabs>
        <!-- 数据列表 end -->

    </div>
</template>

<script>
import * as moment from 'moment';

export default {
    data() {
        return {
            pickerOptions: {
                // 日期选项

                disabledDate: (time) =>
                    moment(time).isAfter(moment(), 'day') ||
                    moment(time).isBefore(moment('2022-10-28'), 'day'),
            },

            /* 查询表单 */
            queryForm: {
                timeRange: [
                    moment().format('YYYY-MM-DD'),
                    moment().format('YYYY-MM-DD'),
                ],
            },
            /* 查询表单 end */

            /* 邮件数据概览 */
            overviewLoading: false,
            overviewData: [],
            /* 邮件数据概览 end */

            /* 列表数据 */
            noticeType: '1',
            tableLoading: false,
            tableData: [],
            /* 列表数据 end */
        };
    },

    mounted() {
        this.queryOverviewData();
        this.queryData();
    },

    methods: {
        /* 查询表单 */
        querySearch() {
            this.queryOverviewData();
            this.queryData();
        },
        /* 查询表单 end */

        /* 邮件数据概览 */
        queryOverviewData() {
            this.overviewLoading = true;

            let { timeRange, ...requestData } = this.queryForm;

            requestData.startTime = timeRange?.[0];
            requestData.endTime = timeRange?.[1];

            this.$http
                .get('/customer/notice/log/getCount', { params: requestData })
                .then((r) => {
                    if (r.data.code === 200) {
                        let d = r.data.data;

                        d.sendCountPrint = this.$yrFormatNumber(
                            d.sendCount,
                            ',',
                            0
                        );
                        d.openRatePrint = d.openRate + '%';
                        d.clickRatePrint = d.clickRate + '%';
                        d.totalPricePrint =
                            '$' + this.$yrFormatNumber(d.totalPrice, ',', 2);
                        this.overviewData = d;
                    }
                })
                .finally(() => (this.overviewLoading = false));
        },
        /* 邮件数据概览 end */

        /* 数据列表 */
        queryData() {
            this.tableLoading = true;

            let { timeRange, ...requestData } = this.queryForm;

            requestData.startTime = timeRange?.[0];
            requestData.endTime = timeRange?.[1];

            this.$http
                .get('/customer/notice/log/getList?', { params: requestData })
                .then((r) => {
                    if (r.data.code === 200) {
                        let d = r.data.data;

                        [
                            'orderNotice',
                            'orderRecallNotice',
                            'customerNotice',
                            'giftCardNotice',
                        ].forEach((category) => {
                            d[category].forEach((group) => {
                                group.list.forEach((row) => {
                                    row.sendCountPrint = this.$yrFormatNumber(
                                        row.sendCount,
                                        ',',
                                        0
                                    );
                                    row.openRatePrint = row.openRate + '%';
                                    row.clickRatePrint = row.clickRate + '%';
                                    row.totalPricePrint =
                                        '$' +
                                        this.$yrFormatNumber(
                                            row.totalPrice,
                                            ',',
                                            2
                                        );
                                });
                            });
                        });

                        this.tableData = d;
                    }
                })
                .finally(() => (this.tableLoading = false));
        },
        /* 数据列表 end */
    },
};
</script>

<style scoped>
.main-wrap {
    height: auto;
}

.main-wrap /deep/ .el-tabs__header {
    margin-bottom: 0;
}
</style>