p<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>订单列表</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 查询表单 -->
        <div class="tabs-mod">
            <el-tabs v-model="orderStatus" @tab-click="changeTab">
                <el-tab-pane v-for="item in statusTypes" :key="item.value" :label="item.name" :name="item.value"></el-tab-pane>
                <el-tab-pane label="全部" name="0"></el-tab-pane>
            </el-tabs>
            <el-button type="primary" plain class="btn-position" size="mini" icon="el-icon-edit-outline" @click="bridgeEditTable">编辑表头</el-button>
        </div>

        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input class="order-list-query-keyword" placeholder="关键字" v-model="queryKeyword">
                    <el-select slot="prepend" placeholder="类型" clearable v-model="queryType">
                        <el-option v-for="(item,index) in queryTypes" :key="index" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-input>
            </el-form-item>

            <el-form-item style="width: 120px" v-if="orderStatus == 0">
                <el-select placeholder="支付状态" clearable v-model="queryForm.financialStatus">
                    <el-option v-for="item in financialStatusTypes" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item style="width: 120px">
                <el-select placeholder="支付方式" clearable v-model="queryForm.paymentMethod">
                    <el-option v-for="item in paymentMethodTypes" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item style="width: 120px">
                <el-select placeholder="渠道来源" clearable v-model="queryForm.channelSource">
                    <el-option v-for="item in channelSourceData" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item style="width: 120px">
                <el-select placeholder="DIY状态" clearable v-model="queryForm.isDiy">
                    <el-option value="1" label="是"></el-option>
                    <el-option value="0" label="否"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item style="width:150px;">
                <el-select placeholder="时间类型" v-model="timeType" @change="getTimeType">
                    <el-option v-for="(item,index) in timeTypes" :key="index" :label="item.name" :value="item.value"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item v-if="timeType == 'createTime'" style="margin-left: -10px;">
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="开始时间" style="width: 140px" v-model="queryForm.startTime">
                </el-date-picker>
                ～
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="结束时间" style="width: 140px" v-model="queryForm.endTime">
                </el-date-picker>
            </el-form-item>
            <el-form-item v-if="timeType == 'payTime'" style="margin-left: -10px;">
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="开始时间" style="width: 140px" v-model="queryForm.payStartTime">
                </el-date-picker>
                ～
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="结束时间" style="width: 140px" v-model="queryForm.payEndTime">
                </el-date-picker>
            </el-form-item>

            <!-- <el-form-item style="color: #606266">&nbsp;创建时间</el-form-item>
            <el-form-item style="color: #606266">&nbsp;成功下单时间</el-form-item> -->

            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-refresh-left" @click="resetCondition">重置</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-upload2" @click="outPut">导出</el-button>
            </el-form-item>

            <!-- <el-form-item>
                <el-button type="success" icon="el-icon-upload2" @click="resetTbaleLayou">chongzh</el-button>
            </el-form-item> -->
        </el-form>

        <!-- 数据表 -->
        <el-table ref="orderListTable" show-summary :summary-method="getTotal" border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" max-height="1000" :data="tableData" v-loading="loading">
            <el-table-column v-for="(item, index) in tableColumn" :key="index" :prop="item.field" :label="item.name" min-width="200">
                <template #default="{row}">
                    <template v-if="item.field == 'orderNo' ">
                        {{ row.orderNo }}
                        <i class="el-icon-document-copy bold_i cp" title="复制" style="color: #000" v-clipboard:copy="row.orderNo" v-clipboard:success="onCopySuccess" v-clipboard:error="onCopyError">
                        </i>
                    </template>
                    <template v-else-if=" item.field == 'goodsInfo' ">
                        <div class="infobox">
                            <div>{{row.orderItems[0].goodsTitle }} ... </div>
                            <el-popover placement="right" width="500" trigger="click">
                                <div class="infoitem">
                                    <div class="inforow" v-for="(item,index) in row.orderItems" :key="index">
                                        <div class="imgbox">
                                            <GoodsImageTemplate :images="item.goodsImage" :isOrderItems="true" :orderMainImage="item.goodsImages"></GoodsImageTemplate>
                                        </div>
                                        <div class="infos">
                                            <p class="tit">{{item.goodsTitle}}</p>
                                            <p class="p">style: {{ getGoodsOption(item.goodsOption) }}</p>
                                            <p class="p">sku: {{item.sku}}</p>
                                            <p class="p">spu: {{item.spu}}</p>
                                            <p class="p">状态: {{ getFulfillmentStatus(item.fulfillmentStatus) }}</p>
                                            <p class="p">DIY状态：{{ item.isDiy ? '是' : '否' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <el-button slot="reference" type="primary" plain size="mini">查看</el-button>
                            </el-popover>
                        </div>
                    </template>
                    <template v-else-if=" item.field == 'orderSource' ">
                        <el-tooltip :content="row.orderSource" placement="top">
                            <div class="hidelink">{{ row.orderSource }}</div>
                        </el-tooltip>
                    </template>
                    <template v-else-if=" item.field == 'shippingName' ">
                        {{ row.shippingFirstName }}{{ row.shippingLastName }}
                    </template>
                    <template v-else-if=" item.field == 'shippingCountry' ">
                        {{row.shippingCountryCn}}
                    </template>
                    <template v-else-if=" item.field == 'shippingProvince' ">
                        {{ row.shippingProvinceCn }}
                    </template>
                    <template v-else-if=" item.field == 'financialStatus' ">
                        <span>
                            {{
                                formatterName(financialStatusTypes, row.financialStatus)
                            }}
                        </span>
                    </template>
                    <template v-else-if=" item.field == 'channelSource' ">
                        {{
                            formatterName(channelSourceData,row.channelSource)
                         }}
                    </template>
                    <template v-else-if=" item.field == 'orderStatus' ">
                        {{
                            formatterName(orderStatusTypes, row.orderStatus)
                      }}
                    </template>
                    <template v-else-if=" item.field == 'paymentMethod' ">
                        {{
                             formatterName(paymentMethodTypes, row.paymentMethod)
                      }}
                    </template>
                    <template v-else-if=" item.field == 'fulfillmentStatus' ">
                        {{
                            formatterName(fulfillmentStatusTypes, row.fulfillmentStatus)
                        }}
                    </template>
                    <template v-else-if=" item.field == 'visitDevice' ">
                        {{
                            visitDeviceType[row.visitDevice]
                        }}
                    </template>
                    <template v-else-if="item.field == 'giftCardNo' ">
                        <!-- {{ Array.isArray( row.shopOrderGifts) }} -->
                        <div v-html="row.shopOrderGifts && row.shopOrderGifts.map((item)=>{ return item['giftCardNo']}).join('<br>')"></div>
                    </template>
                    <template v-else>
                        {{ row[item.field] }}
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="140" header-align="center" align="center">
                <template #default="{row, $index}">
                    <el-button type="text" size="mini" icon="el-icon-s-promotion" v-if="
                                    row.orderStatus != 2 &&
                                    (row.financialStatus == 2 ||
                                        row.financialStatus == 5 ||
                                        row.financialStatus == 6 ||
                                        row.financialStatus == 7 ||
                                        row.financialStatus == 8 ) &&
                                    (row.fulfillmentStatus == 1 ||
                                        row.fulfillmentStatus == 2)
                                    " @click="initDelivery(row.id)">
                        发货
                    </el-button>
                    <el-button type="text" size="mini" icon="el-icon-document" @click="getOrderDetail(row, true, $index)">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-drawer :visible.sync="visibleDraw" direction="rtl" :with-header="false" class="drawer-table-head" @opened="dialogSortableOpened" @closed="dialogSortableClosed">
            <div class="header">
                <h2>表头设置</h2>
                <p class="sub-h2">（请至少选择5个）</p>
                <a class="ico-close" @click="visibleDraw = false;">×</a>
            </div>
            <el-checkbox-group v-model="checkList" @change="handleCheckedHeadChange">
                <div class="table-header-list" id="SortableTable">
                    <div :class="configure.includes(item.field) ? 'item' : 'drag-item item' " v-for="(item, index) in copyTableColumn" :key="index">
                        <i class="el-icon-rank"></i>
                        <el-checkbox :label="item.field" :name="item.field" :disabled="configure.includes(item.field)">{{ item.name }}</el-checkbox>
                    </div>
                </div>
            </el-checkbox-group>

            <div class="drawer-footer">
                <!-- <el-checkbox label="全选"></el-checkbox> -->
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                <div class="mod">
                    <el-button type="info" size="mini" @click="resetSetting">重置</el-button>
                    <el-button type="primary" size="mini" @click="sortableAct">确定</el-button>
                </div>
            </div>
        </el-drawer>

        <!-- 新增运单 -->
        <el-dialog top="3%" title="新增运单" id="deliveryDialog" width="850px" :visible.sync="dialogDelivery" :close-on-click-modal="false" @closed="resetDeliveryDialog">
            <!-- 1: 选择商品 -->
            <section class="px_3" :class="{ pb_4: deliveryShowGoods }" style="background-color: #fff">
                <header class="df ai_c">
                    <h3>选择商品</h3>
                    <el-checkbox class="ml_3" :indeterminate="deliveryGoodsIndeterminate" v-model="deliveryGoodsCheckAll" @change="deliverySelectAll($event)">全选</el-checkbox>
                    <i class="ml_a cp el-icon-arrow-down" style="font-size: 22px" v-if="deliveryShowGoods" @click="deliveryShowGoods = false"></i>
                    <i class="ml_a cp el-icon-arrow-right" style="font-size: 22px" v-else @click="deliveryShowGoods = true"></i>
                </header>
                <!-- 物流商品列表 -->
                <table ref="deliveryGoodsList" class="deliveryGoodsList" v-show="deliveryShowGoods">
                    <tr v-for="row in deliveryGoods" :key="row.id">
                        <td style="width: 50px">
                            <el-checkbox :key="'cb_' + row.id" v-model="row.isChecked" @change="deliverySelect($event, row)"></el-checkbox>
                        </td>
                        <td style="width: 80px">
                             <GoodsImageTemplate :images="row.goodsImage" :isOrderItems="true" :orderMainImage="row.goodsImages"></GoodsImageTemplate>
                        </td>
                        <td>
                            <div class="tl" style="line-height: 24px">
                                {{ row.goodsTitle }}<br />
                                <span style="color: #909399">
                                    <span v-if="row.goodsOption">
                                        Style: {{ getGoodsOption(row.goodsOption) }}
                                        <br />
                                    </span>
                                    SKU: {{ row.sku }}
                                </span>
                            </div>
                        </td>
                        <td style="width: 100px;">
                            <div class="pr_3 tr">
                                {{ $yrFormatNumber(row.weight, ",", 2) }}{{ row.weighUnit }}/件
                            </div>
                        </td>
                        <td style="width: 80px;">
                            <div class="pr_3 tr">&times; {{ row.canSendNum }}</div>
                        </td>
                        <td style="width: 100px;">
                            <el-input-number placeholder="本次发货数" style="width: 76px !important;" :min="1" :max="row.canSendNum" :precision="0" v-model="row.num" />
                        </td>
                    </tr>
                </table>
            </section>

            <!-- 2: 收货地址 -->
            <section class="mt_3 px_3" :class="{ pb_4: deliveryShowAddress }" style="background-color: #fff">
                <header class="df ai_c">
                    <h3>收货信息</h3>
                    <i class="ml_3 el-icon-edit cp" style="font-size: 22px" v-if="deliveryShowAddress && hideShipAddressForm" @click="initShipAddress(true)"></i>
                    <i class="ml_a cp el-icon-arrow-down" style="font-size: 22px" v-if="deliveryShowAddress" @click="deliveryShowAddress = false"></i>
                    <i class="ml_a cp el-icon-arrow-right" style="font-size: 22px" v-else @click="deliveryShowAddress = true"></i>
                </header>
                <template v-if="deliveryShowAddress">
                    <p class="mt_0 pt_3" style="border-top: solid 1px #e8ebf3; line-height: 36px" v-if="hideShipAddressForm">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                姓：{{
              orderDetail.data ? orderDetail.data.shippingLastName : ""
            }}<br />
                                电话：+{{
              orderDetail.data ? orderDetail.data.shippingPhoneCode : ""
            }}
                                {{ orderDetail.data ? orderDetail.data.shippingPhone : ""
            }}<br />
                                国家/地区：{{
              orderDetail.data ? orderDetail.data.shippingCountryEn : ""
            }}<br />
                                城市：{{
              orderDetail.data ? orderDetail.data.shippingCity : ""
            }}
                            </el-col>
                            <el-col :span="12">
                                名：{{
              orderDetail.data ? orderDetail.data.shippingFirstName : ""
            }}<br />
                                邮箱：{{ orderDetail.data ? orderDetail.data.shippingEmail : ""
            }}<br />
                                省份：{{
              orderDetail.data ? orderDetail.data.shippingProvinceEn : ""
            }}<br />
                                邮编：{{ orderDetail.data ? orderDetail.data.shippingZip : "" }}
                            </el-col>
                        </el-row>
                        地址：{{ orderDetail.data ? orderDetail.data.shippingAddress : "" }}
                    </p>
                    <!-- 收货信息表单 -->
                    <el-form size="small" class="pt_3" style="border-top: solid 1px #e8ebf3" v-else :model="orderDetail.shipAddressForm">
                        <el-row :gutter="12">
                            <el-col :span="12">
                                <el-form-item prop="shippingLastName">
                                    <el-input placeholder="名" v-model="orderDetail.shipAddressForm.shippingLastName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item prop="shippingFirstName">
                                    <el-input placeholder="姓" v-model="orderDetail.shipAddressForm.shippingFirstName"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="4">
                            <el-col :span="10">
                                <el-form-item prop="shippingPhoneCode">
                                    <el-select v-model="orderDetail.shipAddressForm.shippingPhoneCode" placeholder="区号">
                                        <el-option v-for="item in orderDetail.phoneCodeData" :key="item.id" :label="item.enNameFormat" :value="item.phoneCode"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <el-form-item prop="shippingPhone">
                                    <el-input maxlength="11" placeholder="手机号" v-model="orderDetail.shipAddressForm.shippingPhone"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item prop="shippingEmail">
                            <el-input maxlength="50" placeholder="邮箱" v-model="orderDetail.shipAddressForm.shippingEmail"></el-input>
                        </el-form-item>
                        <el-row :gutter="12">
                            <el-col :span="12">
                                <el-form-item prop="shippingCountryId">
                                    <el-select v-model="orderDetail.shipAddressForm.shippingCountryId" placeholder="国家" @change="changeShipCountry($event)">
                                        <el-option v-for="item in orderDetail.phoneCodeData" :key="item.id" :label="item.enName" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item prop="shippingProvinceId">
                                    <el-select v-model="orderDetail.shipAddressForm.shippingProvinceId" placeholder="省(州)" v-if="orderDetail.provinceData.length > 0">
                                        <el-option v-for="item in orderDetail.provinceData" :key="item.id" :label="item.enName" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item prop="shippingCity">
                            <el-input maxlength="50" placeholder="城市" v-model="orderDetail.shipAddressForm.shippingCity"></el-input>
                        </el-form-item>
                        <el-form-item prop="shippingAddress">
                            <el-input maxlength="50" placeholder="地址" v-model="orderDetail.shipAddressForm.shippingAddress"></el-input>
                        </el-form-item>
                        <el-form-item prop="shippingZip">
                            <el-input maxlength="10" placeholder="邮编" v-model="orderDetail.shipAddressForm.shippingZip"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" :loading="btnLoading == 'saveShipAddress'" @click="saveShipAddress">保存</el-button>
                            <el-button @click="hideShipAddressForm = true">取消</el-button>
                        </el-form-item>
                    </el-form>
                </template>
            </section>

            <!-- 3: 运单信息 -->
            <section class="mt_3 px_3 pb_3" style="background-color: #fff" v-show="!hasDisabledDelivery">
                <header class="df ai_c">
                    <h3>运单信息</h3>
                    <p class="ml_3" style="color: #909399">
                        该顾客选择的配送方式是：{{ orderDetail.data ? orderDetail.data.logisticsFreightPlan : "" }}
                    </p>
                </header>
                <!-- 运单信息表单 -->
                <el-form ref="deliveryForm" size="small" class="pt_3" style="border-top: solid 1px #e8ebf3" :model="deliveryForm" :rules="deliveryRules">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="运单号" prop="trackNum">
                                <el-input maxlength="50" v-model="deliveryForm.trackNum"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="物流公司" prop="trackCompany">
                                <el-input v-model="deliveryForm.trackCompany"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="物流公司编码" prop="trackCompanyCode">
                                <el-input v-model="deliveryForm.trackCompanyCode"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </section>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :disabled="!dialogDelivery" :loading="deliveryLoading" @click="saveDelivery">保存</el-button>
                <el-button @click="dialogDelivery = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 订单详情 -->
        <el-dialog fullscreen id="orderDetailDialog" title="订单详情" :visible.sync="dialogOrderDetail" :close-on-click-modal="false" @closed="resetInfo">
            <div class="df ai_fs">
                <!-- 左侧 -->
                <main style="flex: 1 1 auto">
                    <div class="order-control">
                        <el-link type="primary" :disabled="isDisabledPrev" @click="getOrderNavigation({type: 'prev'})">&lt; </el-link>
                        <el-link type="primary" :disabled="isDisabledNext" @click="getOrderNavigation({type: 'next'})"> &gt;</el-link>
                    </div>

                    <!-- 订单信息 -->
                    <section class="px_4 pt_3 pb_5" style="background-color: #fff">
                        <div class="tr" v-if="orderDetail.data && orderDetail.data.orderStatus == 1">
                            <el-button type="text" size="mini" class="py_0_i" :loading="btnLoading == 'cancelOrder'" @click="cancelOrder(orderDetail.data.id)">取消订单</el-button>
                        </div>
                        <div class="tr" v-if="orderDetail.data && orderDetail.data.orderStatus == 2">
                            <el-button type="text" size="mini" class="py_0_i" :loading="btnLoading == 'orderAfterSale'" @click="orderAfterSale(orderDetail.data.id)">申请售后</el-button>
                        </div>
                        <div class="df ai_c mb_2">
                            <h2 class="my_0 mr_3">
                                {{ orderDetail.data ? orderDetail.data.orderNo : "" }}
                            </h2>
                            <p class="my_0" style="color: #909399; font-size: 12px">
                                创建时间：{{ orderDetail.data ? orderDetail.data.createTime : "" }}
                                &nbsp;&nbsp;
                                <span v-if="orderDetail.data && orderDetail.data.financialTime">成功下单时间：{{ orderDetail.data.financialTime }}</span>
                            </p>
                        </div>
                        <div class="mb_2" v-if="orderDetail.data && orderDetail.data.transactionNo">
                            支付交易号: {{ orderDetail.data.transactionNo }}
                        </div>
                        <div v-if="orderDetail.data && orderDetail.data.refundNo">
                            交易退款号: {{ orderDetail.data.refundNo }}
                        </div>

                        <!-- 订单状态 -->
                        <div class="mb_2 py_2" v-if="orderDetail.data">
                            <!-- 订单状态 -->
                            <el-tag type="danger" size="mini">{{
            orderStatusTypes.find(
              (v) => v.value == orderDetail.data.orderStatus
            ).name
          }}</el-tag>
                            <!-- 支付状态 -->
                            <el-tag type="success" size="mini">{{
            financialStatusTypes.find(
              (v) => v.value == orderDetail.data.financialStatus
            ).name
          }}</el-tag>
                            <!-- 发货状态 -->
                            <el-tag type="primary" size="mini">{{
            fulfillmentStatusTypes.find(
              (v) => v.value == orderDetail.data.fulfillmentStatus
            ).name
          }}</el-tag>
                        </div>
                        <!-- 订单备注 -->
                        <div id="OrderDetailRemark" class="pb_3">
                            <template v-if="orderDetail.hideOrderRemarkForm">
                                <span style="font-size: 14px" v-if="orderDetail.data && orderDetail.data.orderRemark">{{ orderDetail.data.orderRemark }}</span>
                                <span style="color: #409eff; font-size: 12px" v-else>新增订单备注</span>
                                <i class="el-icon-edit ml_2 cp" style="color: #409eff; font-size: 20px" @click="initOrderRemark"></i>
                            </template>
                            <el-form class="df ai_fe" v-else :inline="true" :model="orderDetail.orderRemarkForm">
                                <el-form-item>
                                    <el-input type="textarea" style="width: 400px" :rows="5" v-model="orderDetail.orderRemarkForm.orderRemark"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" :loading="btnLoading == 'saveOrderRemark'" @click="saveOrderRemark">确定</el-button>
                                    <el-button @click="orderDetail.hideOrderRemarkForm = true">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </div>

                        <!-- 订单价格详情 -->
                        <h4 class="my_0 pt_4" style="border-top: solid 1px #d3d8e9">
                            订单价格详情
                        </h4>
                        <div class="df ai_c mb_2" style="border-bottom: solid 1px #d3d8e9">
                            <p style="line-height: 28px">
                                商品总价<br />
                                满减<br />
                                优惠码<br />
                                <template v-if="orderDetail.data && orderDetail.data.shopOrderGifts">
                                    <span v-for="(item, index) in orderDetail.data.shopOrderGifts" :key="index">
                                        礼品卡（{{ item.giftCardNo }}）<br>
                                    </span>
                                </template>
                                捆绑折扣<br />
                                物流费用<br>
                                运费险<br>
                                小费
                            </p>
                            <p class="tr" style="margin-left: 180px; line-height: 28px" v-if="orderDetail.data">
                                {{ $yrFormatNumber(orderDetail.data.goodsTotal, ",", 2) }} USD<br />
                                - {{ $yrFormatNumber(orderDetail.data.discountFullPrice, ",", 2) }} USD<br />
                                - {{ $yrFormatNumber(orderDetail.data.discountCodePrice, ",", 2) }} USD<br />
                                <template v-if="orderDetail.data && orderDetail.data.shopOrderGifts">
                                    <span v-for="(item, index) in orderDetail.data.shopOrderGifts" :key="index">
                                        - {{  $yrFormatNumber( item.subPrice, ",", 2) }} USD<br>
                                    </span>
                                </template>
                                - {{ $yrFormatNumber(orderDetail.data.discountBindPrice, ",", 2) }} USD<br />
                                {{ $yrFormatNumber(orderDetail.data.totalShipping, ",", 2) }} USD<br />
                                <!-- {{ $yrFormatNumber(orderDetail.data.totalTax, ",", 2) }} USD<br> -->
                                {{ $yrFormatNumber(orderDetail.data.insurePrice, ",", 2) }} USD<br>
                                {{ $yrFormatNumber(orderDetail.data.tipPrice, ",", 2) }} USD
                            </p>
                            <p style="margin-left: 100px; line-height: 28px" v-if="orderDetail.data">
                                <span class="bold">订单总金额：{{
                                        $yrFormatNumber(orderDetail.data.totalPrice, ",", 2)
                                    }}
                                    USD</span><br />
                                <span class="bold">实付总金额：{{
                                        $yrFormatNumber(orderDetail.data.cashTotal, ",", 2)
                                    }}
                                    USD</span><br />
                                <el-tag type="success" size="mini">{{
                                    financialStatusTypes.find(
                                        (v) => v.value == orderDetail.data.financialStatus
                                    ).name
                                    }}</el-tag>
                            </p>
                        </div>

                        <!-- 订单商品列表 -->
                        <el-table v-if="orderDetail.data" :show-header="false" :data="orderDetail.data.orderItems">
                            <el-table-column type="index" label="序号" width="60"></el-table-column>
                            <el-table-column label="商品图片" width="90">
                                <template slot-scope="scope">
                                     <GoodsImageTemplate :images="scope.row.goodsImage" :isOrderItems="true" :orderMainImage="scope.row.goodsImages"></GoodsImageTemplate>
                                    <el-tag type="primary" size="mini">{{
                fulfillment.fulfillmentGoodsStatusTypes.find(
                  (v) => v.value == scope.row.fulfillmentStatus
                ).name
              }}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="商品信息" min-width="120">
                                <template slot-scope="scope">
                                    <div class="tl" style="line-height: 24px">
                                        {{ scope.row.goodsTitle }}<br />
                                        <span style="color: #909399">
                                            <span v-if="scope.row.goodsOption">
                                                Style: {{ getGoodsOption(scope.row.goodsOption) }}
                                                <br />
                                            </span>
                                            SKU: {{ scope.row.sku }}<br />
                                            <!-- 供应商: {{ scope.row.vendor }} -->
                                        </span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="单价" width="200">
                                <template slot-scope="scope">
                                    <div class="tr">
                                        {{ $yrFormatNumber(scope.row.price, ",", 2) }} USD &times;
                                        {{ scope.row.quantity }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="总价" width="140">
                                <template slot-scope="scope">
                                    <div class="tr">
                                        {{ $yrFormatNumber(scope.row.total, ",", 2) }} USD
                                    </div>
                                    <div class="tr ft-through" v-if="scope.row.isCampaignReplace">
                                        {{ $yrFormatNumber(scope.row.compareAtPrice) }} USD
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </section>

                    <!-- 物流信息 -->
                    <header class="df ai_c py_2">
                        <h2 class="mr_3">物流状态</h2>
                        <el-tag type="primary" size="mini" class="mr_3" v-if="orderDetail.data">
                            {{
                                fulfillmentStatusTypes.find(
                                (v) => v.value == orderDetail.data.fulfillmentStatus
                                ).name
                            }}
                        </el-tag>
                        <el-button type="primary" v-if="
                                orderDetail.data &&
                                orderDetail.data.orderStatus != 2 &&
                                (orderDetail.data.financialStatus == 2 ||
                                orderDetail.data.financialStatus == 5 ||
                                orderDetail.data.financialStatus == 6 ||
                                orderDetail.data.financialStatus == 7 ||
                                orderDetail.data.financialStatus == 8 ) &&
                                (orderDetail.data.fulfillmentStatus == 1 ||
                                orderDetail.data.fulfillmentStatus == 2)
                            " @click="initDelivery(orderDetail.data.id)">
                            去发货
                        </el-button>
                    </header>
                    <template v-if="fulfillment.data.length > 0">
                        <section class="mb_3 px_4 pb_3" :style="{
            backgroundColor:
              item.fulfillmentStatus == 3 ? '#F7F8FA' : '#FFF',
          }" v-for="item in fulfillment.data" :key="item.id">
                            <header class="df ai_c" style="border-bottom: solid 1px #d3d8e9">
                                <div class="dg jc_s ai_c py_3" style="grid-template-columns: 100px auto; gap: 14px 0; width: 400px;">
                                    <span>发货时间:</span>
                                    <div>
                                        {{ item.createTime }}
                                        <el-tag type="primary" size="mini" class="ml_2">
                                            {{ fulfillment.fulfillmentStatusTypes.find( v => v.value == item.fulfillmentStatus ).name }}
                                        </el-tag>
                                    </div>
                                    <span>物流单号:</span>
                                    <span>{{ item.trackNum }}</span>
                                    <span>物流商:</span>
                                    <span>{{ item.trackCompany }}</span>
                                    <span>运输状态:</span>
                                    <div class="df ai_c">
                                        <span class="mr_4" v-if="item.trackingStatus">{{ trackingStatusData.find( v => v.value == item.trackingStatus ).name }}</span>
                                        <template v-if="$auth( 806 )">
                                            <i class="el-icon-loading mr_1" v-if="changeTrackingStatusLoading"></i>
                                            <el-select size="small" placeholder="更新运输状态" style="width: 150px;" :disabled="changeTrackingStatusLoading" v-model="newTrackingStatus" @change="changeTrackingStatus( $event, item )">
                                                <el-option v-for="statusItem of trackingStatusData" :key="statusItem.value" :label="statusItem.name" :value="statusItem.value" :disabled="statusItem.value == item.trackingStatus"></el-option>
                                            </el-select>
                                        </template>
                                    </div>
                                </div>
                                <div class="ml_a df ai_c">
                                    <el-button type="text" icon="el-icon-close" v-if="item.fulfillmentStatus == 2" :loading="btnLoading == 'cancelFulfillment_' + item.id" @click="cancelFulfillment(item)">
                                        取消本次发货
                                    </el-button>
                                    <el-button type="text" icon="el-icon-delete" v-if="item.fulfillmentStatus == 4" :loading="btnLoading == 'deleteFulfillment_' + item.id" @click="deleteFulfillment(item)">
                                        删除运单
                                    </el-button>
                                    <el-button type="primary" class="ml_5_i" v-if="item.fulfillmentStatus == 2" :loading="btnLoading == 'confirmDelivery' + item.id" @click="confirmDelivery(item)">
                                        确认收货
                                    </el-button>
                                </div>
                            </header>

                            <!-- 物流商品列表 -->
                            <el-table :show-header="false" :data="item.orderItems">
                                <el-table-column label="商品图片" width="90">
                                    <template slot-scope="scope">
                                        <GoodsImageTemplate :images="scope.row.goodsImage" :isOrderItems="true" :orderMainImage="scope.row.goodsImages"></GoodsImageTemplate>
                                    </template>
                                </el-table-column>
                                <el-table-column label="商品信息" min-width="120">
                                    <template slot-scope="scope">
                                        <div class="tl" style="line-height: 24px">
                                            {{ scope.row.goodsTitle }}<br />
                                            <span style="color: #909399">
                                                <span v-if="scope.row.goodsOption">
                                                    Style: {{ getGoodsOption(scope.row.goodsOption) }}
                                                    <br />
                                                </span>
                                                SKU: {{ scope.row.sku }}
                                            </span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="数量" width="100">
                                    <template slot-scope="scope">
                                        <div class="tr">&times; {{ scope.row.shippedQuantity }}</div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </section>
                    </template>
                    <!-- 无运单的提示信息 -->
                    <section style="background-color: #fff" v-else>
                        <div class="df jc_c ai_c" style="height: 150px">
                            <h3>暂无物流状态，请安排发货</h3>
                        </div>
                    </section>

                    <!-- 退货退款时间轴 -->
                    <header class="py_2" v-if="orderDetail.data && orderDetail.data.refundTimeLine">
                        <h2>退货退款时间轴</h2>
                    </header>
                    <section style="background-color: #fff" v-if="orderDetail.data && orderDetail.data.refundTimeLine">
                        <el-timeline class="pt_4">
                            <el-timeline-item :hide-timestamp="true" v-for="(item, index) in orderDetail.data.refundTimeLine" :key="index">
                                <span style="color: #c0c4cc">{{ item.time }}</span> &nbsp;<span style="color: #909399">{{ item.content }}</span>
                            </el-timeline-item>
                        </el-timeline>
                    </section>
                </main>

                <!-- 右侧 -->
                <aside class="ml_3 p_4" style="flex: 0 0 385px; background-color: #f7f8fa">
                    <h2 class="mt_0">购买人信息</h2>
                    <h3 class="mb_0">
                        {{
                        orderDetail.data ? orderDetail.data.customerInfo.firstName : ""
                        }}
                        {{ orderDetail.data ? orderDetail.data.customerInfo.lastName : "" }}
                        {{
                        orderDetail.data ? orderDetail.data.customerInfo.buyNum : ""
                        }}个订单
                    </h3>
                    <p class="mt_2">
                        {{ orderDetail.data ? orderDetail.data.customerInfo.email : "" }}
                    </p>
                    <div class="OrderDetail_AsideList df ai_c" v-if="orderDetail.data && orderDetail.data.paymentMethod">
                        <h3 class="pr_5">支付方式</h3>
                        <i class="ml_a mr_2 el-icon-postcard" style="font-size: 22px"></i>
                        <p>
                            {{
                                paymentMethodTypes.find(
                                (v) => v.value == orderDetail.data.paymentMethod
                                ).name
                            }}
                        </p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c" v-if="orderDetail.data && orderDetail.data.paymentChannel">
                        <h3 class="mr_a pr_5">支付渠道</h3>
                        <p>{{ orderDetail.data.paymentChannel }}</p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c">
                        <h3 class="mr_a pr_5">物流方案</h3>
                        <p>
                            {{
                                orderDetail.data ? orderDetail.data.logisticsFreightPlan : ""
                            }}
                        </p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c">
                        <h3 class="mr_a pr_5">访问IP</h3>
                        <p>{{ orderDetail.data ? orderDetail.data.visitIp : "" }}</p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c">
                        <h3 class="mr_a pr_5">订单来源</h3>
                        <p>{{ orderDetail.data ? orderDetail.data.orderSource : "" }}</p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c">
                        <h3 class="mr_a pr_5">渠道来源</h3>
                        <p>{{ orderDetail.data ? channelSourceData.find( v => v.value == orderDetail.data.channelSource ).name : "" }}</p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c">
                        <h3 class="mr_a pr_5">订单访问设备</h3>
                        <p>
                            {{
                                orderDetail.data
                                ? visitDeviceType[orderDetail.data.visitDevice]
                                : ""
                            }}
                        </p>
                    </div>
                    <div class="OrderDetail_AsideList">
                        <header class="df ai_c">
                            <h3>收货信息</h3>
                            <i class="ml_a el-icon-edit cp" style="font-size: 22px" v-if="orderDetail.hideShipAddressForm" @click="initShipAddress(false)">
                            </i>
                        </header>
                        <p class="mt_0" style="line-height: 36px" v-if="orderDetail.hideShipAddressForm">
                            姓：{{ orderDetail.data ? orderDetail.data.shippingLastName : ""
          }}<br />
                            名：{{ orderDetail.data ? orderDetail.data.shippingFirstName : ""
          }}<br />
                            电话：+{{
            orderDetail.data ? orderDetail.data.shippingPhoneCode : ""
          }}
                            {{ orderDetail.data ? orderDetail.data.shippingPhone : "" }}<br />
                            邮箱：{{ orderDetail.data ? orderDetail.data.shippingEmail : ""
          }}<br />
                            国家/地区：{{
            orderDetail.data ? orderDetail.data.shippingCountryEn : ""
          }}<br />
                            省份：{{
            orderDetail.data ? orderDetail.data.shippingProvinceEn : ""
          }}<br />
                            城市：{{ orderDetail.data ? orderDetail.data.shippingCity : ""
          }}<br />
                            地址：{{ orderDetail.data ? orderDetail.data.shippingAddress : ""
          }}<br />
                            邮编：{{ orderDetail.data ? orderDetail.data.shippingZip : "" }}
                        </p>
                        <!-- 收货信息表单 -->
                        <el-form size="small" v-else :model="orderDetail.shipAddressForm">
                            <el-row :gutter="12">
                                <el-col :span="12">
                                    <el-form-item prop="shippingLastName">
                                        <el-input placeholder="名" v-model="orderDetail.shipAddressForm.shippingLastName"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item prop="shippingFirstName">
                                        <el-input placeholder="姓" v-model="orderDetail.shipAddressForm.shippingFirstName"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="4">
                                <el-col :span="10">
                                    <el-form-item prop="shippingPhoneCode">
                                        <el-select v-model="orderDetail.shipAddressForm.shippingPhoneCode" placeholder="区号">
                                            <el-option v-for="item in orderDetail.phoneCodeData" :key="item.id" :label="item.enNameFormat" :value="item.phoneCode"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="14">
                                    <el-form-item prop="shippingPhone">
                                        <el-input maxlength="11" placeholder="手机号" v-model="orderDetail.shipAddressForm.shippingPhone"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item prop="shippingEmail">
                                <el-input maxlength="50" placeholder="邮箱" v-model="orderDetail.shipAddressForm.shippingEmail"></el-input>
                            </el-form-item>
                            <el-row :gutter="12">
                                <el-col :span="12">
                                    <el-form-item prop="shippingCountryId">
                                        <el-select v-model="orderDetail.shipAddressForm.shippingCountryId" placeholder="国家" @change="changeShipCountry($event)">
                                            <el-option v-for="item in orderDetail.phoneCodeData" :key="item.id" :label="item.enName" :value="item.id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item prop="shippingProvinceId">
                                        <el-select v-model="orderDetail.shipAddressForm.shippingProvinceId" placeholder="省(州)" v-if="orderDetail.provinceData.length > 0">
                                            <el-option v-for="item in orderDetail.provinceData" :key="item.id" :label="item.enName" :value="item.id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item prop="shippingCity">
                                <el-input maxlength="50" placeholder="城市" v-model="orderDetail.shipAddressForm.shippingCity"></el-input>
                            </el-form-item>
                            <el-form-item prop="shippingAddress">
                                <el-input maxlength="50" placeholder="地址" v-model="orderDetail.shipAddressForm.shippingAddress"></el-input>
                            </el-form-item>
                            <el-form-item prop="shippingZip">
                                <el-input maxlength="10" placeholder="邮编" v-model="orderDetail.shipAddressForm.shippingZip"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" :loading="btnLoading == 'saveShipAddress'" @click="saveShipAddress">保存</el-button>
                                <el-button @click="cancelEditAddress">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </aside>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Sortable from 'sortablejs';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';

export default {
    name: 'OrderList',
    components: {
        GoodsImageTemplate,
    },
    data() {
        return {
            dialogOrderItems: false,
            orderItems: {},
            channelSourceData: [
                // 订单详情 - 渠道来源数据
                { name: '其它来源', value: 0 },
                { name: '直接访问', value: 1 },
                { name: 'Google', value: 2 },
                { name: 'Facebook', value: 3 },
                { name: 'Instagram', value: 4 },
                { name: 'Bing', value: 5 },
                { name: 'Baidu', value: 6 },
                { name: 'Snapchat', value: 7 },
                { name: 'pinterest', value: 8 },
                { name: 'youtube', value: 9 },
				{ name: 'TikTok', value: 10 },
				{ name: 'Email', value: 11 },
            ],
            trackingStatusData: [
                // 运输状态数据

                { name: '到达出口中心', value: 1 },
                { name: '到达目的地机场', value: 2 },
                { name: '海关已清关', value: 3 },
                { name: '已送达', value: 4 },
            ],
            changeTrackingStatusLoading: false,
            newTrackingStatus: null, // 选中的运输状态值

            btnLoading: '',

            // 列表查询参数
            orderStatus: '4', // 订单状态
            statusTypes: [
                // 订单状态选项 new
                { name: '待发货', value: '4' },
                { name: '已发货', value: '5' },
                { name: '未完成', value: '1' },
                { name: '已完成', value: '2' },
                { name: '已取消', value: '3' },
            ],
            orderStatusTypes: [
                // 订单状态选项
                { name: '未完成', value: '1', id: '1' },
                { name: '已完成', value: '2', id: '2' },
                { name: '已取消', value: '3', id: '3' },
            ],

            queryType: null, // 搜索类型
            queryTypes: [
                // 搜索类型选项
                { name: '订单号', value: 'orderNo' },
                { name: '交易号', value: 'transactionNo' },
                { name: '退款号', value: 'refundNo' },
                { name: '商品标题', value: 'goodsTitle' },
                { name: 'SKU', value: 'sku' },
                { name: 'SPU', value: 'spu' },
                { name: '收件人姓名', value: 'shippingName' },
                { name: '邮箱', value: 'shippingEmail' },
                { name: '国家/地区', value: 'shippingCountryEn' },
                { name: '订单来源', value: 'orderSource' },
                { name: '优惠码', value: 'discountCode' },
                { name: '落地页ID', value: 'adPageId' },
                { name: '礼品卡卡号', value: 'giftCardNo' },
            ],
            queryKeyword: null, // 搜索关键字

            timeType: null,
            timeTypes: [
                { name: '创建时间', value: 'createTime' },
                { name: '成功下单时间', value: 'payTime' },
            ],

            financialStatusTypes: [
                // 支付状态选项

                { name: '待支付', value: '1' },
                { name: '已支付', value: '2' },
                { name: '已取消', value: '3' },
                { name: '支付失败', value: '4' },
                { name: '待退款', value: '5' },
                { name: '部分退款', value: '6' },
                { name: '已退款', value: '7' },
                { name: '退款失败', value: '8' },
            ],
            paymentMethodTypes: [
                // 支付方式选项c

                { name: '线上支付', value: '1' },
                { name: '信用卡', value: '2' },
            ],
            queryForm: {
                orderStatus: null,
                fulfillmentStatus: 1,
                financialStatus: 2,
                paymentMethod: null,
                startTime: null,
                endTime: null,
                isDiy: null,
                isItem: 1,
                pageNo: 1,
                pageSize: 10,
                channelSource: null,
                payStartTime: null,
                payEndTime: null,
            },

            // 列表数据
            loading: false,
            tableData: [],
            total: 0,
            fulfillmentStatusTypes: [
                // 订单发货状态选项

                { name: '待发货', value: '1' },
                { name: '部分发货', value: '2' },
                { name: '已发货', value: '3' },
                { name: '部分完成', value: '4' },
                { name: '已完成', value: '5' },
                { name: '已退货', value: '6' },
            ],

            // 发货
            hasDisabledDelivery: false, // 是否隐藏发货填写信息
            dialogDelivery: false,
            deliveryLoading: false,
            deliveryForm: {
                orderId: null,
                trackCompany: null,
                trackCompanyCode: null,
                trackNum: null,
            },
            deliveryRules: {
                trackNum: [
                    {
                        required: true,
                        message: '请输入运单号',
                        trigger: 'blur',
                    },
                ],
                trackCompany: [
                    {
                        required: true,
                        message: '请输入物流公司',
                        trigger: 'blur',
                    },
                ],
            },
            deliveryShowGoods: false,
            deliveryShowAddress: false,
            hideShipAddressForm: true,
            deliveryGoods: [],
            deliveryGoodsCheckAll: true,
            deliveryGoodsIndeterminate: false,

            // 订单详情
            dialogOrderDetail: false,
            visitDeviceType: {
                1: 'PC端',
                2: '移动端',
                3: '其它设备',
            },
            orderDetail: {
                data: null, // 订单详情数据

                // 订单备注
                hideOrderRemarkForm: true,
                orderRemarkForm: {
                    id: null,
                    orderRemark: null,
                },

                // 收货信息表单
                hideShipAddressForm: true,
                phoneCodeData: [],
                provinceData: [],
                shipAddressForm: {
                    id: null,
                    shippingAddress: null,
                    shippingCity: null,
                    shippingCountryId: null,
                    shippingEmail: null,
                    shippingFirstName: null,
                    shippingLastName: null,
                    shippingPhone: null,
                    shippingPhoneCode: null,
                    shippingProvinceId: null,
                    shippingZip: null,
                },
            },

            copy_shipAddressForm: {},

            // 物流详情
            fulfillment: {
                data: [], // 物流数据

                fulfillmentStatusTypes: [
                    // 物流发货状态选项

                    { name: '待发货', value: '1' },
                    { name: '已发货', value: '2' },
                    { name: '已完成', value: '3' },
                    { name: '已取消', value: '4' },
                ],

                fulfillmentGoodsStatusTypes: [
                    // 物流商品发货状态选项

                    { name: '待发货', value: '1' },
                    { name: '已发货', value: '2' },
                    { name: '已完成', value: '3' },
                    { name: '已退货', value: '4' },
                    { name: '部分发货', value: '5' },
                ],
            },

            isDisabledPrev: false,
            isDisabledNext: false,
            detailId: null,
            detailIndex: null,
            orderTotalPrice: null,

            // table heading
            tableColumn: [
                {
                    name: '商品信息',
                    field: 'goodsInfo',
                },
                {
                    name: '订单号',
                    field: 'orderNo',
                },
            ],
            copyTableColumn: [],
            visibleDraw: false,
            checkList: ['goodsInfo', 'orderNo'],
            configure: ['goodsInfo', 'orderNo'],
            firstCheckList: [
                {
                    name: '订单号',
                    field: 'orderNo',
                },
                {
                    name: '商品信息',
                    field: 'goodsInfo',
                },
                {
                    name: '创建时间',
                    field: 'createTime',
                },
                {
                    name: '支付时间',
                    field: 'financialTime',
                },
                {
                    name: '收货人姓名',
                    field: 'shippingName',
                },
                {
                    name: '支付状态',
                    field: 'financialStatus',
                },
                {
                    name: '订单总金额',
                    field: 'totalPrice',
                },
                {
                    name: '收货国家',
                    field: 'shippingCountry',
                },
                {
                    name: '订单来源',
                    field: 'orderSource',
                },
                {
                    name: '渠道来源',
                    field: 'channelSource',
                },
                {
                    name: '落地页id',
                    field: 'adPageId',
                },
                {
                    name: '优惠码',
                    field: 'discountCode',
                },
                {
                    name: '物流状态',
                    field: 'fulfillmentStatus',
                },
            ],

            // drag
            sortable_obj: null,
            isIndeterminate: true,
            checkAll: false,
        };
    },

    computed: {
        formatterName() {
            return function (_arr, val) {
                let stu = _arr.find((item) => {
                    return item.value == val;
                });
                return stu && stu.name;
            };
        },
    },

    mounted() {
        let query = this.$route.query;
        if (query.orderStatus) {
            if (query.orderStatus == 0) {
                this.queryForm.orderStatus = null;
                this.queryForm.fulfillmentStatus = null;
                this.queryForm.financialStatus = null;
            }
            this.orderStatus = query.orderStatus;
        }

        if (query.financialStatus) {
            this.queryForm.financialStatus = query.financialStatus;

            if (query.date?.length) {
                this.queryForm.startTime = query.date?.[0];
                this.queryForm.endTime = query.date?.[1];
                this.timeType = 'createTime';
            }

            this.queryData();
        }

        this.getTableHeaderAll();

        this.queryData();
        this.queryCountry();
    },

    methods: {
        resetTbaleLayou() {
            let _data = [...this.tableData];
            this.tableData = [];
            this.$nextTick(() => {
                this.getOrderTotal(() => {
                    this.tableData = _data;
                    this.$refs.orderListTable?.doLayout();
                    this.$forceUpdate();
                });
            });
        },

        handleCheckAllChange(val) {
            let allFields = this.copyTableColumn.map((item) => item.field);
            this.checkList = val ? allFields : [...this.configure];
            this.isIndeterminate = false;
        },
        handleCheckedHeadChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.copyTableColumn.length;
            this.isIndeterminate =
                checkedCount > 0 && checkedCount < this.copyTableColumn.length;
        },
        initTableHead() {
            let orderTableHeadActive = window.localStorage.getItem(
                    'orderTableHeadActive'
                ),
                tableColumn = [];

            if (orderTableHeadActive) {
                tableColumn = JSON.parse(orderTableHeadActive).map(
                    (fieldName) => {
                        let stu = this.copyTableColumn.find((item) => {
                            return item.field == fieldName;
                        });
                        return stu;
                    }
                );
            } else {
                tableColumn = this.firstCheckList;
            }

            this.tableColumn = tableColumn;
        },
        sortableAct() {
            let checkFields = [],
                checkTable = [];

            Array.from(this.sortable_obj.el.children).map((row) => {
                let $check = row.querySelector('.is-checked');
                if ($check) {
                    let name = $check.querySelector(
                            '.el-checkbox__label'
                        ).innerText,
                        field = $check.querySelector('input').value;

                    checkFields.push(field);
                    checkTable.push({
                        field,
                        name,
                    });
                }
            });

            if (checkFields.length < 5) {
                this.$message.warning('请至少选择5个');
                return;
            }

            let orderTableHead = Array.from(this.sortable_obj.el.children).map(
                (row) => {
                    let name = row.querySelector(
                            '.el-checkbox__label'
                        ).innerText,
                        field = row.querySelector('input').value;

                    return {
                        name,
                        field,
                    };
                }
            );

            this.tableColumn = checkTable;

            this.resetTbaleLayou();

            // this.queryData();

            window.localStorage.setItem(
                'orderTableHead',
                JSON.stringify(orderTableHead)
            );
            window.localStorage.setItem(
                'orderTableHeadActive',
                JSON.stringify(checkFields)
            );

            this.visibleDraw = false;

            // this.$nextTick(() => {
            //     this.$refs.orderListTable?.doLayout();
            // });
        },
        resetSortHead() {
            let orderTableHeadActive = window.localStorage.getItem(
                    'orderTableHeadActive'
                ),
                orderTableHead =
                    JSON.parse(window.localStorage.getItem('orderTableHead')) ||
                    [],
                checkList;

            if (orderTableHeadActive) {
                checkList = JSON.parse(orderTableHeadActive);
            } else {
                checkList = this.firstCheckList.map((item) => {
                    return item.field;
                });
            }

            this.copyTableColumn = orderTableHead;
            this.checkList = checkList;

            this.$forceUpdate();
        },

        resetSetting() {
            this.dialogSortableClosed();

            this.$nextTick(() => {
                this.dialogSortableOpened();
            });
        },

        dialogSortableOpened() {
            this.resetSortHead();

            const tbody = document.querySelector('#SortableTable');

            this.sortable_obj = Sortable.create(tbody, {
                ghostClass: 'sortable-ghost',
                handle: '.drag-item',
                animation: 200,
                onMove: function ({ dragged, related }) {
                    if (
                        ['goodsInfo', 'orderNo'].includes(
                            related.querySelector('input').value
                        )
                    ) {
                        return false;
                    }
                },
            });
        },
        dialogSortableClosed() {
            this.copyTableColumn = [];
            this.sortable_obj.destroy();
        },
        //

        bridgeEditTable() {
            this.visibleDraw = true;
        },
        getTableHeaderAll() {
            let orderTableHead = JSON.parse(
                window.localStorage.getItem('orderTableHead') || '[]'
            );

            let url = '/order/getTableHeader';
            this.$http.get(url).then((res) => {
                let { code, data } = res.data;
                if (code === 200) {
                    if (data.length) {
                        [data[0], data[1]] = [data[1], data[0]];
                    }

                    if (orderTableHead.length == data.length) {
                        this.tableColumn = orderTableHead;
                        this.copyTableColumn = [...orderTableHead];
                    } else {
                        this.tableColumn = data;
                        this.copyTableColumn = [...data];

                        window.localStorage.setItem(
                            'orderTableHead',
                            JSON.stringify(data)
                        );
                    }

                    this.initTableHead();
                }
            });
        },

        getTotal(param) {
            const sums = [];
            const { columns, data } = param;
            columns.forEach((column, index) => {
                let field = column.property;

                if (index === 0) {
                    sums[index] = '合计';
                    return;
                } else if (field == 'totalPrice') {
                    sums[index] = this.orderTotalPrice + 'USD';
                    return;
                } else {
                    sums[index] = '';
                }
            });

            return sums;
        },
        getOrderTotal(cb) {
            let url = '/order/getOrderTotal';
            let { pageNo, pageSize, channelSource, ...args } = this.queryForm,
                params = {
                    ...args,
                };

            if ((channelSource ?? '') !== '') {
                params['channelSource'] = channelSource;
            }
            params[this.queryType] = this.queryKeyword;
            this.$http
                .get(url, {
                    params,
                })
                .then((res) => {
                    let { code, data } = res.data;
                    if (code === 200) {
                        this.orderTotalPrice = data;
                        cb && cb();
                    }
                });
        },

        getTimeType(e) {
            if (e == 'createTime') {
                this.queryForm.payStartTime = null;
                this.queryForm.payEndTime = null;
            }
            if (e == 'payTime') {
                this.queryForm.startTime = null;
                this.queryForm.endTime = null;
            }
        },
        lookDetail(items) {
            this.orderItems = {};
            this.$nextTick(() => {
                this.orderItems = items;
            });
            this.dialogOrderItems = true;
        },
        /* 更新运输状态 */
        changeTrackingStatus(val, item) {
            this.changeTrackingStatusLoading = true;

            this.$http
                .post('/order/updateTrackingStatus', {
                    trackNum: item.trackNum,
                    trackingStatus: val,
                })
                .then((r) => {
                    if (r.data.code === 200) {
                        this.$message.success('运输状态更新成功');
                        item.trackingStatus = val;
                    }
                })
                .finally(() => {
                    this.changeTrackingStatusLoading = false;
                    this.newTrackingStatus = null;
                });
        },
        /* 更新运输状态 end */

        // 重置列表查询条件
        resetCondition() {
            this.queryType = null;
            this.queryKeyword = null;
            this.queryTypes.forEach((item) => {
                delete this.queryForm[item.value];
            });

            this.timeType = null;

            this.queryForm.orderStatus = null;
            this.queryForm.fulfillmentStatus = null;
            this.queryForm.financialStatus = null;
            this.queryForm.paymentMethod = null;
            this.queryForm.startTime = null;
            this.queryForm.endTime = null;
            this.queryForm.isDiy = null;
            this.queryForm.pageNo = 1;
            this.queryForm.payStartTime = null;
            this.queryForm.payEndTime = null;

            this.changeTab();
            this.queryData();
        },
        changeTab() {
            // console.log(this.orderStatus)
            switch (this.orderStatus) {
                case '0':
                    this.queryForm.orderStatus = null;
                    this.queryForm.fulfillmentStatus = null;
                    this.queryForm.financialStatus = null;
                    break;
                case '1':
                    this.queryForm.orderStatus = 1;
                    this.queryForm.fulfillmentStatus = null;
                    this.queryForm.financialStatus = null;
                    break;
                case '2':
                    this.queryForm.orderStatus = 2;
                    this.queryForm.fulfillmentStatus = null;
                    this.queryForm.financialStatus = null;
                    break;
                case '3':
                    this.queryForm.orderStatus = 3;
                    this.queryForm.fulfillmentStatus = null;
                    this.queryForm.financialStatus = null;
                    break;
                case '4':
                    this.queryForm.orderStatus = null;
                    this.queryForm.fulfillmentStatus = 1;
                    this.queryForm.financialStatus = '2';
                    break;
                case '5':
                    this.queryForm.orderStatus = null;
                    this.queryForm.fulfillmentStatus = 3;
                    this.queryForm.financialStatus = null;
                    break;
                default:
                    break;
            }
            this.queryData();
        },

        // 获取列表数据
        queryData() {
            // total price
            this.getOrderTotal();

            let { channelSource, ...args } = this.queryForm,
                params = {
                    ...args,
                };

            if ((channelSource ?? '') !== '') {
                params['channelSource'] = channelSource;
            }
            params[this.queryType] = this.queryKeyword;

            this.loading = true;

            this.$http
                .get(`/order/getPage`, {
                    params,
                })
                .then((r) => {
                    this.loading = false;

                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        this.total = r.data.count;
                        this.$refs.orderListTable?.doLayout();
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    // this.$message.error(error);;
                });
        },

        outPut() {
            this.$confirm('是否导出订单？', '导出订单', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            })
                .then(() => {
                    let params = {
                        ...this.queryForm,
                        includeColumnFieldNames: this.tableColumn.map(
                            (item) => {
                                return item.field;
                            }
                        ),
                    };
                    params[this.queryType] = this.queryKeyword;

                    this.loading = true;

                    this.$http
                        .post(`/order/export`, params)
                        .then((r) => {
                            this.loading = false;
                            if (r.data.code === 200) {
                                this.$confirm(
                                    '操作成功，是否跳转到任务中心？',
                                    '导出成功',
                                    {
                                        type: 'warning',
                                        confirmButtonText: '确定',
                                        cancelButtonText: '取消',
                                    }
                                )
                                    .then(() => {
                                        this.$router.push('/task/center');
                                    })
                                    .catch(() => {});
                            }
                        })
                        .catch((err) => {
                            this.loading = false;
                            // this.$message.error(error);;
                        });
                })
                .catch(() => {});
        },

        // urlEncode(param, key, encode) {
        //         if (param == null) return '';
        //         var paramStr = '';
        //         var t = typeof (param);
        //         if (t == 'string' || t == 'number' || t == 'boolean') {
        //           paramStr += '&' + key + '=' + ((encode == null || encode) ? param : param);
        //         } else {
        //           for (var i in param) {
        //             var k = key == null ? i : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
        //             paramStr += this.urlEncode(param[i], k, encode);
        //           }
        //         }
        //         return paramStr;
        //       },

        // 获取国家数据，用于区号
        queryCountry() {
            this.$http
                .get('/common/country/getList')
                .then((r) => {
                    if (r.data.code === 200) {
                        let data = r.data.data;

                        this.orderDetail.phoneCodeData = data.map((item) => {
                            let enNameFormat =
                                '+' + item.phoneCode + ' (' + item.enName + ')';
                            return {
                                id: item.id,
                                cnName: item.cnName,
                                enName: item.enName,
                                phoneCode: item.phoneCode,
                                enNameFormat: enNameFormat,
                            };
                        });
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                });
        },

        // 获取省份数据
        queryProvince(countryId) {
            this.$http
                .get('/common/province/getList', { params: { countryId } })
                .then((r) => {
                    if (r.data.code === 200) {
                        this.orderDetail.provinceData = r.data.data;
                        if (r.data.data.length > 0) {
                            let provinceId = r.data.data[0].id;

                            this.orderDetail.shipAddressForm.shippingProvinceId =
                                provinceId;
                            this.copy_shipAddressForm.shippingProvinceId =
                                provinceId;
                        }
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                });
        },

        // 复制成功
        onCopySuccess() {
            this.$message.success('复制成功');
        },
        // 复制失败
        onCopyError() {
            this.$message.error('复制失败');
        },

        // 检查是否隐藏运单信息， form requiresShipping
        hasDisabledState() {
            this.hasDisabledDelivery = this.deliveryGoods
                .filter((item) => item.isChecked)
                .every((item) => !+item.requiresShipping);
        },

        // 发货 - 全选
        deliverySelectAll(val) {
            this.deliveryGoods.map((v) => (v.isChecked = val));
            this.deliveryGoods = [...this.deliveryGoods];
            this.deliveryGoodsIndeterminate = false;

            this.hasDisabledState();
        },

        // 发货 - 选中商品
        deliverySelect(val, item) {
            item.isChecked = val;

            this.deliveryGoods = [...this.deliveryGoods];

            let a = this.deliveryGoods.length,
                b = this.deliveryGoods.filter((v) => v.isChecked).length;

            this.deliveryGoodsCheckAll = b > 0 && a == b;
            this.deliveryGoodsIndeterminate = b > 0 && b < a;

            this.hasDisabledState();
        },

        /* 发货 */
        async initDelivery(orderId) {
            this.deliveryShowGoods = false;
            this.deliveryShowAddress = false;
            this.hideShipAddressForm = true;
            this.deliveryGoodsCheckAll = true;
            this.deliveryGoodsIndeterminate = false;

            this.deliveryForm = {
                orderId: orderId,
                trackCompany: null,
                trackCompanyCode: null,
                trackNum: null,
            };

            if (!this.dialogOrderDetail)
                await this.getOrderDetail({ id: orderId }, false);

            // this.hasDisabledDelivery = this.orderDetail.data.orderItems.every(
            //     (item) => !+item.requiresShipping
            // );

            this.deliveryGoods = this.orderDetail.data.orderItems
                .filter(
                    (v) => v.fulfillmentStatus == 1 || v.fulfillmentStatus == 5
                ) // 1: 待发货 2: 部分发货
                .map((v) => {
                    v.isChecked = true;
                    v.canSendNum = v.quantity - v.shippedQuantity;
                    v.num = v.quantity - v.shippedQuantity;
                    return v;
                });

            this.hasDisabledState();

            this.dialogDelivery = true;
        },
        resetDeliveryDialog() {
            this.hasDisabledDelivery = false;
        },
        saveDelivery() {
            let checkedGoods = this.deliveryGoods.filter((v) => v.isChecked);

            if (!checkedGoods.length) {
                this.$message.error('没有选中任何商品');
                return;
            }

            if (checkedGoods.some((v) => !v.num)) {
                this.$message.error('选中商品的发货数量不能为空');
                return;
            }

            if (this.hasDisabledDelivery) {
                let params = {
                    orderItem: checkedGoods.map((v) => ({
                        orderItemId: v.id,
                        quantity: v.num ?? 0,
                    })),
                    orderId: this.deliveryForm.orderId,
                };
                this.$http
                    .post('/order/doDelivery', params)
                    .then(({ data: { code } }) => {
                        if (code === 200) {
                            this.dialogDelivery = false;
                            this.$message.success('操作成功');
                            this.queryData();
                            if (this.dialogOrderDetail)
                                this.getOrderDetail({
                                    id: this.orderDetail.data.id,
                                });
                        }
                    })
                    .finally(() => (this.deliveryLoading = false));
            } else {
                this.$refs.deliveryForm.validate((valid) => {
                    if (valid) {
                        this.deliveryLoading = true;
                        this.deliveryForm.orderItem = checkedGoods.map((v) => ({
                            orderItemId: v.id,
                            quantity: v.num ?? 0,
                        }));

                        this.$http
                            .post('/order/doDelivery', this.deliveryForm)
                            .then(({ data: { code } }) => {
                                if (code === 200) {
                                    this.dialogDelivery = false;
                                    this.$message.success('操作成功');
                                    this.queryData();
                                    if (this.dialogOrderDetail)
                                        this.getOrderDetail({
                                            id: this.orderDetail.data.id,
                                        });
                                }
                            })
                            .finally(() => (this.deliveryLoading = false));
                    }
                });
            }
        },
        /* 发货 end */

        getOrderNavigation({ type }) {
            if (type === 'prev') --this.detailIndex;
            if (type === 'next') ++this.detailIndex;
            this.detailId = this.tableData[this.detailIndex]?.id;

            this.toggleDetailNavigation(this.detailIndex);
            this.resetInfo();
            this.getOrderDetail({
                id: this.detailId,
            });
        },

        toggleDetailNavigation(index) {
            let isPrev = false,
                isNext = false;
            if (index === 0) isPrev = true;
            if (index === this.tableData.length - 1) isNext = true;

            this.isDisabledPrev = isPrev;
            this.isDisabledNext = isNext;
        },

        // 获取订单详情
        getOrderDetail(item, showDialog = true, index) {
            return new Promise((resolve) => {
                this.orderDetail.hideOrderRemarkForm = true;
                this.orderDetail.hideShipAddressForm = true;
                this.fulfillment.data = [];

                if ((index ?? '') !== '') {
                    this.detailIndex = index;
                    this.toggleDetailNavigation(index);
                }

                this.$http
                    .get('/order/getDetail', { params: { id: item.id } })
                    .then((r) => {
                        if (r.data.code === 200) {
                            this.orderDetail.data = r.data.data;
                            this.getFulfillment(this.orderDetail.data.id);
                            resolve();
                        }
                    })
                    .catch((err) => {
                        this.$message.error('加载订单详情失败');
                    });

                this.dialogOrderDetail = showDialog;
            });
        },

        // 编辑订单备注
        initOrderRemark() {
            this.orderDetail.orderRemarkForm = {
                id: this.orderDetail.data.id,
                orderRemark: this.orderDetail.data.orderRemark,
            };

            this.orderDetail.hideOrderRemarkForm = false;
        },
        saveOrderRemark() {
            this.btnLoading = 'saveOrderRemark';

            this.$http
                .post('/order/remark', this.orderDetail.orderRemarkForm)
                .then((r) => {
                    if (r.data.code === 200) {
                        this.btnLoading = '';
                        this.orderDetail.hideOrderRemarkForm = true;

                        this.$message({
                            type: 'success',
                            message: '保存成功',
                            duration: 1000,
                        });

                        this.orderDetail.data.orderRemark =
                            this.orderDetail.orderRemarkForm.orderRemark;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    this.btnLoading = '';
                });
        },

        // 订单详情 - 切换国家
        changeShipCountry(countryId) {
            this.orderDetail.provinceData = [];
            this.orderDetail.shipAddressForm.shippingProvinceId = null;

            this.queryProvince(countryId);
        },

        // 取消订单
        cancelOrder(orderId) {
            this.$confirm('该操作无法撤回，请确认是否取消？', '取消订单', {
                type: 'warning',
                confirmButtonText: '确认取消',
                cancelButtonText: '不取消',
            })
                .then(() => {
                    this.btnLoading = 'cancelOrder';

                    this.$http
                        .get('/order/cancel', { params: { id: orderId } })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.btnLoading = '';

                                this.$message({
                                    type: 'success',
                                    message: '取消订单成功',
                                    duration: 1000,
                                    onClose: () =>
                                        this.$router.push('/order/after/sale'), // this.getOrderDetail( { id: orderId } )
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = '';
                        });
                })
                .catch(() => {});
        },

        // 申请售后
        orderAfterSale(orderId) {
            this.$confirm(
                '若点击「是」，需到【售后订单】页面处理售后问题',
                '是否进行售后',
                {
                    type: 'warning',
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                }
            )
                .then(() => {
                    this.btnLoading = 'orderAfterSale';

                    this.$http
                        .get('/order/afterSale', { params: { id: orderId } })
                        .then((r) => {
                            this.btnLoading = '';

                            if (r.data.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '操作成功，即将转到售后订单页面',
                                    duration: 1000,
                                    onClose: () =>
                                        this.$router.push('/order/after/sale'),
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = '';
                        });
                })
                .catch(() => {});
        },

        // 保存收货信息
        initShipAddress(isDelivery) {
            this.orderDetail.shipAddressForm = {
                id: this.orderDetail.data.id,
                shippingAddress: this.orderDetail.data.shippingAddress,
                shippingCity: this.orderDetail.data.shippingCity,
                shippingCountryId: this.orderDetail.data.shippingCountryId,
                shippingEmail: this.orderDetail.data.shippingEmail,
                shippingFirstName: this.orderDetail.data.shippingFirstName,
                shippingLastName: this.orderDetail.data.shippingLastName,
                shippingPhone: this.orderDetail.data.shippingPhone,
                shippingPhoneCode: this.orderDetail.data.shippingPhoneCode,
                shippingProvinceId: this.orderDetail.data.shippingProvinceId,
                shippingZip: this.orderDetail.data.shippingZip,
            };

            this.copy_shipAddressForm = JSON.parse(
                JSON.stringify(this.orderDetail.shipAddressForm)
            );

            this.queryProvince(this.orderDetail.data.shippingCountryId);

            if (isDelivery) this.hideShipAddressForm = false;
            else this.orderDetail.hideShipAddressForm = false;
        },

        cancelEditAddress() {
            this.orderDetail.shipAddressForm = JSON.parse(
                JSON.stringify(this.copy_shipAddressForm)
            );
            this.orderDetail.hideShipAddressForm = true;
        },

        resetInfo() {
            Object.assign(
                this.orderDetail.shipAddressForm,
                this.$options.data.call(this).orderDetail.shipAddressForm
            );

            this.copy_shipAddressForm = {};

            Object.assign(
                this.orderDetail.data,
                this.$options.data.call(this).orderDetail.data
            );
        },

        saveShipAddress() {
            this.btnLoading = 'saveShipAddress';

            this.$http
                .post('/order/editShipping', this.orderDetail.shipAddressForm)
                .then((r) => {
                    if (r.data.code === 200) {
                        this.btnLoading = '';

                        this.$message({
                            type: 'success',
                            message: '保存成功',
                            duration: 1000,
                        });

                        this.orderDetail.data.shippingFirstName =
                            this.orderDetail.shipAddressForm.shippingFirstName;
                        this.orderDetail.data.shippingLastName =
                            this.orderDetail.shipAddressForm.shippingLastName;
                        this.orderDetail.data.shippingPhoneCode =
                            this.orderDetail.shipAddressForm.shippingPhoneCode;
                        this.orderDetail.data.shippingPhone =
                            this.orderDetail.shipAddressForm.shippingPhone;
                        this.orderDetail.data.shippingEmail =
                            this.orderDetail.shipAddressForm.shippingEmail;
                        this.orderDetail.data.shippingCountryId =
                            this.orderDetail.shipAddressForm.shippingCountryId;
                        this.orderDetail.data.shippingCountryEn =
                            this.orderDetail.phoneCodeData.find(
                                (v) =>
                                    v.id ==
                                    this.orderDetail.data.shippingCountryId
                            ).enName;
                        this.orderDetail.data.shippingProvinceId =
                            this.orderDetail.shipAddressForm.shippingProvinceId;
                        this.orderDetail.data.shippingProvinceEn =
                            this.orderDetail.provinceData.find(
                                (v) =>
                                    v.id ==
                                    this.orderDetail.data.shippingProvinceId
                            ).enName;
                        this.orderDetail.data.shippingCity =
                            this.orderDetail.shipAddressForm.shippingCity;
                        this.orderDetail.data.shippingAddress =
                            this.orderDetail.shipAddressForm.shippingAddress;
                        this.orderDetail.data.shippingZip =
                            this.orderDetail.shipAddressForm.shippingZip;

                        this.orderDetail.hideShipAddressForm = true;
                        this.hideShipAddressForm = true;
                    } else {
                        this.btnLoading = '';
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    this.btnLoading = '';
                });
        },

        // 获取订单物流详情
        getFulfillment(orderId) {
            this.$http
                .get('/order/getFulfillment', { params: { orderId } })
                .then((r) => {
                    if (r.data.code === 200) {
                        this.fulfillment.data = r.data.data;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                });
        },

        // 物流详情 - 取消发货
        cancelFulfillment(item) {
            this.$confirm('是否确认取消发货？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            })
                .then(() => {
                    this.btnLoading = 'cancelFulfillment_' + item.id;

                    this.$http
                        .get('/order/cancelDelivery', {
                            params: { fulfillmentId: item.id },
                        })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.btnLoading = '';

                                this.$message({
                                    type: 'success',
                                    message: '操作成功',
                                    duration: 1000,
                                    onClose: () => {
                                        this.getOrderDetail({
                                            id: this.orderDetail.data.id,
                                        });
                                    },
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = '';
                        });
                })
                .catch(() => {});
        },

        // 物流详情 - 确认收货
        confirmDelivery(item) {
            this.$confirm('是否确认收货？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            })
                .then(() => {
                    this.btnLoading = 'confirmDelivery_' + item.id;

                    this.$http
                        .get('/order/confirmDelivery', {
                            params: { fulfillmentId: item.id },
                        })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.btnLoading = '';

                                this.$message({
                                    type: 'success',
                                    message: '操作成功',
                                    duration: 1000,
                                    onClose: () => {
                                        this.getOrderDetail({
                                            id: this.orderDetail.data.id,
                                        });
                                    },
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = '';
                        });
                })
                .catch(() => {});
        },

        // 物流详情 - 删除运单
        deleteFulfillment(item) {
            this.$confirm('是否确认删除运单？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            })
                .then(() => {
                    this.btnLoading = 'deleteFulfillment' + item.id;

                    this.$http
                        .delete('/order/deleteFulfillment', {
                            params: { fulfillmentId: item.id },
                        })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.btnLoading = '';

                                this.$message({
                                    type: 'success',
                                    message: '操作成功',
                                    duration: 1000,
                                    onClose: () => {
                                        this.getOrderDetail({
                                            id: this.orderDetail.data.id,
                                        });
                                    },
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = '';
                        });
                })
                .catch(() => {});
        },
        getFulfillmentStatus(status) {
            switch (status) {
                case '1':
                    return '待发货';
                case '2':
                    return '已发货';
                case '3':
                    return '已完成';
                case '4':
                    return '已退货';
            }
        },
        getGoodsOption(obj) {
            let str = '';
            for (let item in obj) {
                str += item + ':' + obj[item] + ' / ';
            }
            str = str.substring(0, str.length - 3);
            return str;
        },
    },
};
</script>

<style scoped>
.drawer-footer {
    background: #e4e7ee;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 58px;
    box-sizing: border-box;
    padding: 0 6px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* position: relative; */
    z-index: 2;
}
.drawer-table-head .ico-close {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: #000;
    font-size: 24px;
    cursor: pointer;
}
::v-deep .drawer-table-head .el-drawer__body {
    background: #e4e7ee;
}
.drawer-table-head .header {
    padding-left: 24px;
    box-sizing: border-box;
    background: #fff;
    display: flex;
    align-items: center;
    position: relative;
}
.drawer-table-head .header h2 {
    font-size: 18px;
    font-weight: normal;
}
.table-header-list {
    padding: 10px 10px 10px;
}
.table-header-list .item {
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    height: 54px;
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.table-header-list .item:nth-child(1) {
    margin-top: 0;
}

.table-header-list .item i {
    font-size: 16px;
    color: #409eff;
    margin-right: 10px;
}

.drawer-table-head .header .sub-h2 {
    font-size: 12px;
    color: #999;
}

.main-wrap {
    height: auto;
}

.order-control {
    position: absolute;
    top: 14px;
    right: 80px;
    display: flex;
    gap: 20px;
}
.order-control a {
    font-size: 22px;
}

.hidelink {
    max-height: 42px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.el-table /deep/ .cell {
    overflow: unset;
}
.infobox {
    position: relative;
}
.infobox .infoalert {
    position: absolute;
    /*position: fixed;*/
    z-index: 5;
    /*transform: translateY(-300px) translateX(150px);*/
    top: 0;
    left: 140px;
    width: 360px;
    height: 300px;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #eee;
    display: none;
    padding: 10px;
}
.el-table__row:nth-last-child(-n + 5) .infoalert {
    bottom: 0;
    top: inherit;
    /*transform: translateY(-500px) translateX(150px);*/
}
/*.infobox:hover .infoalert {
    display: block;
}*/
.infoitem {
    max-height: 450px;
    overflow-y: auto;
}
.inforow {
    display: flex;
    margin: 10px 0;
    /* align-items: center; */
}
.inforow .imgbox {
    width: 120px;
    /*flex-basis: 120px;*/
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.inforow .infos {
    width: calc(100% - 150px);
    margin-left: 10px;
}
.inforow .infos p {
    text-align: left;
    margin: 0;
}
.inforow .infos p.tit {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}
.inforow .infos p.p {
    font-size: 14px;
    color: #bbb;
    line-height: 20px;
}
/* 新增运单 - 商品列表 */
.deliveryGoodsList {
    border-collapse: collapse;
    width: 100%;
    color: #606266;
}

.deliveryGoodsList > tr > td {
    border-top: solid 1px #e8ebf3;
    border-bottom: solid 1px #e8ebf3;
    padding: 6px;
}

#OrderDetailRemark > .el-icon-edit:hover {
    opacity: 0.8;
}
</style>
<style>
.order-list-query-keyword .el-select .el-input {
    width: 130px !important;
    color: #606266;
}

.order-list-query-keyword .el-input-group__prepend {
    background-color: #fff;
}
/*
.el-table__row:nth-child(-n + 3) .infoalert {
    top: -25px !important;
    bottom: inherit !important;
}
*/

/* 新增运单弹窗 */
#deliveryDialog > .el-dialog {
    background: #ecedf3;
}

#deliveryDialog .el-icon-edit:hover {
    color: #428dfc;
}

/* 查看详情弹窗 */
#orderDetailDialog > .el-dialog {
    background: #ecedf3;
}

.OrderDetail_AsideList {
    border-top: solid 1px #d3d8e9;
}

.OrderDetail_AsideList > h3 {
    white-space: nowrap;
}

.OrderDetail_AsideList .el-icon-edit:hover {
    color: #428dfc;
}
.ft-through {
    text-decoration: line-through;
    font-style: italic;
    color: #a0a0a0;
}
.tabs-mod {
    width: 100%;
    position: relative;
}
.tabs-mod .btn-position {
    position: absolute;
    top: 0;
    right: 0;
}
</style>
