<template>
    <el-autocomplete :clearable="true" :title="searchStr" :trigger-on-focus="true" :fetch-suggestions="querySearchAsync" v-scrollLoad="load" v-model="searchStr" popper-class="diy-autocomplete" class="el-autocomplete-component" size="small" ref="autocomplete" @select="handleSelect" @blur="handleBlur" @clear="handleClear" autocomplete=“off” :disabled="disabled">
        <i class="el-icon-search el-input__icon" slot="suffix" @click="handleIconClick">
        </i>
        <template slot-scope="{ item }">
            <!-- <slot name="item" :item="item"></slot> -->
            <slot name="default" :item="item">
                <div>{{item}}</div>
            </slot>
        </template>
    </el-autocomplete>
</template>
<script>
import { Loading } from 'element-ui';
let loadingInstance = null;

function debounce(func, wait, immediate) {
    let timeout, args, context, timestamp, result;
    const later = function () {
        const last = +new Date() - timestamp;
        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            if (!immediate) {
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            }
        }
    };
    return function (...args) {
        context = this;
        timestamp = +new Date();
        const callNow = immediate && !timeout;
        if (!timeout) timeout = setTimeout(later, wait);
        if (callNow) {
            result = func.apply(context, args);
            context = args = null;
        }
        return result;
    };
}

export default {
    name: 'autocomplete',
    // extends: Autocomplete,
    props: {
        title: {
            type: String,
            default: '',
        },
        item: {
            type: Object,
            default: {},
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            required: true,
        },
        keyname: {
            type: String,
            default: 'value',
        },
    },
    data() {
        return {
            customerForm: {
                pageNo: 1,
                pageSize: 20,
            },
            showAutocomplete: false,
            searchStr: '', //输入关键词的值
            pageNum: 1,
            pageSize: 20,
            total: 0, //筛选数据的总值
            // placeholder: '请输入邮箱',
            blurTxt: null, //记录失焦时搜索框中的文字，避免聚焦时重新筛选数据
            blurArr: [], //记录失焦时已经搜索出来的列表
            activeItem: {},
        };
    },

    created() {
        this.$set(this.customerForm, this.keyname, '');
    },

    async mounted() {
        // let result = await this.getList('');
        // this.blurArr = result;
    },

    watch: {
        item: {
            handler(_item) {
                this.activeItem = _item;
            },
            immediate: true,
            deep: true,
        },
        title: {
            handler(val) {
                this.searchStr = val;
            },
            immediate: true,
        },
    },

    directives: {
        scrollLoad: {
            bind(el, binding, vnode) {
                let wrapDom = el.querySelector(
                    '.el-autocomplete-suggestion__wrap'
                );
                let listDom = el.querySelector(
                    '.el-autocomplete-suggestion__wrap  .el-autocomplete-suggestion__list'
                );

                // 滚动事件做防抖处理
                wrapDom.addEventListener(
                    'scroll',
                    debounce((e) => {
                        let condition =
                            wrapDom.offsetHeight +
                            wrapDom.scrollTop +
                            50 -
                            listDom.offsetHeight;
                        if (condition > 0 && !vnode.context.loading) {
                            binding.value();
                        }
                    }, 300),
                    false
                );
            },
        },
    },

    methods: {
        handleBlur() {
            this.blurTxt = this.searchStr || '';
            this.blurArr = this.$refs['autocomplete'].$data.suggestions;
        },
        reset() {
            this.searchStr = '';
            this.blurTxt = '';
            this.blurArr = [];
            this.activeItem = {};
            this.$refs['autocomplete'].$data.suggestions = [];
        },
        // 清空搜索项
        handleClear() {
            this.blurTxt = '';
            this.blurArr = [];
            this.activeItem = {};
            // this.title = '';
            this.$refs['autocomplete'].$data.suggestions = [];
            // alert('1');
        }, // 关闭加载圈
        closeLoading() {
            loadingInstance && loadingInstance.close && loadingInstance.close();
            loadingInstance = null;
        }, // 条件查询
        async querySearchAsync(queryString = '', cb) {
            this.$refs['autocomplete'].$data.suggestions = [];
            if (this.blurTxt === queryString) {
                console.dir(cb);
                cb(this.blurArr);
                return;
            }
            this.handleClear();
            let searchVal = queryString; // 后面所拼接的班级名称和角色不参与筛选字段中
            queryString.indexOf('-') !== -1
                ? (searchVal = queryString.split('-')[0])
                : '';
            this.pageNum = 1;
            this.blurTxt = searchVal;
            let results = await this.getList(searchVal);
            cb(results || []);
        }, // 获取用户列表
        async getList(queryString) {
            let res = await this.searchUserList({
                pageNo: this.pageNum,
                pageSize: this.pageSize,
                [this.keyname]: decodeURI(queryString),
            });
            let { code, data: result, count } = res.data;
            if (code !== 200) return;
            this.total = count; // 调用 callback 返回建议列表的数据
            return result;
        },
        async load() {
            this.closeLoading(); // 加载到最后一页停止加载
            if (this.pageNum * this.pageSize > this.total) {
                return;
            }
            this.pageNum++;
            loadingInstance = Loading.service({
                target: document.querySelector('.el-autocomplete-suggestion'),
                fullscreen: false,
                spinner: 'el-icon-loading',
                lock: true,
                text: '加载中...',
            });
            let results = await this.getList(this.searchStr);
            this.closeLoading();
            // this.pageNum * this.pageSize >= this.total
            //     ? results.push({ value: '暂无更多数据' })
            //     : '';
            this.$refs['autocomplete'].$data.suggestions =
                this.$refs['autocomplete'].$data.suggestions.concat(results);
        }, // 选中用户跳转至对应的页面
        handleSelect(item) {
            this.$refs['autocomplete'].$data.suggestions = this.blurArr = [
                item,
            ];

            if (this.$listeners.handeItem) {
                this.$emit('handeItem', item);
            } else {
                this.searchStr = item[this.keyname];
            }

            this.activeItem = item;
        },

        searchUserList(options) {
            const URL = this.url,
                params = Object.assign({}, this.customerForm, options);

            return this.$http.get(URL, { params });
        },

        handleIconClick() {},
    },
};
</script>

<style scoped>
.complete-item p {
    font-size: 12px;
    margin: 0;
}
.complete-item {
    padding: 4px 0;
    line-height: 18px;
}
.complete-item .email {
    font-size: 12px;
}
.complete-item .last {
    text-align: center;
}
</style>