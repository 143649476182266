<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商品管理</el-breadcrumb-item>
            <el-breadcrumb-item>款式组合</el-breadcrumb-item>
        </el-breadcrumb>

        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input placeholder="SPU" v-model="queryForm.spu">
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-input placeholder="组合名称" v-model="queryForm.makeupName">
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-input placeholder="商品名称" v-model="queryForm.goodsTitle">
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-refresh-left" @click="resetSearch">重置</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="showAdd">新增</el-button>
            </el-form-item>
        </el-form>

        <el-table ref="orderListTable" border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" :data="tableData" v-loading="loading == 'table'">
            <el-table-column label="ID" prop="id" width="100px"></el-table-column>
            <el-table-column label="组合名称" prop="makeupName"></el-table-column>
            <el-table-column label="组合商品">
                <template slot-scope="{row}">
                    <div class="mod-list">
                        <div class="item" v-for="(item, i) in row.goodsList" :key="i">

                            <GoodsImageTemplate :images="item.goodsImages"></GoodsImageTemplate>
                            <p class="name">{{ item.goodsTitle }}</p>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="{row}">
                    <div class="mod-list">
                        <div class="item" v-for="(item, i) in row.goodsList" :key="'status_' + i">
                            <el-tag :type="item.published ? 'success' : 'info' ">
                                {{ item.published ? '已上架' : '未上架' }}</el-tag>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="更新时间" prop="updateTime"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="{row}">
                    <div class="handler">
                        <el-link type="primary" @click="editItem(row)">编辑</el-link>
                        <el-link type="primary" :href="row.path" target="_blank">预览</el-link>
                        <el-link type="primary" @click="removeItem(row)">删除</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-dialog fullscreen title="组合设置" :visible.sync="visibleDialog" :close-on-click-modal="false" class="dialog-info" @close="resetDialog">
            <div class="content">
                <el-form ref="formInfo" :model="formInfo" :rules="rules">
                    <el-form-item label="组合名称" prop="makeupName">
                        <el-input v-model="formInfo.makeupName" label="组合名称" :disabled="onlyView ? true : false"></el-input>
                    </el-form-item>
                    <div class="mod">
                        <p>组合商品(商品未上架时，组合不展示该商品)</p>
                        <el-button type="primary" @click="openSelectGoods" :disabled="onlyView ? true : false">
                            选择商品（{{ selectedGoodsTable.total || 0 }}/{{ 10 }}）</el-button>
                    </div>

                    <el-form-item label="标题名称（用于商品详情款式内容处展示）" prop="makeupTitle">
                        <el-input v-model="formInfo.makeupTitle" label="标题名称" :disabled="onlyView ? true : false"></el-input>
                    </el-form-item>
                </el-form>

                <div class="flex">
                    <template v-if="selectedGoodsTable.data.length > 0">
                        <vuedraggable class="wrapper" v-model="selectedGoodsTable.data">
                            <div class="itembox" v-for="(item,indexB) in selectedGoodsTable.data" :key="indexB">
                                <div class="imgbox">
                                    <GoodsImageTemplate :images="item.goodsImages" width="180" height="180" :hasHover="false"></GoodsImageTemplate>
                                </div>
                                <p class="tit">{{ item.goodsTitle }}</p>
                                <p class="tit">售价:{{ item.price }} / 原价:{{ item.compareAtPrice }}
                                </p>
                                <p>库存: {{ item.inventoryQuantity }}</p>
                                <p class="between" v-show="!onlyView">
                                    <span class="w50" title="置顶"><i class="el-icon-top" @click="toppingGoods(item)"></i></span>
                                    <span class="w50" @click="deleteSelectedGoods(item)"><i class="el-icon-delete"></i></span>
                                </p>
                                <span :class="`published published${item.published ? 'B' : 'A'}` ">{{ item.published ? '已上架': '未上架' }}</span>
                            </div>
                        </vuedraggable>
                    </template>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveInfo" v-show="!onlyView" :loading="loading=='saveInfo'">确定</el-button>
                <el-button @click="visibleDialog = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 选择商品 -->
        <SelectGoods ref="selectGoods" :showDialog="dialogSelectGoods" :containItem="false" :selectedGoods="selectedGoodsTable.data" @closeDialog="closeSelectGoods" @saveSelectedGoods="saveSelectGoods">
        </SelectGoods>

    </div>
</template>
<script>
import vuedraggable from 'vuedraggable';
import SelectGoods from '@/components/SelectGoods';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
export default {
    name: 'GoodsMakeUp',
    components: {
        SelectGoods,
        vuedraggable,
        GoodsImageTemplate,
    },
    data: () => ({
        loading: '',
        queryForm: {
            spu: null,
            makeupTitle: null,
            makeupName: null,
            pageNo: 1,
            pageSize: 10,
        },
        tableData: [],
        total: null,
        dialogSelectGoods: false,
        selectedGoodsTable: {
            data: [],
            total: 0,
        },
        formInfo: {
            makeupName: null,
            makeupTitle: null,
        },
        rules: {
            makeupName: [
                { required: true, message: '请填写组合名称', trigger: 'blur' },
            ],
            makeupTitle: [
                { required: true, message: '请填写标题名称', trigger: 'blur' },
            ],
        },
        onlyView: false,
        visibleDialog: false,
        stateInfo: '', // add | edit
    }),
    mounted() {
        this.queryData();
    },
    methods: {
        queryData() {
            this.loading = 'table';
            this.$http
                .get('/goods/mekeup/getPage', { params: this.queryForm })
                .then((res) => {
                    const { code, data, count } = res.data;
                    if (code === 200) {
                        this.tableData = data;
                        this.total = count;
                    }
                })
                .finally(() => {
                    this.loading = '';
                });
        },
        resetSearch() {
            Object.assign(
                this.queryForm,
                this.$options.data.call(this).queryForm
            );
            this.queryData();
        },
        openSelectGoods() {
            this.$refs.selectGoods.caller = 'notset';
            this.$refs.selectGoods.init();
            this.dialogSelectGoods = true;
        },
        closeSelectGoods() {
            this.dialogSelectGoods = false;
        },
        saveSelectGoods(items, caller) {
            switch (caller) {
                default:
                    this.selectedGoodsTable.data = items;
                    this.selectedGoodsTable.total =
                        this.selectedGoodsTable.data.length;
            }
        },
        // 置顶
        toppingGoods(item) {
            let tempitem = JSON.parse(JSON.stringify(item));
            tempitem.top = 1;
            this.selectedGoodsTable.data.unshift(tempitem);
            let index = this.selectedGoodsTable.data.indexOf(item);
            this.selectedGoodsTable.data.splice(index, 1);
        },
        // 删除选中的商品（删除）
        deleteSelectedGoods(item) {
            let index = this.selectedGoodsTable.data.indexOf(item);

            if (index > -1) {
                this.selectedGoodsTable.data.splice(index, 1);
                this.selectedGoodsTable.total =
                    this.selectedGoodsTable.data.length;
            }
        },
        async upInfo(id) {
            const URL = '/goods/mekeup/getDetail';
            this.visibleDialog = true;
            if (this.stateInfo === 'add') return;
            let {
                data: { code, data: info },
            } = await this.$http.get(`${URL}?id=${id}`);
            if (code === 200) {
                this.formInfo = info;
                this.selectedGoodsTable = {
                    data: info?.goodsList,
                    total: info?.goodsList.length,
                };
            }
        },
        showAdd() {
            this.stateInfo = 'add';
            this.upInfo();
        },
        editItem({ id }) {
            this.stateInfo = 'edit';
            this.upInfo(id);
        },
        viewItem({ id }) {
            this.onlyView = true;
            this.editItem({ id });
        },
        saveInfo() {
            let url = '/goods/mekeup/' + this.stateInfo,
                msg = this.stateInfo === 'edit' ? '编辑成功~' : '新增成功~',
                { goodsList, ...args } = this.formInfo,
                options = {
                    ...args,
                    goodsIdList: this.selectedGoodsTable.data.map(
                        (cur) => cur.id
                    ),
                };

            this.$refs.formInfo.validate((valid) => {
                if (valid) {
                    this.loading = 'saveInfo';
                    this.$http
                        .post(url, options)
                        .then((res) => {
                            let { code } = res.data;
                            if (code === 200) {
                                this.$message.success(msg);
                                this.queryData();
                                this.visibleDialog = false;
                            }
                        })
                        .finally(() => {
                            this.loading = null;
                        });
                }
            });
        },
        removeItem({ id }) {
            const URL = '/goods/mekeup/delete';
            this.$confirm('是否删除', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                let {
                    data: { code },
                } = await this.$http.delete(URL, {
                    params: {
                        id,
                    },
                });
                if (code === 200) {
                    this.$message.success('删除成功~');
                    this.queryData();
                }
            });
        },
        resetDialog() {
            Object.assign(
                this.formInfo,
                this.$options.data.call(this).formInfo
            );
            Object.assign(
                this.selectedGoodsTable,
                this.$options.data.call(this).selectedGoodsTable
            );
            this.onlyView = false;
            this.$refs.formInfo.clearValidate();
        },
    },
};
</script>
<style scoped>
.mod-list {
    padding: 10px;
}
.mod-list .item {
    display: flex;
    align-items: center;
    min-height: 80px;
    margin-top: 20px;
}
.mod-list .el-tag {
    margin: 0 auto;
    display: block;
}
.mod-list .name {
    max-width: 60%;
    padding-left: 20px;
    margin: 0;
}
.mod-list .item:nth-child(1) {
    margin-top: 0;
}
.ui-img {
    width: 80px;
    height: 80px;
}
.handler {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}
.handler a {
    margin: 0 10px;
}

/* list */
.wrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}
.wrapper .itembox {
    width: 180px;
    flex-basis: 180px;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    padding: 5px 5px 0 5px;
    border-radius: 10px;
    background-image: linear-gradient(315deg, #e2e2e2, #fff);
    position: relative;
}
.wrapper .itembox .imgbox {
    width: 100%;
    height: 220px;
}
.wrapper .itembox .tit {
    height: 32px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.wrapper .itembox .between {
    display: flex;
    border-top: 1px solid #ccc;
    margin: 0;
    height: 30px;
}
.wrapper .itembox .w50 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 50%;
    width: 50%;
    height: 100%;
    cursor: pointer;
    color: #409eff;
    font-size: 20px;
}
.wrapper .itembox .published {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    padding: 2px 5px;
    font-size: 14px;
    color: #fff;
    background: #409eff;
}
.wrapper .itembox .published.publishedA {
    color: #333;
    background: #ddd;
}
.wrapper .itembox .published.publishedB {
    color: #fff;
    background: #409eff;
}
.wrapper .itembox .w50:first-child {
    border-right: 1px solid #ccc;
}
.wrapper .itembox .published {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    padding: 2px 5px;
    font-size: 14px;
}
.wrapper .itembox .published.publishedA {
    color: #333;
    background: #ddd;
}
.wrapper .itembox .published.publishedB {
    color: #fff;
    background: #409eff;
}
</style>