<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>角色管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.roleName" placeholder="角色名"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData" v-if="$auth($authData.system.roleGet)">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="initAddForm" v-if="$auth($authData.system.roleAdd)">新增</el-button>
            </el-form-item>
        </el-form>
        <!-- 数据表 -->
        <el-table border stripe :data="tableData" ref="roleTable" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="roleName" label="角色名" min-width="120"></el-table-column>
            <el-table-column prop="roleDesc" label="角色描述" min-width="120"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160" sortable></el-table-column>
            <el-table-column prop="updateTime" label="更新时间" width="160" sortable></el-table-column>
            <el-table-column fixed="right" label="操作" width="230" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-search" @click="queryDetail(scope.row)" v-if="$auth($authData.system.roleGet)">详情</el-button>
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="initEditForm(scope.row)" v-if="$auth($authData.system.roleEdit)">编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-lock" @click="initAuthForm(scope.row)" v-if="$store.state.userAuth.roleId === 1">授权</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="deleteData(scope.row)" v-if="$auth($authData.system.roleDelete)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>
        <!-- 业务弹框-新增角色 -->
        <el-dialog title="新增角色" :visible.sync="dialogAdd" :close-on-click-modal="false" top="3%" width="35%">
            <el-form :model="roleAddForm" :rules="roleRules" status-icon ref="roleAddForm" label-position="top" size="medium">
                <el-form-item label="角色名" prop="roleName">
                    <el-input placeholder="请输入角色名" v-model="roleAddForm.roleName" maxlength="32" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="角色描述" prop="roleDesc">
                    <el-input type="textarea" :rows="4" placeholder="请输入角色描述" v-model="roleAddForm.roleDesc" maxlength="100" show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                <el-button @click="dialogAdd = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 业务弹框-角色详情 -->
        <el-dialog title="角色详情" :visible.sync="dialogDetail" :close-on-click-modal="false" top="3%" width="35%">
            <el-descriptions border :column="1" direction="vertical" v-loading="detailLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading">
                <el-descriptions-item label="角色ID">
                    {{ detailData.id }}
                </el-descriptions-item>
                <el-descriptions-item label="角色名">
                    {{ detailData.roleName }}
                </el-descriptions-item>
                <el-descriptions-item label="角色描述">
                    {{ detailData.roleDesc }}
                </el-descriptions-item>
                <el-descriptions-item label="角色权限">
                    <el-tree :data="detailData.authTree" :props="{ label: 'name', children: 'children' }"></el-tree>
                </el-descriptions-item>
                <el-descriptions-item label="创建时间">
                    {{ detailData.createTime }}
                </el-descriptions-item>
                <el-descriptions-item label="更新时间">
                    {{ detailData.updateTime }}
                </el-descriptions-item>
            </el-descriptions>
        </el-dialog>
        <!-- 业务弹框-编辑角色 -->
        <el-dialog title="编辑角色" :visible.sync="dialogEdit" :close-on-click-modal="false" top="3%" width="35%">
            <el-form :model="roleEditForm" :rules="roleRules" status-icon ref="roleEditForm" label-position="top" size="medium">
                <el-form-item label="角色名" prop="roleName">
                    <el-input placeholder="请输入角色名" v-model="roleEditForm.roleName" maxlength="32" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="角色描述" prop="roleDesc">
                    <el-input type="textarea" :rows="4" placeholder="请输入角色描述" v-model="roleEditForm.roleDesc" maxlength="100" show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="editLoading" @click="editData">确定</el-button>
                <el-button @click="dialogEdit = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 业务弹框-角色授权 -->
        <el-dialog title="角色授权" :visible.sync="dialogAuth" :close-on-click-modal="false" top="3%" width="35%">
            <el-form :model="roleAuthForm" label-position="top" size="medium" v-loading="getAuthLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading">
                <el-form-item label="角色名">
                    <el-input v-model="roleAuthForm.roleName" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="角色权限">
                    <el-card shadow="never">
                        <el-tree :data="authTree" show-checkbox node-key="id" ref="authTree" :props="{ label: 'name', children: 'children' }"></el-tree>
                    </el-card>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="authLoading" @click="roleAuth">确定</el-button>
                <el-button @click="dialogAuth = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'Role',
    data() {
        return {
            queryForm: {
                roleName: null,
                pageNo: 1,
                pageSize: 10,
            },
            tableData: [],
            total: 0,
            loading: false,
            authTree: [],
            roleRules: {
                roleName: [
                    {
                        required: true,
                        message: '请输入角色名',
                        trigger: 'blur',
                    },
                ],
            },
            dialogAdd: false,
            roleAddForm: {},
            addLoading: false,
            dialogDetail: false,
            detailLoading: false,
            detailData: {},
            dialogEdit: false,
            roleEditForm: {},
            editLoading: false,
            dialogAuth: false,
            roleAuthForm: {},
            getAuthLoading: false,
            authLoading: false,
        };
    },
    mounted() {
        this.queryData();
        this.getAuth();
    },
    methods: {
        queryData() {
            this.loading = true;
            this.$http
                .get('/system/role/getPage', { params: this.queryForm })
                .then((response) => {
                    if (response.data.code === 200) {
                        this.tableData = response.data.data;
                        this.total = response.data.count;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getAuth() {
            this.$http.get('/system/role/getAuth').then((response) => {
                if (response.data.code === 200) {
                    this.authTree = response.data.data;
                }
            });
        },
        queryDetail(row) {
            this.detailLoading = true;
            this.$http
                .get('/system/role/get', { params: { id: row.id } })
                .then((response) => {
                    if (response.data.code === 200) {
                        this.detailData = response.data.data;
                    }
                })
                .finally(() => {
                    this.detailLoading = false;
                });
            this.dialogDetail = true;
        },
        initAddForm() {
            this.roleAddForm = {};
            this.addLoading = false;
            this.dialogAdd = true;
        },
        addData() {
            this.$refs['roleAddForm'].validate((valid) => {
                if (valid) {
                    this.addLoading = true;
                    this.$http
                        .post('/system/role/add', this.roleAddForm)
                        .then((response) => {
                            if (response.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message({
                                    message: '新增成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: () => {
                                        this.queryData();
                                    },
                                });
                            }
                        })
                        .finally(() => {
                            this.addLoading = false;
                        });
                }
            });
        },
        initEditForm(row) {
            this.roleEditForm = {
                id: row.id,
                roleName: row.roleName,
                roleDesc: row.roleDesc,
            };
            this.editLoading = false;
            this.dialogEdit = true;
        },
        editData() {
            this.$refs['roleEditForm'].validate((valid) => {
                if (valid) {
                    this.editLoading = true;
                    this.$http
                        .post('/system/role/edit', this.roleEditForm)
                        .then((response) => {
                            if (response.data.code === 200) {
                                this.dialogEdit = false;
                                this.$message({
                                    message: '编辑成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: () => {
                                        this.queryData();
                                    },
                                });
                            }
                        })
                        .finally(() => {
                            this.editLoading = false;
                        });
                }
            });
        },
        initAuthForm(row) {
            this.roleAuthForm = {
                id: row.id,
                roleName: row.roleName,
            };
            this.$nextTick(() => {
                this.$refs.authTree.setCheckedKeys([]);
            });
            this.getAuthLoading = true;
            this.$http
                .get('/system/role/getRoleAuth', { params: { id: row.id } })
                .then((response) => {
                    if (response.data.code === 200) {
                        response.data.data.forEach((id) => {
                            if (this.$refs.authTree.getNode(id).isLeaf) {
                                this.$refs.authTree.setChecked(id, true);
                            }
                        });
                    }
                })
                .finally(() => {
                    this.getAuthLoading = false;
                });
            this.authLoading = false;
            this.dialogAuth = true;
        },
        roleAuth() {
            this.authLoading = true;
            this.$http
                .post('/system/role/auth', {
                    roleId: this.roleAuthForm.id,
                    authData: this.$refs.authTree
                        .getHalfCheckedKeys()
                        .concat(this.$refs.authTree.getCheckedKeys()),
                })
                .then((response) => {
                    if (response.data.code === 200) {
                        this.dialogAuth = false;
                        this.$message({
                            message: '授权成功',
                            type: 'success',
                            duration: 1000,
                        });
                    }
                })
                .finally(() => {
                    this.authLoading = false;
                });
        },
        deleteData(row) {
            this.$confirm('是否删除该角色？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .delete('/system/role/delete', { params: { id: row.id } })
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                                duration: 1000,
                                onClose: () => {
                                    this.queryData();
                                },
                            });
                        }
                    });
            });
        },
    },
};
</script>

<style scoped></style>
