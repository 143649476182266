<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>店铺管理</el-breadcrumb-item>
            <el-breadcrumb-item>Youtube视频设置</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 查询表单 -->
        <el-form class="queryForm" :inline="true" :gutter="20" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.name" placeholder="昵称"></el-input>
            </el-form-item>
			<el-form-item>
			    <el-input v-model="queryForm.title" placeholder="标题"></el-input>
			</el-form-item>
			<el-form-item>
			    <el-input v-model="queryForm.url" placeholder="视频链接"></el-input>
			</el-form-item>
            <el-form-item>
                <el-select style="width:200px;" v-model="queryForm.published" filterable clearable placeholder="是否发布">
                    <el-option v-for="item in publishedOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="querySearch">查询</el-button>
            </el-form-item>
			<el-form-item>
			    <el-button type="info" icon="el-icon-refresh" @click="resetQuery">重置</el-button>
			</el-form-item>
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="openAdd">新增</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表 -->
        <el-table border stripe :data="tableData" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="name" label="昵称" min-width="120"></el-table-column>
			<el-table-column label="头像" width="120" header-align="center" align="center">
			    <template slot-scope="scope" v-if="scope.row.logo">
			        <!-- <GoodsImageTemplate :images="scope.row.logo"></GoodsImageTemplate> -->
					<img :src="scope.row.logo">
			    </template>
			</el-table-column>
            <el-table-column prop="title" label="标题" width="120"></el-table-column>
			<el-table-column label="视频链接" width="150">
				<template slot-scope="scope">
					<a :href="scope.row.url" target="_blank" class="link">{{scope.row.url}}</a>
				</template>
			</el-table-column>
			<el-table-column label="内容" width="200">
				<template slot-scope="scope">
					<p :title="scope.row.content" class="hidetext">{{scope.row.content}}</p>
				</template>
			</el-table-column>
			<el-table-column label="排序" width="150">
				<template slot-scope="scope">
					<el-input-number v-model="scope.row.sort" :precision="0" :min="0" :max="999999" label="排序" @change="changeSort(scope.row.id, $event)"></el-input-number>
				</template>
			</el-table-column>
            <el-table-column label="是否发布" width="100">
                <template slot-scope="scope">
                    <el-switch active-value="1" inactive-value="0" v-model="scope.row.published" @change="changeEnabled(scope.row, $event)"></el-switch>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="createTime" label="创建时间" width="100"></el-table-column>
            <el-table-column prop="updateTime" label="更新时间" width="100"></el-table-column> -->
            <el-table-column fixed="right" label="操作" width="150" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="editThis(scope.row.id)">编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="deleteThis(scope.row.id)" v-show="scope.row.published != 1">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-dialog top="3%" :title="dialogAddForm.id ? '编辑像素' : '添加像素'" :visible.sync="dialogAdd" width="1200px" :close-on-click-modal="false">
            <el-form :model="dialogAddForm" :rules="dialogRules" status-icon ref="dialogAddForm" label-width="80px" size="medium">
                <el-form-item label="昵称" prop="name">
                    <el-input placeholder="请输入昵称" autocomplete="off" v-model="dialogAddForm.name"></el-input>
                </el-form-item>
				<el-form-item label="头像" prop="logo">
					<div class="logoImg" v-if="dialogAddForm.logo" @click="setOpenInsert('dialogAddForm.logo')">
						<img :src="dialogAddForm.logo">
					</div>
				    <el-button v-else type="primary" @click="setOpenInsert('dialogAddForm.logo')">选择</el-button>
				</el-form-item>
                <el-form-item label="标题" prop="title">
                    <el-input placeholder="请输入标题" autocomplete="off" v-model="dialogAddForm.title"></el-input>
                </el-form-item>
				<el-form-item label="内容" prop="content">
				    <el-input type="textarea" :rows="4" placeholder="请输入内容" autocomplete="off" v-model="dialogAddForm.content"></el-input>
				</el-form-item>
                <el-form-item label="链接地址" prop="url">
                    <el-input placeholder="请输入链接地址" autocomplete="off" v-model="dialogAddForm.url"></el-input>
                </el-form-item>
				<el-form-item label="点赞数" prop="likeNum">
				    <el-input-number v-model="dialogAddForm.likeNum" :precision="0" :min="0" label="点赞数"></el-input-number>
				</el-form-item>
				<el-form-item label="关联商品">
				    <el-button type="primary" @click="openSelectGoods">选择</el-button>
					<div class="flex">
					    <template v-if="selectedGoodsTable.data.length > 0">
					        <vuedraggable class="wrapper" v-model="selectedGoodsTable.data">
					            <div class="itembox" v-for="(item,indexB) in selectedGoodsTable.data" :key="indexB">
					                <div class="imgbox">
					                    <GoodsImageTemplate :images="item.goodsImages" width="180" height="180" :hasHover="false"></GoodsImageTemplate>
					                </div>
					                <p class="tit">{{ item.goodsTitle }}</p>
					                <p class="tit">售价:{{ item.price }} / 原价:{{ item.compareAtPrice }}
					                </p>
					                <p>库存: {{ item.inventoryQuantity }}</p>
					                <p class="between">
					                    <!-- <span class="w50" title="置顶"><i class="el-icon-top" @click="toppingGoods(item)"></i></span> -->
					                    <span class="w50" @click="deleteSelectedGoods(item)"><i class="el-icon-delete"></i></span>
					                </p>
					                <span :class="`published published${item.published ? 'B' : 'A'}` ">{{ item.published ? '已上架': '未上架' }}</span>
					            </div>
					        </vuedraggable>
					    </template>
					</div>
				</el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                <el-button @click="dialogAdd = false">取消</el-button>
            </div>
        </el-dialog>
		
		<!--插入图片-->
		<ImageUploader :insertImage="insertImage" :insertType="insertType" @getOpenState="getOpenState" @getImage="getImage" ref="uploadImage"></ImageUploader>
		
		<!-- 选择商品 -->
		<SelectGoods ref="selectGoods" :showDialog="dialogSelectGoods" :containItem="false" :selectedGoods="selectedGoodsTable.data" @closeDialog="closeSelectGoods" @saveSelectedGoods="saveSelectGoods"></SelectGoods>
    </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
import vuedraggable from 'vuedraggable';
import SelectGoods from '@/components/SelectGoods';
export default {
    name: 'YouTube',
	components: {
	    GoodsImageTemplate,
		ImageUploader,
		vuedraggable,
		SelectGoods
	},
    data() {
        return {
            queryForm: {
                name: null,
                title: null,
                url: null,
				published: null,
                pageNo: 1,
                pageSize: 10,
            },
            loading: false,
            tableData: [],
            total: 0,
			
			insertImage: false,
			insertType: null,
			
			dialogSelectGoods: false,
			selectedGoodsTable: {
			    data: [],
			    total: 0,
			},

            dialogAdd: false,
            addLoading: false,
            dialogAddForm: {
                name: null,
				logo: null,
				content: null,
                title: null,
                url: null,
				goodsIds: [],
				type: 1,
				likeNum: undefined
            },
            dialogRules: {
                name: [
					{ required: true, message: '请输入昵称', trigger: 'blur', },
                ],
                logo: [
                    { required: true, message: '请选择头像', trigger: 'blur' },
                ],
                title: [
                    { required: true, message: '请输入标题', trigger: 'blur' },
                ],
                content: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                ],
				url: [
				    { required: true, message: '请输入链接地址', trigger: 'blur' },
				],
				likeNum: [
                    { required: true, message: '请输入点赞数', trigger: 'blur' },
                ],
            },
            publishedOptions: [
                { name: '禁用', id: 0 },
                { name: '启用', id: 1 },
            ],
        };
    },
    mounted() {
        this.queryData();
    },
    methods: {
		setOpenInsert(obj) {
		    this.insertType = obj;
		    this.insertImage = true;
		    this.$refs.uploadImage.openInsert();
		},
		getOpenState(result) {
		    this.insertImage = result;
		},
		getImage(obj, image) {
		    if (obj == 'dialogAddForm.logo') {
		        this.dialogAddForm.logo = image[image.length - 1];
		    }
		},
		openSelectGoods() {
		    this.$refs.selectGoods.caller = 'notset';
		    this.$refs.selectGoods.init();
		    this.dialogSelectGoods = true;
		},
		closeSelectGoods() {
		    this.dialogSelectGoods = false;
		},
		saveSelectGoods(items, caller) {
		    switch (caller) {
		        default:
		            this.selectedGoodsTable.data = items;
		            this.selectedGoodsTable.total =
		                this.selectedGoodsTable.data.length;
		    }
		},
		// 删除选中的商品（删除）
		deleteSelectedGoods(item) {
		    let index = this.selectedGoodsTable.data.indexOf(item);
		
		    if (index > -1) {
		        this.selectedGoodsTable.data.splice(index, 1);
		        this.selectedGoodsTable.total =
		            this.selectedGoodsTable.data.length;
		    }
		},
		changeSort(id, sort){
			console.log(id, sort)
			this.$http
			    .post('/setting/video/setSort', {
			        id: id,
			        sort: sort,
			    })
			    .then((r) => {
			        if (r.data.code === 200) {
			            this.$message({
			                type: 'success',
			                message: '已更新排序',
			                duration: 1000,
			            });
			        }
			    })
			    .catch((err) => {
					
			    })
			    .finally(() => {
			        this.queryData();
			    });
		},
        openAdd() {
			this.selectedGoodsTable = {
			    data: [],
			    total: 0
			}
            this.dialogAddForm = {
                name: null,
                logo: null,
                content: null,
                title: null,
                url: null,
                goodsIds: [],
                type: 1,
				likeNum: undefined
            };
            this.addLoading = false;
            this.dialogAdd = true;
        },
        async editThis(id) {
            this.loading = true;
			
			this.selectedGoodsTable = {
			    data: [],
			    total: 0
			}
			
            this.$http
                .get('/setting/video/getDetail', { params: { id: id } })
                .then(async (response) => {
                    if (response.data.code === 200) {
                        console.log(response.data.data);
                        let data = response.data.data;
                        this.dialogAddForm = {
                            id: data.id,
                            name: data.name,
                            logo: data.logo,
                            content: data.content,
                            title: data.title,
                            url: data.url,
                            goodsIds: data.goodsIds,
                            type: 1,
							likeNum: data.likeNum
                        }
						
						this.selectedGoodsTable = {
						    data: data.shopGoods,
						    total: data.shopGoods.length
						}
						
						// await this.getDetail(id)
                        this.addLoading = false;
                        this.dialogAdd = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
		async getDetail(id){
			await this.$http
			    .get('/setting/video/getDetail', { params: { id: id } })
			    .then((response) => {
			        if (response.data.code === 200) {
			            console.log(response.data.data[0]);
			            // let data = response.data.data[0];
			            // this.dialogAddForm.goodsIds
			        }
			    }).finally(() => {
                    return true
                });
		},
        addData() {
            this.$refs['dialogAddForm'].validate((valid) => {
                if (valid) {
                    this.addLoading = true;
                    let url;
                    if (this.dialogAddForm.id) {
                        url = '/setting/video/edit';
                    } else {
                        url = '/setting/video/add';
                    }
					
					let trueLink = this.dialogAddForm.url.indexOf('https://www.youtube.com/watch?v=')
					// console.log(trueLink)
					if(trueLink === -1){
						this.$message.warning('请填写正确的链接地址。')
						this.addLoading = false
						return false
					}
					
					this.dialogAddForm.goodsIds = []
					for(let i = 0; i < this.selectedGoodsTable.data.length; i++){
						let item = this.selectedGoodsTable.data[i]
						this.dialogAddForm.goodsIds.push(item.id)
					}
					
                    this.$http
                        .post(url, this.dialogAddForm)
                        .then((response) => {
                            this.addLoading = false;
                            if (response.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message({
                                    message: this.dialogAddForm.id
                                        ? '编辑成功'
                                        : '添加成功',
                                    type: 'success',
                                    duration: 1000,
                                });
                                this.queryData();
                            }
                        })
                        .catch((err) => {
                            this.addLoading = false;
                        });
                }
            });
        },
        changeEnabled(scope, value) {
            console.log(scope);
            this.$confirm(`确认要${value == 0 ? '取消发布' : '发布视频'}吗？`, {
                type: 'warning',
            })
                .then(() => {
                    this.$http
                        .post('/setting/video/setPublished', {
                            id: scope.id,
                            published: value,
                        })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '设置成功',
                                    duration: 1000,
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            scope.published = value == 0 ? '1' : '0';
                        })
                        .finally(() => {
                            this.queryData();
                        });
                })
                .catch(() => (scope.published = value == 0 ? '1' : '0'));
        },
        deleteThis(id) {
            this.$confirm('是否删除视频？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .delete('/setting/video/delete?id=' + id)
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message.success('删除成功');
                            this.queryData();
                        }
                    });
            });
        },
        querySearch() {
            this.queryForm.pageNo = 1
            this.queryData()
        },
		resetQuery(){
			this.queryForm = {
                name: null,
                title: null,
                url: null,
				published: null,
                pageNo: 1,
                pageSize: 10,
            }
			this.queryForm.pageNo = 1
			this.queryData()
		},
        queryData() {
            this.loading = true;

            this.$http
                .get('/setting/video/getPage', { params: this.queryForm })
                .then((r) => {
                    this.loading = false;

                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        for (let i = 0; i < this.tableData.length; i++) {
                            let item = this.tableData[i];
							item.published = item.published.toString();
                        }
                        this.total = r.data.count;
                    }
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
	.link {
		color: #409EFF;
	}
	.logoImg {
		width: 150px;
		height: 150px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.logoImg img {
		max-width: 100%;
		max-height: 100%;
	}
	/* list */
	.wrapper {
	    margin-top: 20px;
	    display: flex;
	    flex-wrap: wrap;
	}
	.wrapper p {
		margin: 0;
	}
	.wrapper .itembox {
	    width: 180px;
	    flex-basis: 180px;
	    margin-right: 20px;
	    margin-bottom: 20px;
	    border: 1px solid #ccc;
	    overflow: hidden;
	    padding: 5px 5px 0 5px;
	    border-radius: 10px;
	    background-image: linear-gradient(315deg, #e2e2e2, #fff);
	    position: relative;
	}
	.wrapper .itembox .imgbox {
	    width: 100%;
	    height: 220px;
	}
	.wrapper .itembox .tit {
	    height: 32px;
	    text-overflow: -o-ellipsis-lastline;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    display: -webkit-box;
	    -webkit-line-clamp: 2;
	    line-clamp: 2;
	    -webkit-box-orient: vertical;
	}
	.wrapper .itembox .between {
	    display: flex;
	    border-top: 1px solid #ccc;
	    margin: 0;
	    height: 30px;
	}
	.wrapper .itembox .w50 {
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    flex-basis: 100%;
	    width: 100%;
	    height: 100%;
	    cursor: pointer;
	    color: #409eff;
	    font-size: 20px;
	}
	.wrapper .itembox .published {
	    position: absolute;
	    top: 0;
	    right: 0;
	    display: inline-block;
	    padding: 2px 5px;
	    font-size: 14px;
	    color: #fff;
	    background: #409eff;
		height: 22px;
		line-height: 22px;
		border-bottom-left-radius: 4px;
	}
	.wrapper .itembox .published.publishedA {
	    color: #333;
	    background: #ddd;
	}
	.wrapper .itembox .published.publishedB {
	    color: #fff;
	    background: #409eff;
	}
	/* .wrapper .itembox .w50:first-child {
	    border-right: 1px solid #ccc;
	} */
	.wrapper .itembox .published {
	    position: absolute;
	    top: 0;
	    right: 0;
	    display: inline-block;
	    padding: 2px 5px;
	    font-size: 14px;
	}
	.wrapper .itembox .published.publishedA {
	    color: #333;
	    background: #ddd;
	}
	.wrapper .itembox .published.publishedB {
	    color: #fff;
	    background: #409eff;
	}
</style>