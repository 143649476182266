<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>营销管理</el-breadcrumb-item>
            <el-breadcrumb-item>满减活动</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 查询表单 -->
        <el-tabs v-model="campaignStatus" @tab-click="queryData">
            <el-tab-pane label="全部" name="0"></el-tab-pane>
            <el-tab-pane v-for="item in campaignStatusTypes" :key="item.value" :label="item.name" :name="item.value"></el-tab-pane>
        </el-tabs>
        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.campaignName" placeholder="活动名称"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData" :loading="loading">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="info" plain icon="el-icon-refresh-left" @click="resetCondition">重置</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="initAddCampaignFull">新增</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="warning" icon="el-icon-s-data" @click="openStatic" :loading="loading">数据明细</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表 -->
        <el-table border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" :data="tableData" v-loading="loading">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="campaignName" label="活动名称" min-width="120"></el-table-column>
            <el-table-column label="活动时间" width="160">
                <template slot-scope="scope">
                    {{ scope.row.startTime }}-{{
            scope.row.endTime ? scope.row.endTime : "不限"
          }}
                </template>
            </el-table-column>
            <el-table-column label="优惠项" min-width="120">
                <template slot-scope="scope">
                    <span class="py_2" v-for="(item, index) in scope.row.discountLevel" :key="index">
                        {{ index + 1 }}.满 {{ item.full }} {{ item.fullUnit }}
                        <span v-if="scope.row.discountType == 5">固定</span>
                        <span v-else>
                            <span v-if="item.subUnit == 'USD'"> 减</span>
                            <span v-else>，</span>
                        </span>
                        {{ item.sub }} {{ item.subUnit }}<br />
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="商品范围" width="100">
                <template slot-scope="scope">
                    {{ scope.row.scope == 1 ? "全部" : "部分" }}商品
                </template>
            </el-table-column>
            <el-table-column label="活动状态" width="100">
                <template slot-scope="scope">
                    {{
            campaignStatusTypes.find((v) => v.value == scope.row.campaignStatus)
              .name
          }}
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="200" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit" v-if="scope.row.campaignStatus != 3" @click="initEditCampaignFull(scope.row.id)">编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-view" v-if="scope.row.campaignStatus == 3" @click="initEditCampaignFull(scope.row.id)">详情</el-button>
                    <el-button size="mini" type="text" icon="el-icon-video-pause" v-if="scope.row.campaignStatus != 3" @click="stopCampaignFull(scope.row)">提前结束</el-button>
                    <!-- <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="deleteCampaignFull(scope.row)"
            >删除</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <!-- 新增满减活动 -->
        <el-dialog fullscreen title="新增满减活动" id="addCampaignFullDialog" :visible.sync="dialogAddCampaignFull" :close-on-click-modal="false">
            <el-form ref="addCampaignFullForm" size="medium" label-position="top" status-icon :model="addCampaignFullForm" :rules="addCampaignFullRules">
                <!-- 创建活动 -->
                <section class="addCampaignFullSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignFullLegend">基本信息</el-col>
                        <el-col :span="20">
                            <el-form-item label="活动名称" prop="campaignName" style="max-width: 650px">
                                <el-input placeholder="请输入活动名称" maxlength="20" show-word-limit v-model="addCampaignFullForm.campaignName"></el-input>
                            </el-form-item>
                            <div class="df ai_fe">
                                <el-form-item label="活动时间" prop="startTime">
                                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间" v-model="addCampaignFullForm.startTime">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item class="px_1" style="color: #bbb">～</el-form-item>
                                <el-form-item prop="endTime">
                                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间" :disabled="addCampaignFullEndTime" v-model="addCampaignFullForm.endTime">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item prop="isDefault" class="ml_3">
                                    <el-checkbox v-model="addCampaignFullEndTime" @change="
                      ($event) => {
                        if ($event) addCampaignFullForm.endTime = null;
                      }
                    ">不限结束时间</el-checkbox>
                                </el-form-item>
                            </div>
                            <el-form-item label="优惠类型" prop="discountType">
                                <el-select v-model="addCampaignFullForm.discountType" style="width: 240px">
                                    <el-option v-for="(item, index) in discountTypeOptions" :key="index" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </section>

                <section class="addCampaignFullSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignFullLegend">优惠规则</el-col>
                        <el-col :span="20" style="margin-top: -16px">
                            <div class="pr df ai_fe pt_3" style="border-bottom: solid 1px #d3d8e9" v-for="(item, index) in discountLevel" :key="index">
                                <el-form-item :label="'优惠层级' + (index + 1)" style="max-width: 300px">
                                    <div class="df ai_c">
                                        满&nbsp;
                                        <el-input maxlength="10" style="width: 150px" v-model="item.full" @change="setMaxNum(item.full, item.sub, addCampaignFullForm.discountType, index)"></el-input>
                                        &nbsp;{{ discountLevelFullUnit[addCampaignFullForm.discountType] }}
                                    </div>
                                </el-form-item>
                                <el-form-item style="margin-left: 30px; max-width: 300px">
                                    <div class="df">
                                        <span v-if="discountLevelSubUnit[addCampaignFullForm.discountType] == 'USD'">减&nbsp;</span>
                                        <span v-if="addCampaignFullForm.discountType == '6'">其中价格最低的&nbsp;</span>
                                        <el-input maxlength="10" style="width: 150px" v-model="item.sub" @change="setMaxNum(item.full, item.sub, addCampaignFullForm.discountType, index)"></el-input>
                                        &nbsp;{{ discountLevelSubUnit[addCampaignFullForm.discountType] }}<span v-if="addCampaignFullForm.discountType == '6'">免费</span>
                                    </div>
                                </el-form-item>
                                <el-button type="text" icon="el-icon-delete" class="pa py_0_i" style="top: 26px; left: 90px" v-if="index > 0" @click="deleteDiscountLevel(index)">
                                </el-button>
                            </div>
                            <el-button type="success" style="margin-top: 24px; margin-bottom: 24px" icon="el-icon-plus" @click="addDiscountLevel">新增层级</el-button>
                        </el-col>
                    </el-row>
                </section>

                <section class="addCampaignFullSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignFullLegend">适用范围</el-col>
                        <el-col :span="20">
                            <el-form-item prop="scope">
                                <div class="df ai_c mt_2">
                                    <div class="addCampaignFullCard" :class="{
                      addCampaignFullSelectedCard:
                        addCampaignFullForm.scope == 1,
                    }" @click="addCampaignFullForm.scope = 1">
                                        <h4>选择全场商品</h4>
                                        <p style="margin-top: 10px">所有商品可使用优惠码</p>
                                        <div class="addCampaignFullCardChecked" v-if="addCampaignFullForm.scope == 1">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </div>
                                    <div class="addCampaignFullCard" :class="{
                      addCampaignFullSelectedCard:
                        addCampaignFullForm.scope == 2,
                    }" @click="addCampaignFullForm.scope = 2">
                                        <h4>选择部分商品</h4>
                                        <p v-if="addCampaignFullForm.scope == 1">
                                            仅限部分商品或款式可使用<br />优惠码
                                        </p>
                                        <p v-if="addCampaignFullForm.scope == 2">
                                            已选择{{ selectedGoodsTable.total }}件商品
                                            <el-button type="text" icon="el-icon-plus" style="padding-top: 4px; padding-bottom: 4px" @click="openSelectGoods">继续新增商品</el-button>
                                        </p>
                                        <div class="addCampaignFullCardChecked" v-if="addCampaignFullForm.scope == 2">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <!-- 数据表 -->
                    <el-table ref="selectedGoodsTable" class="mt_3" stripe v-if="
              addCampaignFullForm.scope == 2 && selectedGoodsTable.total > 0
            " :data="selectedGoodsTable.data">
                        <el-table-column label="商品图片" width="80">
                            <template slot-scope="scope">

                                <!-- {{  scope.row.goodsImages }} -->

                                <GoodsImageTemplate :images="scope.row.goodsImages" width="80" height="80"></GoodsImageTemplate>

                            </template>
                        </el-table-column>
                        <el-table-column prop="goodsTitle" label="商品名称" min-width="80"></el-table-column>
                        <el-table-column label="售价/原价" min-width="80">
                            <template slot-scope="scope">
                                {{ scope.row.price }} / {{ scope.row.compareAtPrice }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="inventoryQuantity" label="库存" min-width="80"></el-table-column>
                        <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center">
                            <template slot-scope="scope">
                                <el-button size="mini" type="text" @click="deleteSelectedGoods(scope.row)">退出活动</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </section>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading" @click="addCampaignFull">保存</el-button>
            </div>
        </el-dialog>

        <!-- 编辑满减活动 -->
        <el-dialog fullscreen title="编辑满减活动" id="editCampaignFullDialog" :visible.sync="dialogEditCampaignFull" :close-on-click-modal="false" @close="clearCountDown">
            <el-form ref="editCampaignFullForm" size="medium" label-position="top" status-icon :model="editCampaignFullForm" :rules="editCampaignFullRules">
                <!-- 创建活动 -->
                <section class="addCampaignFullSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignFullLegend">基本信息</el-col>
                        <el-col :span="20" style="position: relative;">
                            <el-form-item label="活动名称" prop="campaignName" style="max-width: 650px">
                                <el-input placeholder="请输入活动名称" maxlength="20" show-word-limit v-model="editCampaignFullForm.campaignName"></el-input>
                            </el-form-item>
                            <div class="df ai_fe">
                                <el-form-item label="活动时间" prop="startTime">
                                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间" disabled v-model="editCampaignFullForm.startTime">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item class="px_1" style="color: #bbb">～</el-form-item>
                                <el-form-item prop="endTime">
                                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间" :disabled="editCampaignFullEndTime" v-model="editCampaignFullForm.endTime">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item prop="isDefault" class="ml_3">
                                    <el-checkbox v-model="editCampaignFullEndTime" @change="
                      ($event) => {
                        if ($event) editCampaignFullForm.endTime = null;
                      }
                    ">不限结束时间</el-checkbox>
                                </el-form-item>
                            </div>
                            <el-form-item label="优惠类型" prop="discountType">
                                <el-select disabled style="width: 240px" v-model="editCampaignFullForm.discountType">
                                    <el-option v-for="(item, index) in discountTypeOptions" :key="index" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <div class="countdownbox" v-if="editCampaignFullForm.campaignStatus == 1 || editCampaignFullForm.campaignStatus == 2">
                                <div class="top">距活动{{ countdown.type }}</div>
                                <div class="bottom">
                                    <p class="a">还剩<span class="red">{{ countdown.time }}</span>{{ countdown.unitCn }}</p>
                                    <p class="b">{{ countdown.time }} {{ countdown.unitEn }} to the end of the activity</p>
                                </div>
                            </div>
                            <!-- <div class="countdownbox" v-if="editCampaignFullForm.campaignStatus == 3">
								<p class="finish">活动已结束</p>
							</div> -->
                        </el-col>
                    </el-row>
                </section>

                <section class="addCampaignFullSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignFullLegend">优惠规则</el-col>
                        <el-col :span="20" style="margin-top: -16px">
                            <div class="pr df ai_fe pt_3" style="border-bottom: solid 1px #d3d8e9" v-for="(item, index) in discountLevel" :key="index">
                                <el-form-item :label="'优惠层级' + (index + 1)" style="max-width: 300px">
                                    <div class="df ai_c">
                                        满&nbsp;
                                        <el-input maxlength="10" style="width: 150px" v-model="item.full" @change="setMaxNum(item.full, item.sub, editCampaignFullForm.discountType, index)"></el-input>
                                        &nbsp;{{
                      discountLevelFullUnit[editCampaignFullForm.discountType]
                    }}
                                    </div>
                                </el-form-item>
                                <el-form-item style="margin-left: 30px; max-width: 300px">
                                    <div class="df">
                                        <span v-if="
                        discountLevelSubUnit[
                          editCampaignFullForm.discountType
                        ] == 'USD'
                      ">减&nbsp;</span>
                                        <span v-if="editCampaignFullForm.discountType == '6'">其中价格最低的&nbsp;</span>
                                        <el-input maxlength="10" style="width: 150px" v-model="item.sub" @change="setMaxNum(item.full, item.sub, editCampaignFullForm.discountType, index)"></el-input>
                                        &nbsp;{{
                      discountLevelSubUnit[editCampaignFullForm.discountType]
                    }}
                                        <span v-if="editCampaignFullForm.discountType == '6'">免费</span>
                                    </div>
                                </el-form-item>
                                <el-button type="text" icon="el-icon-delete" class="pa py_0_i" style="top: 26px; left: 90px" v-if="index > 0" @click="deleteDiscountLevel(index)">
                                </el-button>
                            </div>
                            <el-button type="success" style="margin-top: 24px; margin-bottom: 24px" icon="el-icon-plus" @click="addDiscountLevel">新增层级</el-button>
                        </el-col>
                    </el-row>
                </section>

                <section class="addCampaignFullSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignFullLegend">适用范围</el-col>
                        <el-col :span="20">
                            <el-form-item prop="scope">
                                <div class="df ai_c mt_2">
                                    <div class="addCampaignFullCard" style="cursor: default" :class="{
                      addCampaignFullSelectedCard:
                        editCampaignFullForm.scope == 1,
                    }">
                                        <h4>选择全场商品</h4>
                                        <p style="margin-top: 10px">所有商品可使用优惠码</p>
                                        <div class="addCampaignFullCardChecked" v-if="editCampaignFullForm.scope == 1">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </div>
                                    <div class="addCampaignFullCard" style="cursor: default" :class="{
                      addCampaignFullSelectedCard:
                        editCampaignFullForm.scope == 2,
                    }">
                                        <h4>选择部分商品</h4>
                                        <p v-if="editCampaignFullForm.scope == 1">
                                            仅限部分商品或款式可使用<br />优惠码
                                        </p>
                                        <p v-if="editCampaignFullForm.scope == 2">
                                            已选择{{ selectedGoodsTable.total }}件商品
                                            <!-- <el-button type="text" icon="el-icon-plus" style="padding-top: 4px; padding-bottom: 4px;" @click="openSelectGoods">继续新增商品</el-button> -->
                                        </p>
                                        <div class="addCampaignFullCardChecked" v-if="editCampaignFullForm.scope == 2">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <!-- 数据表 -->
                    <el-table ref="selectedGoodsTable" class="mt_3" stripe v-if="
              editCampaignFullForm.scope == 2 && selectedGoodsTable.total > 0
            " :data="selectedGoodsTable.data">
                        <el-table-column label="商品图片" width="80">
                            <template slot-scope="scope">
                                <!-- <el-image fit="contain" style="width: 40px; height: 40px" :src="scope.row.goodsImages[ 0 ]">
                                    <div slot="error" class="el-image__error" style="width: 40px; height: 40px; font-size: 10px">
                                        无图
                                    </div>
                                </el-image> -->

                                <GoodsImageTemplate :images="scope.row.goodsImages" width="40" height="40"></GoodsImageTemplate>

                            </template>
                        </el-table-column>
                        <el-table-column prop="goodsTitle" label="商品名称" min-width="80"></el-table-column>
                        <el-table-column label="售价/原价" min-width="80">
                            <template slot-scope="scope">
                                {{ scope.row.price }} / {{ scope.row.compareAtPrice }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="inventoryQuantity" label="库存" min-width="80"></el-table-column>
                        <el-table-column label="是否上架" min-width="80">
                            <template slot-scope="scope">
                                {{ getPublished(scope.row.published) }}
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center">
                            <template slot-scope="scope">
                                <el-button size="mini" type="text" disabled @click="deleteSelectedGoods(scope.row)">退出活动</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </section>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeEdit">取消</el-button>
                <el-button type="primary" :loading="btnLoading" v-if="editCampaignFullForm.campaignStatus != 3" @click="editCampaignFull">保存</el-button>
            </div>
        </el-dialog>

        <el-dialog fullscreen title="满减数据明细" :visible.sync="dialogDetail" :close-on-click-modal="false">
            <p>满减活动数据自2022年12月17日开始统计</p>
            <el-form :inline="true" :model="detailForm" class="demo-form-inline">
                <el-form-item>
                    <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="detailDate" :picker-options="pickerOptionsA" :default-value="new Date()" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="searchDetail" :loading="detailLoading">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="resetDetail">重置</el-button>
                </el-form-item>
            </el-form>
            <section class="detailitems between">
                <div class="item">
                    <div class="tit">订单量</div>
                    <div class="val">{{detailData.orderSuccessNum}}单</div>
                </div>
                <div class="item">
                    <div class="tit">优惠金额</div>
                    <div class="val">${{detailData.discountPrice}}</div>
                </div>
                <div class="item">
                    <div class="tit">订单金额</div>
                    <div class="val">${{detailData.total}}</div>
                </div>
                <div class="item">
                    <div class="tit">平均客单价<el-tooltip effect="dark" content="总成交额/总订单量" placement="top"><i class="el-icon-question"></i></el-tooltip></div>
                    <div class="val">${{detailData.customerAvgPrice}}</div>
                </div>
                <div class="item">
                    <div class="tit">连带率<el-tooltip effect="dark" content="平均每笔订单的商品数量，所有已支付的订单的商品数量/所有已支付的订单的数量" placement="top"><i class="el-icon-question"></i></el-tooltip></div>
                    <div class="val">{{detailData.relatedRate}}</div>
                </div>
            </section>

            <!-- 数据表 -->
            <el-table border stripe ref="detailTable" :data="detailTable" v-loading="detailLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" max-height="600">
                <el-table-column prop="campaignName" label="活动名称"></el-table-column>
                <el-table-column prop="orderSuccessNum" label="订单量"></el-table-column>
                <el-table-column prop="discountPrice" label="优惠金额($)"></el-table-column>
                <el-table-column prop="total" label="订单金额($)"></el-table-column>
                <el-table-column prop="customerAvgPrice" label="客单价($)"></el-table-column>
                <el-table-column prop="relatedRate" label="连带率"></el-table-column>
            </el-table>
            <el-pagination background :current-page.sync="detailForm.pageNo" :page-sizes="[20, 50, 100]" :page-size.sync="detailForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="detailTotal" @size-change="getDetail" @current-change="getDetail">
            </el-pagination>
        </el-dialog>

        <!-- 插入图片 -->
        <ImageUploader ref="uploadImage" :insertImage="dialogInsertImage" :insertType="insertType" :activeName="activeName" @getOpenState="getOpenState" @getImage="getImage">
        </ImageUploader>

        <!-- 选择商品 -->
        <SelectGoods ref="selectGoods" :showDialog="dialogSelectGoods" :containItem="false" :selectedGoods="selectedGoodsTable.data" @closeDialog="closeSelectGoods" @saveSelectedGoods="saveSelectGoods">
        </SelectGoods>
    </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader';
import SelectGoods from '@/components/SelectGoods';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';

export default {
    name: 'CampaignFull',

    components: {
        ImageUploader: ImageUploader,
        SelectGoods: SelectGoods,
        GoodsImageTemplate,
    },

    data() {
        return {
            countdown: {
                type: '',
                time: null,
                unitCn: null,
                unitEn: null,
            },
            dialogDetail: false,
            detailForm: {
                pageNo: 1,
                pageSize: 20,
                startDate: null,
                endDate: null,
                campaignName: null,
                campaignStatus: null,
            },
            detailDate: [],
            pickerOptionsA: {
                disabledDate: (time) => {
                    var lasttime = Date.parse('2022-12-16');
                    // return time.getTime() < lasttime;
                    // let futrue = time.getTime() > Date.now()
                    return (
                        time.getTime() <= lasttime ||
                        time.getTime() > Date.now()
                    );
                },
            },
            detailData: {
                orderSuccessNum: null,
                customerAvgPrice: null,
                discountPrice: null,
                relatedRate: null,
                total: null,
            },
            detailTable: [],
            detailTotal: 0,
            detailLoading: false,
            btnLoading: false,

            // 插入图片参数
            dialogInsertImage: false,
            activeName: 'first',
            insertType: null,

            // 选择商品参数
            dialogSelectGoods: false,

            /* 类型集合 */
            campaignStatusTypes: [
                // 活动状态

                { name: '未开始', value: '1' },
                { name: '进行中', value: '2' },
                { name: '已结束', value: '3' },
            ],

            // 列表查询参数
            campaignStatus: '0',
            queryForm: {
                campaignStatus: null,
                campaignName: null,
                pageNo: 1,
                pageSize: 10,
            },

            // 列表数据
            loading: false,
            tableData: [],
            total: 0,

            // 新增优惠码活动
            dialogAddCampaignFull: false,
            addCampaignFullForm: {},
            addCampaignFullEndTime: false, // 不限结束时间
            addCampaignFullRules: {
                campaignName: [
                    { required: true, message: '活动名称', trigger: 'blur' },
                ],
            },

            // 编辑优惠码活动
            dialogEditCampaignFull: false,
            editCampaignFullForm: {},
            editCampaignFullEndTime: false, // 不限结束时间
            editCampaignFullRules: {
                campaignName: [
                    { required: true, message: '活动名称', trigger: 'blur' },
                ],
            },

            discountTypeOptions: [
                { name: '满额减元', value: '1' },
                { name: '满件打折', value: '2' },
                { name: '满件减元', value: '3' },
                { name: '满额打折', value: '4' },
                { name: '满件定价', value: '5' },
                { name: '满件减免', value: '6' },
            ],

            // 优惠层级
            discountLevel: [{ full: '', sub: '' }],
            discountLevelFullUnit: {
                // full unit
                1: 'USD',
                2: '件',
                3: '件',
                4: 'USD',
                5: '件',
                6: '件',
            },
            discountLevelSubUnit: {
                // sub unit
                1: 'USD',
                2: '% off',
                3: 'USD',
                4: '% off',
                5: 'USD ',
                6: '件',
            },

            // 选中的商品列表数据
            selectedGoodsTable: {
                data: [],
                total: 0,
            },
        };
    },

    mounted() {
        this.queryData();
    },

    destroyed() {
        this.clearCountDown();
    },

    computed: {
        // 新增优惠码下一步
        addCampaignFullNextStepDisabled() {
            return (
                this.addCampaignFullForm.campaignName == null ||
                this.addCampaignFullForm.campaignName == ''
            );
        },

        // 编辑优惠码下一步
        editCampaignFullNextStepDisabled() {
            return (
                this.editCampaignFullForm.campaignName == null ||
                this.editCampaignFullForm.campaignName == ''
            );
        },
    },

    methods: {
        setMaxNum(a, b, type, index) {
            if (type == 6) {
                console.log(a, b, index);

                if (
                    a != '' &&
                    (parseFloat(a).toString() == 'NaN' ||
                        Number(a).toString() == 'NaN')
                ) {
                    this.$message.warning('请输入数字。');
                    this.discountLevel[index].full = '';
                    return false;
                }

                if (
                    b != '' &&
                    (parseFloat(b).toString() == 'NaN' ||
                        Number(b).toString() == 'NaN')
                ) {
                    this.$message.warning('请输入数字。');
                    this.discountLevel[index].sub = '';
                    return false;
                }

                if (a.indexOf('.') > -1) {
                    this.$message.warning('只能输入整数。');
                    this.discountLevel[index].full = '';
                    return false;
                }

                if (b.indexOf('.') > -1) {
                    this.$message.warning('只能输入整数。');
                    this.discountLevel[index].sub = '';
                    return false;
                }

                if (parseInt(b) >= parseInt(a)) {
                    // b = ''
                    this.discountLevel[index].sub = '';
                    this.$message.warning('不能大于等于满件数。');
                    this.$forceUpdate();
                    return false;
                }
            }
        },
        getToday() {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }
            let result = year + '-' + month + '-' + day;
            this.detailForm.startDate = result;
            this.detailForm.endDate = result;
            this.detailDate = [result, result];
        },
        openStatic() {
            this.dialogDetail = true;
            this.loading = true;
            this.getToday();
            this.detailForm.pageNo = 1;
            this.getStaticTotal();
            if (this.$refs.detailTable) {
                this.$refs.detailTable.clearSort();
            }
        },
        getStaticTotal() {
            this.detailForm.startDate = this.detailDate[0];
            this.detailForm.endDate = this.detailDate[1];
            this.detailForm.campaignName = this.queryForm.campaignName;
            this.detailForm.campaignStatus = this.queryForm.campaignStatus;

            this.$http
                .get('/campaign/full/getStatisticTotal', {
                    params: this.detailForm,
                })
                .then((r) => {
                    if (r.data.code === 200) {
                        let item = r.data.data;
                        this.detailData = item;
                        this.getDetail();
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getDetail() {
            this.dialogDetail = true;
            this.detailForm.startDate = this.detailDate[0];
            this.detailForm.endDate = this.detailDate[1];
            this.detailLoading = true;
            await this.$http
                .get('/campaign/full/getStatisticItem', {
                    params: this.detailForm,
                })
                .then((r) => {
                    if (r.data.code === 200) {
                        this.detailTable = r.data.data;
                        this.detailTotal = r.data.count;
                    }
                })
                .finally(() => {
                    this.detailLoading = false;
                });
        },
        searchDetail() {
            this.$nextTick(() => {
                this.$refs.detailTable.clearSort();
            });
            this.detailForm.pageNo = 1;
            this.getStaticTotal();
        },
        resetDetail() {
            this.detailForm.pageNo = 1;
            this.getToday();
            this.getStaticTotal();
            this.$refs.detailTable.clearSort();
        },
        // 插入图片方法
        openImageUploader(obj) {
            this.insertType = obj;
            this.dialogInsertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.dialogInsertImage = result;
        },
        getImage(obj, image) {
            switch (obj) {
                case 'addCampaignFullForm.banner':
                    this.addCampaignFullForm.banner = image[image.length - 1];
                    break;

                case 'editCampaignFullForm.banner':
                    this.editCampaignFullForm.banner = image[image.length - 1];
                    break;
            }
        },

        // 选择商品方法
        openSelectGoods() {
            // this.$http
            // 	.get("/goods/getCampaignEndGoods")
            // 	.then((r) => {
            // 	  if (r.data.code === 200) {
            // 		this.endGoods = r.data.data
            // 		console.log(this.endGoods)
            // 	  }
            // 	})
            // 	.catch((err) => {
            // 	});

            this.$refs.selectGoods.caller = 'notset';
            this.$refs.selectGoods.init();
            this.dialogSelectGoods = true;
        },
        closeSelectGoods() {
            this.dialogSelectGoods = false;
        },
        saveSelectGoods(items, caller) {
            switch (caller) {
                default:
                    this.selectedGoodsTable.data = items;
                    this.selectedGoodsTable.total =
                        this.selectedGoodsTable.data.length;
            }
        },

        // 获取列表数据
        queryData() {
            if (this.campaignStatus == '0')
                this.queryForm.campaignStatus = null;
            else this.queryForm.campaignStatus = this.campaignStatus;

            this.loading = true;

            this.$http
                .get('/campaign/full/getPage', { params: this.queryForm })
                .then((r) => {
                    this.loading = false;

                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        this.total = r.data.count;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    this.loading = false;
                });
        },

        // 重置列表查询条件
        resetCondition() {
            this.queryForm.campaignName = null;
            this.queryForm.pageNo = 1;

            this.queryData();
        },

        // 删除选中的商品（退出活动）
        deleteSelectedGoods(item) {
            let index = this.selectedGoodsTable.data.indexOf(item);

            if (index > -1) {
                this.selectedGoodsTable.data.splice(index, 1);
                this.selectedGoodsTable.total =
                    this.selectedGoodsTable.data.length;
            }
        },

        // 新增满减活动
        initAddCampaignFull() {
            this.addCampaignFullForm = {
                discountLevel: [], // 优惠层级

                campaignName: null,
                startTime: null,
                endTime: null,
                discountType: '1',
                scope: 1,
                goodsItemCodes: null,

                banner: null,
                bannerShow: '1',
                isBox: '0',
                isFullBanner: '0',
                fullBannerColour: '',
                fullBannerShow: '1',
                countDownColour: '',
                isTagsOpen: '0',
                campaignTag: null,
            };

            this.addCampaignFullEndTime = false; // 不限结束时间
            this.discountLevel = [{ full: '', sub: '' }]; // 优惠层级数据
            this.selectedGoodsTable.data = []; // 选中的商品
            this.selectedGoodsTable.total = 0;
            this.dialogAddCampaignFull = true;
        },
        addCampaignFull() {
            this.$refs.addCampaignFullForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;

                    let fullUnit =
                            this.discountLevelFullUnit[
                                this.addCampaignFullForm.discountType
                            ],
                        subUint =
                            this.discountLevelSubUnit[
                                this.addCampaignFullForm.discountType
                            ];

                    this.addCampaignFullForm.discountLevel = []; // 清空旧数据
                    this.discountLevel.forEach((item) => {
                        this.addCampaignFullForm.discountLevel.push({
                            full: item.full,
                            fullUnit: fullUnit,
                            sub: item.sub,
                            subUnit: subUint,
                        });
                    });

                    this.addCampaignFullForm.goodsIds =
                        this.selectedGoodsTable.total > 0
                            ? this.selectedGoodsTable.data.map(
                                  (item) => item.id
                              )
                            : null;
                    this.$http
                        .post('/campaign/full/add', this.addCampaignFullForm)
                        .then((r) => {
                            this.btnLoading = false;

                            if (r.data.code === 200) {
                                this.dialogAddCampaignFull = false;

                                this.$message({
                                    message: '新增成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: this.queryData,
                                });
                            }
                        })
                        .catch((err) => {
                            this.btnLoading = false;
                        });
                }
            });
        },

        // 新增满减活动 - 新增层级
        addDiscountLevel() {
            this.discountLevel.push({ full: '', sub: '' });
        },

        // 新增满减活动 - 删除层级
        deleteDiscountLevel(index) {
            // console.log(index)
            this.discountLevel.splice(index, 1);
            // console.log(this.discountLevel)
        },
        setCountDownTime(status, start, end) {
            let now = new Date().getTime();
            let endTime = null;
            let str = '';
            if (status == 1) {
                endTime = new Date(start).getTime();
                str = '开始';
            }
            if (status == 2) {
                endTime = new Date(end).getTime();
                str = '结束';
            }
            // console.log(now, endTime)
            let time = endTime - now;
            let finish = this.getTime(time);
            // console.log(finish)
            this.countdown = {
                type: str,
                time: finish ? finish.value : 0,
                unitCn: finish ? finish.unitCn : '',
                unitEn: finish ? finish.unitEn : '',
            };
        },
        clearCountDown() {
            clearInterval(window.refreshData);
        },
        getTime(time) {
            let ts = time / 1000;
            console.log(ts);
            if (ts > 0) {
                // console.log('aaa')
                let dd = parseInt(ts / 60 / 60 / 24, 10);
                let hh = parseInt((ts / 60 / 60) % 24, 10);
                let mm = parseInt((ts / 60) % 60, 10);
                let ss = parseInt(ts % 60, 10);
                let arr = [
                    { unitCn: '天', value: dd, unitEn: 'days' },
                    { unitCn: '小时', value: hh, unitEn: 'hours' },
                    { unitCn: '分钟', value: mm, unitEn: 'minutes' },
                    { unitCn: '秒', value: ss, unitEn: 'seconds' },
                ];
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].value >= 1) {
                        return arr[i];
                    }
                }
            } else {
                console.log('bbb', this.editCampaignFullForm.campaignStatus);
                this.clearCountDown();
                if (this.editCampaignFullForm.campaignStatus == 1) {
                    this.editCampaignFullForm.campaignStatus = 2;
                    this.setCountDownTime(
                        this.editCampaignFullForm.campaignStatus,
                        this.editCampaignFullForm.startTime,
                        this.editCampaignFullForm.endTime
                    );
                    window.refreshData = setInterval(() => {
                        this.setCountDownTime(
                            2,
                            this.editCampaignFullForm.startTime,
                            this.editCampaignFullForm.endTime
                        );
                    }, 1000);
                    return;
                }
                if (this.editCampaignFullForm.campaignStatus == 2) {
                    // this.editCampaignFullForm.campaignStatus = 3
                    return { unitCn: '秒', value: 0, unitEn: 'seconds' };
                }
                // return {unitCn: '', value: 0, unitEn: ''}
            }
        },
        closeEdit() {
            this.clearCountDown();
            this.dialogEditCampaignFull = false;
        },
        // 编辑优惠码
        initEditCampaignFull(id) {
            this.$http
                .get('/campaign/full/getDetail?id=' + id)
                .then((r) => {
                    if (r.data.code === 200) {
                        let item = r.data.data;
                        this.editCampaignFullForm = {
                            id: id,
                            campaignName: item.campaignName,
                            startTime: item.startTime, // 不可修改
                            endTime: item.endTime,
                            discountType: item.discountType.toString(), // 不可修改
                            // discountType: item.discountType,
                            discountLevel: item.discountLevel, // 优惠层级
                            scope: item.scope, // 不可修改
                            goodsItemCodes: null, // 不可修改

                            banner: item.banner,
                            // bannerShow: item.bannerShow.toString(),
                            // isBox: item.isBox.toString(),
                            // isFullBanner: item.isFullBanner.toString(),
                            fullBannerColour: item.fullBannerColour,
                            // fullBannerShow: item.fullBannerShow.toString(),
                            // countDownColour: item.countDownColour,
                            // isTagsOpen: item.isTagsOpen.toString(),
                            campaignTag: item.campaignTag,
                            campaignStatus: item.campaignStatus,
                        };
                        this.editCampaignFullEndTime = this.editCampaignFullForm
                            .endTime
                            ? false
                            : true; // 不限结束时间

                        // 优惠层级
                        this.discountLevel = [];
                        this.editCampaignFullForm.discountLevel.forEach((v) => {
                            this.discountLevel.push({
                                full: v.full,
                                fullUnit: v.fullUnit,
                                sub: v.sub,
                                subUnit: v.subUnit,
                            });
                        });

                        this.setCountDownTime(
                            this.editCampaignFullForm.campaignStatus,
                            this.editCampaignFullForm.startTime,
                            this.editCampaignFullForm.endTime
                        );

                        window.refreshData = setInterval(() => {
                            this.setCountDownTime(
                                this.editCampaignFullForm.campaignStatus,
                                this.editCampaignFullForm.startTime,
                                this.editCampaignFullForm.endTime
                            );
                        }, 1000);

                        console.log('discountLevel', this.discountLevel);

                        this.editCampaignFullForm.discountLevel = [];
                        console.log(this.editCampaignFullForm.discountLevel);
                        if (item.scope == 1) {
                            this.selectedGoodsTable.data = []; // 选中的商品
                            this.selectedGoodsTable.total = 0;
                        } else {
                            this.selectedGoodsTable.data = item.goodsList; // 选中的商品
                            this.selectedGoodsTable.total =
                                item.goodsList.length;
                        }
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                });

            this.dialogEditCampaignFull = true;
        },
        editCampaignFull() {
            this.$refs.editCampaignFullForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;

                    let fullUnit =
                            this.discountLevelFullUnit[
                                this.editCampaignFullForm.discountType
                            ],
                        subUint =
                            this.discountLevelSubUnit[
                                this.editCampaignFullForm.discountType
                            ];

                    this.editCampaignFullForm.discountLevel = [];

                    this.discountLevel.forEach((item) => {
                        this.editCampaignFullForm.discountLevel.push({
                            full: item.full,
                            fullUnit: fullUnit,
                            sub: item.sub,
                            subUnit: subUint,
                        });
                    });

                    console.log(
                        this.discountLevel,
                        this.editCampaignFullForm.discountLevel
                    );

                    this.editCampaignFullForm.goodsIds =
                        this.selectedGoodsTable.total > 0
                            ? this.selectedGoodsTable.data.map(
                                  (item) => item.id
                              )
                            : null;

                    this.$http
                        .post('/campaign/full/edit', this.editCampaignFullForm)
                        .then((r) => {
                            this.btnLoading = false;

                            if (r.data.code === 200) {
                                this.dialogEditCampaignFull = false;
                                this.clearCountDown();

                                this.$message({
                                    message: '编辑成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: this.queryData,
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = false;
                        });
                }
            });
        },

        // 提前结束满减活动
        stopCampaignFull(item) {
            this.$confirm('确定要结束满减活动吗？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.$http
                    .get('/campaign/full/end', { params: { id: item.id } })
                    .then((r) => {
                        if (r.data.code === 200) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1000,
                                onClose: this.queryData,
                            });
                        }
                    })
                    .catch((err) => {
                        // this.$message.error(error);;
                    });
            });
        },

        // 删除满减活动
        deleteCampaignFull(item) {
            this.$confirm('确定要删除满减活动吗？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.$http
                    .delete('/campaign/full/delete', {
                        params: { id: item.id },
                    })
                    .then((r) => {
                        if (r.data.code === 200) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                                duration: 1000,
                                onClose: this.queryData,
                            });
                        }
                    })
                    .catch((err) => {
                        // this.$message.error(error);;
                    });
            });
        },
        getPublished(status) {
            switch (status) {
                case 0:
                    return '否';
                case 1:
                    return '是';
            }
        },
    },
};
</script>

<style scoped>
.countdownbox {
    position: absolute;
    bottom: -30px;
    right: 0;
    font-size: 20px;
	font-family: 'Rajdhani';
}
.countdownbox p {
    margin: 0;
}
.countdownbox .top,
.countdownbox .bottom .a {
    font-size: 20px;
    font-weight: 600;
}
.countdownbox .bottom {
    margin-left: 50px;
    padding-left: 10px;
    position: relative;
}
.countdownbox .bottom::before {
	content: '';
	position:absolute;
	left: 0;
	top: 7px;
	height: 50px;
	width: 4px;
	background-color: #ee0000;
}
.countdownbox .bottom .a,
.countdownbox .bottom .b {
    transform: translateY(-26px);
}
.countdownbox .bottom .b {
    font-size: 14px;
}
.countdownbox .bottom .red {
	color: #ee0000;
	font-size: 68px;
	display: inline-block;
	padding: 0 5px;
}
.countdownbox .finish {
    color: #f00;
}
.detailitems {
    margin: 40px 0;
}
.detailitems .item {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    width: calc(100% / 5 - 20px);
    box-sizing: border-box;
}
.detailitems .item .tit {
    font-size: 18px;
}
.detailitems .item .val {
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
}
.addCampaignFullSection {
    margin-bottom: 16px;
    padding: 16px 32px;
    background-color: #fff;
}

.addCampaignFullLegend {
    font-size: 20px;
    font-weight: bold;
}

/* 适用范围卡片 */
.addCampaignFullCard {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    box-sizing: border-box;
    border: solid 1px #ecedf3;
    border-radius: 6px;
    width: 200px;
    height: 100px;
    cursor: pointer;
    overflow: hidden;
}

.addCampaignFullCard > h4 {
    margin: 5px 16px;
}

.addCampaignFullCard > p {
    margin: 0 16px;
    color: #999;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
}

.addCampaignFullSelectedCard {
    border: solid 2px #2d67f3;
    background: #f3f7ff;
}

.addCampaignFullCardChecked {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 50px;
    height: 50px;
    background-color: #2d67f3;
    transform: rotate(45deg);
}

.addCampaignFullCardChecked > i {
    position: absolute;
    top: 31px;
    left: 16px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    transform: rotate(-45deg);
}

/* Banner图片 */
.BannerImageBox {
    margin-right: 20px;
    margin-bottom: 20px;
    width: 320px !important;
    height: 176px !important;
    background-color: #f5f6f8;
}

.BannerImage {
    max-width: 320px;
    max-height: 176px;
}

.BannerImageBox > .mask_d5 {
    display: none;
}

.BannerImageBox:hover > .mask_d5 {
    display: block;
}

/* 方案设置项 */
.DemoBox {
    display: flex;
    align-items: center;
    margin: 16px 0;
    padding: 0 24px;
    height: 80px;
}

.DemoBox > p {
    font-size: 18px;
}

.DemoBox_Time {
    border-radius: 6px;
    padding: 4px 10px;
    font-size: 20px;
}

.DemoColorRow {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.DemoColorTitle {
    width: 300px;
}
</style>
<style>
#addCampaignFullDialog > .el-dialog,
#editCampaignFullDialog > .el-dialog {
    background: #ecedf3;
}
</style>
