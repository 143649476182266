<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>召回订单</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 查询表单 -->
        <el-tabs v-model="recallStatus" @tab-click="queryData">
            <!-- <el-tab-pane label="全部" name="0"></el-tab-pane> -->
            <el-tab-pane v-for="item in statusTypes" :key="item.value" :label="item.name" :name="item.id"></el-tab-pane>
        </el-tabs>

        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input class="order-list-query-keyword" placeholder="关键字" v-model="queryKeyword">
                    <el-select slot="prepend" placeholder="类型" clearable v-model="queryType">
                        <el-option v-for="item in queryTypes" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-input>
            </el-form-item>
            <el-form-item style="width: 120px">
                <el-select placeholder="支付状态" clearable v-model="queryForm.financialStatus">
                    <el-option v-for="item in financialStatusTypes" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item style="width: 120px">
                <el-select placeholder="支付方式" clearable v-model="queryForm.paymentMethod">
                    <el-option v-for="item in paymentMethodTypes" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
			<el-form-item>
			    <el-select v-model="queryForm.channelSource" placeholder="渠道来源" clearable>
			        <el-option v-for="(item, index) in channelSourceOptions" :label="item.name" :value="item.value"></el-option>
			    </el-select>
			</el-form-item>
			
			<div class="flex">
				<div>
					<el-form-item style="color: #606266">&nbsp;创建时间</el-form-item>
					<el-form-item>
						<el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="开始时间" style="width: 140px" v-model="queryForm.startTime">
						</el-date-picker>
					</el-form-item>
					<el-form-item style="color: #bbb">～</el-form-item>
					<el-form-item>
						<el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="结束时间" style="width: 140px" v-model="queryForm.endTime">
						</el-date-picker>
					</el-form-item>
				</div>
				
				<div v-if="recallStatus == 1 || recallStatus == 2  || recallStatus == 3  || recallStatus == 4 ">
					<el-form-item style="color: #606266">&nbsp;召回时间</el-form-item>
					<el-form-item>
						<el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="开始时间" style="width: 140px" v-model="queryForm.startRecallTime">
						</el-date-picker>
					</el-form-item>
					<el-form-item style="color: #bbb">～</el-form-item>
					<el-form-item>
						<el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="结束时间" style="width: 140px" v-model="queryForm.endRecallTime">
						</el-date-picker>
					</el-form-item>
				</div>
				
				<div v-if="recallStatus == 2 || recallStatus == 4">
					<el-form-item style="color: #606266">&nbsp;成功下单时间</el-form-item>
					<el-form-item>
						<el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="开始时间" style="width: 140px" v-model="queryForm.payStartTime">
						</el-date-picker>
					</el-form-item>
					<el-form-item style="color: #bbb">～</el-form-item>
					<el-form-item>
						<el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="结束时间" style="width: 140px" v-model="queryForm.payEndTime">
						</el-date-picker>
					</el-form-item>
				</div>
			</div>
			
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-refresh-left" @click="resetCondition">重置</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-position" @click="sendEmailBoth" v-show="recallStatus == '0' || recallStatus == '3' ">批量重新发送邮件</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表 -->
        <el-table ref="orderListTable" border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" :data="tableData" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="60" v-if="recallStatus == '0' || recallStatus == '3' ">
            </el-table-column>
            <el-table-column label="订单号" fixed>
                <template slot-scope="scope">
                    {{ scope.row.orderNo }}
                    <i class="el-icon-document-copy bold_i cp" title="复制" style="color: #000" v-clipboard:copy="scope.row.orderNo" v-clipboard:success="onCopySuccess" v-clipboard:error="onCopyError">
                    </i>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="120"></el-table-column>
            <el-table-column label="收货人">
                <template slot-scope="scope">
                    {{ scope.row.shippingFirstName }} {{ scope.row.shippingLastName }}
                </template>
            </el-table-column>
            <el-table-column label="支付渠道" prop="paymentChannel"></el-table-column>
            <el-table-column label="支付状态">
                <template slot-scope="scope">
                    {{
                        financialStatusTypes.find(
                        (v) => v.value == scope.row.financialStatus
                        ).name
                    }}
                </template>
            </el-table-column>
            <el-table-column label="订单总价">
                <template slot-scope="scope">
                    {{ $yrFormatNumber(scope.row.totalPrice, ",", 2) }}USD
                </template>
            </el-table-column>
            <el-table-column label="国家/地区" prop="shippingCountryCn"></el-table-column>
            <el-table-column label="订单来源" prop="orderSource"></el-table-column>
            <el-table-column label="优惠码" prop="discountCode"></el-table-column>
            <el-table-column label="召回状态">
                <template slot-scope="scope">
                    {{ statusArr[scope.row.recallStatus -1 ] }}
                </template>
            </el-table-column>
            <!-- <el-table-column label="礼品卡卡号">
                <template #default="{row}">
                    <div v-html="row.shopOrderGifts && row.shopOrderGifts.map((item)=>{ return item['giftCardNo']}).join('<br>')"></div>
                </template>
            </el-table-column> -->

            <el-table-column label="操作" fixed="right" width="140" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" icon="el-icon-document" @click="getOrderDetail(scope.row)">详情</el-button>
                    <!--  v-show="scope.row.recallStatus == 3" -->
                    <el-button type="text" size="mini" icon="el-icon-s-promotion" @click="sendMaill(scope.row)" v-show="scope.row.recallStatus == 1 || scope.row.recallStatus == 4">
                        重新发送邮件
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <!-- 新增运单 -->
        <el-dialog top="3%" title="新增运单" id="deliveryDialog" width="750px" :visible.sync="dialogDelivery" :close-on-click-modal="false">
            <!-- 1: 选择商品 -->
            <section class="px_3" :class="{ pb_4: deliveryShowGoods }" style="background-color: #fff">
                <header class="df ai_c">
                    <h3>选择商品</h3>
                    <el-checkbox class="ml_3" :indeterminate="deliveryGoodsIndeterminate" v-model="deliveryGoodsCheckAll" @change="deliverySelectAll($event)">全选</el-checkbox>
                    <i class="ml_a cp el-icon-arrow-down" style="font-size: 22px" v-if="deliveryShowGoods" @click="deliveryShowGoods = false"></i>
                    <i class="ml_a cp el-icon-arrow-right" style="font-size: 22px" v-else @click="deliveryShowGoods = true"></i>
                </header>
                <!-- 物流商品列表 -->
                <table ref="deliveryGoodsList" class="deliveryGoodsList" v-show="deliveryShowGoods">
                    <tr v-for="row in deliveryGoods" :key="row.id">
                        <td style="width: 50px">
                            <el-checkbox v-model="row.isChecked" @change="deliverySelect($event, row)"></el-checkbox>
                        </td>
                        <td style="width: 80px">
                            <el-image fit="contain" style="width: 64px; height: 64px" :src="row.goodsImage">
                                <div slot="error" class="el-image__error" style="width: 64px; height: 64px; font-size: 10px">
                                    无图
                                </div>
                            </el-image>
                        </td>
                        <td>
                            <div class="tl" style="line-height: 24px">
                                {{ row.goodsTitle }}<br />
                                <span style="color: #909399">
                                    <span v-if="row.goodsOption">Style: {{ row.goodsOption["1"] }}-{{ row.goodsOption["2"]
                    }}<br /></span>
                                    SKU: {{ row.sku }}
                                </span>
                            </div>
                        </td>
                        <td>
                            <div class="pr_3 tr">
                                {{ $yrFormatNumber(row.weight, ",", 2) }}{{ row.weighUnit }}/件
                            </div>
                        </td>
                        <td style="width: 100px">
                            <div class="pr_3 tr">&times; {{ row.quantity }}</div>
                        </td>
                    </tr>
                </table>
            </section>

            <!-- 2: 收货地址 -->
            <section class="mt_3 px_3" :class="{ pb_4: deliveryShowAddress }" style="background-color: #fff">
                <header class="df ai_c">
                    <h3>收货信息</h3>
                    <i class="ml_3 el-icon-edit cp" style="font-size: 22px" v-if="deliveryShowAddress && hideShipAddressForm" @click="initShipAddress(true)"></i>
                    <i class="ml_a cp el-icon-arrow-down" style="font-size: 22px" v-if="deliveryShowAddress" @click="deliveryShowAddress = false"></i>
                    <i class="ml_a cp el-icon-arrow-right" style="font-size: 22px" v-else @click="deliveryShowAddress = true"></i>
                </header>
                <template v-if="deliveryShowAddress">
                    <p class="mt_0 pt_3" style="border-top: solid 1px #e8ebf3; line-height: 36px" v-if="hideShipAddressForm">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                姓：{{
                  orderDetail.data ? orderDetail.data.shippingLastName : ""
                }}<br />
                                电话：+{{
                  orderDetail.data ? orderDetail.data.shippingPhoneCode : ""
                }}
                                {{ orderDetail.data ? orderDetail.data.shippingPhone : ""
                }}<br />
                                国家/地区：{{
                  orderDetail.data ? orderDetail.data.shippingCountryEn : ""
                }}<br />
                                城市：{{
                  orderDetail.data ? orderDetail.data.shippingCity : ""
                }}
                            </el-col>
                            <el-col :span="12">
                                名：{{
                  orderDetail.data ? orderDetail.data.shippingFirstName : ""
                }}<br />
                                邮箱：{{ orderDetail.data ? orderDetail.data.shippingEmail : ""
                }}<br />
                                省份：{{
                  orderDetail.data ? orderDetail.data.shippingProvinceEn : ""
                }}<br />
                                邮编：{{ orderDetail.data ? orderDetail.data.shippingZip : "" }}
                            </el-col>
                        </el-row>
                        地址：{{ orderDetail.data ? orderDetail.data.shippingAddress : "" }}
                    </p>
                    <!-- 收货信息表单 -->
                    <el-form size="small" class="pt_3" style="border-top: solid 1px #e8ebf3" v-else :model="orderDetail.shipAddressForm">
                        <el-row :gutter="12">
                            <el-col :span="12">
                                <el-form-item prop="shippingLastName">
                                    <el-input placeholder="名" v-model="orderDetail.shipAddressForm.shippingLastName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item prop="shippingFirstName">
                                    <el-input placeholder="姓" v-model="orderDetail.shipAddressForm.shippingFirstName"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="4">
                            <el-col :span="10">
                                <el-form-item prop="shippingPhoneCode">
                                    <el-select v-model="orderDetail.shipAddressForm.shippingPhoneCode" placeholder="区号">
                                        <el-option v-for="item in orderDetail.phoneCodeData" :key="item.id" :label="item.cnNameFormat" :value="item.phoneCode"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <el-form-item prop="shippingPhone">
                                    <el-input maxlength="11" placeholder="手机号" v-model="orderDetail.shipAddressForm.shippingPhone"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item prop="shippingEmail">
                            <el-input maxlength="50" placeholder="邮箱" v-model="orderDetail.shipAddressForm.shippingEmail"></el-input>
                        </el-form-item>
                        <el-row :gutter="12">
                            <el-col :span="12">
                                <el-form-item prop="shippingCountryId">
                                    <el-select v-model="orderDetail.shipAddressForm.shippingCountryId" placeholder="国家" @change="changeShipCountry($event)">
                                        <el-option v-for="item in orderDetail.phoneCodeData" :key="item.id" :label="item.enName" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item prop="shippingProvinceId">
                                    <el-select v-model="orderDetail.shipAddressForm.shippingProvinceId" placeholder="省(州)">
                                        <el-option v-for="item in orderDetail.provinceData" :key="item.id" :label="item.enName" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item prop="shippingCity">
                            <el-input maxlength="50" placeholder="城市" v-model="orderDetail.shipAddressForm.shippingCity"></el-input>
                        </el-form-item>
                        <el-form-item prop="shippingAddress">
                            <el-input maxlength="50" placeholder="地址" v-model="orderDetail.shipAddressForm.shippingAddress"></el-input>
                        </el-form-item>
                        <el-form-item prop="shippingZip">
                            <el-input maxlength="10" placeholder="邮编" v-model="orderDetail.shipAddressForm.shippingZip"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" :loading="btnLoading == 'saveShipAddress'" @click="saveShipAddress">保存</el-button>
                            <el-button @click="cancelEditAddress">取消</el-button>
                        </el-form-item>
                    </el-form>
                </template>
            </section>

            <!-- 3: 运单信息 -->
            <section class="mt_3 px_3 pb_3" style="background-color: #fff">
                <header class="df ai_c">
                    <h3>运单信息</h3>
                    <p class="ml_3" style="color: #909399">
                        该顾客选择的配送方式是：{{
              orderDetail.data ? orderDetail.data.logisticsFreightPlan : ""
            }}
                    </p>
                </header>
                <!-- 运单信息表单 -->
                <el-form ref="deliveryForm" size="small" class="pt_3" style="border-top: solid 1px #e8ebf3" :model="deliveryForm" :rules="deliveryRules">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="运单号" prop="trackNum">
                                <el-input maxlength="50" v-model="deliveryForm.trackNum"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="物流公司" prop="trackCompany">
                                <el-input v-model="deliveryForm.trackCompany"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="物流公司编码" prop="trackCompanyCode">
                                <el-input v-model="deliveryForm.trackCompanyCode"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </section>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading == 'saveDelivery'" @click="saveDelivery">保存</el-button>
                <el-button @click="dialogDelivery = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 订单详情 -->
        <el-dialog fullscreen id="orderDetailDialog" title="订单详情" :visible.sync="dialogOrderDetail" :close-on-click-modal="false" @closed="resetInfo">
            <div class="df ai_fs">
                <!-- 左侧 -->
                <main style="flex: 1 1 auto">
                    <!-- 订单信息 -->
                    <section class="px_4 pt_3 pb_5" style="background-color: #fff">
                        <div class="tr" v-if="orderDetail.data && orderDetail.data.orderStatus == 1">
                            <el-button type="text" size="mini" class="py_0_i" :loading="btnLoading == 'cancelOrder'" @click="cancelOrder(orderDetail.data.id)">取消订单</el-button>
                        </div>
                        <div class="tr" v-if="orderDetail.data && orderDetail.data.orderStatus == 2">
                            <el-button type="text" size="mini" class="py_0_i" :loading="btnLoading == 'orderAfterSale'" @click="orderAfterSale(orderDetail.data.id)">申请售后</el-button>
                        </div>
                        <div class="df ai_c mb_2">
                            <h2 class="my_0 mr_3">
                                {{ orderDetail.data ? orderDetail.data.orderNo : "" }}
                            </h2>
                            <p class="my_0" style="color: #909399; font-size: 12px">
                                创建时间：{{
                  orderDetail.data ? orderDetail.data.createTime : ""
                }}
                                &nbsp;&nbsp;
                                <span v-if="orderDetail.data && orderDetail.data.financialTime">成功下单：{{ orderDetail.data.financialTime }}</span>
                            </p>
                        </div>
                        <div class="mb_2" v-if="orderDetail.data && orderDetail.data.transactionNo">
                            支付交易号: {{ orderDetail.data.transactionNo }}
                        </div>
                        <div v-if="orderDetail.data && orderDetail.data.refundNo">
                            交易退款号: {{ orderDetail.data.refundNo }}
                        </div>

                        <!-- 订单状态 -->
                        <div class="mb_2 py_2" v-if="orderDetail.data">
                            <!-- 订单状态 -->
                            <el-tag type="danger" size="mini">{{
                orderStatusTypes.find(
                  (v) => v.value == orderDetail.data.orderStatus
                ).name
              }}</el-tag>
                            <!-- 支付状态 -->
                            <el-tag type="success" size="mini">{{
                financialStatusTypes.find(
                  (v) => v.value == orderDetail.data.financialStatus
                ).name
              }}</el-tag>
                            <!-- 发货状态 -->
                            <el-tag type="primary" size="mini">{{
                fulfillmentStatusTypes.find(
                  (v) => v.value == orderDetail.data.fulfillmentStatus
                ).name
              }}</el-tag>
                        </div>
                        <!-- 订单备注 -->
                        <div id="OrderDetailRemark" class="pb_3">
                            <template v-if="orderDetail.hideOrderRemarkForm">
                                <span style="font-size: 14px" v-if="orderDetail.data && orderDetail.data.orderRemark">{{ orderDetail.data.orderRemark }}</span>
                                <span style="color: #409eff; font-size: 12px" v-else>新增订单备注</span>
                                <i class="el-icon-edit ml_2 cp" style="color: #409eff; font-size: 20px" @click="initOrderRemark"></i>
                            </template>
                            <el-form class="df ai_fe" v-else :inline="true" :model="orderDetail.orderRemarkForm">
                                <el-form-item>
                                    <el-input type="textarea" style="width: 400px" :rows="5" v-model="orderDetail.orderRemarkForm.orderRemark"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" :loading="btnLoading == 'saveOrderRemark'" @click="saveOrderRemark">确定</el-button>
                                    <el-button @click="orderDetail.hideOrderRemarkForm = true">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </div>

                        <!-- 订单价格详情 -->
                        <h4 class="my_0 pt_4" style="border-top: solid 1px #d3d8e9">
                            订单价格详情
                        </h4>
                        <div class="df ai_c mb_2" style="border-bottom: solid 1px #d3d8e9">
                            <p style="line-height: 28px">
                                商品总价<br />满减<br />优惠码<br />
                                <template v-if="orderDetail.data && orderDetail.data.shopOrderGifts">
                                    <span v-for="(item, index) in orderDetail.data.shopOrderGifts" :key="index">
                                        礼品卡（{{ item.giftCardNo }}）<br>
                                    </span>
                                </template>
                                捆绑折扣<br />物流费用<br>运费险<br>小费
                            </p>
                            <p class="tr" style="margin-left: 180px; line-height: 28px" v-if="orderDetail.data">
                                {{ $yrFormatNumber(orderDetail.data.goodsTotal, ",", 2) }} USD<br />
                                - {{ $yrFormatNumber(orderDetail.data.discountFullPrice, ",", 2) }} USD<br />
                                - {{ $yrFormatNumber(orderDetail.data.discountCodePrice, ",", 2) }} USD<br />
                                <template v-if="orderDetail.data && orderDetail.data.shopOrderGifts">
                                    <span v-for="(item, index) in orderDetail.data.shopOrderGifts" :key="index">
                                        - {{  $yrFormatNumber( item.subPrice, ",", 2) }} USD<br>
                                    </span>
                                </template>
                                - {{ $yrFormatNumber(orderDetail.data.discountBindPrice, ",", 2) }} USD<br />
                                {{ $yrFormatNumber(orderDetail.data.totalShipping, ",", 2) }} USD<br />
                                <!-- {{ $yrFormatNumber(orderDetail.data.totalTax, ",", 2) }} USD<br> -->
                                {{ $yrFormatNumber(orderDetail.data.insurePrice, ",", 2) }} USD<br>
                                {{ $yrFormatNumber(orderDetail.data.tipPrice, ",", 2) }} USD
                            </p>
                            <p style="margin-left: 100px; line-height: 28px" v-if="orderDetail.data">
                                <span class="bold">订单总金额：{{
                                        $yrFormatNumber(orderDetail.data.totalPrice, ",", 2)
                                    }}
                                    USD</span><br />
                                <span class="bold">实付总金额：{{
                                        $yrFormatNumber(orderDetail.data.cashTotal, ",", 2)
                                    }}
                                    USD</span><br />
                                <el-tag type="success" size="mini">{{
                                        financialStatusTypes.find(
                                            (v) => v.value == orderDetail.data.financialStatus
                                        ).name
                                        }}</el-tag>
                            </p>
                        </div>

                        <!-- 订单商品列表 -->
                        <el-table v-if="orderDetail.data" :show-header="false" :data="orderDetail.data.orderItems">
                            <el-table-column type="index" label="序号" width="60"></el-table-column>
                            <el-table-column label="商品图片" width="90">
                                <template slot-scope="scope">
                                     <GoodsImageTemplate :images="scope.row.goodsImage" :isOrderItems="true" :orderMainImage="scope.row.goodsImages"></GoodsImageTemplate>
                                    <el-tag type="primary" size="mini">{{
                    fulfillment.fulfillmentGoodsStatusTypes.find(
                      (v) => v.value == scope.row.fulfillmentStatus
                    ).name
                  }}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="商品信息" min-width="120">
                                <template slot-scope="scope">
                                    <div class="tl" style="line-height: 24px">
                                        {{ scope.row.goodsTitle }}<br />
                                        <span style="color: #909399">
                                            <span v-if="scope.row.goodsOption">Style: {{ scope.row.goodsOption["1"] }}-{{
                          scope.row.goodsOption["2"]
                        }}<br /></span>
                                            SKU: {{ scope.row.sku }}<br />
                                            <!-- 供应商: {{ scope.row.vendor }} -->
                                        </span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="单价" width="200">
                                <template slot-scope="scope">
                                    <div class="tr">
                                        {{ $yrFormatNumber(scope.row.price, ",", 2) }} USD &times;
                                        {{ scope.row.quantity }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="总价" width="140">
                                <template slot-scope="scope">
                                    <div class="tr">
                                        {{ $yrFormatNumber(scope.row.total, ",", 2) }} USD
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </section>

                    <!-- 物流信息 -->
                    <header class="df ai_c py_2">
                        <h2 class="mr_3">物流状态</h2>
                        <el-tag type="primary" size="mini" class="mr_3" v-if="orderDetail.data">
                            {{
                fulfillmentStatusTypes.find(
                  (v) => v.value == orderDetail.data.fulfillmentStatus
                ).name
              }}
                        </el-tag>
                        <!-- <el-button
              type="primary"
              v-if="
                orderDetail.data &&
                orderDetail.data.orderStatus == 1 &&
                orderDetail.data.financialStatus == 2 &&
                (orderDetail.data.fulfillmentStatus == 1 ||
                  orderDetail.data.fulfillmentStatus == 2)
              "
              @click="initDelivery(orderDetail.data.id)"
            >
              去发货
            </el-button> -->
                    </header>
                    <template v-if="fulfillment.data.length > 0">
                        <section class="mb_3 px_4 pb_3" :style="{
                backgroundColor:
                  item.fulfillmentStatus == 3 ? '#F7F8FA' : '#FFF',
              }" v-for="item in fulfillment.data" :key="item.id">
                            <header class="df ai_c" style="border-bottom: solid 1px #d3d8e9">
                                <p style="line-height: 28px">
                                    发货时间:<br />物流单号:<br />物流商:
                                </p>
                                <p style="margin-left: 50px; line-height: 28px">
                                    {{ item.createTime }}
                                    <el-tag type="primary" size="mini" class="ml_2">
                                        {{
                      fulfillment.fulfillmentStatusTypes.find(
                        (v) => v.value == item.fulfillmentStatus
                      ).name
                    }}
                                    </el-tag>
                                    <br />
                                    {{ item.trackNum }}<br />
                                    {{ item.trackCompany }}
                                </p>
                                <div class="ml_a df ai_c">
                                    <el-button type="text" icon="el-icon-close" v-if="item.fulfillmentStatus == 2" :loading="btnLoading == 'cancelFulfillment_' + item.id" @click="cancelFulfillment(item)">
                                        取消本次发货
                                    </el-button>
                                    <el-button type="text" icon="el-icon-delete" v-if="item.fulfillmentStatus == 4" :loading="btnLoading == 'deleteFulfillment_' + item.id" @click="deleteFulfillment(item)">
                                        删除运单
                                    </el-button>
                                    <el-button type="primary" class="ml_5_i" v-if="item.fulfillmentStatus == 2" :loading="btnLoading == 'confirmDelivery' + item.id" @click="confirmDelivery(item)">
                                        确认收货
                                    </el-button>
                                </div>
                            </header>

                            <!-- 物流商品列表 -->
                            <el-table :show-header="false" :data="item.orderItems">
                                <el-table-column label="商品图片" width="90">
                                    <template slot-scope="scope">
                                         <GoodsImageTemplate :images="scope.row.goodsImage" :isOrderItems="true" :orderMainImage="scope.row.goodsImages"></GoodsImageTemplate>
                                    </template>
                                </el-table-column>
                                <el-table-column label="商品信息" min-width="120">
                                    <template slot-scope="scope">
                                        <div class="tl" style="line-height: 24px">
                                            {{ scope.row.goodsTitle }}<br />
                                            <span style="color: #909399">
                                                <span v-if="scope.row.goodsOption">Style: {{ scope.row.goodsOption["1"] }}-{{
                            scope.row.goodsOption["2"]
                          }}<br /></span>
                                                SKU: {{ scope.row.sku }}
                                            </span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="数量" width="100">
                                    <template slot-scope="scope">
                                        <div class="tr">&times; {{ scope.row.quantity }}</div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </section>
                    </template>
                    <!-- 无运单的提示信息 -->
                    <section style="background-color: #fff" v-else>
                        <div class="df jc_c ai_c" style="height: 150px">
                            <h3>暂无物流状态，请安排发货</h3>
                        </div>
                    </section>

                    <!-- 退货退款时间轴 -->
                    <header class="py_2" v-if="orderDetail.data && orderDetail.data.refundTimeLine">
                        <h2>退货退款时间轴</h2>
                    </header>
                    <section style="background-color: #fff" v-if="orderDetail.data && orderDetail.data.refundTimeLine">
                        <el-timeline class="pt_4">
                            <el-timeline-item :hide-timestamp="true" v-for="(item, index) in orderDetail.data.refundTimeLine" :key="index">
                                <span style="color: #c0c4cc">{{ item.time }}</span> &nbsp;<span style="color: #909399">{{ item.content }}</span>
                            </el-timeline-item>
                        </el-timeline>
                    </section>
                </main>

                <!-- 右侧 -->
                <aside class="ml_3 p_4" style="flex: 0 0 385px; background-color: #f7f8fa">
                    <h2 class="mt_0">购买人信息</h2>
                    <h3 class="mb_0">
                        {{
              orderDetail.data ? orderDetail.data.customerInfo.firstName : ""
            }}
                        {{ orderDetail.data ? orderDetail.data.customerInfo.lastName : "" }}
                        {{
              orderDetail.data ? orderDetail.data.customerInfo.buyNum : ""
            }}个订单
                    </h3>
                    <p class="mt_2">
                        {{ orderDetail.data ? orderDetail.data.customerInfo.email : "" }}
                    </p>
                    <div class="OrderDetail_AsideList df ai_c" v-if="orderDetail.data && orderDetail.data.paymentMethod">
                        <h3 class="pr_5">支付方式</h3>
                        <i class="ml_a mr_2 el-icon-postcard" style="font-size: 22px"></i>
                        <p>
                            {{
                paymentMethodTypes.find(
                  (v) => v.value == orderDetail.data.paymentMethod
                ).name
              }}
                        </p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c" v-if="orderDetail.data && orderDetail.data.paymentChannel">
                        <h3 class="mr_a pr_5">支付渠道</h3>
                        <p>{{ orderDetail.data.paymentChannel }}</p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c">
                        <h3 class="mr_a pr_5">物流方案</h3>
                        <p>
                            {{
                orderDetail.data ? orderDetail.data.logisticsFreightPlan : ""
              }}
                        </p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c">
                        <h3 class="mr_a pr_5">访问IP</h3>
                        <p>{{ orderDetail.data ? orderDetail.data.visitIp : "" }}</p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c">
                        <h3 class="mr_a pr_5">订单来源</h3>
                        <p>{{ orderDetail.data ? orderDetail.data.orderSource : "" }}</p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c">
                        <h3 class="mr_a pr_5">渠道来源</h3>
                        <p>{{ orderDetail.data ? channelSourceData.find( v => v.value == orderDetail.data.channelSource ).name : "" }}</p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c">
                        <h3 class="mr_a pr_5">订单访问设备</h3>
                        <p>
                            {{
                orderDetail.data
                  ? visitDeviceType[orderDetail.data.visitDevice]
                  : ""
              }}
                        </p>
                    </div>
                    <div class="OrderDetail_AsideList">
                        <header class="df ai_c">
                            <h3>收货信息</h3>
                            <i class="ml_a el-icon-edit cp" style="font-size: 22px" v-if="orderDetail.hideShipAddressForm" @click="initShipAddress(false)">
                            </i>
                        </header>
                        <p class="mt_0" style="line-height: 36px" v-if="orderDetail.hideShipAddressForm">
                            姓：{{ orderDetail.data ? orderDetail.data.shippingLastName : ""
              }}<br />
                            名：{{ orderDetail.data ? orderDetail.data.shippingFirstName : ""
              }}<br />
                            电话：+{{
                orderDetail.data ? orderDetail.data.shippingPhoneCode : ""
              }}
                            {{ orderDetail.data ? orderDetail.data.shippingPhone : "" }}<br />
                            邮箱：{{ orderDetail.data ? orderDetail.data.shippingEmail : ""
              }}<br />
                            国家/地区：{{
                orderDetail.data ? orderDetail.data.shippingCountryEn : ""
              }}<br />
                            省份：{{
                orderDetail.data ? orderDetail.data.shippingProvinceEn : ""
              }}<br />
                            城市：{{ orderDetail.data ? orderDetail.data.shippingCity : ""
              }}<br />
                            地址：{{ orderDetail.data ? orderDetail.data.shippingAddress : ""
              }}<br />
                            邮编：{{ orderDetail.data ? orderDetail.data.shippingZip : "" }}
                        </p>
                        <!-- 收货信息表单 -->
                        <el-form size="small" v-else :model="orderDetail.shipAddressForm">
                            <el-row :gutter="12">
                                <el-col :span="12">
                                    <el-form-item prop="shippingLastName">
                                        <el-input placeholder="名" v-model="orderDetail.shipAddressForm.shippingLastName"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item prop="shippingFirstName">
                                        <el-input placeholder="姓" v-model="orderDetail.shipAddressForm.shippingFirstName"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="4">
                                <el-col :span="10">
                                    <el-form-item prop="shippingPhoneCode">
                                        <el-select v-model="orderDetail.shipAddressForm.shippingPhoneCode" placeholder="区号">
                                            <el-option v-for="item in orderDetail.phoneCodeData" :key="item.id" :label="item.cnNameFormat" :value="item.phoneCode"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="14">
                                    <el-form-item prop="shippingPhone">
                                        <el-input maxlength="11" placeholder="手机号" v-model="orderDetail.shipAddressForm.shippingPhone"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item prop="shippingEmail">
                                <el-input maxlength="50" placeholder="邮箱" v-model="orderDetail.shipAddressForm.shippingEmail"></el-input>
                            </el-form-item>
                            <el-row :gutter="12">
                                <el-col :span="12">
                                    <el-form-item prop="shippingCountryId">
                                        <el-select v-model="orderDetail.shipAddressForm.shippingCountryId" placeholder="国家" @change="changeShipCountry($event)">
                                            <el-option v-for="item in orderDetail.phoneCodeData" :key="item.id" :label="item.enName" :value="item.id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item prop="shippingProvinceId">
                                        <el-select v-model="orderDetail.shipAddressForm.shippingProvinceId" placeholder="省(州)">
                                            <el-option v-for="item in orderDetail.provinceData" :key="item.id" :label="item.enName" :value="item.id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item prop="shippingCity">
                                <el-input maxlength="50" placeholder="城市" v-model="orderDetail.shipAddressForm.shippingCity"></el-input>
                            </el-form-item>
                            <el-form-item prop="shippingAddress">
                                <el-input maxlength="50" placeholder="地址" v-model="orderDetail.shipAddressForm.shippingAddress"></el-input>
                            </el-form-item>
                            <el-form-item prop="shippingZip">
                                <el-input maxlength="10" placeholder="邮编" v-model="orderDetail.shipAddressForm.shippingZip"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" :loading="btnLoading == 'saveShipAddress'" @click="saveShipAddress">保存</el-button>
                                <el-button @click="cancelEditAddress">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </aside>
            </div>
        </el-dialog>

        <!-- 发送邮件 -->
        <el-dialog class="dialog-maill" :visible.sync="visibleDialogMaill" :close-on-click-modal="false" title="发送邮件" width="800px" @close="closeMaillCallback">
            <el-form :model="maillForm" :rules="maillRule" ref="mainForm">
                <el-form-item label="召回通知模板" prop="template">
                    <el-select v-model=" maillForm.template" placeholder="请选择召回通知模板" ref="selectMaill">
                        <el-option v-for="item in maillList" :key="item.id" :label="item.recallName + '（优惠码：' +item.discountCode + '）' " :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <!-- <el-form-item>
                    优惠码：{{ discountCode }}
                </el-form-item> -->
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="difineSend" :loading="btnLoading == 'maill' ">确定</el-button>
                <el-button size="small" @click="visibleDialogMaill = false">取消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
export default {
    name: 'OrderRecall',
    components: {
        GoodsImageTemplate,
    },
    data() {
        return {
            // emaill
            maillForm: {
                template: null,
            },
            maillList: null,
            visibleDialogMaill: false,
            // discountCode: null,
            orderNo: null,
            maillRule: {
                template: [
                    {
                        required: true,
                        message: '请选择召回通知模板',
                        trigger: 'change',
                    },
                ],
            },
            channelSourceData: [
                // 订单详情 - 渠道来源数据

                { name: '其它来源', value: 0 },
                { name: '直接访问', value: 1 },
                { name: 'Google', value: 2 },
                { name: 'Facebook', value: 3 },
                { name: 'Instagram', value: 4 },
                { name: 'Bing', value: 5 },
                { name: 'Baidu', value: 6 },
                { name: 'Snapchat', value: 7 },
                { name: 'pinterest', value: 8 },
                { name: 'youtube', value: 9 },
				{ name: 'TikTok', value: 10 },
				{ name: 'Email', value: 11 },
            ],

            btnLoading: '',

            // 列表查询参数
            recallStatus: 0, // 订单状态
            statusArr: ['可召回', '召回中', '召回成功', '召回失败', '无需召回'],
            statusTypes: [
                { name: '可召回', value: 1 },
                { name: '召回中', value: 2 },
                { name: '召回成功', value: 3 },
                { name: '召回失败', value: 4 },
                { name: '全部', value: 0 },
            ],

            orderStatusTypes: [
                // 订单状态选项
                { name: '未完成', value: '1', id: '1' },
                { name: '已完成', value: '2', id: '2' },
                { name: '已取消', value: '3', id: '3' },
            ],
            queryType: null, // 搜索类型
            queryTypes: [
                // 搜索类型选项
                { name: '订单号', value: 'orderNo' },
                { name: '交易号', value: 'transactionNo' },
                { name: '退款号', value: 'refundNo' },
            ],
            queryKeyword: null, // 搜索关键字
            financialStatusTypes: [
                // 支付状态选项

                { name: '待支付', value: '1' },
                { name: '已支付', value: '2' },
                { name: '已取消', value: '3' },
                { name: '支付失败', value: '4' },
                { name: '待退款', value: '5' },
                { name: '部分退款', value: '6' },
                { name: '已退款', value: '7' },
                { name: '退款失败', value: '8' },
            ],
            paymentMethodTypes: [
                // 支付方式选项c

                { name: '线上支付', value: '1' },
                { name: '信用卡', value: '2' },
            ],
			
			channelSourceOptions: [
				{ name: '其它来源', value: '0' },
				{ name: '直接访问', value: '1' },
				{ name: 'Google', value: '2' },
				{ name: 'Facebook', value: '3' },
				{ name: 'Instagram', value: '4' },
				{ name: 'Bing', value: '5' },
				{ name: 'Baidu', value: '6' },
				{ name: 'Snapchat', value: '7' },
				{ name: 'pinterest', value: '8' },
				{ name: 'youtube', value: '9' },
				{ name: 'Tiktok', value: '10' },
				{ name: 'Email', value: '11' },
			],
            queryForm: {
                fulfillmentStatus: null,
                paymentMethod: null,
                financialStatus: null,
                startTime: null,
                endTime: null,
				channelSource: null,
                isItem: 1,
                pageNo: 1,
                pageSize: 10,
				startRecallTime: null,
				endRecallTime: null,
				payStartTime: null,
				payEndTime: null,
            },

            // 列表数据
            loading: false,
            tableData: [],
            total: 0,
            fulfillmentStatusTypes: [
                // 订单发货状态选项

                { name: '待发货', value: '1' },
                { name: '部分发货', value: '2' },
                { name: '已发货', value: '3' },
                { name: '部分完成', value: '4' },
                { name: '已完成', value: '5' },
                { name: '已退货', value: '6' },
            ],

            // 发货
            dialogDelivery: false,
            deliveryForm: {
                orderId: null,
                orderItemIds: [],
                trackCompany: null,
                trackCompanyCode: null,
                trackNum: null,
            },
            deliveryRules: {
                trackNum: [
                    {
                        required: true,
                        message: '请输入运单号',
                        trigger: 'blur',
                    },
                ],
                trackCompany: [
                    {
                        required: true,
                        message: '请输入物流公司',
                        trigger: 'blur',
                    },
                ],
            },
            deliveryShowGoods: false,
            deliveryShowAddress: false,
            hideShipAddressForm: true,
            deliveryGoodsCheckAll: true,
            deliveryGoodsIndeterminate: false,

            // 订单详情
            dialogOrderDetail: false,
            visitDeviceType: {
                1: 'PC端',
                2: '移动端',
                3: '其它设备',
            },
            orderDetail: {
                data: null, // 订单详情数据

                // 订单备注
                hideOrderRemarkForm: true,
                orderRemarkForm: {
                    id: null,
                    orderRemark: null,
                },

                // 收货信息表单
                hideShipAddressForm: true,
                phoneCodeData: [],
                provinceData: [],
                shipAddressForm: {
                    id: null,
                    shippingAddress: null,
                    shippingCity: null,
                    shippingCountryId: null,
                    shippingEmail: null,
                    shippingFirstName: null,
                    shippingLastName: null,
                    shippingPhone: null,
                    shippingPhoneCode: null,
                    shippingProvinceId: null,
                    shippingZip: null,
                },
            },

            copy_shipAddressForm: {},

            // 物流详情
            fulfillment: {
                data: [], // 物流数据

                fulfillmentStatusTypes: [
                    // 物流发货状态选项

                    { name: '待发货', value: '1' },
                    { name: '已发货', value: '2' },
                    { name: '已完成', value: '3' },
                    { name: '已取消', value: '4' },
                ],

                fulfillmentGoodsStatusTypes: [
                    // 物流商品发货状态选项

                    { name: '待发货', value: '1' },
                    { name: '已发货', value: '2' },
                    { name: '已完成', value: '3' },
                    { name: '已退货', value: '4' },
                ],
            },

            multipleSelection: [],
        };
    },

    mounted() {
        let query = this.$route.query;
        if (query.recallStatus) {
            this.recallStatus = query.recallStatus;

            if (query.date?.length) {
                this.queryForm.startRecallTime = query.date?.[0];
                this.queryForm.endRecallTime = query.date?.[1];
            }
        }

        this.queryData();
        this.queryCountry();
        this.queryMailList();
    },

    computed: {
        deliveryGoods() {
            if (this.orderDetail.data) {
                return this.orderDetail.data.orderItems
                    .filter((v) => v.fulfillmentStatus == 1)
                    .map((v) => {
                        v.isChecked = true;
                        return v;
                    });
            } else return [];
        },
    },

    methods: {
        // 重置列表查询条件
        resetCondition() {
            this.queryType = null;
            this.queryKeyword = null;
            this.queryTypes.forEach(
                (item) => delete this.queryForm[item.value]
            );

            // this.queryForm.orderStatus = null;
            this.queryForm.fulfillmentStatus = null;
            this.queryForm.paymentMethod = null;
            this.queryForm.financialStatus = null;
            this.queryForm.startTime = null;
            this.queryForm.endTime = null;
			this.queryForm.startRecallTime = null
			this.queryForm.endRecallTime = null
			this.queryForm.payStartTime = null
			this.queryForm.payEndTime = null
            this.queryForm.pageNo = 1;

            this.queryData();
        },

        // 获取列表数据
        queryData() {
            console.log(this.recallStatus)
			
			switch(this.recallStatus){
				case '0':
					this.queryForm.startRecallTime = null
					this.queryForm.endRecallTime = null
					this.queryForm.payStartTime = null
					this.queryForm.payEndTime = null
					break
				case '1':
					this.queryForm.payStartTime = null
					this.queryForm.payEndTime = null
					break
				case '2':
					break
				case '3':
					this.queryForm.payStartTime = null
					this.queryForm.payEndTime = null
					break
				case '4':
					break				
			}
			
            let params = {
                ...this.queryForm,
                isItem: 0,
                recallStatus:
                    this.recallStatus == 4 ? null : +this.recallStatus + 1,
            };

            if (
                this.queryType != null &&
                this.queryKeyword != null &&
                this.queryKeyword != ''
            ) {
                // 类型-关键字
                params[this.queryType] = this.queryKeyword;
            }

            this.loading = true;
            this.$http
                .get('/order/recall/getPage', { params })
                .then((r) => {
                    this.loading = false;

                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        this.total = r.data.count;
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    // this.$message.error(error);;
                });
        },

        // 获取国家数据，用于区号
        queryCountry() {
            this.$http
                .get('/common/country/getList')
                .then((r) => {
                    if (r.data.code === 200) {
                        let data = r.data.data;

                        this.orderDetail.phoneCodeData = data.map((item) => {
                            let cnNameFormat =
                                '+' + item.phoneCode + ' (' + item.cnName + ')';
                            return {
                                enName: item.enName,
                                id: item.id,
                                cnName: item.cnName,
                                phoneCode: item.phoneCode,
                                cnNameFormat: cnNameFormat,
                            };
                        });
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                });
        },

        // 获取省份数据
        queryProvince(countryId) {
            this.$http
                .get('/common/province/getList', { params: { countryId } })
                .then((r) => {
                    if (r.data.code === 200) {
                        this.orderDetail.provinceData = r.data.data;
                        this.shipAddressForm.shippingProvinceId = r.data.data;
                        this.copy_shipAddressForm.shippingProvinceId =
                            r.data.data;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                });
        },

        queryMailList() {
            this.$http
                .get('/customer/notice/recall/getList?isOpen=1')
                .then((res) => {
                    let { code, data } = res.data;
                    if (code === 200) {
                        this.maillList = data;
                    }
                });
        },

        // 复制成功
        onCopySuccess() {
            this.$message.success('复制成功');
        },
        // 复制失败
        onCopyError() {
            this.$message.error('复制失败');
        },

        // 发货 - 全选
        deliverySelectAll(val) {
            this.deliveryGoods.map((v) => (v.isChecked = val));
            this.deliveryGoodsIndeterminate = false;
        },

        // 发货 - 选中商品
        deliverySelect(val, item) {
            item.isChecked = val;

            let a = this.deliveryGoods.length,
                b = this.deliveryGoods.filter(
                    (v) => v.isChecked == true
                ).length;

            this.deliveryGoodsCheckAll = b > 0 && a == b;
            this.deliveryGoodsIndeterminate = b > 0 && b < a;
        },

        // 发货
        initDelivery(orderId) {
            this.deliveryShowGoods = false;
            this.deliveryShowAddress = false;
            this.hideShipAddressForm = true;
            this.deliveryGoodsCheckAll = true;
            this.deliveryGoodsIndeterminate = false;

            this.deliveryForm = {
                orderId: orderId,
                orderItemIds: [],
                trackCompany: null,
                trackCompanyCode: null,
                trackNum: null,
            };

            if (!this.dialogOrderDetail)
                this.getOrderDetail({ id: orderId }, false);

            this.dialogDelivery = true;
        },
        saveDelivery() {
            this.$refs.deliveryForm.validate((valid) => {
                if (valid) {
                    this.deliveryForm.orderItemIds = this.deliveryGoods
                        .filter((v) => v.isChecked)
                        .map((v) => v.id);
                    this.btnLoading = 'saveDelivery';

                    this.$http
                        .post('/order/doDelivery', this.deliveryForm)
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.btnLoading = '';
                                this.dialogDelivery = false;

                                this.$message({
                                    type: 'success',
                                    message: '操作成功',
                                    duration: 1000,
                                    onClose: () => {
                                        this.queryData();
                                        if (this.dialogOrderDetail)
                                            this.getOrderDetail({
                                                id: this.orderDetail.data.id,
                                            });
                                    },
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = '';
                        });
                }
            });
        },

        // 获取订单详情
        getOrderDetail(item, showDialog = true) {
            // this.orderDetail.data = null;
            this.orderDetail.hideOrderRemarkForm = true;
            this.orderDetail.hideShipAddressForm = true;

            this.fulfillment.data = [];

            this.$http
                .get('/order/getDetail', { params: { id: item.id } })
                .then((r) => {
                    if (r.data.code === 200) {
                        this.orderDetail.data = r.data.data;

                        this.getFulfillment(this.orderDetail.data.id);
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                });

            this.dialogOrderDetail = showDialog;
        },

        // 编辑订单备注
        initOrderRemark() {
            this.orderDetail.orderRemarkForm = {
                id: this.orderDetail.data.id,
                orderRemark: this.orderDetail.data.orderRemark,
            };

            this.orderDetail.hideOrderRemarkForm = false;
        },
        saveOrderRemark() {
            this.btnLoading = 'saveOrderRemark';

            this.$http
                .post('/order/remark', this.orderDetail.orderRemarkForm)
                .then((r) => {
                    if (r.data.code === 200) {
                        this.btnLoading = '';
                        this.orderDetail.hideOrderRemarkForm = true;

                        this.$message({
                            type: 'success',
                            message: '保存成功',
                            duration: 1000,
                        });

                        this.orderDetail.data.orderRemark =
                            this.orderDetail.orderRemarkForm.orderRemark;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    this.btnLoading = '';
                });
        },

        // 订单详情 - 切换国家
        changeShipCountry(countryId) {
            this.orderDetail.provinceData = [];
            this.orderDetail.shipAddressForm.shippingProvinceId = null;

            this.queryProvince(countryId);
        },

        // 取消订单
        cancelOrder(orderId) {
            this.$confirm('该操作无法撤回，请确认是否取消？', '取消订单', {
                type: 'warning',
                confirmButtonText: '确认取消',
                cancelButtonText: '不取消',
            })
                .then(() => {
                    this.btnLoading = 'cancelOrder';

                    this.$http
                        .get('/order/cancel', { params: { id: orderId } })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.btnLoading = '';

                                this.$message({
                                    type: 'success',
                                    message: '取消订单成功',
                                    duration: 1000,
                                    onClose: () =>
                                        this.$router.push('/order/after/sale'), // this.getOrderDetail( { id: orderId } )
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = '';
                        });
                })
                .catch(() => {});
        },

        // 申请售后
        orderAfterSale(orderId) {
            this.$confirm(
                '若点击「是」，需到【售后订单】页面处理售后问题',
                '是否进行售后',
                {
                    type: 'warning',
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                }
            )
                .then(() => {
                    this.btnLoading = 'orderAfterSale';

                    this.$http
                        .get('/order/afterSale', { params: { id: orderId } })
                        .then((r) => {
                            this.btnLoading = '';

                            if (r.data.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '操作成功，即将转到售后订单页面',
                                    duration: 1000,
                                    onClose: () =>
                                        this.$router.push('/order/after/sale'),
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = '';
                        });
                })
                .catch(() => {});
        },

        // 保存收货信息
        initShipAddress(isDelivery) {
            this.orderDetail.shipAddressForm = {
                id: this.orderDetail.data.id,
                shippingAddress: this.orderDetail.data.shippingAddress,
                shippingCity: this.orderDetail.data.shippingCity,
                shippingCountryId: this.orderDetail.data.shippingCountryId,
                shippingEmail: this.orderDetail.data.shippingEmail,
                shippingFirstName: this.orderDetail.data.shippingFirstName,
                shippingLastName: this.orderDetail.data.shippingLastName,
                shippingPhone: this.orderDetail.data.shippingPhone,
                shippingPhoneCode: this.orderDetail.data.shippingPhoneCode,
                shippingProvinceId: this.orderDetail.data.shippingProvinceId,
                shippingZip: this.orderDetail.data.shippingZip,
            };

            this.copy_shipAddressForm = JSON.parse(
                JSON.stringify(this.orderDetail.shipAddressForm)
            );

            this.queryProvince(this.orderDetail.data.shippingCountryId);

            if (isDelivery) this.hideShipAddressForm = false;
            else this.orderDetail.hideShipAddressForm = false;
        },

        cancelEditAddress() {
            this.orderDetail.shipAddressForm = JSON.parse(
                JSON.stringify(this.copy_shipAddressForm)
            );
            this.orderDetail.hideShipAddressForm = true;
        },

        resetInfo() {
            Object.assign(
                this.orderDetail.shipAddressForm,
                this.$options.data.call(this).orderDetail.shipAddressForm
            );

            this.copy_shipAddressForm = {};

            Object.assign(
                this.orderDetail.data,
                this.$options.data.call(this).orderDetail.data
            );
        },

        saveShipAddress() {
            this.btnLoading = 'saveShipAddress';

            this.$http
                .post('/order/editShipping', this.orderDetail.shipAddressForm)
                .then((r) => {
                    if (r.data.code === 200) {
                        this.btnLoading = '';

                        this.$message({
                            type: 'success',
                            message: '保存成功',
                            duration: 1000,
                        });

                        this.orderDetail.data.shippingFirstName =
                            this.orderDetail.shipAddressForm.shippingFirstName;
                        this.orderDetail.data.shippingLastName =
                            this.orderDetail.shipAddressForm.shippingLastName;
                        this.orderDetail.data.shippingPhoneCode =
                            this.orderDetail.shipAddressForm.shippingPhoneCode;
                        this.orderDetail.data.shippingPhone =
                            this.orderDetail.shipAddressForm.shippingPhone;
                        this.orderDetail.data.shippingEmail =
                            this.orderDetail.shipAddressForm.shippingEmail;
                        this.orderDetail.data.shippingCountryId =
                            this.orderDetail.shipAddressForm.shippingCountryId;
                        this.orderDetail.data.shippingCountryCn =
                            this.orderDetail.phoneCodeData.find(
                                (v) =>
                                    v.id ==
                                    this.orderDetail.data.shippingCountryId
                            ).enName;
                        this.orderDetail.data.shippingProvinceId =
                            this.orderDetail.shipAddressForm.shippingProvinceId;

                        this.orderDetail.data.shippingProvinceEn =
                            this.orderDetail.provinceData.find(
                                (v) =>
                                    v.id ==
                                    this.orderDetail.shipAddressForm
                                        .shippingProvinceId
                            ).enName;
                        this.orderDetail.data.shippingCity =
                            this.orderDetail.shipAddressForm.shippingCity;
                        this.orderDetail.data.shippingAddress =
                            this.orderDetail.shipAddressForm.shippingAddress;
                        this.orderDetail.data.shippingZip =
                            this.orderDetail.shipAddressForm.shippingZip;

                        this.orderDetail.hideShipAddressForm = true;
                        this.hideShipAddressForm = true;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    this.btnLoading = '';
                });
        },

        // 获取订单物流详情
        getFulfillment(orderId) {
            this.$http
                .get('/order/getFulfillment', { params: { orderId } })
                .then((r) => {
                    if (r.data.code === 200) {
                        this.fulfillment.data = r.data.data;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                });
        },

        // 物流详情 - 取消发货
        cancelFulfillment(item) {
            this.$confirm('是否确认取消发货？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            })
                .then(() => {
                    this.btnLoading = 'cancelFulfillment_' + item.id;

                    this.$http
                        .get('/order/cancelDelivery', {
                            params: { fulfillmentId: item.id },
                        })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.btnLoading = '';

                                this.$message({
                                    type: 'success',
                                    message: '操作成功',
                                    duration: 1000,
                                    onClose: () => {
                                        this.getOrderDetail({
                                            id: this.orderDetail.data.id,
                                        });
                                    },
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = '';
                        });
                })
                .catch(() => {});
        },

        // 物流详情 - 确认收货
        confirmDelivery(item) {
            this.$confirm('是否确认收货？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            })
                .then(() => {
                    this.btnLoading = 'confirmDelivery_' + item.id;

                    this.$http
                        .get('/order/confirmDelivery', {
                            params: { fulfillmentId: item.id },
                        })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.btnLoading = '';

                                this.$message({
                                    type: 'success',
                                    message: '操作成功',
                                    duration: 1000,
                                    onClose: () => {
                                        this.getOrderDetail({
                                            id: this.orderDetail.data.id,
                                        });
                                    },
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = '';
                        });
                })
                .catch(() => {});
        },

        // 物流详情 - 删除运单
        deleteFulfillment(item) {
            this.$confirm('是否确认删除运单？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            })
                .then(() => {
                    this.btnLoading = 'deleteFulfillment' + item.id;

                    this.$http
                        .delete('/order/deleteFulfillment', {
                            params: { fulfillmentId: item.id },
                        })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.btnLoading = '';

                                this.$message({
                                    type: 'success',
                                    message: '操作成功',
                                    duration: 1000,
                                    onClose: () => {
                                        this.getOrderDetail({
                                            id: this.orderDetail.data.id,
                                        });
                                    },
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = '';
                        });
                })
                .catch(() => {});
        },
        getFulfillmentStatus(status) {
            switch (status) {
                case '1':
                    return '待发货';
                case '2':
                    return '已发货';
                case '3':
                    return '已完成';
                case '4':
                    return '已退货';
            }
        },
        getGoodsOption(obj) {
            let str = '';
            for (let item in obj) {
                str += item + ':' + obj[item] + ' / ';
            }
            str = str.substring(0, str.length - 3);
            return str;
        },
        sendMaill({ orderNo }) {
            this.visibleDialogMaill = true;
            this.orderNo = orderNo;
        },
        // changeSelectMaill({ discountCode }) {
        //     // this.$nextTick(function () {
        //     //     console.log(this.$refs.selectMaill);
        //     //     this.discountCode = this.$refs.selectMaill.selectedLabel;
        //     // });
        //     // this.discountCode = discountCode;
        // },
        closeMaillCallback() {
            this.discountCode = null;
            this.maillForm.template = null;
            this.$refs.mainForm.clearValidate();
            this.orderNo = null;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        sendEmailBoth() {
            if (!this.multipleSelection.length) {
                return this.$message.warning('至少选择一条数据~');
            }

            this.visibleDialogMaill = true;
        },
        difineSend() {
            this.$refs.mainForm.validate((valid) => {
                if (valid) {
                    let noticeRecallId = this.maillForm.template;

                    this.btnLoading = 'maill';
                    this.$http
                        .post('/order/recall/sendMail', {
                            noticeRecallId,
                            orderNos: this.orderNo
                                ? [this.orderNo]
                                : this.multipleSelection.map((cur) => {
                                      return cur.orderNo;
                                  }),
                        })
                        .then((res) => {
                            let { code } = res.data;
                            if (code === 200) {
                                this.$message.success('发送订单召回邮件成功');
                                this.queryData();
                                this.visibleDialogMaill = false;
                            }
                        })
                        .finally(() => {
                            this.btnLoading = '';
                        });
                }
            });
        },
    },
};
</script>

<style scoped>
.el-table /deep/ .cell {
    overflow: unset;
}
.infobox {
    position: relative;
}
.infobox .infoalert {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 140px;
    width: 360px;
    max-height: 400px;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #eee;
    display: none;
    padding: 10px;
}
.infobox:hover .infoalert {
    display: block;
}
.infobox .inforow {
    display: flex;
}
.infobox .inforow .imgbox {
    width: 120px;
    flex-basis: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.infobox .inforow .infos {
    width: 220px;
    flex-basis: 220px;
    margin-left: 10px;
}
.infobox .inforow .infos p {
    text-align: left;
    margin: 0;
}
.infobox .inforow .infos p.tit {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}
.infobox .inforow .infos p.p {
    font-size: 14px;
    color: #bbb;
}
/* 新增运单 - 商品列表 */
.deliveryGoodsList {
    border-collapse: collapse;
    width: 100%;
    color: #606266;
}

.deliveryGoodsList > tr > td {
    border-top: solid 1px #e8ebf3;
    border-bottom: solid 1px #e8ebf3;
    padding: 6px;
}

#OrderDetailRemark > .el-icon-edit:hover {
    opacity: 0.8;
}
</style>
<style>
.order-list-query-keyword .el-select .el-input {
    width: 90px;
    color: #606266;
}

.order-list-query-keyword .el-input-group__prepend {
    background-color: #fff;
}

/* 新增运单弹窗 */
#deliveryDialog > .el-dialog {
    background: #ecedf3;
}

#deliveryDialog .el-icon-edit:hover {
    color: #428dfc;
}

/* 查看详情弹窗 */
#orderDetailDialog > .el-dialog {
    background: #ecedf3;
}

.OrderDetail_AsideList {
    border-top: solid 1px #d3d8e9;
}

.OrderDetail_AsideList > h3 {
    white-space: nowrap;
}

.OrderDetail_AsideList .el-icon-edit:hover {
    color: #428dfc;
}
</style>
