<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>数据统计</el-breadcrumb-item>
            <el-breadcrumb-item>页面数据统计</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item>
                <el-input v-model="queryForm.pagePath" placeholder="页面路径" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-date-picker type="daterange" :clearable="false" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="date" value-format="yyyy-MM-dd" @change="changeTime"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-refresh-left" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>
        <!-- 数据表 -->
        <el-table border stripe :data="tableData" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" max-height="600" :default-sort="option" @sort-change="changeSort($event, 'queryForm')">
            <el-table-column prop="pagePath" label="页面路径" min-width="180px">
                <template #default="{ row }">
                    <div class="jump" @click="openView(row.path)" style="word-break: break-word ">
                        {{row.pagePath}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="visitNum" label="浏览数" sortable="1"></el-table-column>
            <el-table-column prop="visitUserNum" label="浏览用户数" sortable="2"></el-table-column>
            <el-table-column prop="outNum" label="跳出数" sortable="3">
            </el-table-column>
            <el-table-column prop="outRate" label="跳出率 %" sortable="4">
                <template slot-scope="scope">
                    {{ scope.row.outRate || 0 }}%
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template #default="{row}">
                    <el-link type="primary" @click="getChartInfo(row)">查看数据</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background :current-page.sync="queryForm.pageNo" :page-sizes="[100, 200, 300]" :page-size.sync="queryForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-dialog title="页面数据统计" :visible.sync="visibleDialogInfo" :close-on-click-modal="false" top="3%" width="1000px" @closed="resetInfo">
            <div class="chart-mod">
                <div id="tradingTrend" style="height:100%;width:100%;"></div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import * as echarts from 'echarts';
// 引入基本模板

// 引入柱状图组件
require('echarts/lib/chart/bar');
// 引入提示框和title组件
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');

export default {
    name: 'StatisticPage',
    data() {
        return {
            date: null,
            queryForm: {
                pagePath: null,
                startDate: null,
                endDate: null,
                pageNo: 1,
                pageSize: 100,
                ascSort: null,
                descSort: 1,
            },
            tableData: [],
            option: { prop: 'visitNum', order: 'descending' },
            loading: false,
            total: 0,
            sort: null,
            sortOptions: [
                { name: 'visitNum', value: 1 },
                { name: 'visitUserNum', value: 2 },
                { name: 'outNum', value: 3 },
                { name: 'outRate', value: 4 },
            ],
            visibleDialogInfo: false,
        };
    },
    created() {
        let sort = this.$route.query.sort;
        if (sort) {
            this.queryForm.descSort = sort;
            for (let i = 0; i < this.sortOptions.length; i++) {
                let item = this.sortOptions[i];
                if (item.value == sort) {
                    this.option.prop = item.name;
                }
            }
        }
    },
    mounted() {
        let query = this.$route.query;
        if (query.date) {
            this.date = query.date;
        } else {
            this.date = this.timeDefault();
        }

        // this.date = ['2022-09-01', '2022-09-09'];
        // this.subdate = ['2022-09-01', '2022-09-09'];

        this.queryData();
    },
    methods: {
        resetQuery() {
            let query = this.$route.query;
            if (query.date) {
                this.date = query.date;
            } else {
                this.date = this.timeDefault();
            }

            this.queryForm.pagePath = '';
            this.queryData();
        },
        queryData() {
            if (this.date && this.date.length > 1) {
                this.queryForm.startDate = this.date[0];
                this.queryForm.endDate = this.date[1];
            } else {
                this.queryForm.startDate = null;
                this.queryForm.endDate = null;
            }
            this.$http
                .get('/statistic/page/getPage', { params: this.queryForm })
                .then((response) => {
                    if (response.data.code === 200) {
                        this.tableData = response.data.data;
                        this.total = response.data.count;
                    }
                });
        },
        changeTime(e) {},
        timeDefault() {
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            if (mm < 10) {
                mm = '0' + mm;
            }
            let dd = new Date().getDate();
            if (dd < 10) {
                dd = '0' + dd;
            }
            let dateTime = yy + '-' + mm + '-' + dd;
            return [dateTime, dateTime];
        },
        changeSort(column, form) {
            let name = column.prop;
            let order = column.order;
            for (let i = 0; i < this.sortOptions.length; i++) {
                let item = this.sortOptions[i];
                if (name == item.name) {
                    switch (order) {
                        case 'ascending':
                            this[form].ascSort = item.value;
                            this[form].descSort = null;
                            break;
                        case 'descending':
                            this[form].ascSort = null;
                            this[form].descSort = item.value;
                            break;
                        default:
                            this[form].ascSort = null;
                            this[form].descSort = null;
                            break;
                    }
                }
            }
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        openView(url) {
			if(url.indexOf('/amazon') >= 1){
				let arr = url.split('/amazon/')
				let arr2 = arr[1]
				// console.log(arr2)
				let newUrl = url + '?url=https://www.amazon.com/dp/' + arr2
				// console.log(newUrl)
				window.open(newUrl)
			} else {
				window.open(url)
			}
        },

        async getChartInfo({ pagePath }) {
            this.visibleDialogInfo = true;
            let [startDate, endDate] = this.date,
                params = {
                    pagePath,
                    startDate,
                    endDate,
                };

            let {
                data: { code, data },
            } = await this.$http.get('/statistic/page/getChartData', {
                params,
            });
            if (code === 200) {
                this.bridgeChartHandle(data);
            }
        },

        bridgeChartHandle(data) {
            let visitNums = [],
                visitUserNums = [],
                outNums = [],
                times = [];

            data.map(({ visitNum, visitUserNum, outNum, statisticDate }) => {
                visitNums.push(visitNum);
                visitUserNums.push(visitUserNum);
                outNums.push(outNum);
                times.push(statisticDate);
            });

            let options = this.getDrawLineOptions({
                xAxis: times,
                yAxis: [visitNums, visitUserNums, outNums],
            });

            console.log(options);

            this.drawLineChart(options);
        },

        drawLineChart(options) {
            this.chart = echarts.init(document.getElementById('tradingTrend'));
            window.onresize = () => {
                this.chart.resize();
            };

            this.$nextTick(() => {
                // 绘制图表
                this.chart.setOption(options);
            });
        },

        getDrawLineOptions({ xAxis = [], yAxis = [] }) {
            let names = ['浏览数', '浏览用户数', '跳出数'],
                option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985',
                            },
                        },
                    },
                    legend: {
                        data: names,
                    },
                    // toolbox: {
                    //     feature: {
                    //         saveAsImage: {},
                    //     },
                    // },
                    dataZoom: [
                        {
                            // type: 'inside',
                            start: 0,
                            end: 100,
                            // orient: 'vertical',
                        },
                        {
                            start: 0,
                            end: 100,
                        },
                        {
                            type: 'slider',
                            show: true,
                            handleSize: 0, //滑动条的 左右2个滑动条的大小
                            startValue: 0, // 初始显示值
                            endValue: 500000, // 结束显示值,自己定
                            height: 5, //组件高度
                            // left: '10%', //左边的距离
                            // right: '10%', //右边的距离
                            // bottom: 10, //底边的距离
                            borderColor: '#ccc',
                            fillerColor: '#4cccfe',
                            borderRadius: 5,
                            backgroundColor: '#efefef', //两边未选中的滑动条区域的颜色
                            showDataShadow: false, //是否显示数据阴影 默认auto
                            showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
                            realtime: true, //是否实时更新
                            filterMode: 'filter',
                        },
                    ],
                    grid: {
                        top: 50,
                        left: '3%',
                        right: '4%',
                        bottom: 50,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: xAxis,
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: [
                        {
                            name: '浏览数',
                            data: yAxis[0],
                            type: 'line',
                        },
                        {
                            name: '浏览用户数',
                            data: yAxis[1],
                            type: 'line',
                        },
                        {
                            name: '跳出数',
                            data: yAxis[2],
                            type: 'line',
                        },
                    ],
                };
            // option = {
            //     xAxis: {
            //         type: 'category',
            //         data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            //     },
            //     yAxis: {
            //         type: 'value',
            //     },
            //     series: [
            //         {
            //             type: 'line',
            //             data: [12, 9, 19, 4, 122, 2],
            //         },
            //         {
            //             type: 'line',
            //             data: [3, 3, 5, 2, 8, 1],
            //         },
            //         {
            //             type: 'line',
            //             data: [0, 0, 0, 0, 0, 0],
            //         },
            //     ],
            // };
            return option;
        },
        resetInfo() {
            this.chart.clear();
        },
    },
};
</script>

<style scoped>
.cell .jump {
    color: #6f96e8;
    word-break: break-word !important;
}
.chart-mod {
    width: 100%;
    height: 600px;
}

.main-wrap {
    background: #eef0f5;
    max-height: 900px;
    overflow-x: auto;
    display: block;
    box-sizing: none;
    min-width: 1220px;
}
</style>