<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>营销管理</el-breadcrumb-item>
            <el-breadcrumb-item>分销落地页</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
			<el-form-item>
			    <el-date-picker type="daterange" range-separator="至" :clearable="false" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="date" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :default-value="new Date()"></el-date-picker>
			</el-form-item>
            <el-form-item>
                <el-input v-model="queryForm.title" placeholder="名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <!-- <el-input v-model="queryForm.id" placeholder="ID" clearable></el-input> -->
				<el-input-number v-model.number="queryForm.id" :precision="0" :min="0" placeholder="ID" class="w200" clearable></el-input-number>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="querySearch" :loading="loading">查询</el-button>
                <el-button icon="el-icon-refresh-left" @click="resetQuery">重置</el-button>
                <!-- <el-button type="success" icon="el-icon-plus" @click="openAdd">Generate URL</el-button> -->
            </el-form-item>
        </el-form>
        <!-- 数据表 -->
        <el-table border stripe :data="tableData" v-loading="loading" element-loading-text="Loading" element-loading-spinner="el-icon-loading" max-height="600" ref="userTable" show-summary :summary-method="getTotal">
            <el-table-column prop="id" label="ID" width="50px"></el-table-column>
            <!-- <el-table-column label="商品图片" width="140" header-align="center" align="center">
                <template slot-scope="scope">
					<el-image 
					    style="width: 100px; height: 100px"
					    :src="scope.row.shopGoods.goodsImages[0]" 
					    :preview-src-list="scope.row.shopGoods.goodsImages">
					</el-image>
                </template>
            </el-table-column> -->
            <el-table-column prop="title" label="名称" width="150"></el-table-column>
			<el-table-column label="排序" width="100px">
			    <template slot-scope="scope">
			        {{ getSortType(scope.row.sortType) }}
			    </template>
			</el-table-column>
			<el-table-column prop="shopStatisticDistribution.visitNum" label="页面访问量" min-width="50px"></el-table-column>
			<el-table-column prop="shopStatisticDistribution.visitUserNum" label="访问用户数" min-width="50px"></el-table-column>
			<el-table-column prop="shopStatisticDistribution.outNum" label="跳出数" min-width="50px"></el-table-column>
			<el-table-column label="跳出率" min-width="50px">
				<template slot-scope="scope">
				    {{scope.row.shopStatisticDistribution.outRate + '%'}}
				</template>
			</el-table-column>
			<el-table-column label="下单成功数" min-width="50px">
				<template slot-scope="scope">
				    <p>{{scope.row.shopStatisticDistribution.orderSuccessNum}}</p>
				    <el-link type="primary" @click="intoOrder(scope.row.shopStatisticDistribution.distributionPageId)" v-if="scope.row.shopStatisticDistribution.orderSuccessNum && scope.row.shopStatisticDistribution.distributionPageId">查看</el-link>
				</template>
			</el-table-column>
			<el-table-column prop="shopStatisticDistribution.saleNum" label="销量" min-width="50px"></el-table-column>
			<el-table-column prop="shopStatisticDistribution.saleNet" label="商品净销售额" width="130px">
				<template slot="header" slot-scope="scope">
					商品净销售额
					<el-tooltip class="item" effect="dark" content="商品净销售额=商品销售额-均摊折扣" placement="top">
					    <i class="el-icon-question"></i>
					</el-tooltip>
				</template>
			</el-table-column>
			<el-table-column prop="shopStatisticDistribution.saleTotal" label="订单销售额" min-width="50px"></el-table-column>
			<el-table-column label="转化率" min-width="50px">
				<template slot-scope="scope">
				    {{scope.row.shopStatisticDistribution.conversionRate + '%'}}
				</template>
			</el-table-column>
			<el-table-column prop="shopStatisticDistribution.buyUserNum" label="加购用户数" min-width="50px"></el-table-column>
			<el-table-column label="加购率" min-width="50px">
				<template slot-scope="scope">
				    {{scope.row.shopStatisticDistribution.buyPurchaseRate+ '%'}}
				</template>
			</el-table-column>
            <!-- <el-table-column prop="viewUrl" label="URL" min-width="50px"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="100px"></el-table-column>
            <el-table-column prop="updateTime" label="更新时间" width="100px"></el-table-column> -->
            <el-table-column fixed="right" label="操作" width="80px">
                <template slot-scope="scope">
                    <!-- <el-button size="mini" type="text" icon="el-icon-edit" @click="editThis(scope.row.id)">Edit</el-button>
                    <el-button size="mini" type="text" icon="el-icon-document" @click="previewThis(scope.row.viewUrl)">Preview</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="delThis(scope.row.id)">Delete</el-button> -->
					<el-button size="mini" type="text" icon="el-icon-view" @click="openView(scope.row.viewUrl)">预览</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-dialog title="Select template image" :visible.sync="dialogImage" top="3%" width="900px" :close-on-click-modal="false">
            <i class="el-icon-plus img-uploader-icon cp ImageBox" v-if="!addForm.banner" style="border: dotted 1px #AAA; border-radius: 4px;" @click="setOpenInsert('addForm.banner')"></i>
            <section class="ImageBox" v-if="addForm.banner">
                <el-image fit="contain" class="Image" :src="addForm.banner">
                    <div slot="error" class="el-image__error">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
                <div class="pa100 mask_d5">
                    <el-button type="danger" icon="el-icon-delete" circle class="ImageDeleteButton" @click="addForm.banner = null"></el-button>
                </div>
            </section>

            <h3>Templates for you</h3>
            <div class="flex">
                <div class="tempImgbox center" v-for="item in 8" :key="item" @click="selectTempImg('https://www.baidu.com/img/PCtm_d9c8750bed0b3c7d089fa7d55720d6cf.png')">
                    <img src="https://www.baidu.com/img/PCtm_d9c8750bed0b3c7d089fa7d55720d6cf.png">
                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogImage = false">Confirm</el-button>
                <el-button @click="dialogImage = false">Cancle</el-button>
            </div>
        </el-dialog>

        <el-dialog title="Generate your Exclusive URL" class="gray" :visible.sync="dialogGenerate" fullscreen :close-on-click-modal="false" @closed="closedGenerate">

            <el-form :model="addForm" :rules="addFormRules" status-icon ref="addForm" label-position="top" size="medium">
                <div class="frow mb20">
                    <div class="fbox1 bgwhite pd10">
                        <!-- <el-form-item label="Choose a merchant" prop="merchant">
		          <el-select v-model="addForm.merchant">
			          <el-option v-for="item in shopInfoIdOptions" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
			        </el-select>
		        </el-form-item> -->
                        <el-form-item label="URL Title" prop="title">
                            <el-input v-model="addForm.title" placeholder="title"></el-input>
                        </el-form-item>
                        <el-form-item label="Sort" prop="sortType">
                            <el-select v-model="addForm.sortType" style="width:200px;">
                                <el-option v-for="item in sortTypeOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="fbox2 bgwhite pd10">
                        <div class="flex pd10">
                            <div>Banner（optional）</div>
                            <div class="ml10">
                                <section class="ImageBox" v-if="addForm.banner">
                                    <el-image fit="contain" class="Image" :src="addForm.banner">
                                        <div slot="error" class="el-image__error">
                                            <i class="el-icon-picture-outline"></i>
                                        </div>
                                    </el-image>
                                    <div class="pa100 mask_d5">
                                        <el-button type="danger" icon="el-icon-delete" circle class="ImageDeleteButton" @click="addForm.banner = null"></el-button>
                                    </div>
                                </section>
                                <i class="el-icon-plus img-uploader-icon ImageBox" style="border: dotted 1px #AAA; border-radius: 4px; width: 300px; height:168px;" @click="dialogImage = true" v-if="!addForm.banner"></i>
                                <p>(Recommended size：1080*600px)</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bgwhite pd10">
                    <el-form-item label="Select product">
                        <el-button type="primary" icon="el-icon-plus" @click="openSelectGoods">Add</el-button>
                    </el-form-item>

                    <div class="flex">
                        <template v-if="selectedGoodsTable.data.length > 0">
                            <vuedraggable class="wrapper" v-model="selectedGoodsTable.data">
                                <div class="itembox" v-for="(item,indexB) in selectedGoodsTable.data" :key="indexB">
                                    <div class="imgbox">
                                        <GoodsImageTemplate :images="item.goodsImages" width="180" height="180" :hasHover="false"></GoodsImageTemplate>
                                    </div>
                                    <p class="tit">{{ item.goodsTitle }}</p>
                                    <p class="tit">${{ item.price }} / ${{ item.compareAtPrice }}</p>
                                    <p>Qty: {{ item.inventoryQuantity }}</p>
                                    <p class="between">
                                        <span class="w50" title="Up"><i class="el-icon-top" @click="toppingGoods(item)"></i></span>
                                        <!-- <span class="w50" title="取消置顶"><i class="el-icon-top" @click="cancleToppingGoods(item)" v-else></i></span> -->
                                        <span class="w50" @click="deleteSelectedGoods(item)"><i class="el-icon-delete"></i></span>
                                    </p>
                                    <span class="published publishedA" v-if="item.published == 0">{{ getPublishedB(item.published) }}</span>
                                    <span class="published publishedB" v-if="item.published == 1">{{ getPublishedB(item.published) }}</span>
                                </div>
                            </vuedraggable>
                        </template>
                        <p v-else>You don't have any product added yet.Get started and add products to your page!</p>
                    </div>
                </div>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">Confirm</el-button>
                <el-button @click="dialogGenerate = false">Cancle</el-button>
            </div>
        </el-dialog>


		<el-dialog title="订单信息" :visible.sync="visibleDialogOrder" :close-on-click-modal="false" top="3%" width="1200px" @closed="resetDialogOrder">
		    <!-- <el-table border stripe :data="orderData" ref="userTable" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000">
		        <el-table-column label="ID" prop="id"></el-table-column>
		    </el-table> -->
		
		    <el-table ref="orderListTable" border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" max-height="1000" :data="orderData" v-loading="loading">
		        <el-table-column fixed label="订单号" min-width="200">
		            <template slot-scope="scope">
		                {{ scope.row.orderNo }}
		                <i class="el-icon-document-copy bold_i cp" title="复制" style="color: #000" v-clipboard:copy="scope.row.orderNo" v-clipboard:success="onCopySuccess" v-clipboard:error="onCopyError">
		                </i>
		            </template>
		        </el-table-column>
		        <el-table-column label="商品信息" width="200">
		            <template slot-scope="scope">
		                <div class="infobox">
		                    <div v-if="scope.row.orderItems && scope.row.orderItems.length">{{ scope.row.orderItems[0].goodsTitle }} ... </div>
		                    <!-- <el-button icon="el-icon-s-order" type="info" size="mini" round @click="lookDetail(scope.row.orderItems)">查看详细</el-button> -->
		                    <el-popover placement="right" width="500" trigger="click">
		                        <div class="infoitem">
		                            <div class="inforow" v-for="(item,index) in scope.row.orderItems" :key="index">
		                                <div class="imgbox">
		                                    <GoodsImageTemplate :images="item.goodsImage" :isOrderItems="true" :orderMainImage="item.goodsImages"></GoodsImageTemplate>
		                                </div>
		                                <div class="infos">
		                                    <p class="tit">{{item.goodsTitle}}</p>
		                                    <p class="p">style: {{ getGoodsOption(item.goodsOption) }}</p>
		                                    <p class="p">sku: {{item.sku}}</p>
		                                    <p class="p">spu: {{item.spu}}</p>
		                                    <p class="p">状态: {{ getFulfillmentStatus(item.fulfillmentStatus) }}</p>
		                                    <p class="p">DIY状态：{{ item.isDiy ? '是' : '否' }}</p>
		                                </div>
		                            </div>
		                        </div>
		                        <el-button slot="reference" type="primary" plain size="mini">查看</el-button>
		                    </el-popover>
		                </div>
		            </template>
		        </el-table-column>
		        <el-table-column prop="createTime" label="创建时间" min-width="160"></el-table-column>
		        <el-table-column label="收货人" min-width="120">
		            <template slot-scope="scope">
		                {{ scope.row.shippingFirstName }} {{ scope.row.shippingLastName }}
		            </template>
		        </el-table-column>
		        <el-table-column label="支付状态" min-width="120">
		            <template slot-scope="scope">
		                <span>
		                    {{
		      financialStatusTypes.find(
		        (v) => v.value == scope.row.financialStatus
		      ).name
		    }}
		                </span>
		            </template>
		        </el-table-column>
		        <el-table-column label="订单总价" min-width="120">
		            <template slot-scope="scope">
		                {{ $yrFormatNumber(scope.row.totalPrice, ",", 2) }}USD
		            </template>
		        </el-table-column>
		        <el-table-column prop="shippingCountryEn" label="国家/地区" min-width="120"></el-table-column>
		        <el-table-column prop="orderSource" label="订单来源" min-width="200">
		            <template slot-scope="scope">
		                <el-tooltip :content="scope.row.orderSource" placement="top">
		                    <div class="hidelink">{{ scope.row.orderSource }}</div>
		                </el-tooltip>
		            </template>
		        </el-table-column>
		        <el-table-column prop="channelSource" label="渠道来源" min-width="120">
		            <template slot-scope="scope">
		                {{
		                channelSourceData.find(
		                  (v) => v.value == scope.row.channelSource
		                ).name
		              }}
		            </template>
		        </el-table-column>
		        <el-table-column prop="adPageId" label="落地页ID" min-width="120"></el-table-column>
		        <el-table-column prop="discountCode" label="优惠码" min-width="120"></el-table-column>
		        <el-table-column label="物流状态" min-width="120">
		            <template slot-scope="scope">
		                {{
		    fulfillmentStatusTypes.find(
		      (v) => v.value == scope.row.fulfillmentStatus
		    ).name
		  }}
		            </template>
		        </el-table-column>
		        <!-- @click="getOrderDetail(scope.row, true, scope.$index)" -->
		        <el-table-column label="操作" fixed="right" width="140" header-align="center" align="center">
		            <template #default="{row}">
		                <el-button type="text" size="mini" icon="el-icon-document" @click="bridgeOrderInfo(row)">详情</el-button>
		            </template>
		        </el-table-column>
		    </el-table>
		
		    <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="orderForm.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size.sync="orderForm.pageSize" :total="orderTotal" @size-change="queryOrderData" @current-change="queryOrderData">
		    </el-pagination>
		
		</el-dialog>
		
		<OrderInfo ref="orderInfo"></OrderInfo>
        <!-- 选择商品 -->
        <SelectGoods ref="selectGoods" :showDialog="dialogSelectGoods" :containItem="false" :selectedGoods="selectedGoodsTable.data" @closeDialog="closeSelectGoods" @saveSelectedGoods="saveSelectGoods">
        </SelectGoods>

        <!--插入图片-->
        <ImageUploader :insertImage="insertImage" :insertType="insertType" @getOpenState="getOpenState" @getImage="getImage" ref="uploadImage">
        </ImageUploader>

    </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader';
import SelectGoods from '@/components/SelectGoods';
import OrderInfo from '../order/orderInfo.vue';
import vuedraggable from 'vuedraggable';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
export default {
    name: 'exclusiveUrl',
    components: {
        ImageUploader,
        SelectGoods,
        vuedraggable,
        GoodsImageTemplate,
		OrderInfo,
    },
    data() {
        return {
            date: '',
            queryForm: {
                title: null,
                id: undefined,
                startDate: null,
                endDate: null,
                pageNo: 1,
                pageSize: 20,
            },
            tableData: [],
            loading: false,
            total: 0,
            shopInfoIdOptions: [],
            sortTypeOptions: [
                { name: '销量从高到低', value: 1 },
                { name: '访问量从高到低', value: 2 },
                { name: '转化率从高到低', value: 3 },
                { name: '新品', value: 4 },
                { name: '自定义排序', value: 5 },
            ],
            // 选择商品参数
            dialogSelectGoods: false,
            // 选中的商品列表数据
            selectedGoodsTable: {
                data: [],
                total: 0,
            },
            insertImage: false,
            insertType: null,
            dialogGenerate: false,
            addLoading: false,
            addForm: {
                // merchant: null,
                title: null,
                sortType: null,
                banner: null,
                goodsIdList: [],
            },
            addFormRules: {
                // merchant: [{ required: true, message: "Pleast choose the merchant", trigger: "change" }],
                title: [
                    {
                        required: true,
                        message: 'Pleast insert the title',
                        trigger: 'blur',
                    },
                ],
                sortType: [
                    {
                        required: true,
                        message: 'Pleast choose the sort',
                        trigger: 'change',
                    },
                ],
            },
            cationGoodsIds: [],
            dialogImage: false,
			pickerOptions: {
			    disabledDate: (time) => {
			        return time.getTime() > Date.now();
			    },
			},
			totalData: {},
			visibleDialogOrder: false,
			orderId: null,
			orderData: [],
			orderTotal: 0,
			orderForm: {
			    pageNo: 1,
			    pageSize: 20,
			},
			fulfillmentStatusTypes: [
			    // 订单发货状态选项
			
			    { name: '待发货', value: '1' },
			    { name: '部分发货', value: '2' },
			    { name: '已发货', value: '3' },
			    { name: '部分完成', value: '4' },
			    { name: '已完成', value: '5' },
			    { name: '已退货', value: '6' },
			],
			channelSourceData: [
			    // 订单详情 - 渠道来源数据
			    { name: '其它来源', value: 0 },
			    { name: '直接访问', value: 1 },
			    { name: 'Google', value: 2 },
			    { name: 'Facebook', value: 3 },
			    { name: 'Instagram', value: 4 },
			    { name: 'Bing', value: 5 },
			    { name: 'Baidu', value: 6 },
			    { name: 'Snapchat', value: 7 },
			    { name: 'pinterest', value: 8 },
			    { name: 'youtube', value: 9 },
			    { name: 'titok', value: 10 },
			    { name: 'email', value: 11 },
			],
			financialStatusTypes: [
			    // 支付状态选项
			    { name: '待支付', value: '1' },
			    { name: '已支付', value: '2' },
			    { name: '已取消', value: '3' },
			    { name: '支付失败', value: '4' },
			    { name: '待退款', value: '5' },
			    { name: '部分退款', value: '6' },
			    { name: '已退款', value: '7' },
			    { name: '退款失败', value: '8' },
			],
        };
    },
    mounted() {
		this.getToday()
        // this.date = this.timeDefault()
        this.queryData();
        // this.getShops()
    },
    methods: {
        getShops() {
            this.$http.get('/shop/info/getList').then(async (res) => {
                let { code, data } = res.data;
                if (code === 200) this.shopInfoIdOptions = data;
            });
        },
        resetQuery() {
            this.queryForm = {
                title: null,
                id: undefined,
                startDate: null,
                endDate: null,
                pageNo: 1,
                pageSize: 20,
            };
            this.getToday()
			this.date[0] = "2021-01-01"
			this.queryForm.startDate = "2021-01-01"
            this.queryData()
        },
        timeDefault() {
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            if (mm < 10) {
                mm = '0' + mm;
            }
            let dd = new Date().getDate();
            if (dd < 10) {
                dd = '0' + dd;
            }
            let dateTime = yy + '-' + mm + '-' + dd;
            // console.log(dateTime)
            return [dateTime, dateTime];
        },
		getToday() {
		    let date = new Date();
		    let year = date.getFullYear();
		    let month = date.getMonth() + 1;
		    let day = date.getDate();
		    if (month < 10) {
		        month = '0' + month;
		    }
		    if (day < 10) {
		        day = '0' + day;
		    }
		    let result = year + '-' + month + '-' + day;
		    this.queryForm.startDate = result;
		    this.queryForm.endDate = result;
		    this.date = [result, result];
		},
        queryData() {
			this.loading = true
            if (this.date && this.date.length > 1) {
                this.queryForm.startDate = this.date[0];
                this.queryForm.endDate = this.date[1];
            } else {
                this.queryForm.startDate = null;
                this.queryForm.endDate = null;
            }
            this.$http
                .get('/setting/distribution/getPage', {
                    params: this.queryForm,
                })
                .then((response) => {
                    if (response.data.code === 200) {
                        this.tableData = response.data.data;
                        this.total = response.data.count;
						this.queryTotal()
                    }
                }).finally(() => {
                    this.loading = false;
                })
        },
        querySearch() {
            this.queryForm.pageNo = 1;
            this.queryData();
        },
		queryTotal(){
			if (this.date && this.date.length > 1) {
			    this.queryForm.startDate = this.date[0];
			    this.queryForm.endDate = this.date[1];
			} else {
			    this.queryForm.startDate = null;
			    this.queryForm.endDate = null;
			}
			this.$http
			    .get('/statistic/Distribution/getTotalStatistic', {
			        params: this.queryForm,
			    })
			    .then((response) => {
			        let { code, data } = response.data;
			
			        if (code === 200) {
			            // console.log('total',data)
			            this.totalData = data;
			            // this.$forceUpdate();
			        }
			    })
			    .finally(() => {
			        this.loading = false;
			    });
		},
		getTotal(param) {
		    const sums = [];
		    const { columns, data } = param;
		    // console.log(columns, data)
		    columns.forEach((column, index) => {
		        if (index === 0) {
		            sums[index] = '总计';
		            return;
		        }
		        if (index < 3 && index !== 0) {
		            sums[index] = '';
		            return;
		        }
		        switch (index) {
		            case 3:
		                sums[index] = this.totalData.visitNum;
		                break;
		            case 4:
		                sums[index] = this.totalData.visitUserNum;
		                break;
		            case 5:
		                sums[index] = this.totalData.outNum;
		                break;
		            case 6:
		                sums[index] = this.totalData.outRate + '%';
		                break;
		            case 7:
		                sums[index] = this.totalData.orderSuccessNum;
		                break;
		            case 8:
		                sums[index] = this.totalData.saleNum;
		                break;
		            case 9:
		                sums[index] = this.totalData.saleNet;
		                break;
		            case 10:
		                sums[index] = this.totalData.saleTotal;
		                break;
		            case 11:
		                sums[index] = this.totalData.conversionRate + '%';
		                break;
		            case 12:
		                sums[index] = this.totalData.buyUserNum;
		                break;
		            case 13:
		                sums[index] = this.totalData.buyPurchaseRate + '%';
		                break;
		            default:
		                break;
		        }
		    });
		    setTimeout(() => {
		        this.$refs.userTable.doLayout();
		    }, 100);
		    return sums;
		},
        // 选择商品方法
        openSelectGoods() {
            this.$refs.selectGoods.caller = 'notset';
            this.$refs.selectGoods.init();
            this.dialogSelectGoods = true;
        },
        closeSelectGoods() {
            this.dialogSelectGoods = false;
        },
        saveSelectGoods(items, caller) {
            switch (caller) {
                default:
                    this.selectedGoodsTable.data = items;
                    this.selectedGoodsTable.total =
                        this.selectedGoodsTable.data.length;
            }
        },
        // 置顶
        toppingGoods(item) {
            let tempitem = JSON.parse(JSON.stringify(item));
            tempitem.top = 1;
            this.selectedGoodsTable.data.unshift(tempitem);
            let index = this.selectedGoodsTable.data.indexOf(item);
            this.selectedGoodsTable.data.splice(index, 1);
        },
        // 删除选中的商品（删除）
        deleteSelectedGoods(item) {
            let index = this.selectedGoodsTable.data.indexOf(item);

            if (index > -1) {
                this.selectedGoodsTable.data.splice(index, 1);
                this.selectedGoodsTable.total =
                    this.selectedGoodsTable.data.length;
            }
        },
        //图片上传
        setOpenInsert(obj) {
            this.insertType = obj;
            this.insertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.insertImage = result;
        },
        getImage(obj, image) {
            if (obj == 'addForm.banner') {
                this.addForm.banner = image[image.length - 1];
            }
            // this.dialogImage = false
        },
        selectTempImg(img) {
            this.addForm.banner = img;
        },
        openAdd() {
            this.addForm = {
                // merchant: null,
                title: null,
                sortType: null,
                banner: null,
                goodsIdList: [],
            };
            this.selectedGoodsTable.data = [];
            this.addLoading = false;
            this.dialogGenerate = true;
        },
        editThis(id) {
            this.loading = true;
            this.$http
                .get('/setting/distribution/getDetail?id=' + id)
                .then((response) => {
                    if (response.data.code === 200) {
                        console.log(response.data.data);
                        let data = response.data.data;
                        this.addForm = {
                            id: data.id,
                            title: data.title,
                            sortType: data.sortType,
                            banner: data.banner,
                            goodsIdList: [],
                        };
                        this.selectedGoodsTable.data = data.goodsList;
                        this.dialogGenerate = true;
                        this.loading = false;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        previewThis(url) {
            window.open(url);
        },
        addData() {
            this.addForm.goodsIdList = [];
            for (let i = 0; i < this.selectedGoodsTable.data.length; i++) {
                this.addForm.goodsIdList.push(
                    this.selectedGoodsTable.data[i].id
                );
            }
            if (this.addForm.goodsIdList.length < 1) {
                this.$message.warning('Please select an item.');
                return false;
            }

            let url;
            if (this.addForm.id) {
                url = '/setting/distribution/edit';
            } else {
                url = '/setting/distribution/add';
            }
            this.addLoading = true;
            this.$refs['addForm'].validate((valid) => {
                if (valid) {
                    this.$http
                        .post(url, this.addForm)
                        .then((response) => {
                            this.addLoading = false;
                            if (response.data.code === 200) {
                                this.$message({
                                    message: 'Update.',
                                    type: 'success',
                                });
                                this.querySearch();
                                this.dialogGenerate = false;
                            }
                        })
                        .catch((error) => {
                            this.addLoading = false;
                            // this.$message.error(error);
                        });
                } else {
                    this.addLoading = false;
                }
            });
        },
		openView(url) {
		    window.open(url);
		},
		intoOrder(id) {
		    this.visibleDialogOrder = true;
		    this.orderId = id
		    this.queryOrderData();
		},
		async queryOrderData() {
		    let [startDate, endDate] = this.date || [],
		        {
		            data: { code, data, count },
		        } = await this.$http.get(`/statistic/Distribution/getDistributionOrder`, {
		            params: {
		                startDate,
		                endDate,
		                ...this.orderForm,
		                distributionPageId: this.orderId,
		            },
		        });
		
		    if (code === 200) {
		        this.orderData = data;
		        this.orderTotal = count;
		    }
		},
		resetDialogOrder() {
		    Object.assign(
		        this.orderForm,
		        this.$options.data.call(this).orderForm
		    );
		
		    this.orderId = null;
		    this.orderData = [];
		    this.orderTotal = 0;
		},
        delThis(id) {
            this.loading = true;
            this.$confirm('Confirm the deletion?', { type: 'warning' }).then(
                () => {
                    this.$http
                        .delete('/setting/distribution/delete?id=' + id)
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.$message.success('Deleted.');
                                this.queryData();
                                this.loading = false;
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            );
        },
        closedGenerate() {},
        getPublishedB(status) {
            switch (status) {
                case 0:
                    return 'Busy';
                case 1:
                    return 'Active';
            }
        },
        getSortType(type) {
            let name = '';
            for (let i = 0; i < this.sortTypeOptions.length; i++) {
                let item = this.sortTypeOptions[i];
                if (item.value == type) {
                    name = item.name;
                    break;
                }
            }
            return name;
        },
		// 复制成功
		onCopySuccess() {
		    this.$message.success('复制成功');
		},
		// 复制失败
		onCopyError() {
		    this.$message.error('复制失败');
		},
		getFulfillmentStatus(status) {
		    switch (status) {
		        case '1':
		            return '待发货';
		        case '2':
		            return '已发货';
		        case '3':
		            return '已完成';
		        case '4':
		            return '已退货';
		    }
		},
		getGoodsOption(obj) {
		    let str = '';
		    for (let item in obj) {
		        str += item + ':' + obj[item] + ' / ';
		    }
		    str = str.substring(0, str.length - 3);
		    return str;
		},
		
		bridgeOrderInfo(row) {
		    this.$refs['orderInfo'].getOrderDetail(row);
		},
    },
};
</script>

<style scoped>
.main-wrap {
    height: auto;
}
.el-image {
    width: 100%;
    height: 100%;
}
.ImageBox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 120px;
    height: 120px;
}
.ImageDeleteButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: none;
    z-index: 2;
}
.mask_d5 {
    background-color: rgba(0, 0, 0, 0.5);
}
.ImageBox > .mask_d5 {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
}
.ImageBox:hover .ImageDeleteButton,
.ImageBox:hover .mask_d5 {
    display: block;
}
.frow {
    display: grid;
    grid-template-columns: 1fr 500px;
    grid-column-gap: 20px;
}
.tempImgbox {
    width: 200px;
    height: 112px;
    margin-right: 15px;
    margin-bottom: 15px;
}
.img-uploader-icon,
.ImageBox {
    width: 200px;
    height: 112px;
}
.wrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}
.wrapper .itembox {
    width: 180px;
    flex-basis: 180px;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    padding: 5px 5px 0 5px;
    border-radius: 10px;
    background-image: linear-gradient(315deg, #e2e2e2, #fff);
    position: relative;
}
.wrapper .itembox .imgbox {
    width: 100%;
    height: 220px;
}
.wrapper .itembox .tit {
    height: 32px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.wrapper .itembox .between {
    display: flex;
    border-top: 1px solid #ccc;
    margin: 0;
    height: 30px;
}
.wrapper .itembox .w50 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 50%;
    width: 50%;
    height: 100%;
    cursor: pointer;
    color: #409eff;
    font-size: 20px;
}
.wrapper .itembox .published {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    padding: 2px 5px;
    font-size: 14px;
    color: #fff;
    background: #409eff;
}
.wrapper .itembox .published.publishedA {
    color: #333;
    background: #ddd;
}
.wrapper .itembox .published.publishedB {
    color: #fff;
    background: #409eff;
}
.wrapper .itembox .w50:first-child {
    border-right: 1px solid #ccc;
}
</style>
