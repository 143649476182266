<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商品管理</el-breadcrumb-item>
            <el-breadcrumb-item>颜色管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 查询条件 -->
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item>
                <el-input v-model="queryForm.colorCn" placeholder="颜色中文名"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="queryForm.colorEn" placeholder="颜色英文名"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="initAddForm">新增</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
            </el-form-item>
        </el-form>
        <!-- 数据表 -->
        <el-table ref="tableData" border stripe :data="tableData" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" default-expand-all row-key="id" :tree-props="{ children: 'colorList' }">
            <el-table-column prop="colorCn" label="颜色中文名" min-width="120"></el-table-column>
            <el-table-column prop="colorEn" label="颜色英文名" min-width="120"></el-table-column>
            <el-table-column label="图片" min-width="120">
                <template slot-scope="scope">
                    <el-image fit="contain" style="width: 80px; height: 80px" v-if="scope.row.colorImage" :src="scope.row.colorImage">
                        <div slot="error" class="el-image__error" style="width: 80px; height: 80px; font-size: 10px">无图</div>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column label="启用状态" width="120">
                <template slot-scope="scope">
                    <i class="el-icon-loading mr_1" v-if="scope.row.loadingSetStatus"></i>
                    <el-switch class="MySwitch" :active-value="1" :inactive-value="0" v-model="scope.row.isOpen" @change="setStatus( scope.row, $event )">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="240" header-align="center" align="center">
                <template slot-scope="scope">

                    <el-button v-show="scope.row.parentId == 0" type="text" size="mini" icon="el-icon-plus" @click="initAddSubCategory( scope.row )">
                        新增子分类
                    </el-button>
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="initEditForm(scope.row.id)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 新增 -->
        <el-dialog title="新增颜色" :visible.sync="dialogAdd" top="3%" width="30%">
            <el-form :model="goodsColorAddForm" :rules="goodsColorRules" status-icon ref="goodsColorAddForm" label-position="top" size="medium">
                <el-form-item label="颜色中文名" prop="colorCn">
                    <el-input placeholder="请输入颜色中文名" v-model="goodsColorAddForm.colorCn" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="颜色英文名" prop="colorEn">
                    <el-input placeholder="请输入颜色英文名" v-model="goodsColorAddForm.colorEn" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="父级" v-if="this.goodsColorAddForm.id">
                    <el-select v-model="goodsColorAddForm.parentId" filterable clearable placeholder="请选择">
                        <el-option v-for="item in getEditParentedOptions" :key="item.id" :label="item.colorCn" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="颜色图片" prop="colorImage">
                    <section class="ImageBox pr df jc_c ai_c" v-if="goodsColorAddForm.colorImage">
                        <el-image fit="contain" class="Image" :src="goodsColorAddForm.colorImage">
                            <div slot="error" class="el-image__error">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <div class="pa100 mask_d5">
                            <el-button type="danger" class="pa_cen cp" icon="el-icon-delete" circle @click="goodsColorAddForm.colorImage = null">
                            </el-button>
                        </div>
                    </section>
                    <i class="el-icon-plus img-uploader-icon cp ImageBox" style="border: dotted 1px #AAA; border-radius: 4px;" v-else @click="openImageUploader( 'goodsColorAddForm.colorImage' )">
                    </i>
                    <span class="fs12" style="color: #909399;">（只能上传jpg、png文件，且每张图片不超过5mb）</span>
                </el-form-item>
                <el-form-item style="margin-top: -30px;">
                    <el-input type="hidden" class="dn_i" v-model="goodsColorAddForm.colorImage"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                <el-button @click="dialogAdd = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 编辑 -->
        <el-dialog title="编辑颜色" :visible.sync="dialogEdit" top="3%" width="30%">
            <el-form :model="goodsColorEditForm" :rules="goodsColorEditRules" status-icon ref="goodsColorEditForm" label-position="top" size="medium">
                <el-form-item label="颜色中文名" prop="colorCn">
                    <el-input placeholder="请输入颜色中文名" v-model="goodsColorEditForm.colorCn" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="颜色英文名" prop="colorEn">
                    <el-input placeholder="请输入颜色英文名" v-model="goodsColorEditForm.colorEn" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="父级">
                    <el-select v-model="goodsColorEditForm.parentId" filterable clearable placeholder="请选择">
                        <el-option v-for="item in getEditParentedOptions" :key="item.id" :label="item.colorCn" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="颜色图片" prop="colorImage">
                    <section class="ImageBox pr df jc_c ai_c" v-if="goodsColorEditForm.colorImage">
                        <el-image fit="contain" class="Image" :src="goodsColorEditForm.colorImage">
                            <div slot="error" class="el-image__error">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <div class="pa100 mask_d5">
                            <el-button type="danger" class="pa_cen cp" icon="el-icon-delete" circle @click="goodsColorEditForm.colorImage = null">
                            </el-button>
                        </div>
                    </section>
                    <i class="el-icon-plus img-uploader-icon cp ImageBox" style="border: dotted 1px #AAA; border-radius: 4px;" v-else @click="openImageUploader( 'goodsColorEditForm.colorImage' )">
                    </i>
                    <span class="fs12" style="color: #909399;">（只能上传jpg、png文件，且每张图片不超过5mb）</span>
                </el-form-item>
                <el-form-item style="margin-top: -30px;">
                    <el-input type="hidden" class="dn_i" v-model="goodsColorEditForm.colorImage"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="editLoading" @click="editData">确定</el-button>
                <el-button @click="dialogEdit = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 新增子分类 -->
        <el-dialog title="新增子分类" :visible.sync="dialogAddSub" :close-on-click-modal="false" top="3%" width="500px">
            <el-form ref="dictAddSubForm" :rules="addSubRules" :model="dictAddSubForm" label-width="120px" label-position="top">
                <el-form-item label="颜色中文名" prop="colorCn">
                    <el-input placeholder="请输入颜色中文名" v-model="dictAddSubForm.colorCn" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="颜色英文名" prop="colorEn">
                    <el-input placeholder="请输入颜色英文名" v-model="dictAddSubForm.colorEn" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="颜色图片" prop="colorImage">
                    <section class="ImageBox pr df jc_c ai_c" v-if="dictAddSubForm.colorImage">
                        <el-image fit="contain" class="Image" :src="dictAddSubForm.colorImage">
                            <div slot="error" class="el-image__error">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <div class="pa100 mask_d5">
                            <el-button type="danger" class="pa_cen cp" icon="el-icon-delete" circle @click="dictAddSubForm.colorImage = null">
                            </el-button>
                        </div>
                    </section>
                    <i class="el-icon-plus img-uploader-icon cp ImageBox" style="border: dotted 1px #AAA; border-radius: 4px;" v-else @click="openImageUploader( 'dictAddSubForm.colorImage' )">
                    </i>
                    <span class="fs12" style="color: #909399;">（只能上传jpg、png文件，且每张图片不超过5mb）</span>
                </el-form-item>
                <el-form-item style="margin-top: -30px;">
                    <el-input type="hidden" class="dn_i" v-model="dictAddSubForm.colorImage"></el-input>
                </el-form-item>
                <el-form-item label="父id">
                    <el-input :disabled="true" v-model="dictAddSubForm.parentId"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :loading="addSubLoading" @click="addSubData">确定</el-button>
                    <el-button @click="dialogAddSub = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 插入图片 -->
        <ImageUploader ref="uploadImage" :insertImage="dialogInsertImage" :insertType="insertType" :activeName="activeName" @getOpenState="getOpenState" @getImage="getImage">
        </ImageUploader>
    </div>
</template>

<script>
import _ from 'lodash';
import ImageUploader from '@/components/ImageUploader';
export default {
    name: 'GoodsColor',
    components: {
        ImageUploader: ImageUploader,
    },
    data() {
        return {
            queryForm: {
                colorCn: null,
                colorEn: null,
                id: null,
            },
            tableData: [],
            loading: false,
            dialogAdd: false,
            goodsColorRules: {
                colorCn: [
                    {
                        required: true,
                        message: '请输入中文名称',
                        trigger: 'blur',
                    },
                ],
                colorEn: [
                    {
                        required: true,
                        message: '请输入英文名称',
                        trigger: 'blur',
                    },
                ],
                colorImage: [
                    { required: true, message: '请上传图片', trigger: 'blur' },
                ],
            },
            goodsColorAddForm: {
                colorCn: null,
                colorEn: null,
                colorImage: null,
            },
            addLoading: false,
            dialogEdit: false,
            goodsColorEditRules: {
                colorCn: [
                    {
                        required: true,
                        message: '请输入中文名称',
                        trigger: 'blur',
                    },
                ],
                colorEn: [
                    {
                        required: true,
                        message: '请输入英文名称',
                        trigger: 'blur',
                    },
                ],
                colorImage: [
                    { required: true, message: '请上传图片', trigger: 'blur' },
                ],
            },
            goodsColorEditForm: {
                colorCn: null,
                colorEn: null,
                colorImage: null,
            },
            editLoading: false,
            /* 图片 */
            dialogInsertImage: false,
            activeName: 'first',
            insertType: null,
            editColor: null,
            dialogAddSub: false,
            dictAddSubForm: {},
            addSubRules: {},
            addSubLoading: false,
            getEditParentedOptions: {},
        };
    },
    mounted() {
        this.queryData();
    },
    methods: {
        // 图片
        openImageUploader(obj) {
            this.insertType = obj;
            this.dialogInsertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.dialogInsertImage = result;
        },
        getImage(obj, image) {
            switch (obj) {
                case 'goodsColorAddForm.colorImage':
                    this.goodsColorAddForm.colorImage = image[image.length - 1];
                    break;
                case 'dictAddSubForm.colorImage':
                    this.dictAddSubForm.colorImage = image[image.length - 1];
                    break;
                case 'goodsColorEditForm.colorImage':
                    this.goodsColorEditForm.colorImage =
                        image[image.length - 1];
                    break;
            }
        },
        /* 插入图片方法 end */

        querySearch() {
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        queryData() {
            this.loading = true;
            this.$http
                .get('/goods/color/getThreeList', { params: this.queryForm })
                .then((response) => {
                    if (response.data.code === 200) {
                        this.tableData = response.data.data.map((row) => {
                            row.loadingSetStatus = false;
                            return row;
                        });
                        this.total = response.data.count;
                    }
                    // 解决表格固定列错位
                    this.$refs.tableData.doLayout();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 新增
        initAddForm() {
            this.dialogAdd = true;
            this.addLoading = false;
            this.goodsColorAddForm = {
                colorCn: null,
                colorEn: null,
                colorImage: null,
                parentId: 0,
            };
        },
        // 编辑
        initEditForm(id) {
            this.dialogEdit = true;
            this.editLoading = false;
            this.$http
                .get('/goods/color/getDetail?id=' + id)
                .then((response) => {
                    if (response.data.code === 200) {
                        // let data= JSON.stringify(response.data.data)
                        let data = response.data.data;
                        // this.data= response.data.data
                        this.getEditParentedOptions =
                            response.data.data.parentList;
                        console.log(data);
                        this.goodsColorEditForm = {
                            id: data.id,
                            colorCn: data.colorCn,
                            colorEn: data.colorEn,
                            colorImage: data.colorImage,
                            parentId: data.parentId ? data.parentId : 0,
                        };
                    }
                    this.editLoading = false;
                    this.dialogEdit = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addData() {
            this.addLoading = true;
            this.$http
                .post('/goods/color/add', this.goodsColorAddForm)
                .then((response) => {
                    this.addLoading = false;
                    if (response.data.code === 200) {
                        this.dialogAdd = false;
                        this.$message({
                            message: '新增成功',
                            type: 'success',
                            duration: 1000,
                            onClose: () => {
                                this.queryData();
                            },
                        });
                    }
                })
                .catch((error) => {
                    // this.$message.error(error);
                    this.addLoading = false;
                });
        },
        editData() {
            this.editLoading = true;
            this.$http
                .post('/goods/color/edit', this.goodsColorEditForm)
                .then((response) => {
                    this.editLoading = false;
                    if (response.data.code === 200) {
                        this.dialogEdit = false;
                        this.$message({
                            message: '编辑成功',
                            type: 'success',
                            duration: 1000,
                            onClose: () => {
                                this.queryData();
                            },
                        });
                    }
                })
                .catch((error) => {
                    // this.$message.error(error);
                    this.editLoading = false;
                });
        },
        // 是否启用
        setStatus(row, value) {
            this.$confirm(`确认要${value == 0 ? '禁用' : '开启'}吗？`, {
                type: 'warning',
            })
                .then(() => {
                    row.loadingSetStatus = true;
                    row.isUse = value;
                    let off = {
                        id: row.id,
                        isOpen: value,
                    };
                    this.$http
                        .get('/goods/color/onOff', { params: off })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '设置成功',
                                    duration: 1000,
                                    onClose: () => {
                                        this.queryData();
                                    },
                                });
                                // this.queryData();
                            }
                        })
                        .catch(() => (row.isUse = value == 0 ? 1 : 0))
                        .finally(() => (row.loadingSetStatus = false));
                })
                .catch(() => this.queryData());
        },
        // 新增子类
        initAddSubCategory(data) {
            (this.dialogAddSub = true), (this.addSubLoading = false);
            this.dictAddSubForm = {
                categoryNameCn: null,
                categoryNameEn: null,
                sort: null,
                parentId: data.id,
            };
            console.log(this.dictAddSubForm, data.parentId);
        },
        addSubData() {
            this.$refs['dictAddSubForm'].validate((valid) => {
                if (valid) {
                    this.addSubLoading = true;
                    this.$http
                        .post('/goods/color/add', this.dictAddSubForm)
                        .then((response) => {
                            this.addSubLoading = false;
                            if (response.data.code === 200) {
                                this.dialogAddSub = false;
                                this.$message({
                                    message: '子类新增成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: () => {
                                        this.queryData();
                                    },
                                });
                            }
                        })
                        .catch((error) => {
                            // this.$message.error(error);
                            this.addSubLoading = false;
                        });
                }
            });
        },
    },
};
</script>

<style scoped>
.main-wrap {
    height: auto;
}
/* 颜色图片 */
.ImageBox {
    margin-right: 20px;
    width: 80px !important;
    height: 80px !important;
}

.Image {
    max-width: 80px;
    max-height: 80px;
}

.ImageBox > .mask_d5 {
    display: none;
}

.ImageBox:hover > .mask_d5 {
    display: block;
}
/* 颜色图片 end */
</style>