<template>
    <div>
        <el-dialog fullscreen id="orderDetailDialog" :title="id ? '编辑博客' : '新建博客' " :visible.sync="visibleDialog" :close-on-click-modal="false" @closed="resetInfo">
            <el-form :model="form" :rules="rules" ref="form">
                <div class="box">
                    <div class="panel panel-left">
                        <el-form-item label="博客封面">
                            <div class="mod-photo">
                                <template v-if="form.image">
                                    <div class="ImageBox">
                                        <el-image fit="cover" class="Image" :src="form.image">
                                            <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                                        </el-image>
                                        <div class="mask_d5"></div>
                                        <el-button type="danger" icon="el-icon-delete" circle class="ImageDeleteButton" @click="form.image = null"></el-button>
                                    </div>
                                </template>
                                <i v-else class="el-icon-plus img-uploader-icon ImageAddButton" @click="openImageUploader( 'form')"></i>
                            </div>
                        </el-form-item>
                        <el-form-item label="博客标题" prop="title">
                            <el-input v-model="form.title"></el-input>
                        </el-form-item>
                        <el-form-item label="博客摘要">
                            <el-input v-model="form.excerpts" type="textarea"></el-input>
                        </el-form-item>
                        <el-form-item label="正文" prop="content" class="content-mod">
                            <vue-tinymce v-model="form.content" :setup="setup" :setting="setting"></vue-tinymce>
                        </el-form-item>
                    </div>
                    <div class="panel panel-right">
                        <div class="mod">
                            <h3>基础信息</h3>
                            <el-form-item label="是否发布">
                                <el-switch v-model="form.isPublish" :active-value="1" :inactive-value="0">
                                </el-switch>
                            </el-form-item>
                            <!-- prop="sendDate" -->
                            <el-form-item label="发布日期" prop="sendDate" class="read-mod">
                                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.sendDate">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="阅读时间" class="read-mod" prop="readTime">
                                <el-input v-model="form.readTime"></el-input>
                                <span class="ft-min">分钟</span>
                            </el-form-item>
                        </div>

                        <div class="mod">
                            <h3>SEO信息</h3>
                            <el-form-item label="SEO标题">
                                <el-input v-model="form.seoTitle"></el-input>
                            </el-form-item>
                            <el-form-item label="SEO描述">
                                <el-input v-model="form.seoDesc"></el-input>
                            </el-form-item>
                            <el-form-item label="SEO链接">
                                <el-input v-model="form.seoUrl">
                                    <template slot="prepend">{{ prevUrl }}</template>
                                    <template slot="append">{{ afterName }}</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="SEO关键词">
                                <div class="mod-tags">
                                    <el-tag :key="index" v-for="(tag, index) in form.seoKeywords" closable :disable-transitions="false" @close="handleClose(tag)">
                                        {{ tag }}
                                    </el-tag>
                                    <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
                                    </el-input>
                                    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增</el-button>
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="loading== 'addLoading' " @click="bridgeSubmit">确定</el-button>
                <el-button @click="visibleDialog = false">取消</el-button>
            </div>
        </el-dialog>
        <ImageUploader ref="uploadImage" :insertImage="dialogInsertImage" :insertType="insertType" :activeName="activeName" @getOpenState="getOpenState" @getImage="getImage">
        </ImageUploader>

        <VideoUploader ref="uploadVideo" :insertVideo="dialogInsertVideo" :insertVideoType="insertVideoType" :activeVideo="activeVideo" @getOpenVideo="getOpenVideo" @getVideo="getVideo">
        </VideoUploader>
    </div>
</template>
<script>
import ImageUploader from '@/components/ImageUploader';
import VideoUploader from '@/components/VideoUploader';
export default {
    props: {
        seoUrl: {
            default: '',
        },
    },
    name: 'BlogsInfo',
    components: {
        ImageUploader,
        VideoUploader,
    },
    data: () => ({
        /* 插入图片参数 */
        dialogInsertImage: false,
        activeName: 'first',
        insertType: null,
        insertIndex: null,
        /* 插入图片参数 end */

        dialogInsertVideo: false,
        activeVideo: 'first',
        insertVideoType: null,
        dialogVisibleVideo: false,
        dialogVideoUrl: null,

        // textare info
        setting: {
            menubar: 'edit view format tools table',
            toolbar:
                'undo redo removeformat | fullscreen | imageUpload | videoUpload code | styleselect alignleft aligncenter alignright alignjustify | link | numlist bullist | fontselect fontsizeselect forecolor backcolor | table bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
            toolbar_mode: 'sliding',
            quickbars_insert_toolbar: 'quicktable',
            quickbars_selection_toolbar:
                'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
            plugins:
                'link image media code table textpattern lists fullscreen quickbars',
            language: 'zh_CN',
            height: 350,
            convert_urls: false,
        },

        inputVisible: false,
        inputValue: '',

        loading: null,
        visibleDialog: false,
        form: {
            content: '',
            excerpts: '',
            image: '',
            isPublish: 0,
            readTime: '',
            sendDate: '',
            seoDesc: '',
            seoKeywords: [],
            seoTitle: '',
            seoUrl: '',
            title: '',
            id: '',
        },
        id: null,
        afterName: '-id.html',
        prevUrl: '',
        rules: {
            title: [
                { required: true, message: '请输入博客标题', trigger: 'blur' },
            ],
            readTime: [
                {
                    required: true,
                    message: '请输入阅读时间',
                    trigger: 'blur',
                },
            ],
            content: [
                {
                    required: true,
                    message: '请输入正文',
                    trigger: 'blur',
                },
            ],
            sendDate: [
                {
                    // type: 'date',
                    required: true,
                    message: '请选择发布日期',
                    trigger: 'change',
                },
            ],
        },
    }),
    // watch: {
    //     'form.title': {
    //         handler(newValue, oldValue) {
    //             if (!this.id) {
    //                 this.afterName = `-${newValue}.html`;
    //             }
    //         },
    //         deep: true,
    //         immediate: true,
    //     },
    // },
    methods: {
        show(row = {}) {
            let data = JSON.parse(JSON.stringify(row));
            let { url, seoUrl, seoKeywords, ...args } = data;
            if ((data.id ?? '') !== '') {
                this.id = data.id;
                this.afterName = `-${data.id}.html`;
                this.prevUrl = url;

                if (seoUrl) {
                    let _url = seoUrl
                        .replace(this.prevUrl, '')
                        .replace(`-${data.id}.html`, '');

                    this.form = {
                        seoKeywords: seoKeywords || [],
                        seoUrl: _url,
                        ...args,
                    };
                } else {
                    this.form = {
                        seoKeywords: seoKeywords || [],
                        ...args,
                    };
                }
            } else {
                this.prevUrl = row.url;
            }
            this.visibleDialog = true;
        },
        // 插入图片方法
        openImageUploader(obj, index = 0) {
            this.insertType = obj;
            this.insertIndex = index;
            this.dialogInsertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.dialogInsertImage = result;
        },
        getImage(obj, image) {
            switch (obj) {
                case 'form':
                    this.form.image = image[0];
                    break;
                case 'tinymce':
                    this.form.content += `<img src="${
                        image[image.length - 1]
                    }"/>`;
                    break;
            }
            // this.dialogInsertImage = false;
        },
        setOpenInsert(obj) {
            this.insertType = obj;
            this.dialogInsertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        /* 插入视频方法 */
        openVideoUploader(obj) {
            this.insertVideoType = obj;
            this.dialogInsertVideo = true;
            this.$refs.uploadVideo.openInsert();
        },
        getOpenVideo(result) {
            this.dialogInsertVideo = result;
        },
        setup(editor) {
            let that = this;
            editor.ui.registry.addButton('imageUpload', {
                tooltip: '插入图片',
                icon: 'image',
                onAction: () => {
                    that.setOpenInsert('tinymce');
                },
            });
            editor.ui.registry.addButton('videoUpload', {
                tooltip: '插入视频',
                icon: 'embed',
                onAction: () => {
                    that.openVideoUploader('tinymce');
                },
            });
        },
        getVideo(obj, video) {
            // console.log(obj)
            switch (obj) {
                case 'tinymce':
                    // if ( !this.editGoodsCommentForm.videos ) this.editGoodsCommentForm.videos = [];
                    // this.editGoodsCommentForm.videos.push( ...video );
                    this.form.content += `<video width="100%" controls autoplay loop><source src="${
                        video[video.length - 1]
                    }"/></video>`;
                    break;
                default:
                    break;
            }
        },
        /* 插入图片方法 end */

        // tag
        showInput() {
            this.inputVisible = true;
            this.$nextTick(() => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.form.seoKeywords.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        handleClose(tag) {
            this.form.seoKeywords.splice(this.form.seoKeywords.indexOf(tag), 1);
        },
        bridgeSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitInfo();
                }
            });
        },
        submitInfo() {
            let params = {
                    ...this.form,
                },
                msg = this.id ? '编辑' : '新增',
                flag = this.id ? 'edit' : 'add';

            this.loading = 'addLoading';
            this.$http
                .post(`/blog/${flag}`, params)
                .then((res) => {
                    let { code } = res.data;
                    if (code === 200) {
                        this.$message.success(`${msg}成功~`);
                        this.visibleDialog = false;
                        this.$emit('submitCallback', 1);
                    }
                })
                .finally(() => {
                    this.loading = null;
                });
        },
        resetInfo() {
            Object.assign(this.form, {}, this.$options.data.call(this).form);
            this.afterName = '-id.html';
            this.id = null;
            this.$refs.form?.clearValidate();
        },
    },
};
</script>
<style scoped>
.box {
    display: flex;
}
.panel-left {
    flex: 1 1 auto;
    background: rgb(255, 255, 255);
    padding: 16px 24px 32px;
}
.panel-right {
    flex: 0 0 520px;
    background: rgb(255, 255, 255);
    padding: 24px;
    margin-left: 16px;
}
.mod-photo {
    justify-content: flex-start;
}
.tox-tinymce {
    width: 100%;
}
.read-mod {
    display: flex;
}
::v-deep .read-mod .el-form-item__content {
    display: flex;
}
.ft-min {
    margin-left: 20px;
    min-width: 40px;
}

::v-deep .content-mod .el-form-item__label {
    float: none;
}
</style>