<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>物流管理</el-breadcrumb-item>
            <el-breadcrumb-item>发货地址</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 标题 -->
        <div class="df jc_sb ai_c mb_3">
            <el-button type="success" icon="el-icon-plus" @click="initAddAddress">新增发货地址</el-button>
        </div>

        <!-- 数据表 -->
        <div class="oa" v-loading="loading">
            <div class="List_Address df ai_c mb_3 p_3" v-for="item in tableData" :key="item.id">
                <div class="List_Address_Info mr_a">
                    {{ item.cnCountry }}
                    <el-tag type="success" size="medium" v-if="item.isDefault == 1">默认</el-tag><br />
                    {{ item.cnProvince }} {{ item.city }}<br />
                    {{ item.addressInfo }}<br />
                    {{ item.postcode }}<br />
                    {{ item.phone }}
                </div>
                <el-button type="success" icon="el-icon-success" v-if="item.isDefault == 0" :loading="btnListLoading == 's' + item.id" @click="setDefaultAddress(item, 's')">
                    设为默认
                </el-button>
                <el-button type="primary" icon="el-icon-edit" @click="initEditAddress(item)">编辑</el-button>
                <el-button type="danger" icon="el-icon-delete" :loading="btnListLoading == 'd' + item.id" @click="deleteAddress(item, 'd')">
                    删除
                </el-button>
            </div>
        </div>

        <!-- 新增发货地址 -->
        <el-dialog top="3%" title="新增发货地址" width="750px" :visible.sync="dialogAddAddress" :close-on-click-modal="false">
            <el-form ref="addAddressForm" size="medium" label-position="top" status-icon :model="addAddressForm" :rules="addAddressRules">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="选择国家" prop="countryId">
                            <el-select v-model="addAddressForm.countryId" @change="updateProvince($event, 'addAddressForm')">
                                <el-option v-for="item in countries" :key="item.id" :label="item.cnName" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="选择省份" prop="provinceId">
                            <el-select v-model="addAddressForm.provinceId">
                                <el-option v-for="item in provinces" :key="item.id" :label="item.cnName" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="城市" prop="city">
                            <el-input maxlength="20" show-word-limit v-model="addAddressForm.city"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="详细地址" prop="addressInfo">
                    <el-input maxlength="80" show-word-limit v-model="addAddressForm.addressInfo"></el-input>
                </el-form-item>
                <el-form-item label="邮编" prop="postcode">
                    <el-input maxlength="10" show-word-limit v-model="addAddressForm.postcode"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone">
                    <el-input maxlength="20" show-word-limit v-model="addAddressForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="默认地址" prop="isDefault">
                    <el-checkbox v-model="addAddressForm.isDefault">设为默认地址</el-checkbox>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading" @click="addAddress">确定</el-button>
                <el-button @click="dialogAddAddress = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 编辑发货地址 -->
        <el-dialog top="3%" title="编辑发货地址" width="750px" :visible.sync="dialogEditAddress" :close-on-click-modal="false">
            <el-form ref="editAddressForm" size="medium" label-position="top" status-icon :model="editAddressForm" :rules="editAddressRules">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="选择国家" prop="countryId">
                            <el-select v-model="editAddressForm.countryId" @change="updateProvince($event, 'editAddressForm')">
                                <el-option v-for="item in countries" :key="item.id" :label="item.cnName" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="选择省份" prop="provinceId">
                            <el-select v-model="editAddressForm.provinceId">
                                <el-option v-for="item in provinces" :key="item.id" :label="item.cnName" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="城市" prop="city">
                            <el-input maxlength="20" show-word-limit v-model="editAddressForm.city"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="详细地址" prop="addressInfo">
                    <el-input maxlength="80" show-word-limit v-model="editAddressForm.addressInfo"></el-input>
                </el-form-item>
                <el-form-item label="邮编" prop="postcode">
                    <el-input maxlength="10" show-word-limit v-model="editAddressForm.postcode"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone">
                    <el-input maxlength="20" show-word-limit v-model="editAddressForm.phone"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading" @click="editAddress">确定</el-button>
                <el-button @click="dialogEditAddress = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'LogisticsShipAddress',

    data() {
        return {
            loading: false,
            btnListLoading: '',
            btnLoading: false,

            // 列表数据
            tableData: [],

            // 国家数据
            countries: [],
            // 省份数据
            provinces: [],

            // 新增地址
            dialogAddAddress: false,
            addAddressForm: {},
            addAddressRules: {
                addressInfo: [
                    {
                        required: true,
                        message: '输入详细地址',
                        trigger: 'blur',
                    },
                ],
            },

            // 编辑地址
            dialogEditAddress: false,
            editAddressForm: {},
            editAddressRules: {
                addressInfo: [
                    {
                        required: true,
                        message: '输入详细地址',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },

    mounted() {
        this.queryData();
        this.queryCountry();
    },

    methods: {
        // 获取列表数据
        queryData() {
            this.tableData = [];
            this.loading = true;
            this.loading = true;

            this.$http
                .get('/logistics/ship/address/getList')
                .then((r) => {
                    this.loading = false;

                    if (r.data.code === 200) {
                        this.loading = false;
                        this.tableData = r.data.data;
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    // this.$message.error(error);;
                });
        },

        // 获取国家数据
        queryCountry() {
            this.$http
                .get('/common/country/getList')
                .then((r) => {
                    if (r.data.code === 200) {
                        this.countries = r.data.data;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                });
        },
        // 更新省份数据
        updateProvince(val, form) {
            if (form) this[form].provinceId = null;

            this.$http
                .get('/common/province/getList', { params: { countryId: val } })
                .then((r) => {
                    if (r.data.code === 200) {
                        this.provinces = r.data.data;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                });
        },

        // 新增地址
        initAddAddress() {
            this.addAddressForm = {
                countryId: null,
                provinceId: null,
                city: null,
                addressInfo: null,
                postcode: null,
                phone: null,
                isDefault: true,
            };

            this.dialogAddAddress = true;
        },
        addAddress() {
            this.$refs.addAddressForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;

                    if (this.addAddressForm.isDefault)
                        this.addAddressForm.isDefault = 1;
                    else this.addAddressForm.isDefault = 0;

                    this.$http
                        .post(
                            '/logistics/ship/address/add',
                            this.addAddressForm
                        )
                        .then((r) => {
                            this.btnLoading = false;

                            if (r.data.code === 200) {
                                this.dialogAddAddress = false;

                                this.$message({
                                    message: '新增成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: this.queryData,
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = false;
                        });
                }
            });
        },

        // 编辑地址
        initEditAddress(item) {
            this.updateProvince(item.countryId); // 更新省份

            this.editAddressForm = {
                id: item.id,
                countryId: item.countryId,
                provinceId: item.provinceId,
                city: item.city,
                addressInfo: item.addressInfo,
                postcode: item.postcode,
                phone: item.phone,
            };

            this.dialogEditAddress = true;
        },
        editAddress() {
            this.$refs.editAddressForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;

                    this.$http
                        .post(
                            '/logistics/ship/address/edit',
                            this.editAddressForm
                        )
                        .then((r) => {
                            this.btnLoading = false;

                            if (r.data.code === 200) {
                                this.dialogEditAddress = false;

                                this.$message({
                                    message: '编辑成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: this.queryData,
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = false;
                        });
                }
            });
        },

        // 设为默认地址
        setDefaultAddress(item, kind) {
            this.$confirm('确认设为默认地址吗？', { type: 'warning' })
                .then(() => {
                    this.btnListLoading = kind + item.id;

                    this.$http
                        .post('/logistics/ship/address/edit', {
                            id: item.id,
                            isDefault: 1,
                        })
                        .then((r) => {
                            this.btnListLoading = '';

                            if (r.data.code === 200) {
                                this.$message({
                                    message: '设置成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: this.queryData,
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnListLoading = '';
                        });
                })
                .catch(() => {});
        },

        // 删除地址
        deleteAddress(item, kind) {
            this.$confirm('是否删除该运费方案？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.btnListLoading = kind + item.id;

                this.$http
                    .delete('/logistics/ship/address/delete', {
                        params: { id: item.id },
                    })
                    .then((r) => {
                        this.btnListLoading = '';

                        if (r.data.code === 200) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                                duration: 1000,
                                onClose: this.queryData,
                            });
                        }
                    })
                    .catch((err) => {
                        // this.$message.error(error);;
                        this.btnListLoading = '';
                    });
            });
        },
    },
};
</script>

<style scoped>
.List_Address {
    background-color: #f5f5f5;
}

.List_Address_Info {
    color: #555;
    line-height: 26px;
}
</style>
