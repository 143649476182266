<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>数据统计</el-breadcrumb-item>
            <el-breadcrumb-item>投放数据统计</el-breadcrumb-item>
        </el-breadcrumb>

        <el-form size="medium" status-icon :model="queryForm" :inline="true">
            <el-form-item>
                <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="date" value-format="yyyy-MM-dd" :default-value="new Date()">
                    ></el-date-picker>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData" :loading="loading == 'table' ">查询</el-button>
            </el-form-item>
        </el-form>

        <el-table border stripe :data="tableData" ref="userTable" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" @sort-change="sortTable" :default-sort="{prop: 'shopStatisticAdPage.visitUserNum', order: 'descending'}">
            <!-- <el-table-column prop="id" label="ID" width="80"></el-table-column> -->
            <el-table-column label="用户名称" prop="userName" min-width="160" fixed="left">
            </el-table-column>
            <el-table-column prop="shopStatisticAdPage.visitNum" label="页面访问量" width="160" sortable="custom"></el-table-column>
            <el-table-column prop="shopStatisticAdPage.visitUserNum" label="访问用户数" width="160" sortable="custom"></el-table-column>
            <el-table-column prop="shopStatisticAdPage.outNum" label="跳出数" width="160" sortable="custom"></el-table-column>
            <el-table-column prop="shopStatisticAdPage.outRate" label="跳出率" width="160" sortable="custom">
                <template slot-scope="scope">
                    {{scope.row.shopStatisticAdPage.outRate + '%'}}
                </template>
            </el-table-column>
            <el-table-column prop="orderSuccessNum" label="下单成功数" width="160" sortable="custom">
                <template #default="{row}">
                    <p> {{ row.shopStatisticAdPage.orderSuccessNum }}</p>
                    <el-link type="primary" @click="intoOrder(row)" v-if="row.shopStatisticAdPage.orderSuccessNum">查看</el-link>
                </template>
            </el-table-column>
            <el-table-column prop="shopStatisticAdPage.saleNum" label="销量" width="160" sortable="custom"></el-table-column>
            <el-table-column prop="shopStatisticAdPage.saleTotal" label="商品销售总额" width="160" sortable="custom"></el-table-column>
            <el-table-column prop="shopStatisticAdPage.orderSaleTotal" label="订单销售总额" width="160" sortable="custom"></el-table-column>
            <el-table-column prop="shopStatisticAdPage.conversionRate" label="转化率" width="160" sortable="custom">
                <template slot-scope="scope">
                    {{scope.row.shopStatisticAdPage.conversionRate + '%'}}
                </template>
            </el-table-column>
            <el-table-column prop="shopStatisticAdPage.buyUserNum" label="加购用户数" width="160" sortable="custom"></el-table-column>
            <el-table-column prop="shopStatisticAdPage.buyPurchaseRate" label="加购率" width="160" sortable="custom">
                <template slot-scope="scope">
                    {{scope.row.shopStatisticAdPage.buyPurchaseRate + '%'}}
                </template>
            </el-table-column>

        </el-table>

        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-dialog title="订单信息" :visible.sync="visibleDialogOrder" :close-on-click-modal="false" top="3%" width="1200px" @closed="resetDialogOrder">
            <!-- <el-table border stripe :data="orderData" ref="userTable" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000">
                <el-table-column label="ID" prop="id"></el-table-column>
            </el-table> -->

            <el-table ref="orderListTable" border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" max-height="1000" :data="orderData" v-loading="loading">
                <el-table-column fixed label="订单号" min-width="200" fixer="">
                    <template slot-scope="scope">
                        {{ scope.row.orderNo }}
                        <i class="el-icon-document-copy bold_i cp" title="复制" style="color: #000" v-clipboard:copy="scope.row.orderNo" v-clipboard:success="onCopySuccess" v-clipboard:error="onCopyError">
                        </i>
                    </template>
                </el-table-column>
                <el-table-column label="商品信息" width="200">
                    <template slot-scope="scope">
                        <div class="infobox">
                            <div v-if="scope.row.orderItems && scope.row.orderItems.length">{{ scope.row.orderItems[0].goodsTitle }} ... </div>
                            <!-- <el-button icon="el-icon-s-order" type="info" size="mini" round @click="lookDetail(scope.row.orderItems)">查看详细</el-button> -->
                            <el-popover placement="right" width="500" trigger="click">
                                <div class="infoitem">
                                    <div class="inforow" v-for="(item,index) in scope.row.orderItems" :key="index">
                                        <div class="imgbox">
                                            <GoodsImageTemplate :images="item.goodsImage" :isOrderItems="true" :orderMainImage="item.goodsImages"></GoodsImageTemplate>
                                        </div>
                                        <div class="infos">
                                            <p class="tit">{{item.goodsTitle}}</p>
                                            <p class="p">style: {{ getGoodsOption(item.goodsOption) }}</p>
                                            <p class="p">sku: {{item.sku}}</p>
                                            <p class="p">spu: {{item.spu}}</p>
                                            <p class="p">状态: {{ getFulfillmentStatus(item.fulfillmentStatus) }}</p>
                                            <p class="p">DIY状态：{{ item.isDiy ? '是' : '否' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <el-button slot="reference" type="primary" plain size="mini">查看</el-button>
                            </el-popover>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="160"></el-table-column>
                <el-table-column label="收货人" min-width="120">
                    <template slot-scope="scope">
                        {{ scope.row.shippingFirstName }} {{ scope.row.shippingLastName }}
                    </template>
                </el-table-column>
                <el-table-column label="支付状态" min-width="120">
                    <template slot-scope="scope">
                        <span>
                            {{
              financialStatusTypes.find(
                (v) => v.value == scope.row.financialStatus
              ).name
            }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="订单总价" min-width="120">
                    <template slot-scope="scope">
                        {{ $yrFormatNumber(scope.row.totalPrice, ",", 2) }}USD
                    </template>
                </el-table-column>
                <el-table-column prop="shippingCountryEn" label="国家/地区" min-width="120"></el-table-column>
                <el-table-column prop="orderSource" label="订单来源" min-width="200">
                    <template slot-scope="scope">
                        <el-tooltip :content="scope.row.orderSource" placement="top">
                            <div class="hidelink">{{ scope.row.orderSource }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="channelSource" label="渠道来源" min-width="120">
                    <template slot-scope="scope">
                        {{
                        channelSourceData.find(
                          (v) => v.value == scope.row.channelSource
                        ).name
                      }}
                    </template>
                </el-table-column>
                <el-table-column prop="adPageId" label="落地页ID" min-width="120"></el-table-column>
                <el-table-column prop="discountCode" label="优惠码" min-width="120"></el-table-column>
                <el-table-column label="物流状态" min-width="120">
                    <template slot-scope="scope">
                        {{
            fulfillmentStatusTypes.find(
              (v) => v.value == scope.row.fulfillmentStatus
            ).name
          }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="140" header-align="center" align="center">
                    <template #default="{row}">
                        <el-button type="text" size="mini" icon="el-icon-document" @click="bridgeOrderInfo(row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="orderForm.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size.sync="orderForm.pageSize" :total="orderTotal" @size-change="queryOrderData" @current-change="queryOrderData">
            </el-pagination>

        </el-dialog>

        <OrderInfo ref="orderInfo"></OrderInfo>

    </div>
</template>
<script>
// import SelectGoods from '@/components/SelectGoods';
import OrderInfo from '../order/orderInfo.vue';
import vuedraggable from 'vuedraggable';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
export default {
    name: 'Delivery',
    components: {
        vuedraggable,
        OrderInfo,
        GoodsImageTemplate,
    },
    data: () => ({
        queryForm: {
            pageNo: 1,
            pageSize: 20,
            sort: 2,
            sortType: 2,
        },
        orderForm: {
            pageNo: 1,
            pageSize: 20,
        },
        total: 0,
        date: null,
        tableData: [],
        loading: null,

        sortKeyName: [
            ,
            'visitNum',
            'visitUserNum',
            'outNum',
            'outRate',
            'orderSuccessNum',
            'saleNum',
            'saleTotal',
            'orderSaleTotal',
            'conversionRate',
            'buyUserNum',
            'buyPurchaseRate',
        ],

        totalData: {},
        visibleDialogOrder: false,
        orderId: null,
        orderTotal: 0,
        orderData: [],

        fulfillmentStatusTypes: [
            // 订单发货状态选项

            { name: '待发货', value: '1' },
            { name: '部分发货', value: '2' },
            { name: '已发货', value: '3' },
            { name: '部分完成', value: '4' },
            { name: '已完成', value: '5' },
            { name: '已退货', value: '6' },
        ],
        channelSourceData: [
            // 订单详情 - 渠道来源数据
            { name: '其它来源', value: 0 },
            { name: '直接访问', value: 1 },
            { name: 'Google', value: 2 },
            { name: 'Facebook', value: 3 },
            { name: 'Instagram', value: 4 },
            { name: 'Bing', value: 5 },
            { name: 'Baidu', value: 6 },
            { name: 'Snapchat', value: 7 },
            { name: 'pinterest', value: 8 },
            { name: 'youtube', value: 9 },
            { name: 'titok', value: 10 },
            { name: 'email', value: 11 },
        ],
        financialStatusTypes: [
            // 支付状态选项

            { name: '待支付', value: '1' },
            { name: '已支付', value: '2' },
            { name: '已取消', value: '3' },
            { name: '支付失败', value: '4' },
            { name: '待退款', value: '5' },
            { name: '部分退款', value: '6' },
            { name: '已退款', value: '7' },
            { name: '退款失败', value: '8' },
        ],
    }),
    mounted() {
        this.date = this.timeDefault();
        this.queryData();
    },
    methods: {
        queryData() {
            let url = '/statistic/ad/page/getUserPage',
                [startDate, endDate] = this.date || [],
                params = {
                    ...this.queryForm,
                    startDate,
                    endDate,
                };

            this.loading = 'table';
            this.$http
                .get(url, {
                    params,
                })
                .then((res) => {
                    let { code, data, count } = res.data;
                    if (code === 200) {
                        this.tableData = data;
                        this.total = count;
                    }
                })
                .finally(() => {
                    this.loading = null;
                });
        },
        timeDefault() {
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            if (mm < 10) {
                mm = '0' + mm;
            }
            let dd = new Date().getDate();
            if (dd < 10) {
                dd = '0' + dd;
            }
            let dateTime = yy + '-' + mm + '-' + dd;
            // console.log(dateTime)
            return [dateTime, dateTime];
        },
        getTotal(param) {
            const sums = [];
            const { columns, data } = param;
            // console.log(columns, data)
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总计';
                    return;
                }
                if (index < 6 && index !== 0) {
                    sums[index] = '';
                    return;
                }
                switch (index) {
                    case 6:
                        sums[index] = this.totalData.visitNum;
                        break;
                    case 7:
                        sums[index] = this.totalData.visitUserNum;
                        break;
                    case 8:
                        sums[index] = this.totalData.outNum;
                        break;
                    case 9:
                        sums[index] = this.totalData.outRate + '%';
                        break;
                    case 10:
                        sums[index] = this.totalData.orderSuccessNum;
                        break;
                    case 11:
                        sums[index] = this.totalData.saleNum;
                        break;
                    case 12:
                        sums[index] = this.totalData.saleTotal;
                        break;
                    case 13:
                        sums[index] = this.totalData.orderSaleTotal;
                        break;
                    case 14:
                        sums[index] = this.totalData.conversionRate + '%';
                        break;
                    case 15:
                        sums[index] = this.totalData.buyUserNum;
                        break;
                    case 16:
                        sums[index] = this.totalData.buyPurchaseRate + '%';
                        break;
                    default:
                        break;
                }
            });
            setTimeout(() => {
                this.$refs.userTable.doLayout();
            }, 100);
            return sums;
        },
        sortTable(info) {
            let { prop = '', order } = info;

            console.log(info);

            if ((order ?? '') !== '') {
                let _key = prop.match(/([^.]+)$/)?.[0],
                    sort = this.sortKeyName.findIndex((cur) => {
                        return cur == _key;
                    }),
                    sortType = order == 'ascending' ? 1 : 2;

                this.queryForm.sort = sort;
                this.queryForm.sortType = sortType;
            } else {
                this.queryForm.sort = '';
                this.queryForm.sortType = '';
            }
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        intoOrder({ userId }) {
            this.visibleDialogOrder = true;
            this.orderId = userId;
            this.queryOrderData();
        },
        async queryOrderData() {
            let [startDate, endDate] = this.date || [],
                {
                    data: { code, data, count },
                } = await this.$http.get(`/order/getAdOrderPage`, {
                    params: {
                        isItem: 1,
                        startDate,
                        endDate,
                        ...this.orderForm,
                        userId: this.orderId,
                    },
                });

            if (code === 200) {
                this.orderData = data;
                this.orderTotal = count;
            }
        },
        resetDialogOrder() {
            Object.assign(
                this.orderForm,
                this.$options.data.call(this).orderForm
            );

            this.orderId = null;
            this.orderData = [];
            this.orderTotal = 0;
        },
        bridgeOrderInfo(row) {
            this.$refs['orderInfo'].getOrderDetail(row);
        },
        // 复制成功
        onCopySuccess() {
            this.$message.success('复制成功');
        },
        // 复制失败
        onCopyError() {
            this.$message.error('复制失败');
        },
        getGoodsOption(obj) {
            let str = '';
            for (let item in obj) {
                str += item + ':' + obj[item] + ' / ';
            }
            str = str.substring(0, str.length - 3);
            return str;
        },
        getFulfillmentStatus(status) {
            switch (status) {
                case '1':
                    return '待发货';
                case '2':
                    return '已发货';
                case '3':
                    return '已完成';
                case '4':
                    return '已退货';
            }
        },
    },
};
</script>
<style>
.infoitem {
    max-height: 450px;
    overflow-y: auto;
}
.inforow {
    display: flex;
    margin: 10px 0;
    /* align-items: center; */
}
.inforow .imgbox {
    width: 120px;
    /*flex-basis: 120px;*/
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.inforow .infos {
    width: calc(100% - 150px);
    margin-left: 10px;
}
.inforow .infos p {
    text-align: left;
    margin: 0;
}
.inforow .infos p.tit {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}
.inforow .infos p.p {
    font-size: 14px;
    color: #bbb;
    line-height: 20px;
}
</style>