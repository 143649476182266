<template>
    <div>
        <template>
            <!-- <el-image fit="contain" :style="`width: ${width == 'auto' ? 'auto' :  width + 'px'}; height: ${height == 'auto' ? 'auto' :  height + 'px'}; `" :src="item" :preview-src-list="[item]">
                <div slot="error" class="el-image__error" style="width: 80px; height: 80px; font-size: 10px">
                    无图
                </div>
            </el-image> -->

            <el-popover placement="top-start" width="200" trigger="hover" v-if="hasHover">
                <img :src="item" width="200" height="200" style="object-fit:cover;" alt="" />

                <img :src="item" :style="`width: ${width == 'auto' ? 'auto' :  width + 'px'}; height: ${height == 'auto' ? 'auto' :  height + 'px'};  object-fit:cover; `" alt="" slot="reference" />
            </el-popover>
            <img v-else :src="item" :style="`width: ${width == 'auto' ? 'auto' :  width + 'px'}; height: ${height == 'auto' ? 'auto' :  height + 'px'};  object-fit:cover; `" alt="" />

        </template>
    </div>
</template>
<script>
export default {
    props: {
        images: {
            required: true,
        },
        isOrderItems: {
            type: Boolean,
            default: false,
        },
        orderMainImage: {
            type: Array,
        },
        width: {
            default: 80,
        },
        height: {
            default: 80,
        },
        hasHover: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        IMG_REGEX: /\.(jpg|jpeg|png|gif|GIF|JPG|PNG)$/,
        VIDEO_REGEX: /\.(swf|avi|flv|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4)$/,
        item: null,
    }),
    watch: {
        images: {
            handler() {
                this.setImg();
            },
            immediate: true,
        },
    },
    mounted() {
        this.setImg();
    },
    methods: {
        getTypeformatter(string) {
            if (this.IMG_REGEX.test(string)) return 'image';
            if (this.VIDEO_REGEX.test(string)) return 'video';
        },
        setImg() {
            let img = this.isOrderItems
                ? this.images || this.filters(this.orderMainImage)
                : this.filters(this.images);

            if (this.getTypeformatter(img) == 'image') {
                this.item = img;
                return;
            }
            if (this.getTypeformatter(img) == 'video') {
                this.getVideoBase64(img).then((_img) => {
                    // base64 转blob链接预览，规避base64长字符
                    let blobFils = this.convertBase64UrlToBlob(_img);
                    let img = URL.createObjectURL(blobFils);
                    this.item = img;
                });
            }
        },
        filters(imgs) {
            if (!imgs || !imgs.length) return;
            let hasImg = false;
            for (let item of imgs) {
                if (this.IMG_REGEX.test(item)) {
                    hasImg = true;
                    return item;
                }
            }
            if (!hasImg) {
                return imgs[0];
            }
        },
        getVideoBase64(url) {
            return new Promise(function (resolve, reject) {
                let dataURL = '';
                let video = document.createElement('video');
                video.setAttribute('crossOrigin', 'anonymous');
                video.setAttribute('src', url);
                video.setAttribute('width', 400);
                video.setAttribute('height', 240);
                video.setAttribute('preload', 'auto');
                video.currentTime = 1;
                video.addEventListener('loadeddata', function () {
                    let canvas = document.createElement('canvas'),
                        width = video.videoWidth,
                        height = video.videoHeight;

                    canvas.width = width;
                    canvas.height = height;
                    canvas
                        .getContext('2d')
                        .drawImage(video, 0, 0, width, height);
                    dataURL = canvas.toDataURL('image/jpeg');
                    resolve(dataURL);
                });
            });
        },
        convertBase64UrlToBlob(urlData) {
            let arr = urlData.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        },
    },
};
</script>
<style>
.video-box,
.video-box video {
    width: 80px;
    height: 80px;
}

.video-box video {
    object-fit: cover;
}
</style>