<template>
    <div class="main-wrap" :class="isHandsome ? 'handsome' : ''" v-loading="loading">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right" v-if="!isHandsome">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>数据统计</el-breadcrumb-item>
            <el-breadcrumb-item>实时数据</el-breadcrumb-item>
        </el-breadcrumb>
		
		<div class="between-center">
			<h2><b>实时数据</b> 数据更新时间：<span class="word" :class="loading ? '' : 'wordchange'">{{ realData.sysTime }}</span></h2>
			<div>
			<el-switch
				active-text="看板模式"
				inactive-text=""
				v-model="isHandsome"
				@change="changeHandsome($event)"></el-switch>
			<p v-show="isHandsome" class="f11tips">按F11可进入全屏模式</p>
			</div>
		</div>
		
		<div class="mainbox">
			<div class="left">
				<div class="top">
					<div class="list">
						<div class="listbox box">
							<h4>成交额USD</h4>
							<div class="flexhalf">
								<div class="half">
									<p class="name">实时</p>
									<p class="val word" :class="loading ? '' : 'wordchange'">{{ realData.realTimeAmount }}</p>
								</div>
								<div class="half">
									<p class="name">今日</p>
									<p class="val word" :class="loading ? '' : 'wordchange'">{{ realData.amount }}</p>
								</div>
							</div>
						</div>
						
						<div class="listbox box">
							<h4>访问用户数</h4>
							<div class="flexhalf">
								<div class="half">
									<p class="name">实时</p>
									<p class="val word" :class="loading ? '' : 'wordchange'">{{ realData.realTimeVisitUserNum }}</p>
								</div>
								<div class="half">
									<p class="name">今日</p>
									<p class="val word" :class="loading ? '' : 'wordchange'">{{ realData.visitUserNum }}</p>
								</div>
							</div>
						</div>
						<div class="listbox box">
							<h4>订单数</h4>
							<div class="flexhalf">
								<div class="half">
									<p class="name">实时</p>
									<p class="val word" :class="loading ? '' : 'wordchange'">{{ realData.realTimeOrderCount }}</p>
								</div>
								<div class="half">
									<p class="name">今日</p>
									<p class="val word" :class="loading ? '' : 'wordchange'">{{ realData.orderCount }}</p>
								</div>
							</div>
						</div>
					</div>
					<div id="AccessTrends" class="box"></div>
					<div id="VisitorAction" class="box"></div>
				</div>
				<div class="bottom">
					<div id="World" class="box"></div>
				</div>
			</div>
			
			<div class="right">
				<div class="refund">
				    <div class="refund_wrap">
						
						<div class="refund_item box">
						    <p>热门/国家地区</p>
						    <el-table :data="countryBuyData" :highlight-current-row="false" v-loading="loading" element-loading-text="加载中">
						        <el-table-column prop="countryName" label="国家名称" min-width="110">
						            <template slot-scope="scope">
						                <div class="hidetext" :title="scope.row.countryName">
						                    {{scope.row.countryName}}
						                </div>
						            </template>
						        </el-table-column>
						        <el-table-column prop="orderSuccessNum" label="订单数" min-width="80" align="center"></el-table-column>
								<el-table-column prop="orderSuccessAmount" label="成交额(USD)" min-width="110" align="center"></el-table-column>
						    </el-table>
						</div>
						
						<div class="refund_item box">
						    <p>热门商品<router-link class="link" :to="{path: '/statistic/goods', query: { sort: 2 }}">查看详情</router-link></p>
						    <el-table :data="goodsVisitData" v-loading="loading" element-loading-text="加载中">
						        <el-table-column prop="goodsTitle" label="商品标题" min-width="110">
						            <template slot-scope="scope">
						                <div class="hidetext link" :title="scope.row.goodsTitle" @click="openView(scope.row.seoUrl)">
						                    {{ scope.row.goodsTitle }}
						                </div>
						            </template>
						        </el-table-column>
						        <el-table-column prop="visitUserNum" label="访问用户数" min-width="110" align="center"></el-table-column>
						        <el-table-column prop="visitNum" label="访问量" min-width="80" align="center"></el-table-column>
						    </el-table>
						</div>
						
						<div class="refund_item box">
						    <p>热门页面<router-link class="link" :to="{path: '/statistic/page', query: { sort: 2 }}">查看详情</router-link></p>
						    <el-table :data="pageVisitData" v-loading="loading" element-loading-text="加载中">
						        <el-table-column align="left" prop="pagePath" label="页面路径" min-width="110">
						            <template slot-scope="scope">
						                <div class="hidetext link" :title="scope.row.pagePath" @click="openView(scope.row.path)">
						                    {{scope.row.pagePath}}
						                </div>
						            </template>
						        </el-table-column>
						        <el-table-column prop="visitUserNum" label="访问用户数" min-width="110" align="center"></el-table-column>
						        <el-table-column prop="visitNum" label="访问量" min-width="80" align="center"></el-table-column>
						    </el-table>
						</div>
						
					</div>
				</div>	
			</div>
		</div>
		
    </div>
</template>

<script>
import * as echarts from 'echarts';
import '@/assets/map/js/world.js'
import worldJson from '@/assets/map/world.json';
export default {
    name: 'RealTime',
	props:{
		isCollapse: Boolean,
		default: false,
	},
    data() {
        return {
			isHandsome: true,
			isChangeing: false,
			loading: false,
			globalColor: ['#0770FF', '#fd6e00', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
			realData: {
				amount: 0,
				orderCount: 0,
				realTimeAmount: 0,
				realTimeOrderCount: 0,
				realTimeVisitUserNum: 0,
				visitUserNum: 0,
				sysTime: '',
			},
			countryBuyData: [],
			goodsVisitData: [],
			pageVisitData: [],
        };
    },
    mounted() {
		this.$emit('changeClose', true)
		this.$emit('changeBlue', true)
		this.queryData()
        this.autoRefresh();
    },
	destroyed(){
		this.$emit('changeClose', false)
		this.$emit('changeBlue', false)
		clearInterval(window.refreshData)
	},
    methods: {
		openView(url) {
		    window.open(url);
		},
		changeHandsome(e){
			this.isChangeing = true
			if(e){
				this.$emit('changeClose', true)
				this.$emit('changeBlue', true)
			} else {
				this.$emit('changeClose', false)
				this.$emit('changeBlue', false)
			}
			clearInterval(window.refreshData)
			this.$nextTick(()=>{
				this.queryData()
				this.autoRefresh()
			})
		},
		async autoRefresh(){
			window.refreshData = setInterval(()=>{
				console.log('Refresh!')
				this.queryData()
			}, 60000)
		},
        // 查询
        async queryData() {
			this.loading = true
			//基础
			await this.$http
			    .get('/statistic/realTime/getRealTimeData')
			    .then(async (response) => {
			        if (response.data.code == 200) {
			            this.realData = response.data.data;
			            return true;
			        } else {
			            return false;
			        }
			    });
			//趋势	
			await this.$http
			    .get('/statistic/realTime/getAccessTrends')
			    .then(async (response) => {
			        if (response.data.code == 200) {
			            let data = response.data.data
						// console.log(data)
			            if (data.length) {
			                const nameList = []
							const timeList = []
			                const visitNumList = []
			                const visitUserList = []
							
							data.forEach((item)=>{
								nameList.push(item.name)
								timeList.push(item.time)
								visitNumList.push(item.visitNum ? item.visitNum : 0)
								visitUserList.push(item.visitUserNum ? item.visitUserNum : 0)
							})
			
							await this.accessTrendsLine(
								nameList,
								timeList,
								visitNumList,
								visitUserList
							)
			            } else {
			                
			            }
			            return true;
			        } else {
			            return false;
			        }
			    });
			//行为	
			await this.$http
			    .get('/statistic/realTime/getVisitorAction')
			    .then(async (response) => {
			        if (response.data.code == 200) {
			            let data = response.data.data
						// console.log(data)
			            if (data) {
			                const addCartUserNum = data.addCartUserNum
							const paymentUserNum = data.paymentUserNum
							const paymentSuccessUserNum = data.paymentSuccessUserNum
							
							await this.visitorActionLine(
								addCartUserNum,
								paymentUserNum,
								paymentSuccessUserNum,
							)
			            } else {
			                
			            }
			            return true;
			        } else {
			            return false;
			        }
			    });	
			//地图
			await this.$http
			    .get('/statistic/realTime/getMap')
			    .then(async (response) => {
			        if (response.data.code == 200) {
						let mapdata = response.data.data
						// console.log(mapdata)
						let namemap = worldJson.namemap
						let dataArr = worldJson.dataArr
						let max = 100
						
						for(let i = 0; i < dataArr.length; i++){
							let item = dataArr[i]
							item.value = 0
							item.visit = 0
							for(let k = 0; k < mapdata.length; k++){
								let db = mapdata[k]
								if(db.countryNameCn == item.name){
									item.value = db.orderSuccessAmount ? db.orderSuccessAmount : 0
									item.visit = db.visitUserNum ? db.visitUserNum : 0
									if(item.value > max){
										max = item.value
									}
								}
							}
						}
						
						await this.drawWorld(namemap, dataArr, max)
			            return true;
			        } else {
			            return false;
			        }
			    });	
			//热门国家
            await this.$http
                .get('/statistic/realTime/getPopularArea')
                .then(async (response) => {
                    if (response.data.code == 200) {
                        this.countryBuyData = response.data.data
                        return true;
                    } else {
                        return false;
                    }
                });
			//热门商品	
			await this.$http
			    .get('/statistic/realTime/getPopularGoods')
			    .then(async (response) => {
			        if (response.data.code) {
			            this.goodsVisitData = response.data.data;
			            return true;
			        } else {
			            return false;
			        }
			    });
			//热门页面
			await this.$http
			    .get('/statistic/realTime/getPopularPage')
			    .then(async (response) => {
			        if (response.data.code == 200) {
			            this.pageVisitData = response.data.data;
			            return true;
			        } else {
			            return false;
			        }
			    });
			this.loading = false
        },
		async accessTrendsLine(name, time, visitNum, visitUser) {
		    // 基于准备好的dom，初始化echarts实例
		    let chart = echarts.init(document.getElementById('AccessTrends'));
			
			let maxVist = 0
			for(let i = 0; i < visitNum.length; i++){
				if(visitNum[i] > maxVist){
					maxVist = visitNum[i]
				}
			}
			
			let maxUser = 0
			for(let i = 0; i < visitUser.length; i++){
				if(visitUser[i] > maxUser){
					maxUser = visitUser[i]
				}
			}
			
		    // 绘制图表
		    chart.setOption({
				title: { 
					text: '访问趋势',
					textStyle: {
					  fontSize: 18,
					  fontWeight: 600,
					  fontFamily: 'Rajdhani',
					  color: this.isHandsome ? '#fff' : '#000'
					}
				},
				visualMap: [
				    {
						show: false,
						type: 'continuous',
						seriesIndex: 0,
						min: 0,
						max: maxVist,
						inRange: {
							color: ['#07e7ff', '#0894ff', '#0770FF']
						},
				    },
					{
						show: false,
						type: 'continuous',
						seriesIndex: 1,
						min: 0,
						max: maxUser,
						inRange: {
							color: ['#ffa200', '#ff8000', '#ff6f00']
						},
					},
				],
				color: this.globalColor,
		        tooltip: {
		            trigger: 'axis',
		            axisPointer: {
		                type: 'shadow',
		            },
		            // position: function (pos, params, dom, rect, size) {
		            //     var obj = {top: 60};
		            //     obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
		            //     return obj;
		            // },
		            // formatter: function (param) {
		            //     // console.log(param)
		            //     let dataIndex = param[0].dataIndex;
		            //     let dom = param[0].name;
		            //     for (let i = 0; i < param.length; i++) {
		            //         let item = param[i];
		            //         dom += `<div><span style="color:${item.color}">`;
		            //         switch (item.seriesIndex) {
		            //             case 0:
		            //                 dom +=
		            //                     '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #0770FF;"></i>';
		            //                 break;
		            //             case 1:
		            //                 dom +=
		            //                     '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #ff6f00;"></i>';
		            //                 break;
		            //             default:
		            //                 break;
		            //         }
		            //         dom += `${item.seriesName}<span>: ${item.value}</div>`;
		            //         if (i == 1 && z2.length) {
		            //             dom += z2[dataIndex] ? z2[dataIndex] : '';
		            //             continue;
		            //         }
		            //     }
		            //     return dom;
		            // },
		        },
		        grid: {
		            top: 50,
		            right: 10,
		            left: 0,
		            bottom: 20,
		            containLabel: true,
		        },
		        legend: {
		            data: ['访问量', '访问用户数'],
					textStyle: {
						color: this.isHandsome ? '#fff' : '#999',
						fontFamily: 'Rajdhani',
					},
		        },
		        xAxis: [
		            {
		                type: 'category',
		                data: name,
		                axisLine: { 
							onZero: false,
							lineStyle: {
								color: this.isHandsome ? '#ddd' : '#999'
							},
						},
		                splitLine: {
		                    show: false,
		                },
						nameTextStyle: {
							fontFamily: 'Rajdhani',
						},
		            },
		        ],
		        yAxis: {
		            type: 'value',
		            axisLine: { 
						onZero: false,
						lineStyle: {
							color: this.isHandsome ? '#ddd' : '#999'
						},
					},
		            boundaryGap: [0, '10%'],
		            minInterval: 1,
		            splitLine: {
		                show: false,
		            },
					nameTextStyle: {
						fontFamily: 'Rajdhani',
					},
		        },
		        series: [
		            {
		                name: '访问量',
		                type: 'line',
		                data: visitNum,
		                itemStyle: {
		                    color: '#0770FF',
		                },
						lineStyle: {
							width: 3
						},
						smooth: this.isHandsome ? true : false,
		            },
		            {
		                name: '访问用户数',
		                type: 'line',
		                itemStyle: {
		                    color: '#ff6f00',
		                },
		                data: visitUser,
						lineStyle: {
							width: 3
						},
						smooth: this.isHandsome ? true : false,
						nameTextStyle: {
							fontFamily: 'Rajdhani',
						},
		            },
		        ],
		    });
			if(this.isChangeing){
				chart.resize()
			}
			return true
		},
		async visitorActionLine(add, pay, success){
		    // 基于准备好的dom，初始化echarts实例
		    let chart = echarts.init(document.getElementById('VisitorAction'));
		    // 绘制图表
		    chart.setOption({
				title: { 
					text: '访客行为',
					textStyle: {
					  fontSize: 18,
					  fontWeight: 600,
					  fontFamily: 'Rajdhani',
					  color: this.isHandsome ? '#fff' : '#000'
					}
				},
				color: this.globalColor,
		        tooltip: {
		            trigger: 'axis',
		            axisPointer: {
		                type: 'shadow',
		            },
		        },
		        grid: {
		            top: 50,
		            right: 10,
		            left: 0,
		            bottom: 20,
		            containLabel: true,
		        },
		        legend: {
		            data: ['访问量', '访问用户数'],
		        },
		        xAxis: [
		            {
		                type: 'category',
		                data: ['正在加购', '正在结账', '成功下单'],
		                axisLine: {
		                	onZero: false,
		                	lineStyle: {
		                		color: this.isHandsome ? '#ddd' : '#999',
								fontFamily: 'Rajdhani',
		                	},
		                },
		                splitLine: {
		                    show: false,
		                },
		            },
		        ],
		        yAxis: {
		            type: 'value',
		            axisLine: {
		            	onZero: false,
		            	lineStyle: {
		            		color: this.isHandsome ? '#ddd' : '#999',
							fontFamily: 'Rajdhani',
		            	},
		            },
		            boundaryGap: [0, '10%'],
		            minInterval: 1,
		            splitLine: {
		                show: false,
		            },
		        },
		        series: [
		            {
		                type: 'bar',
						barMaxWidth: 40,
		                data: [
							{
								value: add,
							    itemStyle: {
							        // color: '#ff6f00',
									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#ff9d00' },
										{ offset: 0.5, color: '#ff8902' },
										{ offset: 1, color: '#ff6f00' }
									])
							    }
							},
							{
								value: pay,
							    itemStyle: {
							        // color: '#0770FF',
									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#05b4ff' },
										{ offset: 0.5, color: '#1c7bf7' },
										{ offset: 1, color: '#0770FF' }
									])
							    }
							},
							{
								value: success,
							    itemStyle: {
							        // color: '#67C23A'
									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{ offset: 0, color: '#7fea46' },
										{ offset: 0.5, color: '#71d43f' },
										{ offset: 1, color: '#67C23A' }
									])
							    }
							},
						],
		            }
		        ],
		    });
			if(this.isChangeing){
				chart.resize()
			}
			return true
		},
		async drawWorld(name, data, max){
			let chart = echarts.init(document.getElementById('World'))
			// 监听屏幕变化自动缩放图表
			window.addEventListener('resize', function () {
				chart.resize()
			})
			chart.setOption({
				// 图表主标题
				title: {
				  text: 'My world',
				  textStyle: {
				    fontSize: 18,
				    fontWeight: 600,
					fontFamily: 'Ronduit',
				    color: this.isHandsome ? '#fff' : '#000'
				  }
				},
				grid: {
					width:'100%',
					height:'100%',
					left: '0%',
					right: '0%',
					bottom: '0%',
					containLabel: true
				},
				// 提示框组件
				tooltip: {
				  trigger: 'item', // 触发类型, 数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用
				  // 提示框浮层内容格式器，支持字符串模板和回调函数两种形式
				  // 使用函数模板  传入的数据值 -> value: number | Array
				  textStyle: {
					// fontFamily: 'Rajdhani',
				  },
				  formatter: function (val) {
					// console.log(val)
					if(val.data == null) {
						return false
					} else {
						let dom = `${val.data.name}<br>`
						dom += `<span style="color:#ff6f00;">成交额: ${val.data.value} USD</span><br>`
						dom += `<span style="color:#0770FF;">访客数: ${val.data.visit}</span>`
						return dom
					}
				  }
				},
				// 视觉映射组件
				visualMap: {
				  min: 0,
				  max: max,
				  text:['MAX','MIN'],
				  realtime: false,
				  calculable: true,
				  color: this.isHandsome ? ['#FF6347','#374f5c'] : ['#FF6347','#999'],
				  textStyle: {
				  	color: this.isHandsome ? '#fff' : '#999',
					fontFamily: 'digitalnumber',
					fontSize: 18,
				  },
				},
				series: [
				  {
					type: 'map', // 类型
					// 系列名称，用于tooltip的显示，legend 的图例筛选 在 setOption 更新数据和配置项时用于指定对应的系列
					name: '世界地图',
					mapType: 'world', // 地图类型
					// 是否开启鼠标缩放和平移漫游 默认不开启 如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move' 设置成 true 为都开启
					roam: true,
					selectedMode: false,
					select: {
						disabled: true
					},
					// projection: {
					// 	project: (point) => [point[0] / 180 * Math.PI, -Math.log(Math.tan((Math.PI / 2 + point[1] / 180 * Math.PI) / 2))],
					// 	unproject: (point) => [point[0] * 180 / Math.PI, 2 * 180 / Math.PI * Math.atan(Math.exp(point[1])) - 90]
					// },
					aspectScale: .8,
					label: {
					  show: false // 是否显示对应地名
					},
					zoom: 1	,
					scaleLimit: {
						min: .5,
						max: 5
					},
					// 地图区域的多边形 图形样式
					itemStyle: {
					  // areaColor: '#7B68EE', // 地图区域的颜色 如果设置了visualMap，areaColor属性将不起作用
					  borderWidth: 0.2, // 描边线宽 为 0 时无描边
					  borderColor: this.isHandsome ? '#78898d' : '#ccc', // 图形的描边颜色 支持的颜色格式同 color，不支持回调函数
					  borderType: 'solid' // 描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'
					},
					// 高亮状态下的多边形和标签样式
					emphasis: {
					  label: {
						show: true, // 是否显示标签
						color: '#fff604' // 文字的颜色 如果设置为 'auto'，则为视觉映射得到的颜色，如系列色
					  },
					  itemStyle: {
						areaColor: '#FF6347' // 地图区域的颜色
					  }
					},
					// 自定义地区的名称映射
					nameMap: name,
					// 地图系列中的数据内容数组 数组项可以为单个数值
					data: data
				  }
				]
			})
			if(this.isChangeing){
				chart.resize()
				this.isChangeing = false
			}
			return true
		},
    },
};
</script>

<style>
</style>
<style scoped>
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body /deep/ .el-main {
	background-color: #fff;
}
.f11tips {
	color:#fff;
	margin-top:5px;
	font-size:12px;
}
.main-wrap {
    height: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}
.hidetext {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.hidetext.link {
	cursor: pointer;
}
h2 {
	font-size: 14px;
	margin: 20px 0;
}
h2 b {
	font-size: 24px;
	display: inline-block;
	margin-right: 15px;
}
.mainbox {
	display: flex;
	flex-wrap: wrap;
}
.mainbox .left {
	flex-basis: 1200px;
	max-width: 1200px;
	margin-right: 20px;
}
.mainbox .right {
	flex-basis: 360px;
	max-width: 360px;
}
.top {
	display: grid;
	grid-template-columns: 20% 1fr 28%;
	grid-gap: 20px;
	/* height: 326px; */
	margin-bottom: 20px;
}
.top .list {
	width: 100%;
}
.box {
	border-radius: 4px;
	/* box-shadow: 0 0 8px 4px #eee; */
	border: 2px solid #d2d2d2;
	padding: 10px;
}
.listbox {
	margin-bottom: 20px;
	height: 110px;
}
.listbox:last-child {
	margin-bottom: 0;
}
.listbox h4 {
	font-size: 18px;
	margin-bottom: 18px;
}
.listbox .flexhalf {
	display: flex;
}
.listbox .flexhalf .half {
	flex-basis: 50%;
	max-width: 50%;
}
.listbox .flexhalf .half .name {
	font-size: 14px;
	margin-bottom: 6px;
}
.listbox .flexhalf .half .val {
	font-size: 20px;
	font-weight: 600;
	font-family: 'Rajdhani';
	letter-spacing: 2px;
}
#AccessTrends,
#VisitorAction {
	width: 100%;
	height: 370px;
}
.refund {
    width: 100%;
    margin-bottom: 20px;
}
.refund_item {
    background: #fff;
    padding: 10px;
    /* margin-bottom: 20px; */
    overflow: hidden;
    min-width: 310px;
}
.num_pirce {
    font-size: 24px;
}
.refund_item p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    align-items: center;
	font-size: 18px;
	font-weight: 600;
}
.refund_item p .link {
    text-decoration: none;
    color: #0770ff;
    font-size: 14px;
}
.refund_wrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    height: auto;
}
#World {
	width: 100%;
	height: 600px;
}
.main-wrap.handsome {
	color: #fff;
	background-color: transparent;
	max-width: 1584px;
	margin: 0 auto;
	padding: 0;
}
.handsome .word {
	display: inline-block;
	filter: blur(40px);
	opacity: 0;
}
.handsome .wordchange {
	animation: smoky 59.5s linear 1;
	animation-fill-mode: forwards;
}
@keyframes smoky {
	1% {
		filter: blur(0);
		opacity: 1;
	}
	98% {
		filter: blur(0);
		opacity: 1;
	}
	100% {
		filter: blur(40px);	
		opacity: 0;
	}
}
.handsome .box {
	/* box-shadow: inset 0 0 8px 4px #0077d9; */
	border: 2px solid #8e9ca0;
	background: transparent;
}
.handsome /deep/ .el-loading-mask {
	background-color: rgba(10,18,22,.6);
}
.handsome /deep/ .el-breadcrumb__inner,
.handsome /deep/ .el-breadcrumb__inner.is-link,
.handsome /deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner,
.handsome /deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover,
.handsome /deep/ .el-table,
.handsome /deep/ .el-table th.el-table__cell,
.handsome /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell,
.handsome /deep/ .el-table tr {
	background: transparent;
	color: #fff;
}
.handsome /deep/ .el-table thead,
.handsome /deep/ .el-table tr,
.handsome /deep/ .el-table th.el-table__cell {
	background-color: #172b36;
	color: #fff;
}
.handsome .refund_item p .link {
	color: #fff;
}
@media (max-width: 1800px) {
	.mainbox {
		display: block;
	}
	.mainbox .left,
	.mainbox .right {
		width: 100%;
		max-width: 100%;
	}
	.mainbox .right {
		margin-top: 20px;
	}
	.refund_wrap {
		grid-template-columns: 1fr 1fr 1fr;
	}
	.refund_item {
		margin-bottom: 20px;
	}
}
@media (max-width: 1520px) {
	.top {
		display: flex;
		flex-wrap: wrap;
		grid-gap: 10px;
		justify-content: space-between;
	}
	.top .list {
		display: flex;
		justify-content: space-between;
	}
	.top .list .listbox {
		flex-basis: 30%;
		width: 30%;
	}
	#AccessTrends {
		width: 60%;
		height: 300px;
	}
	#VisitorAction {
		width: 35%;
		height: 300px;
	}
}
@media (max-width: 1280px) {
	#AccessTrends {
		width: 100%;
		height: 300px;
	}
	#VisitorAction {
		width: 100%;
		height: 300px;
	}
	.refund_wrap {
		display: block;
	}
}
@media (max-width: 1100px) {
	#AccessTrends {
		width: 100%;
		height: 300px;
	}
	#VisitorAction {
		width: 100%;
		height: 300px;
	}
}
</style>
