<template>
  <div>
    <!--插入图片-->
    <el-dialog
      top="3%"
      title="插入图片"
      :visible.sync="insertImage"
      :close-on-click-modal="false"
      width="756px"
      :before-close="insertClose"
	    @open="insertNoMore = false"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="本地上传" name="first">
          <div
            class="insertbox local"
            v-infinite-scroll="insertLoad"
            infinite-scroll-disabled="insertDisabled"
            infinite-scroll-distance="50"
            style="overflow: auto"
          >
            <el-upload
              class="img-uploader item"
			        multiple
              action="#"
              :show-file-list="false"
              :http-request="upload"
            >
              <i class="el-icon-plus img-uploader-icon"></i>
            </el-upload>

            <!-- <div
              class="item"
              v-for="item in materialList"
              :key="item.id"
              @click="selectThis(item.id, item.materialUrl)"
              :class="nowMaterId == item.id ? 'selected' : ''"
            >
              <img :src="item.materialUrl" />
            </div> -->

            <div
              class="item"
              v-for="item in materialList"
              :key="item.id"
              @click="selectThis( item.materialUrl )"
              :class="selectedImages.includes( item.materialUrl ) ? 'selected' : ''"
            >
              <img :src="item.materialUrl" />
            </div>

          </div>

          <p class="center" v-if="insertLoading">加载中...</p>
          <p class="center" v-if="insertNoMore">没有更多了</p>

          <div class="controlrow">
            <el-button @click="insertClose">取消</el-button>
            <el-button type="primary" @click="insertSubmit">选择</el-button>
          </div>
        </el-tab-pane>

        <el-tab-pane label="URL上传" name="second">
          <el-form ref="form" :model="urlform" label-width="80px">
            <el-form-item label="URL">
              <el-input
                :rows="12"
                type="textarea"
                placeholder="请输入图片URL http://... ，如有多个可用英文逗号','分隔"
                v-model="urlform.desc"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="uploadMaterialUrls"
                >上传</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ImageUploader",
  data() {
    return {
      activeName: "first",
      insertLoading: false,
      insertNoMore: false,
      materialList: [],
      materialQuery: {
        PageNo: 1,
        PageSize: 20,
        // total: 0,
        materialType: 1,
      },
      nowMaterId: "",
      nowMaterUrl: "",
      urlform: {
        desc: "",
      },

	  // 多选
	  selectedImages: []

    };
  },
  props: {
    insertImage: {
      type: Boolean,
      default: false,
    },
    insertType: {
      type: String,
      default: "",
    },
  },
  computed: {
    insertDisabled() {
      return this.insertLoading || this.insertNoMore;
    },
  },
  mounted() {
  },
  methods: {
    insertLoad() {
      this.materialQuery.PageNo += 1;
      this.insertLoading = true;
      this.$http
        .get("/material/centre/getPage", { params: this.materialQuery })
        .then((response) => {
          this.insertLoading = false;
          if (response.data.code === 200) {
            this.materialList = this.materialList.concat(response.data.data);
            if (response.data.data.length < 20) {
              this.insertNoMore = true;
            }
          }
        });
    },
    openInsert() {
      this.loading = true;
      this.$http
        .get("/material/centre/getPage", { params: this.materialQuery })
        .then((response) => {
          this.loading = false;
          if (response.data.code === 200) {
            this.materialList = response.data.data;
          }
        })
    },
    selectThis( url ) {
    //   this.nowMaterId = id;
    //   this.nowMaterUrl = url;
		if ( this.selectedImages.includes( url ) ) {

			let i = this.selectedImages.indexOf( url );

			this.selectedImages.splice( i, 1 );

		} else this.selectedImages.push( url );
    },
    insertSubmit() {
      this.$emit("getImage", this.insertType, this.selectedImages );
      this.clearInsert();
    },
    uploadMaterialUrls() {
      let materialUrls = this.urlform.desc.split(",");
      if (!materialUrls) {
        this.$message.error("请输入正确的URL格式");
      }
      this.$http
        .post("/material/centre/add", { materialUrls: materialUrls, materialType: 1 })
        .then((response) => {
          this.loading = false;
          if (response.data.code === 200) {
			this.materialQuery.PageNo = 1;
			this.insertNoMore = false;
            this.openInsert();
            this.activeName = "first";
            this.urlform.desc = "";
          }
        })
    },
    insertClose() {
      this.clearInsert();
      // this.$confirm("确认关闭?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //   this.clearInsert();
      // });
    },
    clearInsert() {
      this.$emit("getOpenState", false);
      this.nowMaterId = "";
      this.nowMaterUrl = "";
	    this.selectedImages = [];
      this.urlform.desc = "";
      this.materialList = [];
      this.materialQuery.PageNo = 1;
    },
    upload(params) {
      let file = params.file;
      const isFormat =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      const isSize = file.size / 1024 / 1024 < 1;
      if (!isFormat) {
        this.$message.error("上传图片只能是jpg、png、gif格式");
        return;
      }
      if (!isSize) {
        this.$message.error("上传图片大小不能超过1MB");
        return;
      }
      let form = new FormData();
      form.append("fileType", "1");
      form.append("files", file);
      this.$http
        .post("/common/file/upload", form)
        .then((response) => {
          if (response.data.code === 200) {
            let data = response.data.data;
            if (data) {
              this.nowMaterId = "";
              this.nowMaterUrl = "";
			        this.selectedImages = [];
              this.materialQuery.PageNo = 1;
              this.$http
                .post("/material/centre/add", { materialUrls: [data[0].url], materialType: 1 })
                .then((response) => {
                  if (response.data.code === 200) {
          					this.materialQuery.PageNo = 1;
          					this.insertNoMore = false;
                    this.openInsert();
                    this.activeName = "first";
                    this.urlform.desc = "";
                  }
                })
            }
          }
        })
      
    },
  },
};
</script>

<style scope>
.img-uploader {
  border: 1px dashed #000;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.img-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 136px;
  height: 136px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.insertbox {
  height: 420px;
  display: flex;
  flex-wrap: wrap;
}
.insertbox .item {
  width: 115px;
  height: 115px;
  flex-basis: 115px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
}
.insertbox .item.img-uploader {
  border: 1px dashed #000;
}
.insertbox .item img {
  max-width: 110px;
  max-height: 110px;
  border: 2px solid transparent;
}
.insertbox .item.selected {
  border: 2px solid #409eff;
}
.center {
  text-align: center;
}
.controlrow {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}
</style>