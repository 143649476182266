<!--
 * @Author: wulei 543411690@qq.com
 * @Date: 2022-10-08 17:35:05
 * @LastEditors: wulei 543411690@qq.com
 * @LastEditTime: 2023-01-05 09:40:36
 * @FilePath: \mediav-shop-admin-ui\src\views\gmc\gmcSetting.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="main-wrap">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>店铺管理</el-breadcrumb-item>
            <el-breadcrumb-item>GMC账户设置</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="top-handler">
            <el-button type="success" icon="el-icon-plus" @click="intoAdd">新增</el-button>
        </div>

        <el-table border stripe :data="tableData" v-loading="loading== 'table' " element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="name" label="账户名称"></el-table-column>
            <el-table-column prop="merchantId" label="GMC ID"></el-table-column>
            <el-table-column prop="email" label="邮箱"></el-table-column>
            <el-table-column label="启用状态">
                <template #default="{row, $index}">
                    <el-switch v-model="row.isEnabled" :active-value="1" :inactive-value="0" @change="enabledHandler($event, row.id, $index)">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="是否默认">
                <template #default="{row}">
                    {{ row.isDefault ? '是' : '否' }}
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间"></el-table-column>
            <el-table-column prop="updateTime" label="更新时间"></el-table-column>
            <el-table-column label="操作" fixed="right" width="200px">
                <template #default="{row}">
                    <div class="handler">
                        <el-link type="primary" @click="setDefault(row)" v-show="!row.isDefault">设为默认</el-link>
                        <el-link type="primary" @click="intoEdit(row)">编辑</el-link>
                        <!-- <el-link type="primary" @click="deleteItem(row)">删除</el-link> -->
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-dialog :title="isEdit ? '编辑' : '新增'" :visible.sync="visibleDialogInfo" :close-on-click-modal="false" top="3%" width="500px" @closed="resetInfo">
            <el-form :model="infoForm" :rules="infoRules" status-icon ref="infoForm">
                <el-form-item label="账户名称" prop="name">
                    <el-input placeholder="请输入账户名称" v-model="infoForm.name" clearable></el-input>
                </el-form-item>
                <el-form-item label="GMC ID" prop="merchantId">
                    <el-input placeholder="请输入GMC ID" v-model="infoForm.merchantId" clearable></el-input>
                </el-form-item>
                <el-form-item label="服务账户私钥" prop="privateKey">
                    <el-input placeholder="请输入账户私钥" v-model="infoForm.privateKey" clearable></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input placeholder="请输入邮箱" v-model="infoForm.email" clearable></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="loading == 'info' " @click="submitInfo">确定</el-button>
                <el-button @click="visibleDialogInfo = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: 'gmcSetting',
    data() {
        return {
            tableData: [],
            total: 0,
            queryForm: {
                pageNo: 1,
                pageSize: 20,
            },
            infoForm: {
                name: '',
                merchantId: '',
                privateKey: '',
                email: '',
            },
            loading: '',
            visibleDialogInfo: false,
            isEdit: false,
            infoRules: {
                name: [
                    {
                        required: true,
                        message: '请输入账户名称',
                        trigger: 'blur',
                    },
                ],
                merchantId: [
                    {
                        required: true,
                        message: '请输入GMC ID',
                        trigger: 'blur',
                    },
                ],
                privateKey: [
                    {
                        required: true,
                        message: '请输入邮箱',
                        trigger: 'blur',
                    },
                ],
                email: [
                    {
                        required: true,
                        message: '请输入账户名称',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    mounted() {
        this.queryData();
    },
    methods: {
        queryData() {
            this.loading = 'table';
            this.$http
                .get('/goods/gmc/getPage', { params: this.queryForm })
                .then((response) => {
                    let { code, count, data } = response.data;

                    if (code === 200) {
                        this.tableData = data;
                        this.total = count;
                    }
                })
                .finally(() => {
                    this.loading = '';
                });
        },
        submitInfo() {
            this.$refs['infoForm'].validate((valid) => {
                if (valid) {
                    let url = `/goods/gmc/${this.isEdit ? 'edit' : 'add'}`,
                        text = this.isEdit ? '编辑' : '新增';

                    this.$http.post(url, this.infoForm).then((res) => {
                        let { code } = res.data;
                        if (code === 200) {
                            this.queryData();
                            this.visibleDialogInfo = false;
                            this.$message.success(`${text}成功~`);
                        }
                    });
                }
            });
        },
        intoEdit(row) {
            this.intoAdd();
            this.isEdit = true;
            this.infoForm = JSON.parse(JSON.stringify(row));
        },
        intoAdd() {
            this.visibleDialogInfo = true;
        },
        setDefault({ id }) {
            this.$confirm('是否设为默认？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .post('/goods/gmc/setDefault', {
                        id,
                    })
                    .then((res) => {
                        let { code } = res.data;
                        if (code === 200) {
                            this.queryData();
                            this.$message.success('成功设为默认~');
                        }
                    });
            });
        },
        deleteItem({ id }) {
            this.$confirm('是否删除这一条？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .delete('/goods/gmc/delete', {
                        params: { id },
                    })
                    .then((res) => {
                        let { code } = res.data;
                        if (code === 200) {
                            this.queryData();
                            this.$message.success('删除成功~');
                        }
                    });
            });
        },
        resetInfo() {
            Object.assign(
                this.infoForm,
                this.$options.data.call(this).infoForm
            );
            this.$refs['infoForm'].clearValidate();
            this.isEdit = false;
        },
        enabledHandler(even, id, index) {
            let params = {
                    id,
                    isEnabled: +even,
                },
                msg = even ? '已开启~' : '已关闭~';

            this.$http.post('/goods/gmc/setIsEnable', params).then(
                (res) => {
                    let { code } = res.data;
                    if (code === 200) {
                        this.$message.success(msg);
                    }
                },
                () => {
                    this.tableData[index]['isEnabled'] = !even;
                }
            );
        },
    },
};
</script>
<style>
.top-handler {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 30px;
}
</style>