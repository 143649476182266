<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>DIY素材</el-breadcrumb-item>
        </el-breadcrumb>

        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.materialName" placeholder="DIY标题"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-refresh-left" @click="resetData">重置</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="dialogVisible = true;">新增</el-button>
            </el-form-item>
        </el-form>

        <el-table border stripe :data="tableData" ref="table" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000">
            <el-table-column label="ID" prop="id" width="80px"></el-table-column>
            <el-table-column label="标题" prop="materialName" min-width="140px"></el-table-column>
            <el-table-column label="素材" width="610">
                <template slot-scope="{row}">
                    <div class="list-mod">
                        <div class="images-list">
                            <div class="item" v-for="(item, index) in urilsFormatter(row.materialUrl)" :key="index">
                                <el-image :src="item" :preview-src-list="row.materialUrl"></el-image>
                            </div>
                        </div>
                        <p v-if="row.materialUrl && row.materialUrl.length > 6">...</p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime" min-width="160px"></el-table-column>
            <el-table-column label="状态">
                <template slot-scope="{row}">
                    <el-switch v-model="row.materialStatus" @change="changeWithSwitch($event, row)" :active-value="1" :inactive-value="0"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="{row}">
                    <div class="handler">
                        <el-link type="primary" @click="editTable(row)">编辑</el-link>
                        <el-link type="primary" @click="deleteItem(row)">删除</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-dialog title="新增DIY" :visible.sync="dialogVisible" width="800px" @closed="resetInfo">
            <div class="container">
                <el-form :model="dialogForm">
                    <el-form-item label="DIY标题" label-width="80px">
                        <el-input v-model="dialogForm.materialName" placeholder="DIY标题"></el-input>
                    </el-form-item>
                    <el-form-item label="上传素材" label-width="80px">
                        <div class="mod-handler-image">
                            <div class="mod-images-list">
                                <section class="ImageBox" v-for="(item, i) in dialogForm.materialUrls" :key="i">
                                    <el-image fit="cover" class="Image" :src="item">
                                        <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                                    </el-image>
                                    <div class="mask_d5"></div>
                                    <el-button type="danger" icon="el-icon-delete" circle class="ImageDeleteButton" @click="dialogForm.materialUrls.splice(i, 1)"></el-button>
                                </section>
                            </div>
                            <i class="el-icon-plus img-uploader-icon ImageAddButton" @click="openImageUploader( 'dialogForm' )"></i>
                        </div>
                        <p class="tips">（请上传不要超过400x400px，大小1M的素材）</p>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitInfo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 插入图片 -->
        <ImageUploader ref="uploadImage" :insertImage="dialogInsertImage" :insertType="insertType" :activeName="activeName" @getOpenState="getOpenState" @getImage="getImage">
        </ImageUploader>

    </div>
</template>
<script>
import ImageUploader from '@/components/ImageUploader';
export default {
    name: 'Diy',
    components: { ImageUploader },
    data: () => ({
        queryForm: {
            materialName: '',
            pageNo: 1,
            pageSize: 10,
        },
        total: 0,
        loading: false,
        dialogVisible: false,
        tableData: [],
        dialogForm: {
            materialName: '',
            materialUrls: [],
        },

        isEdit: false,
        dialogInsertImage: false,
        activeName: 'first',
        insertType: null,
        insertIndex: null,
    }),
    mounted() {
        this.queryData();
    },
    computed: {
        urilsFormatter() {
            return function (arr) {
                return JSON.parse(JSON.stringify(arr)).splice(0, 6);
            };
        },
    },
    methods: {
        queryData() {
            let url = '/material/diy/getPage';
            this.$http
                .get(url, {
                    params: this.queryForm,
                })
                .then((res) => {
                    let { code, data, count } = res.data;
                    if (code === 200) {
                        this.tableData = data;
                        this.total = count;
                    }
                });
        },
        submitInfo() {
            if (!this.dialogForm.materialName) {
                return this.$message.warning('请填写DIY标题');
            }

            let isEdit = this.isEdit,
                url = isEdit ? '/material/diy/edit' : '/material/diy/add',
                key = isEdit
                    ? 'shopMaterialGoodsDiyEdit'
                    : 'shopMaterialGoodsDiyAdd';

            this.$http.post(url, this.dialogForm).then((res) => {
                let { code } = res.data;
                if (code === 200) {
                    this.$message.success(`${isEdit ? '修改' : '新增'}成功`);
                    this.queryData();
                    this.dialogVisible = false;
                }
            });
        },
        editTable(row) {
            let { materialUrl: materialUrls, ...args } = JSON.parse(
                JSON.stringify(row)
            );
            this.dialogForm = {
                materialUrls,
                ...args,
            };
            this.isEdit = true;
            this.dialogVisible = true;
        },
        deleteItem({ id }) {
            this.$confirm('确定要删除活动吗？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.deleteTable(id);
            });
        },
        deleteTable(id) {
            let url = '/material/diy/delete';
            this.$http
                .delete(url, {
                    params: { id },
                })
                .then((res) => {
                    let { code } = res.data;
                    if (code === 200) {
                        this.$message.success('删除成功');
                        this.queryData();
                    }
                });
        },
        changeWithSwitch(materialStatus, { id }) {
            let url = '/material/diy/setStatus',
                params = {
                    id,
                    materialStatus,
                };

            this.$http.post(url, params).then((res) => {
                let { code } = res.data;
                if (code === 200) {
                    this.$message.success('状态修改成功~');
                }
            });
        },

        resetInfo() {
            Object.assign(
                this.dialogForm,
                this.$options.data.call(this).dialogForm
            );
        },
        resetData() {
            Object.assign(
                this.queryForm,
                this.$options.data.call(this).queryForm
            );
            this.queryData();
            this.edit = false;
        },

        // 插入图片方法
        openImageUploader(obj, index) {
            this.insertType = obj;
            this.insertIndex = index;
            this.dialogInsertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.dialogInsertImage = result;
        },
        getImage(obj, images = []) {
            this.dialogForm.materialUrls = [
                ...this.dialogForm.materialUrls,
                ...images,
            ];
            // this.dialogInsertImage = false;
        },
        /* 插入图片方法 end */
    },
};
</script>
<style>
.mod-photo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ImageBox {
    width: 120px;
    height: 120px;
    /* margin: 0 auto; */
    position: relative;
}
::v-deep .ImageBox img {
    width: 120px;
    height: 120px;
    object-fit: cover;
}

.ImageDeleteButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

.ImageBox > .mask_d5 {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.ImageBox:hover > .mask_d5,
.ImageBox:hover .ImageDeleteButton {
    display: block;
    opacity: 1;
}

.ImageAddButton {
    margin-bottom: 20px;
    box-sizing: border-box;
    border: dotted 1px #aaa;
    border-radius: 4px;
    width: 120px;
    height: 120px;
    cursor: pointer;
}
.mod-handler-image {
    /* display: flex; */
    gap: 20px;
}
.mod-images-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
}

.mod-images-list .el-image {
    height: 120px;
}

.list-mod {
    display: flex;
    align-items: flex-end;
}

.list-mod p {
    margin: 0 0 0 20px;
}

.img-uploader-icon {
    margin-top: 20px;
    margin-bottom: 0;
}

.tips {
    margin: 0;
}

.handler {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.handler a {
}

.images-list {
    display: flex;
}

.images-list .item {
    width: 80px;
    height: 80px;
    margin-left: 10px;
}

.images-list img {
    width: 80px;
    height: 80px;
    object-fit: cover;
}

::v-deep .img-uploader-icon {
    width: 100px;
    height: 100px;
}
</style>