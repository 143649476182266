<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商品管理</el-breadcrumb-item>
            <el-breadcrumb-item>网红帖子</el-breadcrumb-item>
        </el-breadcrumb>

        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.kolName" placeholder="网红昵称"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="queryForm.content" placeholder="内容"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="queryForm.spu" placeholder="spu"></el-input>
            </el-form-item>

            <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">重置</el-button>
            <el-button type="success" icon="el-icon-plus" @click="showInfo()">新增</el-button>
        </el-form>

        <el-table ref="table" max-height="1000" border stripe :data="tableData" v-loading="loading == 'table'" element-loading-text="加载中" element-loading-spinner="el-icon-loading">
            <el-table-column label="ID" prop="id" width="80px"></el-table-column>
            <el-table-column label="网红图片" width="160px">
                <template slot-scope="{row}">
                    <el-image :src="row.images && row.images[0]" :preview-src-list="row.images" class="view-img" fit="cover"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="排序" width="160px">
                <template slot-scope="{row, $index}">
                    <el-popover placement="bottom" width="240" v-model="row.visible" @hide="resetItemSort($index, row.copySort)">
                        <div class="sort-thumb">
                            <el-input-number v-model="row.sort" :min="1" :max="9999" :controls="false"></el-input-number>
                            <div class="sort-handler">
                                <el-button type="text" size="mini" @click="sortItem(row)">保存</el-button>
                                <el-button size="mini" type="text" @click="row.visible = false">取消</el-button>
                            </div>
                        </div>
                        <el-button slot="reference" class="sort-button">{{ row.copySort }}</el-button>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column prop="kolName" label="网红昵称" width="180px">
            </el-table-column>
            <el-table-column prop="content" label="内容" min-width="100px"></el-table-column>
            <el-table-column label="关联商品" min-width="340px">
                <template slot-scope="{row}">
                    <ul class="goods-list">
                        <li v-for="(item, index) in row.goodsList" :key="index">
                            <div class="cover">
                                <!-- <el-image :src="item.goodsImages && item.goodsImages[0]" :preview-src-list="item.goodsImages"></el-image> -->
                                <GoodsImageTemplate :images="item.goodsImages"></GoodsImageTemplate>
                            </div>
                            <p class="name">{{item.goodsTitle}}</p>
                        </li>
                    </ul>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime" width="180px"></el-table-column>

            <el-table-column label="操作" width="120px">
                <template slot-scope="{row}">
                    <div class="handler">
                        <div class="item">
                            <el-link type="primary" @click="editItem(row)">编辑</el-link>
                        </div>
                        <div class="item">
                            <el-link type="primary" @click="removeItem(row)">删除</el-link>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <!-- 新增商品款式 -->
        <el-dialog top="3%" :title="isAdd ? '新建网红帖子' : '编辑网红帖子'" width="1000px" :visible.sync="visibleDialogInfo" :close-on-click-modal="false" class="dialog-info" @closed="resetDialogInfo">
            <!-- 选择商品（{{ selectedGoodsTable.total || 0 }}/{{ 10 }}）  -->
            <el-form ref="dictAddForm" :model="dictAddForm" :rules="addRules">
                <el-form-item label="选择关联商品" v-show="isAdd">
                    <el-link type="primary" @click="openSelectGoods">选择</el-link>
                </el-form-item>

                <div class="flex">
                    <template v-if="selectedGoodsTable.data.length > 0">
                        <vuedraggable class="wrapper" v-model="selectedGoodsTable.data">
                            <div class="itembox" v-for="(item,indexB) in selectedGoodsTable.data" :key="indexB">
                                <div class="imgbox">
                                    <GoodsImageTemplate :images="item.goodsImages" width="180" height="180" :hasHover="false"></GoodsImageTemplate>
                                </div>
                                <p class="tit">{{ item.goodsTitle }}</p>
                                <p class="tit">售价:{{ item.price }} / 原价:{{ item.compareAtPrice }}
                                </p>
                                <p>库存: {{ item.inventoryQuantity }}</p>
                                <p class="between" v-show="isAdd">
                                    <!-- <span class="w50" title="置顶"><i class="el-icon-top" @click="toppingGoods(item)"></i></span> -->
                                    <span class="w50" @click="deleteSelectedGoods(item)"><i class="el-icon-delete"></i></span>
                                </p>
                                <span :class="`published published ${item.published ? 'B' : 'A'}` ">{{ item.published ? '已上架': '未上架' }}</span>
                            </div>
                        </vuedraggable>
                    </template>
                </div>

                <el-form-item label="网红名字" class="inline-label" prop="kolName">
                    <el-input maxlength="50" v-model="dictAddForm.kolName"></el-input>
                </el-form-item>
                <el-form-item label="发帖日期" prop="sendDate">
                    <el-date-picker v-model="dictAddForm.sendDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="网红图片">
                    <div class="goodsImagesRow">
                        <section class="goodsImageBox pr df jc_c ai_c" v-for="(item, index) in modelImgList" :key="index">
                            <el-image fit="cover" class="wh100 db_i" :src="item">
                                <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                            </el-image>
                            <div class="pa100 mask_d5">
                                <el-button type="danger" class="pa_cen cp" icon="el-icon-delete" circle @click="deleteSeleckedImage(modelImgList, item)"></el-button>
                            </div>
                        </section>
                        <i class="el-icon-plus img-uploader-icon cp goodsImageBox" style="border: dotted 1px #909399; border-radius: 4px;" @click="proxyInsert({
                            obj: 'modelImgList',
                            type: 'image'
                        })">
                        </i>
                    </div>
                </el-form-item>
                <el-form-item label="网红视频">
                    <div class="goodsImagesRow">
                        <section class="goodsImageBox pr df jc_c ai_c" v-for="(item, index) in modelVideoList" :key="index">
                            <video width="100%" controls autoplay loop :src="item">
                            </video>
                            <div class="pa100 mask_d5">
                                <el-button type="danger" class="pa_cen cp" icon="el-icon-delete" circle @click="deleteSeleckedImage(modelVideoList, item)"></el-button>
                            </div>
                        </section>
                        <i class="el-icon-plus img-uploader-icon cp goodsImageBox" style="border: dotted 1px #909399; border-radius: 4px;" @click="proxyInsert({
                        obj: 'modelVideoList',
                        type: 'video'
                    })">
                        </i>
                    </div>
                </el-form-item>
                <el-form-item label="帖子内容" class="inline-label" prop="content">
                    <el-input type="textarea" :rows="2" placeholder="Tropical plants Front Knot Bikinis ..." v-model="dictAddForm.content" resize="none" class="textarea">
                    </el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="loading === 'submit' " @click="addData">保存</el-button>
                <el-button @click="visibleDialogInfo = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 选择商品 -->
        <SelectGoods ref="selectGoods" :showDialog="dialogSelectGoods" :containItem="false" :selectedGoods="selectedGoodsTable.data" @closeDialog="closeSelectGoods" @saveSelectedGoods="saveSelectGoods">
        </SelectGoods>

        <ImageUploader :insertImage="insertImage" :insertType="insertType" @getOpenState="getOpenState" @getImage="getImage" ref="uploadImage">
        </ImageUploader>

        <!-- 视频 -->
        <VideoUploader ref="uploadVideo" :insertVideo="dialogInsertVideo" :insertVideoType="insertVideoType" :activeVideo="activeVideo" @getOpenVideo="getOpenVideo" @getVideo="getVideo">
        </VideoUploader>

    </div>
</template>
<script>
import vuedraggable from 'vuedraggable';
import SelectGoods from '@/components/SelectGoods';
import ImageUploader from '@/components/ImageUploader';
import VideoUploader from '@/components/VideoUploader';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
export default {
    name: 'Kolposts',
    components: {
        SelectGoods,
        vuedraggable,
        ImageUploader,
        VideoUploader,
        GoodsImageTemplate,
    },
    data: () => ({
        queryForm: {
            content: '',
            kolName: '',
            spu: '',
            pageNo: 1,
            pageSize: 10,
        },
        dictAddForm: {
            content: '',
            sendDate: '',
            kolName: '',
        },
        tableData: [],
        modelImgList: [],
        modelVideoList: [],
        selectedGoodsTable: {
            data: [],
            total: 0,
        },

        loading: '',
        dialogSelectGoods: false,
        isAdd: true,
        visibleDialogInfo: false,
        dialogInsertVideo: false,
        insertVideoType: null,
        activeVideo: 'first',
        insertType: null,
        insertImage: false,

        addRules: {
            kolName: [
                { required: true, message: '请输入网红名字', trigger: 'blur' },
            ],
            sendDate: [
                { required: true, message: '请输入发帖日期', trigger: 'blur' },
            ],
            content: [
                { required: true, message: '请输入帖子内容', trigger: 'blur' },
            ],
        },

        total: 0,
    }),
    mounted() {
        this.queryData();
    },
    methods: {
        queryData() {
            this.loading = 'table';
            this.$http
                .get('/goods/kol/getPage', { params: this.queryForm })
                .then((res) => {
                    const { code, data, count } = res.data;
                    if (code === 200) {
                        this.tableData = this.initSort(data);
                        this.total = count;
                    }
                })
                .finally(() => {
                    this.loading = '';
                });
        },
        initSort(data) {
            data.forEach((item) => {
                let value = item?.sort ?? 100;
                item.sort = value;
                item.copySort = value;
            });

            return data;
        },
        resetItemSort(index, init_value) {
            this.tableData[index]['sort'] = init_value;
        },
        sortItem({ id, sort }) {
            if ((sort ?? '') === '') {
                return this.$message.warning('排序不能为空~');
            }

            let url = '/goods/kol/editSort',
                params = [
                    {
                        id,
                        sort,
                    },
                ];

            this.$http.post(url, params).then((res) => {
                let { code } = res.data;
                if (code === 200) {
                    this.queryData();
                }
            });
        },
        editItem({ id }) {
            this.isAdd = false;
            this.upInfo(id);
        },
        removeItem({ id }) {
            const URL = '/goods/kol/delete';
            this.$confirm('是否删除', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                let {
                    data: { code },
                } = await this.$http.delete(URL, {
                    params: {
                        id,
                    },
                });
                if (code === 200) {
                    this.$message.success('删除成功~');
                    this.queryData();
                }
            });
        },
        async upInfo(id) {
            const URL = '/goods/kol/detail';
            this.visibleDialogInfo = true;

            let {
                data: { code, data: info },
            } = await this.$http.get(`${URL}?id=${id}`);
            if (code === 200) {
                let { goodsDetailList, images, videos, ...args } = info || {};

                this.dictAddForm = args;
                this.selectedGoodsTable = {
                    data: goodsDetailList,
                    total: goodsDetailList.length,
                };
                this.modelImgList = images;
                this.modelVideoList = videos;
            }
        },
        showInfo() {
            this.visibleDialogInfo = true;
        },
        getInfoParams(cb) {
            this.$refs.dictAddForm.validate((valid) => {
                if (valid) {
                    if (!this.selectedGoodsTable.data.length) {
                        return this.$message.warning('至少选择一个关联商品~');
                    }

                    if (
                        !this.modelImgList.length &&
                        !this.modelVideoList.length
                    ) {
                        return this.$message.warning(
                            '至少选择一个图片或者视频~'
                        );
                    }

                    let params = {
                        ...this.dictAddForm,
                        goodsIds: this.selectedGoodsTable.data.map((item) => {
                            return item.id;
                        }),
                        images: this.modelImgList,
                        videos: this.modelVideoList,
                    };
                    cb && cb(params || {});
                }
            });
        },
        addData() {
            this.getInfoParams((params) => {
                let url = this.isAdd ? '/goods/kol/add' : '/goods/kol/edit',
                    flag = this.isAdd ? '新增' : '编辑';

                this.loading = 'submit';
                this.$http
                    .post(url, params)
                    .then((res) => {
                        let { code } = res.data;
                        if (code === 200) {
                            this.$message.success(`${flag}成功~`);
                            this.visibleDialogInfo = false;
                            this.queryData();
                        }
                    })
                    .finally(() => {
                        this.loading = '';
                    });
            });
        },
        resetQuery() {
            Object.assign(
                this.queryForm,
                this.$options.data.call(this).queryForm
            );
            this.queryData();
        },
        resetDialogInfo() {
            Object.assign(
                this.dictAddForm,
                this.$options.data.call(this).dictAddForm
            );
            Object.assign(
                this.selectedGoodsTable,
                this.$options.data.call(this).selectedGoodsTable
            );
            this.modelImgList = [];
            this.modelVideoList = [];
            this.isAdd = true;
        },
        openSelectGoods() {
            this.$refs.selectGoods.caller = 'notset';
            this.$refs.selectGoods.init();
            this.dialogSelectGoods = true;
        },
        closeSelectGoods() {
            this.dialogSelectGoods = false;
        },
        saveSelectGoods(items, caller) {
            switch (caller) {
                default:
                    this.selectedGoodsTable.data = items;
                    this.selectedGoodsTable.total =
                        this.selectedGoodsTable.data.length;
            }
        },

        // 置顶
        toppingGoods(item) {
            let tempitem = JSON.parse(JSON.stringify(item));
            tempitem.top = 1;
            this.selectedGoodsTable.data.unshift(tempitem);
            let index = this.selectedGoodsTable.data.indexOf(item);
            this.selectedGoodsTable.data.splice(index, 1);
        },
        // 删除选中的商品（删除）
        deleteSelectedGoods(item) {
            let index = this.selectedGoodsTable.data.indexOf(item);

            if (index > -1) {
                this.selectedGoodsTable.data.splice(index, 1);
                this.selectedGoodsTable.total =
                    this.selectedGoodsTable.data.length;
            }
        },

        // 新增评论 - 删除已选择的图片
        deleteSeleckedImage(images, item) {
            let index = images.indexOf(item);

            if (index > -1) images.splice(index, 1);
        },

        proxyInsert({ index, type, key, obj }) {
            console.log(obj);
            if ((index ?? '') !== '') {
                this.modelIndex = index;
            }
            if (key) {
                this.modelKey = key;
            }
            if (type === 'image') {
                this.setOpenInsert(obj);
                return;
            }
            this.openVideoUploader(obj);
        },
        setOpenInsert(obj) {
            this.insertType = obj;
            this.insertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.insertImage = result;
        },
        getImage(obj, image) {
            if (obj == 'dictAddForm.goodsImages') {
                this.dictAddForm.goodsImages.push(...image);
            }
            if (obj == 'tinymce') {
                this.dictAddForm.goodsDesc += `<img src="${
                    image[image.length - 1]
                }"/>`;
            }

            if (obj == 'modelImgList') {
                this.modelImgList.push(...image);
            }

            //模特
            if (~obj.indexOf('modelList')) {
                let elment = this.modelList[this.modelIndex];
                if (this.modelKey === 'portrait') {
                    elment[this.modelKey] = image?.[0];
                    return;
                }
                if (!elment[this.modelKey]) {
                    elment[this.modelKey] = [];
                }
                elment[this.modelKey].push(...image);
            }

            if (~obj.indexOf('influencerList')) {
                let elment = this.influencerList[this.modelIndex];
                // console.log(this.modelKey)
                if (this.modelKey === 'portrait') {
                    elment[this.modelKey] = image?.[0];
                    return;
                }
                if (!elment[this.modelKey]) {
                    elment[this.modelKey] = [];
                }
                elment[this.modelKey].push(...image);
            }
        },
        /* 插入视频方法 */
        openVideoUploader(obj) {
            this.insertVideoType = obj;
            this.dialogInsertVideo = true;
            this.$refs.uploadVideo.openInsert();
        },
        getOpenVideo(result) {
            this.dialogInsertVideo = result;
        },
        getVideo(obj, video) {
            // console.log(obj)
            let elment;
            switch (obj) {
                case 'modelList':
                    elment = this.modelList[this.modelIndex];
                    if (!elment[this.modelKey]) {
                        elment[this.modelKey] = [];
                    }
                    elment[this.modelKey].push(...video);
                    break;
                case 'influencerList':
                    elment = this.influencerList[this.modelIndex];
                    // console.log(this.modelKey)
                    if (!elment[this.modelKey]) {
                        elment[this.modelKey] = [];
                    }
                    elment[this.modelKey].push(...video);
                    break;
                case 'modelVideoList':
                    this.modelVideoList.push(...video);
                    break;
                case 'tinymce':
                    // if ( !this.editGoodsCommentForm.videos ) this.editGoodsCommentForm.videos = [];
                    // this.editGoodsCommentForm.videos.push( ...video );
                    this.dictAddForm.goodsDesc += `<video width="100%" controls autoplay loop><source src="${
                        video[video.length - 1]
                    }"/></video>`;
                    break;
                default:
                    break;
            }
        },
        /* end */
    },
};
</script>
<style scoped>
.main-wrap {
    height: auto;
}
.handler {
    display: flex;
    justify-content: center;
}
.handler .item {
    min-width: 40px;
}

.dialog-info .inline-label ::v-deep .el-form-item__content {
    display: flex;
}

.view-img {
    width: 100px;
    height: 120px;
}
.view-img img {
    width: 100px;
    height: 120px;
}

.goods-list li {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.goods-list li:nth-child(1) {
    margin-top: 0;
}
.goods-list .cover,
.goods-list .cover ::v-deep img {
    width: 100px;
    height: 120px;
    display: block;
    object-fit: cover;
}
.goods-list .name {
    padding-left: 20px;
}

/* list */
.textarea ::v-deep textarea {
    min-height: 120px !important;
}
.wrapper {
    display: flex;
    flex-wrap: wrap;
}
.wrapper .itembox {
    width: 180px;
    flex-basis: 180px;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    padding: 5px 5px 0 5px;
    border-radius: 10px;
    background-image: linear-gradient(315deg, #e2e2e2, #fff);
    position: relative;
}
.wrapper .itembox .imgbox {
    width: 100%;
    height: 220px;
}
.wrapper .itembox .tit {
    height: 32px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.wrapper .itembox .between {
    display: flex;
    border-top: 1px solid #ccc;
    margin: 0;
    height: 30px;
}
.wrapper .itembox .w50 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* flex-basis: 50%;
    width: 50%; */
    height: 100%;
    cursor: pointer;
    color: #409eff;
    font-size: 20px;
}
.wrapper .itembox .published {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    padding: 2px 5px;
    font-size: 14px;
    color: #fff;
    background: #409eff;
}
.wrapper .itembox .published.publishedA {
    color: #333;
    background: #ddd;
}
.wrapper .itembox .published.publishedB {
    color: #fff;
    background: #409eff;
}
/* .wrapper .itembox .w50:first-child {
    border-right: 1px solid #ccc;
} */
.wrapper .itembox .published {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    padding: 2px 5px;
    font-size: 14px;
}
.wrapper .itembox .published.publishedA {
    color: #333;
    background: #ddd;
}
.wrapper .itembox .published.publishedB {
    color: #fff;
    background: #409eff;
}

.goodsImagesRow {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, 120px);
    flex-wrap: wrap;
}
.selectedGoodsImages {
    width: 120px;
    height: 120px;
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.selectedGoodsImages .image {
    max-width: 100%;
    max-height: 100%;
    min-width: unset;
    min-height: unset;
}
.selectedGoodsImages .del {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    cursor: pointer;
}
.selectedGoodsImages .del:hover {
    color: #409eff;
}

/* 商品图片 */
.goodsImageBox {
    /* margin-right: 20px;
    margin-bottom: 20px; */
    width: 120px !important;
    height: 120px !important;
}

.goodsImageBox video {
    width: 120px;
    height: 120px;
    object-fit: cover;
}

.goodsImageBox > .mask_d5 {
    display: none;
}

.goodsImageBox:hover > .mask_d5 {
    display: block;
}

.sort-thumb {
    display: flex;
}
.sort-handler {
    display: flex;
    margin-left: 20px;
}
.sort-button {
    padding-left: 40px;
    padding-right: 40px;
    min-width: 80px;
    display: block;
    margin: 0 auto;
    box-sizing: border-box;
}
/* 商品图片 end */
</style>