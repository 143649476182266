<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>营销管理</el-breadcrumb-item>
            <el-breadcrumb-item>优惠码活动</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 查询表单 -->
        <el-tabs v-model="campaignStatus" @tab-click="queryData">
            <el-tab-pane label="全部" name="0"></el-tab-pane>
            <el-tab-pane v-for="item in campaignStatusTypes" :key="item.value" :label="item.name" :name="item.value"></el-tab-pane>
        </el-tabs>
        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.campaignName" placeholder="活动名称"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="queryForm.discountCode" placeholder="优惠码"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryForm.discountType" clearable placeholder="折扣类型" style="width: 120px">
                    <el-option label="代金券" value="2"></el-option>
                    <el-option label="折扣券" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-refresh-left" @click="resetCondition">重置</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="initAddCampaignCode">新增</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表 -->
        <el-table border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" :data="tableData" v-loading="loading">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="campaignName" label="活动名称" min-width="120"></el-table-column>
            <el-table-column prop="userName" label="创建人" min-width="120"></el-table-column>
            <el-table-column prop="discountCode" label="优惠码" min-width="120"></el-table-column>
            <el-table-column label="活动时间" width="160">
                <template slot-scope="scope">
                    {{ scope.row.startTime }}-{{
            scope.row.endTime ? scope.row.endTime : "不限"
          }}
                </template>
            </el-table-column>
            <el-table-column label="优惠规则" min-width="120">
                <template slot-scope="scope">
                    {{ scope.row.discountNum
          }}{{ scope.row.discountType == 1 ? "%off" : "USD" }}
                </template>
            </el-table-column>
            <el-table-column label="商品范围" width="100">
                <template slot-scope="scope">
                    {{ scope.row.scope == 1 ? "全部" : "部分" }}商品
                </template>
            </el-table-column>
            <!-- <el-table-column
        prop="alreadyUseCount"
        label="已消耗"
        width="80"
      ></el-table-column> -->
            <el-table-column label="活动状态" width="100">
                <template slot-scope="scope">
                    {{
            campaignStatusTypes.find((v) => v.value == scope.row.campaignStatus)
              .name
          }}
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="200" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="initEditCampaignCode(scope.row)">编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-video-pause" v-if="scope.row.campaignStatus != 3" @click="stopCampaignCode(scope.row)">提前结束</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="deleteCampaignCode(scope.row)">删除</el-button>

                    <el-button size="mini" type="text" icon="el-icon-s-data" @click="openDataInfo(scope.row)">数据明细</el-button>

                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <!-- 新增优惠码活动 -->
        <el-dialog fullscreen title="新增优惠码活动" id="addCampaignCodeDialog" :visible.sync="dialogAddCampaignCode" :close-on-click-modal="false">
            <el-form ref="addCampaignCodeForm" size="medium" label-position="top" status-icon :model="addCampaignCodeForm" :rules="addCampaignCodeRules">
                <!-- 创建活动 -->
                <section class="addCampaignCodeSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignCodeLegend">基本信息</el-col>
                        <el-col :span="20">
                            <el-form-item label="活动名称" prop="campaignName" style="max-width: 650px">
                                <el-input placeholder="请输入活动名称" maxlength="20" show-word-limit v-model="addCampaignCodeForm.campaignName"></el-input>
                            </el-form-item>
                            <div class="df ai_fe">
                                <el-form-item label="优惠码" prop="discountCode" style="width: 100%; max-width: 650px">
                                    <el-input placeholder="4～16位字符" maxlength="16" show-word-limit @blur="checkCodeRules('addCampaignCodeForm')" v-model="addCampaignCodeForm.discountCode"></el-input>
                                </el-form-item>
                                <el-form-item class="ml_3">
                                    <el-button type="text" class="td_u" :loading="randomCodeLoading" @click="randomCampaignCode('addCampaignCodeForm')">
                                        随机生成
                                    </el-button>
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>
                </section>

                <section class="addCampaignCodeSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignCodeLegend">使用规则</el-col>
                        <el-col :span="20">
                            <div class="df ai_fe">
                                <el-form-item label="活动时间" prop="startTime">
                                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间" v-model="addCampaignCodeForm.startTime">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item class="px_1" style="color: #bbb">～</el-form-item>
                                <el-form-item :prop="addCampaignCodeEndTime ? '' : 'endTime'">
                                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间" :disabled="addCampaignCodeEndTime" v-model="addCampaignCodeForm.endTime">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item prop="isDefault" class="ml_3">
                                    <el-checkbox v-model="addCampaignCodeEndTime" @change="
                      ($event) => {
                        if ($event) addCampaignCodeForm.endTime = null;
                      }
                    ">不限结束时间</el-checkbox>
                                </el-form-item>
                            </div>
                            <!-- <div class="df ai_fe">
                <el-form-item
                  label="数量"
                  prop="discountCodeCount"
                  style="max-width: 240px"
                >
                  <el-input
                    maxlength="10"
                    :disabled="addCampaignCodeDiscountCodeCount"
                    v-model="addCampaignCodeForm.discountCodeCount"
                  >
                    <template slot="append">张</template>
                  </el-input>
                  <el-checkbox
                    class="mt_2"
                    v-model="addCampaignCodeDiscountCodeCount"
                    @change="
                      ($event) => {
                        if ($event)
                          addCampaignCodeForm.discountCodeCount = null;
                      }
                    "
                    >不限</el-checkbox
                  >
                </el-form-item>
                <el-form-item
                  label="每人限用"
                  prop="useNum"
                  style="margin-left: 30px; max-width: 240px"
                >
                  <el-input
                    maxlength="10"
                    :disabled="addCampaignCodeUseNum"
                    v-model="addCampaignCodeForm.useNum"
                  >
                    <template slot="append">次</template>
                  </el-input>
                  <el-checkbox
                    class="mt_2"
                    v-model="addCampaignCodeUseNum"
                    @change="
                      ($event) => {
                        if ($event) addCampaignCodeForm.useNum = null;
                      }
                    "
                    >不限</el-checkbox
                  >
                </el-form-item>
              </div> -->
                            <div class="df ai_fe">
                                <el-form-item label="优惠码数量（张）" prop="codeNum">
                                    <el-input-number :min="0" :precision="0" v-if="!addCampaignCodeCodeNum" v-model="addCampaignCodeForm.codeNum"></el-input-number>
                                    <span class="ml_3">
                                        <el-checkbox v-model="addCampaignCodeCodeNum" @change="
                        ($event) => {
                          if ($event) addCampaignCodeForm.codeNum = null;
                        }
                      ">不限</el-checkbox>
                                    </span>
                                </el-form-item>
                            </div>
                            <div class="df ai_fe">
                                <el-form-item label="每人限用（次）" prop="codeLimitNum">
                                    <el-input-number :min="0" :precision="0" v-if="!addCampaignCodeCodeLimitNum" v-model="addCampaignCodeForm.codeLimitNum"></el-input-number>
                                    <span class="ml_3">
                                        <el-checkbox v-model="addCampaignCodeCodeLimitNum" @change="
                        ($event) => {
                          if ($event) addCampaignCodeForm.codeLimitNum = null;
                        }
                      ">不限</el-checkbox>
                                    </span>
                                </el-form-item>
                            </div>
                            <div class="df ai_fe">
                                <el-form-item label="是否可抵运费" prop="isFreight">
                                    <el-radio-group v-model="addCampaignCodeForm.isFreight">
                                        <el-radio :label="0">否</el-radio>
                                        <el-radio :label="1">是</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                            <div class="df ai_fe">
                                <el-form-item label="是否可与满减活动叠加" prop="isFull">
                                    <el-radio-group v-model="addCampaignCodeForm.isFull">
                                        <el-radio :label="0">否</el-radio>
                                        <el-radio :label="1">是</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>

                        </el-col>
                    </el-row>
                </section>

                <section class="addCampaignCodeSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignCodeLegend">优惠规则</el-col>
                        <el-col :span="20">
                            <div class="df ai_fe">
                                <el-form-item label="优惠门槛" prop="discountCondition">
                                    <el-select v-model="addCampaignCodeForm.discountCondition" style="width: 240px">
                                        <el-option label="无门槛" value="1"></el-option>
                                        <el-option label="消费满件数" value="2"></el-option>
                                        <el-option label="消费满金额" value="3"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item prop="discountConditionNum" class="ml_3" style="width: 180px" v-if="addCampaignCodeForm.discountCondition != 1">
                                    <el-input v-model="addCampaignCodeForm.discountConditionNum">
                                        <template slot="append" v-if="addCampaignCodeForm.discountCondition == 2">件</template>
                                        <template slot="append" v-if="addCampaignCodeForm.discountCondition == 3">USD</template>
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div class="df ai_fe">
                                <el-form-item label="优惠类型" prop="discountType">
                                    <el-select v-model="addCampaignCodeForm.discountType" style="width: 240px">
                                        <el-option label="折扣" value="1"></el-option>
                                        <el-option label="固定金额" value="2"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item prop="discountNum" class="ml_3" style="width: 180px">
                                    <el-input v-model="addCampaignCodeForm.discountNum">
                                        <template slot="append" v-if="addCampaignCodeForm.discountType == 1">%off</template>
                                        <template slot="append" v-if="addCampaignCodeForm.discountType == 2">USD</template>
                                    </el-input>
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>
                </section>

                <section class="addCampaignCodeSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignCodeLegend">适用范围</el-col>
                        <el-col :span="20">
                            <el-form-item prop="scope">
                                <div class="df ai_c mt_2">
                                    <div class="addCampaignCodeCard" :class="{
                      addCampaignCodeSelectedCard:
                        addCampaignCodeForm.scope == 1,
                    }" @click="addCampaignCodeForm.scope = 1">
                                        <h4>选择全场商品</h4>
                                        <p style="margin-top: 10px">所有商品可使用优惠码</p>
                                        <div class="addCampaignCodeCardChecked" v-if="addCampaignCodeForm.scope == 1">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </div>
                                    <div class="addCampaignCodeCard" :class="{
                      addCampaignCodeSelectedCard:
                        addCampaignCodeForm.scope == 2,
                    }" @click="addCampaignCodeForm.scope = 2">
                                        <h4>选择部分商品</h4>
                                        <p v-if="addCampaignCodeForm.scope == 1">
                                            仅限部分商品或款式可使用<br />优惠码
                                        </p>
                                        <p v-if="addCampaignCodeForm.scope == 2">
                                            已选择{{ selectedGoodsTable.total }}件商品
                                            <el-button type="text" icon="el-icon-plus" style="padding-top: 4px; padding-bottom: 4px" @click="openSelectGoods">继续新增商品</el-button>
                                        </p>
                                        <div class="addCampaignCodeCardChecked" v-if="addCampaignCodeForm.scope == 2">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <!-- 数据表 -->
                    <el-table ref="selectedGoodsTable" class="mt_3" stripe v-if="
              addCampaignCodeForm.scope == 2 && selectedGoodsTable.total > 0
            " :data="selectedGoodsTable.data">
                        <el-table-column label="商品图片" width="80">
                            <template slot-scope="scope">
                                <el-image fit="contain" style="width: 40px; height: 40px" :src="scope.row.goodsImages[ 0 ]">
                                    <div slot="error" class="el-image__error" style="width: 40px; height: 40px; font-size: 10px">
                                        无图
                                    </div>
                                </el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="goodsTitle" label="商品名称" min-width="80"></el-table-column>
                        <el-table-column label="售价/原价" min-width="80">
                            <template slot-scope="scope">
                                {{ scope.row.price }} / {{ scope.row.compareAtPrice }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="inventoryQuantity" label="库存" min-width="80"></el-table-column>
                        <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center">
                            <template slot-scope="scope">
                                <el-button size="mini" type="text" @click="deleteSelectedGoods(scope.row)">退出活动</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </section>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogAddCampaignCode = false">取消</el-button>
                <el-button type="primary" :loading="btnLoading" @click="addCampaignCode">保存</el-button>
            </div>
        </el-dialog>

        <!-- 编辑优惠码活动 -->
        <el-dialog fullscreen title="编辑优惠码活动" id="editCampaignCodeDialog" :visible.sync="dialogEditCampaignCode" :close-on-click-modal="false">
            <el-form ref="editCampaignCodeForm" size="medium" label-position="top" status-icon :model="editCampaignCodeForm" :rules="editCampaignCodeRules">
                <!-- 创建活动 -->
                <section class="addCampaignCodeSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignCodeLegend">基本信息</el-col>
                        <el-col :span="20">
                            <el-form-item label="活动名称" prop="campaignName" style="max-width: 650px">
                                <el-input placeholder="请输入活动名称" maxlength="20" show-word-limit v-model="editCampaignCodeForm.campaignName"></el-input>
                            </el-form-item>
                            <div class="df ai_fe">
                                <el-form-item label="优惠码" prop="discountCode" style="width: 100%; max-width: 650px">
                                    <el-input placeholder="4～16位字符" maxlength="16" show-word-limit disabled @blur="checkCodeRules('editCampaignCodeForm')" v-model="editCampaignCodeForm.discountCode"></el-input>
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>
                </section>

                <section class="addCampaignCodeSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignCodeLegend">使用规则</el-col>
                        <el-col :span="20">
                            <div class="df ai_fe">
                                <el-form-item label="活动时间" prop="startTime">
                                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间" disabled v-model="editCampaignCodeForm.startTime">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item class="px_1" style="color: #bbb">～</el-form-item>
                                <el-form-item :prop="editCampaignCodeEndTime ? '' : 'endTime'">
                                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间" :disabled="editCampaignCodeEndTime" v-model="editCampaignCodeForm.endTime">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item prop="isDefault" class="ml_3">
                                    <el-checkbox v-model="editCampaignCodeEndTime" @change="
                      ($event) => {
                        if ($event) editCampaignCodeForm.endTime = null;
                      }
                    ">不限结束时间</el-checkbox>
                                </el-form-item>
                            </div>
                            <!-- <div class="df ai_fe">
                <el-form-item
                  label="数量"
                  prop="discountCodeCount"
                  style="max-width: 240px"
                >
                  <el-input
                    maxlength="10"
                    :disabled="editCampaignCodeDiscountCodeCount"
                    v-model="editCampaignCodeForm.discountCodeCount"
                  >
                    <template slot="append">张</template>
                  </el-input>
                  <el-checkbox
                    class="mt_2"
                    v-model="editCampaignCodeDiscountCodeCount"
                    @change="
                      ($event) => {
                        if ($event)
                          editCampaignCodeForm.discountCodeCount = null;
                      }
                    "
                    >不限</el-checkbox
                  >
                </el-form-item>
                <el-form-item
                  label="每人限用"
                  prop="useNum"
                  style="margin-left: 30px; max-width: 240px"
                >
                  <el-input
                    maxlength="10"
                    :disabled="editCampaignCodeUseNum"
                    v-model="editCampaignCodeForm.useNum"
                  >
                    <template slot="append">次</template>
                  </el-input>
                  <el-checkbox
                    class="mt_2"
                    v-model="editCampaignCodeUseNum"
                    @change="
                      ($event) => {
                        if ($event) editCampaignCodeForm.useNum = null;
                      }
                    "
                    >不限</el-checkbox
                  >
                </el-form-item>
              </div> -->

                            <div class="df ai_fe">
                                <el-form-item label="优惠码数量（张）" prop="codeNum">
                                    <el-input-number :min="0" :precision="0" v-if="!editCampaignCodeCodeNum" v-model="editCampaignCodeForm.codeNum"></el-input-number>
                                    <span class="ml_3">
                                        <el-checkbox v-model="editCampaignCodeCodeNum" @change="
                        ($event) => {
                          if ($event) editCampaignCodeForm.codeNum = null;
                        }
                      ">不限</el-checkbox>
                                    </span>
                                </el-form-item>
                            </div>
                            <div class="df ai_fe">
                                <el-form-item label="每人限用（次）" prop="codeLimitNum">
                                    <el-input-number :min="0" :precision="0" v-if="!editCampaignCodeCodeLimitNum" v-model="editCampaignCodeForm.codeLimitNum"></el-input-number>
                                    <span class="ml_3">
                                        <el-checkbox v-model="editCampaignCodeCodeLimitNum" @change="
                        ($event) => {
                          if ($event) editCampaignCodeForm.codeLimitNum = null;
                        }
                      ">不限</el-checkbox>
                                    </span>
                                </el-form-item>
                            </div>
                            <div class="df ai_fe">
                                <el-form-item label="是否可抵运费" prop="isFreight">
                                    <el-radio-group v-model="editCampaignCodeForm.isFreight">
                                        <el-radio :label="0">否</el-radio>
                                        <el-radio :label="1">是</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                            <div class="df ai_fe">
                                <el-form-item label="是否可与满减活动叠加" prop="isFull">
                                    <el-radio-group v-model="editCampaignCodeForm.isFull">
                                        <el-radio :label="0">否</el-radio>
                                        <el-radio :label="1">是</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>

                        </el-col>
                    </el-row>
                </section>

                <section class="addCampaignCodeSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignCodeLegend">优惠规则</el-col>
                        <el-col :span="20">
                            <div class="df ai_fe">
                                <el-form-item label="优惠门槛" prop="discountCondition">
                                    <el-select v-model="editCampaignCodeForm.discountCondition" style="width: 240px" disabled>
                                        <el-option label="无门槛" value="1"></el-option>
                                        <el-option label="消费满件数" value="2"></el-option>
                                        <el-option label="消费满金额" value="3"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item prop="discountConditionNum" class="ml_3" style="width: 180px" v-if="editCampaignCodeForm.discountCondition != 1">
                                    <el-input v-model="editCampaignCodeForm.discountConditionNum">
                                        <template slot="append" v-if="editCampaignCodeForm.discountCondition == 2">件</template>
                                        <template slot="append" v-if="editCampaignCodeForm.discountCondition == 3">USD</template>
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div class="df ai_fe">
                                <el-form-item label="优惠类型" prop="discountType">
                                    <el-select v-model="editCampaignCodeForm.discountType" style="width: 240px" disabled>
                                        <el-option label="折扣" value="1"></el-option>
                                        <el-option label="固定金额" value="2"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item prop="discountNum" class="ml_3" style="width: 180px">
                                    <el-input v-model="editCampaignCodeForm.discountNum">
                                        <template slot="append" v-if="editCampaignCodeForm.discountType == 1">%off</template>
                                        <template slot="append" v-if="editCampaignCodeForm.discountType == 2">USD</template>
                                    </el-input>
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>
                </section>

                <section class="addCampaignCodeSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignCodeLegend">适用范围</el-col>
                        <el-col :span="20">
                            <el-form-item prop="scope">
                                <div class="df ai_c mt_2">
                                    <div class="addCampaignCodeCard" :class="{
                      addCampaignCodeSelectedCard:
                        editCampaignCodeForm.scope == 1,
                    }" @click="editCampaignCodeForm.scope = 1">
                                        <h4>选择全场商品</h4>
                                        <p style="margin-top: 10px">所有商品可使用优惠码</p>
                                        <div class="addCampaignCodeCardChecked" v-if="editCampaignCodeForm.scope == 1">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </div>
                                    <div class="addCampaignCodeCard" :class="{
                      addCampaignCodeSelectedCard:
                        editCampaignCodeForm.scope == 2,
                    }" @click="editCampaignCodeForm.scope = 2">
                                        <h4>选择部分商品</h4>
                                        <p v-if="editCampaignCodeForm.scope == 1">
                                            仅限部分商品或款式可使用<br />优惠码
                                        </p>
                                        <p v-if="editCampaignCodeForm.scope == 2">
                                            已选择{{ selectedGoodsTable.total }}件商品
                                            <el-button type="text" icon="el-icon-plus" style="padding-top: 4px; padding-bottom: 4px" @click="openSelectGoods">继续新增商品</el-button>
                                        </p>
                                        <div class="addCampaignCodeCardChecked" v-if="editCampaignCodeForm.scope == 2">
                                            <i class="el-icon-check"></i>
                                        </div>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <!-- 数据表 -->
                    <el-table ref="selectedGoodsTable" class="mt_3" stripe v-if="
              editCampaignCodeForm.scope == 2 && selectedGoodsTable.total > 0
            " :data="selectedGoodsTable.data">
                        <el-table-column label="商品图片" width="80">
                            <template slot-scope="scope">
                                <el-image fit="contain" style="width: 40px; height: 40px" :src="scope.row.goodsImages[ 0 ]">
                                    <div slot="error" class="el-image__error" style="width: 40px; height: 40px; font-size: 10px">
                                        无图
                                    </div>
                                </el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="goodsTitle" label="商品名称" min-width="80"></el-table-column>
                        <el-table-column label="售价/原价" min-width="80">
                            <template slot-scope="scope">
                                {{ scope.row.price }} / {{ scope.row.compareAtPrice }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="inventoryQuantity" label="库存" min-width="80"></el-table-column>
                        <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center">
                            <template slot-scope="scope">
                                <el-button size="mini" type="text" @click="deleteSelectedGoods(scope.row)">退出活动</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </section>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogEditCampaignCode = false">取消</el-button>
                <el-button type="primary" :loading="btnLoading" @click="editCampaignCode">保存</el-button>
            </div>
        </el-dialog>

        <el-dialog fullscreen title="优惠码-数据明细" :visible.sync="visibleDialogData" :close-on-click-modal="false" class="dialog-data" @closed="resetDataInfo">
            <div class="data-mod">
                <div class="grid">
                    <p><span>活动名称：</span> {{ dataInfo.campaignName }}</p>
                    <p><span>优惠码：</span> {{ dataInfo.discountCode }}</p>
                </div>
                <div class="border-grid-mod">
                    <div class="item">
                        <p>已使用</p>
                        <p><span class="ft-price">{{ dataInfo.used }}</span>张</p>
                    </div>
                    <div class="item">
                        <p>优惠金额</p>
                        <p><span class="ft-price">${{ dataInfo.discountCodePrice }}</span></p>
                    </div>
                    <div class="item">
                        <p>订单金额</p>
                        <p><span class="ft-price">${{ dataInfo.totalPrice }}</span></p>
                    </div>
                    <div class="item">
                        <p>平均客单价</p>
                        <p><span class="ft-price">${{ dataInfo.customerAvgPrice }}</span></p>
                    </div>
                </div>
            </div>

            <el-form :inline="true" :model="dataForm" style="margin-top: 20px;">
                <el-form-item>
                    <el-input v-model="dataForm.orderNo" placeholder="订单号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="dataForm.shippingEmail" placeholder="顾客邮箱"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="dataFormRate" :picker-options="pickerOptionsA" :default-value="new Date()" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="searchDataForm">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="resetDataForm">重置</el-button>
                </el-form-item>
            </el-form>

            <div>
                <el-table :data="infoTableData" ref="infoTableData" stripe :default-sort="option" @sort-change="changeSort($event, 'dataForm')">
                    <el-table-column label="订单号" prop="orderNo"></el-table-column>
                    <el-table-column label="顾客邮箱" prop="shippingEmail"></el-table-column>
                    <el-table-column label="优惠金额($)" prop="discountCodePrice" sortable="1"></el-table-column>
                    <el-table-column label="订单金额($)" prop="totalPrice" sortable="2"></el-table-column>
                    <el-table-column label="商品数量" prop="goodsCount" sortable="3"></el-table-column>
                    <el-table-column label="支付时间" prop="financialTime" sortable="4"></el-table-column>
                </el-table>
            </div>

            <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="dataForm.pageNo" :page-sizes="[20, 50, 100]" :page-size.sync="dataForm.pageSize" :total="dataTotal" @size-change="getCodeOrderPage" @current-change="getCodeOrderPage">
            </el-pagination>
        </el-dialog>

        <!-- 插入图片 -->
        <ImageUploader ref="uploadImage" :insertImage="dialogInsertImage" :insertType="insertType" :activeName="activeName" @getOpenState="getOpenState" @getImage="getImage">
        </ImageUploader>

        <!-- 选择商品 -->
        <SelectGoods ref="selectGoods" :showDialog="dialogSelectGoods" :containItem="false" :selectedGoods="selectedGoodsTable.data" @closeDialog="closeSelectGoods" @saveSelectedGoods="saveSelectGoods">
        </SelectGoods>
    </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader';
import SelectGoods from '@/components/SelectGoods';

export default {
    name: 'CampaignCode',

    components: {
        ImageUploader: ImageUploader,
        SelectGoods: SelectGoods,
    },

    data() {
        return {
            btnLoading: false,
            randomCodeLoading: false, // 随机优惠码loading

            // 插入图片参数
            dialogInsertImage: false,
            activeName: 'first',
            insertType: null,

            // 选择商品参数
            dialogSelectGoods: false,

            /* 类型集合 */
            campaignStatusTypes: [
                // 活动状态

                { name: '未开始', value: '1' },
                { name: '进行中', value: '2' },
                { name: '已结束', value: '3' },
            ],

            // 列表查询参数
            campaignStatus: '0',
            queryForm: {
                campaignStatus: '0',
                campaignName: null,
                discountCode: null,
                discountType: null,
                pageNo: 1,
                pageSize: 10,
            },

            // 列表数据
            loading: false,
            tableData: [],
            total: 0,

            // 新增优惠码活动
            dialogAddCampaignCode: false,
            addCampaignCodeForm: {},
            addCampaignCodeEndTime: false, // 不限结束时间
            addCampaignCodeCodeNum: false,
            addCampaignCodeCodeLimitNum: false,
            addCampaignCodeDiscountCodeCount: false, // 不限优惠码数量
            addCampaignCodeUseNum: false, // 不限单人使用次数
            addCampaignCodeRules: {
                campaignName: [
                    {
                        required: true,
                        message: '请输入活动名称',
                        trigger: 'blur',
                    },
                ],
                discountCode: [
                    {
                        required: true,
                        message: '请输入优惠码',
                        trigger: 'blur',
                    },
                ],
                startTime: [
                    {
                        required: true,
                        message: '请选择活动时间',
                        trigger: 'blur',
                    },
                ],
                endTime: [
                    {
                        required: true,
                        message: '请选择结束时间',
                        trigger: 'blur',
                    },
                ],
                discountNum: [
                    {
                        required: true,
                        message: '请输入优惠数值',
                        trigger: 'blur',
                    },
                ],
            },

            // 编辑优惠码活动
            dialogEditCampaignCode: false,
            editCampaignCodeForm: {},
            editCampaignCodeEndTime: false, // 不限结束时间
            editCampaignCodeCodeNum: false,
            editCampaignCodeCodeLimitNum: false,
            editCampaignCodeDiscountCodeCount: false, // 不限优惠码数量
            editCampaignCodeUseNum: false, // 不限单人使用次数
            editCampaignCodeRules: {
                campaignName: [
                    {
                        required: true,
                        message: '请输入活动名称',
                        trigger: 'blur',
                    },
                ],
                discountCode: [
                    {
                        required: true,
                        message: '请输入优惠码',
                        trigger: 'blur',
                    },
                ],
                startTime: [
                    {
                        required: true,
                        message: '请选择活动时间',
                        trigger: 'blur',
                    },
                ],
                endTime: [
                    {
                        required: true,
                        message: '请选择结束时间',
                        trigger: 'blur',
                    },
                ],
                discountNum: [
                    {
                        required: true,
                        message: '请输入优惠数值',
                        trigger: 'blur',
                    },
                ],
            },

            bannerColors: [], // 配色方案

            // 选中的商品列表数据
            selectedGoodsTable: {
                data: [],
                total: 0,
            },

            // 数据明细
            pickerOptionsA: {
                disabledDate: (time) => {
                    return time.getTime() > Date.now();
                },
            },
            infoTableData: [],
            edit_discountCode: null,
            dataForm: {
                discountCode: null,
                orderNo: null,
                shippingEmail: null,
                pageNo: 1,
                pageSize: 10,
                startFinancialTime: null,
                endFinancialTime: null,
                sortField: 0,
                sortType: 0,
            },
            option: { prop: 'orderNo' },
            sortOptions: [
                { name: 'discountCodePrice', value: 1 },
                { name: 'totalPrice', value: 2 },
                { name: 'goodsCount', value: 3 },
                { name: 'financialTime', value: 4 },
            ],
            dataFormRate: [],
            dataTotal: 0,
            dataInfo: {
                campaignName: '',
                customerAvgPrice: '',
                discountCode: '',
                discountCodePrice: '',
                totalPrice: '',
                used: null,
            },
            visibleDialogData: false,
        };
    },

    mounted() {
        this.queryData();
    },

    computed: {
        // 新增优惠码下一步
        addCampaignCodeNextStepDisabled() {
            return (
                this.addCampaignCodeForm.campaignName == null ||
                this.addCampaignCodeForm.campaignName == ''
            );
        },

        // 编辑优惠码下一步
        editCampaignCodeNextStepDisabled() {
            return (
                this.editCampaignCodeForm.campaignName == null ||
                this.editCampaignCodeForm.campaignName == ''
            );
        },
    },

    methods: {
        checkCodeRules(type) {
            // let rule = new RegExp(/^[A-Za-z0-9]{4,16}$/)
            // // let len = this[type].discountCode.length
            // // if(len < 4 || len > 16){
            // //   this.$message.error('优惠码长度不匹配.')
            // //   return false
            // // }
            // if(!rule.test(this[type].discountCode)){
            //   this.$message.error('优惠码必须为4~16位字母或数字.')
            //   return false
            // } else {
            //   return true
            // }
            return true;
        },
        // 插入图片方法
        openImageUploader(obj) {
            this.insertType = obj;
            this.dialogInsertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.dialogInsertImage = result;
        },
        getImage(obj, image) {
            switch (obj) {
                case 'addCampaignCodeForm.banner':
                    this.addCampaignCodeForm.banner = image[image.length - 1];
                    break;

                case 'editCampaignCodeForm.banner':
                    this.editCampaignCodeForm.banner = image[image.length - 1];
                    break;
            }
        },

        // 选择商品方法
        openSelectGoods() {
            this.$refs.selectGoods.caller = 'notset';
            this.$refs.selectGoods.init();
            this.dialogSelectGoods = true;
        },
        closeSelectGoods() {
            this.dialogSelectGoods = false;
        },
        saveSelectGoods(items, caller) {
            switch (caller) {
                default:
                    this.selectedGoodsTable.data = items;
                    this.selectedGoodsTable.total =
                        this.selectedGoodsTable.data.length;
            }
        },

        // 获取列表数据
        queryData() {
            if (this.campaignStatus == '0')
                this.queryForm.campaignStatus = null;
            else this.queryForm.campaignStatus = this.campaignStatus;

            this.loading = true;

            this.$http
                .get('/campaign/code/getPage', { params: this.queryForm })
                .then((r) => {
                    this.loading = false;

                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        this.total = r.data.count;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    this.loading = false;
                });
        },

        // 重置列表查询条件
        resetCondition() {
            this.queryForm.campaignName = null;
            this.queryForm.discountCode = null;
            this.queryForm.discountType = null;
            this.queryForm.pageNo = 1;

            this.queryData();
        },

        // 随机生成优惠码
        randomCampaignCode(form) {
            this.randomCodeLoading = true;

            this.$http
                .get('/campaign/code/getRandom')
                .then((r) => {
                    this.randomCodeLoading = false;
                    this[form].discountCode = r.data.data;
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    this.randomCodeLoading = false;
                });
        },

        // 删除选中的商品（退出活动）
        deleteSelectedGoods(item) {
            let index = this.selectedGoodsTable.data.indexOf(item);

            if (index > -1) {
                this.selectedGoodsTable.data.splice(index, 1);
                this.selectedGoodsTable.total =
                    this.selectedGoodsTable.data.length;
            }
        },

        // 新增优惠码
        initAddCampaignCode() {
            this.addCampaignCodeForm = {
                campaignName: null,
                discountCode: null,
                startTime: null,
                endTime: null,
                discountCodeCount: null,
                useNum: null,
                discountCondition: '1',
                discountConditionNum: null,
                discountConditionUnit: null,
                discountType: '1',
                discountNum: null,
                discountUnit: null,
                scope: 1,
                goodsItemCodes: null,
                isFreight: 0,
                isFull: 0,
                codeNum: 0,
                codeLimitNum: null,
            };

            this.addCampaignCodeEndTime = false; // 不限结束时间
            this.addCampaignCodeDiscountCodeCount = false; // 不限优惠码数量
            this.addCampaignCodeUseNum = false; // 不限单人使用次数
            this.selectedGoodsTable.data = []; // 选中的商品
            this.selectedGoodsTable.total = 0;
            this.dialogAddCampaignCode = true;
            this.addCampaignCodeCodeNum = false;
            this.addCampaignCodeCodeLimitNum = false;
        },
        async addCampaignCode() {
            let re = await this.checkCodeRules('addCampaignCodeForm');
            console.log(re);
            if (re) {
                this.$refs.addCampaignCodeForm.validate((valid) => {
                    if (valid) {
                        this.btnLoading = true;

                        if (this.addCampaignCodeForm.discountCondition == '2')
                            this.addCampaignCodeForm.discountConditionUnit =
                                '件';

                        if (this.addCampaignCodeForm.discountCondition == '3')
                            this.addCampaignCodeForm.discountConditionUnit =
                                'USD';
                        this.addCampaignCodeForm.discountUnit =
                            this.addCampaignCodeForm.discountType == '1'
                                ? '%off'
                                : 'USD';
                        this.addCampaignCodeForm.goodsIds =
                            this.selectedGoodsTable.total > 0
                                ? this.selectedGoodsTable.data.map(
                                      (item) => item.id
                                  )
                                : null;

                        // if(this.addCampaignCodeForm.codeNum == 0){
                        //   this.addCampaignCodeForm.codeNum = null
                        // }
                        if (this.addCampaignCodeForm.codeLimitNum == 0) {
                            this.addCampaignCodeForm.codeLimitNum = null;
                        }

                        this.$http
                            .post(
                                '/campaign/code/add',
                                this.addCampaignCodeForm
                            )
                            .then((r) => {
                                this.btnLoading = false;

                                if (r.data.code === 200) {
                                    this.dialogAddCampaignCode = false;

                                    this.$message({
                                        message: '新增成功',
                                        type: 'success',
                                        duration: 1000,
                                        onClose: this.queryData,
                                    });
                                }
                            })
                            .catch((err) => {
                                // this.$message.error(error);;
                                this.btnLoading = false;
                            });
                    }
                });
            } else {
                this.$message.error('请输入优惠码。');
            }
        },

        // 编辑优惠码
        initEditCampaignCode(row) {
            this.$http
                .get('/campaign/code/getDetail', { params: { id: row.id } })
                .then((r) => {
                    if (r.data.code === 200) {
                        let item = r.data.data;

                        this.editCampaignCodeForm = {
                            id: item.id,
                            campaignName: item.campaignName,
                            discountCode: item.discountCode, // 不可修改
                            startTime: item.startTime, // 不可修改
                            endTime: item.endTime,
                            discountCodeCount: item.discountCodeCount,
                            useNum: item.useNum,
                            discountCondition:
                                item.discountCondition.toString(),
                            discountConditionNum: item.discountConditionNum,
                            discountConditionUnit: item.discountConditionUnit,
                            discountType: item.discountType.toString(),
                            discountNum: item.discountNum,
                            discountUnit: item.discountUnit,
                            scope: item.scope,
                            goodsItemCodes: null,
                            isFreight: item.isFreight,
                            isFull: item.isFull,
                            codeNum:
                                parseInt(item.codeNum) >= 0
                                    ? item.codeNum
                                    : null,
                            codeLimitNum: item.codeLimitNum
                                ? item.codeLimitNum
                                : null,
                        };

                        this.editCampaignCodeEndTime = this.editCampaignCodeForm
                            .endTime
                            ? false
                            : true; // 不限结束时间
                        this.editCampaignCodeDiscountCodeCount = this
                            .editCampaignCodeForm.discountCodeCount
                            ? false
                            : true; // 不限优惠码数量
                        this.editCampaignCodeUseNum = this.editCampaignCodeForm
                            .useNum
                            ? false
                            : true; // 不限单人使用次数
                        // console.log(this.editCampaignCodeForm.codeNum, parseInt(this.editCampaignCodeForm.codeNum) >= 0)
                        this.editCampaignCodeCodeNum =
                            parseInt(this.editCampaignCodeForm.codeNum) >= 0
                                ? false
                                : true;
                        this.editCampaignCodeCodeLimitNum = this
                            .editCampaignCodeForm.codeLimitNum
                            ? false
                            : true;

                        if (item.scope == 1) {
                            this.selectedGoodsTable.data = []; // 选中的商品
                            this.selectedGoodsTable.total = 0;
                        } else {
                            this.selectedGoodsTable.data = item.goodsList; // 选中的商品
                            this.selectedGoodsTable.total =
                                item.goodsList.length;
                        }
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                });

            this.dialogEditCampaignCode = true;
        },
        async editCampaignCode() {
            let re = await this.checkCodeRules('editCampaignCodeForm');
            if (re) {
                this.$refs.editCampaignCodeForm.validate((valid) => {
                    if (valid) {
                        this.btnLoading = true;

                        if (this.editCampaignCodeForm.discountCondition == '2')
                            this.editCampaignCodeForm.discountConditionUnit =
                                '件';

                        if (this.editCampaignCodeForm.discountCondition == '3')
                            this.editCampaignCodeForm.discountConditionUnit =
                                'USD';
                        this.editCampaignCodeForm.discountUnit =
                            this.editCampaignCodeForm.discountType == '1'
                                ? '%off'
                                : 'USD';
                        this.editCampaignCodeForm.goodsIds =
                            this.selectedGoodsTable.total > 0
                                ? this.selectedGoodsTable.data.map(
                                      (item) => item.id
                                  )
                                : null;

                        // if(this.editCampaignCodeForm.codeNum == 0){
                        //   this.editCampaignCodeForm.codeNum = null
                        // }

                        if (this.editCampaignCodeForm.codeLimitNum == 0) {
                            this.editCampaignCodeForm.codeLimitNum = null;
                        }

                        this.$http
                            .post(
                                '/campaign/code/edit',
                                this.editCampaignCodeForm
                            )
                            .then((r) => {
                                this.btnLoading = false;

                                if (r.data.code === 200) {
                                    this.dialogEditCampaignCode = false;

                                    this.$message({
                                        message: '编辑成功',
                                        type: 'success',
                                        duration: 1000,
                                        onClose: this.queryData,
                                    });
                                }
                            })
                            .catch((err) => {
                                // this.$message.error(error);;
                                this.btnLoading = false;
                            });
                    }
                });
            } else {
                this.$message.error('请输入优惠码。');
            }
        },

        // 提前结束优惠码
        stopCampaignCode(item) {
            this.$confirm('确定要结束优惠码吗？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.$http
                    .get('/campaign/code/end', { params: { id: item.id } })
                    .then((r) => {
                        if (r.data.code === 200) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1000,
                                onClose: this.queryData,
                            });
                        }
                    })
                    .catch((err) => {
                        // this.$message.error(error);;
                    });
            });
        },

        // 删除优惠码
        deleteCampaignCode(item) {
            this.$confirm('确定要删除优惠码吗？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.$http
                    .delete('/campaign/code/delete', {
                        params: { id: item.id },
                    })
                    .then((r) => {
                        if (r.data.code === 200) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                                duration: 1000,
                                onClose: this.queryData,
                            });
                        }
                    })
                    .catch((err) => {
                        // this.$message.error(error);;
                    });
            });
        },

        // 数据明细
        openDataInfo({ discountCode }) {
            this.edit_discountCode = discountCode;
            this.resetDataForm();
            // this.dataForm.discountCode = discountCode
            this.getCodeOrderPage();
            this.getCodeStatistics(discountCode);
            this.visibleDialogData = true;
        },
        getCodeOrderPage() {
            if (!this.dataForm.orderNo) {
                this.dataForm.orderNo = null;
            }
            if (!this.dataForm.shippingEmail) {
                this.dataForm.shippingEmail = null;
            }
            this.dataForm.startFinancialTime = this.dataFormRate[0];
            this.dataForm.endFinancialTime = this.dataFormRate[1];

            let url = '/campaign/code/getCodeOrderPage',
                params = {
                    // discountCode: this.edit_discountCode,
                    ...this.dataForm,
                };

            this.$http
                .get(url, {
                    params,
                })
                .then((res) => {
                    let { code, data, count } = res.data;
                    if (code === 200) {
                        this.infoTableData = data;
                        this.dataTotal = count;
                    }
                });
        },
        searchDataForm() {
            this.dataForm.pageNo = 1;
            this.dataForm.discountCode = this.edit_discountCode;
            this.getCodeOrderPage();
        },
        resetDataForm() {
            this.dataForm = {
                discountCode: this.edit_discountCode,
                orderNo: null,
                shippingEmail: null,
                pageNo: 1,
                pageSize: 20,
                startFinancialTime: null,
                endFinancialTime: null,
                sortField: 0,
                sortType: 0,
            };
            this.dataFormRate = [];
            this.$nextTick(() => {
                this.$refs.infoTableData.clearSort();

                this.searchDataForm();
            });
        },
        changeSort(column, form) {
            let name = column.prop;
            let order = column.order;
            for (let i = 0; i < this.sortOptions.length; i++) {
                let item = this.sortOptions[i];
                if (name == item.name) {
                    switch (order) {
                        case 'ascending':
                            this[form].sortType = 1;
                            this[form].sortField = item.value;
                            break;
                        case 'descending':
                            this[form].sortType = 2;
                            this[form].sortField = item.value;
                            break;
                        default:
                            this[form].sortType = null;
                            this[form].sortField = null;
                            break;
                    }
                }
            }
            this.dataForm.pageNo = 1;
            this.getCodeOrderPage();
        },
        getCodeStatistics(discountCode) {
            let url = '/campaign/code/getCodeStatistics';
            this.$http
                .get(`${url}?discountCode=${discountCode}`)
                .then((res) => {
                    let { code, data } = res.data;
                    if (code === 200) {
                        this.dataInfo = data;
                    }
                });
        },
        resetDataInfo() {
            Object.assign(
                this.dataForm,
                this.$options.data.call(this).dataForm
            );
            Object.assign(
                this.dataInfo,
                this.$options.data.call(this).dataInfo
            );
            this.infoTableData = [];
            this.dataTotal = 0;
            this.edit_discountCode = '';
        },
    },
};
</script>

<style scoped>
.addCampaignCodeSection {
    margin-bottom: 16px;
    padding: 16px 32px;
    background-color: #fff;
}

.addCampaignCodeLegend {
    font-size: 20px;
    font-weight: bold;
}

/* 适用范围卡片 */
.addCampaignCodeCard {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    box-sizing: border-box;
    border: solid 1px #ecedf3;
    border-radius: 6px;
    width: 200px;
    height: 100px;
    cursor: pointer;
    overflow: hidden;
}

.addCampaignCodeCard > h4 {
    margin: 5px 16px;
}

.addCampaignCodeCard > p {
    margin: 0 16px;
    color: #999;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
}

.addCampaignCodeSelectedCard {
    border: solid 2px #2d67f3;
    background: #f3f7ff;
}

.addCampaignCodeCardChecked {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 50px;
    height: 50px;
    background-color: #2d67f3;
    transform: rotate(45deg);
}

.addCampaignCodeCardChecked > i {
    position: absolute;
    top: 31px;
    left: 16px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    transform: rotate(-45deg);
}

/* Banner图片 */
.BannerImageBox {
    margin-right: 20px;
    margin-bottom: 20px;
    width: 320px !important;
    height: 176px !important;
    background-color: #f5f6f8;
}

.BannerImage {
    max-width: 320px;
    max-height: 176px;
}

.BannerImageBox > .mask_d5 {
    display: none;
}

.BannerImageBox:hover > .mask_d5 {
    display: block;
}

/* 方案设置项 */
.DemoBox {
    display: flex;
    align-items: center;
    margin: 16px 0;
    padding: 0 24px;
    height: 80px;
}

.DemoBox > p {
    font-size: 18px;
}

.DemoBox_Time {
    border-radius: 6px;
    padding: 4px 10px;
    font-size: 20px;
}

.DemoColorRow {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.DemoColorTitle {
    width: 300px;
}

::v-deep .dialog-data .el-dialog {
    background: #f0f1f4;
}
.data-mod {
    background: #fff;
    padding: 20px 20px 40px;
}
.data-mod p {
    margin: 0;
    font-size: 16px;
}
.data-mod .grid {
    line-height: 40px;
}
.data-mod .price {
    line-height: 40px;
}
.data-mod .ft-price {
    font-size: 28px;
    padding-right: 10px;
}
.border-grid-mod {
    line-height: 30px;
}
.border-grid-mod {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 200px 200px 200px 200px;
    gap: 40px;
}
.border-grid-mod .item {
    border: 1px solid #797979;
    padding: 6px 14px;
}
.table-mod {
    margin-top: 30px;
}
</style>
<style>
#addCampaignCodeDialog > .el-dialog,
#editCampaignCodeDialog > .el-dialog {
    background: #ecedf3;
}
</style>
