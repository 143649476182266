<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>营销管理</el-breadcrumb-item>
            <el-breadcrumb-item>秒杀活动</el-breadcrumb-item>
        </el-breadcrumb>

        <el-tabs v-model="campaignStatus" @tab-click="queryData">
            <el-tab-pane label="全部" name="0"></el-tab-pane>
            <el-tab-pane v-for="item in campaignStatusTypes" :key="item.value" :label="item.name" :name="item.value"></el-tab-pane>
        </el-tabs>

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm">
            <el-form-item label="活动名称">
                <el-input v-model="queryForm.campaignName" placeholder="活动名称"></el-input>
            </el-form-item>
            <el-form-item label="优惠类型">
                <el-select v-model="queryForm.discountType">
                    <el-option v-for="item in discountTypeOption" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="querySearch">查询</el-button>
                <el-button icon="el-icon-refresh-left" @click="resetCondition">重置</el-button>
                <el-button type="success" icon="el-icon-plus" @click="openAdd">新增</el-button>

                <el-button type="warning" icon="el-icon-s-data" @click="bridge_getStaticTotal">数据明细</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表 -->
        <el-table border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" :data="tableData" v-loading="loading">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="campaignName" label="活动名称" min-width="120"></el-table-column>
            <el-table-column label="活动时间" prop="startTime" width="160">
                <template slot-scope="scope">
                    {{ scope.row.startTime }} - {{ scope.row.endTime }}
                </template>
            </el-table-column>
            <el-table-column label="商品数量" prop="goodsCount" width="100"></el-table-column>

            <el-table-column label="活动状态" width="120">
                <template slot-scope="scope">
                    {{ campaignStatusTypes.find((v) => v.value == scope.row.campaignStatus).name }}
                </template>
            </el-table-column>
            <el-table-column label="优惠类型" width="120">
                <template slot-scope="scope">
                    {{ discountTypeOption.find((v) => v.value == scope.row.discountType).name }}
                </template>
            </el-table-column>

            <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-view" @click="openEdit(scope.row.id , true)" v-show="scope.row.campaignStatus == 3">详情</el-button>
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="openEdit(scope.row.id)" v-show="scope.row.campaignStatus != 3">编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-video-pause" v-if="scope.row.campaignStatus == 2" @click="endThis(scope.row.id)">提前结束</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="deleteThis(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[20, 50, 100]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <!-- 编辑满减活动 -->
        <el-dialog :title="isAdding ? '新增秒杀活动' : '编辑秒杀活动'" :visible.sync="dialogAdd" id="addCampaignCodeDialog" :close-on-click-modal="false" top="3%" fullscreen>
            <el-form :model="dialogAddForm" :rules="dialogAddRules" status-icon ref="dialogAddForm" size="medium">
                <!-- 创建活动 -->
                <section class="addCampaignCodeSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignCodeLegend">基本信息</el-col>
                        <el-col :span="20">

                            <el-form-item label="活动名称" prop="campaignName" style="max-width: 400px">
                                <el-input placeholder="请输入活动名称" maxlength="20" show-word-limit v-model="dialogAddForm.campaignName"></el-input>
                            </el-form-item>

                            <div class="df ai_fe">
                                <el-form-item label="活动时间" prop="startTime">
                                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间" v-model="dialogAddForm.startTime" :disabled="!isAdding">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item class="px_1" style="color: #bbb">～</el-form-item>
                                <el-form-item prop="endTime">
                                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间" v-model="dialogAddForm.endTime">
                                    </el-date-picker>
                                </el-form-item>
                            </div>

                        </el-col>
                    </el-row>
                </section>

                <section class="addCampaignCodeSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignCodeLegend">优惠规则</el-col>
                        <el-col :span="20">

                            <el-form-item label="优惠类型" prop="discountType">
                                <el-select v-model="dialogAddForm.discountType" :disabled="!isAdding" style="width: 240px" @change="resetListDiscount">
                                    <el-option v-for="item in discountTypeOption" :key="item.value" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>

                        </el-col>
                    </el-row>
                </section>

                <section class="addCampaignCodeSection">
                    <el-row>
                        <el-col :span="4" class="addCampaignCodeLegend">使用范围</el-col>
                        <el-col :span="20">

                            <div style="margin-bottom: 15px;margin-top: 5px;">（原价为0的商品不可选择）</div>
                            <el-form-item>
                                <!-- <el-button
			            size="mini"
			            type="primary"
			            icon="el-icon-s-tools"
			            @click="chooseItem">
			            选择商品...
			          </el-button> -->
                                <el-button type="primary" icon="el-icon-plus" @click="openSelectGoods">选择商品
                                </el-button>
                                &nbsp;&nbsp;已选商品：{{ selectedGoodsTable.total }}
                            </el-form-item>

                            <!-- 数据表 -->
                            <el-table ref="selectedGoodsTable" class="mt_3" stripe :data="selectedGoodsTable.data" row-key="su" default-expand-all :tree-props="{children: 'goodsItems'}">>
                                <el-table-column prop="" label="" width="50px"></el-table-column>
                                <el-table-column label="商品图片" width="80">
                                    <template slot-scope="scope">
                                        <!-- <el-image v-if="scope.row.goodsImages && scope.row.goodsImages.length > 0" fit="contain" style="width: 40px; height: 40px" :src="scope.row.goodsImages[0]" :preview-src-list="scope.row.goodsImages">
                                            <div slot="error" class="el-image__error" style="width: 40px; height: 40px; font-size: 10px">
                                                无图
                                            </div>
                                        </el-image> -->
                                        <GoodsImageTemplate :images="scope.row.goodsImages" v-if="scope.row.goodsImages && scope.row.goodsImages.length > 0"></GoodsImageTemplate>

                                    </template>
                                </el-table-column>
                                <el-table-column prop="goodsTitle" label="商品名称" min-width="250px"></el-table-column>
                                <el-table-column prop="su" label="SPU/SKU" width="100px"></el-table-column>
                                <el-table-column label="款式" width="100px">
                                    <template slot-scope="scope">
                                        {{ scope.row.goodsTypeStr ? scope.row.goodsTypeStr.toString() : '' }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="售价/原价" min-width="80">
                                    <template slot-scope="scope">
                                        {{ scope.row.priceStr ? scope.row.priceStr : scope.row.price }} / {{ scope.row.compareAtPriceStr ? scope.row.compareAtPriceStr : scope.row.compareAtPrice }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="inventoryQuantity" label="折扣" min-width="120">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.goodsId">
                                            <el-input v-model="scope.row.discountNum" v-if="dialogAddForm.discountType == 1 || dialogAddForm.discountType == 2" @input="changeDiscountNum($event, scope, scope.row.price, scope.row.compareAtPrice)">
                                                <template slot="append" v-if="dialogAddForm.discountType == 1">%off</template>
                                                <template slot="append" v-if="dialogAddForm.discountType == 2">USD</template>
                                            </el-input>
                                            <span v-else>-</span>
                                        </div>
                                        <div v-else>
                                            <el-input v-model="scope.row.discountNum" v-if="dialogAddForm.discountType == 1 || dialogAddForm.discountType == 2" @input="changeListtNum($event, scope, scope.row.price, scope.row.compareAtPrice)">
                                                <template slot="append">
                                                    <span v-if="dialogAddForm.discountType == 1">%off</span>
                                                    <span v-if="dialogAddForm.discountType == 2">USD</span>
                                                    <el-tooltip class="item" effect="dark" content="批量修改折扣,若子商品折扣不一致,则此项为空" placement="top">
                                                        <i class="el-icon-warning"></i>
                                                    </el-tooltip>
                                                </template>
                                            </el-input>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="原价折后价(USD)" min-width="120">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.goodsId">
                                            <el-input v-model="scope.row.discountNum" v-if="dialogAddForm.discountType == 3" @input="changeDiscountNum($event, scope, scope.row.price, scope.row.compareAtPrice)"></el-input>
                                            <p v-else>
                                                {{ getDiscount(scope.row.price, scope.row.compareAtPrice, scope.row.discountNum) }}
                                            </p>
                                        </div>
                                        <div v-else>
                                            <div v-if="dialogAddForm.discountType == 1 || dialogAddForm.discountType == 2">
                                                <div v-if="scope.row.maxPrice == scope.row.minPrice">{{ scope.row.minPrice }}</div>
                                                <div v-else>{{ Number(scope.row.minPrice) < Number(scope.row.maxPrice) ? scope.row.minPrice : scope.row.maxPrice }} - {{ Number(scope.row.maxPrice) > Number(scope.row.minPrice) ? scope.row.maxPrice : scope.row.minPrice }}</div>
                                            </div>
                                            <el-input v-model="scope.row.discountNum" v-if="dialogAddForm.discountType == 3" @input="changeListtNum($event, scope, scope.row.price, scope.row.compareAtPrice)">
                                                <template slot="append">
                                                    <el-tooltip class="item" effect="dark" content="批量修改折后价,若子商品折后价不一致,则此项为空" placement="top">
                                                        <i class="el-icon-warning"></i>
                                                    </el-tooltip>
                                                </template>
                                            </el-input>
                                        </div>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column
			            prop="goodsRemark"
			            label="备注"
			            min-width="80"
			          ></el-table-column> -->
                                <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center">
                                    <template slot-scope="scope">
                                        <el-button size="mini" type="text" @click="deleteSelectedGoods(scope.row)">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>

                        </el-col>
                    </el-row>
                </section>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData" :disabled="onlyView">确定</el-button>
                <el-button @click="dialogAdd = false">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog fullscreen title="秒杀数据明细" :visible.sync="dialogDetail" :close-on-click-modal="false" @closed="resetDialogData">
            <p>秒杀活动数据自2022年12月31日开始统计</p>
            <el-form :inline="true" :model="detailForm" class="demo-form-inline">
                <el-form-item>
                    <!--  :picker-options="pickerOptionsA" -->
                    <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="detailDate" :default-value="new Date()" :picker-options="pickerOptionsA" value-format="yyyy-MM-dd" :clearable="false"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="searchDetail">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" icon="el-icon-refresh-left" @click="resetDetail">重置</el-button>
                </el-form-item>
            </el-form>
            <section class="detailitems between">
                <div class="item">
                    <div class="tit">订单量</div>
                    <div class="val">{{detailData.orderNum}}单</div>
                </div>
                <div class="item">
                    <div class="tit">优惠金额</div>
                    <div class="val">${{detailData.discountSalePrice}}</div>
                </div>
                <div class="item">
                    <div class="tit">订单金额</div>
                    <div class="val">${{detailData.totalPrice}}</div>
                </div>
                <div class="item">
                    <div class="tit">平均客单价<el-tooltip effect="dark" content="总成交额/总订单量" placement="top"><i class="el-icon-question"></i></el-tooltip>
                    </div>
                    <div class="val">${{detailData.customerAvgPrice}}</div>
                </div>
                <div class="item">
                    <div class="tit">连带率<el-tooltip effect="dark" content="平均每笔订单的商品数量，所有已支付的订单的商品数量/所有已支付的订单的数量" placement="top"><i class="el-icon-question"></i></el-tooltip>
                    </div>
                    <div class="val">{{detailData.relatedRate}}</div>
                </div>
            </section>

            <!-- 数据表 -->
            <el-table border stripe :data="detailTable" v-loading="detailLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" max-height="600">
                <el-table-column prop="campaignName" label="活动名称"></el-table-column>
                <el-table-column prop="orderNum" label="订单量"></el-table-column>
                <el-table-column prop="discountSalePrice" label="优惠金额($)"></el-table-column>
                <el-table-column prop="totalPrice" label="订单金额($)"></el-table-column>
                <el-table-column prop="customerAvgPrice" label="客单价($)"></el-table-column>
                <el-table-column prop="relatedRate" label="连带率"></el-table-column>
            </el-table>
            <el-pagination background :current-page.sync="detailForm.pageNo" :page-sizes="[20, 50, 100]" :page-size.sync="detailForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="detailTotal" @size-change="getDetail" @current-change="getDetail">
            </el-pagination>
        </el-dialog>

        <!-- 选择商品 -->
        <SelectGoods ref="selectGoods" :showDialog="dialogSelectGoods" :containItem="true" :tree="true" :selectedGoods="selectedGoodsTable.data" @closeDialog="closeSelectGoods" @saveSelectedGoods="saveSelectGoods">
        </SelectGoods>

    </div>

</template>

<script>
import SelectGoods from '@/components/SelectGoods';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
export default {
    name: 'CampaignSale',
    components: {
        SelectGoods: SelectGoods,
        GoodsImageTemplate,
    },
    data() {
        return {
            // 选择商品参数
            dialogSelectGoods: false,
            // 选中的商品列表数据
            selectedGoodsTable: {
                data: [],
                total: 0,
            },
            dialogShowgoods: false,
            goodsQuery: {
                goodsTitle: null,
                pageNo: 1,
                pageSize: 10,
            },
            goodsloading: false,
            goodsData: [],
            goodsTotal: 0,
            multipleSelection: [],
            selectedGoods: [],

            campaignStatus: '0',
            queryForm: {
                campaignName: null,
                campaignStatus: '0',
                discountType: null,
                pageNo: 1,
                pageSize: 20,
            },
            tableData: [],
            total: 0,
            loading: false,
            campaignStatusTypes: [
                { name: '未开始', value: '1' },
                { name: '进行中', value: '2' },
                { name: '已结束', value: '3' },
            ],
            discountTypeOption: [
                {
                    value: 1,
                    name: '固定折扣',
                },
                {
                    value: 2,
                    name: '固定减价',
                },
                {
                    value: 3,
                    name: '固定价格',
                },
            ],
            distype: 'USD',
            addCampaignFullEndTime: false, // 不限结束时间
            isAdding: false,
            dialogAdd: false,
            dialogAddForm: {
                campaignName: null,
                discountType: null,
                startTime: null,
                endTime: null,
                saleDiscounts: [],
            },
            dialogAddRules: {
                campaignName: [
                    {
                        required: true,
                        message: '请输入活动名称',
                        trigger: 'blur',
                    },
                ],
                startTime: [
                    { required: true, message: '请选择时间 ', trigger: 'blur' },
                ],
                endTime: [
                    { required: true, message: '请选择时间 ', trigger: 'blur' },
                ],
                discountType: [
                    {
                        required: true,
                        message: '请选择优惠类型',
                        trigger: 'change',
                    },
                ],
            },
            addLoading: false,

            dialogDetail: false,
            detailForm: {
                pageNo: 1,
                pageSize: 20,
                startTime: null,
                endTime: null,
            },
            detailDate: [],
            pickerOptionsA: {
                disabledDate: (time) => {
                    var lasttime = Date.parse('2022-12-30');
                    return (
                        time.getTime() <= lasttime ||
                        time.getTime() > Date.now()
                    );
                },
            },
            detailData: {
                orderSuccessNum: '',
                discountPrice: '',
                total: '',
                customerAvgPrice: '',
                relatedRate: '',
            },
            detailTable: [],
            detailTotal: 0,
            detailLoading: false,
            onlyView: false,
        };
    },
    mounted() {
        this.queryData();
    },
    methods: {
        getToday() {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }
            let result = year + '-' + month + '-' + day;
            this.detailForm.startTime = result;
            this.detailForm.endTime = result;
            this.detailDate = [result, result];
        },

        bridge_getStaticTotal() {
            this.getToday();
            this.getStaticTotal();
        },
        getStaticTotal() {
            this.detailForm.startTime = this.detailDate?.[0] || '';
            this.detailForm.endTime = this.detailDate?.[1] || '';

            this.$http
                .get('/campaign/sale/getSaleStatistics', {
                    params: this.detailForm,
                })
                .then((r) => {
                    if (r.data.code === 200) {
                        let item = r.data.data;
                        this.detailData = item;
                        this.dialogDetail = true;
                        this.getDetail();
                    }
                })
                .finally(() => {
                    this.detailLoading = false;
                });
        },

        async getDetail() {
            this.dialogDetail = true;
            this.detailForm.startTime = this.detailDate?.[0] || '';
            this.detailForm.endTime = this.detailDate?.[1] || '';
            this.detailLoading = true;
            await this.$http
                .get('/campaign/sale/getSaleOrderPage', {
                    params: this.detailForm,
                })
                .then((r) => {
                    if (r.data.code === 200) {
                        this.detailTable = r.data.data;
                        this.detailTotal = r.data.count;
                    }
                })
                .finally(() => {
                    this.detailLoading = false;
                });
        },

        resetDialogData() {
            Object.assign(
                this.detailForm,
                this.$options.data.call(this).detailForm
            );
            Object.assign(
                this.detailData,
                this.$options.data.call(this).detailData
            );
        },

        searchDetail() {
            this.detailForm.pageNo = 1;
            this.getStaticTotal();
        },
        resetDetail() {
            this.detailForm.pageNo = 1;
            // this.detailDate = [];
            // this.detailForm.startTime = null;
            // this.detailForm.endTime = null;
            this.getToday();
            this.searchDetail();
        },

        // 选择商品方法
        openSelectGoods() {
            this.$refs.selectGoods.caller = 'notset';
            this.$refs.selectGoods.init();
            this.dialogSelectGoods = true;
        },
        closeSelectGoods() {
            this.dialogSelectGoods = false;
        },
        saveSelectGoods(items, caller) {
            // console.log(items)
            let hasZero = false;

            let arr = [];
            for (let i = 0; i < items.length; i++) {
                let s = items[i].compareAtPrice.split('~');
                if (s[1]) {
                    for (let k = 0; k < items[i].goodsItems.length; k++) {
                        let good = items[i].goodsItems[k];
                        if (good.compareAtPrice === 0) {
                            // console.log(k)
                            items[i].goodsItems[k] = null;
                            hasZero = true;
                        }
                    }
                    items[i].goodsItems = items[i].goodsItems.filter(Boolean);
                    if (items[i].goodsItems.length > 0) {
                        arr.push(items[i]);
                    }
                } else if (s[0] !== '0.00') {
                    arr.push(items[i]);
                } else if (s[0] == '0.00') {
                    hasZero = true;
                }
            }
            // console.log(arr)

            if (hasZero) {
                this.$message.warning('已剔除部分0元商品。');
            }

            switch (caller) {
                default:
                    this.selectedGoodsTable.data = arr;
                    this.selectedGoodsTable.total =
                        this.selectedGoodsTable.data.length;
                    break;
            }
        },
        // 删除选中的商品（删除）
        deleteSelectedGoods(item) {
            if (item.id) {
                let index = this.selectedGoodsTable.data.indexOf(item);
                if (index > -1) {
                    this.selectedGoodsTable.data.splice(index, 1);
                    this.selectedGoodsTable.total =
                        this.selectedGoodsTable.data.length;
                }
            } else {
                let id = item.goodsId;
                let code = item.code;
                let father;
                for (let i = 0; i < this.selectedGoodsTable.data.length; i++) {
                    let item = this.selectedGoodsTable.data[i];
                    if (item.id == id) {
                        father = item;
                        // console.log(father)
                        for (let k = 0; k < father.goodsItems.length; k++) {
                            let good = father.goodsItems[k];
                            if (good.code == code) {
                                // console.log(k)
                                father.goodsItems.splice(k, 1);
                                break;
                            }
                        }
                        if (father.goodsItems.length == 0) {
                            this.selectedGoodsTable.data.splice(i, 1);
                        }
                    }
                }
            }
        },
        querySearch() {
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        // 获取列表数据
        queryData() {
            if (this.campaignStatus == '0')
                this.queryForm.campaignStatus = null;
            else this.queryForm.campaignStatus = this.campaignStatus;

            this.loading = true;
            this.$http
                .get('/campaign/sale/getPage', { params: this.queryForm })
                .then((r) => {
                    this.loading = false;
                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        this.total = r.data.count;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    this.loading = false;
                });
        },
        resetCondition() {
            this.queryForm.campaignName = null;
            this.queryForm.campaignStatus = null;
            this.queryForm.discountType = null;
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        openAdd() {
            this.dialogAddForm = {
                campaignName: null,
                discountType: null,
                startTime: null,
                endTime: null,
                saleDiscounts: [],
            };
            this.selectedGoods = [];
            this.selectedGoodsTable.data = []; // 选中的商品
            this.selectedGoodsTable.total = 0;
            this.isAdding = true;
            this.addLoading = false;
            this.dialogAdd = true;
            this.onlyView = false;
            this.$nextTick(() => {
                this.$refs['dialogAddForm'].clearValidate();
            });
        },
        openEdit(id, onlyView = false) {
            this.loading = true;
            this.onlyView = onlyView;

            this.$http
                .get('/campaign/sale/getDetail?id=' + id)
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data;
                        this.dialogAddForm = {
                            id: id,
                            campaignName: data.campaignName,
                            discountType: data.discountType,
                            startTime: data.startTime,
                            endTime: data.endTime,
                            saleDiscounts: data.goodsList.map(
                                (item) => item.id
                            ),
                        };
                        this.selectedGoods = JSON.parse(
                            JSON.stringify(data.goodsList)
                        );
                        // console.log(this.dialogAddForm)
                        this.multipleSelection = data.goodsList.map(
                            (item) => item.id
                        );
                        // console.log(this.multipleSelection)
                        this.selectedGoodsTable.data = JSON.parse(
                            JSON.stringify(data.goodsList)
                        ); // 选中的商品
                        this.selectedGoodsTable.total = data.goodsList.length;
                        for (
                            let i = 0;
                            i < this.selectedGoodsTable.total;
                            i++
                        ) {
                            let item = this.selectedGoodsTable.data[i];
                            item.su = item.spu;
                            item.maxPrice = '0.00';
                            item.minPrice = '0.00';

                            let rowMin = 99999999;
                            let rowMax = 0;

                            for (let k = 0; k < item.goodsItems.length; k++) {
                                let good = item.goodsItems[k];
                                good.su = good.sku;
                                // if(item.single == 1){
                                // 	good.su = item.spu
                                // } else {
                                // 	good.su = good.sku
                                // }

                                if (data.discountType == 1) {
                                    let disPrice = parseFloat(
                                        (good.compareAtPrice *
                                            good.discountNum) /
                                            100
                                    ).toFixed(2);
                                    // console.log(disPrice)
                                    let finalPrice = parseFloat(
                                        good.compareAtPrice - disPrice
                                    ).toFixed(2);

                                    if (finalPrice > rowMax) {
                                        rowMax = finalPrice;
                                        item.maxPrice = rowMax;
                                    }
                                    if (finalPrice < rowMin) {
                                        rowMin = finalPrice;
                                        item.minPrice = rowMin;
                                    }
                                }

                                if (data.discountType == 2) {
                                    let disPrice = parseFloat(
                                        good.compareAtPrice - good.discountNum
                                    ).toFixed(2);
                                    let finalPrice =
                                        parseFloat(disPrice).toFixed(2);
                                    // console.log(disPrice, finalPrice)
                                    if (disPrice > rowMax) {
                                        rowMax = finalPrice;
                                        item.maxPrice = rowMax;
                                    }
                                    if (disPrice < rowMin) {
                                        rowMin = finalPrice;
                                        item.minPrice = rowMin;
                                    }
                                    // console.log(rowMax, rowMin)
                                }
                            }
                        }

                        this.isAdding = false;
                        this.addLoading = false;
                        this.dialogAdd = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addData() {
            let content = this.isAdding == true ? '新增' : '编辑';
            this.$refs.dialogAddForm.validate((valid) => {
                if (valid) {
                    this.$confirm(`是否${content}秒杀活动？`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.addLoading = true;
                        let url = this.dialogAddForm.id
                            ? '/campaign/sale/edit'
                            : '/campaign/sale/add';
                        this.dialogAddForm.saleDiscounts = [];
                        for (
                            let i = 0;
                            i < this.selectedGoodsTable.data.length;
                            i++
                        ) {
                            let item = this.selectedGoodsTable.data[i];
                            for (let k = 0; k < item.goodsItems.length; k++) {
                                let good = item.goodsItems[k];
                                let unit;
                                switch (this.dialogAddForm.discountType) {
                                    case 1:
                                        unit = '%off';
                                        break;
                                    case 2:
                                        unit = 'USD';
                                        break;
                                    case 3:
                                        unit = 'USD';
                                        break;
                                }
                                let json = {
                                    goodsId: item.id,
                                    discountNum: Number(good.discountNum),
                                    discountUnit: unit,
                                    goodsItemCode: good.code,
                                };
                                this.dialogAddForm.saleDiscounts.push(json);
                            }
                        }
                        if (this.dialogAddForm.saleDiscounts.length == 0) {
                            this.$message.warning('请选择秒杀商品。');
                            this.addLoading = false;
                            return false;
                        }

                        if (
                            !this.dialogAddForm.startTime ||
                            !this.dialogAddForm.endTime
                        ) {
                            this.$message.warning('请选择时间范围。');
                            this.addLoading = false;
                            return false;
                        }

                        this.$http
                            .post(url, this.dialogAddForm)
                            .then((response) => {
                                if (response.data.code === 200) {
                                    this.$message.success(`${content}成功`);
                                    this.queryData();
                                    this.dialogAdd = false;
                                    this.$refs.dialogAddForm.resetFields();
                                }
                            })
                            .finally(() => {
                                this.addLoading = false;
                            });
                    });
                }
            });
        },
        endThis(id) {
            this.$confirm('是否结束活动？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .get('/campaign/sale/end?id=' + id)
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message.success('结束成功');
                            this.queryData();
                        }
                    });
            });
        },
        deleteThis(id) {
            this.$confirm('是否删除活动？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .delete('/campaign/sale/delete?id=' + id)
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message.success('删除成功');
                            this.queryData();
                        }
                    });
            });
        },
        resetListDiscount() {
            let len = this.selectedGoodsTable.data.length;
            for (let i = 0; i < len; i++) {
                let item = this.selectedGoodsTable.data[i];
                item.discountNum = '';
                item.maxPrice = '';
                item.minPrice = '';
                for (let k = 0; k < item.goodsItems.length; k++) {
                    let good = item.goodsItems[k];
                    good.discountNum = '';
                }
            }
        },
        changeListtNum(e, scope, price, source) {
            // console.log(e, parseFloat(e), Number(e).toString());
            let items = scope.row.goodsItems;
            if (
                parseFloat(e).toString() == 'NaN' ||
                Number(e).toString() == 'NaN'
            ) {
                this.$message.warning('请输入数字。');
                scope.row.discountNum = '';
                scope.row.maxPrice = '';
                scope.row.minPrice = '';
                for (let i = 0; i < items.length; i++) {
                    items[i].discountNum = '';
                }
                return false;
            }

            if (
                e.indexOf('.') > -1 &&
                e.substring(e.indexOf('.'), e.length).length > 3
            ) {
                this.$message.warning('小数最多只能有两位。');
                scope.row.discountNum = '';
                scope.row.maxPrice = '';
                scope.row.minPrice = '';
                for (let i = 0; i < items.length; i++) {
                    items[i].discountNum = '';
                }
                return false;
            }

            let minPrice = 99999999;
            for (let i = 0; i < items.length; i++) {
                if (items[i].compareAtPrice < minPrice) {
                    minPrice = items[i].compareAtPrice;
                }
            }

            let maxPrice = 0;
            for (let i = 0; i < items.length; i++) {
                if (items[i].compareAtPrice > maxPrice) {
                    maxPrice = items[i].compareAtPrice;
                }
            }

            let rowMin = 99999999;
            let rowMax = 0;

            if (this.dialogAddForm.discountType == 1) {
                if (parseInt(e) > 99 || parseInt(e) < 1) {
                    this.$message.warning('折扣范围为1-99。');
                    scope.row.discountNum = '';
                    scope.row.maxPrice = '';
                    scope.row.minPrice = '';
                    for (let i = 0; i < items.length; i++) {
                        items[i].discountNum = '';
                    }
                    return false;
                }
                for (let i = 0; i < items.length; i++) {
                    let disPrice = parseFloat(
                        (items[i].compareAtPrice * e) / 100
                    ).toFixed(2);
                    // console.log(disPrice, maxPrice, minPrice)
                    if (disPrice > rowMax) {
                        rowMax = disPrice;
                    }
                    if (disPrice < rowMin) {
                        rowMin = disPrice;
                    }
                    scope.row.maxPrice = parseFloat(
                        maxPrice - (maxPrice * e) / 100
                    ).toFixed(2);
                    scope.row.minPrice = parseFloat(minPrice - rowMin).toFixed(
                        2
                    );
                }
            }

            if (this.dialogAddForm.discountType == 2) {
                if (e >= minPrice) {
                    this.$message.warning('不能大于等于最小原价。');
                    scope.row.discountNum = '';
                    scope.row.maxPrice = '';
                    scope.row.minPrice = '';
                    for (let i = 0; i < items.length; i++) {
                        items[i].discountNum = '';
                    }
                    return false;
                }
                for (let i = 0; i < items.length; i++) {
                    let disPrice = items[i].compareAtPrice - e;
                    if (disPrice > rowMax) {
                        rowMax = disPrice;
                    }
                    if (disPrice < rowMin) {
                        rowMin = disPrice;
                    }
                    // console.log(disPrice, maxPrice, minPrice, rowMax, rowMin)
                    scope.row.maxPrice = parseFloat(rowMax).toFixed(2);
                    scope.row.minPrice = parseFloat(rowMin).toFixed(2);
                }
            }

            if (this.dialogAddForm.discountType == 3) {
                if (e >= minPrice) {
                    this.$message.warning('折后价不能大于等于原价。');
                    scope.row.discountNum = '';
                    for (let i = 0; i < items.length; i++) {
                        items[i].discountNum = '';
                    }
                    return false;
                }
            }

            for (let i = 0; i < items.length; i++) {
                items[i].discountNum = e;
            }
        },
        changeDiscountNum(e, scope, price, source) {
            // console.log(scope)
            source = String(source);
            if (
                parseFloat(e).toString() == 'NaN' ||
                Number(e).toString() == 'NaN'
            ) {
                this.$message.warning('请输入数字。');
                scope.row.discountNum = '';
                scope.row.maxPrice = '';
                scope.row.minPrice = '';
                return false;
            }

            if (
                e.indexOf('.') > -1 &&
                e.substring(e.indexOf('.'), e.length).length > 3
            ) {
                this.$message.warning('小数最多只能有两位。');
                scope.row.discountNum = '';
                scope.row.maxPrice = '';
                scope.row.minPrice = '';
                return false;
            }

            let id = scope.row.goodsId;
            let father;
            for (let i = 0; i < this.selectedGoodsTable.data.length; i++) {
                let item = this.selectedGoodsTable.data[i];
                if (item.id == id) {
                    father = item;
                    // console.log(father)
                    father.discountNum = '';
                    break;
                }
            }

            let items = father.goodsItems;

            let rowMin = 99999999;
            let rowMax = 0;

            if (this.dialogAddForm.discountType == 1) {
                if (parseInt(e) > 99 || parseInt(e) < 1) {
                    this.$message.warning('折扣范围为1-99。');
                    scope.row.discountNum = '';
                    scope.row.maxPrice = '';
                    scope.row.minPrice = '';
                    return false;
                }
                for (let i = 0; i < items.length; i++) {
                    let disPrice = parseFloat(
                        (items[i].compareAtPrice * items[i].discountNum) / 100
                    ).toFixed(2);
                    console.log(disPrice);
                    let finalPrice = parseFloat(
                        items[i].compareAtPrice - disPrice
                    ).toFixed(2);
                    if (finalPrice > rowMax) {
                        rowMax = finalPrice;
                        father.maxPrice = finalPrice;
                    }
                    if (finalPrice < rowMin) {
                        rowMin = finalPrice;
                        father.minPrice = finalPrice;
                    }
                }
            }
            if (this.dialogAddForm.discountType == 2) {
                let s = source.split('~');
                // console.log(s[0], s[1]);
                if (s[1]) {
                    if (e >= parseFloat(s[0])) {
                        this.$message.warning('折扣价不能大于等于最小原价。');
                        scope.row.discountNum = '';
                        scope.row.maxPrice = '';
                        scope.row.minPrice = '';
                        return false;
                    }
                } else {
                    if (e >= parseFloat(s[0])) {
                        this.$message.warning('折扣价不能大于等于原价。');
                        scope.row.discountNum = '';
                        scope.row.maxPrice = '';
                        scope.row.minPrice = '';
                        return false;
                    }
                }
                for (let i = 0; i < items.length; i++) {
                    let disPrice =
                        items[i].compareAtPrice - items[i].discountNum;
                    // console.log(disPrice)
                    let finalPrice = parseFloat(disPrice).toFixed(2);
                    if (finalPrice > rowMax) {
                        rowMax = finalPrice;
                        father.maxPrice = finalPrice;
                    }
                    if (finalPrice < rowMin) {
                        rowMin = finalPrice;
                        father.minPrice = finalPrice;
                    }
                }
            }
            if (this.dialogAddForm.discountType == 3) {
                let s = source.split('~');
                // console.log(s[0], s[1]);
                if (s[1]) {
                    if (e >= parseFloat(s[0])) {
                        this.$message.warning('折后价不能大于等于最小原价。');
                        scope.row.discountNum = '';
                    }
                } else {
                    if (e >= parseFloat(s[0])) {
                        this.$message.warning('折后价不能大于等于原价。');
                        scope.row.discountNum = '';
                    }
                }
            }
        },
        getDiscount(price, source, discount) {
            let tempnum = parseFloat(source);
            if (discount) {
                source = String(source);
                let s = source.split('~');
                if (s[1]) {
                    if (this.dialogAddForm.discountType == 1) {
                        return (
                            parseFloat((s[0] * (100 - discount)) / 100).toFixed(
                                2
                            ) +
                            '~' +
                            parseFloat((s[1] * (100 - discount)) / 100).toFixed(
                                2
                            )
                        );
                    }
                    if (this.dialogAddForm.discountType == 2) {
                        return (
                            parseFloat(s[0] - discount).toFixed(2) +
                            '~' +
                            parseFloat(s[1] - discount).toFixed(2)
                        );
                    }
                } else {
                    if (this.dialogAddForm.discountType == 1) {
                        return parseFloat(
                            (tempnum * (100 - discount)) / 100
                        ).toFixed(2);
                    }
                    if (this.dialogAddForm.discountType == 2) {
                        return parseFloat(tempnum - discount).toFixed(2);
                    }
                }
            } else {
                return '-';
            }
        },
    },
};
</script>

<style scoped>
.addCampaignCodeSection {
    margin-bottom: 16px;
    padding: 16px 32px;
    background-color: #fff;
}

.addCampaignCodeLegend {
    font-size: 20px;
    font-weight: bold;
}

.addCampaignFullSection {
    margin-bottom: 16px;
    padding: 16px 32px;
    background-color: #fff;
}

.addCampaignFullLegend {
    font-size: 20px;
    font-weight: bold;
}

/* 适用范围卡片 */
.addCampaignFullCard {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    box-sizing: border-box;
    border: solid 1px #ecedf3;
    border-radius: 6px;
    width: 200px;
    height: 100px;
    cursor: pointer;
    overflow: hidden;
}

.addCampaignFullCard > h4 {
    margin: 5px 16px;
}

.addCampaignFullCard > p {
    margin: 0 16px;
    color: #999;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
}

.addCampaignFullSelectedCard {
    border: solid 2px #2d67f3;
    background: #f3f7ff;
}

.addCampaignFullCardChecked {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 50px;
    height: 50px;
    background-color: #2d67f3;
    transform: rotate(45deg);
}

.addCampaignFullCardChecked > i {
    position: absolute;
    top: 31px;
    left: 16px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    transform: rotate(-45deg);
}

/* Banner图片 */
.BannerImageBox {
    margin-right: 20px;
    margin-bottom: 20px;
    width: 320px !important;
    height: 176px !important;
    background-color: #f5f6f8;
}

.BannerImage {
    max-width: 320px;
    max-height: 176px;
}

.BannerImageBox > .mask_d5 {
    display: none;
}

.BannerImageBox:hover > .mask_d5 {
    display: block;
}

/* 方案设置项 */
.DemoBox {
    display: flex;
    align-items: center;
    margin: 16px 0;
    padding: 0 24px;
    height: 80px;
}

.DemoBox > p {
    font-size: 18px;
}

.DemoBox_Time {
    border-radius: 6px;
    padding: 4px 10px;
    font-size: 20px;
}

.DemoColorRow {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.DemoColorTitle {
    width: 300px;
}
</style>
  <style>
#addCampaignFullDialog > .el-dialog,
#editCampaignFullDialog > .el-dialog {
    background: #ecedf3;
}
</style>