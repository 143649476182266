<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>营销管理</el-breadcrumb-item>
            <el-breadcrumb-item>落地页</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="date" value-format="yyyy-MM-dd" :default-value="new Date()">
                    ></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-input v-model="queryForm.pageName" placeholder="请输入落地页" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="queryForm.spu" placeholder="请输入spu" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="queryForm.id" placeholder="请输入ID" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryForm.targetWebsite" placeholder="目标网站" clearable>
                    <el-option v-for="(item, index) in targetWebsiteOptions" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryForm.userId" placeholder="用户名称" clearable>
                    <el-option v-for="(item, index) in userData" :label="item.realName ? item.userName + '(' + item.realName + ')' : item.userName " :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData({isClearSort:1})" :loading="loading">查询</el-button>
                <el-button type="success" icon="el-icon-plus" @click="queryAdd">新增</el-button>
                <el-button type="primary" icon="el-icon-s-custom" @click="bridgeChangeUser">变更用户</el-button>
            </el-form-item>
        </el-form>
        <!-- 数据列表 -->
        <!--  :default-sort="{prop: 'orderSuccessNum', order: 'descending'}" -->
        <el-table border stripe :data="tableData" show-summary :summary-method="getTotal" ref="userTable" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" @sort-change="sortTable" :default-sort="{prop: 'shopStatisticAdPage.visitUserNum', order: 'descending'}" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column label="落地页" prop="pageName" min-width="120"></el-table-column>
            <el-table-column label="排序规则" min-width="280">
                <template #default="{row}">
                    <p class="hidetext"  :title="row.ruleJson && row.ruleJson.spu ? row.ruleJson.spu : '' ">spu:
                        <!-- <span v-if="scope.row.ruleJson.spu.length > 0" :title="scope.row.ruleJson.spu.join(',')">{{ scope.row.ruleJson.spu.join(',') }}</span> -->
                        <span v-if="row.ruleJson && row.ruleJson.spu != ''">{{ row.ruleJson.spu }}</span>
                        <span v-else>无</span>
                    </p>
                    <p>规则：
                        <span v-if="row.ruleJson && row.ruleJson.rule">{{ getRule(row.ruleJson.rule) }}</span>
                        <span v-else>无</span>
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="目标网站" width="160">
                <template slot-scope="scope">
                    {{ getTargetWebsite(scope.row.targetWebsite) }}
                </template>
            </el-table-column>
            <el-table-column label="分类" prop="category.categoryNameCn" min-width="120">
            </el-table-column>
            <el-table-column label="用户名称" prop="userName" min-width="160">
            </el-table-column>
            <!-- <el-table-column label="适用人群" prop="fitPerson" min-width="120">
                <template slot-scope="scope">
                    {{ getFItPerson(scope.row.fitPerson) }}
                </template>
            </el-table-column> -->
            <!-- <el-table-column label="天数" prop="days" min-width="120"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160" sortable="custom"></el-table-column>
            <el-table-column prop="updateTime" label="更新时间" width="160" sortable="custom"></el-table-column> -->

            <el-table-column prop="shopStatisticAdPage.visitNum" label="页面访问量" width="160" sortable="custom"></el-table-column>
            <el-table-column prop="shopStatisticAdPage.visitUserNum" label="访问用户数" width="160" sortable="custom"></el-table-column>
            <el-table-column prop="shopStatisticAdPage.outNum" label="跳出数" width="160" sortable="custom"></el-table-column>
            <el-table-column prop="shopStatisticAdPage.outRate" label="跳出率" width="160" sortable="custom">
                <template slot-scope="scope">
                    {{scope.row.shopStatisticAdPage.outRate + '%'}}
                </template>
            </el-table-column>
            <el-table-column prop="orderSuccessNum" label="下单成功数" width="160" sortable="custom">
                <template #default="{row}">
                    <p> {{ row.shopStatisticAdPage.orderSuccessNum }}</p>
                    <el-link type="primary" @click="intoOrder(row)" v-if="row.shopStatisticAdPage.orderSuccessNum">查看</el-link>
                </template>
            </el-table-column>
            <el-table-column prop="shopStatisticAdPage.saleNum" label="销量" width="160" sortable="custom"></el-table-column>
            <el-table-column prop="shopStatisticAdPage.saleTotal" label="商品净销售额" width="160" sortable="custom">
				<template slot="header" slot-scope="scope">
					商品净销售额
					<el-tooltip class="item" effect="dark" content="商品净销售额=商品销售额-均摊折扣" placement="top">
					    <i class="el-icon-question"></i>
					</el-tooltip>
			    </template>
			</el-table-column>
            <el-table-column prop="shopStatisticAdPage.orderSaleTotal" label="订单销售总额" width="160" sortable="custom"></el-table-column>
            <el-table-column prop="shopStatisticAdPage.conversionRate" label="转化率" width="160" sortable="custom">
                <template slot-scope="scope">
                    {{scope.row.shopStatisticAdPage.conversionRate + '%'}}
                </template>
            </el-table-column>
            <el-table-column prop="shopStatisticAdPage.buyUserNum" label="加购用户数" width="160" sortable="custom"></el-table-column>
            <el-table-column prop="shopStatisticAdPage.buyPurchaseRate" label="加购率" width="160" sortable="custom">
                <template slot-scope="scope">
                    {{scope.row.shopStatisticAdPage.buyPurchaseRate + '%'}}
                </template>
            </el-table-column>

            <el-table-column fixed="right" label="操作" width="250" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-discover" @click="alinmItem(scope.row)" v-show="!scope.row.userId">认领</el-button>
                    <el-button size="mini" type="text" icon="el-icon-view" @click="openView(scope.row.viewUrl)">预览</el-button>
                    <el-button size="mini" type="text" icon="el-icon-edit" v-if="$auth($authData.campaign.adedit)" @click="initEditForm(scope.row.id)">编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" v-if="$auth($authData.campaign.addelete)" @click="deleteData(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <!-- 新增 -->
        <el-dialog :title="isEdit ? '编辑' : '新增'" :visible.sync="dialogAdd" :close-on-click-modal="false" top="3%" width="800px" @closed="resetDialogInfo">
            <el-form :model="dialogAddForm" :rules="addRules" status-icon ref="dialogAddForm" label-width="100px">
                <el-form-item label="落地页名称" prop="pageName">
                    <el-input placeholder="请输入落地页名称" v-model="dialogAddForm.pageName" clearable></el-input>
                </el-form-item>

                <el-form-item label="选择像素" prop="pixel">
                    <el-select v-model="dialogAddForm.pixelId" clearable filterable placeholder="请选择像素">
                        <el-option v-for="(item,index) in pixelTypes" :key="index" :label="(item.pixelName ? item.pixelName : '') + '(' + item.pixel + ')'" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="置顶SPU" v-show="dialogAddForm.type < 3">
                    <el-input type="textarea" :rows="6" placeholder="请输入spu，多个用逗号隔开" v-model="dialogAddForm.ruleJson.spu">
                    </el-input>
                </el-form-item>

                <el-form-item label="选择页面" prop="type">
                    <el-select v-model="dialogAddForm.type" clearable filterable placeholder="请选择页面">
                        <el-option v-for="(item,index) in pageTypes" :key="index" :label="item.name" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="选择商品" v-show="dialogAddForm.type == 4">
                    <el-button type="primary" icon="el-icon-plus" @click="openSelectGoods">选择商品
                    </el-button>
                </el-form-item>

                <el-form-item label="选择分类" class="type-require input-cascader" v-if="dialogAddForm.type == 1" prop="categoryId" key="categoryId">
                    <el-cascader @change="testChange" v-model="dialogAddForm.categoryId" :options="classifyData" :props="{
                        value: 'id',
                        label: 'categoryNameCn',
                        children: 'categoryItemList',
                        checkStrictly: true,
                        leaf: 'isNodes',
                        }" clearable placeholder="商品分类"></el-cascader>
                </el-form-item>

                <el-form-item label="选择专辑" prop="albumId" v-if="dialogAddForm.type == 2" key="albumId">
                    <el-select v-model="dialogAddForm.albumId" clearable filterable placeholder="请选择专辑">
                        <el-option v-for="(item,index) in albumOptions" :key="index" :label="item.collectionName" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="排序规则" v-if="dialogAddForm.type < 3" prop="rule" key="rule">
                    <el-select v-model="dialogAddForm.ruleJson.rule" clearable filterable placeholder="请选择排序规则">
                        <el-option v-for="(item,index) in ruleOptions" :key="index" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- <el-form-item label="类型" prop="type">
                    <el-select v-model="dialogAddForm.type" clearable filterable placeholder="请选择类型">
                        <el-option v-for="(item,index) in typeArrs" :key="index" :label="item.name" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->

                <el-form-item label="目标网站" prop="targetWebsite">
                    <el-select v-model="dialogAddForm.targetWebsite" clearable filterable placeholder="请选择目标网站">
                        <el-option v-for="(item,index) in targetWebsiteOptions" :key="index" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="适用人群" prop="fitPerson">
                    <el-select v-model="dialogAddForm.fitPerson" clearable filterable placeholder="请选择适用人群">
                        <el-option v-for="(item,index) in fitPersonOptions" :key="index" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="天数" prop="days" v-if="dialogAddForm.type < 3" key="days">
                    <template>
                        <el-input-number v-model="dialogAddForm.days" @change="handleChange" :min="1" :max="60" label="天数"></el-input-number>
                    </template>
                    <!-- <el-input-number v-model="dialogAddForm.days" @change="handleChange" :min="0" :max="60" label="天数"></el-input-number> -->
                </el-form-item>
                <!-- <el-form-item label="是否默认" prop="isDefault">
                <el-radio-group  v-model="dialogAddForm.isDefault" >
                    <el-radio label="0" v-model="dialogAddForm.isDefault" >否</el-radio>
                    <el-radio label="1" v-model="dialogAddForm.isDefault" >是</el-radio>
                </el-radio-group>
                
            </el-form-item> -->

                <el-form-item label="商品描述" v-show="dialogAddForm.type < 3" key="pageDesc">
                    <vue-tinymce v-model="dialogAddForm.pageDesc" :setup="setup" :setting="setting"></vue-tinymce>
                </el-form-item>

            </el-form>

            <div class="flex">
                <template v-if="selectedGoodsTable.data.length > 0">
                    <vuedraggable class="wrapper" v-model="selectedGoodsTable.data">
                        <div class="itembox" v-for="(item,indexB) in selectedGoodsTable.data" :key="indexB">
                            <div class="imgbox">
                                <GoodsImageTemplate :images="item.goodsImages" width="180" height="180" :hasHover="false"></GoodsImageTemplate>
                            </div>
                            <p class="tit">{{ item.goodsTitle }}</p>
                            <p class="tit">售价:{{ item.price }} / 原价:{{ item.compareAtPrice }}</p>
                            <p>库存: {{ item.inventoryQuantity }}</p>
                            <p class="between">
                                <!-- <span class="w50"><i class="el-icon-top" @click="toppingGoods(item)"></i></span> -->
                                <span class="w50" @click="deleteSelectedGoods(item)"><i class="el-icon-delete"></i></span>
                            </p>
                            <span class="published publishedA" v-if="item.published == 0">{{ getPublishedB(item.published) }}</span>
                            <span class="published publishedB" v-if="item.published == 1">{{ getPublishedB(item.published) }}</span>
                        </div>
                    </vuedraggable>
                </template>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                <el-button @click="dialogAdd = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 链接 -->
        <el-dialog title="生成链接" :visible.sync="dialogLink" :close-on-click-modal="false" top="3%" width="500px">
            <el-form :model="dialogLinkForm" :rules="dialogLinkRules" status-icon ref="dialogLinkForm">
                <div id="code">{{code}}</div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="linkLoading" id="copy_text" ref="copy" data-clipboard-action="copy" :data-clipboard-text="code" @click="copy">复制</el-button>
                <el-button @click="dialogLink = false">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="订单信息" :visible.sync="visibleDialogOrder" :close-on-click-modal="false" top="3%" width="1200px" @closed="resetDialogOrder">
            <!-- <el-table border stripe :data="orderData" ref="userTable" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000">
                <el-table-column label="ID" prop="id"></el-table-column>
            </el-table> -->

            <el-table ref="orderListTable" border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" max-height="1000" :data="orderData" v-loading="loading">
                <el-table-column fixed label="订单号" min-width="200">
                    <template slot-scope="scope">
                        {{ scope.row.orderNo }}
                        <i class="el-icon-document-copy bold_i cp" title="复制" style="color: #000" v-clipboard:copy="scope.row.orderNo" v-clipboard:success="onCopySuccess" v-clipboard:error="onCopyError">
                        </i>
                    </template>
                </el-table-column>
                <el-table-column label="商品信息" width="200">
                    <template slot-scope="scope">
                        <div class="infobox">
                            <div v-if="scope.row.orderItems && scope.row.orderItems.length">{{ scope.row.orderItems[0].goodsTitle }} ... </div>
                            <!-- <el-button icon="el-icon-s-order" type="info" size="mini" round @click="lookDetail(scope.row.orderItems)">查看详细</el-button> -->
                            <el-popover placement="right" width="500" trigger="click">
                                <div class="infoitem">
                                    <div class="inforow" v-for="(item,index) in scope.row.orderItems" :key="index">
                                        <div class="imgbox">
                                            <GoodsImageTemplate :images="item.goodsImage" :isOrderItems="true" :orderMainImage="item.goodsImages"></GoodsImageTemplate>
                                        </div>
                                        <div class="infos">
                                            <p class="tit">{{item.goodsTitle}}</p>
                                            <p class="p">style: {{ getGoodsOption(item.goodsOption) }}</p>
                                            <p class="p">sku: {{item.sku}}</p>
                                            <p class="p">spu: {{item.spu}}</p>
                                            <p class="p">状态: {{ getFulfillmentStatus(item.fulfillmentStatus) }}</p>
                                            <p class="p">DIY状态：{{ item.isDiy ? '是' : '否' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <el-button slot="reference" type="primary" plain size="mini">查看</el-button>
                            </el-popover>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="160"></el-table-column>
                <el-table-column label="收货人" min-width="120">
                    <template slot-scope="scope">
                        {{ scope.row.shippingFirstName }} {{ scope.row.shippingLastName }}
                    </template>
                </el-table-column>
                <el-table-column label="支付状态" min-width="120">
                    <template slot-scope="scope">
                        <span>
                            {{
              financialStatusTypes.find(
                (v) => v.value == scope.row.financialStatus
              ).name
            }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="订单总价" min-width="120">
                    <template slot-scope="scope">
                        {{ $yrFormatNumber(scope.row.totalPrice, ",", 2) }}USD
                    </template>
                </el-table-column>
                <el-table-column prop="shippingCountryEn" label="国家/地区" min-width="120"></el-table-column>
                <el-table-column prop="orderSource" label="订单来源" min-width="200">
                    <template slot-scope="scope">
                        <el-tooltip :content="scope.row.orderSource" placement="top">
                            <div class="hidelink">{{ scope.row.orderSource }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="channelSource" label="渠道来源" min-width="120">
                    <template slot-scope="scope">
                        {{
                        channelSourceData.find(
                          (v) => v.value == scope.row.channelSource
                        ).name
                      }}
                    </template>
                </el-table-column>
                <el-table-column prop="adPageId" label="落地页ID" min-width="120"></el-table-column>
                <el-table-column prop="discountCode" label="优惠码" min-width="120"></el-table-column>
                <el-table-column label="物流状态" min-width="120">
                    <template slot-scope="scope">
                        {{
            fulfillmentStatusTypes.find(
              (v) => v.value == scope.row.fulfillmentStatus
            ).name
          }}
                    </template>
                </el-table-column>
                <!-- @click="getOrderDetail(scope.row, true, scope.$index)" -->
                <el-table-column label="操作" fixed="right" width="140" header-align="center" align="center">
                    <template #default="{row}">
                        <el-button type="text" size="mini" icon="el-icon-document" @click="bridgeOrderInfo(row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="orderForm.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size.sync="orderForm.pageSize" :total="orderTotal" @size-change="queryOrderData" @current-change="queryOrderData">
            </el-pagination>

        </el-dialog>

        <el-dialog title="变更用户" :visible.sync="visibleDialogUser" :close-on-click-modal="false" top="3%" width="600px" @closed="resetDialogUser">
            <el-form :model="userForm" status-icon ref="userForm">
                <el-form-item label="请选择变更后的用户名称，变更后，落地页将属于ta">
                    <el-select v-model="userForm.userId" clearable filterable placeholder="请选择页面">
                        <el-option v-for="(item,index) in userList" :key="index" :label="item.userName + (item.realName ? '（' +  item.realName + '）' : '') " :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitChangeUser" :loading="loading == 'user' ">确定</el-button>
                <el-button @click="visibleDialogUser = false">取消</el-button>
            </div>

        </el-dialog>

        <OrderInfo ref="orderInfo"></OrderInfo>
        <!-- 选择商品 -->
        <SelectGoods ref="selectGoods" :showDialog="dialogSelectGoods" :isRaio="true" :containItem="false" :selectedGoods="selectedGoodsTable.data" @closeDialog="closeSelectGoods" @saveSelectedGoods="saveSelectGoods">
        </SelectGoods>

        <ImageUploader :insertImage="insertImage" :insertType="insertType" @getOpenState="getOpenState" @getImage="getImage" ref="uploadImage">
        </ImageUploader>

        <!-- 视频 -->
        <VideoUploader ref="uploadVideo" :insertVideo="dialogInsertVideo" :insertVideoType="insertVideoType" :activeVideo="activeVideo" @getOpenVideo="getOpenVideo" @getVideo="getVideo">
        </VideoUploader>

    </div>
</template>
<script>
import SelectGoods from '@/components/SelectGoods';
import OrderInfo from '../order/orderInfo.vue';
import vuedraggable from 'vuedraggable';
import ImageUploader from '@/components/ImageUploader';
import VideoUploader from '@/components/VideoUploader';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
export default {
    name: 'AdPage',
    components: {
        vuedraggable,
        SelectGoods: SelectGoods,
        OrderInfo,
        ImageUploader,
        VideoUploader,
        GoodsImageTemplate,
    },
    data() {
        return {
            isEdit: false,
            date: null,
            queryForm: {
                startDate: null,
                endDate: null,
                pageName: null,
                spu: null,
                id: null,
                pageNo: 1,
                pageSize: 20,
                sort: '',
                sortType: '',
                targetWebsite: null,
                userId: null,
            },
            orderForm: {
                pageNo: 1,
                pageSize: 20,
            },
            orderId: null,
            orderTotal: 0,
            total: 0,
            tableData: [],
            sortKeyName: [
                ,
                'visitNum',
                'visitUserNum',
                'outNum',
                'outRate',
                'orderSuccessNum',
                'saleNum',
                'saleTotal',
                'orderSaleTotal',
                'conversionRate',
                'buyUserNum',
                'buyPurchaseRate',
            ],
            loading: false,
            dialogAdd: false,
            addLoading: false,
            visibleDialogOrder: false,
            addForm: {},
            // isDefault: 0,
            addRules: {
                type: [
                    {
                        required: true,
                        message: '请选择页面',
                        trigger: 'change',
                    },
                ],
                albumId: [
                    {
                        required: true,
                        message: '请选择专辑',
                        trigger: 'change',
                    },
                    {
                        required: true,
                        message: '请选择专辑',
                        trigger: 'blur',
                    },
                ],
                pageName: [
                    {
                        required: true,
                        message: '请输入落地页',
                        trigger: 'blur',
                    },
                ],
                categoryId: [
                    {
                        required: true,
                        message: '请选择商品分类',
                        trigger: 'change',
                    },
                    {
                        required: true,
                        message: '请选择商品分类',
                        trigger: 'blur',
                    },
                ],
                targetWebsite: [
                    {
                        required: true,
                        message: '请选择目标网站',
                        trigger: 'change',
                    },
                ],
                // fitPerson: [
                //     {
                //         required: true,
                //         message: '请选择适用人群',
                //         trigger: 'change',
                //     },
                // ],
                days: [
                    {
                        required: true,
                        message: '请输入天数',
                        trigger: 'change',
                    },
                ],
            },
            pixelTypes: [
                {
                    pixelName: 'facebook像素1',
                    id: 1,
                },
                {
                    pixelName: 'facebook像素2',
                    id: 2,
                },
            ],
            pageTypes: [
                {
                    name: '分类',
                    value: 1,
                },
                {
                    name: '专辑',
                    value: 2,
                },
                {
                    name: '首页',
                    value: 3,
                },
                {
                    name: '详情页',
                    value: 4,
                },
                {
                    name: '专题页',
                    value: 5,
                },
            ],
            typeArrs: [
                {
                    name: '分类',
                    value: 1,
                },
                {
                    name: '专辑',
                    value: 2,
                },
            ],
            spuOptions: [],
            albumOptions: [],

            ruleJson: [],
            dialogAddForm: {
                type: null,
                ruleJson: {
                    spu: '',
                    rule: null,
                },
                id: null,
                albumId: null,
                categoryId: null,
                days: null,
                targetWebsite: null,
                pageName: null,
                fitPerson: null,
                pageDesc: '',
                pixelId: null,
            },
            ruleOptions: [
                {
                    value: 'sale',
                    name: '销售',
                    id: 1,
                },
                {
                    value: 'visit',
                    name: '访问',
                    id: 2,
                },
                {
                    value: 'conversionRate',
                    name: '转换率',
                    id: 3,
                },
                {
                    value: 'newIn',
                    name: '新品',
                    id: 4,
                },
                {
                    value: 'Recommend',
                    name: '自定义',
                    id: 5,
                },
            ],
            typeOptions: [],
            days: 1,
            targetWebsiteOptions: [
                {
                    name: 'Google',
                    id: 1,
                },
                {
                    name: 'Facebook',
                    id: 2,
                },
                {
                    name: 'Instagram',
                    id: 3,
                },
                {
                    name: 'email',
                    id: 4,
                },
                {
                    name: 'TikTok',
                    id: 5,
                },
                {
                    name: 'Facebook&Ins',
                    id: 6,
                },
            ],
            fitPersonOptions: [
                {
                    name: '黑人',
                    id: 2,
                },
                {
                    name: '白人',
                    id: 3,
                },
            ],
            dialogLink: false,
            dialogLinkForm: {},
            dialogLinkRules: {},
            code: {},
            linkLoading: false,

            classifyData: [],
            sortOptions: [
                { name: 'conversionRate', value: 8 },
                { name: 'orderSuccessNum', value: 4 },
                { name: 'saleNum', value: 5 },
                { name: 'saleTotal', value: 6 },
                { name: 'saleNet', value: 7 },
                { name: 'buyNum', value: 9 },
                { name: 'buyUserNum', value: 10 },
                { name: 'buyPurchaseRate', value: 11 },
                { name: 'visitNum', value: 1 },
                { name: 'visitUserNum', value: 2 },
            ],
            shopOrders: [],

            orderData: [],

            orderStatusTypes: [
                // 订单状态选项
                { name: '未完成', value: '1', id: '1' },
                { name: '已完成', value: '2', id: '2' },
                { name: '已取消', value: '3', id: '3' },
            ],

            fulfillmentStatusTypes: [
                // 订单发货状态选项

                { name: '待发货', value: '1' },
                { name: '部分发货', value: '2' },
                { name: '已发货', value: '3' },
                { name: '部分完成', value: '4' },
                { name: '已完成', value: '5' },
                { name: '已退货', value: '6' },
            ],
            channelSourceData: [
                // 订单详情 - 渠道来源数据
                { name: '其它来源', value: 0 },
                { name: '直接访问', value: 1 },
                { name: 'Google', value: 2 },
                { name: 'Facebook', value: 3 },
                { name: 'Instagram', value: 4 },
                { name: 'Bing', value: 5 },
                { name: 'Baidu', value: 6 },
                { name: 'Snapchat', value: 7 },
                { name: 'pinterest', value: 8 },
                { name: 'youtube', value: 9 },
                { name: 'titok', value: 10 },
                { name: 'email', value: 11 },
            ],
            financialStatusTypes: [
                // 支付状态选项

                { name: '待支付', value: '1' },
                { name: '已支付', value: '2' },
                { name: '已取消', value: '3' },
                { name: '支付失败', value: '4' },
                { name: '待退款', value: '5' },
                { name: '部分退款', value: '6' },
                { name: '已退款', value: '7' },
                { name: '退款失败', value: '8' },
            ],

            // 选择商品参数
            dialogSelectGoods: false,
            // 选中的商品列表数据
            selectedGoodsTable: {
                data: [],
                total: 0,
            },
            deepSelectedGoodsTable: [],
            totalData: {},

            setting: {
                menubar: 'edit view format tools table',
                toolbar:
                    'undo redo removeformat | fullscreen | imageUpload | videoUpload code | styleselect alignleft aligncenter alignright alignjustify | link | numlist bullist | fontselect fontsizeselect forecolor backcolor | table bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
                toolbar_mode: 'sliding',
                quickbars_insert_toolbar: 'quicktable',
                quickbars_selection_toolbar:
                    'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
                plugins:
                    'link image media code table textpattern lists fullscreen quickbars',
                language: 'zh_CN',
                height: 350,
                convert_urls: false,
            },

            insertType: null,
            insertImage: false,

            dialogInsertVideo: false,
            activeVideo: 'first',
            insertVideoType: null,
            dialogVisibleVideo: false,
            dialogVideoUrl: null,

            userList: [],
            visibleDialogUser: false,
            userForm: {
                userId: '',
            },
            multipleSelection: [],
            userData: [],
        };
    },
    mounted() {
        this.date = this.timeDefault();
        this.queryData();
        this.getSkuGroup();
        this.changeSkuGroup();
        this.getType();
        this.initUserList();
        this.getPixel();
        
    },
    methods: {
		async getUserByAd(){
			return await this.$http.get('/system/user/getByAd').then((response) => {
			    if (response.data.code === 200) {
			        this.userData = response.data.data;
			        console.log(this.userData);
			    }
			});
		},
        getPixel() {
            this.$http.get('/setting/pixel/getList?pixelType=1').then((res) => {
                let { code, data } = res.data;
                if (code === 200) {
                    this.pixelTypes = data;
                }
            });
        },
        initUserList() {
            this.$http.get('/system/user/getList').then((res) => {
                let { code, data } = res.data;
                if (code === 200) {
                    this.userList = data;
                }
            });
        },
        bridgeChangeUser() {
            if (!this.multipleSelection.length) {
                this.$message.warning('请勾选变更的数据');
                return;
            }
            this.visibleDialogUser = true;
        },
        resetDialogUser() {
            Object.assign(
                this.userForm,
                this.$options.data.call(this).userForm
            );
        },
        submitChangeUser() {
            this.loading = 'user';
            this.$http
                .post('/setting/ad/editUser', {
                    ...this.userForm,
                    ids: this.multipleSelection.map((item) => item.id),
                })
                .then((res) => {
                    let { code } = res.data;
                    if (code === 200) {
                        this.$message.success('变更成功~');
                        this.queryData();
                        this.visibleDialogUser = false;
                    }
                })
                .finally(() => {
                    this.loading = null;
                });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        intoOrder({ id }) {
            this.visibleDialogOrder = true;
            this.orderId = id;
            this.queryOrderData();
        },
        getTotal(param) {
            const sums = [];
            const { columns, data } = param;
            // console.log(columns, data)
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总计';
                    return;
                }
                if (index < 6 && index !== 0) {
                    sums[index] = '';
                    return;
                }
                switch (index) {
                    case 6:
                        // this.totalData.visitNum
                        sums[index] = '';
                        break;
                    case 7:
                        sums[index] = this.totalData.visitNum;
                        break;
                    case 8:
                        sums[index] = this.totalData.visitUserNum;
                        break;
                    case 9:
                        sums[index] = this.totalData.outNum;
                        break;
                    case 10:
                        sums[index] = this.totalData.outRate + '%';
                        break;
                    case 11:
                        sums[index] = this.totalData.orderSuccessNum;
                        break;
                    case 12:
                        sums[index] = this.totalData.saleNum;
                        break;
                    case 13:
                        sums[index] = this.totalData.saleTotal;
                        break;
                    case 14:
                        sums[index] = this.totalData.orderSaleTotal;
                        break;
                    case 15:
                        sums[index] = this.totalData.conversionRate + '%';
                        break;
                    case 16:
                        sums[index] = this.totalData.buyUserNum;
                        break;
                    case 17:
                        sums[index] = this.totalData.buyPurchaseRate + '%';
                        break;
                    default:
                        break;
                }
            });
            setTimeout(() => {
                this.$refs.userTable.doLayout();
            }, 100);
            return sums;
        },
        queryTotal() {
            if (this.date && this.date.length > 1) {
                this.queryForm.startDate = this.date[0];
                this.queryForm.endDate = this.date[1];
            } else {
                this.queryForm.startDate = null;
                this.queryForm.endDate = null;
            }
            this.$http
                .get('/setting/ad/getTotalStatistic', {
                    params: this.queryForm,
                })
                .then((response) => {
                    let { code, data } = response.data;

                    if (code === 200) {
                        // console.log('total',data)
                        this.totalData = data;
                        this.$forceUpdate();
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async queryOrderData() {
            let [startDate, endDate] = this.date || [],
                {
                    data: { code, data, count },
                } = await this.$http.get(`/order/getAdOrderPage`, {
                    params: {
                        isItem: 1,
                        startDate,
                        endDate,
                        ...this.orderForm,
                        id: this.orderId,
                    },
                });

            if (code === 200) {
                this.orderData = data;
                this.orderTotal = count;
            }
        },
        sortTable(info) {
            let { prop = '', order } = info;

            console.log(info);

            if ((order ?? '') !== '') {
                let _key = prop.match(/([^.]+)$/)?.[0],
                    sort = this.sortKeyName.findIndex((cur) => {
                        return cur == _key;
                    }),
                    sortType = order == 'ascending' ? 1 : 2;

                this.queryForm.sort = sort;
                this.queryForm.sortType = sortType;
            } else {
                this.queryForm.sort = '';
                this.queryForm.sortType = '';
            }
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        timeDefault() {
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            if (mm < 10) {
                mm = '0' + mm;
            }
            let dd = new Date().getDate();
            if (dd < 10) {
                dd = '0' + dd;
            }
            let dateTime = yy + '-' + mm + '-' + dd;
            // console.log(dateTime)
            return [dateTime, dateTime];
        },
        testChange(val) {
            console.log(JSON.stringify(val));
        },
        // 获取列表数据
        async queryData({ isClearSort } = {}) {
			await this.getUserByAd()
            if (this.date && this.date.length > 1) {
                this.queryForm.startDate = this.date[0];
                this.queryForm.endDate = this.date[1];
            } else {
                this.queryForm.startDate = null;
                this.queryForm.endDate = null;
            }

            if (isClearSort) {
                this.queryForm.sort = '';
                this.queryForm.sortType = '';
                this.$refs.userTable.clearSort();
            }

            let { id, ...args } = this.queryForm;
            let params = {
                ...args,
            };
            if ((id ?? '') !== '') {
                params['id'] = id;
            }

            this.loading = true;
            this.$http
                .get('/setting/ad/getPage', { params })
                .then((response) => {
                    let {
                        code,
                        data: { records, total },
                    } = response.data;

                    if (code === 200) {
                        this.tableData = records;
                        this.total = total;
                        this.queryTotal();
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        queryAdd() {
            this.dialogAdd = true;
            this.$nextTick(() => {
                this.$refs['dialogAddForm']?.clearValidate();
            });
			for(let i = 0; i < this.pixelTypes.length; i++){
				let item = this.pixelTypes[i]
				if(item.firstChoice == '1'){
					this.dialogAddForm.pixelId = item.id
					break
				}
			}
			// this.dialogAddForm.pixelId = this.pixelTypes[0].id
        },
        getTypeId(type) {
            let typeId = '',
                categoryId = this.dialogAddForm.categoryId;

            switch (type) {
                case 1:
                    typeId = Array.isArray(categoryId)
                        ? categoryId.slice(-1).join(',')
                        : categoryId;
                    break;
                case 2:
                    typeId = this.dialogAddForm.albumId;
                    break;
                case 3:
                    typeId = '';
                    break;
                case 4:
                    typeId = this.selectedGoodsTable.data[0].id;
                    break;
            }

            return typeId;
        },
        setTypeId(type, typeId) {
            switch (type) {
                case 1:
                    this.dialogAddForm.categoryId = typeId;
                    break;
                case 2:
                    this.$set(this.dialogAddForm, 'albumId', typeId);
                    break;
                case 4:
                    this.getSingleGoods(typeId);
                    break;
            }
        },
        addData() {
            let url = this.dialogAddForm.id
                    ? '/setting/ad/edit'
                    : '/setting/ad/add',
                str = this.dialogAddForm.id ? '编辑' : '新增',
                spu = this.dialogAddForm.ruleJson.spu,
                _deepForm = JSON.parse(JSON.stringify(this.dialogAddForm)),
                { pageDesc, ...args } = _deepForm;

            _deepForm.ruleJson.spu = spu
                ? spu.replace('\n', '').replace(/\s+/g, '').split(',')
                : [];

            if (_deepForm.type == 4 && !this.selectedGoodsTable.data.length) {
                return this.$message.warning('请选择一个商品~');
            }

            let obj = {
                ..._deepForm,
                typeId: this.getTypeId(_deepForm.type),
            };

            if (_deepForm.type < 3) {
                obj['pageDesc'] = pageDesc;
            }

            this.$refs['dialogAddForm'].validate((valid) => {
                if (valid) {
                    this.$http
                        .post(url, obj)
                        .then((response) => {
                            let { code, data } = response.data;
                            if (code === 200) {
                                this.table = data;
                                this.dialogAdd = false;
                                this.$message.success(str + '成功');
                                this.queryData();
                            }
                        })
                        .finally(() => {
                            this.loadingAdd = false;
                        });
                }
            });
        },

        resetDialogInfo() {
            Object.assign(
                this.dialogAddForm,
                this.$options.data.call(this).dialogAddForm
            );
            Object.assign(
                this.selectedGoodsTable,
                this.$options.data.call(this).selectedGoodsTable
            );

            this.deepSelectedGoodsTable = [];

            this.isEdit = false;
        },

        resetDialogOrder() {
            Object.assign(
                this.orderForm,
                this.$options.data.call(this).orderForm
            );

            this.orderId = null;
            this.orderData = [];
            this.orderTotal = 0;
        },

        async getSingleGoods(id) {
            let {
                data: { code, data },
            } = await this.$http.get(`/goods/getDetail?id=${id}`);

            if (code === 200) {
                this.selectedGoodsTable.data = [data];
                this.selectedGoodsTable.total = 1;
            }
        },

        initEditForm(id) {
            this.$http
                .get('/setting/ad/getDetail?id=' + id)
                .then((response) => {
                    let { code, data } = response.data;
                    if (code == 200) {
                        this.dialogAddForm = {
                            id: data.id,
                            type: data.type,
                            ruleJson: {
                                spu: data.ruleJson.spu?.join(','),
                                rule: data.ruleJson.rule,
                            },
                            // isDefault:  data.isDefault.toString(),
                            pageName: data.pageName,
                            days: data.days,
                            targetWebsite: data.targetWebsite,
                            pageDesc: data.pageDesc || '',
                            // fitPerson: data.fitPerson,
                            pixelId: data.pixelId,
                        };

                        this.setTypeId(data.type, data.typeId);

                        this.dialogAdd = true;
                        this.$refs['dialogAddForm'].clearValidate();
                        this.isEdit = true;
                        this.addLoading = false;
                    }
                });
        },
        getSkuGroup() {
            this.spuOptions = [];
            this.$http.get('/goods/getSpus').then((response) => {
                if (response.data.code === 200) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        let json = {
                            id: i,
                            value: response.data.data[i],
                        };
                        this.spuOptions.push(json);
                    }
                }
            });
        },
        changeSkuGroup(value) {
            for (let i = 0; i < this.spuOptions.length; i++) {
                let item = this.spuOptions[i];
                let len = this.spuOptions[i].length;
                if (value === item.id) {
                    return len;
                }
                console.log(this.spuOptions[i], this.spuOptions[i].length, len);
            }
        },
        deleteData(row) {
            this.$confirm('是否删除该落地页？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .delete('/setting/ad/delete', { params: { id: row.id } })
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                                duration: 1000,
                                onClose: () => {
                                    this.queryData();
                                },
                            });
                        }
                    });
            });
        },

        alinmItem({ id }) {
            this.$confirm('确认认领此落地页', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                let {
                    data: { code },
                } = await this.$http.get(`/setting/ad/claim?id=${id}`);

                if (code === 200) {
                    this.$message.success('认领成功');
                    this.queryData();
                }
            });
        },

        openView(url) {
            window.open(url);
        },
        copy() {
            var clipboard = new this.Clipboard('#copy_text');
            clipboard.on('success', (e) => {
                this.$message({
                    message: '复制成功',
                    type: 'success',
                    duration: 1000,
                    onClose: () => {
                        this.queryData();
                        this.dialogLink = false;
                    },
                });
                clipboard.destroy();
            });
            clipboard.on('error', (e) => {
                // 不支持复制
                this.$message({
                    message: '该浏览器不支持自动复制',
                    type: 'warning',
                });
                // 释放内存
                clipboard.destroy();
            });
        },

        // linkData(e, text){
        //     const clipboard = new Clipboard(e.target, { text: () => text })
        //     clipboard.on('success', e => {
        //         this.$message({ type: 'success', message: '复制成功' })
        //         // 释放内存
        //         clipboard.off('error')
        //         clipboard.off('success')
        //         clipboard.destroy()
        //     })
        //     clipboard.on('error', e => {
        //         // 不支持复制
        //         this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        //         // 释放内存
        //         clipboard.off('error')
        //         clipboard.off('success')
        //         clipboard.destroy()
        //     })
        //     clipboard.onClick(e)
        // },
        getType() {
            this.$http.get('/goods/category/getList').then((res) => {
                let { code, data } = res.data;
                if (code === 200) this.classifyData = data;
            });

            this.$http.get('/goods/collection/getNames').then((res) => {
                let { code, data } = res.data;
                if (code === 200) this.albumOptions = data;
            });
            // this.$http.get('/goods/category/getPage').then((response) => {
            //     if (response.data.code === 200) {
            //         this.typeOptions = response.data.data;
            //     }
            //     console.log(this.typeOptions);
            // });
        },
        changeGetType(type) {
            for (let i = 0; i < this.classifyData.length; i++) {
                let item = this.classifyData[i];
                if (type === item.id) {
                    return item.categoryNameCn;
                }
            }
        },

        getRule(rule) {
            for (let i = 0; i < this.ruleOptions.length; i++) {
                let item = this.ruleOptions[i];
                if (rule === item.id) {
                    return item.name;
                }
            }
        },
        getTargetWebsite(targetWebsite) {
            for (let i = 0; i < this.targetWebsiteOptions.length; i++) {
                let item = this.targetWebsiteOptions[i];
                if (targetWebsite === item.id) {
                    return item.name;
                }
            }
        },
        getFItPerson(fitPerson) {
            for (let i = 0; i < this.fitPersonOptions.length; i++) {
                let item = this.fitPersonOptions[i];
                if (fitPerson === item.id) {
                    return item.name;
                }
            }
        },

        handleChange(value) {
            console.log(value);
        },

        // 复制成功
        onCopySuccess() {
            this.$message.success('复制成功');
        },
        // 复制失败
        onCopyError() {
            this.$message.error('复制失败');
        },
        getFulfillmentStatus(status) {
            switch (status) {
                case '1':
                    return '待发货';
                case '2':
                    return '已发货';
                case '3':
                    return '已完成';
                case '4':
                    return '已退货';
            }
        },
        getGoodsOption(obj) {
            let str = '';
            for (let item in obj) {
                str += item + ':' + obj[item] + ' / ';
            }
            str = str.substring(0, str.length - 3);
            return str;
        },

        bridgeOrderInfo(row) {
            this.$refs['orderInfo'].getOrderDetail(row);
        },

        // 选择商品方法
        openSelectGoods() {
            this.$refs.selectGoods.caller = 'notset';
            this.$refs.selectGoods.init();
            this.dialogSelectGoods = true;
        },
        closeSelectGoods() {
            this.dialogSelectGoods = false;
        },
        saveSelectGoods(items, caller) {
            switch (caller) {
                default:
                    this.selectedGoodsTable.data = items;
                    this.selectedGoodsTable.total =
                        this.selectedGoodsTable.data.length;
            }
        },
        // 置顶
        toppingGoods(item) {
            let tempitem = JSON.parse(JSON.stringify(item));
            tempitem.top = 1;
            this.selectedGoodsTable.data.unshift(tempitem);
            let index = this.selectedGoodsTable.data.indexOf(item);
            this.selectedGoodsTable.data.splice(index, 1);
        },
        // 删除选中的商品（删除）
        deleteSelectedGoods(item) {
            let index = this.selectedGoodsTable.data.indexOf(item);

            if (index > -1) {
                this.selectedGoodsTable.data.splice(index, 1);
                this.selectedGoodsTable.total =
                    this.selectedGoodsTable.data.length;
            }
        },
        getPublished(status) {
            switch (status) {
                case 0:
                    return '否';
                case 1:
                    return '是';
            }
        },
        getPublishedB(status) {
            switch (status) {
                case 0:
                    return '未上架';
                case 1:
                    return '已上架';
            }
        },

        setup(editor) {
            const that = this;
            editor.ui.registry.addButton('imageUpload', {
                tooltip: '插入图片',
                icon: 'image',
                onAction: () => {
                    that.setOpenInsert('tinymce');
                },
            });
            editor.ui.registry.addButton('videoUpload', {
                tooltip: '插入视频',
                icon: 'embed',
                onAction: () => {
                    that.openVideoUploader('tinymce');
                },
            });
        },
        /* 插入视频方法 */
        openVideoUploader(obj) {
            this.insertVideoType = obj;
            this.dialogInsertVideo = true;
            this.$refs.uploadVideo.openInsert();
        },
        getOpenVideo(result) {
            this.dialogInsertVideo = result;
        },
        getVideo(obj, video) {
            // console.log(obj)
            let elment;
            switch (obj) {
                // case 'modelList':
                //     elment = this.modelList[this.modelIndex];
                //     if (!elment[this.modelKey]) {
                //         elment[this.modelKey] = [];
                //     }
                //     elment[this.modelKey].push(...video);
                //     break;
                // case 'influencerList':
                //     elment = this.influencerList[this.modelIndex];
                //     // console.log(this.modelKey)
                //     if (!elment[this.modelKey]) {
                //         elment[this.modelKey] = [];
                //     }
                //     elment[this.modelKey].push(...video);
                //     break;
                // case 'modelVideoList':
                //     this.modelVideoList.push(...video);
                //     break;
                case 'tinymce':
                    // if ( !this.editGoodsCommentForm.videos ) this.editGoodsCommentForm.videos = [];
                    // this.editGoodsCommentForm.videos.push( ...video );
                    this.dialogAddForm.pageDesc += `<video width="100%" controls autoplay loop><source src="${
                        video[video.length - 1]
                    }"/></video>`;
                    break;
                default:
                    break;
            }
        },
        /* end */
        setOpenInsert(obj) {
            this.insertType = obj;
            this.insertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.insertImage = result;
        },
        getImage(obj, image) {
            // console.log(obj)
            if (obj == 'dictAddForm.goodsImages') {
                this.dictAddForm.goodsImages.push(...image);
            }
            if (obj == 'tinymce') {
                this.dialogAddForm.pageDesc += `<img src="${
                    image[image.length - 1]
                }"/>`;
            }
        },
    },
};
</script>
<style scoped>
.hidetext {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.el-input-number /deep/ .el-input-number__increase {
    display: block !important;
}
.el-input-number /deep/ .el-input-number__decrease {
    display: block !important;
}

.infoitem {
    max-height: 450px;
    overflow-y: auto;
}
.inforow {
    display: flex;
    margin: 10px 0;
    /* align-items: center; */
}
.inforow .imgbox {
    width: 120px;
    /*flex-basis: 120px;*/
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.inforow .infos {
    width: calc(100% - 150px);
    margin-left: 10px;
}
.inforow .infos p {
    text-align: left;
    margin: 0;
}
.inforow .infos p.tit {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}
.inforow .infos p.p {
    font-size: 14px;
    color: #bbb;
    line-height: 20px;
}

.wrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}
.wrapper .itembox {
    width: 180px;
    flex-basis: 180px;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    padding: 5px 5px 0 5px;
    border-radius: 10px;
    background-image: linear-gradient(315deg, #e2e2e2, #fff);
    position: relative;
}
.wrapper .itembox .imgbox {
    width: 100%;
    height: 220px;
}
.wrapper .itembox .tit {
    height: 32px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.wrapper .itembox .between {
    display: flex;
    border-top: 1px solid #ccc;
    margin: 0;
    height: 30px;
}
.wrapper .itembox .w50 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 50%;
    width: 50%;
    height: 100%;
    cursor: pointer;
    color: #409eff;
    font-size: 20px;
}
.wrapper .itembox p {
    margin: 0;
}
.wrapper .itembox .w50:first-child {
    border-right: 1px solid #ccc;
}
.wrapper .itembox .published {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    padding: 2px 5px;
    font-size: 14px;
}
.wrapper .itembox .published.publishedA {
    color: #333;
    background: #ddd;
}
.wrapper .itembox .published.publishedB {
    color: #fff;
    background: #409eff;
}

::v-deep .input-cascader {
    width: 87%;
}
/* ::v-deep .input-cascader .el-input {
    width: 87%;
} */
</style>