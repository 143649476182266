<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>店铺管理</el-breadcrumb-item>
            <el-breadcrumb-item>自定义代码</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.codeName" placeholder="名称"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="querySearch">查询</el-button>
                <el-button icon="el-icon-refresh-left" @click="resetCondition">重置</el-button>
                <el-button type="success" icon="el-icon-plus" @click="openAdd">新增</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表 -->
        <el-table border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" :data="tableData" v-loading="loading">
            <el-table-column prop="codeName" label="名称" min-width="200"></el-table-column>
            <el-table-column label="启用状态" width="120">
                <template slot-scope="scope">
                    <el-switch active-value="1" inactive-value="0" v-model="scope.row.status" @change="changeEnabled(scope.row, $event)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="用户端" width="180">
                <template slot-scope="scope">
                    {{ getClient(scope.row.client) }}
                </template>
            </el-table-column>
            <el-table-column label="触发页面" width="150">
                <template slot-scope="scope">
                    {{ getScope(scope.row.scope) }}
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="openEdit(scope.row.id)">编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="deleteThis(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[20, 50, 100]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-dialog top="3%" width="600px" :title="dialogAddForm.id ? '编辑代码' : '新增代码'" :visible.sync="dialogAdd" :close-on-click-modal="false">
            <el-form :model="dialogAddForm" :rules="dialogRules" status-icon class="addform" label-width="100px" ref="dialogAddForm">
                <el-form-item label="代码名称" prop="codeName">
                    <el-input v-model="dialogAddForm.codeName" placeholder="请输入代码名称"></el-input>
                </el-form-item>
                <el-form-item label="插入代码" prop="code">
                    <el-input v-model="dialogAddForm.code" placeholder="请输入代码" type="textarea" :rows="8"></el-input>
                </el-form-item>
                <el-form-item label="选择用户端">
                    <el-radio-group v-model="dialogAddForm.client">
                        <el-radio v-for="(item, index) in clientOptions" :key="index" :label="item.value">{{ item.name }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="触发页面">
                    <el-radio-group v-model="dialogAddForm.scope">
                        <el-radio v-for="(item, index) in scopeOptions" :key="index" :label="item.value">{{ item.name }}</el-radio>
                    </el-radio-group>
                    <el-checkbox-group v-model="dialogAddForm.pageTypes" v-if="dialogAddForm.scope == 2">
                        <el-checkbox v-for="(item, index) in pagesOptions" :key="index" :label="item.value">{{ item.name }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <!-- <el-form-item label="是否启用">
				<el-select style="width:150px;" v-model="dialogAddForm.status" placeholder="请选择">
					<el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                <el-button @click="dialogAdd = false">取消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: 'CustomCode',
    data() {
        return {
            queryForm: {
                codeName: null,
                pageNo: 1,
                pageSize: 20,
            },
            total: 0,
            tableData: [],
            loading: false,

            dialogAdd: false,
            addLoading: false,
            dialogAddForm: {
                codeName: null,
                code: '',
                client: 1,
                scope: 1,
                pageTypes: [],
                status: 1,
            },
            dialogRules: {
                codeName: [
                    {
                        required: true,
                        message: '请输入代码名称',
                        trigger: 'blur',
                    },
                ],
                code: [
                    { required: true, message: '请输入代码', trigger: 'blur' },
                ],
            },
            clientOptions: [
                { name: 'PC端和手机端', value: 1 },
                { name: '仅PC端', value: 2 },
                { name: '仅手机端', value: 3 },
            ],
            pagesOptions: [
                { name: '首页', value: 1 },
                { name: '列表页', value: 2 },
                { name: '商品详情页', value: 3 },
                { name: '购物车页', value: 4 },
                { name: '搜索结果页', value: 5 },
                { name: '支付结果页', value: 6 },
            ],
            scopeOptions: [
                { name: '全部页面', value: 1 },
                { name: '指定页面', value: 2 },
            ],
            statusOptions: [
                { name: '禁用', id: 0 },
                { name: '启用', id: 1 },
            ],
        };
    },
    mounted() {
        this.getPages();
        this.queryData();
    },
    methods: {
        querySearch() {
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        resetCondition() {
            this.queryForm.codeName = null;
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        queryData() {
            this.loading = true;

            if (!this.queryForm.codeName) {
                this.queryForm.codeName = null;
            }
            this.$http
                .get('/setting/code/getPage', { params: this.queryForm })
                .then((r) => {
                    this.loading = false;
                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        for (let i = 0; i < this.tableData.length; i++) {
                            let item = this.tableData[i];
                            item.status = item.status.toString();
                        }

                        this.total = r.data.count;
                    }
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        getPages() {
            this.loading = true;
            this.$http
                .get('/setting/page/getShopPageTypes')
                .then((r) => {
                    this.loading = false;
                    if (r.data.code === 200) {
                        console.log(r.data.data);
                        let data = r.data.data;
                        this.pagesOptions = [];
                        for (let i = 0; i < data.length; i++) {
                            let item = data[i];
                            let json = {
                                name: item.pageName,
                                value: item.pageType,
                            };
                            this.pagesOptions.push(json);
                        }
                    }
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        openAdd() {
            this.dialogAddForm = {
                codeName: null,
                code: '',
                client: 1,
                scope: 1,
                pageTypes: [],
                status: 1,
            };
            this.addLoading = false;
            this.dialogAdd = true;
        },
        openEdit(id) {
            this.loading = true;

            this.$http
                .get('/setting/code/getDetail?id=' + id)
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data;
                        this.dialogAddForm = {
                            id: data.id,
                            codeName: data.codeName,
                            code: data.code,
                            client: data.client,
                            scope: data.scope,
                            pageTypes: [],
                            status: data.status,
                        };

                        let pagetype = data.shopPageTypes;
                        if (pagetype) {
                            for (let i = 0; i < pagetype.length; i++) {
                                this.dialogAddForm.pageTypes.push(
                                    pagetype[i].pageType
                                );
                            }
                        }
                        this.addLoading = false;
                        this.dialogAdd = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addData() {
            this.$refs['dialogAddForm'].validate((valid) => {
                if (valid) {
                    let url;
                    if (this.dialogAddForm.id) {
                        url = '/setting/code/edit';
                    } else {
                        url = '/setting/code/add';
                    }

                    this.$http
                        .post(url, this.dialogAddForm)
                        .then((response) => {
                            this.addLoading = false;
                            if (response.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message({
                                    message: this.dialogAddForm.id
                                        ? '编辑成功'
                                        : '新增成功',
                                    type: 'success',
                                    duration: 1000,
                                });
                                this.queryData();
                            }
                        })
                        .catch((err) => {
                            this.addLoading = false;
                        });
                }
            });
        },
        deleteThis(id) {
            this.$confirm('是否删除自定义代码？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .delete('/setting/code/delete?id=' + id)
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message.success('删除成功');
                            this.queryData();
                        }
                    });
            });
        },
        changeEnabled(scope, value) {
            console.log(scope);
            this.$confirm(`确认要${value == 0 ? '取消启用' : '启用'}吗？`, {
                type: 'warning',
            })
                .then(() => {
                    this.$http
                        .post('/setting/code/setStatus', {
                            id: scope.id,
                            status: value,
                        })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '设置成功',
                                    duration: 1000,
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            scope.status = value == 0 ? '1' : '0';
                        })
                        .finally(() => {
                            this.queryData();
                        });
                })
                .catch(() => (scope.status = value == 0 ? '1' : '0'));
        },
        getClient(type) {
            for (let i = 0; i < this.clientOptions.length; i++) {
                let item = this.clientOptions[i];
                if (item.value == type) {
                    return item.name;
                }
            }
        },
        getScope(type) {
            for (let i = 0; i < this.scopeOptions.length; i++) {
                let item = this.scopeOptions[i];
                if (item.value == type) {
                    return item.name;
                }
            }
        },
    },
};
</script>

<style scoped>
</style>