<template>
  <div class="sys-wrap">
    <!-- 主页面 -->
    <el-container style="height: 100%">
      <el-aside :width="isCollapse ? '64px' : '240px'">
        <div class="logo" v-if="!isCollapse" @click="logo">
          Tivisiy商城管理平台
        </div>
        <div class="logo" v-else @click="logo">
          <i class="el-icon-monitor"></i>
        </div>
		<div class="logo" v-if="isCollapse">
		  <i
		  	@click="toggleCollapse"
		  	:class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
		  ></i>
		</div>
        <el-menu
          router
          background-color="#545c64"
          text-color="#fff"
          :default-active="$route.path"
          active-text-color="#ffd04b"
          :collapse="isCollapse"
          :collapse-transition="false"
        >
          <MenuTree :menus="$store.state.userAuth.menus"></MenuTree> </el-menu
      ></el-aside>
      <el-container>
        <el-header height="50px" :class="isCollapse ? 'blue' : ''" v-if="!isCollapse">
		  <div>
			<i
				class="fold"
				@click="toggleCollapse"
				:class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
			></i>
			<i class="fold el-icon-collection-tag" @click="dialogHot = true"></i>
		  </div>
          
          <el-dropdown trigger="click" @command="handleCommand">
            <el-avatar icon="el-icon-user" :size="30"></el-avatar>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item disabled
                >账号：{{ $store.state.userAuth.userName }}</el-dropdown-item
              >
              <el-dropdown-item divided icon="el-icon-user" command="selfInfo"
                >个人信息</el-dropdown-item
              >
              <el-dropdown-item icon="el-icon-edit" command="editSelf"
                >修改信息</el-dropdown-item
              >
              <el-dropdown-item icon="el-icon-key" command="editPassword"
                >修改密码</el-dropdown-item
              >
              <el-dropdown-item icon="el-icon-switch-button" command="logout"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-header>
        <el-main :class="blueBackground ? 'blue' : ''"><router-view :isCollapse="isCollapse" @changeClose="changeClose" @changeBlue="changeBlue"></router-view></el-main>
      </el-container>
    </el-container>
	
	<el-dialog title="快捷导航" :visible.sync="dialogHot" :close-on-click-modal="false" top="10%" width="740px">
		<div class="flex pathbox">
			<div class="item" v-for="(item, index) in hotPath" :key="index" @click="goPath(item.path)">
				<i class="icon" :class="item.icon"></i>
				<p class="name">{{ item.name }}</p>
			</div>
		</div>
	</el-dialog>
	
    <!-- 业务弹框-个人信息 -->
    <el-dialog
      title="个人信息"
      :visible.sync="dialogSelfInfo"
      :close-on-click-modal="false"
      top="3%"
      width="35%"
    >
      <el-descriptions
        :column="1"
        v-loading="selfInfoLoading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-descriptions-item label="用户ID">
          {{ selfInfoData.id }}
        </el-descriptions-item>
        <el-descriptions-item label="用户名">
          {{ selfInfoData.userName }}
        </el-descriptions-item>
        <el-descriptions-item label="真实姓名">
          {{ selfInfoData.realName }}
        </el-descriptions-item>
        <el-descriptions-item label="性别">
          {{ this.sexFormatter(selfInfoData) }}
        </el-descriptions-item>
        <el-descriptions-item label="手机号">
          {{ selfInfoData.phone }}
        </el-descriptions-item>
        <el-descriptions-item label="邮箱">
          {{ selfInfoData.email }}
        </el-descriptions-item>
        <el-descriptions-item label="角色">
          {{ selfInfoData.roleName }}
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">
          {{ selfInfoData.createTime }}
        </el-descriptions-item>
        <el-descriptions-item label="更新时间">
          {{ selfInfoData.updateTime }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <!-- 业务弹框-修改信息 -->
    <el-dialog
      title="修改信息"
      :visible.sync="dialogEditSelf"
      :close-on-click-modal="false"
      top="3%"
      width="35%"
    >
      <el-form
        :model="selfEditForm"
        :rules="selfRules"
        status-icon
        ref="selfEditForm"
        label-position="top"
        size="medium"
        v-loading="initEditLoading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-form-item label="真实姓名" prop="realName">
          <el-tooltip
            content="真实姓名必须为中文、字母或数字，且不超过20位"
            placement="right"
            effect="light"
          >
            <el-input
              placeholder="请输入真实姓名"
              v-model="selfEditForm.realName"
            ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="selfEditForm.sex">
            <el-option
              v-for="item in sexOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            placeholder="请输入手机号"
            v-model="selfEditForm.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            placeholder="请输入邮箱"
            v-model="selfEditForm.email"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="selfEditLoading" @click="selfEdit"
          >确定</el-button
        >
        <el-button @click="dialogEditSelf = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 业务弹框-修改密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogEditPassword"
      :close-on-click-modal="false"
      top="3%"
      width="35%"
    >
      <el-form
        :model="editPasswordForm"
        :rules="editPasswordRules"
        status-icon
        ref="editPasswordForm"
        label-position="top"
        size="medium"
      >
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            type="password"
            placeholder="请输入旧密码"
            v-model="editPasswordForm.oldPassword"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-tooltip
            content="密码必须6~12位字符，且不能出现不可见字符"
            placement="right"
            effect="light"
          >
            <el-input
              type="password"
              placeholder="请输入新密码"
              v-model="editPasswordForm.newPassword"
            ></el-input
          ></el-tooltip>
        </el-form-item>
        <el-form-item label="确认密码" prop="rePassword">
          <el-input
            type="password"
            placeholder="请输入确认密码"
            v-model="editPasswordForm.rePassword"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          :loading="editPasswordLoading"
          @click="editPassword"
          >确定</el-button
        >
        <el-button @click="dialogEditPassword = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MenuTree from "../components/menuTree";
export default {
  name: "Main",
  data() {
    let vRePassword = (rule, value, callback) => {
      if (value !== this.editPasswordForm.newPassword) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      isCollapse: false,
	  blueBackground: false,
      dialogSelfInfo: false,
      selfInfoLoading: false,
      selfInfoData: {},
      dialogEditSelf: false,
      sexOptions: [
        {
          name: "男",
          value: 1,
        },
        {
          name: "女",
          value: 2,
        },
      ],
      selfEditForm: {},
      selfRules: {
        realName: [
          {
            pattern: /^[\u4E00-\u9FA5A-Za-z0-9]{0,20}$/,
            message: "真实姓名格式不正确",
            trigger: "blur",
          },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        phone: [
          {
            pattern: /^1\d{10}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        email: [
          {
            type: "email",
            message: "邮箱格式不正确",
            trigger: "blur",
          },
        ],
      },
      initEditLoading: false,
      selfEditLoading: false,
      dialogEditPassword: false,
      editPasswordForm: {},
      editPasswordRules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^[\S]{6,12}$/,
            message: "密码格式不正确",
            trigger: "blur",
          },
        ],
        rePassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: vRePassword, trigger: "blur" },
        ],
      },
      editPasswordLoading: false,
	  dialogHot: false,
	  allPath: [],
	  hotNames: ['首页', '商品列表', '订单列表', '售后订单', '满减活动', '优惠码活动', '秒杀活动', '物流方案', '商品数据统计', '实时数据统计', '素材中心', 'Banner设置', '自定义页面'],
	  hotPath: [],
    };
  },
  mounted(){
	this.getPath()
  },
  methods: {
	getPath(){
		let res = this.getDeep(this.$store.state.userAuth.menus)
		// console.log(this.allPath)
		for(let i = 0; i < this.allPath.length; i++){
			let itemA = this.allPath[i]
			for(let k = 0; k < this.hotNames.length; k++){
				if(itemA.name == this.hotNames[k]){
					this.hotPath.push(itemA)
				}
			}
		}
		// console.log(this.hotPath)
	},
	getDeep(menus){
		let len = menus.length
		for(let i = 0; i < len; i++){
			let item = menus[i]
			if(item.children && item.children.length > 0){
				this.getDeep(item.children)
			} else {
				let json = {
					path: item.menuUrl,
					name: item.menuName,
					icon: item.menuIcon
				}
				this.allPath.push(json)
			}
		}
	},
	goPath(path){
		this.$router.push({
		    path
		})
		this.dialogHot = false
	},
	changeClose(type){
		this.isCollapse = type
	},
	changeBlue(type){
		this.blueBackground = type
	},
    sexFormatter(row) {
      for (let i = 0, len = this.sexOptions.length; i < len; i++) {
        let item = this.sexOptions[i];
        if (row.sex === item.value) {
          return item.name;
        }
      }
      return null;
    },
    logo() {
      this.$router.push({ name: "Index" });
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    handleCommand(command) {
      switch (command) {
        case "selfInfo":
          this.selfInfoLoading = true;
          this.$http
            .get("/system/user/getSelfInfo")
            .then((response) => {
              if (response.data.code === 200) {
                this.selfInfoData = response.data.data;
              }
            })
            .finally(() => {
              this.selfInfoLoading = false;
            });
          this.dialogSelfInfo = true;
          break;
        case "editSelf":
          this.initEditLoading = true;
          this.$http
            .get("/system/user/getSelfInfo")
            .then((response) => {
              if (response.data.code === 200) {
                let retData = response.data.data;
                this.selfEditForm = {
                  realName: retData.realName,
                  sex: retData.sex,
                  phone: retData.phone,
                  email: retData.email,
                };
              }
            })
            .finally(() => {
              this.initEditLoading = false;
            });
          this.selfEditLoading = false;
          this.dialogEditSelf = true;
          break;
        case "editPassword":
          this.editPasswordForm = {};
          this.editPasswordLoading = false;
          this.dialogEditPassword = true;
          break;
        case "logout":
          this.$http.get("/system/logout").then(() => {
            this.$message({
              message: "退出登录",
              type: "success",
              duration: 1000,
              onClose: () => {
                this.$router.push({ name: "Login" });
                this.$store.commit("delUserAuth");
              },
            });
          });
          break;
      }
    },
    selfEdit() {
      this.$refs["selfEditForm"].validate((valid) => {
        if (valid) {
          this.selfEditLoading = true;
          this.$http
            .post("/system/user/editSelf", this.selfEditForm)
            .then((response) => {
              if (response.data.code === 200) {
                this.dialogEditSelf = false;
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 1000,
                });
              }
            })
            .finally(() => {
              this.selfEditLoading = false;
            });
        }
      });
    },
    editPassword() {
      this.$refs["editPasswordForm"].validate((valid) => {
        if (valid) {
          this.editPasswordLoading = true;
          let editSelfPassword = {
            oldPassword: this.$encodePassword(
              this.editPasswordForm.oldPassword
            ),
            newPassword: this.$encodePassword(
              this.editPasswordForm.newPassword
            ),
            rePassword: this.$encodePassword(this.editPasswordForm.rePassword),
          };
          this.$http
            .post("/system/user/editSelfPassword", editSelfPassword)
            .then((response) => {
              if (response.data.code === 200) {
                this.dialogEditPassword = false;
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 1000,
                });
              }
            })
            .finally(() => {
              this.editPasswordLoading = false;
            });
        }
      });
    },
  },
  components: {
    MenuTree,
  },
};
</script>

<style scoped>
.sys-wrap {
  width: 100vw;
  height: 100vh;
}
.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.el-header.blue {
	background-color: #011d40;
}
.el-aside {
  background-color: #545c64;
}
.logo {
  height: 50px;
  color: #fff;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.fold {
  color: #545c64;
  font-size: 20px;
  cursor: pointer;
  margin-right: 15px;
}
.el-header.blue .fold {
	color: #fff;
}
.el-avatar {
  cursor: pointer;
  vertical-align: middle;
}
.el-menu {
  border: 0;
}
.el-main {
  background-color: #f2f2f2;
  background-image: unset;
}
.el-main.blue {
	/* background-image: linear-gradient(180deg, #011c3c, #002a61); */
	background: 
	        repeating-linear-gradient(90deg,#102029 0, #102029 0, transparent 1px, transparent 8px),
	        repeating-linear-gradient(#102029 0, #102029 0, transparent 1px, transparent 8px),
	        #0a1216;
	padding: 0 20px;
}
.pathbox {
	padding-bottom: 30px;
}
.pathbox .item {
	flex-basis: calc(25% - 32px);
	width: calc(25% - 32px);
	border: 1px solid #dfdfdf;
	color: #333;
	margin: 15px;
	cursor: pointer;
	border-radius: 8px;
	box-shadow: 0px 2px 6px 0px #ddd;
}
.pathbox .icon {
	font-size: 48px;
	margin: 15px auto;
	display: block;
	text-align: center;
}
.pathbox .name {
	text-align: center;
	font-size: 20px;
	margin: 15px 0;
}
::-webkit-scrollbar {
	width:10px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
	-webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
	border-radius:10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
	border-radius:10px;
	background:rgba(0,0,0,.5);
	-webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
	background:rgba(0,0,0,.5);
}
</style>
