<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>店铺管理</el-breadcrumb-item>
            <el-breadcrumb-item>币种设置</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="top-mod">
            <h2>全局设置</h2>
            <el-switch v-model="hasSetting" :active-value="1" :inactive-value="0" inactive-text="是否开启多币种" @change="setIsEnable">
            </el-switch>
        </div>

        <div class="top-form">
            <el-button type="success" icon="el-icon-plus" @click="bridgeAdd">新增</el-button>
        </div>

        <el-table border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" :data="tableData" v-loading="loading == 'table' ">
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="cnName" label="币种中文名"></el-table-column>
            <el-table-column prop="enName" label="币种英文名"></el-table-column>
            <el-table-column prop="code" label="英文缩写"></el-table-column>
            <el-table-column prop="symbol" label="币种符号"></el-table-column>
            <el-table-column prop="exchangeRate" label="兑美元汇率"></el-table-column>
            <el-table-column label="图标">
                <template #default="{row}">
                    <template v-if="row.image">
                        <el-image :src="row.image" fit="cover" :preview-src-list="[row.image || '']" style="width:80px; height: 80px;">
                        </el-image>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="220">
                <template #default="{row}">
                    <div class="handler">
                        <el-link type="primary" @click="intoEdit(row)">编辑</el-link>
                        <el-link type="primary" @click="initEditCountry(row)">分配国家</el-link>
                        <el-link type="primary" @click="bridgeRmove(row)" v-if="row.code != 'USD' ">删除</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background :current-page.sync="queryForm.pageNo" :page-sizes="[20, 50]" :page-size.sync="queryForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <!-- 新增 -->
        <el-dialog :title="isEdit ? '编辑币种' : '新增币种'" :visible.sync="visibleDialog" :close-on-click-modal="false" top="3%" width="800px" @closed="resetDialogInfo" @open="openDialogCurr">
            <el-form :model="dialogForm" :rules="rules" status-icon ref="dialogForm" label-width="100px">
                <el-form-item label="选择币种" prop="currencyId" label-width="120px" v-if="!isEdit">
                    <el-select v-model="dialogForm.currencyId" clearable filterable placeholder="选择币种">
                        <el-option v-for="(item,index) in currencyList" :key="index" :label="item.cnName" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择币种" label-width="120px" v-if="isEdit">
                    {{dialogForm.cnName}}
                </el-form-item>
                <el-form-item label="币种符号" label-width="120px" v-if="isEdit">
                    {{dialogForm.symbol}}
                </el-form-item>
                <el-form-item label="美元兑币种汇率" prop="exchangeRate" label-width="120px">
                    <el-input placeholder="美元兑币种汇率" v-model="dialogForm.exchangeRate" clearable></el-input>
                </el-form-item>
                <el-form-item label="币种图标" label-width="120px" class="other-form-item">
                    <!-- <div class="alomost-label">上传素材</div> -->
                    <p class="explain">（假设填写2，则显示的币种售价为原美元售价*2）</p>
                    <div class="mod-handler-image">
                        <div class="mod-images-list">
                            <section class="ImageBox" v-for="(item, i) in dialogForm.materialUrls" :key="i">
                                <el-image fit="cover" class="Image" :src="item">
                                    <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                                </el-image>
                                <div class="mask_d5"></div>
                                <el-button type="danger" icon="el-icon-delete" circle class="ImageDeleteButton" @click="dialogForm.materialUrls.splice(i, 1)"></el-button>
                            </section>
                        </div>
                        <i class="el-icon-plus img-uploader-icon ImageAddButton" @click="openImageUploader( 'dialogForm' )"></i>
                    </div>
                    <!-- <p class="tips">（请上传不要超过400x400px，大小1M的素材）</p> -->
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="loading == 'dialog' " @click="bridgeSumbit">确定</el-button>
                <el-button @click="visibleDialog = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 分配区域 -->
        <el-dialog top="3%" title="分配区域" width="1200px" :visible.sync="dialogEditCountry" :close-on-click-modal="false">
            <el-form ref="editCountryForm" size="medium" label-position="top" status-icon :model="editCountryForm">
                <div class="m_3" v-for="item in continents" :key="item.id">
                    <div class="mb_3" style="padding: 15px 12px; color: #fff; background-color: #f0f0f0">
                        <el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll" @change="checkContinent($event, item)">{{ item.cnName }}</el-checkbox>
                    </div>
                    <el-checkbox-group class="dg" style="
              grid-template-columns: repeat(auto-fill, 180px);
              padding-left: 12px;
            " v-model="item.checkedIds" @change="checkCountry($event, item)">
                        <el-checkbox v-for="country in item.countryList" :key="country.id" :label="country.id">{{ country.cnName }}</el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="editCountry">确定</el-button>
                <el-button @click="dialogEditCountry = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 插入图片 -->
        <ImageUploader ref="uploadImage" :insertImage="dialogInsertImage" :insertType="insertType" :activeName="activeName" @getOpenState="getOpenState" @getImage="getImage">
        </ImageUploader>

    </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader';
export default {
    name: 'Currency',
    components: { ImageUploader },

    data() {
        var checkImg = (rule, value, callback) => {
            if (this.dialogForm.materialUrls.length) {
                return callback();
            }
            callback(new Error('请选择币种图标'));
        };

        return {
            queryForm: {
                pageNo: 1,
                pageSize: 20,
            },
            dialogForm: {
                currencyId: null,
                exchangeRate: null,
                materialUrls: [],
            },
            total: 0,
            hasSetting: null,
            tableData: [],
            currencyList: [],
            loading: null,
            isEdit: false,
            visibleDialog: false,
            rules: {
                currencyId: [
                    {
                        required: true,
                        message: '请选择币种',
                        trigger: 'change',
                    },
                    {
                        required: true,
                        message: '请选择币种',
                        trigger: 'blur',
                    },
                ],
                exchangeRate: [
                    {
                        required: true,
                        message: '兑换美元汇率',
                        trigger: 'blur',
                    },
                ],
                materialUrls: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: checkImg,
                    },
                ],
            },

            //
            dialogInsertImage: false,
            activeName: 'first',
            insertType: null,
            insertIndex: null,

            // 分配区域
            continents: null,
            dialogEditCountry: false,
            editCountryForm: {},
        };
    },
    mounted() {
        this.queryCountry();
        this.getIsEnable();
        // this.getCurrencyList();
        this.queryData();
    },
    methods: {
        queryData() {
            this.loading = 'table';
            this.$http
                .get(`/currency/rate/getPage`, {
                    params: this.queryForm,
                })
                .then((res) => {
                    let { code, data, count } = res.data;
                    if (code === 200) {
                        this.tableData = data;
                        this.total = count;
                    }
                })
                .finally(() => {
                    this.loading = null;
                });
        },
        async getCurrencyList() {
            let {
                data: { code, data },
            } = await this.$http.get('/currency/rate/getCurrencyList');

            if (code === 200) {
                this.currencyList = data;
            }
        },
        async getIsEnable() {
            let {
                data: { code, data },
            } = await this.$http.get('/currency/rate/getIsEnable');

            if (code === 200) {
                this.hasSetting = data;
            }
        },
        setIsEnable() {
            let url = `/currency/rate/setIsEnable`,
                msg = this.hasSetting ? '开启' : '关闭';
            this.$http.post(url).then(
                (res) => {
                    let { code } = res.data;
                    if (code === 200) {
                        this.$message.success(`${msg}成功~`);
                    }
                },
                () => {
                    this.hasSetting = !this.hasSetting;
                }
            );
        },
        bridgeAdd() {
            this.visibleDialog = true;
        },
        bridgeSumbit() {
            this.$refs.dialogForm.validate((valid) => {
                if (valid) {
                    this.addData();
                }
            });
        },
        addData() {
            let { exchangeRate, materialUrls, id, ...args } = this.dialogForm,
                msg = id ? '编辑' : '新增',
                url = id ? 'edit' : 'add';

            let params = {
                ...args,
                exchangeRate: +exchangeRate,
                image: materialUrls?.[0] || null,
            };

            if (id) {
                params['id'] = id;
            }

            this.$http.post(`/currency/rate/${url}`, params).then((res) => {
                let { code } = res.data;
                if (code === 200) {
                    this.$message.success(`${msg}成功~`);
                    this.queryData();
                    this.visibleDialog = false;
                }
            });
        },

        bridgeRmove({ id }) {
            this.$confirm('是否删除当前这一条？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.deteleItem(id);
            });
        },
        deteleItem(id) {
            this.$http
                .delete(`/currency/rate/delete`, {
                    params: {
                        id,
                    },
                })
                .then((res) => {
                    let { code, data } = res.data;
                    if (code === 200) {
                        this.$message.success(`删除成功~`);
                        this.queryData();
                    }
                });
        },
        intoEdit(row) {
            let { image, ...args } = JSON.parse(JSON.stringify(row));
            this.visibleDialog = true;
            this.isEdit = true;

            this.dialogForm = {
                materialUrls: image ? [image] : [],
                ...args,
            };
        },
        resetDialogInfo() {
            this.dialogForm = Object.assign(
                {},
                this.$options.data.call(this).dialogForm
            );
            this.isEdit = false;
        },

        openDialogCurr() {
            this.getCurrencyList();
            this.$refs.dialogForm?.clearValidate();
        },

        // 插入图片方法
        openImageUploader(obj, index) {
            this.insertType = obj;
            this.insertIndex = index;
            this.dialogInsertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.dialogInsertImage = result;
        },
        getImage(obj, images = []) {
            this.dialogForm.materialUrls = [
                // ...this.dialogForm.materialUrls,
                ...images,
            ];
            // this.dialogInsertImage = false;
        },

        // 分配区域
        queryCountry() {
            this.$http
                .get('/common/continent/getList', { params: { isCountry: 1 } })
                .then((r) => {
                    if (r.data.code === 200) {
                        let data = r.data.data;

                        data.map((item) => {
                            item.isIndeterminate = false;
                            item.checkAll = false;
                            item.checkedIds = [];
                        });

                        this.continents = data;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                });
        },
        initEditCountry(item) {
            this.editCountryForm = {
                id: item.id,
            };

            let checkedIds = item.countryIds || []; // 物流方案选中的所有国家id集合

            this.continents.forEach((el) => {
                let ids = []; // 单个洲选中的国家id集合

                el.countryList.forEach((country) => {
                    if (checkedIds.includes(country.id)) ids.push(country.id);
                });

                el.checkedIds = ids;
                el.checkAll = ids.length === el.countryList.length;
                el.isIndeterminate =
                    ids.length > 0 && ids.length < el.countryList.length;
            });

            this.dialogEditCountry = true;
        },
        editCountry() {
            this.editCountryForm.countryIds = this.continents.reduce(
                (a, b) => a.concat(b.checkedIds),
                []
            );

            // this.btnLoading = true;

            this.$http
                .post('/currency/rate/editCountry', this.editCountryForm)
                .then((r) => {
                    // this.btnLoading = false;

                    if (r.data.code === 200) {
                        this.dialogEditCountry = false;

                        this.$message({
                            message: '新增成功',
                            type: 'success',
                            duration: 1000,
                            onClose: this.queryData,
                        });
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    // this.btnLoading = false;
                });
        },
        // 选中洲
        checkContinent(val, item) {
            item.checkedIds = val ? item.countryList.map((v) => v.id) : [];
            item.isIndeterminate = false;
        },
        // 选中国家
        checkCountry(vals, item) {
            let checkedCount = vals.length;

            item.checkAll = checkedCount === item.countryList.length;
            item.isIndeterminate =
                checkedCount > 0 && checkedCount < item.countryList.length;
        },
    },
};
</script>

<style>
.top-form {
    display: flex;
    /* justify-content: flex-end; */
    padding: 20px 0 30px;
}
.ImageBox .el-image {
    width: 120px;
}
.ImageBox .el-image img {
    object-fit: cover;
}
.img-uploader-icon {
    margin-top: 0;
}
.mod-handler-image {
    display: flex;
    gap: 0;
}
.mod-images-list {
    gap: 0;
}
.explain {
    margin: 0;
    color: #999;
    margin-top: -30px;
    font-size: 12px;
}
.other-form-item {
    margin-top: 40px;
}
.ImageBox {
    margin-right: 20px;
}
</style>