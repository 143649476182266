<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>营销管理</el-breadcrumb-item>
            <el-breadcrumb-item>捆绑活动</el-breadcrumb-item>
        </el-breadcrumb>

        <el-tabs v-model="campaignStatus" @tab-click="queryData">
            <el-tab-pane label="全部" name="0"></el-tab-pane>
            <el-tab-pane v-for="item in campaignStatusTypes" :key="item.value" :label="item.name" :name="item.value"></el-tab-pane>
        </el-tabs>

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm">
            <el-form-item label="活动名称">
                <el-input v-model="queryForm.campaignName" placeholder="活动名称"></el-input>
            </el-form-item>
            <el-form-item label="SPU">
                <el-input v-model="queryForm.spu" placeholder="SPU"></el-input>
            </el-form-item>
            <!-- <el-form-item label="活动状态">
        <el-select v-model="queryForm.campaignStatus">
          <el-option
            v-for="item in campaignStatusTypes"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item> -->
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="querySearch">查询</el-button>
                <el-button icon="el-icon-refresh-left" @click="resetCondition">重置</el-button>
                <el-button type="success" icon="el-icon-plus" @click="openAdd">新增</el-button>
                <el-button type="warning" icon="el-icon-s-data" @click="bridge_getStaticTotal">数据明细</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表 -->
        <el-table border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" :data="tableData" v-loading="loading">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="campaignName" label="活动名称" min-width="120"></el-table-column>
            <el-table-column label="活动状态" width="120">
                <template slot-scope="scope">
                    {{ campaignStatusTypes.find((v) => v.value == scope.row.campaignStatus).name }}
                </template>
            </el-table-column>
            <el-table-column label="优惠类型" width="120">
                <template slot-scope="scope">
                    {{ discountTypeOption.find((v) => v.value == scope.row.discountType).name }}
                </template>
            </el-table-column>
            <el-table-column label="优惠数值" prop="discountNum" width="100"></el-table-column>
            <el-table-column label="优惠单位" prop="discountUnit" width="100"></el-table-column>
            <el-table-column label="开始时间" prop="startTime" width="100"></el-table-column>
            <el-table-column label="结束时间" prop="endTime" width="100"></el-table-column>

            <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit" v-if="$auth($authData.campaign.bingEdit)" @click="openEdit(scope.row.id)">编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-video-pause" v-if="$auth($authData.campaign.bingEnd)" @click="endThis(scope.row.id)">结束活动</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" v-if="$auth($authData.campaign.bingDelete)" @click="deleteThis(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[20, 50, 100]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <!-- 编辑满减活动 -->
        <el-dialog :title="isAdding ? '新增活动' : '编辑活动'" :visible.sync="dialogAdd" :close-on-click-modal="false" top="3%" fullscreen>
            <el-form :model="dialogAddForm" :rules="dialogAddRules" status-icon ref="dialogAddForm" size="medium">
                <!-- 创建活动 -->
                <el-form-item label="活动名称" prop="campaignName" style="max-width: 400px">
                    <el-input placeholder="请输入活动名称" maxlength="20" show-word-limit v-model="dialogAddForm.campaignName"></el-input>
                </el-form-item>

                <div class="df ai_fe">
                    <el-form-item label="活动时间" prop="startTime">
                        <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间" v-model="dialogAddForm.startTime">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item class="px_1" style="color: #bbb">～</el-form-item>
                    <el-form-item prop="endTime">
                        <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间" :disabled="addCampaignFullEndTime" v-model="dialogAddForm.endTime">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item prop="isDefault" class="ml_3">
                        <el-checkbox v-model="addCampaignFullEndTime" @change="
                ($event) => {
                  if ($event) dialogAddForm.endTime = null;
                }
              ">不限结束时间</el-checkbox>
                    </el-form-item>
                </div>

                <el-form-item label="优惠类型" prop="discountType">
                    <el-select v-model="dialogAddForm.discountType" :disabled="!isAdding" style="width: 240px" @change="changeDiscountType">
                        <el-option v-for="item in discountTypeOption" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="优惠数值" prop="discountNum" v-if="dialogAddForm.discountType != 4">
                    <el-input-number v-model.number="dialogAddForm.discountNum" :precision="0" :min="0" placeholder="请输入优惠数值" class="w200">
                    </el-input-number> {{ dialogAddForm.discountUnit }}
                </el-form-item>

                <!-- <el-form-item label="优惠单位" prop="discountUnit">
          <el-input
            v-model="dialogAddForm.discountUnit" 
            placeholder="请输入优惠单位" 
            style="max-width: 150px">
          </el-input>
        </el-form-item> -->

                <el-form-item label="商品">
                    <!-- <el-button
            size="mini"
            type="primary"
            icon="el-icon-s-tools"
            @click="chooseItem">
            选择商品...
          </el-button> -->
                    <el-button type="primary" icon="el-icon-plus" @click="openSelectGoods">选择商品
                    </el-button>
                    &nbsp;&nbsp;已选商品：{{ selectedGoodsTable.total }}
                </el-form-item>

                <!-- 数据表 -->
                <el-table ref="selectedGoodsTable" class="mt_3" stripe :data="selectedGoodsTable.data">
                    <el-table-column label="商品图片" width="80">
                        <template slot-scope="scope">
                            <GoodsImageTemplate :images="scope.row.goodsImages"></GoodsImageTemplate>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goodsTitle" label="商品名称" min-width="250px"></el-table-column>
                    <el-table-column label="售价/原价" min-width="80">
                        <template slot-scope="scope">
                            {{ scope.row.price }} / {{ scope.row.compareAtPrice }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="inventoryQuantity" label="库存" min-width="80"></el-table-column>
                    <el-table-column label="是否上架" min-width="80">
                        <template slot-scope="scope">
                            {{ getPublished(scope.row.published) }}
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
            prop="goodsRemark"
            label="备注"
            min-width="80"
          ></el-table-column> -->
                    <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="text" @click="deleteSelectedGoods(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- <el-table
          :data="selectedGoods"
          v-loading="goodsloading"
          element-loading-text="加载中"
          element-loading-spinner="el-icon-loading"
          tooltip-effect="dark"
          style="width: 100%">
          <el-table-column prop="id" label="ID" width="80"></el-table-column>
          <el-table-column prop="goodsTitle" label="商品名称" min-width="150"></el-table-column>
          <el-table-column
            label="图片缩略图"
            width="180"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-image
                fit="contain"
                style="width: 80px; height: 80px"
                v-if="scope.row.goodsImages && scope.row.goodsImages[0] != ''"
                :src="scope.row.goodsImages[0]"
                :preview-src-list="[scope.row.goodsImages]"
              >
                <div
                  slot="error"
                  class="el-image__error"
                  style="width: 80px; height: 80px; font-size: 10px"
                >
                  无图无真相
                </div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column label="售价/原价" min-width="150">
            <template slot-scope="scope">
              {{ scope.row.price }} / {{ scope.row.compareAtPrice }}
            </template>
          </el-table-column>
          <el-table-column prop="inventoryQuantity" label="库存" width="100"></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="deleteSelectedGood(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table> -->

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                <el-button @click="dialogAdd = false">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog fullscreen title="捆绑数据明细" :visible.sync="dialogDetail" :close-on-click-modal="false" @closed="resetDialogData">
            <!-- <p>满减活动数据自2022年12月17日开始统计</p> -->
            <el-form :inline="true" :model="detailForm" class="demo-form-inline">
                <el-form-item>
                    <!--  :picker-options="pickerOptionsA" -->
                    <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="detailDate" :default-value="new Date()" value-format="yyyy-MM-dd" :clearable="false" :picker-options="pickerOptionsA"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="searchDetail">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" icon="el-icon-refresh-left" @click="resetDetail">重置</el-button>
                </el-form-item>
            </el-form>
            <section class="detailitems between">
                <div class="item">
                    <div class="tit">订单量</div>
                    <div class="val">{{detailData.orderNum}}单</div>
                </div>
                <div class="item">
                    <div class="tit">优惠金额</div>
                    <div class="val">${{detailData.discountBindPrice}}</div>
                </div>
                <div class="item">
                    <div class="tit">订单金额</div>
                    <div class="val">${{detailData.totalPrice}}</div>
                </div>
                <div class="item">
                    <div class="tit">平均客单价<el-tooltip effect="dark" content="总成交额/总订单量" placement="top"><i class="el-icon-question"></i></el-tooltip>
                    </div>
                    <div class="val">${{detailData.customerAvgPrice}}</div>
                </div>
                <div class="item">
                    <div class="tit">连带率<el-tooltip effect="dark" content="平均每笔订单的商品数量，所有已支付的订单的商品数量/所有已支付的订单的数量" placement="top"><i class="el-icon-question"></i></el-tooltip>
                    </div>
                    <div class="val">{{detailData.relatedRate}}</div>
                </div>
            </section>

            <!-- 数据表 -->
            <el-table border stripe :data="detailTable" v-loading="detailLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" max-height="600">
                <el-table-column prop="campaignName" label="活动名称"></el-table-column>
                <el-table-column prop="type" label="活动类型">
                    <template #default="{row}">
                        {{ getDiscountType(row.discountType) }}
                    </template>
                </el-table-column>
                <el-table-column prop="orderNum" label="订单量"></el-table-column>
                <el-table-column prop="discountBindPrice" label="优惠金额($)"></el-table-column>
                <el-table-column prop="totalPrice" label="订单金额($)"></el-table-column>
                <el-table-column prop="customerAvgPrice" label="客单价($)"></el-table-column>
                <el-table-column prop="relatedRate" label="连带率"></el-table-column>
            </el-table>
            <el-pagination background :current-page.sync="detailForm.pageNo" :page-sizes="[20, 50, 100]" :page-size.sync="detailForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="detailTotal" @size-change="getDetail" @current-change="getDetail">
            </el-pagination>
        </el-dialog>

        <!-- 选择商品弹窗 -->
        <!-- <el-dialog
      top="3%"
      title="商品列表"
      width="850px"
      :visible.sync="dialogShowgoods"
      :close-on-click-modal="false">

      <el-form size="medium" :inline="true" :model="goodsQuery">
        <el-form-item>
          <el-input
            v-model="goodsQuery.goodsTitle"
            placeholder="商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="goodsSearch()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>

      <el-table
        ref="multipleTable"
        :data="goodsData"
        v-loading="goodsloading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        tooltip-effect="dark"
        style="width: 100%"
        @select="handleSelect"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          label="缩略图"
          width="80"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              fit="contain"
              style="width: 80px; height: 80px"
              v-if="scope.row.goodsImages && scope.row.goodsImages[0] != ''"
              :src="scope.row.goodsImages[0]"
              :preview-src-list="[scope.row.goodsImages]"
            >
              <div
                slot="error"
                class="el-image__error"
                style="width: 80px; height: 80px; font-size: 10px"
              >
                无图无真相
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="goodsTitle" label="标题" width="250px"> </el-table-column>
        <el-table-column label="售价/原价" width="150px">
          <template slot-scope="scope">
            {{ scope.row.price }} / {{ scope.row.compareAtPrice }}
          </template>
        </el-table-column>
        <el-table-column prop="inventoryQuantity" label="库存" width="50px"> </el-table-column>
        <el-table-column prop="goodsRemark" label="备注"> </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="goodsQuery.pageNo"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size.sync="goodsQuery.pageSize"
        :total="goodsTotal"
        @size-change="changeGoods"
        @current-change="changeGoods"
        @prev-click="changeGoods"
        @next-click="changeGoods"
      >
      </el-pagination>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogShowgoods = false">取消</el-button>
        <el-button type="primary" @click="saveGoods">确定</el-button>
      </div>
    </el-dialog> -->

        <!-- 选择商品 -->
        <SelectGoods ref="selectGoods" :showDialog="dialogSelectGoods" :containItem="false" :selectedGoods="selectedGoodsTable.data" @closeDialog="closeSelectGoods" @saveSelectedGoods="saveSelectGoods">
        </SelectGoods>

    </div>

</template>

<script>
import SelectGoods from '@/components/SelectGoods';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
export default {
    name: 'CampaignBind',
    components: {
        SelectGoods: SelectGoods,
        GoodsImageTemplate,
    },
    data() {
        return {
            // 选择商品参数
            dialogSelectGoods: false,
            // 选中的商品列表数据
            selectedGoodsTable: {
                data: [],
                total: 0,
            },
            dialogShowgoods: false,
            goodsQuery: {
                goodsTitle: null,
                pageNo: 1,
                pageSize: 10,
            },
            goodsloading: false,
            goodsData: [],
            goodsTotal: 0,
            multipleSelection: [],
            selectedGoods: [],
            campaignStatus: '0',
            queryForm: {
                campaignName: null,
                campaignStatus: '0',
                spu: null,
                pageNo: 1,
                pageSize: 20,
            },
            tableData: [],
            total: 0,
            loading: false,
            campaignStatusTypes: [
                { name: '未开始', value: '1' },
                { name: '进行中', value: '2' },
                { name: '已结束', value: '3' },
            ],
            discountTypeOption: [
                {
                    value: 1,
                    name: '直接定价',
                },
                {
                    value: 2,
                    name: '固定减价',
                },
                {
                    value: 3,
                    name: '打折',
                },
                {
                    value: 4,
                    name: '无折扣',
                },
            ],
            distype: 'USD',
            addCampaignFullEndTime: false, // 不限结束时间
            isAdding: false,
            dialogAdd: false,
            dialogAddForm: {
                campaignName: null,
                discountNum: null,
                discountType: null,
                discountUnit: 'USD',
                startTime: null,
                endTime: null,
                goodsIds: [],
            },
            dialogAddRules: {
                campaignName: [
                    {
                        required: true,
                        message: '请输入活动名称',
                        trigger: 'blur',
                    },
                ],
                startTime: [
                    {
                        required: true,
                        message: '请选择开始时间 ',
                        trigger: 'blur',
                    },
                ],
                discountNum: [
                    {
                        required: true,
                        message: '请输入discountNum',
                        trigger: 'blur',
                    },
                ],
                // discountUnit: [
                //   { required: true, message: "请输入优惠单位", trigger: "blur" },
                // ],
                discountType: [
                    {
                        required: true,
                        message: '请选择优惠类型',
                        trigger: 'change',
                    },
                ],
            },
            addLoading: false,

            dialogDetail: false,
            detailForm: {
                pageNo: 1,
                pageSize: 20,
                startTime: null,
                endTime: null,
                // campaignName: null,
                // campaignStatus: null,
            },
            detailDate: [],
            pickerOptionsA: {
                disabledDate: (time) => {
                    // var lasttime = Date.parse('2022-12-29');
                    return time.getTime() > Date.now();
                },
            },
            detailData: {
                orderSuccessNum: '',
                discountPrice: '',
                total: '',
                customerAvgPrice: '',
                relatedRate: '',
            },
            detailTable: [],
            detailTotal: 0,
            detailLoading: false,
        };
    },
    computed: {
        getDiscountType() {
            return function (typeIndex) {
                let types = [, '直接定价', '固定减价', '打折', '无折扣'];
                return types[typeIndex] || '未知';
            };
        },
    },
    mounted() {
        this.queryData();
    },
    methods: {
        getToday() {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }
            let result = year + '-' + month + '-' + day;
            this.detailForm.startTime = result;
            this.detailForm.endTime = result;
            this.detailDate = [result, result];
        },

        bridge_getStaticTotal() {
            this.getToday();
            this.getStaticTotal();
        },
        getStaticTotal() {
            this.detailForm.startTime = this.detailDate?.[0] || '';
            this.detailForm.endTime = this.detailDate?.[1] || '';
            // this.detailForm.campaignName = this.queryForm.campaignName;
            // this.detailForm.campaignStatus = this.queryForm.campaignStatus;

            this.$http
                .get('/campaign/bind/getBindStatistics', {
                    params: this.detailForm,
                })
                .then((r) => {
                    if (r.data.code === 200) {
                        let item = r.data.data;
                        this.detailData = item;
                        this.dialogDetail = true;
                        this.getDetail();
                    }
                })
                .finally(() => {
                    this.detailLoading = false;
                });
        },

        async getDetail() {
            this.dialogDetail = true;
            this.detailForm.startTime = this.detailDate?.[0] || '';
            this.detailForm.endTime = this.detailDate?.[1] || '';
            this.detailLoading = true;
            await this.$http
                .get('/campaign/bind/getBindOrderPage', {
                    params: this.detailForm,
                })
                .then((r) => {
                    if (r.data.code === 200) {
                        this.detailTable = r.data.data;
                        this.detailTotal = r.data.count;
                    }
                })
                .finally(() => {
                    this.detailLoading = false;
                });
        },

        resetDialogData() {
            Object.assign(
                this.detailForm,
                this.$options.data.call(this).detailForm
            );
            Object.assign(
                this.detailData,
                this.$options.data.call(this).detailData
            );
        },

        searchDetail() {
            this.detailForm.pageNo = 1;
            this.getStaticTotal();
        },
        resetDetail() {
            this.detailForm.pageNo = 1;
            // this.detailDate = [];
            // this.detailForm.startTime = null;
            // this.detailForm.endTime = null;
            this.getToday();
            this.searchDetail();
        },

        // 选择商品方法
        openSelectGoods() {
            this.$refs.selectGoods.caller = 'notset';
            this.$refs.selectGoods.init();
            this.dialogSelectGoods = true;
        },
        closeSelectGoods() {
            this.dialogSelectGoods = false;
        },
        saveSelectGoods(items, caller) {
            switch (caller) {
                default:
                    this.selectedGoodsTable.data = items;
                    this.selectedGoodsTable.total =
                        this.selectedGoodsTable.data.length;
            }
        },
        // 删除选中的商品（删除）
        deleteSelectedGoods(item) {
            let index = this.selectedGoodsTable.data.indexOf(item);

            if (index > -1) {
                this.selectedGoodsTable.data.splice(index, 1);
                this.selectedGoodsTable.total =
                    this.selectedGoodsTable.data.length;
            }
        },
        // handleSelect(list, item){
        //   // console.log(list, item)
        //   if(list.includes(item)){
        //     // console.log('has')
        //   } else {
        //     // console.log('cancle')
        //     let id = item.id
        //     for(let i = 0; i < this.dialogAddForm.goodsIds.length; i++){
        //       if(id == this.dialogAddForm.goodsIds[i]){
        //         this.dialogAddForm.goodsIds.splice(i,1)
        //       }
        //     }
        //   }
        // },
        // handleSelectionChange(val) {
        //   this.multipleSelection = val
        // },
        changeDiscountType(e) {
            console.log(e);
            if (e == 4) {
                this.dialogAddForm.discountNum = null;
            }
            switch (e) {
                case 1:
                    this.dialogAddForm.discountUnit = 'USD';
                    break;
                case 2:
                    this.dialogAddForm.discountUnit = 'USD';
                    break;
                case 3:
                    this.dialogAddForm.discountUnit = '%';
                    break;
                case 4:
                    this.dialogAddForm.discountUnit = '';
                    break;
            }
        },
        querySearch() {
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        // 获取列表数据
        queryData() {
            if (this.campaignStatus == '0')
                this.queryForm.campaignStatus = null;
            else this.queryForm.campaignStatus = this.campaignStatus;

            this.loading = true;
            this.$http
                .get('/campaign/bind/getPage', { params: this.queryForm })
                .then((r) => {
                    this.loading = false;
                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        this.total = r.data.count;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    this.loading = false;
                });
        },
        resetCondition() {
            this.queryForm.campaignName = null;
            this.queryForm.campaignStatus = null;
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        // chooseItem(){
        //   this.goodsQuery.goodsTitle = null
        //   this.goodsQuery.pageNo = 1
        //   this.dialogShowgoods = true
        //   this.goodsQueryData()
        // },
        // goodsSearch(){
        //   this.goodsQuery.pageNo = 1
        //   this.goodsQueryData()
        // },
        // goodsQueryData(){
        //   this.goodsloading = true;
        //   this.$http
        //     .get("/goods/getPage", { params: this.goodsQuery })
        //     .then((r) => {
        //       this.goodsloading = false;
        //       if (r.data.code === 200) {
        //         this.goodsData = r.data.data;
        //         this.goodsTotal = r.data.count;

        //         this.$nextTick(function() {
        //           this.goodsData.forEach(row => {
        //             this.dialogAddForm.goodsIds.forEach(item => {
        //               if (row.id == item) {
        //                 // console.log(row)
        //                 this.$refs.multipleTable.toggleRowSelection(row, true);
        //               }
        //             })
        //           })
        //         })

        //         // console.log(this.dialogAddForm.goodsIds)

        //       }
        //     })
        //     .catch((err) => {
        //       this.goodsloading = false;
        //       // this.$message.error(error);;
        //     });
        // },
        // changeGoods(){
        //   console.log(this.multipleSelection)
        //   for(let i = 0; i < this.multipleSelection.length; i++){
        //     this.dialogAddForm.goodsIds.push(this.multipleSelection[i].id)
        //     this.selectedGoods.push(this.multipleSelection[i])
        //   }
        //   this.dialogAddForm.goodsIds = [...new Set(this.dialogAddForm.goodsIds)];
        //   let obj = {}
        //   this.selectedGoods = this.selectedGoods.reduce(function(a, b) {
        //     obj[b.id] ? '' : obj[b.id] = true && a.push(b);
        //     return a;
        //   }, [])
        //   this.goodsQueryData()
        // },
        // saveGoods(){
        //   for(let i = 0; i < this.multipleSelection.length; i++){
        //     this.dialogAddForm.goodsIds.push(this.multipleSelection[i].id)
        //     this.selectedGoods.push(this.multipleSelection[i])
        //   }
        //   this.dialogAddForm.goodsIds = [...new Set(this.dialogAddForm.goodsIds)];
        //   let obj = {}
        //   this.selectedGoods = this.selectedGoods.reduce(function(a, b) {
        //     obj[b.id] ? '' : obj[b.id] = true && a.push(b);
        //     return a;
        //   }, [])
        //   this.dialogShowgoods = false
        // },
        // deleteSelectedGood(id){
        //   console.log(id)
        //   for(let i = 0; i < this.selectedGoods.length; i++){
        //     if(id == this.selectedGoods[i].id){
        //       this.selectedGoods.splice(i,1)
        //     }
        //   }
        //   for(let i = 0; i < this.dialogAddForm.goodsIds.length; i++){
        //     if(id == this.dialogAddForm.goodsIds[i]){
        //       this.dialogAddForm.goodsIds.splice(i,1)
        //     }
        //   }
        // },
        openAdd() {
            this.dialogAddForm = {
                campaignName: null,
                discountNum: null,
                discountType: null,
                discountUnit: 'USD',
                startTime: null,
                endTime: null,
                goodsIds: [],
            };
            this.addCampaignFullEndTime = false;
            this.selectedGoods = [];
            this.selectedGoodsTable.data = []; // 选中的商品
            this.selectedGoodsTable.total = 0;
            this.isAdding = true;
            this.addLoading = false;
            this.dialogAdd = true;
        },
        openEdit(id) {
            this.loading = true;
            this.$http
                .get('/campaign/bind/getDetail?id=' + id)
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data;
                        this.dialogAddForm = {
                            id: id,
                            campaignName: data.campaignName,
                            discountNum: data.discountNum,
                            discountType: data.discountType,
                            discountUnit: data.discountUnit,
                            startTime: data.startTime,
                            endTime: data.endTime,
                            goodsIds: data.goodsList.map((item) => item.id),
                        };
                        this.addCampaignFullEndTime = data.endTime
                            ? false
                            : true;
                        this.selectedGoods = JSON.parse(
                            JSON.stringify(data.goodsList)
                        );
                        // console.log(this.dialogAddForm)
                        this.multipleSelection = data.goodsList.map(
                            (item) => item.id
                        );
                        // console.log(this.multipleSelection)
                        this.selectedGoodsTable.data = JSON.parse(
                            JSON.stringify(data.goodsList)
                        ); // 选中的商品
                        this.selectedGoodsTable.total = data.goodsList.length;
                        this.isAdding = false;
                        this.addLoading = false;
                        this.dialogAdd = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addData() {
            let content = this.isAdding == true ? '新增' : '编辑';
            this.$confirm(`是否${content}活动？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.addLoading = true;
                let url = this.dialogAddForm.id
                    ? '/campaign/bind/edit'
                    : '/campaign/bind/add';
                this.dialogAddForm.goodsIds = [];
                for (let i = 0; i < this.selectedGoodsTable.data.length; i++) {
                    this.dialogAddForm.goodsIds.push(
                        this.selectedGoodsTable.data[i].id
                    );
                }
                this.$http
                    .post(url, this.dialogAddForm)
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message.success(`${content}成功`);
                            this.queryData();
                            this.dialogAdd = false;
                        }
                    })
                    .finally(() => {
                        this.addLoading = false;
                    });
            });
        },
        endThis(id) {
            this.$confirm('是否结束活动？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .get('/campaign/bind/end?id=' + id)
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message.success('结束成功');
                            this.queryData();
                        }
                    });
            });
        },
        deleteThis(id) {
            this.$confirm('是否删除活动？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .delete('/campaign/bind/delete?id=' + id)
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message.success('删除成功');
                            this.queryData();
                        }
                    });
            });
        },
        getPublished(status) {
            switch (status) {
                case 0:
                    return '否';
                case 1:
                    return '是';
            }
        },
    },
};
</script>

<style scoped>
.detailitems {
    margin: 40px 0;
}
.detailitems .item {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    width: calc(100% / 5 - 20px);
    box-sizing: border-box;
}
.detailitems .item .tit {
    font-size: 18px;
}
.detailitems .item .val {
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
}

.addCampaignFullSection {
    margin-bottom: 16px;
    padding: 16px 32px;
    background-color: #fff;
}

.addCampaignFullLegend {
    font-size: 20px;
    font-weight: bold;
}

/* 适用范围卡片 */
.addCampaignFullCard {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    box-sizing: border-box;
    border: solid 1px #ecedf3;
    border-radius: 6px;
    width: 200px;
    height: 100px;
    cursor: pointer;
    overflow: hidden;
}

.addCampaignFullCard > h4 {
    margin: 5px 16px;
}

.addCampaignFullCard > p {
    margin: 0 16px;
    color: #999;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
}

.addCampaignFullSelectedCard {
    border: solid 2px #2d67f3;
    background: #f3f7ff;
}

.addCampaignFullCardChecked {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 50px;
    height: 50px;
    background-color: #2d67f3;
    transform: rotate(45deg);
}

.addCampaignFullCardChecked > i {
    position: absolute;
    top: 31px;
    left: 16px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    transform: rotate(-45deg);
}

/* Banner图片 */
.BannerImageBox {
    margin-right: 20px;
    margin-bottom: 20px;
    width: 320px !important;
    height: 176px !important;
    background-color: #f5f6f8;
}

.BannerImage {
    max-width: 320px;
    max-height: 176px;
}

.BannerImageBox > .mask_d5 {
    display: none;
}

.BannerImageBox:hover > .mask_d5 {
    display: block;
}

/* 方案设置项 */
.DemoBox {
    display: flex;
    align-items: center;
    margin: 16px 0;
    padding: 0 24px;
    height: 80px;
}

.DemoBox > p {
    font-size: 18px;
}

.DemoBox_Time {
    border-radius: 6px;
    padding: 4px 10px;
    font-size: 20px;
}

.DemoColorRow {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.DemoColorTitle {
    width: 300px;
}
</style>
  <style>
#addCampaignFullDialog > .el-dialog,
#editCampaignFullDialog > .el-dialog {
    background: #ecedf3;
}
</style>