import Vue from "vue";
import store from "../store";

//密码加密
Vue.prototype.$encodePassword = (password) => {
  let encodePassword = "";
  if (password != null && password !== "") {
    for (let i = 0, len = password.length; i < len; i++) {
      encodePassword = encodePassword + (password.charCodeAt(i) ^ 0x01) + "O";
    }
    encodePassword = encodePassword.substr(0, encodePassword.length - 1);
  }
  return encodePassword;
};

//密码解密
Vue.prototype.$decodePassword = (password) => {
  let decodePassword = "";
  if (password != null && password !== "") {
    let arrData = password.split("O");
    for (let i = 0, len = arrData.length; i < len; i++) {
      decodePassword = decodePassword + String.fromCodePoint(arrData[i] ^ 0x01);
    }
  }
  return decodePassword;
};

//权限认证
Vue.prototype.$auth = (code) => {
  let userAuth = store.state.userAuth;
  if (userAuth && userAuth.roleId !== 1) {
    let privileges = userAuth.privileges;
    return privileges && privileges.indexOf(code) >= 0;
  }
  return true;
};

//权限数据
Vue.prototype.$authData = {
  system: {
    //查询用户权限
    userGet: 100,
    //添加用户权限
    userAdd: 101,
    //编辑用户权限
    userEdit: 102,
    //编辑用户密码权限
    userEditPassword: 103,
    //删除用户权限
    userDelete: 104,
    //查询角色权限
    roleGet: 200,
    //添加角色权限
    roleAdd: 201,
    //编辑角色权限
    roleEdit: 202,
    //删除角色权限
    roleDelete: 203,
    //查询字典权限
    dictGet: 300,
    //添加字典权限
    dictAdd: 301,
    //编辑字典权限
    dictEdit: 302,
    //查询日志权限
    logGet: 400,
    //查询告警日志权限
    alarmLogGet: 2800,
  },
  goods: {
    //添加商品分类
    categoryAdd: 2201,
    // 分页查询商品分类
    categoryGetPage: 2200,
    //添加商品款式
    goodsTypeAdd: 2401,
    //编辑商品款式
    goodsTypeEdit: 2402,
    //分页查询商品款式
    goodsTypegetPage: 2400,
  },
  campaign: {
    //添加捆绑活动
    bingAdd: 2301,
    //删除捆绑活动
    bingDelete: 2304,
    //编辑捆绑活动
    bingEdit: 2302,
    //结束捆绑活动
    bingEnd: 2303,
    //查询捆绑活动详情
    bingGetDetail: 2300,
    //分页查询捆绑活动
    bingGetPage: 2300,
    //删除落地页规则
    addelete: 2103,
    //修改落地页规则
    adedit: 2102,
  },
};

/* get查询接口获取数据 2021.12.21.001 */
Vue.prototype.$getData = function( objName, api, ...args ) {

	return new Promise( ( resolve, reject ) => {

		let dataPath = [ "data" ], // 数据路径，相对于response.data，默认为data
			params = null, // 接口参数
			callback = null; // 请求成功后的回调函数

		args.forEach( arg => {

			if ( arg === null ) return;

			switch ( typeof( arg ) ) {

				case "string":
					dataPath = arg.split( "." );
					break;

				case "function":
					callback = arg;
					break;

				case "object":
					params = arg;
					break;

			}

		} );

		this.$http.get( api, params )
		.then( r => {

			if ( r.data.code === 200 ) {

				// 获取数据
				let returnData = r.data;
				while ( dataPath.length ) returnData = returnData[ dataPath.shift() ];

				// 数据对象
				if ( objName ) {

					let objPath = objName.split( "." ),
						obj = this;

					while ( objPath.length > 1 ) obj = obj[ objPath.shift() ];
					obj[ objPath[ 0 ] ] = returnData;

				}

				callback && callback( returnData );
				resolve( returnData );

			} else reject( `request over. errCode: ${ r.data.code }, obj: ${ objName }.` );

		} )
		.catch( () => reject( `request fail. get ${ api } error. obj: ${ objName }` ) );

	} );

};
/* get查询接口获取数据 end */

/* 获取字典数据 2021.12.21.001 */
Vue.prototype.$getDictionary = function( objName, code, callback = null ) {

	return this.$getData( objName, `/system/dict/getChildren?code=${ code }`, callback );

};
/* 获取字典数据 end */

/* 格式化数字 Randy 2021-09-18
	eg: $yrFormatNumber( 1234567, ",", 2 ) -> 1,234,567.00
	参数说明:
	value: 数值
	separator: 千分位分隔符，默认值：null
	digitNum: 小数位数，默认值：null
*/
Vue.prototype.$yrFormatNumber = (value, separator, digitNum) => {
  let sign = "";

  if (value < 0) {
    sign = "-";
    value = Math.abs(value);
  }

  let initV = value;

  if (
    (value = (value = value + "").replace(/^\s*|\s*$|,*/g, "")).match(
      /^\d*\.?\d*$/
    ) == null
  )
    return sign + initV;

  value = digitNum >= 0 ? Number(value).toFixed(digitNum) + "" : value;

  let r = [],
    tl = value.split(".")[0],
    tr = value.split(".")[1];

  tr = typeof tr != "undefined" ? tr : "";

  if (separator != null && separator != "") {
    while (tl.length >= 3) {
      r.push(tl.substring(tl.length - 3));
      tl = tl.substring(0, tl.length - 3);
    }

    if (tl.length > 0) r.push(tl);
    r.reverse();
    r = r.join(separator);
    return tr == "" ? sign + r : sign + r + "." + tr;
  }

  return sign + value;
};
