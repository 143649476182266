<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>店铺管理</el-breadcrumb-item>
            <el-breadcrumb-item>Banner设置</el-breadcrumb-item>
        </el-breadcrumb>

        <section>
            <el-form ref="form" label-width="120px">
                <el-form-item label="开启自动轮播">
                    <el-switch v-model="loop"></el-switch>
                </el-form-item>
                <el-form-item label="间隔时间(秒)" v-if="loop" style="width:500px;">
                    <!-- <el-input-number :min="0" :precision="0"
					placeholder="请输入间隔时间(毫秒)"
					autocomplete="off"
					@change="changesecond"
					v-model="second"></el-input-number>
					(单位毫秒, 1秒 = 1000毫秒) -->
                    <el-slider v-model="second" :min="1" :max="10"></el-slider>
                    <!-- @change="changesecond" -->
                </el-form-item>
            </el-form>
        </section>

        <section style="margin-bottom: 20px;">
            <el-button type="success" icon="el-icon-plus" @click="openAdd()">新增</el-button>
            <el-button type="primary" @click="savesInfo">保存</el-button>
        </section>

        <section>
            <div class="mod">
                <vuedraggable class="grid" v-model="tableData">
                    <div class="itembox" v-for="(item, index) in tableData" :key="index">
                        <div class="tits between-center">
                            <p>{{ item.name }}</p>
                            <div>
                                <el-button type="primary" size="mini" icon="el-icon-edit-outline" circle @click="editThisB(item.id)"></el-button>
                                <el-button type="danger" size="mini" icon="el-icon-delete" circle @click="deleteThis(item.id)"></el-button>
                            </div>
                        </div>
                        <div class="img imgbox">
                            <img :src="item.pcImage">
                        </div>
                    </div>
                </vuedraggable>
            </div>
        </section>

        <el-pagination background :current-page.sync="queryForm.pageNo" :page-sizes="[20, 50, 100]" :page-size.sync="queryForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-dialog top="3%" :title="dialogAddForm.id ? '编辑Banner' : '新增Banner'" :visible.sync="dialogAdd" width="500px" :close-on-click-modal="false">
            <el-form :model="dialogAddForm" :rules="dialogRules" status-icon ref="dialogAddForm" label-position="top" size="medium">
                <el-form-item label="名称" prop="name">
                    <el-input placeholder="请输入名称" autocomplete="off" v-model="dialogAddForm.name"></el-input>
                </el-form-item>

                <el-form-item label="PC端图片">
                    <el-upload class="avatar-uploader" action="#" list-type="picture-card" :http-request="avatarUpload" :show-file-list="false">
                        <img v-if="dialogAddForm.pcImage" :src="dialogAddForm.pcImage" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>

                <el-form-item label="H5端图片">
                    <el-upload class="avatar-uploader" action="#" list-type="picture-card" :http-request="avatarUploadB" :show-file-list="false">
                        <img v-if="dialogAddForm.mpImage" :src="dialogAddForm.mpImage" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>

                <el-form-item label="跳转链接">
                    <el-input placeholder="请输入跳转链接" autocomplete="off" v-model="dialogAddForm.link"></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                <el-button @click="dialogAdd = false">取消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import vuedraggable from 'vuedraggable';
export default {
    name: 'AdPixel',
    components: {
        vuedraggable,
    },
    data() {
        return {
            queryForm: {
                pageNo: 1,
                pageSize: 20,
            },
            total: 0,
            loop: true,
            second: 0,
            tableData: [],
            dialogAdd: false,
            addLoading: false,
            dialogAddForm: {
                name: null,
                pcImage: null,
                mpImage: null,
                link: null,
            },
            dialogRules: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                pcImage: [
                    {
                        required: true,
                        message: '请选择PC端图片',
                        trigger: 'blur',
                    },
                ],
                mpImage: [
                    {
                        required: true,
                        message: '请选择H5端图片',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    mounted() {
        this.queryData();
        this.queryConfig();
    },
    methods: {
        savesInfo() {
            let url = '/setting/banner/editConfig',
                json = {
                    configEdit: [
                        { code: 'banner_carousel_time', value: this.second },
                        {
                            code: 'banner_auto_carousel_enable',
                            value: +this.loop,
                        },
                    ],
                    ids: this.tableData.map((item) => item.id),
                };

            this.$http.post(url, json).then((response) => {
                if (response.data.code === 200) {
                    this.$message({
                        message: '保存成功~',
                        type: 'success',
                        duration: 1000,
                    });
                    this.queryData();
                }
            });
        },
        openAdd() {
            this.dialogAddForm = {
                name: null,
                pcImage: null,
                mpImage: null,
                link: null,
            };
            this.addLoading = false;
            this.dialogAdd = true;
        },
        editThisB(id) {
            this.dialogAdd = true;
            let len = this.tableData.length;
            let json = {};
            for (let i = 0; i < len; i++) {
                if (this.tableData[i].id == id) {
                    json = this.tableData[i];
                    break;
                }
            }
            this.dialogAddForm = {
                id: json.id,
                name: json.name,
                pcImage: json.pcImage,
                mpImage: json.mpImage,
                link: json.link,
            };
        },
        editThis(id) {
            this.loading = true;
            this.$http
                .get('/setting/banner/getList', { params: { id: id } })
                .then((response) => {
                    if (response.data.code === 200) {
                        console.log(response.data.data[0]);
                        let data = response.data.data[0];
                        this.dialogAddForm = {
                            id: data.id,
                            name: data.name,
                            pcImage: data.pcImage,
                            mpImage: data.mpImage,
                            link: data.link,
                        };
                        this.addLoading = false;
                        this.dialogAdd = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addData() {
            this.$refs['dialogAddForm'].validate((valid) => {
                if (valid) {
                    let url;
                    if (this.dialogAddForm.id) {
                        url = '/setting/banner/edit';
                    } else {
                        url = '/setting/banner/add';
                    }

                    if (!this.dialogAddForm.pcImage) {
                        this.$message.warning('请上传PC端图片。');
                        return false;
                    }
                    if (!this.dialogAddForm.mpImage) {
                        this.$message.warning('请上传H5端图片。');
                        return false;
                    }

                    this.$http
                        .post(url, this.dialogAddForm)
                        .then((response) => {
                            this.addLoading = false;
                            if (response.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message({
                                    message: this.dialogAddForm.id
                                        ? '编辑成功'
                                        : '新增成功',
                                    type: 'success',
                                    duration: 1000,
                                });
                                this.queryData();
                            }
                        })
                        .catch((err) => {
                            this.addLoading = false;
                        });
                }
            });
        },
        deleteThis(id) {
            this.$confirm('是否删除Banner？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .delete('/setting/banner/delete?id=' + id)
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message.success('删除成功');
                            this.queryData();
                        }
                    });
            });
        },
        queryData() {
            this.loading = true;
            this.$http
                .get('/setting/banner/getPage')
                .then((r) => {
                    this.loading = false;
                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        this.total = r.data.count;
                    }
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        queryConfig() {
            this.$http
                .get(
                    '/setting/banner/getConfigByCode?code=banner_auto_carousel_enable'
                )
                .then((r) => {
                    this.loading = false;
                    if (r.data.code === 200) {
                        // console.log(r.data.data)
                        if (r.data.data.value == 1) {
                            this.loop = true;
                        } else {
                            this.loop = false;
                        }
                    }
                });
            this.$http
                .get(
                    '/setting/banner/getConfigByCode?code=banner_carousel_time'
                )
                .then((r) => {
                    this.loading = false;
                    if (r.data.code === 200) {
                        // console.log(r.data.data)
                        this.second = Number(r.data.data.value);
                    }
                });
        },
        avatarUpload(params) {
            if (params) {
                let file = params.file;

                const isJPG =
                    file.type === 'image/jpeg' ||
                    file.type === 'image/png' ||
                    file.type === 'image/jpg';
                const isLt2M = file.size / 1024 / 1024 < 1;
                if (!isJPG) {
                    this.$message.error('上传图片只能是jpg、png格式');
                    return;
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过1MB');
                    return;
                }
                let form = new FormData();
                form.append('fileType', '1');
                form.append('files', file);
                this.$http
                    .post('/common/file/upload', form)
                    .then((response) => {
                        if (response.data.code === 200) {
                            let data = response.data.data;
                            if (data) {
                                console.log(data[0].url);
                                this.dialogAddForm.pcImage = data[0].url;
                            }
                        }
                    });
            }
        },
        avatarUploadB(params) {
            if (params) {
                let file = params.file;

                const isJPG =
                    file.type === 'image/jpeg' ||
                    file.type === 'image/png' ||
                    file.type === 'image/jpg';
                const isLt2M = file.size / 1024 / 1024 < 1;
                if (!isJPG) {
                    this.$message.error('上传图片只能是jpg、png格式');
                    return;
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过1MB');
                    return;
                }
                let form = new FormData();
                form.append('fileType', '1');
                form.append('files', file);
                this.$http
                    .post('/common/file/upload', form)
                    .then((response) => {
                        if (response.data.code === 200) {
                            let data = response.data.data;
                            if (data) {
                                console.log(data[0].url);
                                this.dialogAddForm.mpImage = data[0].url;
                            }
                        }
                    });
            }
        },
    },
};
</script>

<style scoped>
.main-wrap {
    height: auto;
}
.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}
.itembox {
    border: 1px solid #dfdfdf;
    border-radius: 6px;
}
.itembox .tits {
    height: 40px;
    padding: 0 10px;
    border-bottom: 1px solid #dfdfdf;
}
.itembox .tits p {
    margin: 0;
}
.img {
    height: 200px;
    padding: 10px;
}
.avatar-uploader /deep/ .el-upload--picture-card {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>