<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>店铺管理</el-breadcrumb-item>
            <el-breadcrumb-item>参数配置</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="mod" style="padding-top:20px;">
            <el-form :model="form" :rules="dialogRules" status-icon class="addform" label-width="100px" ref="form">
                <el-form-item label="插入代码" prop="code">
                    <el-input v-model="form.value" placeholder="请输入代码" type="textarea" :rows="8"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'CustomCode',
    data() {
        return {
            addLoading: false,
            form: {
                code: '',
                value: '',
            },
            dialogRules: {
                code: [
                    { required: true, message: '请输入代码', trigger: 'blur' },
                ],
            },
        };
    },
    mounted() {
        this.openEdit();
    },
    methods: {
        openEdit() {
            this.loading = true;

            this.$http
                .get('/sys/config/getDetail')
                .then((response) => {
                    let {
                        code: state,
                        data: { code, value },
                    } = response.data;

                    if (state === 200) {
                        this.form = {
                            code,
                            value,
                        };
                    }
                })
                .finally(() => {
                    this.addLoading = false;
                });
        },
        addData() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let url = '/sys/config/edit';

                    this.$http
                        .post(url, this.form)
                        .then((response) => {
                            this.addLoading = false;
                            if (response.data.code === 200) {
                                this.$message({
                                    message: '编辑成功',
                                    type: 'success',
                                    duration: 1000,
                                });
                            }
                        })
                        .catch((err) => {
                            this.addLoading = false;
                        });
                }
            });
        },
    },
};
</script>

<style scoped>
</style>