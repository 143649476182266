<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>数据统计</el-breadcrumb-item>
            <el-breadcrumb-item>商品数据统计</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 表单查询 -->
        <!-- <el-tabs v-model="dimensionStatus" @tab-click="queryData">
      <el-tab-pane
        v-for="item in dimensionStatusTypes"
        :key="item.value"
        :label="item.name"
        :name="item.value"
      ></el-tab-pane>
    </el-tabs> -->
        <el-tabs v-model="queryForm.dimension">
            <el-tab-pane label="商品维度" name="1">
                <el-form :inline="true" :model="queryForm" class="demo-form-inline">
                    <el-form-item>
                        <el-input v-model="queryForm.goodsTitle" placeholder="商品标题" clearable></el-input>
                    </el-form-item>
                    <el-form-item style="width:150px;">
                        <el-input v-model="queryForm.spu" placeholder="SPU" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels :picker-options="pickerOptions" v-model="date" value-format="yyyy-MM-dd" :default-value="new Date()">
                            ></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <!-- <el-form-item>
                            <el-select
                              placeholder="排序方式"
                              clearable
                              v-model="queryForm.sort"
                            >
                              <el-option
                                v-for="item in sortOptions"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </el-form-item> -->
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" icon="el-icon-upload2" @click="outPut">导出</el-button>
                        </el-form-item>
                    </el-form-item>
                </el-form>
                <!-- 数据表 -->
                <el-table border stripe :data="tableData" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" max-height="650" :default-sort="option" @sort-change="changeSort($event, 'queryForm')">
                    <el-table-column min-width="160" prop="goodsTitle" label="商品标题" fixed="left">
                        <template #default="{ row }">
                            <div class="jump" @click="openView(row.seoUrl)" style="word-break: break-word ">
                                {{row.goodsTitle}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品图片" width="180" align="center" fixed="left">
                        <template slot-scope="scope">
                            <GoodsImageTemplate :images="scope.row.goodsImages"></GoodsImageTemplate>
                            <!-- <el-popover placement="right" trigger="hover" v-if="scope.row.goodsImages && scope.row.goodsImages.length > 0">
                                <el-image fit="contain" style="width: 300px; height: 300px" :src="scope.row.goodsImages[0]" :preview-src-list="scope.row.goodsImages">
                                    <div slot="error" class="el-image__error" style="width: 300px; height: 300px; font-size: 50px">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>


                                <el-image fit="contain" slot="reference" style="width: 80px; height: 80px" :src="scope.row.goodsImages[0]">
                                    <div slot="error" class="el-image__error" style="font-size: 20px">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </el-popover> -->
                            <!-- <span v-else>无</span> -->
                        </template>
                    </el-table-column>
                    <el-table-column prop="spu" label="spu" min-width="120" fixed="left"></el-table-column>
                    <el-table-column min-width="160" prop="conversionRate" label="转化率" sortable="1">
                    </el-table-column>
                    <!-- <el-table-column
            min-width="160"
            prop="orderNum"
            label="订单量"
            sortable="2"
          ></el-table-column> -->
                    <el-table-column min-width="160" prop="orderSuccessNum" label="下单成功数" sortable="2"></el-table-column>
                    <el-table-column min-width="160" prop="saleNum" label="销量" sortable="3"></el-table-column>
                    <el-table-column min-width="160" prop="saleTotal" label="销售总额" sortable="4"></el-table-column>
                    <el-table-column min-width="160" prop="saleNet" label="净销售额" sortable="5"></el-table-column>
                    <el-table-column min-width="160" prop="buyNum" label="加购数量" sortable="6"></el-table-column>
                    <el-table-column min-width="160" prop="buyUserNum" label="加购用户数" sortable="7"></el-table-column>
                    <el-table-column min-width="160" prop="buyPurchaseRate" label="加购率" sortable="8"></el-table-column>
                    <el-table-column min-width="160" prop="visitNum" label="访问数量" sortable="9"></el-table-column>
                    <el-table-column min-width="160" prop="visitUserNum" label="访问用户数量" sortable="10"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="100" header-align="center" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="text" icon="el-icon-detail" @click="getChart(scope.row.goodsId, scope.row.goodsItemCode)">详情</el-button>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
            min-width="160"
            prop="buyRate"
            label="加购占比"
            sortable="8"
          ></el-table-column> -->
                    <!-- <el-table-column min-width="160" prop="visitRate" label="访问占比" sortable="10">
          </el-table-column> -->
                </el-table>
                <el-pagination background :current-page.sync="queryForm.pageNo" :page-sizes="[100, 200, 300]" :page-size.sync="queryForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="queryData" @current-change="queryData">
                </el-pagination>
            </el-tab-pane>
            <el-tab-pane label="子商品维度" name="2">
                <el-form :inline="true" :model="subqueryForm" class="demo-form-inline">
                    <el-form-item>
                        <el-input v-model="subqueryForm.goodsTitle" placeholder="商品标题" clearable></el-input>
                    </el-form-item>
                    <el-form-item style="width:150px;">
                        <el-input v-model="subqueryForm.sku" placeholder="SKU" clearable></el-input>
                    </el-form-item>
                    <el-form-item style="width:150px;">
                        <el-input v-model="subqueryForm.spu" placeholder="SPU" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="subdate" value-format="yyyy-MM-dd"></el-date-picker>
                    </el-form-item>
                    <!-- <el-form-item>
            <el-select
              placeholder="排序方式"
              clearable
              v-model="subqueryForm.sort"
            >
              <el-option
                v-for="item in sortOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item> -->
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" @click="subqueryData">查询</el-button>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" icon="el-icon-upload2" @click="outPut">导出</el-button>
                    </el-form-item>
                </el-form>
                <!-- 数据表 -->
                <el-table border stripe :data="subtableData" v-loading="subloading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" max-height="650" :default-sort="{prop: 'saleNum', order: 'descending'}" @sort-change="changeSort($event, 'subqueryForm')">
                    <el-table-column min-width="160" prop="goodsTitle" label="商品标题" fixed="left">
                        <template #default="{ row }">
                            <div class="jump" @click="openView(row.seoUrl)" style="word-break: break-word ">
                                {{row.goodsTitle}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="子商品图片" width="180" align="center" fixed="left">
                        <template slot-scope="scope">
                            <GoodsImageTemplate :images="scope.row.image" :isOrderItems="true" :orderMainImage="scope.row.goodsImages"></GoodsImageTemplate>
                            <!-- <el-popover placement="right" trigger="hover" v-if="scope.row.image">
                                <el-image fit="contain" style="width: 300px; height: 300px" :src="scope.row.image">
                                    <div slot="error" class="el-image__error" style="width: 300px; height: 300px; font-size: 50px">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                                <el-image fit="contain" slot="reference" style="width: 80px; height: 80px" :src="scope.row.image">
                                    <div slot="error" class="el-image__error" style="font-size: 20px">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </el-popover>
                            <span v-else>无</span> -->
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
            min-width="160"
            prop="goodsItemCode"
            label="子商品编码"
            fixed="left"
          ></el-table-column> -->
                    <el-table-column min-width="160" prop="sku" label="sku" fixed="left"></el-table-column>
                    <el-table-column min-width="160" prop="spu" label="spu" fixed="left"></el-table-column>
                    <el-table-column min-width="160" prop="conversionRate" label="转化率" sortable="1"></el-table-column>
                    <!-- <el-table-column
            min-width="160"
            prop="orderNum"
            label="订单量"
            sortable="2"
          ></el-table-column> -->
                    <el-table-column min-width="160" prop="orderSuccessNum" label="下单成功数" sortable="2"></el-table-column>
                    <el-table-column min-width="160" prop="saleNum" label="销量" sortable="3"></el-table-column>
                    <el-table-column min-width="160" prop="saleTotal" label="销售总额" sortable="4"></el-table-column>
                    <el-table-column min-width="160" prop="saleNet" label="净销售额" sortable="5"></el-table-column>
                    <el-table-column min-width="160" prop="buyNum" label="加购数量" sortable="6"></el-table-column>
                    <el-table-column min-width="160" prop="buyUserNum" label="加购用户数" sortable="7"></el-table-column>
                    <el-table-column min-width="160" prop="buyPurchaseRate" label="加购率" sortable="8"></el-table-column>
                    <el-table-column min-width="160" prop="visitNum" label="访问数量" sortable="9"></el-table-column>
                    <el-table-column min-width="160" prop="visitUserNum" label="访问用户数量" sortable="10"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="100" header-align="center" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="text" icon="el-icon-detail" @click="getChart(scope.row.goodsId, scope.row.goodsItemCode)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background :current-page.sync="subqueryForm.pageNo" :page-sizes="[100, 200, 300]" :page-size.sync="queryForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="subtotal" @size-change="subqueryData" @current-change="subqueryData"></el-pagination>
            </el-tab-pane>
        </el-tabs>

        <el-dialog fullscreen title="商品数据详情" :visible.sync="dialogChart" :close-on-click-modal="false">
            <div class="rowbox">
                <div class="img" v-if="nowData.goodsImages">
                    <GoodsImageTemplate :images="nowData.goodsImages"></GoodsImageTemplate>
                </div>
                <div class="img" v-if="nowData.image">
                     <GoodsImageTemplate :images="nowData.image" :isOrderItems="true" :orderMainImage="nowData.goodsImages"></GoodsImageTemplate>
                </div>
                <div class="detail">
                    <div class="delrow"><span class="tit">商品标题:</span>{{ nowData.goodsTitle }}</div>
                    <div class="delrow" v-if="queryForm.dimension == 1"><span class="tit">SPU:</span>{{ nowData.spu }}</div>
                    <div class="delrow" v-else><span class="tit">SKU:</span>{{ nowData.sku }}</div>
                </div>
            </div>
            <div class="rowbox">
                <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :clearable="false" unlink-panels :picker-options="pickerOptions" v-model="chartdate" value-format="yyyy-MM-dd" :default-value="new Date()">></el-date-picker>
                <el-button type="primary" icon="el-icon-search" @click="getNowData()" style="margin-left: 10px;">查看</el-button>
            </div>
            <div id="StaticChart"></div>
        </el-dialog>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
// 引入基本模板
// let echarts = require('echarts/lib/echarts')
// 引入柱状图组件
require('echarts/lib/chart/bar');
// 引入提示框和title组件
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');
export default {
    name: 'StatisticGoods',
    components: {
        GoodsImageTemplate,
    },
    data() {
        return {
            dialogChart: false,
            chartdate: [],
            // dimensionStatusTypes:[
            //   {
            //     name: "商品维度",
            //     value: "1"
            //   },{
            //     name: "子商品维度",
            //     value: "2"
            //   }
            // ],
            date: null,
            queryForm: {
                dimension: '1',
                goodsTitle: null,
                startDate: null,
                endDate: null,
                pageNo: 1,
                pageSize: 100,
                ascSort: null,
                descSort: 5,
                sku: null,
                spu: null,
            },
            tableData: [],
            loading: false,
            total: 0,
            subdate: null,
            subqueryForm: {
                dimension: '2',
                goodsTitle: null,
                startDate: null,
                endDate: null,
                pageNo: 1,
                pageSize: 100,
                ascSort: null,
                descSort: 5,
                sku: null,
                spu: null,
            },
            subloading: false,
            subtableData: [],
            subtotal: 0,
            sort: null,
            option: { prop: 'saleNum', order: 'descending' },
            sortOptions: [
                { name: 'conversionRate', value: 8 },
                { name: 'orderSuccessNum', value: 4 },
                { name: 'saleNum', value: 5 },
                { name: 'saleTotal', value: 6 },
                { name: 'saleNet', value: 7 },
                { name: 'buyNum', value: 9 },
                { name: 'buyUserNum', value: 10 },
                { name: 'buyPurchaseRate', value: 11 },
                { name: 'visitNum', value: 1 },
                { name: 'visitUserNum', value: 2 },
            ],
            pickerOptions: {
                disabledDate: (time) => {
                    return time.getTime() > Date.now();
                },
            },
            nowData: {},
            nowSelected: {},
        };
    },
    created() {
        let sort = this.$route.query.sort;
        if (sort) {
            this.queryForm.descSort = sort;
            for (let i = 0; i < this.sortOptions.length; i++) {
                let item = this.sortOptions[i];
                if (item.value == sort) {
                    this.option.prop = item.name;
                }
            }
        }
    },
    mounted() {
        let query = this.$route.query;
        if (query.date) {
            this.date = query.date;
            this.subdate = query.date;
        } else {
            this.date = this.timeDefault();
            this.subdate = this.timeDefault();
        }

        this.queryData();
        this.subqueryData();
    },
    methods: {
        async getDetail(type, params) {
            await this.$http
                .get('/statistic/goods/getDetail', { params })
                .then(async (response) => {
                    if (response.data.code == 200) {
                        let data = response.data.data;
                        this.nowData = data;
                        // console.log(data)

                        let statics = data.shopGoodsStatistics;
                        let len = statics.length;
                        let statisticDate = [],
                            buyNum = [],
                            buyPurchaseRate = [],
                            buyUserNum = [],
                            conversionRate = [],
                            // orderNum = [],
                            orderSuccessNum = [],
                            saleNet = [],
                            saleNum = [],
                            saleTotal = [],
                            visitNum = [],
                            visitUserNum = [];
                        for (let i = 0; i < len; i++) {
                            let item = statics[i];
                            statisticDate.push(item.statisticDate);
                            buyNum.push(item.buyNum);
                            buyPurchaseRate.push(
                                item.buyPurchaseRate ? item.buyPurchaseRate : 0
                            );
                            buyUserNum.push(item.buyUserNum);
                            conversionRate.push(
                                item.conversionRate ? item.conversionRate : 0
                            );
                            // orderNum.push(item.orderNum)
                            orderSuccessNum.push(item.orderSuccessNum);
                            saleNet.push(item.saleNet);
                            saleNum.push(item.saleNum);
                            saleTotal.push(item.saleTotal);
                            visitNum.push(item.visitNum);
                            visitUserNum.push(item.visitUserNum);
                        }
                        if (type == 'out') {
                            this.dialogChart = true;
                        }
                        this.$nextTick(() => {
                            this.staticDrawLine(
                                statisticDate,
                                buyNum,
                                buyPurchaseRate,
                                buyUserNum,
                                conversionRate,
                                orderSuccessNum,
                                saleNet,
                                saleNum,
                                saleTotal,
                                visitNum,
                                visitUserNum
                            );
                        });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getNowData() {
            let params = this.nowSelected;
            params.startDate = this.chartdate[0];
            params.endDate = this.chartdate[1];

            await this.getDetail('in', params);
        },
        async getChart(id, goodsItemCode) {
            this.loading = true;

            let params = {};
            params.dimension = this.queryForm.dimension;
            params.goodsId = id ? id : null;
            params.goodsItemCode = goodsItemCode ? goodsItemCode : null;

            if (this.queryForm.dimension == 1) {
                params.startDate = this.date?.[0];
                params.endDate = this.date?.[1];
            } else {
                params.startDate = this.subdate?.[0];
                params.endDate = this.subdate?.[1];
            }

            this.nowSelected = params;
            this.chartdate = [params.startDate, params.endDate];

            await this.getDetail('out', params);
        },
        staticDrawLine(
            statisticDate,
            buyNum,
            buyPurchaseRate,
            buyUserNum,
            conversionRate,
            orderSuccessNum,
            saleNet,
            saleNum,
            saleTotal,
            visitNum,
            visitUserNum
        ) {
            let staticTrend = echarts.init(
                document.getElementById('StaticChart')
            );
            window.onresize = function () {
                staticTrend.resize();
            };
            //   let base = +new Date(1988, 9, 3);
            let oneDay = 24 * 3600 * 1000;
            let data = [[Math.random() * 300]];
            for (let i = 1; i < 20000; i++) {
                let now = new Date(oneDay);
                data.push([
                    +now,
                    Math.round((Math.random() - 0.5) * 20 + data[i - 1][1]),
                ]);
            }
            this.$nextTick(() => {
                // 绘制图表
                staticTrend.setOption({
                    // title: { text: '商品数据详情' },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                        },
                        // legend: {
                        //     data: ['sales'],
                        // },
                    },
                    grid: {
                        // right: '20%'
                    },
                    legend: {
                        data: [
                            '加购数量',
                            '加购率',
                            '加购用户数',
                            '转化率',
                            '下单成功数',
                            '净销售额',
                            '销量',
                            '销售总额',
                            '访问数量',
                            '访问用户数量',
                        ],
                    },
                    xAxis: {
                        type: 'category',
                        // axisLine: { onZero: true },
                        splitLine: {
                            show: true,
                        },
                        data: statisticDate,
                    },
                    yAxis: [
                        {
                            type: 'value',
                            name: '数量',
                            position: 'left',
                            alignTicks: true,
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: '#5470C6',
                                },
                            },
                        },
                        {
                            type: 'value',
                            name: '百分比',
                            position: 'right',
                            alignTicks: true,
                            // max: 100,
                            // min: 0,
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: '#EE6666',
                                },
                            },
                            axisLabel: {
                                formatter: '{value}%',
                            },
                        },
                        {
                            type: 'value',
                            name: '销售额',
                            position: 'right',
                            offset: 60,
                            alignTicks: true,
                            axisLine: {
                                onZero: false,
                                show: true,
                                lineStyle: {
                                    color: '#3ba272',
                                },
                            },
                        },
                    ],
                    // dataZoom: [
                    //     {
                    //         // type: 'inside',
                    //         start: 0,
                    //         end: 100,
                    //         // orient: 'vertical',
                    //     },
                    //     {
                    //         start: 0,
                    //         end: 100,
                    //     },
                    //     {
                    //         // type: 'slider',
                    //         show: true,
                    //         handleSize: 0, //滑动条的 左右2个滑动条的大小
                    //         startValue: 0, // 初始显示值
                    //         endValue: 500000, // 结束显示值,自己定
                    //         height: 5, //组件高度
                    //         left: '10%', //左边的距离
                    //         right: '10%', //右边的距离
                    //         bottom: 15, //底边的距离
                    //         borderColor: '#ccc',
                    //         fillerColor: '#4cccfe',
                    //         borderRadius: 5,
                    //         backgroundColor: '#efefef', //两边未选中的滑动条区域的颜色
                    //         showDataShadow: false, //是否显示数据阴影 默认auto
                    //         showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
                    //         realtime: true, //是否实时更新
                    //         filterMode: 'filter',
                    //     },
                    // ],
                    series: [
                        {
                            name: '加购数量',
                            type: 'line',
                            yAxisIndex: 0,
                            data: buyNum,
                        },
                        {
                            name: '加购率',
                            type: 'bar',
                            yAxisIndex: 1,
                            barMaxWidth: 20,
                            data: buyPurchaseRate,
                        },
                        {
                            name: '加购用户数',
                            type: 'line',
                            yAxisIndex: 0,
                            data: buyUserNum,
                        },
                        {
                            name: '转化率',
                            type: 'bar',
                            yAxisIndex: 1,
                            barMaxWidth: 20,
                            data: conversionRate,
                        },
                        // {
                        //     name: '订单量',
                        //     type: 'line',
                        //     yAxisIndex: 0,
                        //     data: orderNum
                        // },
                        {
                            name: '下单成功数',
                            type: 'line',
                            yAxisIndex: 0,
                            data: orderSuccessNum,
                        },
                        {
                            name: '净销售额',
                            type: 'line',
                            yAxisIndex: 2,
                            data: saleNet,
                        },
                        {
                            name: '销量',
                            type: 'line',
                            yAxisIndex: 0,
                            data: saleNum,
                        },
                        {
                            name: '销售总额',
                            type: 'line',
                            yAxisIndex: 2,
                            data: saleTotal,
                        },
                        {
                            name: '访问数量',
                            type: 'line',
                            yAxisIndex: 0,
                            data: visitNum,
                        },
                        {
                            name: '访问用户数量',
                            type: 'line',
                            yAxisIndex: 0,
                            data: visitUserNum,
                        },
                    ],
                });
            });
        },
        outPut() {
            this.$confirm('是否导出商品数据统计？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.loading = true;

                let params =
                    this.queryForm.dimension == 1
                        ? this.queryForm
                        : this.subqueryForm;

                if (this.queryForm.dimension == 1) {
                    params.startDate = this.date?.[0];
                    params.endDate = this.date?.[1];
                    params.dimension = '1';
                } else {
                    params.startDate = this.subdate?.[0];
                    params.endDate = this.subdate?.[1];
                    params.dimension = '2';
                }

                this.$http
                    .get(`/statistic/goods/export`, {
                        params,
                    })
                    .then((r) => {
                        let { code } = r.data;
                        if (code === 200) {
                            this.$confirm(
                                '操作成功，是否跳转到任务中心？',
                                '导出成功',
                                {
                                    type: 'warning',
                                    confirmButtonText: '确定',
                                    cancelButtonText: '取消',
                                }
                            )
                                .then(() => {
                                    this.$router.push('/task/center');
                                })
                                .catch(() => {});
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        console.log(this.queryForm.dimension);
                        this.loading = false;
                        this.queryForm.dimension == 1
                            ? this.queryData()
                            : this.subqueryData();
                    });
            });
        },
        changeSort(column, form) {
            // console.log(form)
            let name = column.prop;
            let order = column.order;
            for (let i = 0; i < this.sortOptions.length; i++) {
                let item = this.sortOptions[i];
                if (name == item.name) {
                    switch (order) {
                        case 'ascending':
                            this[form].ascSort = item.value;
                            this[form].descSort = null;
                            break;
                        case 'descending':
                            this[form].ascSort = null;
                            this[form].descSort = item.value;
                            break;
                        default:
                            this[form].ascSort = null;
                            this[form].descSort = null;
                            break;
                    }
                }
            }
            if (form == 'queryForm') {
                this.queryForm.pageNo = 1;
                this.queryData();
            } else {
                this.subqueryForm.pageNo = 1;
                this.subqueryData();
            }
        },
        timeDefault() {
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            if (mm < 10) {
                mm = '0' + mm;
            }
            let dd = new Date().getDate();
            if (dd < 10) {
                dd = '0' + dd;
            }
            let dateTime = yy + '-' + mm + '-' + dd;
            // console.log(dateTime)
            return [dateTime, dateTime];
        },
        // 查询
        queryData() {
            if (this.date && this.date.length > 1) {
                this.queryForm.startDate = this.date[0];
                this.queryForm.endDate = this.date[1];
            } else {
                this.queryForm.startDate = null;
                this.queryForm.endDate = null;
            }
            this.loading = true;
            this.$http
                .get('/statistic/goods/getPage', { params: this.queryForm })
                .then((response) => {
                    // console.log(response.data.code)
                    if (response.data.code === 200) {
                        this.tableData = response.data.data;
                        this.total = response.data.count;
                        this.loading = false;
                    }
                });
        },
        subqueryData() {
            if (this.subdate && this.subdate.length > 1) {
                this.subqueryForm.startDate = this.subdate[0];
                this.subqueryForm.endDate = this.subdate[1];
            } else {
                this.subqueryForm.startDate = null;
                this.subqueryForm.endDate = null;
            }
            this.subloading = true;
            this.$http
                .get('/statistic/goods/getPage', { params: this.subqueryForm })
                .then((response) => {
                    // console.log(response.data.code);
                    this.subloading = false;
                    if (response.data.code === 200) {
                        this.subtableData = response.data.data;
                        this.subtotal = response.data.count;
                    }
                });
        },
        openView(url) {
            window.open(url);
        },
    },
};
</script>

<style scoped>
.main-wrap {
    height: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}
/* .main-wrap {
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
} */
.el-tabs {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    color: #606266;
}
.hidetext {
    white-space: nowrap;
    cursor: pointer;
}
.rowbox {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
}
.rowbox .img {
    flex-basis: 180px;
    width: 180px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rowbox .img img {
    max-width: 150px;
    max-height: 150px;
}
.delrow {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 30px;
    margin-bottom: 15px;
}
.delrow .tit {
    width: 80px;
    display: inline-block;
}
.imgbox {
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imgbox img {
    max-width: 150px;
    max-height: 150px;
}

.jump {
    color: #6f96e8;
}
#StaticChart {
    height: 500px;
    width: 90%;
    margin: 40px auto;
}
#World {
    width: 100%;
    height: 600px;
}
</style>
