<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>店铺管理</el-breadcrumb-item>
            <el-breadcrumb-item>收款渠道</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 数据列表 -->
        <div class="oa" v-loading="loading">
            <main v-for="payment in paymentMethod" :key="payment.value">
                <h2 style="color: #303133">{{ payment.name }}</h2>
                <section class="mb_4" v-for="channel in payment.data" :key="channel.id">
                    <header class="df ai_c px_3" style="background-color: #f3f3f3">
                        <h3 style="color: #606266">{{ channel.channelName }}</h3>
                        <div class="ml_a">
                            <i class="el-icon-loading mr_1" v-if="btnLoading == 'setChannelOpenState_' + channel.id"></i>
                            <el-switch style="margin-right:20px;" active-text="默认展开" inactive-text="非默认" active-value="1" inactive-value="0" v-model="channel.isDefaultOpen" @change="setChannelDefault(channel, $event)">
                            </el-switch>
                            <el-switch active-text="启用" inactive-text="禁用" active-value="1" inactive-value="0" v-model="channel.isOpen" @change="setChannelOpenState(channel, $event)">
                            </el-switch>
                        </div>
                        <el-button class="ml_4_i" type="success" icon="el-icon-plus" @click="initAddAccount(channel)">新增账户</el-button>
                        <el-button class="ml_4_i" type="text" icon="el-icon-arrow-down" v-if="channel.isExpand" @click="channel.isExpand = false"></el-button>
                        <el-button class="ml_4_i" type="text" icon="el-icon-arrow-right" v-else @click="channel.isExpand = true"></el-button>
                    </header>
                    <table class="AccountTable" v-if="channel.isExpand && channel.paymentChannelAccounts.length > 0">
                        <tr>
                            <td style="padding-left: 36px; text-align: left !important">
                                账户名称
                            </td>
                            <td>&nbsp;</td>
                            <td style="width: 200px">操作</td>
                        </tr>
                        <tr v-for="row in channel.paymentChannelAccounts" :key="row.id">
                            <td style="padding-left: 36px; text-align: left !important">
                                {{ row.accountName
                }}<el-tag type="success" size="mini" class="ml_1" v-if="row.isDefault == 1">默认</el-tag>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <el-button type="text" icon="el-icon-circle-check" v-if="row.isDefault == 0" :loading="btnLoading == 'setDefaultAccount_' + row.id" @click="setDefaultAccount(row.id)">
                                    设为默认
                                </el-button>
                                <el-button type="text" icon="el-icon-edit" @click="initEditAccount(channel.channelName, row)">编辑</el-button>
                            </td>
                        </tr>
                    </table>
                </section>
            </main>
        </div>

        <!-- 新增账户 -->
        <el-dialog top="3%" title="新增账户" width="500px" :visible.sync="dialogAddAccount" :close-on-click-modal="false">
            <el-form ref="addAccountForm" :model="addAccountForm" :rules="addAccountRules">
                <el-form-item label="账户名称" prop="accountName">
                    <el-input maxlength="50" v-model="addAccountForm.accountName"></el-input>
                </el-form-item>
                <el-form-item v-for="param in accountSelectedParams" :key="param" :label="param" :prop="'accountInfo.' + param" :rules="{
            required: true,
            message: '请输入' + param,
            trigger: 'blur',
          }">
                    <el-input v-model="addAccountForm.accountInfo[param]"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading == 'addAccount'" @click="addAccount">保存</el-button>
                <el-button @click="dialogAddAccount = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 编辑账户 -->
        <el-dialog top="3%" title="编辑账户" width="500px" :visible.sync="dialogEditAccount" :close-on-click-modal="false">
            <el-form ref="editAccountForm" :model="editAccountForm" :rules="editAccountRules">
                <el-form-item label="账户名称" prop="accountName">
                    <el-input maxlength="50" v-model="editAccountForm.accountName" disabled></el-input>
                </el-form-item>
                <el-form-item v-for="param in accountSelectedParams" :key="param" :label="param" :prop="'accountInfo.' + param" :rules="{
            required: true,
            message: '请输入' + param,
            trigger: 'blur',
          }">
                    <el-input v-model="editAccountForm.accountInfo[param]"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading == 'editAccount'" @click="editAccount">保存</el-button>
                <el-button @click="dialogEditAccount = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'PaymentChannel',

    data() {
        return {
            loading: false,
            btnLoading: '',

            // 收款渠道账户参数
            accountParams: {
                PayPal: ['clientId', 'clientSecret'],
                Stripe: ['apiKey', 'publicKey'],
                Pacypay: ['merchantNo', 'appId', 'secretKey'],
				PayPalCard: ['clientId', 'clientSecret'],
            },
            accountSelectedParams: [],

            // 支付方式及数据
            paymentMethod: [
                { name: '线上支付', value: 1, data: [] },
                { name: '信用卡', value: 2, data: [] },
            ],

            // 新增账户
            dialogAddAccount: false,
            addAccountForm: {
                channelId: null,
                accountName: null,
                accountInfo: {},
            },
            addAccountRules: {
                accountName: [
                    {
                        required: true,
                        message: '请输入账户名称',
                        trigger: 'blur',
                    },
                ],
            },

            // 编辑账户
            dialogEditAccount: false,
            editAccountForm: {
                id: null,
                accountName: null,
                accountInfo: {},
            },
            editAccountRules: {
                accountName: [
                    {
                        required: true,
                        message: '请输入账户名称',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },

    mounted() {
        this.queryData();
    },

    methods: {
        // 获取数据
        queryData() {
            this.loading = true;

            this.$http
                .get('/payment/channel/getList')
                .then((r) => {
                    if (r.data.code === 200) {
                        this.loading = false;
                        this.paymentMethod.forEach((v) => (v.data = []));

                        r.data.data.forEach((item) => {
                            item.isOpen = item.isOpen.toString();
                            item.isDefaultOpen = item.isDefaultOpen.toString();
                            item.isExpand = true;
                            this.paymentMethod
                                .find((v) => v.value == item.paymentMethod)
                                .data.push(item);
                            console.log(
                                r.data.data,
                                item.paymentMethod,
                                item.isExpand
                            );
                        });
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    this.loading = false;
                });
        },

        // 设置默认
        setChannelDefault(channel, value) {
            this.$confirm(
                `确认要${value == 0 ? '取消默认展开' : '设为默认展开'}吗？`,
                {
                    type: 'warning',
                }
            )
                .then(() => {
                    this.btnLoading = 'setChannelDefault_' + channel.id;

                    this.$http
                        .get(
                            '/payment/channel/editDefaultOpen?id=' + channel.id
                        )
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '设置成功',
                                    duration: 1000,
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            channel.isDefaultOpen = value == 0 ? '1' : '0';
                        })
                        .finally(() => {
                            this.btnLoading = '';
                            this.queryData();
                        });
                })
                .catch(() => (channel.isDefaultOpen = value == 0 ? '1' : '0'));
        },

        // 设置渠道启用状态
        setChannelOpenState(channel, value) {
            this.$confirm(`确认要${value == 0 ? '禁用' : '启用'}吗？`, {
                type: 'warning',
            })
                .then(() => {
                    this.btnLoading = 'setChannelOpenState_' + channel.id;

                    this.$http
                        .post('/payment/channel/editOpen', {
                            id: channel.id,
                            isOpen: value,
                        })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '设置成功',
                                    duration: 1000,
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            channel.isOpen = value == 0 ? '1' : '0';
                        })
                        .finally(() => {
                            this.btnLoading = '';
                            this.queryData();
                        });
                })
                .catch(() => (channel.isOpen = value == 0 ? '1' : '0'));
        },

        // 设置默认账户
        setDefaultAccount(accountId) {
            this.$confirm('确认设为默认账户吗？', { type: 'warning' })
                .then(() => {
                    this.btnLoading = 'setDefaultAccount_' + accountId;

                    this.$http
                        .post('/payment/channel/editAccount', {
                            id: accountId,
                            isDefault: 1,
                        })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.btnLoading = '';

                                this.$message({
                                    type: 'success',
                                    message: '设置成功',
                                    duration: 1000,
                                    onClose: this.queryData,
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = '';
                        });
                })
                .catch(() => {});
        },

        // 新增账户
        initAddAccount(channel) {
            this.accountSelectedParams =
                this.accountParams[channel.channelName];

            this.addAccountForm = {
                channelId: channel.id,
                accountName: null,
                accountInfo: {},
            };

            this.dialogAddAccount = true;
        },
        addAccount() {
            this.$refs.addAccountForm.validate((valid) => {
                if (valid) {
                    this.btnLoading =
                        'addAccount_' + this.addAccountForm.channelId;

                    this.$http
                        .post(
                            '/payment/channel/addAccount',
                            this.addAccountForm
                        )
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.btnLoading = '';
                                this.dialogAddAccount = false;

                                this.$message({
                                    type: 'success',
                                    message: '新增成功',
                                    duration: 1000,
                                    onClose: this.queryData,
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = '';
                        });
                }
            });
        },

        // 编辑账户
        initEditAccount(channelName, account) {
            this.accountSelectedParams = this.accountParams[channelName];

            let accountInfo = {};
            this.accountSelectedParams.forEach(
                (v) => (accountInfo[v] = account.accountInfo[v])
            );

            this.editAccountForm = {
                id: account.id,
                accountName: account.accountName,
                accountInfo,
            };

            this.dialogEditAccount = true;
        },
        editAccount() {
            this.$refs.editAccountForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = 'editAccount_' + this.addAccountForm.id;

                    this.$http
                        .post(
                            '/payment/channel/editAccount',
                            this.editAccountForm
                        )
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.btnLoading = '';
                                this.dialogEditAccount = false;

                                this.$message({
                                    type: 'success',
                                    message: '编辑成功',
                                    duration: 1000,
                                    onClose: this.queryData,
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = '';
                        });
                }
            });
        },
    },
};
</script>

<style scoped>
.AccountTable {
    border-collapse: collapse;
    width: 100%;
    color: #606266;
    font-size: 14px;
}

.AccountTable tr {
    border-top: solid 1px #e8ebf3;
    border-bottom: solid 1px #e8ebf3;
}

.AccountTable tr:hover {
    background-color: #f4f6f9;
}

.AccountTable tr:first-child {
    border-width: 0;
}

.AccountTable td {
    min-width: 150px;
    padding: 12px;
    word-break: break-all;
}
</style>
