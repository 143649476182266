<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>店铺管理</el-breadcrumb-item>
            <el-breadcrumb-item>自定义页面</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="openAdd">新增</el-button>
                <el-button icon="el-icon-refresh" @click="querySearch">刷新</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表 -->
        <el-table border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" :data="tableData" v-loading="loading">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="title" label="标题" min-width="200"></el-table-column>
            <el-table-column fixed="right" label="操作" width="200" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-view" @click="openView(scope.row.seoUrl)">预览
                    </el-button>
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="openEdit(scope.row.id)">编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="deleteThis(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[20, 50, 100]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-dialog fullscreen :title="dialogAddForm.id ? '编辑页面' : '新增页面'" :visible.sync="dialogAdd" :close-on-click-modal="false">
            <el-form :model="dialogAddForm" :rules="dialogRules" status-icon class="addform" ref="dialogAddForm" label-position="top" size="medium">
                <div class="addleft">
                    <el-form-item label="标题" prop="title">
                        <el-input v-model="dialogAddForm.title" placeholder="请输入标题"></el-input>
                    </el-form-item>
                    <el-form-item label="正文" prop="content">
                        <vue-tinymce v-model="dialogAddForm.content" :setup="setup" :setting="setting"></vue-tinymce>
                    </el-form-item>
                </div>

                <div class="addright">
                    <el-form-item label="seo标题">
                        <el-input placeholder="请输入seo标题" v-model="dialogAddForm.seoTitle"></el-input>
                    </el-form-item>
                    <el-form-item label="seo描述">
                        <el-input placeholder="请输入seo描述" v-model="dialogAddForm.seoDesc"></el-input>
                    </el-form-item>
                    <el-form-item label="seo链接" v-if="dialogAddForm.id">
                        <el-input placeholder="请输入seo链接" v-model="splitLink.href">
                            <template slot="prepend">{{ splitLink.host }}</template>
                            <template slot="append">{{ splitLink.html }}</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="seo关键字">
                        <el-tag closable :disable-transitions="true" v-for="( tag, index ) in dialogAddForm.seoKeywords" :key="index" @close="dialogAddForm.seoKeywords.splice( index, 1 )">
                            {{ tag }}
                        </el-tag>
                        <el-input ref="seo_saveTagInput" size="small" class="input-new-tag" v-if="seo_inputVisible" v-model="seo_inputValue" @blur="seo_inputValue && dialogAddForm.seoKeywords.push( seo_inputValue ); seo_inputVisible = false; seo_inputValue = '';">
                        </el-input>
                        <el-button class="button-new-tag" size="small" v-else @click="seo_inputVisible = true; $nextTick( _ => $refs.seo_saveTagInput.$refs.input.focus() );">
                            + 新增
                        </el-button>
                    </el-form-item>
                </div>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                <el-button @click="dialogAdd = false">取消</el-button>
            </div>
        </el-dialog>

        <ImageUploader :insertImage="insertImage" :insertType="insertType" @getOpenState="getOpenState" @getImage="getImage" ref="uploadImage"></ImageUploader>
        <VideoUploader ref="uploadVideo" :insertVideo="dialogInsertVideo" :insertVideoType="insertVideoType" :activeVideo="activeVideo" @getOpenVideo="getOpenVideo" @getVideo="getVideo"></VideoUploader>

    </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader';
import VideoUploader from '@/components/VideoUploader';
export default {
    name: 'CustomPage',
    components: {
        ImageUploader: ImageUploader,
        VideoUploader: VideoUploader,
    },
    data() {
        return {
            queryForm: {
                pageNo: 1,
                pageSize: 20,
            },
            total: 0,
            tableData: [],
            loading: false,

            dialogAdd: false,
            addLoading: false,
            dialogAddForm: {
                title: null,
                content: '',
                seoTitle: null,
                seoKeywords: [],
                seoDesc: null,
                seoUrl: '',
            },
            dialogRules: {
                title: [
                    { required: true, message: '请输入标题', trigger: 'blur' },
                ],
                content: [
                    { required: true, message: '请输入正文', trigger: 'blur' },
                ],
            },
            splitLink: {
                host: '',
                href: '',
                html: '',
            },
            defaultHost: '',
            // seo关键字
            seo_inputVisible: false,
            seo_inputValue: '',

            insertType: null,
            insertImage: false,
            dialogInsertVideo: false,
            activeVideo: 'first',
            insertVideoType: null,
            setting: {
                menubar: 'edit view format tools table',
                toolbar:
                    'undo redo removeformat | fullscreen | imageUpload | videoUpload code | styleselect alignleft aligncenter alignright alignjustify | link | numlist bullist | fontselect fontsizeselect forecolor backcolor | table bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
                toolbar_mode: 'sliding',
                quickbars_insert_toolbar: 'quicktable',
                quickbars_selection_toolbar:
                    'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
                plugins:
                    'link image media code table textpattern lists fullscreen quickbars',
                language: 'zh_CN',
                height: 350,
                convert_urls: false,
            },
        };
    },
    mounted() {
        this.getUrl();
        this.queryData();
    },
    methods: {
        querySearch() {
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        queryData() {
            this.loading = true;
            this.$http
                .get('/setting/bottom/getPage', { params: this.queryForm })
                .then((r) => {
                    this.loading = false;
                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        this.total = r.data.count;
                    }
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        getUrl() {
            this.loading = true;
            this.$http
                .get('/common/getSeoUrlData')
                .then((r) => {
                    this.loading = false;
                    if (r.data.code === 200) {
                        console.log(r.data.data);
                        let data = r.data.data;
                        this.defaultHost =
                            data.shopHostUrl + data.shopBottomUrl + '/';
                        this.splitLink.host =
                            data.shopHostUrl + data.shopBottomUrl + '/';
                        // this.splitLink.html = data.shopBottomUrl
                    }
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        openAdd() {
            this.dialogAddForm = {
                title: null,
                content: '',
                seoTitle: null,
                seoKeywords: [],
                seoDesc: null,
                seoUrl: '',
            };

            this.splitLink.href = '';

            this.addLoading = false;
            this.dialogAdd = true;
        },
        splitMethod(url) {
            if (url) {
                let arr = url.split('/');
                console.log(arr);

                let host = arr[0] + '//' + arr[2] + '/' + arr[3] + '/';

                let temphtml = arr[4].split('-');
                console.log(temphtml);
                let html = '-' + temphtml[temphtml.length - 1];

                let temphref = arr[4].split(html);
                let href = temphref[0];

                this.splitLink = {
                    host: host,
                    href: href,
                    html: html,
                };
            } else {
                this.splitLink = {
                    host: this.defaultHost,
                    href: '',
                    html: '-' + this.dialogAddForm.id + '.html',
                };
            }
        },
        openEdit(id) {
            this.loading = true;
            this.$http
                .get('/setting/bottom/getDetail?id=' + id)
                .then((response) => {
                    if (response.data.code === 200) {
                        this.addLoading = false;
                        // console.log(response.data.data)
                        let data = response.data.data;
                        this.dialogAddForm = {
                            id: data.id,
                            title: data.title,
                            content: data.content,
                            seoTitle: data.seoTitle,
                            seoKeywords: data.seoKeywords
                                ? data.seoKeywords
                                : [],
                            seoDesc: data.seoDesc,
                            seoUrl: data.seoUrl,
                        };
                        this.splitMethod(this.dialogAddForm.seoUrl);
                        this.dialogAdd = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addData() {
            this.$refs['dialogAddForm'].validate((valid) => {
                if (valid) {
                    let url;
                    if (this.dialogAddForm.id) {
                        url = '/setting/bottom/edit';
                        this.dialogAddForm.seoUrl =
                            this.splitLink.host +
                            this.splitLink.href +
                            this.splitLink.html;
                    } else {
                        url = '/setting/bottom/add';
                    }

                    if (!this.dialogAddForm.seoTitle) {
                        this.dialogAddForm.seoTitle = this.dialogAddForm.title;
                    }
                    if (!this.dialogAddForm.seoDesc) {
                        this.dialogAddForm.seoDesc =
                            this.dialogAddForm.seoTitle;
                    }

                    this.$http
                        .post(url, this.dialogAddForm)
                        .then((response) => {
                            this.addLoading = false;
                            if (response.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message({
                                    message: this.dialogAddForm.id
                                        ? '编辑成功'
                                        : '新增成功',
                                    type: 'success',
                                    duration: 1000,
                                });
                                this.queryData();
                            }
                        })
                        .catch((err) => {
                            this.addLoading = false;
                        });
                }
            });
        },
        deleteThis(id) {
            this.$confirm('是否删除自定义页面？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .delete('/setting/bottom/delete?id=' + id)
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message.success('删除成功');
                            this.queryData();
                        }
                    });
            });
        },
        setup(editor) {
            const that = this;
            editor.ui.registry.addButton('imageUpload', {
                tooltip: '插入图片',
                icon: 'image',
                onAction: () => {
                    that.setOpenInsert('tinymce');
                },
            });
            editor.ui.registry.addButton('videoUpload', {
                tooltip: '插入视频',
                icon: 'embed',
                onAction: () => {
                    that.openVideoUploader('tinymce');
                },
            });
        },
        setOpenInsert(obj) {
            this.insertType = obj;
            this.insertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.insertImage = result;
        },
        getImage(obj, image) {
            if (obj == 'tinymce') {
                image.map((item) => {
                    this.dialogAddForm.content += `<img src="${item}"/>`;
                });
            }
        },
        /* 插入视频方法 */
        openVideoUploader(obj) {
            this.insertVideoType = obj;
            this.dialogInsertVideo = true;
            this.$refs.uploadVideo.openInsert();
        },
        getOpenVideo(result) {
            this.dialogInsertVideo = result;
        },
        getVideo(obj, video) {
            switch (obj) {
                case 'tinymce':
                    video.map((item) => {
                        this.dialogAddForm.content += `<div class="flex-center"><video controls autoplay loop><source src="${item}"/></video></div>`;
                    });
                    break;
                default:
                    break;
            }
        },
        openView(url) {
            window.open(url);
        },
    },
};
</script>

<style scoped>
.addform {
    display: flex;
    justify-content: space-around;
}
.addleft {
    flex-basis: 60%;
    width: 60%;
}
.addright {
    flex-basis: 30%;
    width: 30%;
}
.main-wrap /deep/ .el-tag {
    position: relative;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
}
.main-wrap /deep/ .el-tag .el-icon-close {
    position: absolute;
    top: 8px;
    right: 0;
}

::v-deep .el-tag {
    height: auto;
    min-height: 32px;
    white-space: normal;
}
</style>