<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商品管理</el-breadcrumb-item>
            <el-breadcrumb-item>款式属性</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 查询条件 -->
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="initAddForm">新增</el-button>
            </el-form-item>
        </el-form>
        <!-- 数据表 -->
        <div class="oa">
            <section class="mb_4" v-for="(item,index) in tableData" :key="index">
                <el-collapse @change="handleChange" :collapse="isCollapse">
                    <el-collapse-item>
                        <header class="df ai_c px_3" slot="title">
                            <p style="color: #606266">{{ item.labelName }}</p>
                            <div class="ml_a">
                                <i class="mr_1"></i>
                            </div>
                            <div class="ml_a">
                                <el-button class="ml_4_i" type="text" icon="el-icon-edit" @click="initEditLabel(item)">编辑</el-button>
                            </div>
                        </header>
                        <br>
                        <div>
                            <!-- <div>
                                <el-tag
                                    v-for="(tag,indexB) in item.shopGoodsLabelValues"
                                    :key="indexB"
                                    closable
                                    :disable-transitions="false"
                                    @close="handleClose(tag.id)"
                                >
                                    {{ tag.valueText }}
                                </el-tag>
                            </div> -->

                            <template v-if="item.shopGoodsLabelValues.length > 0">
                                <vuedraggable class="wrapper" v-model="item.shopGoodsLabelValues">
                                    <!-- <transition-group class="flex"> -->
                                    <el-tag v-for="(tag,indexB) in item.shopGoodsLabelValues" :key="indexB" closable :disable-transitions="false" @close="handleClose(tag.id, item.id)">
                                        {{ tag.valueText }}
                                    </el-tag>
                                    <!-- </transition-group> -->
                                </vuedraggable>
                            </template>

                            <div class="rows" v-if="item.inputVisible">
                                <el-input class="input-new-tag" v-model="item.inputValue" :ref="item.saveTagInput" size="small" @input="refreshInput" @keyup.enter.native="handleInputConfirm(item.id, item.inputValue)" @blur="handleInputConfirm(item.id ,item.inputValue)">
                                </el-input>
                                <el-button size="small" @click="hideInput(index)">关闭</el-button>
                            </div>
                            <div class="rows" v-else>
                                <el-button class="button-new-tag" v-if="tableData[index].inputVisible == false" size="small" @click="showInput(index, item.saveTagInput)">+ 新增
                                </el-button>
                                <el-button class="button-new-tag" size="small" @click="saveSort(item.id, item.shopGoodsLabelValues)">
                                    保存顺序
                                </el-button>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </section>
        </div>
        <el-pagination background :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>
        <!-- 新增 -->
        <el-dialog :title="isEdit ? '编辑' : '新增'" :visible.sync="dialogAdd" :close-on-click-modal="false" top="3%" width="500px">
            <el-form ref="dictAddForm" :rules="addRules" :model="dictAddForm" label-width="120px">
                <el-form-item label="款式名称" prop="labelName">
                    <el-input v-model="dictAddForm.labelName"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                    <el-button @click="dialogAdd = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import vuedraggable from 'vuedraggable';
export default {
    name: 'GoodsLabel',
    components: { vuedraggable },
    data() {
        return {
            queryForm: {},
            total: 0,
            tableData: [],
            loading: false,
            dialogAdd: false,
            dictAddForm: {},
            addRules: {},
            isEdit: false,
            addLoading: false,
            activeName: '1',
            isCollapse: false,
        };
    },
    mounted() {
        this.queryData();
    },
    methods: {
        queryData() {
            this.loading = true;
            this.$http
                .get('/goods/label/getList')
                .then((response) => {
                    if (response.data.code === 200) {
                        this.loading = false;
                        this.tableData = response.data.data;
                        this.total = response.data.count;
                        for (let i = 0; i < this.tableData.length; i++) {
                            this.tableData[i].inputVisible = false;
                            this.tableData[i].inputValue = null;
                            this.tableData[i].saveTagInput = 'saveTagInput' + i;
                        }
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    this.loading = false;
                });
        },

        // 新增
        initAddForm() {
            this.dictAddForm = {
                labelName: null,
            };
            this.addLoading = false;
            this.dialogAdd = true;
            this.isEdit = false;
        },
        initEditLabel(data) {
            this.addLoading = false;
            this.isEdit = true;
            this.dialogAdd = true;
            this.dictAddForm = {
                id: data.id,
                labelName: data.labelName,
            };
        },
        addData() {
            this.$refs['dictAddForm'].validate((valid) => {
                if (valid) {
                    let url;
                    let str;
                    if (this.dictAddForm.id) {
                        url = '/goods/label/edit';
                        str = '编辑';
                    } else {
                        url = '/goods/label/add';
                        str = '新增';
                    }
                    this.addLoading = true;
                    this.$http
                        .post(url, this.dictAddForm)
                        .then((response) => {
                            this.addLoading = false;
                            if (response.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message({
                                    message: str + '成功',
                                    type: 'success',
                                });
                                this.queryData();
                            }
                        })
                        .catch((error) => {
                            // this.$message.error(error);
                            this.addLoading = false;
                        });
                }
            });
        },
        handleClose(id) {
            this.addLoading = true;
            this.$http
                .delete('/goods/label/deleteValue?id=' + id)
                .then((response) => {
                    if (response.data.code == 200) {
                        this.queryData();
                    }
                });
        },
        showInput(index, saveTagInput) {
            this.tableData[index].inputVisible = true;
            console.log(this.tableData[index].inputVisible);
            this.$forceUpdate();
            // this.$nextTick(() => {
            //     this.$refs.saveTagInput.$refs.input.focus();
            // });
        },
        hideInput(index, saveTagInput) {
            this.tableData[index].inputVisible = false;
            this.$forceUpdate();
        },
        refreshInput() {
            // console.log('1')
            this.$forceUpdate();
        },
        saveSort(id, labels) {
            // console.log(id, labels)
            let arr = [];
            for (let i = 0; i < labels.length; i++) {
                arr.push(labels[i].id);
            }
            let json = {
                ids: arr,
            };
            this.$http
                .post('/goods/label/value/editSortByIds', json)
                .then((response) => {
                    if (response.data.code == 200) {
                        this.$message.success('修改成功');
                        this.queryData();
                    }
                });
        },
        handleInputConfirm(id, value) {
            this.loading = true;
            let json = {
                goodsLabelId: id,
                valueText: value,
            };
            if (value.length >= 1) {
                this.addLoading = true;
                this.$http
                    .post('/goods/label/addValue', json)
                    .then((response) => {
                        if (response.data.code == 200) {
                            // this.queryData()
                        }
                    });
            } else {
                this.$message({
                    message: '标签不可为空',
                    type: 'error',
                });
            }
            this.queryData();
        },
        handleChange(val) {
            console.log(val);
        },
    },
};
</script>
<style scoped>
.el-tag {
    margin-bottom: 10px;
}
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    /* vertical-align: bottom; */
}
.main-wrap /deep/ .AccountTable {
    border-collapse: collapse;
    width: 100%;
    color: #606266;
    font-size: 14px;
    min-height: 80px;
    padding: 20px 30px;
    border: 1px solid #9a9a9b29;
}
.AccountTable div {
    min-width: 120px;
    padding: 12px;
    word-break: break-all;
}
.main-wrap .oa /deep/ .el-collapse-item__header {
    display: flex;
    align-items: center;
    line-height: auto !important;
    background: rgb(243, 243, 243) !important;
}
.main-wrap .oa /deep/ header.df.ai_c.px_3 {
    width: 100% !important;
    line-height: 40px !important;
    height: 40px !important;
}
p {
    font-size: 22px;
}
/* .main-wrap .oa /deep/ .el-collapse-item__header.is-active{
    background: #3233 !important;
} */
.el-tag {
    margin-left: 10px;
    margin-bottom: 20px;
}
</style>