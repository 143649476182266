<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>营销管理</el-breadcrumb-item>
            <el-breadcrumb-item>礼品卡</el-breadcrumb-item>
        </el-breadcrumb>

        <el-tabs v-model="activeTabs" @tab-click="queryData">
            <el-tab-pane label="全部" name="2"></el-tab-pane>
            <el-tab-pane v-for="item in status" :key="item.value" :label="item.name" :name="item.value"></el-tab-pane>
        </el-tabs>

        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.giftCardNo" placeholder="礼品卡卡号"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="queryForm.email" placeholder="顾客邮箱"></el-input>
            </el-form-item>

            <!-- <el-form-item label="是否过期">
                <el-switch v-model="queryForm.giftStatus" :inactive-value="1" :active-value="0" @change="queryGiftStatus">
                </el-switch>
            </el-form-item> -->

            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="querySearch">查询</el-button>
                <el-button icon="el-icon-refresh-left" @click="resetQuery">重置</el-button>
                <el-button type="success" icon="el-icon-plus" @click="showDialog">新增</el-button>
            </el-form-item>
        </el-form>

        <el-table border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" :data="tableData" v-loading="loading">
            <el-table-column label="ID" prop="id" width="80px">
            </el-table-column>
            <el-table-column label="礼品卡卡号" width="190px">
                <template #default="{row}">
                    <p>{{ row.giftCardNo }}</p>
                    <el-tag type="info" v-if="!row.giftStatus">已过期</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="类型">
                <template #default="{row}">
                    {{ row.campaignType == 1 ? '日常赠送' : '满送优惠' }}
                </template>
            </el-table-column>
            <el-table-column label="创建人" prop="userName"></el-table-column>
            <el-table-column label="顾客邮箱" prop="customer.email"></el-table-column>
            <el-table-column label="状态">
                <template slot-scope="{row}">
                    {{ row.campaignStatus ? '启用' : '禁用'  }}
                </template>
            </el-table-column>
            <el-table-column label="过期日期">
                <template slot-scope="{row}">
                    <div v-html=" row.giftType == 1 ? '永不过期' :  '到期于<br>' + row.endTime"></div>
                </template>
            </el-table-column>
            <el-table-column label="当前余额/初始金额">
                <template slot-scope="{row}">
                    {{ (row.remainingAmount || 0) +'/' + (row.giftPrice || 0) }}
                </template>
            </el-table-column>
            <el-table-column label="备注" prop="giftRemark"></el-table-column>
            <el-table-column label="创建时间" prop="createTime"></el-table-column>
            <el-table-column label="更新时间" prop="updateTime"></el-table-column>
            <el-table-column label="操作" min-width="140px">
                <template #default="{row}">
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="intoEdit(row)">编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-s-promotion" @click="showEmailDialog(true, row.id)" v-show="row.campaignStatus">发送邮件</el-button>
                    <!-- <el-button size="mini" type="text" icon="el-icon-open">禁用</el-button> -->
                    <el-button size="mini" type="text" icon="el-icon-remove-outline" v-show="row.campaignStatus" @click="bridge_disable(row)">禁用</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-dialog title="发送邮件" :visible.sync="visibleDialogEmail" :close-on-click-modal="false" top="3%" @closed="resetDialogEmail">
            <el-form :inline="true" :model="emailForm" label-width="100px" :rules="EmailRules" ref="emailForm">
                <el-form-item style="width:100%;">
                    礼品卡信息将会发送给 {{ emailForm.name }}
                </el-form-item>
                <el-form-item label="邮件模板" prop="noticeTmplId">
                    <el-select v-model="emailForm.noticeTmplId" placeholder="模版名称">
                        <el-option :label="tmplName" :value="id" v-for="{tmplName, id} in noticeList" :key="id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="loading == 'email' " @click="sendEmail">{{ isAdding ? '发送' : '启用并发送' }}</el-button>
                <el-button @click="visibleDialogEmail = false">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="`${isAdding? '新增' : '编辑'}活动`" :visible.sync="visibleDialogInfo" :close-on-click-modal="false" top="3%" fullscreen class="dialog-info" @closed="resetDialogInfo">
            <el-form :inline="true" :model="infoForm" label-width="100px" class="info-form" :rules="rules" ref="infoForm">
                <div class="mod">
                    <strong>礼品卡信息</strong>
                    <el-form-item label="礼品卡卡号" class="card-flex" prop="giftCardNo">
                        <el-input v-model="infoForm.giftCardNo" :disabled="true"></el-input>
                        <!-- :disabled="true" -->
                        <el-link type="primary" style="margin-left:20px;" @click="getRandom" v-show="isAdding">随机生成</el-link>
                    </el-form-item>

                    <el-form-item label="初始金额" prop="giftPrice" v-show="isAdding">
                        <el-input v-model="infoForm.giftPrice" placeholder="USD" @input="formatToFixed2($event, 'giftPrice')" :min="1" :max="9999" type="number" class="number-input " style="width:320px">
                            <template slot="append">USD</template>
                        </el-input>
                    </el-form-item>
                    <div class="mod-progress" style="width:388px;" v-show="!isAdding">
                        <p>当前余额： {{ (infoForm.remainingAmount || 0) + ' / ' + (infoForm.giftPrice || 0 ) }} USD</p>
                        <el-progress :percentage="percentage" color="#409eff"></el-progress>
                    </div>
                </div>

                <div class="mod">
                    <strong>过期日期</strong>
                    <!-- <el-form-item prop="giftType" label="过期时间"> -->
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <div class="grid-content bg-purple">
                                <el-radio v-model="infoForm.giftType" :label="1" :disabled="!isAdding">永不过期</el-radio>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="grid-content bg-purple">
                                <el-radio v-model="infoForm.giftType" :label="2" :disabled="!isAdding">设置过期日期</el-radio>
                                <p>到期于</p>
                                <el-date-picker value-format="yyyy-MM-dd" placeholder="过期日期" v-model="infoForm.endTime" :disabled="infoForm.giftType == 1 || !isAdding">
                                </el-date-picker>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <div class="mod">
                    <strong>过期日期</strong>

                    <div class="flex-complete">
                        <div class="almost-tit"><span class="ft-required">*</span>发放顾客</div>
                        <el-form-item class="form-item-complete" slot-label="111">
                            <autocomplete ref="autocomplete" :item="completeItem" :title="completeTitle" :disabled="!isAdding" keyname="email" url="/customer/getPage" @handeItem="handeItem">
                                <template #default="{item}">
                                    <div class="complete-item">
                                        <template v-if="item.email ">
                                            <p class="name">{{ item.firstName || "" }}{{ item.lastName || "" }}</p>
                                            <p class="email">{{ item.email }}</p>
                                        </template>
                                    </div>
                                </template>
                            </autocomplete>
                        </el-form-item>
                    </div>

                    <el-form-item label="备注">
                        <el-input v-model="infoForm.giftRemark"></el-input>
                        <p class="dished">仅后台可见，顾客不可见</p>
                    </el-form-item>
                </div>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="loading == 'submit' " @click="bridge_submitInfo">确定</el-button>
                <el-button @click="visibleDialogInfo = false">取消</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import autocomplete from './Autocomplete.vue';

export default {
    name: 'Gifts',
    components: {
        autocomplete,
    },
    data: () => ({
        activeTabs: '2',
        queryForm: {
            giftCardNo: '',
            email: '',
            // giftStatus: null,
            pageNo: 1,
            pageSize: 20,
        },
        infoForm: {
            giftCardNo: '',
            giftPrice: null,
            giftType: 1,
            endTime: '',
            giftRemark: '',
        },
        emailForm: {
            id: null,
            noticeTmplId: null,
        },
        status: [
            { name: '启用', value: '1' },
            { name: '禁用', value: '0' },
        ],
        tableData: [],
        loading: null,

        isAdding: true,
        hasPassiveInfo: false,
        visibleDialogInfo: false,
        visibleDialogEmail: false,
        percentage: 0,

        EmailRules: {
            noticeTmplId: [
                {
                    required: true,
                    message: '请选择邮件模版',
                    trigger: 'change',
                },
            ],
        },

        rules: {
            giftCardNo: [
                {
                    required: true,
                    message: '请生成礼品卡卡号',
                    trigger: 'blur',
                },
                {
                    required: true,
                    message: '请生成礼品卡卡号',
                    trigger: 'input',
                },
            ],
            giftPrice: [
                {
                    required: true,
                    message: '请输入礼品卡初始金额',
                    trigger: 'blur',
                },
            ],
            giftType: [
                {
                    required: true,
                    message: '请选择礼品卡过期时间',
                    trigger: 'change',
                },
            ],
        },
        noticeList: [],
        total: 0,
        completeTitle: '',
        completeItem: {},
    }),

    mounted() {
        this.initNoticeList();
        this.queryData();
    },

    methods: {
        querySearch() {
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        queryGiftStatus(val) {
            this.queryData();
        },
        queryData() {
            const URL = '/campaign/gift/card/getPage',
                params = {
                    ...this.queryForm,
                    campaignStatus: this.activeTabs == 2 ? '' : this.activeTabs,
                };

            this.loading = 'searching';
            this.$http
                .get(URL, { params })
                .then((res) => {
                    let { code, data, count } = res.data;

                    if (code === 200) {
                        this.tableData = data;
                        this.total = count;
                    }
                })
                .finally(() => {
                    this.loading = null;
                });
        },
        resetQuery() {
            this.queryForm = this.$options.data.call(this).queryForm;
            this.queryData();
        },
        bridge_disable({ id }) {
            this.$confirm(
                '禁用礼品卡是永久性的，已停用的礼品卡无法再次发送邮件或启用',
                '禁用礼品卡'
            ).then(() => {
                this.disableGifts(id);
            });
        },
        disableGifts(id) {
            this.$http.get(`/campaign/gift/card/end?id=${id}`).then((res) => {
                let { code } = res.data;
                if (code === 200) {
                    this.$message.success('禁用成功~');
                    this.queryData();
                }
            });
        },
        getRandom() {
            let URL = '/campaign/gift/card/getRandom';
            this.$http.get(URL).then((res) => {
                let { code, data } = res.data;
                if (code === 200) {
                    this.infoForm.giftCardNo = data;
                }
            });
        },
        intoEdit(row) {
            let { customer, giftPrice, remainingAmount, ...args } = row || {},
                { email, firstName, lastName } = customer,
                title = `${firstName + lastName + '（' + email + '）'}`;

            this.completeTitle = title;
            this.completeItem = customer;
            this.isAdding = false;
            this.percentage = +(
                ((remainingAmount || 0) / (giftPrice || 0)) *
                100
            ).toFixed(2);
            this.infoForm = { giftPrice, remainingAmount, ...args };

            this.showDialog();
        },
        showEmailDialog(hasPassive = false, id) {
            this.visibleDialogEmail = true;
            if (hasPassive) this.hasPassiveInfo = hasPassive;
            this.emailForm.id = id;
        },
        showDialog() {
            this.visibleDialogInfo = true;
        },
        initNoticeList() {
            let URL = '/campaign/gift/card/getGiftCustomerNotice';
            this.$http.get(URL).then((res) => {
                let {
                    code,
                    data: { customerNoticeTmplList },
                } = res.data;
                if (code === 200) {
                    this.noticeList = customerNoticeTmplList;
                }
            });
        },

        handeItem(item) {
            let { firstName, lastName, email } = item,
                _str = `${firstName + lastName} (${email})`;

            if (_str == this.completeTitle) {
                this.completeTitle = '';
                this.$nextTick(() => {
                    this.completeTitle = _str;
                });
            } else {
                this.completeTitle = _str;
            }
        },

        resetDialogInfo() {
            let { infoForm, completeTitle, completeItem, isAdding } =
                this.$options.data.call(this);

            this.infoForm = infoForm;
            this.completeTitle = completeTitle;
            this.completeItem = completeItem;
            this.isAdding = isAdding;
            this.$refs.autocomplete.reset();
        },
        bridge_submitInfo() {
            this.$refs.infoForm.validate((valid) => {
                if (valid) {
                    let { giftType, endTime, giftPrice } = this.infoForm;

                    console.dir(this.$refs.autocomplete.activeItem);

                    if (!+giftPrice)
                        return this.$message.warning('请填写正确的初始金额~');
                    if (giftType == 2 && !endTime)
                        return this.$message.warning('请填写过期日期~');
                    if (!this.$refs.autocomplete.activeItem?.id)
                        return this.$message.warning('请选择发放顾客~');

                    if (this.isAdding) {
                        this.showEmailDialog();
                        return;
                    }
                    this.submitInfo();
                }
            });
        },
        submitInfo() {
            let URL = `/campaign/gift/card/${this.isAdding ? 'add' : 'edit'}`,
                { giftType, endTime, ...args } = this.infoForm,
                params = {
                    giftType,
                    ...args,
                    customerId: this.$refs.autocomplete.activeItem?.id,
                };

            if (giftType == 2) params['endTime'] = endTime;

            if (this.isAdding && !this.hasPassiveInfo) {
                params['noticeTmplId'] = this.emailForm.noticeTmplId;
            }

            this.loading = 'submit';
            this.$http
                .post(URL, params)
                .then((res) => {
                    let { code } = res.data;
                    if (code === 200) {
                        this.$message.success(
                            `礼品卡${this.isAdding ? '添加' : '编辑'}成功~`
                        );
                        this.visibleDialogInfo = false;
                        this.visibleDialogEmail = false;
                        this.queryData();
                    }
                })
                .finally(() => {
                    this.loading = '';
                });
        },
        sendEmail() {
            this.$refs.emailForm.validate(async (vaild) => {
                if (!vaild) return;
                if (this.isAdding && !this.hasPassiveInfo) {
                    this.submitInfo();
                    return;
                }
                this.loading = 'email';
                let URL = `/campaign/gift/card/sendMail`;
                let {
                    data: { code },
                } = await this.$http.get(URL, {
                    params: {
                        ...this.emailForm,
                    },
                });

                this.loading = '';
                if (code === 200) {
                    this.$message.success('邮件发送成功~');
                    this.visibleDialogEmail = false;
                }
            });
        },
        resetDialogEmail() {
            this.hasPassiveInfo = false;
        },
        formatToFixed2(value, gKey) {
            let str = value.replace('-', ''),
                reg =
                    /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;

            if (~value.indexOf('-')) {
                this.infoForm[gKey] = str;
            }

            if (!reg.test(+str)) {
                this.infoForm[gKey] = +str;
            }
        },
    },
};
</script>
<style scoped>
.dialog-info {
    background: #ecedf3;
}
::v-deep .dialog-info .el-dialog {
    background: transparent;
}
.mod {
    background: #fff;
    padding: 20px;
    margin-top: 20px;
}
.mod:nth-child(1) {
    margin-top: 0;
}
.mod strong {
    font-size: 18px;
    padding-bottom: 20px;
    display: block;
}
.info-form.el-form--inline .el-form-item {
    width: 100%;
}
.card-flex {
    display: flex;
}
::v-deep .card-flex .el-form-item__content {
    min-width: 400px;
}
::v-deep .card-flex .el-form-item__content .el-input {
    width: 60%;
}

::v-deep .form-item-complete .el-form-item__content {
    width: 400px;
}

::v-deep .form-item-complete .el-form-item__content .el-autocomplete {
    width: 100%;
}

.flex-complete {
    display: flex;
    align-content: center;
}
.flex-complete .almost-tit {
    width: 100px;
    padding: 0 12px 0 0;
    line-height: 44px;
    text-align: right;
}
.flex-complete .ft-required {
    color: #f56c6c;
    margin-right: 4px;
}
/* 
.el-autocomplete-component /deep/ .el-input__inner {
    cursor: pointer;
    padding-left: 5px;
    padding-right: 8px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.el-autocomplete-component /deep/ .el-input__inner :placeholder {
    color: #bfbfbf;
    font-size: 12px;
}

.diy-autocomplete .name {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 34px;
} */

::v-deep .number-input input::-webkit-outer-spin-button,
::v-deep .number-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
::v-deep .number-input input {
    -moz-appearance: textfield;
}

.complete-item p {
    font-size: 12px;
    margin: 0;
}
.complete-item {
    padding: 4px 0;
    line-height: 18px;
}
.complete-item .email {
    font-size: 12px;
}
.complete-item .last {
    text-align: center;
}
</style>