<template>
    <div class="main-wrap">

        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>顾客管理</el-breadcrumb-item>
            <el-breadcrumb-item>顾客通知</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 导航栏 end -->

        <!-- 通知类型tab -->
        <el-tabs class="oa" v-model="noticeType">

            <el-tab-pane label="订单通知" name="1">
                <div class="dg" style="grid-template-columns: repeat( auto-fill, minmax( 400px, 1fr ) ); gap: 60px; padding: 0 10px;" v-loading="loading == 'queryOrderNoticeData'">
                    <section class="orderNoticeCover" v-for="orderNoticeType in orderNoticeData" :key="orderNoticeType.id">
                        <h3>{{ orderNoticeType.noticeName }}</h3>
                        <p style="height: 50px; color: #909399; font-size: 14px; line-height: 20px;">
                            {{ orderNoticeMemo[ orderNoticeType.noticeName ].info }}
                        </p>
                        <div class="pr orderNoticeCover_main df jc_c ai_c">
                            <img class="db" style="width: 100%" :src="orderNoticeMemo[ orderNoticeType.noticeName ].thumbnail" />
                            <div class="pa100 orderNoticeCover_pop jc_c ai_c">
                                <el-button type="primary" @click="listOrderNoticeTemplate( orderNoticeType )">管理模板</el-button>
                            </div>
                        </div>
                        <el-checkbox class="mt_3" style="color: #909399" :label="orderNoticeMemo[ orderNoticeType.noticeName ].check" v-model="orderNoticeType.isOpen" @change="setOpenOrderNotice( orderNoticeType, $event )">
                        </el-checkbox>
                    </section>
                </div>
            </el-tab-pane>

            <el-tab-pane label="订单召回通知" name="2">
                <el-form :inline="true">
                    <el-form-item>
                        <el-button type="success" icon="el-icon-plus" @click="addNotice">新增订单召回通知</el-button>
                    </el-form-item>
                </el-form>
                <el-table ref="noticeData" border stripe :data="noticeData" row-key="id" v-loading="noticeDataLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading">
                    <el-table-column prop="id" label="ID" width="150" />
                    <el-table-column label="订单召回通知名称" prop="recallName" min-width="120" />
                    <el-table-column label="优惠码" prop="discountCode" min-width="120" />
                    <el-table-column label="延迟发送时间" min-width="120" #default="{ row }">
                        {{ row.delayTime }}{{ [ "秒", "分钟", "小时" ][ row.delayTimeUnit - 1 ] }}
                    </el-table-column>
                    <el-table-column label="是否启用" prop="isOpen" min-width="120" #default="{ row }">
                        <el-switch v-model="row.isOpen" :active-value="1" :inactive-value="0" active-text="是" inactive-text="否" @change="changeOpen( $event, row )" />
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="300" #default="{ row }">
                        <el-button type="text" size="mini" icon="el-icon-edit" @click="openEdit( row )">编辑</el-button>
                        <el-button type="text" size="mini" icon="el-icon-edit" @click="listOrderNoticeTemplate( { id: row.id, noticeName: '订单召回' }, true )">更改通知模板</el-button>
                    </el-table-column>
                </el-table>
            </el-tab-pane>

            <el-tab-pane label="顾客业务通知" name="3">
                <div class="dg" style="grid-template-columns: repeat( auto-fill, minmax( 400px, 1fr ) ); gap: 60px; padding: 0 10px;" v-loading="loading == 'queryOrderNoticeData'">
                    <section class="orderNoticeCover" v-for="orderNoticeType in orderNoticeData3" :key="orderNoticeType.id">
                        <h3>{{ orderNoticeType.noticeName }}</h3>
                        <p style="height: 50px; color: #909399; font-size: 14px; line-height: 20px;">{{ orderNoticeMemo[ orderNoticeType.noticeName ].info }}</p>
                        <div class="pr orderNoticeCover_main df jc_c ai_c">
                            <img class="db" style="width: 100%" :src="orderNoticeMemo[ orderNoticeType.noticeName ].thumbnail" />
                            <div class="pa100 orderNoticeCover_pop jc_c ai_c">
                                <el-button type="primary" @click="listOrderNoticeTemplate( orderNoticeType )">管理模板</el-button>
                            </div>
                        </div>
                        <el-checkbox class="mt_3" style="color: #909399" :label="orderNoticeMemo[ orderNoticeType.noticeName ].check" v-model="orderNoticeType.isOpen" @change="setOpenOrderNotice( orderNoticeType, $event, 3 )" />
                    </section>
                </div>
            </el-tab-pane>

            <el-tab-pane label="礼品卡" name="4">

                <div class="dg" style="grid-template-columns: repeat( auto-fill, minmax( 400px, 1fr ) ); gap: 60px; padding: 0 10px;" v-loading="loading == 'queryOrderNoticeData'">
                    <section class="orderNoticeCover" v-for="orderNoticeType in orderNoticeData4" :key="orderNoticeType.id">
                        <h3>{{ orderNoticeType.noticeName }}</h3>
                        <p style="height: 50px; color: #909399; font-size: 14px; line-height: 20px;">{{ orderNoticeMemo[ orderNoticeType.noticeName ].info }}</p>
                        <div class="pr orderNoticeCover_main df jc_c ai_c">
                            <img class="db" style="width: 100%" :src="orderNoticeMemo[ orderNoticeType.noticeName ].thumbnail" />
                            <div class="pa100 orderNoticeCover_pop jc_c ai_c">
                                <el-button type="primary" @click="listOrderNoticeTemplate( orderNoticeType )">管理模板</el-button>
                            </div>
                        </div>
                        <el-checkbox class="mt_3 long-checkbox" style="color: #909399" :label="orderNoticeMemo[ orderNoticeType.noticeName ].check" v-model="orderNoticeType.isOpen" @change="setOpenOrderNotice( orderNoticeType, $event, 4 )" />
                    </section>
                </div>
            </el-tab-pane>

        </el-tabs>
        <!-- 通知类型tab end -->

        <!-- 订单通知 - 模板管理 -->
        <el-dialog fullscreen :title="orderNoticeTemplate.orderNotice.name + '模板管理'" :visible.sync="dialogOrderNoticeTemplate" :close-on-click-modal="false">
            <header>
                <el-button type="success" icon="el-icon-plus" @click="initAddOrderNoticeTemplate">新增模板</el-button>
            </header>
            <div class="dg" style="grid-template-columns: repeat( auto-fill, minmax( 400px, 1fr ) ); gap: 60px;" v-loading="loading == 'queryOrderNoticeTemplateData'">
                <section class="orderNoticeCover" v-for="template in orderNoticeTemplate.data" :key="template.id">
                    <h3>{{ template.tmplName }}</h3>
                    <div class="pr orderNoticeCover_main df jc_c ai_c" style="box-shadow: #ccc 0 0 8px">
                        <img class="db" style="width: 100%" :src="orderNoticeMemo[orderNoticeTemplate.orderNotice.name].thumbnail">
                        <div class="pa100 orderNoticeCover_pop jc_c ai_c">
                            <el-button type="primary" @click="initEditOrderNoticeTemplate(template)">编辑</el-button>
                            <el-button type="danger" class="ml_5_i" :loading="btnLoading == 'deleteOrderNoticeTemplate_' + template.id" @click="deleteOrderNoticeTemplate(template)">删除</el-button>
                        </div>
                    </div>
                    <el-radio class="mt_3" :label="template.id" v-model="orderNoticeTemplate.default" @change="setDefaultOrderNoticeTemplate(template)">设为默认发送模板</el-radio>
                </section>
            </div>
        </el-dialog>
        <!-- 订单通知 - 模板管理 end -->

        <!-- 订单通知 - 新增模板 -->
        <el-dialog top="5vh" title="新增模板" width="950px" :visible.sync="addOrderNoticeTemplateDialog" :close-on-click-modal="false" @closed="addOrderNoticeTemplateDialogClosed">
            <el-form ref="addOrderNoticeTemplateForm" label-position="top" :model="addOrderNoticeTemplateForm" :rules="addOrderNoticeTemplateRules">
                <el-form-item label="模板名称" prop="tmplName">
                    <el-input maxlength="20" v-model="addOrderNoticeTemplateForm.tmplName" />
                </el-form-item>
                <el-form-item label="邮件店铺logo">
                    <section class="LogoImageBox pr df jc_c ai_c" v-if="addOrderNoticeTemplateForm.mailLogo">
                        <el-image fit="contain" class="LogoImage" :src="addOrderNoticeTemplateForm.mailLogo">
                            <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                        </el-image>
                        <div class="pa100 mask_d5">
                            <el-button type="danger" size="mini" class="pa_cen cp" icon="el-icon-delete" circle @click="addOrderNoticeTemplateForm.mailLogo = null" />
                        </div>
                    </section>
                    <i class="el-icon-plus img-uploader-icon mt_0_i cp LogoImageBox" style="box-sizing: border-box; border: dotted 1px #aaa; border-radius: 4px" v-else @click="openImageUploader( 'addOrderNoticeTemplateForm.mailLogo' )"></i>
                    <span class="fs12" style="color: #909399;">店铺logo为邮件内显示的店铺图标，一般尺寸为50x50px</span>
                </el-form-item>
                <el-form-item label="邮件标题" prop="mailTitle">
                    <el-input maxlength="50" v-model="addOrderNoticeTemplateForm.mailTitle" />
                </el-form-item>
                <el-form-item label="邮件正文" prop="mailText">
                    <vue-tinymce :setup="editor => tinymceSetup( editor, 'tinymce_add_text' )" :setting="tinymceSetting" v-model="addOrderNoticeTemplateForm.mailText" />
                    <span class="fs12" style="color: #909399">文本格式<span style="color: #409eff">{customer.name}</span>=顾客姓名</span><br>

                    <template v-if="noticeType == 4">
                        <span class="fs12" style="color: #909399"><span style="color: #409eff">{shopGiftCardNotice.giftCardNo}</span>=礼品卡号</span><br>
                        <span class="fs12" style="color: #909399"><span style="color: #409eff">{shopGiftCardNotice.endTime}</span>=礼品卡到期</span><br>
                        <span class="fs12" style="color: #909399"><span style="color: #409eff">{shopGiftCardNotice.remainingAmount}</span>=礼品余额</span>
                    </template>

                    <span class="fs12" style="color: #909399" v-if="this.orderNoticeTemplate.orderNotice.id == 9">
                        ，<span style="color: #409eff">{customer.ver.code}</span>=验证码
                    </span>
                    <span class="fs12" style="color: #909399" v-if="this.orderNoticeTemplate.orderNotice.id == 5">
                        ，<span style="color: #409eff">{order.refundAmount}</span>=退款金额
                    </span>
                </el-form-item>
                <el-form-item label="邮件配图" prop="mailImage">
                    <section class="BannerImageBox pr df jc_c ai_c" v-if="addOrderNoticeTemplateForm.mailImage">
                        <el-image fit="contain" class="BannerImage" :src="addOrderNoticeTemplateForm.mailImage">
                            <div slot="error" class="el-image__error">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <div class="pa100 mask_d5">
                            <el-button type="danger" class="pa_cen cp" icon="el-icon-delete" circle @click="addOrderNoticeTemplateForm.mailImage = null" />
                        </div>
                    </section>
                    <i class="el-icon-plus img-uploader-icon cp BannerImageBox" style="border: dotted 1px #aaa; border-radius: 4px;" v-else @click="openImageUploader( 'addOrderNoticeTemplateForm.mailImage' )"></i>
                    <span class="fs12" style="color: #909399;">邮件配图为邮件内显示的图片，建议尺寸为440x230px</span>
                </el-form-item>
                <el-form-item label="按钮文案" prop="mailButtonText" :required="orderNoticeTemplate.orderNotice.isRecall || orderNoticeTemplate.orderNotice.id == 1">
                    <el-input maxlength="20" v-model="addOrderNoticeTemplateForm.mailButtonText" />
                </el-form-item>
                <el-form-item label="按钮跳转链接" prop="mailShopUrl" :required="orderNoticeTemplate.orderNotice.isRecall || orderNoticeTemplate.orderNotice.id == 1">
                    <el-input maxlength="50" :disabled="orderNoticeTemplate.orderNotice.isRecall || orderNoticeTemplate.orderNotice.id == 1" v-model="addOrderNoticeTemplateForm.mailShopUrl" />
                </el-form-item>
                <el-form-item label="邮件底部" prop="mailFoot">
                    <vue-tinymce :setup="editor => tinymceSetup( editor, 'tinymce_add_foot' )" :setting="tinymceSetting2" v-model="addOrderNoticeTemplateForm.mailFoot" />
                    <span class="fs12" style="color: #909399;">如需要使用邮件超链接，则在超链接地址填写mailto:邮箱地址，例如mailto:service@tivisiy.com</span>
                </el-form-item>
            </el-form>
            <footer slot="footer" class="dialog-footer">
                <el-button type="primary" :disabled="!addOrderNoticeTemplateDialog" :loading="addOrderNoticeTemplateLoading" @click="addOrderNoticeTemplate">保存</el-button>
                <el-button @click="addOrderNoticeTemplateDialog = false">取消</el-button>
            </footer>
        </el-dialog>
        <!-- 订单通知 - 新增模板 end -->

        <!-- 订单通知 - 编辑模板 -->
        <el-dialog top="3%" title="编辑模板" width="950px;" :visible.sync="dialogEditOrderNoticeTemplate" :close-on-click-modal="false">
            <el-form ref="editOrderNoticeTemplateForm" :model="editOrderNoticeTemplateForm" :rules="editOrderNoticeTemplateRules">
                <el-form-item label="模板名称" prop="tmplName">
                    <el-input maxlength="20" v-model="editOrderNoticeTemplateForm.tmplName"></el-input>
                </el-form-item>
                <el-form-item label="邮件店铺logo">
                    <div style="height: 40px;"></div>
                    <section class="LogoImageBox pr df jc_c ai_c" v-if="editOrderNoticeTemplateForm.mailLogo">
                        <el-image fit="contain" class="LogoImage" :src="editOrderNoticeTemplateForm.mailLogo">
                            <div slot="error" class="el-image__error">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <div class="pa100 mask_d5">
                            <el-button type="danger" class="pa_cen cp" icon="el-icon-delete" circle @click="editOrderNoticeTemplateForm.mailLogo = null">
                            </el-button>
                        </div>
                    </section>
                    <i class="el-icon-plus img-uploader-icon cp LogoImageBox" style="border: dotted 1px #aaa; border-radius: 4px" v-else @click="openImageUploader('editOrderNoticeTemplateForm.mailLogo')">
                    </i>
                    <span class="fs12" style="color: #909399">店铺logo为邮件内显示的店铺图标，一般尺寸为50x50px</span>
                </el-form-item>
                <el-form-item label="邮件标题" prop="mailTitle">
                    <el-input maxlength="50" v-model="editOrderNoticeTemplateForm.mailTitle"></el-input>
                </el-form-item>
                <el-form-item label="邮件正文" prop="mailText">
                    <div style="height: 40px;"></div>
                    <vue-tinymce :setup="editor => tinymceSetup( editor, 'tinymce_edit_text' )" :setting="tinymceSetting" v-model="editOrderNoticeTemplateForm.mailText"></vue-tinymce>
                    <span class="fs12" style="color: #909399">文本格式<span style="color: #409eff">{customer.name}</span>=顾客姓名</span><br>

                    <template v-if="noticeType == 4">
                        <span class="fs12" style="color: #909399"><span style="color: #409eff">{shopGiftCardNotice.giftCardNo}</span>=礼品卡号</span><br>
                        <span class="fs12" style="color: #909399"><span style="color: #409eff">{shopGiftCardNotice.endTime}</span>=礼品卡到期</span><br>
                        <span class="fs12" style="color: #909399"><span style="color: #409eff">{shopGiftCardNotice.remainingAmount}</span>=礼品余额</span>
                    </template>

                    <span class="fs12" style="color: #909399" v-if="this.orderNoticeTemplate.orderNotice.id == 9">
                        ，<span style="color: #409eff">{customer.ver.code}</span>=验证码
                    </span>
                    <span class="fs12" style="color: #909399" v-if="this.orderNoticeTemplate.orderNotice.id == 5">
                        ，<span style="color: #409eff">{order.refundAmount}</span>=退款金额
                    </span>
                </el-form-item>
                <el-form-item label="邮件配图" prop="mailImage">
                    <div style="height: 40px;"></div>
                    <section class="BannerImageBox pr df jc_c ai_c" v-if="editOrderNoticeTemplateForm.mailImage">
                        <el-image fit="contain" class="BannerImage" :src="editOrderNoticeTemplateForm.mailImage">
                            <div slot="error" class="el-image__error">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <div class="pa100 mask_d5">
                            <el-button type="danger" class="pa_cen cp" icon="el-icon-delete" circle @click="editOrderNoticeTemplateForm.mailImage = null">
                            </el-button>
                        </div>
                    </section>
                    <i class="el-icon-plus img-uploader-icon cp BannerImageBox" style="border: dotted 1px #aaa; border-radius: 4px" v-else @click="openImageUploader('editOrderNoticeTemplateForm.mailImage')">
                    </i>
                    <span class="fs12" style="color: #909399">邮件配图为邮件内显示的图片，建议尺寸为440x230px</span>
                </el-form-item>
                <el-form-item label="按钮文案" prop="mailButtonText" :required="orderNoticeTemplate.orderNotice.isRecall || orderNoticeTemplate.orderNotice.id == 1">
                    <el-input maxlength="20" v-model="editOrderNoticeTemplateForm.mailButtonText"></el-input>
                </el-form-item>
                <el-form-item label="按钮跳转链接" prop="mailShopUrl" :required="orderNoticeTemplate.orderNotice.isRecall || orderNoticeTemplate.orderNotice.id == 1">
                    <el-input maxlength="50" :disabled="orderNoticeTemplate.orderNotice.isRecall || orderNoticeTemplate.orderNotice.id == 1" v-model="editOrderNoticeTemplateForm.mailShopUrl"></el-input>
                </el-form-item>
                <el-form-item label="邮件底部" prop="mailFoot">
                    <div style="height: 40px"></div>
                    <vue-tinymce :setup="editor => tinymceSetup( editor, 'tinymce_edit_foot' )" :setting="tinymceSetting2" v-model="editOrderNoticeTemplateForm.mailFoot" />
                    <span class="fs12" style="color: #909399;">如需要使用邮件超链接，则在超链接地址填写mailto:邮箱地址，例如mailto:service@tivisiy.com</span>
                </el-form-item>
            </el-form>
            <footer slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading == 'editOrderNoticeTemplate'" @click="editOrderNoticeTemplate">保存</el-button>
                <el-button @click="dialogEditOrderNoticeTemplate = false">取消</el-button>
            </footer>
        </el-dialog>
        <!-- 订单通知 - 编辑模板 end -->

        <!-- 插入图片组件 -->
        <ImageUploader ref="uploadImage" :insertImage="dialogInsertImage" :insertType="insertType" :activeName="activeName" @getOpenState="getOpenState" @getImage="getImage">
        </ImageUploader>
        <!-- 插入图片组件 end -->

        <el-dialog :title="!isEditNotice ? '新增订单召回通知' : '编辑订单召回通知'" :visible.sync="dialogNotice" :close-on-click-modal="false" top="3%" width="500px">
            <el-form ref="dictNoticeForm" :rules="noticeRules" :model="dictNoticeForm" label-width="150px">
                <el-form-item label="订单召回通知名称" prop="recallName">
                    <el-input v-model="dictNoticeForm.recallName"></el-input>
                </el-form-item>
                <el-form-item label="选择优惠码" prop="discountCode">
                    <el-select v-model="dictNoticeForm.discountCode" filterable clearable placeholder="请选择">
                        <el-option v-for="(item,index) in getDiscountCodeOptions" :key="index" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="延迟发送时间" :inline="true">
                    <el-input-number :min="0" :precision="0" v-model="dictNoticeForm.delayTime" style="width:100px;"></el-input-number>
                    <el-select style="width:100px;" v-model="dictNoticeForm.delayTimeUnit" filterable clearable placeholder="请选择">
                        <el-option v-for="item in delayTimeUnitOptions" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否启用" prop="isOpen">
                    <el-select v-model="dictNoticeForm.isOpen" placeholder="请选择">
                        <el-option v-for="item in isopenOptions" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :disabled="noticeLoading" :loading="noticeLoading" @click="noticeSubmit">确定</el-button>
                <el-button @click="dialogNotice = false">取消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader';
import pic_customer_order_confirmed from '@/assets/customer_order_confirmed.png';
import pic_customer_order_shipped from '@/assets/customer_order_shipped.png';
import pic_customer_order_delivered from '@/assets/customer_order_delivered.png';
import pic_customer_order_canceled from '@/assets/customer_order_canceled.png';
import pic_customer_order_refunded from '@/assets/customer_order_refunded.png';
import pic_customer_order_recall from '@/assets/customer_order_recall.png';
import pic_customer_subscribed from '@/assets/customer_subscribed.jpg';
import pic_customer_register from '@/assets/customer_register.jpg';
import pic_customer_reset_password from '@/assets/customer_reset_password.jpg';
import pic_customer_order_airport from '@/assets/customer_order_confirmed.png';
import pic_customer_order_arrive from '@/assets/customer_order_confirmed.png';
import pic_customer_order_appreciate_remark from '@/assets/customer_order_confirmed.png';
import pic_customer_order_partial_delivery from '@/assets/customer_order_confirmed.png'; // 部分发货通知
import pic_gift_card from '@/assets/customer_gift_card.jpg';
import pic_gifts_price from '@/assets/customer_gifts_price.jpg';

export default {
    name: 'CustomerNotice',

    components: {
        ImageUploader: ImageUploader,
    },

    data() {
        return {
            noticeData: [],
            noticeDataLoading: false,
            isEditNotice: false,
            dialogNotice: false,
            noticeRules: {
                recallName: [
                    {
                        required: true,
                        message: '请输入通知名称',
                        trigger: 'blur',
                    },
                ],
                discountCode: [
                    {
                        required: true,
                        message: '请选择优惠码',
                        trigger: 'change',
                    },
                ],
                delayTime: [
                    {
                        required: true,
                        message: '请输入延迟时间',
                        trigger: 'blur',
                    },
                ],
                delayTimeUnit: [
                    {
                        required: true,
                        message: '请选择单位',
                        trigger: 'change',
                    },
                ],
                isOpen: [
                    {
                        required: true,
                        message: '请选择是否开启',
                        trigger: 'change',
                    },
                ],
            },
            dictNoticeForm: {
                recallName: null,
                discountCode: null,
                delayTime: null,
                delayTimeUnit: null,
                isOpen: null,
            },
            noticeLoading: false,
            getDiscountCodeOptions: [],
            delayTimeUnitOptions: [
                { id: 1, name: '秒' },
                { id: 2, name: '分钟' },
                { id: 3, name: '小时' },
            ],
            isopenOptions: [
                { id: 0, name: '否' },
                { id: 1, name: '是' },
            ],

            // 顾客通知预览图片
            pic_customer_order_confirmed,
            pic_customer_order_shipped,
            pic_customer_order_delivered,
            pic_customer_order_canceled,
            pic_customer_order_refunded,
            pic_customer_order_recall,
            pic_customer_subscribed,
            pic_customer_register,
            pic_customer_reset_password,
            pic_customer_order_airport,
            pic_customer_order_arrive,
            pic_customer_order_appreciate_remark,
            pic_customer_order_partial_delivery, // 部分发货通知
            pic_gift_card,
            pic_gifts_price,

            loading: '',
            btnLoading: '',
            noticeType: '1', // 通知类型

            // 插入图片参数
            dialogInsertImage: false,
            activeName: 'first',
            insertType: null,

            // tinymce setting
            tinymceSetting: {
                menubar: 'edit view format tools table',
                toolbar:
                    'undo redo removeformat | fullscreen | imageUpload | videoUpload | styleselect alignleft aligncenter alignright alignjustify | link | numlist bullist | fontselect fontsizeselect forecolor backcolor | table bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
                toolbar_mode: 'sliding',
                quickbars_insert_toolbar: 'quicktable',
                quickbars_selection_toolbar:
                    'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
                plugins: 'link image media table lists fullscreen quickbars',
                language: 'zh_CN',
                height: 350,
                convert_urls: false,
            },
            tinymceSetting2: {
                menubar: 'edit view format tools table',
                toolbar:
                    'undo redo removeformat | fullscreen | imageUpload | videoUpload | styleselect alignleft aligncenter alignright alignjustify | link | numlist bullist | fontselect fontsizeselect forecolor backcolor | table bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
                toolbar_mode: 'sliding',
                quickbars_insert_toolbar: 'quicktable',
                quickbars_selection_toolbar:
                    'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
                plugins: 'link image media table lists fullscreen quickbars',
                language: 'zh_CN',
                height: 220,
                convert_urls: false,
            },

            // 订单通知数据
            orderNoticeData: [],
            orderNoticeData3: [],
            orderNoticeData4: [],

            // 列表说明文字、缩略图
            orderNoticeMemo: {
                订单提交: {
                    info: '邮件通知给已下单的顾客，可查看到订单详情。',
                    thumbnail: pic_customer_order_confirmed,
                    check: '订单提交成功后，将自动发送邮件给顾客。',
                },

                发货通知: {
                    info: '邮件通知你的顾客订单已发货。如果你新增了物流单号，顾客会在这里看到它。',
                    thumbnail: pic_customer_order_shipped,
                    check: '当商品发货后，更新了订单物流单号，自动发送邮件给顾客。',
                },

                收货通知: {
                    info: '邮件通知你的顾客，包裹已经确认收货，感谢他的购买。',
                    thumbnail: pic_customer_order_delivered,
                    check: '当顾客确认收货时，自动发送邮件给顾客。',
                },

                订单取消: {
                    info: '邮件通知你的顾客订单已取消，可查看到相关取消的订单详情。',
                    thumbnail: pic_customer_order_canceled,
                    check: '在订单被取消后，将自动发送邮件给顾客。',
                },

                退款: {
                    info: '邮件通知你的顾客退款已返回到该用户支付的账号，可查看到相关退款订单详情。',
                    thumbnail: pic_customer_order_refunded,
                    check: '在订单退款后，将自动发送邮件给顾客。',
                },

                订单召回: {
                    info: 'xxxx。',
                    thumbnail: pic_customer_order_recall,
                    check: '订单召回成功后xxxx，将自动发送邮件给顾客。',
                },
                顾客订阅通知: {
                    info: '邮件通知你的顾客已订阅',
                    thumbnail: pic_customer_subscribed,
                    check: '顾客订阅成功后，将自动发送邮件给顾客',
                },
                顾客注册通知: {
                    info: '邮件通知你的顾客已成功注册为本店会员，并邀请顾客来浏览我们的网站',
                    thumbnail: pic_customer_register,
                    check: '当完成帐户激活时，自动发送邮件给顾客',
                },
                顾客重置密码通知: {
                    info: '邮件通知你的顾客正在进行重置密码，并发送重置密码的验证码',
                    thumbnail: pic_customer_reset_password,
                    check: '在重置密码后，将自动发送验证码到顾客的邮箱（必选）',
                },
                包裹运输到达机场: {
                    info: '邮件通知你的顾客包裹的运输状态已更新为到达目的地机场',
                    thumbnail: pic_customer_order_airport,
                    check: '当运单的运输状态更新为到达目的地机场时，自动发送邮件给顾客',
                },
                包裹已送达通知: {
                    info: '邮件通知你的顾客包裹的运输状态已更新为已送达',
                    thumbnail: pic_customer_order_arrive,
                    check: '当运单的运输状态更新为已送达时，自动发送邮件给顾客',
                },
                感谢评论通知: {
                    info: '邮件通知你的顾客，感谢他的评论，并赠送优惠码',
                    thumbnail: pic_customer_order_appreciate_remark,
                    check: '当顾客主动提交评论时，自动发送邮件给顾客',
                },

                部分发货通知: {
                    info: '邮件通知你的顾客订单已部分发货。如果你新增了物流单号，顾客会在这里看到它',
                    thumbnail: pic_customer_order_partial_delivery,
                    check: '当订单状态更新为部分发货的时候，自动发送部分发货的邮件',
                },

                礼品卡发放: {
                    info: '',
                    thumbnail: pic_gift_card,
                    check: '向顾客发放礼品卡时，将自动发送此邮件（此项为必填项，如果不勾选，用户将无法收到礼品卡）。',
                },

                礼品卡余额已更新: {
                    info: '',
                    thumbnail: pic_gifts_price,
                    check: '当礼品卡余额更新时，将自动向客户发送一封电子邮件。',
                },
            },

            // 订单通知 - 模板管理
            dialogOrderNoticeTemplate: false,
            orderNoticeTemplate: {
                orderNotice: {
                    // 订单通知
                    id: null,
                    name: '',
                    isRecall: false,
                },
                data: [],
                default: null, // 默认模板id
            },

            /* 订单通知 - 新增模板 */
            addOrderNoticeTemplateDialog: false,
            addOrderNoticeTemplateLoading: false,
            addOrderNoticeTemplateRules: {
                tmplName: {
                    required: true,
                    message: '请输入模板名称',
                    trigger: 'blur',
                },
                mailTitle: {
                    required: true,
                    message: '请输入邮件标题',
                    trigger: 'blur',
                },
                mailText: {
                    required: true,
                    message: '请输入邮件正文',
                    trigger: 'blur',
                },
                mailButtonText: {
                    validator: (rule, value, callback) => {
                        if (value?.replace(/ 　/g)) callback();
                        else {
                            if (
                                this.orderNoticeTemplate.orderNotice.isRecall ||
                                this.orderNoticeTemplate.orderNotice.id == 1
                            )
                                callback('请输入按钮文案');
                            else if (
                                this.addOrderNoticeTemplateForm.mailShopUrl
                            )
                                callback(
                                    '按钮文案和按钮跳转链接必须同时填写或同时为空'
                                );
                            else callback();
                        }
                    },

                    trigger: ['change', 'blur'],
                },
                mailShopUrl: {
                    validator: (rule, value, callback) => {
                        if (value?.replace(/ 　/g)) callback();
                        else {
                            if (this.addOrderNoticeTemplateForm.mailButtonText)
                                callback(
                                    '按钮文案和按钮跳转链接必须同时填写或同时为空'
                                );
                            else callback();
                        }
                    },

                    trigger: ['change', 'blur'],
                },
                mailFoot: {
                    required: true,
                    message: '请输入邮件底部',
                    trigger: 'blur',
                },
            },
            addOrderNoticeTemplateForm: {},
            /* 订单通知 - 新增模板 end */

            /* 订单通知 - 编辑模板 */
            dialogEditOrderNoticeTemplate: false,
            editOrderNoticeTemplateForm: {},
            editOrderNoticeTemplateRules: {
                tmplName: {
                    required: true,
                    message: '请输入模板名称',
                    trigger: 'blur',
                },
                mailTitle: {
                    required: true,
                    message: '请输入邮件标题',
                    trigger: 'blur',
                },
                mailText: {
                    required: true,
                    message: '请输入邮件正文',
                    trigger: 'blur',
                },
                mailButtonText: {
                    validator: (rule, value, callback) => {
                        if (value?.replace(/ 　/g)) callback();
                        else {
                            if (
                                this.orderNoticeTemplate.orderNotice.isRecall ||
                                this.orderNoticeTemplate.orderNotice.id == 1
                            )
                                callback('请输入按钮文案');
                            else if (
                                this.editOrderNoticeTemplateForm.mailShopUrl
                            )
                                callback(
                                    '按钮文案和按钮跳转链接必须同时填写或同时为空'
                                );
                            else callback();
                        }
                    },

                    trigger: ['change', 'blur'],
                },
                mailShopUrl: {
                    validator: (rule, value, callback) => {
                        if (value?.replace(/ 　/g)) callback();
                        else {
                            if (this.editOrderNoticeTemplateForm.mailButtonText)
                                callback(
                                    '按钮文案和按钮跳转链接必须同时填写或同时为空'
                                );
                            else callback();
                        }
                    },

                    trigger: ['change', 'blur'],
                },
                mailFoot: {
                    required: true,
                    message: '请输入邮件底部',
                    trigger: 'blur',
                },
            },
            /* 订单通知 - 编辑模板 end */
        };
    },

    mounted() {
        this.queryOrderNoticeData();
        this.queryOrderNoticeData(3);
        this.queryOrderNoticeData(4);
        this.getDiscountData();
        this.getNoticeData();
    },

    methods: {
        changeOpen(e, data) {
            this.noticeDataLoading = true;
            this.dictNoticeForm = {
                id: data.id,
                recallName: data.recallName,
                delayTime: data.delayTime,
                discountCode: data.discountCode,
                delayTimeUnit: data.delayTimeUnit,
                isOpen: e == true ? 1 : 0,
            };

            this.$http
                .post('/customer/notice/recall/edit', this.dictNoticeForm)
                .then((r) => {
                    this.noticeDataLoading = false;

                    if (r.data.code === 200) {
                        this.dialogNotice = false;
                        this.$message.success('编辑成功');
                        this.getNoticeData();
                    }
                })
                .finally(() => (this.noticeDataLoading = false));
        },

        getNoticeData() {
            this.noticeDataLoading = true;
            this.$http
                .get('/customer/notice/recall/getList')
                .then((r) => {
                    if (r.data.code === 200) {
                        this.noticeData = r.data.data;
                        this.noticeDataLoading = false;
                    }
                })
                .catch((err) => {
                    this.$message.error(err);
                    this.loading = '';
                });
        },
        addNotice() {
            this.isEditNotice = false;
            this.dictNoticeForm = {
                recallName: null,
                discountCode: null,
                delayTime: null,
                delayTimeUnit: null,
                isOpen: null,
            };
            this.noticeLoading = false;
            this.dialogNotice = true;
        },
        openEdit(data) {
            this.isEditNotice = true;
            this.dictNoticeForm = {
                id: data.id,
                recallName: data.recallName,
                delayTime: data.delayTime,
                discountCode: data.discountCode,
                delayTimeUnit: data.delayTimeUnit,
                isOpen: data.isOpen,
            };
            this.noticeLoading = false;
            this.dialogNotice = true;
        },
        noticeSubmit() {
            this.$refs['dictNoticeForm'].validate((valid) => {
                if (valid) {
                    this.noticeLoading = true;
                    let url;
                    if (this.isEditNotice) {
                        url = '/customer/notice/recall/edit';
                    } else {
                        url = '/customer/notice/recall/add';
                    }
                    this.$http
                        .post(url, this.dictNoticeForm)
                        .then((response) => {
                            this.noticeLoading = false;
                            if (response.data.code === 200) {
                                this.noticeLoading = false;
                                this.$message({
                                    message: '编辑成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: () => {
                                        this.getNoticeData();
                                    },
                                });
                                this.dialogNotice = false;
                            }
                        })
                        .catch((error) => {
                            this.$message.error(error);
                            this.noticeLoading = false;
                        });
                }
            });
        },
        getDiscountData() {
            this.$http
                .get('/campaign/code/getCodeList')
                .then((r) => {
                    if (r.data.code === 200) {
                        this.getDiscountCodeOptions = r.data.data;
                    }
                })
                .catch((err) => {
                    this.$message.error(err);
                    this.loading = '';
                });
        },
        // 插入图片方法
        openImageUploader(obj) {
            this.insertType = obj;
            this.dialogInsertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.dialogInsertImage = result;
        },
        getImage(obj, image) {
            switch (obj) {
                case 'addOrderNoticeTemplateForm.mailLogo':
                    this.addOrderNoticeTemplateForm.mailLogo =
                        image[image.length - 1];
                    break;

                case 'addOrderNoticeTemplateForm.mailImage':
                    this.addOrderNoticeTemplateForm.mailImage =
                        image[image.length - 1];
                    break;

                case 'editOrderNoticeTemplateForm.mailLogo':
                    this.editOrderNoticeTemplateForm.mailLogo =
                        image[image.length - 1];
                    break;

                case 'editOrderNoticeTemplateForm.mailImage':
                    this.editOrderNoticeTemplateForm.mailImage =
                        image[image.length - 1];
                    break;

                case 'tinymce_add_text':
                    this.addOrderNoticeTemplateForm.mailText += `<img src="${
                        image[image.length - 1]
                    }" />`;
                    break;

                case 'tinymce_add_foot':
                    this.addOrderNoticeTemplateForm.mailFoot += `<img src="${
                        image[image.length - 1]
                    }" />`;
                    break;

                case 'tinymce_edit_text':
                    this.editOrderNoticeTemplateForm.mailText += `<img src="${
                        image[image.length - 1]
                    }" />`;
                    break;

                case 'tinymce_edit_foot':
                    this.editOrderNoticeTemplateForm.mailFoot += `<img src="${
                        image[image.length - 1]
                    }" />`;
                    break;
            }
        },

        // tinymce setup
        tinymceSetup(editor, flag) {
            const that = this;

            editor.ui.registry.addButton('imageUpload', {
                tooltip: '插入图片',
                icon: 'image',
                onAction: () => {
                    that.openImageUploader(flag);
                },
            });
        },

        // 订单通知 - 获取数据
        queryOrderNoticeData(type = 1) {
            this.loading = 'queryOrderNoticeData';

            this.$http
                .get('/customer/notice/getList', {
                    params: { noticeType: type },
                })
                .then((r) => {
                    if (r.data.code === 200) {
                        this.loading = '';

                        let _type = type == 1 ? '' : type;

                        this[`orderNoticeData${_type}`] = r.data.data;
                        this[`orderNoticeData${_type}`].forEach((item) => {
                            item.isOpen = item.isOpen == 1;
                        });
                    }
                })
                .catch((err) => {
                    this.$message.error(err);
                    this.loading = '';
                });
        },

        // 订单通知 - 获取模板
        queryOrderNoticeTemplateData(orderNoticeId, isRecall = false) {
            this.loading = 'queryOrderNoticeTemplateData';

            this.$http
                .get(
                    isRecall
                        ? '/customer/notice/recall/getTmpl'
                        : '/customer/notice/getDetail',
                    { params: { id: orderNoticeId } }
                )
                .then((r) => {
                    if (r.data.code === 200) {
                        let data = r.data.data;

                        this.loading = '';

                        this.orderNoticeTemplate.data = isRecall
                            ? data
                            : data.customerNoticeTmplList;

                        let item = this.orderNoticeTemplate.data.find(
                            (v) => v.isDefault == 1
                        );

                        if (item)
                            this.orderNoticeTemplate.default = item
                                ? item.id
                                : null;
                    }
                })
                .catch((err) => {
                    this.$message.error(err);
                    this.loading = '';
                });
        },

        // 订单通知 - 模板列表
        listOrderNoticeTemplate(notice, isRecall = false) {
            this.orderNoticeTemplate.orderNotice = {
                id: notice.id,
                name: notice.noticeName,
                isRecall,
            };

            this.queryOrderNoticeTemplateData(notice.id, isRecall);
            this.dialogOrderNoticeTemplate = true;
        },

        // 订单通知 - 开启通知
        setOpenOrderNotice(item, val, type = 1) {
            this.$http
                .post('/customer/notice/editOpen', {
                    id: item.id,
                    isOpen: val ? 1 : 0,
                })
                .then((r) => {
                    if (r.data.code === 200) {
                        this.$message({
                            type: 'success',
                            message: '设置成功',
                            duration: 1000,
                            onClose: this.queryOrderNoticeData(type),
                        });
                    }
                })
                .catch((err) => {
                    this.$message.error(err);
                });
        },

        /* 订单通知 - 新增模板 */
        initAddOrderNoticeTemplate() {
            this.addOrderNoticeTemplateForm = {
                mailText: '',
                mailShopUrl: this.orderNoticeTemplate.orderNotice.isRecall
                    ? '默认跳转待支付的页面'
                    : this.orderNoticeTemplate.orderNotice.id == 1
                    ? '默认跳转订单详情页'
                    : undefined,
                mailFoot: '',
            };

            this.addOrderNoticeTemplateDialog = true;
        },
        addOrderNoticeTemplate() {
            let isRecall = this.orderNoticeTemplate.orderNotice.isRecall;

            this.addOrderNoticeTemplateForm[
                isRecall ? 'noticeRecallId' : 'noticeId'
            ] = this.orderNoticeTemplate.orderNotice.id;

            this.$refs.addOrderNoticeTemplateForm.validate((valid) => {
                if (valid) {
                    this.addOrderNoticeTemplateLoading = true;

                    this.$http
                        .post(
                            isRecall
                                ? '/customer/notice/recall/addTmpl'
                                : '/customer/notice/addTmpl',
                            this.addOrderNoticeTemplateForm
                        )
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.addOrderNoticeTemplateDialog = false;
                                this.$message({
                                    type: 'success',
                                    message: '新增成功',
                                    duration: 1000,
                                    onClose: this.queryOrderNoticeTemplateData(
                                        this.orderNoticeTemplate.orderNotice.id,
                                        isRecall
                                    ),
                                });
                            }
                        })
                        .finally(
                            () => (this.addOrderNoticeTemplateLoading = false)
                        );
                }
            });
        },
        addOrderNoticeTemplateDialogClosed() {
            this.$refs.addOrderNoticeTemplateForm?.resetFields();
            this.addOrderNoticeTemplateForm = {};
        },
        /* 订单通知 - 新增模板 end */

        /* 订单通知 - 编辑模板 */
        initEditOrderNoticeTemplate(item) {
            this.editOrderNoticeTemplateForm = {
                id: item.id,
                tmplName: item.tmplName,
                mailLogo: item.mailLogo,
                mailTitle: item.mailTitle,
                mailText: item.mailText,
                mailImage: item.mailImage,
                mailButtonText: item.mailButtonText,
                mailShopUrl: this.orderNoticeTemplate.orderNotice.isRecall
                    ? '默认跳转待支付的页面'
                    : this.orderNoticeTemplate.orderNotice.id == 1
                    ? '默认跳转订单详情页'
                    : item.mailShopUrl,
                mailFoot: item.mailFoot,
            };

            this.dialogEditOrderNoticeTemplate = true;
        },
        editOrderNoticeTemplate() {
            let isRecall = this.orderNoticeTemplate.orderNotice.isRecall;

            this.$refs.editOrderNoticeTemplateForm.validate((valid) => {
                if (valid) {
                    this.btnLoading == 'editOrderNoticeTemplate';

                    this.$http
                        .post(
                            isRecall
                                ? '/customer/notice/recall/editTmpl'
                                : '/customer/notice/editTmpl',
                            this.editOrderNoticeTemplateForm
                        )
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.dialogEditOrderNoticeTemplate = false;
                                this.$message({
                                    type: 'success',
                                    message: '编辑成功',
                                    duration: 1000,
                                    onClose: this.queryOrderNoticeTemplateData(
                                        this.orderNoticeTemplate.orderNotice.id,
                                        isRecall
                                    ),
                                });
                            }
                        })
                        .finally(() => (this.btnLoading = ''));
                }
            });
        },
        /* 订单通知 - 编辑模板 end */

        /* 订单通知 - 删除模板 */
        deleteOrderNoticeTemplate(template) {
            let isRecall = this.orderNoticeTemplate.orderNotice.isRecall;

            this.$confirm('确认删除此模板吗？', { type: 'warning' })
                .then(() => {
                    this.btnLoading = 'deleteOrderNoticeTemplate' + template.id;

                    this.$http
                        .delete('/customer/notice/deleteTmpl', {
                            params: { noticeTmplId: template.id },
                        })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                    duration: 1000,
                                    onClose: this.queryOrderNoticeTemplateData(
                                        this.orderNoticeTemplate.orderNotice.id,
                                        isRecall
                                    ),
                                });
                            }
                        })
                        .finally(() => (this.btnLoading = ''));
                })
                .catch(() => {});
        },
        /* 订单通知 - 删除模板 end */

        /* 订单通知 - 设置默认模板 */
        setDefaultOrderNoticeTemplate(template) {
            let isRecall = this.orderNoticeTemplate.orderNotice.isRecall;

            this.$http
                .post(
                    isRecall
                        ? '/customer/notice/recall/setDefault'
                        : '/customer/notice/setDefault',
                    { id: template.id }
                )
                .then((r) => {
                    if (r.data.code === 200) {
                        this.$message({
                            type: 'success',
                            message: '设置成功',
                            duration: 1000,
                            onClose: this.queryOrderNoticeTemplateData(
                                this.orderNoticeTemplate.orderNotice.id,
                                isRecall
                            ),
                        });
                    }
                });
        },
        /* 订单通知 - 设置默认模板 end */
    },
};
</script>

<style scoped>
/* 模板列表 */
.orderNoticeCover {
    color: #303133;
}

.orderNoticeCover_main {
    border-radius: 10px;
    box-shadow: #5689e4 0 -5px, #ccc 0 0 8px;
    color: #909399;
    background-color: #f9f9f9;
    overflow: hidden;
}

.orderNoticeCover_pop {
    background-color: rgba(255, 255, 255, 0.7);
    display: none;
}

.orderNoticeCover_main:hover .orderNoticeCover_pop {
    display: flex;
}

/* Logo图片 */
.LogoImageBox {
    width: 50px !important;
    height: 50px !important;
    background-color: #f5f6f8;
}

.LogoImage {
    max-width: 50px;
    max-height: 50px;
}

.LogoImageBox > .mask_d5 {
    display: none;
}

.LogoImageBox:hover > .mask_d5 {
    display: block;
}

/* Banner图片 */
.BannerImageBox {
    width: 440px !important;
    height: 230px !important;
    background-color: #f5f6f8;
}

.BannerImage {
    max-width: 440px;
    max-height: 230px;
}

.BannerImageBox > .mask_d5 {
    display: none;
}

.BannerImageBox:hover > .mask_d5 {
    display: block;
}

.long-checkbox {
    display: flex;
}
::v-deep .long-checkbox .el-checkbox__label {
    white-space: pre-wrap;
}
</style>