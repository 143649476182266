<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>店铺设置</el-breadcrumb-item>
            <el-breadcrumb-item>商品专题页</el-breadcrumb-item>
        </el-breadcrumb>

        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.collectionName" placeholder="请输入专辑名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="queryForm.topicTitle" placeholder="请输入专题名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="queryForm.topicTemplateName" placeholder="请输入模板名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
                <el-button type="success" icon="el-icon-plus" @click="queryAdd">新增模板</el-button>
            </el-form-item>
        </el-form>

        <el-table border :data="tableData" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000">
            <el-table-column label="ID" prop="id" width="50"></el-table-column>
            <el-table-column label="专题页名称" prop="topicTitle" min-width="120"></el-table-column>
            <el-table-column label="模板" prop="topicTemplateId" min-width="120">
                <template slot-scope="scope">
                    <p v-if="scope.row.topicTemplateId == 2">视频</p>
                    <p v-if="scope.row.topicTemplateId == 1">图片</p>
                </template>
            </el-table-column>
            <el-table-column label="选择专辑" prop="collectionName" min-width="120"></el-table-column>
            <!-- <el-table-column label="页面访问量" prop="collectionName" min-width="120"></el-table-column>
			<el-table-column label="访问用户数" prop="collectionName" min-width="120"></el-table-column>
			<el-table-column label="加购数" prop="collectionName" min-width="120"></el-table-column>
			<el-table-column label="下单成功数" prop="collectionName" min-width="120"></el-table-column>
			<el-table-column label="销量" prop="collectionName" min-width="120"></el-table-column>
			<el-table-column label="销售额" prop="collectionName" min-width="120"></el-table-column> -->
            <el-table-column label="创建时间" prop="createTime" width="100"></el-table-column>
            <el-table-column label="更新时间" prop="updateTime" width="100"></el-table-column>
            <el-table-column label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="openEdit(scope.row.id)">编辑</el-button>
                    <el-button type="text" size="mini" @click="openView(scope.row.seoUrl)">预览</el-button>
                    <el-button type="text" size="mini" @click="delThis(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background :current-page.sync="queryForm.pageNo" :page-sizes="[10, 30, 50]" :page-size.sync="queryForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <!-- 新增 -->
        <el-dialog top="3%" width="800px" title="新增商品" :visible.sync="dialogAdd" :close-on-click-modal="false">
            <el-form :model="dictAddForm" :rules="dictAddRules" status-icon ref="dictAddForm" label-position="top" size="medium">
                <el-form-item label="专题页名称" prop="topicTitle">
                    <el-input v-model="dictAddForm.topicTitle" placeholder="请输入商品标题">
                    </el-input>
                </el-form-item>
                <el-form-item label="选择模板" prop="topicTemplateId">
                    <el-radio-group v-model="dictAddForm.topicTemplateId" @change="resetTopic">
                        <el-radio :label="2">
                            <div class="topicbox" :class="dictAddForm.topicTemplateId == 2 ? 'active' : ''">
                                <img src="@/assets/yp1.jpg">
                                <p>视频模板</p>
                                <el-button type="primary" class="topicbtn" @click="openSee('video')">预览</el-button>
                            </div>
                        </el-radio>
                        <el-radio :label="1">
                            <div class="topicbox" :class="dictAddForm.topicTemplateId == 1 ? 'active' : ''">
                                <img src="@/assets/cp1.jpg">
                                <p>图片模板</p>
                                <el-button type="primary" class="topicbtn" @click="openSee('image')">预览</el-button>
                            </div>
                        </el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="PC端图片" v-if="dictAddForm.topicTemplateId == 1">
                    <main class="df fw_w ai_c">
                        <template v-if="dictAddForm.pcImage">
                            <section class="ImageBox">
                                <el-image fit="contain" class="Image" :src="dictAddForm.pcImage">
                                    <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                                </el-image>
                                <div class="mask_d5"></div>
                                <el-button type="danger" icon="el-icon-delete" circle class="ImageDeleteButton" @click="dictAddForm.pcImage = ''"></el-button>
                            </section>
                        </template>
                        <i class="el-icon-plus img-uploader-icon ImageAddButton" v-if="!dictAddForm.pcImage" @click="openImageUploader( 'dictAddForm.pcImage' )"></i>
                    </main>
                    <p style="color:red;margin:0;">*PC端图片尺寸请保持1920*675，否则页面效果不佳。</p>
                </el-form-item>

                <el-form-item label="移动端图片" v-if="dictAddForm.topicTemplateId == 1">
                    <main class="df fw_w ai_c">
                        <template v-if="dictAddForm.mpImage">
                            <section class="ImageBox">
                                <el-image fit="contain" class="Image" :src="dictAddForm.mpImage">
                                    <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                                </el-image>
                                <div class="mask_d5"></div>
                                <el-button type="danger" icon="el-icon-delete" circle class="ImageDeleteButton" @click="dictAddForm.mpImage = ''"></el-button>
                            </section>
                        </template>
                        <i class="el-icon-plus img-uploader-icon ImageAddButton" v-if="!dictAddForm.mpImage" @click="openImageUploader( 'dictAddForm.mpImage' )"></i>
                    </main>
                    <p style="color:red;margin:0;">*移动端图片尺寸请保持750*380，否则页面效果不佳。</p>
                </el-form-item>

                <el-form-item label="上传视频" v-if="dictAddForm.topicTemplateId == 2">
                    <main class="df fw_w ai_c">
                        <template v-if="dictAddForm.video">
                            <section class="ImageBox">
                                <div class="centergrid" @click="showVideo(dictAddForm.video)">
                                    <video width="100%" autoplay loop>
                                        <source :src="dictAddForm.video">
                                    </video>
                                </div>
                                <div class="mask_d5"></div>
                                <el-button type="danger" icon="el-icon-delete" circle class="ImageDeleteButton" @click="delVideo(dictAddForm.video)"></el-button>
                            </section>
                        </template>
                        <i class="el-icon-plus img-uploader-icon ImageAddButton" v-if="!dictAddForm.video" @click="openVideoUploader( 'dictAddForm.video' )"></i>
                    </main>
                </el-form-item>

                <el-form-item label="分配专辑" prop="collectionId">
                    <el-select v-model="dictAddForm.collectionId" placeholder="请选择分配专辑" filterable clearable>
                        <el-option v-for="(item,index) of specialOptions" :key="index" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="商品描述">
                    <vue-tinymce v-model="dictAddForm.topicDesc" :setup="setup" :setting="setting"></vue-tinymce>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogAdd = false">取消</el-button>
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
            </div>
        </el-dialog>

        <el-dialog top="3%" fullscreen title="模板样式预览" :visible.sync="dialogSee" :close-on-click-modal="false">
            <div v-if="seeMode == 'video'">
                <el-tabs v-model="activeSee" type="card">
                    <el-tab-pane label="PC端" name="pc">
                        <div class="seebox flex center">
                            <img src="@/assets/yp1.jpg">
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="移动端" name="mb">
                        <div class="seebox flex center">
                            <img src="@/assets/yp2.jpg">
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div v-else>
                <el-tabs v-model="activeSee" type="card">
                    <el-tab-pane label="PC端" name="pc">
                        <div class="seebox flex center">
                            <img src="@/assets/cp1.jpg">
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="移动端" name="mb">
                        <div class="seebox flex center">
                            <img src="@/assets/cp2.jpg">
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogSee = false">关闭</el-button>
            </div>
        </el-dialog>

        <!-- 插入图片 -->
        <ImageUploader ref="uploadImage" :insertImage="dialogInsertImage" :insertType="insertType" :activeName="activeName" @getOpenState="getOpenState" @getImage="getImage">
        </ImageUploader>
        <!-- 插入图片 end -->

        <!-- 插入图片 -->
        <VideoUploader ref="uploadVideo" :insertVideo="dialogInsertVideo" :insertVideoType="insertVideoType" :activeVideo="activeVideo" @getOpenVideo="getOpenVideo" @getVideo="getVideo">
        </VideoUploader>

        <el-dialog top="3%" :visible.sync="dialogVisibleVideo">
            <video width="100%" controls autoplay loop>
                <source :src="dialogVideoUrl">
            </video>
        </el-dialog>
        <!-- 插入图片 end -->

    </div>
</template>
<script>
import ImageUploader from '@/components/ImageUploader';
import VideoUploader from '@/components/VideoUploader';
export default {
    name: 'SpecialTopic',
    components: { ImageUploader, VideoUploader },
    data() {
        return {
            specialOptions: [],
            queryForm: {
                collectionName: null,
                topicTitle: null,
                topicTemplateName: null,
                pageNo: 1,
                pageSize: 10,
            },
            tableData: [],
            total: 0,
            loading: false,
            isEdit: false,
            dialogAdd: false,
            addLoading: false,
            dictAddForm: {
                topicTitle: null,
                topicTemplateId: 2,
                pcImage: null,
                mpImage: null,
                collectionId: null,
                topicDesc: '',
                video: null,
            },
            dictAddRules: {
                topicTitle: {
                    required: true,
                    message: '请输入专题页名称',
                    trigger: 'blur',
                },
                collectionId: {
                    required: true,
                    message: '请选择分配专辑',
                    trigger: 'change',
                },
            },

            dialogSee: false,
            activeSee: 'pc',
            seeMode: 'video',

            /* 插入图片参数 */
            dialogInsertImage: false,
            activeName: 'first',
            insertType: null,
            /* 插入图片参数 end */

            dialogInsertVideo: false,
            activeVideo: 'first',
            insertVideoType: null,
            dialogVisibleVideo: false,
            dialogVideoUrl: null,

            setting: {
                menubar: 'edit view format tools table',
                toolbar:
                    'undo redo removeformat | fullscreen | styleselect alignleft aligncenter alignright alignjustify | link | numlist bullist | fontselect fontsizeselect forecolor backcolor | table bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
                toolbar_mode: 'sliding',
                quickbars_insert_toolbar: 'quicktable',
                quickbars_selection_toolbar:
                    'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
                plugins:
                    'link image media table textpattern lists fullscreen quickbars',
                language: 'zh_CN',
                height: 350,
                convert_urls: false,
            },
        };
    },
    mounted() {
        this.queryData();
    },
    methods: {
        setup(editor) {
            const that = this;
            // editor.ui.registry.addButton("imageUpload", {
            //   tooltip: "插入图片",
            //   icon: "image",
            //   onAction: () => {
            //     that.setOpenInsert("tinymce");
            //   },
            // });
            // editor.ui.registry.addButton("videoUpload", {
            //   tooltip: "插入视频",
            //   icon: "embed",
            //   onAction: () => {
            //     that.openVideoUploader("tinymce");
            //   },
            // });
        },
        queryData() {
            this.loading = true;
            this.$http
                .get('/setting/topic/getPage', { params: this.queryForm })
                .then((response) => {
                    this.loading = false;
                    if (response.data.code === 200) {
                        this.tableData = response.data.data;
                        this.total = response.data.count;
                        this.getSpeical();
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    // this.$message.error(error);;
                });
        },
        getSpeical() {
            this.$http.get('/goods/collection/getNames').then((response) => {
                if (response.data.code === 200) {
                    this.specialOptions = response.data.data.map((item) => {
                        return {
                            id: item.id,
                            name: item.collectionName,
                        };
                    });
                }
            });
        },
        queryAdd() {
            this.dictAddForm = {
                topicTitle: null,
                topicTemplateId: 2,
                pcImage: null,
                mpImage: null,
                video: null,
                collectionId: null,
                topicDesc: '',
            };
            this.isEdit = false;
            this.addLoading = false;
            this.dialogAdd = true;
        },
        resetTopic(e) {
            if (e == 3) {
                this.dictAddForm.pcImage = null;
                this.dictAddForm.mpImage = null;
            } else {
                this.dictAddForm.video = null;
            }
        },
        addData() {
            this.$refs['dictAddForm'].validate((valid) => {
                if (valid) {
                    if (
                        this.dictAddForm.topicTemplateId == 2 &&
                        !this.dictAddForm.video
                    ) {
                        this.$message.error('请上传视频');
                    }
                    if (
                        this.dictAddForm.topicTemplateId == 1 &&
                        (!this.dictAddForm.pcImage || !this.dictAddForm.mpImage)
                    ) {
                        this.$message.error('请上传图片');
                    }

                    let url = '';
                    if (!this.isEdit) {
                        url = '/setting/topic/add';
                    } else {
                        url = '/setting/topic/edit';
                    }
                    this.addLoading = true;
                    this.$http
                        .post(url, this.dictAddForm)
                        .then((response) => {
                            if (response.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message.success('保存成功');
                                this.queryData();
                            }
                        })
                        .finally(() => {
                            this.addLoading = false;
                        });
                }
            });
        },
        showVideo(url) {
            this.dialogVideoUrl = url;
            this.dialogVisibleVideo = true;
        },
        delVideo(index) {
            this.dictAddForm.video = null;
            this.$forceUpdate();
        },
        /* 插入图片方法 */
        openImageUploader(obj) {
            this.insertType = obj;
            this.dialogInsertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        /* 插入视频方法 */
        openVideoUploader(obj) {
            this.insertVideoType = obj;
            this.dialogInsertVideo = true;
            this.$refs.uploadVideo.openInsert();
        },
        getOpenState(result) {
            this.dialogInsertImage = result;
        },
        getOpenVideo(result) {
            this.dialogInsertVideo = result;
        },
        getImage(obj, image) {
            switch (obj) {
                case 'dictAddForm.pcImage':
                    this.dictAddForm.pcImage = image[0];
                    break;
                case 'dictAddForm.mpImage':
                    this.dictAddForm.mpImage = image[0];
                    break;
            }
        },
        getVideo(obj, video) {
            switch (obj) {
                case 'dictAddForm.video':
                    this.dictAddForm.video = video[0];
                    break;
            }
        },
        openEdit(id) {
            this.loading = true;
            this.$http
                .get('/setting/topic/getDetail?id=' + id)
                .then((response) => {
                    this.loading = false;
                    if (response.data.code === 200) {
                        let item = JSON.parse(
                            JSON.stringify(response.data.data)
                        );

                        this.dictAddForm = {
                            id: item.id,
                            topicTitle: item.topicTitle,
                            topicTemplateId: item.topicTemplateId,
                            pcImage:
                                item.topicTemplateId == 1 ? item.pcImage : null,
                            mpImage:
                                item.topicTemplateId == 1 ? item.mpImage : null,
                            collectionId: item.collectionId,
                            topicDesc: item.topicDesc ? item.topicDesc : '',
                            video:
                                item.topicTemplateId == 2 ? item.video : null,
                        };

                        this.isEdit = true;
                        this.addLoading = false;
                        this.dialogAdd = true;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        openSee(type) {
            // console.log(type)
            this.seeMode = type;
            this.activeSee = 'pc';
            this.dialogSee = true;
        },
        openView(url) {
            window.open(url);
        },
        delThis(id) {
            this.$confirm('是否删除该商品专题页？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .delete('/setting/topic/delete', {
                        params: { id: id },
                    })
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                                duration: 1000,
                            });
                            this.queryData();
                        }
                    });
            });
        },
    },
};
</script>
<style scoped>
/*.main-wrap /deep/ .el-table__cell {
	padding: 4px 0;
}*/
.centergrid {
    max-width: 100%;
    max-height: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
}
.main-wrap /deep/ .el-radio__input {
    display: none;
}
.seebox {
    display: grid;
    place-items: center;
}
.topicbox {
    width: 150px;
    border: 1px solid #ccc;
    padding: 10px;
}
.topicbox.active {
    border: 1px solid #409eff;
}
.topicbox img {
    display: block;
    margin: 0 auto;
    max-width: 150px;
    max-height: 300px;
}
.topicbox p {
    text-align: center;
}
.topicbtn {
    display: block;
    margin: 0 auto;
}
/* 评论图片 */
.ImageAddButton {
    margin-bottom: 20px;
    box-sizing: border-box;
    border: dotted 1px #aaa;
    border-radius: 4px;
    width: 120px;
    height: 120px;
    cursor: pointer;
}

.ImageBox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 120px;
    height: 120px;
}

.Image {
    max-width: 100%;
    max-height: 100%;
}

.ImageBox > .mask_d5 {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    pointer-events: none;
}

.ImageDeleteButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: none;
}

.ImageBox:hover > .mask_d5,
.ImageBox:hover > .ImageDeleteButton {
    display: block;
}
/* 评论图片 end */
</style>