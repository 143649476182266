<template>
  <div class="main-wrap">
    <!-- 导航栏 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>任务中心</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询表单 -->
    <el-form :inline="true" :model="queryForm">
      <el-form-item>
        <el-input
          v-model="queryForm.taskName"
          placeholder="任务名称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="queryForm.taskType"
          placeholder="任务类型"
          clearable
        >
          <el-option
            v-for="item in taskTypeOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="queryForm.taskStatus"
          placeholder="任务状态"
          clearable
        >
          <el-option
            v-for="item in taskStatusOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="queryData"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 数据表 -->
    <el-table
      ref="taskTable"
      v-loading="loading"
      border
      stripe
      :data="tableData"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      height="1000"
    >
      <el-table-column prop="id" label="ID" width="80"></el-table-column>
      <el-table-column
        prop="taskName"
        label="任务名称"
        min-width="100"
      ></el-table-column>
      <el-table-column
        prop="taskType"
        label="任务类型"
        width="100"
        header-align="center"
        align="center"
        :formatter="taskTypeFormatter"
      ></el-table-column>
      <el-table-column
        prop="taskStatus"
        label="任务状态"
        width="100"
        header-align="center"
        align="center"
        :formatter="taskStatusFormatter"
      ></el-table-column>
      <el-table-column
        prop="resultMsg"
        label="任务执行结果信息"
        min-width="200"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="160"
        sortable
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        label="更新时间"
        width="160"
        sortable
      ></el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="100"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-search"
            @click="queryDetail(scope.row)"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page.sync="queryForm.pageNo"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size.sync="queryForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="queryData"
      @current-change="queryData"
    >
    </el-pagination>
    <!-- 业务弹框-任务详情 -->
    <el-dialog
      title="任务详情"
      :visible.sync="dialogDetail"
      :close-on-click-modal="false"
      top="3%"
      width="50%"
    >
      <el-descriptions
        border
        :column="2"
        v-loading="detailLoading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-descriptions-item label="任务名称" :span="2">
          {{ detailData.taskName }}
        </el-descriptions-item>
        <el-descriptions-item label="任务类型">
          {{ this.taskTypeFormatter(detailData) }}
        </el-descriptions-item>
        <el-descriptions-item label="任务状态">
          {{ this.taskStatusFormatter(detailData) }}
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">
          {{ detailData.createTime }}
        </el-descriptions-item>
        <el-descriptions-item label="更新时间">
          {{ detailData.updateTime }}
        </el-descriptions-item>
        <el-descriptions-item label="任务执行结果信息" :span="2">
          {{ detailData.resultMsg }}
        </el-descriptions-item>
        <el-descriptions-item label="任务文件" :span="2">
          <el-table
            ref="taskFileTable"
            border
            stripe
            :data="detailData.taskCenterFiles"
          >
            <el-table-column
              prop="fileName"
              label="文件名称"
              min-width="200"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              width="160"
              sortable
            ></el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="100"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  icon="el-icon-download"
                  :href="scope.row.fileUrl"
                  >下载</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "TaskCenter",
  data() {
    return {
      queryForm: {
        taskName: null,
        taskType: null,
        taskStatus: null,
        pageNo: 1,
        pageSize: 10,
      },
      taskTypeOptions: [
        {
          name: "导出数据",
          value: 1,
        },
        {
          name: "导入数据",
          value: 2,
        },
      ],
      taskStatusOptions: [
        {
          name: "待执行",
          value: 1,
        },
        {
          name: "进行中",
          value: 2,
        },
        {
          name: "已完成",
          value: 3,
        },
        {
          name: "已失败",
          value: 4,
        },
      ],
      tableData: [],
      total: 0,
      loading: false,
      dialogDetail: false,
      detailLoading: false,
      detailData: {},
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {
    queryData() {
      this.loading = true;
      this.$http
        .get("/task/center/getPage", { params: this.queryForm })
        .then((response) => {
          if (response.data.code === 200) {
            this.tableData = response.data.data;
            this.total = response.data.count;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    queryDetail(row) {
      this.detailLoading = true;
      this.$http
        .get("/task/center/getDetail", { params: { id: row.id } })
        .then((response) => {
          if (response.data.code === 200) {
            this.detailData = response.data.data;
          }
        })
        .finally(() => {
          this.detailLoading = false;
        });
      this.dialogDetail = true;
    },
    taskTypeFormatter(row) {
      for (let i = 0, len = this.taskTypeOptions.length; i < len; i++) {
        let item = this.taskTypeOptions[i];
        if (row.taskType === item.value) {
          return item.name;
        }
      }
      return null;
    },
    taskStatusFormatter(row) {
      for (let i = 0, len = this.taskStatusOptions.length; i < len; i++) {
        let item = this.taskStatusOptions[i];
        if (row.taskStatus === item.value) {
          return item.name;
        }
      }
      return null;
    },
  },
};
</script>

<style scoped></style>
