<template>
  <div class="main-wrap">
    <!-- 导航栏 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统告警日志</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询表单 -->
    <el-form :inline="true" :model="queryForm">
      <el-form-item>
        <el-input
          v-model="queryForm.alarmTitle"
          placeholder="告警标题"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="queryForm.alarmType"
          placeholder="告警类型"
          clearable
        >
          <el-option
            v-for="item in alarmTypeOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryForm.alarmContent"
          placeholder="告警内容"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="queryForm.alarmTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          unlink-panels
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          v-if="$auth($authData.system.alarmLogGet)"
          type="primary"
          icon="el-icon-search"
          @click="queryData"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 数据表 -->
    <el-table
      ref="logTable"
      v-loading="loading"
      border
      stripe
      :data="tableData"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      height="1000"
    >
      <el-table-column
        prop="alarmTitle"
        label="告警标题"
        min-width="100"
      ></el-table-column>
      <el-table-column
        prop="alarmType"
        label="告警类型"
        width="80"
        header-align="center"
        align="center"
        :formatter="alarmTypeFormatter"
      ></el-table-column>
      <el-table-column
        prop="alarmContent"
        label="告警内容"
        min-width="200"
      ></el-table-column>
      <el-table-column
        prop="alarmTime"
        label="告警时间"
        width="160"
        sortable
      ></el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page.sync="queryForm.pageNo"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size.sync="queryForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="queryData"
      @current-change="queryData"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "AlarmLog",
  data() {
    return {
      queryForm: {
        alarmTitle: null,
        alarmType: null,
        alarmContent: null,
        alarmTime: [],
        pageNo: 1,
        pageSize: 10,
      },
      alarmTypeOptions: [
        {
          name: "系统",
          value: 1,
        },
        {
          name: "中间件",
          value: 2,
        },
        {
          name: "接口",
          value: 3,
        },
      ],
      tableData: [],
      total: 0,
      loading: false,
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {
    queryData() {
      this.loading = true;
      let queryData = {
        alarmTitle: this.queryForm.alarmTitle,
        alarmType: this.queryForm.alarmType,
        alarmContent: this.queryForm.alarmContent,
        startTime: null,
        endTime: null,
        pageNo: this.queryForm.pageNo,
        pageSize: this.queryForm.pageSize,
      };
      if (this.queryForm.alarmTime && this.queryForm.alarmTime.length > 1) {
        queryData.startTime = this.queryForm.alarmTime[0];
        queryData.endTime = this.queryForm.alarmTime[1];
      }
      this.$http
        .get("/system/alarm/log/getPage", { params: queryData })
        .then((response) => {
          if (response.data.code === 200) {
            this.tableData = response.data.data;
            this.total = response.data.count;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    alarmTypeFormatter(row) {
      for (let i = 0, len = this.alarmTypeOptions.length; i < len; i++) {
        let item = this.alarmTypeOptions[i];
        if (row.alarmType === item.value) {
          return item.name;
        }
      }
      return null;
    },
  },
};
</script>

<style scoped></style>
