<!-- 这是一个小组件 -->
<template>
    <div>

        <!-- 新增评论 -->
        <el-dialog title="新增评论" top="3%" width="70%" :visible.sync="dialogAddGoodsComment" :close-on-click-modal="false" @closed="dialogAddGoodsCommentClosed">
            <el-form ref="addGoodsCommentForm" size="medium" label-position="top" status-icon :model="addGoodsCommentForm" :rules="addGoodsCommentRules">
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item label="评论分数" prop="grade">
                            <el-rate class="ol_n" style="margin-top: 6px;" v-model="addGoodsCommentForm.grade"></el-rate>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="初始点赞数" prop="likeNum">
                            <el-input-number :min="0" placeholder="请输入初始点赞数" v-model="addGoodsCommentForm.likeNum"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="评论日期" prop="commentTime">
                            <el-date-picker type="datetime" placeholder="请选择评论日期" value-format="yyyy-MM-dd HH:mm:ss" v-model="addGoodsCommentForm.commentTime"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="国家/地区" prop="countryId">
                            <el-select v-model="addGoodsCommentForm.countryId">
                                <el-option v-for="item in countryData" :key="item.id" :label="item.cnName" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="15">
                        <el-form-item label="评论内容" prop="content">
                            <el-input type="textarea" maxlength="200" show-word-limit placeholder="请输入评论内容" :autosize="{ minRows: 6, maxRows: 12 }" v-model="addGoodsCommentForm.content">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9">
                        <el-form-item label="评论人" prop="commentator">
                            <el-input maxlength="20" show-word-limit placeholder="请输入评论人" v-model="addGoodsCommentForm.commentator"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="评论图片">
                    <main class="df fw_w ai_c">
                        <template v-if="addGoodsCommentForm.images && addGoodsCommentForm.images.length > 0">
                            <section class="ImageBox" v-for="( item, index ) of addGoodsCommentForm.images" :key="index">
                                <el-image fit="contain" class="Image" :src="item" :preview-src-list="addGoodsCommentForm.images">
                                    <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                                </el-image>
                                <div class="mask_d5"></div>
                                <el-button type="danger" icon="el-icon-delete" circle class="ImageDeleteButton" @click="addGoodsCommentForm.images.splice( index, 1 )"></el-button>
                            </section>
                        </template>
                        <i class="el-icon-plus img-uploader-icon ImageAddButton" @click="openImageUploader( 'addGoodsCommentForm.images' )"></i>
                    </main>
                </el-form-item>

                <el-form-item label="评论视频">
                    <main class="df fw_w ai_c">
                        <template v-if="addGoodsCommentForm.videos && addGoodsCommentForm.videos.length > 0">
                            <section class="ImageBox" v-for="( item, index ) of addGoodsCommentForm.videos" :key="index">
                                <div class="centergrid" @click="showVideo(item)">
                                    <video width="100%" autoplay loop>
                                        <source :src="item">
                                    </video>
                                </div>
                                <div class="mask_d5"></div>
                                <el-button type="danger" icon="el-icon-delete" circle class="ImageDeleteButton" @click="delVideo(index)"></el-button>
                            </section>
                        </template>
                        <i class="el-icon-plus img-uploader-icon ImageAddButton" @click="openVideoUploader( 'addGoodsCommentForm.videos' )"></i>
                    </main>
                </el-form-item>

                <main>
                    <h3>商品信息</h3>
                    <el-form-item label="Overall Fit">
                        <el-radio-group v-model="addGoodsCommentForm.overallFit">
                            <el-radio :label="2">True to Size</el-radio>
                            <el-radio :label="1">Small</el-radio>
                            <el-radio :label="3">Large</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="商品款式" v-if="addGoodsCommentForm_property.length > 0">
                        <el-popover placement="top-end" effect="light" df class="ol_n">
                            <main>
                                <div class="dg jc_s ai_c mb_2" style="grid-template-columns: auto auto; gap: 30px" v-for="( row, index ) of addGoodsCommentForm_property" :key="index">
                                    <span>{{ row.key }}</span>
                                    <el-radio-group v-model="row.value" @change="changeProperty">
                                        <el-radio v-for="( item, index2 ) of row.values" :key="index2" :label="item">{{ item }}</el-radio>
                                    </el-radio-group>
                                </div>
                            </main>
                            <el-input slot="reference" disabled style="width: 500px;" v-model="addGoodsCommentForm.goodsOption">
                                <el-button slot="append" type="primary">选择款式</el-button>
                            </el-input>
                        </el-popover>
                        <el-button class="ml_2_i" @click="clearProperty">清除</el-button>
                    </el-form-item>
                    <div class="dg ai_s" style="grid-template-columns: repeat( 5, 1fr ); gap: 50px">
                        <el-form-item label="臀围（Hips）">
                            <el-input maxlength="20" v-model="addGoodsCommentForm.hips"></el-input>
                        </el-form-item>
                        <el-form-item label="身高（Height）">
                            <el-input maxlength="20" v-model="addGoodsCommentForm.height"></el-input>
                        </el-form-item>
                        <el-form-item label="腰围（Waist）">
                            <el-input maxlength="20" v-model="addGoodsCommentForm.waist"></el-input>
                        </el-form-item>
                        <el-form-item label="体重（Weight）">
                            <el-input maxlength="20" v-model="addGoodsCommentForm.weight"></el-input>
                        </el-form-item>
                        <p style="margin-top: 10px; color: #C0C4CC;">单位：cm、kg</p>
                    </div>
                </main>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="loadingAddGoodsComment" @click="addGoodsComment">确定</el-button>
                <el-button @click="dialogAddGoodsComment = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 新增评论 end -->

        <!-- 插入图片 -->
        <ImageUploader ref="uploadImage" :insertImage="dialogInsertImage" :insertType="insertType" :activeName="activeName" @getOpenState="getOpenState" @getImage="getImage">
        </ImageUploader>
        <!-- 插入图片 end -->

        <!-- 插入图片 -->
        <VideoUploader ref="uploadVideo" :insertVideo="dialogInsertVideo" :insertVideoType="insertVideoType" :activeVideo="activeVideo" @getOpenVideo="getOpenVideo" @getVideo="getVideo">
        </VideoUploader>

        <el-dialog top="3%" :visible.sync="dialogVisibleVideo">
            <video width="100%" controls autoplay loop>
                <source :src="dialogVideoUrl">
            </video>
        </el-dialog>
        <!-- 插入图片 end -->

    </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader';
import VideoUploader from '@/components/VideoUploader';

export default {
    name: 'AddGoodsComment',

    components: { ImageUploader, VideoUploader },

    data() {
        return {
            countryData: [], // 国家数据

            /* 插入图片参数 */
            dialogInsertImage: false,
            activeName: 'first',
            insertType: null,
            /* 插入图片参数 end */

            dialogInsertVideo: false,
            activeVideo: 'first',
            insertVideoType: null,
            dialogVisibleVideo: false,
            dialogVideoUrl: null,

            /* 新增评论 */
            dialogAddGoodsComment: false,
            loadingAddGoodsComment: false,
            addGoodsCommentForm_property: [], // 商品款式
            addGoodsCommentForm: {
                dataSource: 0,
                goodsId: null,
                grade: undefined,
                likeNum: undefined,
                commentTime: null,
                countryId: null,
                content: null,
                commentator: null,
                images: null,
                videos: null,
                overallFit: null,
                goodsOption: null,
                hips: undefined,
                height: undefined,
                waist: undefined,
                weight: undefined,
            },
            addGoodsCommentRules: {
                grade: [
                    {
                        required: true,
                        message: '请选择评论分数',
                        trigger: 'change',
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (value === 0) callback('请选择评论分数');
                            else callback();
                        },

                        trigger: 'change',
                    },
                ],
                likeNum: {
                    required: true,
                    message: '请输入初始点赞数',
                    trigger: 'blur',
                },
                commentTime: {
                    required: true,
                    message: '请选择评论日期',
                    trigger: 'change',
                },
                countryId: {
                    required: true,
                    message: '请选择国家/地区',
                    trigger: 'change',
                },
                content: {
                    required: true,
                    message: '请输入评论内容',
                    trigger: 'blur',
                },
                commentator: {
                    required: true,
                    message: '请输入评论人',
                    trigger: 'blur',
                },
                overallFit: {
                    required: true,
                    message: '请选择Overall Fit',
                    trigger: 'change',
                },
            },
            /* 新增评论 end */
        };
    },

    mounted() {
        this.$getData('countryData', '/common/country/getList'); // 获取国家数据
    },

    methods: {
        showVideo(url) {
            this.dialogVideoUrl = url;
            this.dialogVisibleVideo = true;
        },
        delVideo(index) {
            console.log(index);
            this.editGoodsCommentForm.videos.splice(index, 1);
            console.log(this.editGoodsCommentForm.videos);
            this.$forceUpdate();
        },
        /* 插入图片方法 */
        openImageUploader(obj) {
            this.insertType = obj;
            this.dialogInsertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        /* 插入视频方法 */
        openVideoUploader(obj) {
            this.insertVideoType = obj;
            this.dialogInsertVideo = true;
            this.$refs.uploadVideo.openInsert();
        },
        getOpenState(result) {
            this.dialogInsertImage = result;
        },
        getOpenVideo(result) {
            this.dialogInsertVideo = result;
        },
        getImage(obj, image) {
            switch (obj) {
                case 'addGoodsCommentForm.images':
                    if (!this.addGoodsCommentForm.images)
                        this.addGoodsCommentForm.images = [];
                    this.addGoodsCommentForm.images.push(...image);
                    break;
            }
        },
        getVideo(obj, video) {
            switch (obj) {
                case 'addGoodsCommentForm.videos':
                    if (!this.addGoodsCommentForm.videos)
                        this.addGoodsCommentForm.videos = [];
                    this.addGoodsCommentForm.videos.push(...video);
                    break;
            }
        },
        /* 插入图片方法 end */

        /* 初始化 */
        init(id) {
            this.addGoodsCommentForm.goodsId = id;
            this.dialogAddGoodsComment = true;

            // 商品款式
            this.$getData(null, '/goods/type/getGoodsType?goodsId=' + id).then(
                (d) => {
                    this.addGoodsCommentForm_property = d.map((item) => {
                        let newItem = {
                            key: item.typeName,
                            value: undefined,
                            values: item.typeValues.map((v) => v.valueText),
                        };

                        return newItem;
                    });
                }
            );
        },
        /* 初始化 end */

        /* 改变商品款式 */
        changeProperty() {
            this.addGoodsCommentForm.goodsOption =
                this.addGoodsCommentForm_property
                    .filter((v) => v.value != undefined)
                    .map((item) => `${item.key}: ${item.value}`)
                    .join(' ');
        },
        /* 改变商品款式 end */

        /* 清除商品款式 */
        clearProperty() {
            this.addGoodsCommentForm.goodsOption = null;
            this.addGoodsCommentForm_property.forEach(
                (item) => (item.value = undefined)
            );
        },
        /* 清除商品款式 end */

        /* 新增评论 */
        addGoodsComment() {
            this.$refs.addGoodsCommentForm.validate((valid) => {
                if (valid) {
                    this.loadingAddGoodsComment = true;

                    this.$http
                        .post('/goods/comment/add', this.addGoodsCommentForm)
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.dialogAddGoodsComment = false;

                                this.$message({
                                    message: '新增评论成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: () =>
                                        (this.dialogAddGoodsComment = false),
                                });

                                this.$emit('success');
                            }
                        })
                        .catch(() => (this.loadingAddGoodsComment = false));
                }
            });
        },
        dialogAddGoodsCommentClosed() {
            this.loadingAddGoodsComment = false;
            this.$refs.addGoodsCommentForm.resetFields();

            this.addGoodsCommentForm = {
                dataSource: 0,
                productId: null,
                grade: undefined,
                likeNum: undefined,
                commentTime: null,
                countryId: null,
                content: null,
                commentator: null,
                images: null,
                overallFit: null,
                goodsOption: null,
                hips: undefined,
                height: undefined,
                waist: undefined,
                weight: undefined,
            };

            this.addGoodsCommentForm_property = []; // 商品款式
        },
        /* 新增评论 end */
    },
};
</script>

<style scoped>
.centergrid {
    max-width: 100%;
    max-height: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
}
/* 评论图片 */
.ImageAddButton {
    margin-bottom: 20px;
    box-sizing: border-box;
    border: dotted 1px #aaa;
    border-radius: 4px;
    width: 120px;
    height: 120px;
    cursor: pointer;
}

.ImageBox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 120px;
    height: 120px;
}

.Image {
    max-width: 100%;
    max-height: 100%;
}

.ImageBox > .mask_d5 {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    pointer-events: none;
}

.ImageDeleteButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: none;
}

.ImageBox:hover > .mask_d5,
.ImageBox:hover > .ImageDeleteButton {
    display: block;
}
/* 评论图片 end */
</style>