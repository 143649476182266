<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商品管理</el-breadcrumb-item>
            <el-breadcrumb-item>商品专辑</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.collectionName" placeholder="商品专辑名称"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="initAddForm">新增</el-button>
            </el-form-item>
        </el-form>
        <!-- 数据表 -->
        <el-table border stripe :data="tableData" ref="goodsCollectionTable" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column label="专辑封面" width="180" align="center">
                <template slot-scope="scope">
                    <el-popover placement="right" trigger="hover">
                        <el-image fit="contain" style="width: 300px; height: 300px" :src="scope.row.collectionCover" :preview-src-list="[scope.row.collectionCover]">
                            <div slot="error" class="el-image__error" style="width: 300px; height: 300px; font-size: 50px">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <el-image fit="contain" slot="reference" style="width: 80px; height: 80px" :src="scope.row.collectionCover">
                            <div slot="error" class="el-image__error" style="font-size: 20px">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column prop="collectionName" label="专辑名称" min-width="120"></el-table-column>
            <el-table-column prop="collectionDesc" label="专辑描述" min-width="120"></el-table-column>
            <el-table-column prop="remark" label="专辑备注" min-width="120"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160" sortable></el-table-column>
            <el-table-column prop="updateTime" label="更新时间" width="160" sortable></el-table-column>
            <el-table-column fixed="right" label="操作" width="240" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-view" @click="openView(scope.row.viewUrl)">预览</el-button>
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="initEditForm(scope.row)">编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="editCation(scope.row.id)">分配</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="deleteData(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>
        <!-- 业务弹框-新增专辑 -->
        <el-dialog title="新增专辑" :visible.sync="dialogAdd" top="3%" width="540px" :close-on-click-modal="false">
            <el-form :model="goodsCollectionAddForm" :rules="goodsCollectionRules" status-icon ref="goodsCollectionAddForm" label-position="top" size="medium">
                <el-form-item label="商品专辑名称" prop="collectionName">
                    <el-input placeholder="请输入商品专辑名称" v-model="goodsCollectionAddForm.collectionName" maxlength="100" show-word-limit></el-input>
                </el-form-item>
                <el-row :gutter="20">
                    <el-col :span="15">
                        <el-form-item label="商品专辑描述" prop="collectionDesc">
                            <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 12 }" placeholder="请输入商品专辑描述" v-model="goodsCollectionAddForm.collectionDesc" maxlength="200" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9">
                        <el-form-item label="商品专辑封面" prop="collectionCover">
                            <el-button type="primary" @click="setOpenInsert('goodsCollectionAddForm.collectionCover')">选择</el-button>
                            <img :src="goodsCollectionAddForm.collectionCover" v-if="goodsCollectionAddForm.collectionCover" class="selectedMaterUrl" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="备注">
                    <el-input type="textarea" :rows="8" placeholder="请输入备注" v-model="goodsCollectionAddForm.remark" show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                <el-button @click="dialogAdd = false">取消</el-button>
            </div>
        </el-dialog>

        <!--插入图片-->
        <ImageUploader :insertImage="insertImage" :insertType="insertType" @getOpenState="getOpenState" @getImage="getImage" ref="uploadImage">
        </ImageUploader>
        <!-- 详情 -->
        <!-- <el-dialog
              title="专辑详情"
              :visible.sync="dialogDetail"
              top="3%"
              width="540px"
              :close-on-click-modal="false">
              <el-descriptions
                border
                :column="1"
                direction="vertical"
                v-loading="detailLoading"
                element-loading-text="加载中"
                element-loading-spinner="el-icon-loading"
              >
                <el-descriptions-item label="商品专辑名称">
                  {{ detailForm.collectionName }}
                </el-descriptions-item>
                <el-descriptions-item label="商品专辑描述">
                  {{ detailForm.collectionDesc }}
                </el-descriptions-item>
                <el-descriptions-item label="商品专辑封面">
                  {{ editForm.collectionCover }}
                </el-descriptions-item>
                <el-descriptions-item label="SEO标题">
                  {{ editForm.seoTitle }}
                </el-descriptions-item>
                <el-descriptions-item label="SEO描述">
                  {{ detailForm.seoDesc }}
                </el-descriptions-item>
                <el-descriptions-item label="SEO链接">
                  {{ detailForm.seoUrl }}
                </el-descriptions-item>
                <el-descriptions-item label="SEO关键词">
                  {{ detailForm.seoKeywordsArr }}
                </el-descriptions-item>
              </el-descriptions>
              <el-form
                :model="detailForm"
                status-icon
                ref="detailForm"
                label-position="top"
                size="medium"
              >
                <el-input type="hidden" v-model="detailForm.id"></el-input>
                <el-form-item label="商品专辑名称" prop="collectionName">
                  <el-input
                    placeholder="请输入商品专辑名称"
                    v-model="detailForm.collectionName"
                    maxlength="100"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-row>
                  <el-col>
                    <el-form-item label="商品专辑描述">
                      <el-input
                        type="textarea"
                        v-model="detailForm.collectionDesc"
                        maxlength="200"
                        show-word-limit
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col>
                    <el-form-item label="商品专辑封面">
                      <img
                        :src="editForm.collectionCover"
                        v-if="editForm.collectionCover"
                        class="selectedMaterUrl"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="SEO标题">
                  <div>标题是SEO最重要的部分，该标题会显示在搜索引擎的搜索结果中。</div>
                  <el-input
                    type="textarea"
                    placeholder="请输入SEO标题"
                    v-model="detailForm.seoTitle"
                    maxlength="5000"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="SEO描述">
                  <div>
                    这是该商品专辑的整体 SEO
                    描述，可以新增一些描述，使商品专辑在搜索引擎中获得更高的排名。
                  </div>
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 6, maxRows: 12 }"
                    placeholder="请输入SEO描述"
                    v-model="detailForm.seoDesc"
                    maxlength="5000"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="SEO链接">
                  <el-input
                    placeholder="请输入SEO链接"
                    v-model="detailForm.seoUrl"
                    maxlength="255"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="SEO关键词">
                  <div>Enter新增关键词</div>
                  <el-tag
                    :key="tag"
                    v-for="tag in detailForm.seoKeywordsArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="inputVisible"
                    v-model="inputValue"
                    ref="saveTagInput"
                    size="small"
                    @keyup.enter.native="handleInputConfirm"
                    @blur="handleInputConfirm"
                  >
                  </el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="showInput"
                    >+ 新增</el-button
                  >
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogDetail = false">取消</el-button>
              </div>
        </el-dialog> -->
        <!-- 编辑 -->
        <el-dialog title="专辑编辑" :visible.sync="dialogEdit" top="3%" width="800px" :close-on-click-modal="false">
            <el-form :model="editForm" :rules="editFormRules" status-icon ref="editForm" label-position="top" size="medium">
                <el-input type="hidden" v-model="editForm.id"></el-input>
                <el-form-item label="商品专辑名称" prop="collectionName">
                    <el-input placeholder="请输入商品专辑名称" v-model="editForm.collectionName" maxlength="100" show-word-limit></el-input>
                </el-form-item>
                <el-row :gutter="20">
                    <el-col :span="15">
                        <el-form-item label="商品专辑描述">
                            <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 12 }" placeholder="请输入商品专辑描述" v-model="editForm.collectionDesc" maxlength="200" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9">
                        <el-form-item label="商品专辑封面">
                            <el-button type="primary" @click="setOpenInsert('editForm.collectionCover')">选择</el-button>
                            <img :src="editForm.collectionCover" v-if="editForm.collectionCover" class="selectedMaterUrl" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="备注">
                    <el-input type="textarea" :rows="8" placeholder="请输入备注" v-model="editForm.remark" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="SEO标题">
                    <div>标题是SEO最重要的部分，该标题会显示在搜索引擎的搜索结果中。</div>
                    <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 12 }" placeholder="请输入SEO标题" v-model="editForm.seoTitle" maxlength="5000" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="SEO描述">
                    <div>
                        这是该商品专辑的整体 SEO
                        描述，可以新增一些描述，使商品专辑在搜索引擎中获得更高的排名。
                    </div>
                    <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 12 }" placeholder="请输入SEO描述" v-model="editForm.seoDesc" maxlength="5000" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="SEO链接">
                    <!-- <el-input class="specil" placeholder="请输入SEO链接" v-model="editForm.seoUrl" maxlength="255" show-word-limit></el-input> -->
                    <el-input class="specil" placeholder="请输入seo链接" v-model="splitLink.href">
                        <template slot="prepend">{{ splitLink.host }}</template>
                        <template slot="append">{{ splitLink.html }}</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="SEO关键词">
                    <div>Enter新增关键词</div>
                    <el-tag :key="tag" v-for="tag in editForm.seoKeywordsArr" closable :disable-transitions="false" @close="handleClose(tag)">
                        {{ tag }}
                    </el-tag>
                    <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="editData">确定</el-button>
                <el-button @click="dialogEdit = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 分配 -->
        <el-dialog title="分配商品" :visible.sync="dialogCation" top="3%" fullscreen :close-on-click-modal="false" @closed="closedDispense">

            <p class="explain-p">（切换分配模式，提交后当前分配的商品将会清空，请慎重选择！）</p>

            <el-radio-group v-model="assRadio" @change="radioChange">
                <el-radio :label="2">智能匹配</el-radio>
                <el-radio :label="1">手动匹配</el-radio>
            </el-radio-group>

            <div v-show="assRadio == 2" class="mt-20">
                <el-button type="primary" icon="el-icon-plus" @click="showAiDispense">分配
                </el-button>
            </div>

            <div v-show="assRadio == 1" class="mt-20">
                <el-button type="primary" icon="el-icon-plus" @click="openSelectGoods">选择商品
                </el-button>
            </div>

            <!-- 数据表 -->
            <!-- <el-table ref="selectedGoodsTable" class="cationTable mt_3" v-loading="cationloading" element-loading-text="加载中" element-loading-spinner="el-icon-loading"  stripe :data="selectedGoodsTable.data">
                <el-table-column width="50">
                    <template slot-scope="scope">
                        <el-button draggable="true" icon="el-icon-rank" @dragstart="handleEdit(scope.row)" circle></el-button>
                    </template>
                </el-table-column>

                <el-table-column label="商品图片" width="80">
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.goodsImages && scope.row.goodsImages.length > 0" fit="contain" style="width: 40px; height: 40px" :src="scope.row.goodsImages[0]" :preview-src-list="scope.row.goodsImages">
                            <div slot="error" class="el-image__error" style="width: 40px; height: 40px; font-size: 10px">
                                无图
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsTitle" label="商品名称" min-width="250px"></el-table-column>
                <el-table-column label="售价/原价" min-width="80">
                    <template slot-scope="scope">
                        {{ scope.row.price }} / {{ scope.row.compareAtPrice }}
                    </template>
                </el-table-column>
                <el-table-column prop="inventoryQuantity" label="库存" min-width="80"></el-table-column>
                <el-table-column label="是否上架" min-width="80">
                    <template slot-scope="scope">
                        {{ getPublished(scope.row.published) }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center" v-if="dispenseType == 1">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" @click="deleteSelectedGoods(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table> -->

            <div class="flex">
                <template v-if="selectedGoodsTable.data.length > 0">
                    <vuedraggable class="wrapper" v-model="selectedGoodsTable.data">
                        <div class="itembox" v-for="(item,indexB) in selectedGoodsTable.data" :key="indexB">
                            <div class="imgbox">
                                <GoodsImageTemplate :images="item.goodsImages" width="180" height="180" :hasHover="false"></GoodsImageTemplate>
                            </div>
                            <p class="tit">{{ item.goodsTitle }}</p>
                            <p class="tit">售价:{{ item.price }} / 原价:{{ item.compareAtPrice }}</p>
                            <p>库存: {{ item.inventoryQuantity }}</p>
                            <p class="between">
                                <span class="w50" title="置顶"><i class="el-icon-top" @click="toppingGoods(item)"></i></span>
                                <!-- <span class="w50" title="取消置顶"><i class="el-icon-top" @click="cancleToppingGoods(item)" v-else></i></span> -->
                                <span class="w50" @click="deleteSelectedGoods(item)" v-if="assRadio == 1"><i class="el-icon-delete"></i></span>
                            </p>
                            <span class="published publishedA" v-if="item.published == 0">{{ getPublishedB(item.published) }}</span>
                            <span class="published publishedB" v-if="item.published == 1">{{ getPublishedB(item.published) }}</span>
                        </div>
                    </vuedraggable>
                </template>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="updateAllocation" v-show="assRadio == 1">确定</el-button>
                <el-button type="primary" :loading="aiLoading" @click="updateAi" v-show="assRadio == 2">确定</el-button>
                <el-button @click="dialogCation = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 智能分配 -->
        <el-dialog title="智能自动分配" :visible.sync="visibilityAiDailog" top="3%" width="1000px" :close-on-click-modal="false">
            <el-form :model="aiForm" :inline="true" ref="aiForm" :rules="aiForm.rules">
                <div class="panel">
                    <!-- :model="aiForm.table" -->
                    <el-table stripe :data="aiForm.table" class="ai-table">
                        <el-table-column align="left">
                            <template slot-scope="scope">
                                <div class="panel-item">
                                    <el-form-item width="200px" :prop="'table.'+scope.$index + '.type'" :rules="aiForm.rules.type">
                                        <el-select v-model="scope.row['type']" placeholder="请选择条件" clearable filterable width="200px" @change="tagChange($event, scope.$index)">
                                            <el-option v-for="(sing, index) in classifyTag" :key="index" :label="sing.name" :value="sing.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>

                                    <div class="flex-mod">
                                        <div class="thumb" v-if="scope.row['type'] == 1">
                                            <el-form-item :prop="'table.'+scope.$index + '.operator'" :rules="aiForm.rules.operator">
                                                <el-select v-model="scope.row['operator']" placeholder="请选择条件" clearable filterable>
                                                    <el-option label="包含" :value="1"></el-option>
                                                    <el-option label="不包含" :value="2"></el-option>
                                                </el-select>
                                            </el-form-item>

                                            <el-form-item :prop="'table.'+scope.$index + '.tag'" :rules="aiForm.rules.tag">
                                                <el-cascader v-model="scope.row.tag" :options="tagData" :props="{
                                                    multiple: true,
                                                    value: 'id',
                                                    label: 'labelName',
                                                    children: 'shopGoodsLabelValues',
                                                    leaf: 'isNodes',
                                                }" clearable placeholder="款式属性"></el-cascader>
                                            </el-form-item>

                                        </div>

                                        <div class="thumb" v-if="scope.row['type'] == 2">
                                            <el-form-item :prop="'table.'+scope.$index + '.operator'" :rules="aiForm.rules.operator">
                                                <el-select v-model="scope.row['operator']" placeholder="请选择条件" clearable filterable>
                                                    <el-option label="包含" :value="1"></el-option>
                                                    <el-option label="不包含" :value="2"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <!-- leaf: 'isNodes', -->
                                            <el-form-item :prop="'table.'+scope.$index + '.style'" :rules="aiForm.rules.style">
                                                <el-cascader v-model="scope.row.style" :options="styleData" :props="{
                                                    multiple: true,
                                                    value: 'id',
                                                    label: 'typeName',
                                                    children: 'typeValues',
                                                    leaf: 'isNodes',
                                                    }" clearable placeholder="商品款式"></el-cascader>
                                            </el-form-item>
                                        </div>

                                        <div class="thumb" v-if="scope.row['type'] == 3">
                                            <el-form-item :prop="'table.'+scope.$index + '.operator'" :rules="aiForm.rules.operator">
                                                <el-select v-model="scope.row['operator']" placeholder="请选择条件" clearable filterable>
                                                    <el-option label="包含" :value="1"></el-option>
                                                    <el-option label="不包含" :value="2"></el-option>
                                                </el-select>
                                            </el-form-item>

                                            <el-form-item :prop="'table.'+scope.$index + '.classify'" :rules="aiForm.rules.classify">
                                                <el-cascader v-model="scope.row.classify" :options="classifyData" :props="{
                                                        multiple: true,
                                                        value: 'id',
                                                        label: 'categoryNameCn',
                                                        children: 'categoryItemList',
                                                        checkStrictly:true,
                                                        leaf: 'isNodes',
                                                    }" clearable placeholder="分类"></el-cascader>
                                            </el-form-item>
                                        </div>

                                        <div class="thumb" v-if="scope.row['type'] > 3">
                                            <div class="day-mod" v-if="scope.row['type'] > 7">
                                                近
                                                <el-form-item :prop="'table.'+scope.$index + '.days'" :rules="aiForm.rules.days">
                                                    <el-input v-model="scope.row['days']" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                                                </el-form-item>
                                                天
                                            </div>
                                            <el-form-item v-if="scope.row['type'] == 7" :prop="'table.'+scope.$index + '.days'" :rules="aiForm.rules.days">
                                                <el-select v-model="scope.row['days']" placeholder="请选择天数" clearable filterable>
                                                    <el-option label="最近7天" :value="7"></el-option>
                                                    <el-option label="最近15天" :value="15"></el-option>
                                                    <el-option label="最近30天" :value="30"></el-option>
                                                    <el-option label="最近60天" :value="60"></el-option>
                                                </el-select>
                                            </el-form-item>

                                            <el-form-item v-if="scope.row['type'] != 7" :prop="'table.'+scope.$index + '.operator'" :rules="aiForm.rules.operator">
                                                <el-select v-model="scope.row['operator']" placeholder="请选择条件" clearable filterable>
                                                    <el-option label="大于" :value="3"></el-option>
                                                    <el-option label="小于" :value="4"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item v-if="scope.row['type'] != 7" :prop="'table.'+scope.$index + '.text'" :rules="aiForm.rules.text">
                                                <el-input v-model="scope.row['text']" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                                            </el-form-item>
                                        </div>
                                    </div>

                                    <a class="el-icon-delete" @click="removeLine(scope.$index)"></a>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-link type="primary" @click="addAiItem">新增一条</el-link>
                </div>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="defineAiDispense">确定</el-button>
                <el-button @click="visibilityAiDailog = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 选择商品 -->
        <SelectGoods ref="selectGoods" :showDialog="dialogSelectGoods" :containItem="false" :selectedGoods="selectedGoodsTable.data" @closeDialog="closeSelectGoods" @saveSelectedGoods="saveSelectGoods">
        </SelectGoods>
    </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader';
import SelectGoods from '@/components/SelectGoods';
import Sortable from 'sortablejs';
import vuedraggable from 'vuedraggable';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
export default {
    name: 'GoodsCollection',
    components: {
        ImageUploader: ImageUploader,
        SelectGoods: SelectGoods,
        vuedraggable,
        GoodsImageTemplate,
    },
    data() {
        return {
            splitLink: {},
            goodsQueryAi: {},
            aiLoading: false,
            cationloading: false,
            assRadio: 1,
            visibilityAiDailog: false,
            aiForm: {
                table: [
                    {
                        tag: null,
                        style: null,
                        classify: null,
                        type: null,
                        operator: null,
                        days: null,
                        text: null,
                        date: null,
                    },
                ],
                rules: {
                    input: [
                        {
                            required: true,
                            message: '不能为空',
                            trigger: 'blur',
                        },
                    ],
                    operator: [
                        {
                            required: true,
                            message: '请选择条件',
                            trigger: 'change',
                        },
                    ],
                    days: [
                        {
                            required: true,
                            message: '请填写天数',
                            trigger: 'blur',
                        },
                    ],
                    date: [
                        {
                            required: true,
                            message: '请选择天数',
                            trigger: 'change',
                        },
                    ],
                    text: [
                        {
                            required: true,
                            message: '请填写文本',
                            trigger: 'blur',
                        },
                    ],
                    type: [
                        {
                            type: 'number',
                            required: true,
                            message: '类型不能为空',
                            trigger: 'change',
                        },
                    ],
                    style: [
                        {
                            required: true,
                            message: '请选择商品款式',
                            trigger: 'blur',
                        },
                    ],
                    classify: [
                        {
                            required: true,
                            message: '请选择商品分类',
                            trigger: 'blur',
                        },
                    ],
                    tag: [
                        {
                            required: true,
                            message: '请选择款式属性',
                            trigger: 'blur',
                        },
                    ],
                },
            },
            deepAiTable: [
                {
                    tag: null,
                    style: null,
                    classify: null,
                    type: null,
                    operator: null,
                    days: null,
                    text: null,
                    date: null,
                },
            ],
            classifyTag: [
                { name: '款式属性', value: 1 },
                { name: '商品款式', value: 2 },
                { name: '分类', value: 3 },
                { name: '价格', value: 4 },
                { name: '库存', value: 5 },
                { name: '重量', value: 6 },
                { name: '上架时间', value: 7 },
                { name: '总销量', value: 8 },
                { name: '访问量', value: 9 },
                { name: '加购数', value: 10 },
            ],
            typeTag: [4, 5, 6, 8, 9, 10],
            tagData: [],
            styleData: [],
            classifyData: [],
            dispenseType: null,

            // 选择商品参数
            dialogSelectGoods: false,
            // 选中的商品列表数据
            selectedGoodsTable: {
                data: [],
                total: 0,
            },
            deepSelectedGoodsTable: [],
            queryForm: {
                collectionName: null,
                pageNo: 1,
                pageSize: 10,
            },
            tableData: [],
            total: 0,
            loading: false,
            goodsCollectionRules: {
                collectionName: [
                    {
                        required: true,
                        message: '请输入商品专辑名称',
                        trigger: 'blur',
                    },
                ],
            },
            dialogAdd: false,
            dialogEdit: false,
            dialogCation: false,
            goodsCollectionAddForm: {},
            addLoading: false,
            insertImage: false,
            insertType: null,
            editForm: {
                collectionCover: '',
                collectionDesc: '',
                collectionName: '',
                remark: '',
                id: '',
                seoDesc: '',
                seoTitle: '',
                seoUrl: '',
                seoKeywords: [],
                seoKeywordsArr: [],
            },
            editFormRules: {
                collectionName: [
                    {
                        required: true,
                        message: '请输入商品专辑名称',
                        trigger: 'blur',
                    },
                ],
            },
            inputVisible: false,
            inputValue: '',
            cationForm: {
                collectionId: '',
                goodsTitle: '',
                pageNo: 1,
                pageSize: 10,
                sku: '',
                spu: '',
                vendor: '',
            },
            cationFormTotal: 0,
            cationList: [], //查询商品列表
            multipleSelection: [], //查询商品列表选中项
            goodsCollection: [], //本身已有的分配商品
            cationGoodsIds: [], //提交的goodsids
            // detailForm:{},
            // dialogDetail: false,
            // detailLoading: false
        };
    },
    computed: {
        insertDisabled() {
            return this.insertLoading || this.insertNoMore;
        },
    },
    mounted() {
        this.queryData();
        this.setTagList();

        // let el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
        // let sortable = Sortable.create(el)
    },
    methods: {
        handleEdit(val) {
            console.log(val);
            // this.id = val.id  //这里就是当前拖动行的值，把需要的值赋值给你之前定义好的就可以了
        },
        // 选择商品方法
        openSelectGoods() {
            this.$refs.selectGoods.caller = 'notset';
            this.$refs.selectGoods.init();
            this.dialogSelectGoods = true;
        },
        closeSelectGoods() {
            this.dialogSelectGoods = false;
        },
        saveSelectGoods(items, caller) {
            switch (caller) {
                default:
                    this.selectedGoodsTable.data = items;
                    this.selectedGoodsTable.total =
                        this.selectedGoodsTable.data.length;
            }
        },
        // 置顶
        toppingGoods(item) {
            let tempitem = JSON.parse(JSON.stringify(item));
            tempitem.top = 1;
            this.selectedGoodsTable.data.unshift(tempitem);
            let index = this.selectedGoodsTable.data.indexOf(item);
            this.selectedGoodsTable.data.splice(index, 1);
        },
        // 删除选中的商品（删除）
        deleteSelectedGoods(item) {
            let index = this.selectedGoodsTable.data.indexOf(item);

            if (index > -1) {
                this.selectedGoodsTable.data.splice(index, 1);
                this.selectedGoodsTable.total =
                    this.selectedGoodsTable.data.length;
            }
        },
        setOpenInsert(obj) {
            this.insertType = obj;
            this.insertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.insertImage = result;
        },
        getImage(obj, image) {
            if (obj == 'goodsCollectionAddForm.collectionCover') {
                this.goodsCollectionAddForm.collectionCover =
                    image[image.length - 1];
            }
            if (obj == 'editForm.collectionCover') {
                this.editForm.collectionCover = image[image.length - 1];
            }
        },
        openView(url) {
            window.open(url);
        },
        splitMethod(url) {
            // console.log(url)
            let arr = url.split('/');
            console.log(arr);

            let host = arr[0] + '//' + arr[2] + '/' + arr[3] + '/';

            let temphtml = arr[4].split('-');
            console.log(temphtml);
            let html = '-' + temphtml[temphtml.length - 1];

            let temphref = arr[4].split(html);
            let href = temphref[0];

            this.splitLink = {
                host: host,
                href: href,
                html: html,
            };
        },
        initEditForm(row) {
            let id = row.id;
            this.insertLoading = true;
            this.$http
                .get('/goods/collection/get?id=' + id)
                .then((response) => {
                    this.insertLoading = false;
                    if (response.data.code === 200) {
                        let item = JSON.parse(
                            JSON.stringify(response.data.data)
                        );
                        this.editForm.collectionCover = item.collectionCover;
                        this.editForm.collectionDesc = item.collectionDesc;
                        this.editForm.collectionName = item.collectionName;
                        this.editForm.id = item.id;
                        this.editForm.remark = item.remark;
                        this.editForm.seoDesc = item.seoDesc;
                        this.editForm.seoKeywords = [];
                        this.editForm.seoKeywordsArr = item.seoKeywords || [];
                        this.editForm.seoTitle = item.seoTitle;
                        this.editForm.seoUrl = item.seoUrl;

                        this.splitMethod(this.editForm.seoUrl);

                        this.dialogEdit = true;
                    }
                })
                .catch((error) => {
                    // this.$message.error(error);
                });
        },
        editCation(id) {
            this.cationForm.collectionId = id;
            this.cationForm.pageNo = 1;
            this.getGoodsCollection();
            this.dialogCation = true;
            this.cationForm.goodsTitle = '';
            this.cationForm.sku = '';
            this.cationForm.spu = '';
            this.cationForm.vendor = '';
            this.selectedGoodsTable.data = []; // 选中的商品
            this.selectedGoodsTable.total = 0;
        },
        getGoodsCollection(isAi) {
            this.cationloading = true;
            this.$http
                .get('/goods/collection/get?id=' + this.cationForm.collectionId)
                .then((response) => {
                    if (response.data.code === 200) {
                        let { type, typeJson } = response.data.data;

                        this.selectedGoodsTable.data =
                            response.data.data.goodsList;
                        this.selectedGoodsTable.data.total =
                            response.data.data.goodsList.length;
                        this.deepSelectedGoodsTable = JSON.parse(
                            JSON.stringify(this.selectedGoodsTable.data)
                        );

                        if (type) {
                            this.assRadio = type;
                            this.dispenseType = type;
                        }

                        if (isAi) {
                            this.assRadio = 2;
                            this.dispenseType = 2;
                        }

                        this.cationloading = false;

                        this.setDispenseData(typeJson, type);

                        // let el = document.querySelectorAll('.cationTable > .el-table__body-wrapper > table > tbody')[0];
                        // // console.log(el)
                        // const sortable = Sortable.create(el, {
                        //     onEnd: evt => {  //监听拖动结束事件
                        //       let proId = this.id; //就是我们上面获取到当前行的id值
                        //       console.log(evt.oldIndex, evt.newIndex) //当前行的被拖拽前的顺序
                        //       // console.log(evt.newIndex) //当前行的被拖拽后的顺序
                        //       // 这里就可以写我们需要传给后台的逻辑代码\
                        //       let beforeRow = this.selectedGoodsTable.data[evt.newIndex]
                        //       let currentRow = this.selectedGoodsTable.data[evt.oldIndex]
                        //       console.log(beforeRow.goodsTitle, '/', currentRow.goodsTitle)
                        //     }
                        // })
                    }
                })
                .catch((error) => {
                    this.cationloading = false;
                    // this.$message.error(error);
                });
        },
        getCationList(num) {
            this.multipleSelection = [];
            if (num == 1) {
                this.cationForm.pageNo = 1;
            }
            this.loading = true;
            this.$http
                .get('/goods/getPage', { params: this.cationForm })
                .then((response) => {
                    this.loading = false;
                    if (response.data.code === 200) {
                        this.cationList = response.data.data;
                        this.cationFormTotal = response.data.count;
                        this.$nextTick(() => {
                            this.goodsCollection.forEach((row) => {
                                this.$refs.multipleTable.toggleRowSelection(
                                    this.cationList.find((item) => {
                                        return row.id == item.id;
                                    }),
                                    true
                                );
                                // console.log(this.$refs.multipleTable,this.cationList)
                            });
                        });
                        this.dialogCation = true;
                    }
                })
                .catch((error) => {
                    // this.$message.error(error);
                    this.loading = false;
                });
        },
        goodsCollectionClose(tag) {
            // console.log(tag)
            this.goodsCollection.forEach((row) => {
                this.$refs.multipleTable.toggleRowSelection(
                    this.cationList.find((item) => {
                        // console.log(row.id, item.id);
                        return tag.id == item.id;
                    }),
                    false
                );
            });
            this.goodsCollection.splice(this.goodsCollection.indexOf(tag), 1);
            // console.log('goodsCollection',this.goodsCollection)
        },
        getRowsDetail(select, row) {
            let json = {
                goodsImages: row.goodsImages,
                goodsTitle: row.goodsTitle,
                id: row.id,
            };
            let alreadyHas = false;
            let indexId = null;
            for (let i = 0; i < this.goodsCollection.length; i++) {
                if (row.id == this.goodsCollection[i].id) {
                    alreadyHas = true;
                    indexId = i;
                }
            }
            if (alreadyHas) {
                this.goodsCollection.splice(indexId, 1);
            } else {
                this.goodsCollection.push(json);
            }
        },
        getAllRowsDetail(selection) {
            for (let i = 0; i < selection.length; i++) {
                if (selection[i] == undefined) {
                    selection.splice(i, 1);
                }
            }
            if (selection.length > 0) {
                for (let i = 0; i < selection.length; i++) {
                    let row = selection[i];
                    if (row) {
                        let json = {
                            goodsImages: row.goodsImages,
                            goodsTitle: row.goodsTitle,
                            id: row.id,
                        };
                        let alreadyHas = false;
                        for (let k = 0; k < this.goodsCollection.length; k++) {
                            if (row.id == this.goodsCollection[k].id) {
                                alreadyHas = true;
                            }
                        }
                        if (!alreadyHas) {
                            this.goodsCollection.push(json);
                        }
                    }
                }
            } else {
                for (let i = 0; i < this.cationList.length; i++) {
                    let row = this.cationList[i];
                    let json = {
                        goodsImages: row.goodsImages,
                        goodsTitle: row.goodsTitle,
                        id: row.id,
                    };
                    let alreadyHas = false;
                    let indexId = null;
                    for (let k = 0; k < this.goodsCollection.length; k++) {
                        if (row.id == this.goodsCollection[k].id) {
                            alreadyHas = true;
                            indexId = k;
                        }
                    }
                    if (alreadyHas) {
                        this.goodsCollection.splice(indexId, 1);
                    }
                }
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            // console.log('multipleSelection',this.multipleSelection)
        },
        updateAllocation() {
            this.cationGoodsIds = [];
            for (let i = 0; i < this.selectedGoodsTable.data.length; i++) {
                this.cationGoodsIds.push(this.selectedGoodsTable.data[i].id);
            }
            // for (let i = 0; i < this.selectedGoodsTable.data.length; i++) {
            //     // this.cationGoodsIds.push(this.selectedGoodsTable.data[i].id);
            //     let item = this.selectedGoodsTable.data[i]
            //     let json = {
            //         collectionId: this.cationForm.collectionId,
            //         goodsId: item.id,
            //         top: item.top ? item.top : 0,
            //     }
            //     this.cationGoodsIds.push(json)
            // }
            // let tops = []
            // for (let i = 0; i < this.selectedGoodsTable.data.length; i++) {
            //     tops.push(this.selectedGoodsTable.data[i].top);
            // }
            this.addLoading = true;
            this.$http
                .post('/goods/collection/allocation', {
                    collectionId: this.cationForm.collectionId,
                    goodsIds: this.cationGoodsIds,
                })
                .then((response) => {
                    this.addLoading = false;
                    if (response.data.code === 200) {
                        this.$message({
                            message: '分配商品成功！',
                            type: 'success',
                        });
                        this.dialogCation = false;
                    }
                })
                .catch((error) => {
                    this.addLoading = false;
                    // this.$message.error(error);
                });
        },
        updateAi() {
            this.cationGoodsIds = [];
            for (let i = 0; i < this.selectedGoodsTable.data.length; i++) {
                this.cationGoodsIds.push(this.selectedGoodsTable.data[i].id);
                // let item = this.selectedGoodsTable.data[i]
                // let json = {
                //     collectionId: this.cationForm.collectionId,
                //     goodsId: item.id,
                //     top: item.top ? item.top : 0,
                // }
                // this.cationGoodsIds.push(json)
            }
            this.aiLoading = true;

            this.getDispense();

            let params = {
                collectionId: this.cationForm.collectionId,
                goodsIds: this.cationGoodsIds,
                goodsQueryAi: this.goodsQueryAi,
            };

            this.$http
                .post('/goods/collection/ai/allocation', params)
                .then((response) => {
                    this.aiLoading = false;
                    if (response.data.code === 200) {
                        this.$message({
                            message: '分配商品成功！',
                            type: 'success',
                        });
                        this.dialogCation = false;
                    }
                })
                .catch((error) => {
                    this.aiLoading = false;
                    // this.$message.error(error);
                });
        },
        queryData() {
            this.loading = true;
            this.$http
                .get('/goods/collection/getPage', { params: this.queryForm })
                .then((response) => {
                    this.loading = false;
                    if (response.data.code === 200) {
                        this.tableData = response.data.data;
                        this.total = response.data.count;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    // this.$message.error(error);
                });
        },
        initAddForm() {
            this.goodsCollectionAddForm = {
                collectionName: null,
                collectionDesc: null,
                collectionCover: null,
                remark: null,
            };
            this.addLoading = false;
            this.dialogAdd = true;
        },
        addData() {
            this.$refs['goodsCollectionAddForm'].validate((valid) => {
                if (valid) {
                    this.addLoading = true;
                    this.$http
                        .post(
                            '/goods/collection/add',
                            this.goodsCollectionAddForm
                        )
                        .then((response) => {
                            this.addLoading = false;
                            if (response.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message({
                                    message: '新增成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: () => {
                                        this.queryData();
                                    },
                                });
                            }
                        })
                        .catch((error) => {
                            // this.$message.error(error);
                            this.addLoading = false;
                        });
                }
            });
        },
        clearEdit() {
            this.editForm.collectionCover = '';
            this.editForm.collectionDesc = '';
            this.editForm.collectionName = '';
            this.editForm.id = '';
            this.editForm.remark = '';
            this.editForm.seoDesc = '';
            this.editForm.seoKeywords = [];
            this.editForm.seoTitle = '';
            this.editForm.seoUrl = '';
            this.splitLink = {
                host: '',
                href: '',
                html: '',
            };
        },
        editData() {
            this.$refs['editForm'].validate((valid) => {
                if (valid) {
                    this.editForm.seoKeywords = JSON.parse(
                        JSON.stringify(this.editForm.seoKeywordsArr)
                    );
                    this.editForm.seoUrl =
                        this.splitLink.host +
                        this.splitLink.href +
                        this.splitLink.html;
                    this.$http
                        .post('/goods/collection/edit', this.editForm)
                        .then((response) => {
                            this.addLoading = false;
                            if (response.data.code === 200) {
                                this.dialogEdit = false;
                                this.$message({
                                    message: '编辑成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: () => {
                                        this.queryData();
                                    },
                                });
                            }
                        })
                        .catch((error) => {
                            // this.$message.error(error);
                            this.addLoading = false;
                        });
                }
            });
        },
        deleteData(row) {
            this.$confirm('是否删除该商品专辑？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .delete('/goods/collection/delete', {
                        params: { id: row.id },
                    })
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                                duration: 1000,
                                onClose: () => {
                                    this.queryData();
                                },
                            });
                        }
                    });
            });
        },
        handleClose(tag) {
            this.editForm.seoKeywordsArr.splice(
                this.editForm.seoKeywordsArr.indexOf(tag),
                1
            );
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick(() => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.editForm.seoKeywordsArr.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        getPublished(status) {
            switch (status) {
                case 0:
                    return '否';
                case 1:
                    return '是';
            }
        },
        radioChange(value) {
            console.log(value, this.dispenseType, this.deepSelectedGoodsTable);
            if (value != this.dispenseType) {
                this.selectedGoodsTable.data = [];
            } else {
                this.selectedGoodsTable.data = this.deepSelectedGoodsTable;
            }
        },
        showAiDailog() {
            this.aiDailog = true;
        },
        tagChange(value, index) {
            if (value == 1) {
                this.aiForm.table[index]['style'] = null;
                this.aiForm.table[index]['classify'] = null;
            }
            if (value == 2) {
                this.aiForm.table[index]['tag'] = null;
                this.aiForm.table[index]['classify'] = null;
            }
            if (value == 3) {
                this.aiForm.table[index]['tag'] = null;
                this.aiForm.table[index]['style'] = null;
            }
            if (value == 7) {
                this.aiForm.table[index]['days'] = 7;
            } else {
                this.aiForm.table[index]['days'] = null;
            }

            const types = this.aiForm.table.map((value) => value.type);
            const typesSet = new Set(types);

            if (types.length > 1 && typesSet.size != types.length) {
                return this.$message.warning('类型已存在');
            }

            this.aiForm.table;

            this.aiForm.table[index]['operator'] = ~this.typeTag.indexOf(value)
                ? 3
                : 1;
        },
        setTagList() {
            this.$http.get('/goods/label/getList').then((res) => {
                let { code, data } = res.data;
                if (code === 200) {
                    this.tagData = JSON.parse(
                        JSON.stringify(data).replace(/valueText/g, 'labelName')
                    );
                }
            });

            this.$http.get('/goods/type/getList').then((res) => {
                let { code, data } = res.data;
                if (code === 200) {
                    this.styleData = JSON.parse(
                        JSON.stringify(data).replace(/valueText/g, 'typeName')
                    );
                }
            });

            this.$http.get('/goods/category/getList').then((res) => {
                let { code, data } = res.data;
                if (code === 200) this.classifyData = data;
            });
        },
        addAiItem() {
            let item = {
                type: null,
                cascader: [],
                operator: null,
                days: null,
                text: null,
            };
            this.aiForm.table.push(item);
        },
        getDispense() {
            let _deepTable = JSON.parse(JSON.stringify(this.aiForm.table)),
                keyName = ['tag', 'style', 'classify'];

            for (let i = 0; i < _deepTable.length; i++) {
                let item = _deepTable[i];
                let text = [];
                let type = Number(item['type']);

                if (type < 4) {
                    let singe = item[keyName[type - 1]];
                    if (singe && singe.length) {
                        singe.map((v) => {
                            text.push(v[v.length - 1]);
                        });
                        _deepTable[i]['text'] = text.join(',');
                    }
                }
            }

            let params = {
                collectionId: this.cationForm.collectionId,
                goodsQueryAi: _deepTable,
            };

            // let deepArr = [
            //     {
            //         type: _deepTable[0].type,
            //         operator: _deepTable[0].operator,
            //         days: _deepTable[0].days,
            //         text: _deepTable[0].text,
            //     }
            // ]

            let deepArr = [];

            for (let i = 0; i < _deepTable.length; i++) {
                let item = _deepTable[i];
                let json = {
                    type: item.type,
                    operator: item.operator,
                    days: item.days,
                    text: item.text,
                };
                deepArr.push(json);
            }

            this.goodsQueryAi = deepArr;
        },
        defineAiDispense() {
            console.log(this.aiForm.table);
            const types = this.aiForm.table.map((value) => value.type);
            const typesSet = new Set(types);

            this.deepAiTable = JSON.parse(JSON.stringify(this.aiForm.table));

            if (types.length > 1 && typesSet.size != types.length) {
                return this.$message.warning('类型已存在');
            }

            this.$refs['aiForm'].validate((valid) => {
                if (valid) {
                    const self = this;

                    let _deepTable = JSON.parse(
                            JSON.stringify(self.aiForm.table)
                        ),
                        keyName = ['tag', 'style', 'classify'];

                    for (let i = 0; i < _deepTable.length; i++) {
                        let item = _deepTable[i];
                        let text = [];
                        let type = Number(item['type']);

                        if (type < 4) {
                            let singe = item[keyName[type - 1]];
                            if (singe && singe.length) {
                                singe.map((v) => {
                                    text.push(v[v.length - 1]);
                                });
                                _deepTable[i]['text'] = text.join(',');
                            }
                        }
                    }

                    let params = {
                        collectionId: self.cationForm.collectionId,
                        goodsQueryAi: _deepTable,
                    };

                    // console.log('_deepTable', _deepTable)

                    // let deepArr = [
                    //     {
                    //         type: _deepTable[0].type,
                    //         operator: _deepTable[0].operator,
                    //         days: _deepTable[0].days,
                    //         text: _deepTable[0].text,
                    //     }
                    // ]

                    let deepArr = [];

                    for (let i = 0; i < _deepTable.length; i++) {
                        let item = _deepTable[i];
                        let json = {
                            type: item.type,
                            operator: item.operator,
                            days: item.days,
                            text: item.text,
                        };
                        deepArr.push(json);
                    }

                    this.goodsQueryAi = deepArr;

                    self.$http
                        .post('/goods/getListByQueryAi', deepArr)
                        .then((res) => {
                            let { code } = res.data;
                            if (code == 200) {
                                self.$message.success('智能配置成功');
                                self.visibilityAiDailog = false;
                                // self.getGoodsCollection(true);
                                self.selectedGoodsTable.data = res.data.data;
                                self.selectedGoodsTable.data.total =
                                    res.data.data.length;
                                self.deepSelectedGoodsTable = JSON.parse(
                                    JSON.stringify(this.selectedGoodsTable.data)
                                );
                                self.assRadio = 2;
                                self.dispenseType = 2;

                                // let { type, typeJson } = res.data.data;
                                // self.setDispenseData(typeJson, type);
                                console.log(this.aiForm.table);
                            }
                        });
                    // submit();
                }
            });
        },
        closedDispense() {
            this.aiForm.table = this.$options.data.call(this).aiForm.table;
            this.deepAiTable = this.$options.data.call(this).deepAiTable;
        },
        showAiDispense() {
            console.log(this.aiForm.table, this.deepAiTable);
            this.visibilityAiDailog = true;
            this.aiForm.table = JSON.parse(JSON.stringify(this.deepAiTable));
        },
        removeLine(index) {
            if (this.aiForm.table.length === 1) {
                return this.$message.warning('至少保留一条');
            }
            this.aiForm.table.splice(index, 1);
        },
        setDispenseData(data_string, type) {
            if (!data_string) return;

            let _json = data_string && JSON.parse(data_string);

            if (type == 2 && _json.length) {
                for (let i = 0, count = _json.length; i < count; i++) {
                    let data = [],
                        _type = _json[i]['type'],
                        arr =
                            _json[i]['text'] &&
                            _json[i]['text'].split(',').map(Number);

                    if (_type == 1) {
                        let data = this.tagData,
                            child = 'shopGoodsLabelValues';

                        let _arr = [];
                        arr.map((item) => {
                            for (let j = 0; j < data.length; j++) {
                                let childList = data[j][child];

                                for (let k = 0; k < childList.length; k++) {
                                    if (childList[k]['id'] == item) {
                                        _arr.push([
                                            childList[k]['goodsLabelId'],
                                            childList[k]['id'],
                                        ]);
                                    }
                                }
                            }
                        });

                        _json[i]['tag'] = _arr;
                        _json[i]['text'] = null;
                    }

                    if (_type == 2) {
                        let data = this.styleData,
                            child = 'typeValues';

                        let _arr = [];
                        arr.map((item) => {
                            for (let j = 0; j < data.length; j++) {
                                let childList = data[j][child];

                                for (let k = 0; k < childList.length; k++) {
                                    if (childList[k]['id'] == item) {
                                        _arr.push([
                                            childList[k]['goodsTypeId'],
                                            childList[k]['id'],
                                        ]);
                                    }
                                }
                            }
                        });

                        _json[i]['style'] = _arr;
                        _json[i]['text'] = null;
                    }

                    if (_type == 3) {
                        arr.forEach((item, index) => {
                            let th = this.findPatentValue(
                                this.classifyData,
                                item,
                                'id',
                                'categoryItemList'
                            );

                            if (th.length) {
                                data.push(th);
                            }
                        });
                        _json[i]['classify'] = data;
                        _json[i]['text'] = null;
                    }

                    this.aiForm.table = _json;
                    this.deepAiTable = JSON.parse(JSON.stringify(_json));
                }
            }
        },
        findPatentValue(
            array,
            value,
            valueName = 'value',
            childrenName = 'children'
        ) {
            if (!value || !Array.isArray(array)) return [];
            const result = [];
            let valid = false;
            const seek = (array, value) => {
                let parentValue = '';
                const up = (array, value, lastValue) => {
                    array.forEach((v) => {
                        const val = v[valueName];
                        const child = v[childrenName];
                        if (val == value) {
                            valid = true;
                            parentValue = lastValue;
                            return;
                        }
                        if (child && child.length) up(child, value, val);
                    });
                };
                up(array, value);
                if (parentValue) {
                    result.unshift(parentValue);
                    seek(array, parentValue);
                }
            };
            seek(array, value);
            return valid ? [...result, value] : [];
        },
        getPublishedB(status) {
            switch (status) {
                case 0:
                    return '未上架';
                case 1:
                    return '已上架';
            }
        },
    },
};
</script>

<style scoped>
.wrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}
.wrapper .itembox {
    width: 180px;
    flex-basis: 180px;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    padding: 5px 5px 0 5px;
    border-radius: 10px;
    background-image: linear-gradient(315deg, #e2e2e2, #fff);
    position: relative;
}
.wrapper .itembox .imgbox {
    width: 100%;
    height: 220px;
}
.wrapper .itembox .tit {
    height: 32px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.wrapper .itembox .between {
    display: flex;
    border-top: 1px solid #ccc;
    margin: 0;
    height: 30px;
}
.wrapper .itembox .w50 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 50%;
    width: 50%;
    height: 100%;
    cursor: pointer;
    color: #409eff;
    font-size: 20px;
}
.wrapper .itembox .published {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    padding: 2px 5px;
    font-size: 14px;
    color: #fff;
    background: #409eff;
}
.wrapper .itembox .published.publishedA {
    color: #333;
    background: #ddd;
}
.wrapper .itembox .published.publishedB {
    color: #fff;
    background: #409eff;
}
.wrapper .itembox .w50:first-child {
    border-right: 1px solid #ccc;
}
.specil /deep/ .el-input__inner {
    padding-right: 66px;
}
.img-uploader {
    border: 1px dashed #000;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.img-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 136px;
    height: 136px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.image {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.insertbox {
    height: 410px;
    display: flex;
    flex-wrap: wrap;
}
.insertbox .item {
    width: 115px;
    height: 115px;
    flex-basis: 115px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.insertbox .item img {
    max-width: 110px;
    max-height: 110px;
    border: 2px solid transparent;
}
.insertbox .item img.selected {
    border: 2px solid #409eff;
}
.controlrow {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}
.el-textarea /deep/ .el-textarea__inner {
    height: 140px;
}
.selectedMaterUrl {
    display: block;
    max-width: 110px;
    max-height: 110px;
    margin: 0 auto;
    margin-top: 20px;
}
.center {
    text-align: center;
}
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
.tagbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.tagbox /deep/ .el-tag {
    display: inline-flex;
    align-items: center;
    height: auto;
    margin-bottom: 10px;
}
.goodsTagBox {
    height: 60px;
    display: flex;
    align-items: center;
}
.goodsTagBox img {
    max-width: 40px;
    max-height: 40px;
    display: inline-block;
    margin-right: 10px;
}

/* .ai-table .el-form-item {
    margin-bottom: 0;
} */

::v-deep
    .ai-table.el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    background: transparent;
}

::v-deep .ai-table .el-table__cell {
    text-align: left !important;
}

::v-deep .ai-table td.el-table__cell,
.ai-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid transparent !important;
}

.panel-item {
    width: 100%;
    display: flex;
    position: relative;
    padding-right: 30px;
    box-sizing: border-box;
}
::v-deep .panel-item .el-icon-delete {
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}
.day-mod {
    display: flex;
    line-height: 40px;
    margin-right: 10px;
}
.day-mod .el-input {
    margin: 0 4px;
}
.thumb {
    display: flex;
}
.mt-20 {
    margin-top: 20px;
}
</style>
