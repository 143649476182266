<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>店铺管理</el-breadcrumb-item>
            <el-breadcrumb-item>公告栏设置</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="tabs-mod">
            <el-tabs v-model="status" @tab-click="changeTab">
                <el-tab-pane v-for="item in statusTypes" :key="item.value" :label="item.name" :name="item.value"></el-tab-pane>
            </el-tabs>
        </div>

        <el-form :model="queryForm" status-icon ref="form" label-width="200px" :inline="true">
            <el-form-item>
                <el-input v-model="queryForm.name" placeholder="请输入公告名称"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-refresh-left" @click="resetCondition">重置</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="bridgeAdd">新增</el-button>
            </el-form-item>
        </el-form>

        <div class="mod-board-list" v-loading="loading">
            <div class="board-item" v-for="(item, index) in tableData" :key="index">
                <div class="top">
                    <el-tag :type="getStatusTypes(item.status, 'type')" size="small">{{ getStatusTypes(item.status, 'name') }}</el-tag>
                    <!-- <a class="into-edit" title="编辑"><em></em><em></em><em></em></a> -->
                    <div class="icon">
                        <el-link icon="el-icon-alarm-clock" :underline="false" @click="bridgeEndEvent(item)"></el-link>
                        <el-link icon="el-icon-edit" :underline="false" @click="intoEdit(item)"></el-link>
                        <el-link icon="el-icon-delete" :underline="false" @click="bridgeRemoveItem(item)"></el-link>
                    </div>
                </div>

                <div class="view-content">
                    <template v-if="item.pcBackgroundImage">
                        <img :src="item.pcBackgroundImage" alt="">
                    </template>
                    <template v-else-if="item.mpBackgroundImage">
                        <img :src="item.mpBackgroundImage" alt="">
                    </template>
                    <template v-else>
                        <p class="view-p" v-html="item.content" :style="`background-color:${item.backgroundColor}`"></p>
                    </template>
                </div>

                <div class="info">
                    <p class="name">{{ item.name }}</p>
                    <p class="date">{{ item.startTime }} ~ {{ item.endTime || '长期' }}</p>
                </div>
            </div>
        </div>

        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <!-- 新增 -->
        <el-dialog title="新建公告栏" :visible.sync="visibleDialogInfo" :close-on-click-modal="false" top="3%" fullscreen @closed="resetDialogInfo" class="dialogInfo">

            <el-steps :active="step" align-center>
                <el-step title="设置内容样式"></el-step>
                <el-step title="设置触发条件"></el-step>
                <el-step title="设置生效范围"></el-step>
            </el-steps>

            <div class="info-container">
                <div class="viewer">
                    <p>PC预览</p>
                    <div class="view-pc" :style="`background:  ${form.backgroundColor} url(${form.pcBackgroundImage}) no-repeat center 0 / 100% auto; `">
                        <div v-html="form.content"></div>
                    </div>
                    <p>H5预览</p>
                    <div class="view-m" :style="`background:  ${form.backgroundColor} url(${form.mpBackgroundImage}) no-repeat center 0 / 100% auto;`">
                        <div v-html="form.content"></div>
                    </div>
                </div>

                <el-form :model="form" status-icon ref="form" label-width="200px" class="info-form">
                    <div class="mod" v-show="step == 1">
                        <p class="tit">内容</p>
                        <el-form-item label="正文" prop="content" class="content-mod">
                            <vue-tinymce v-model="form.content" :setup="setup" :setting="setting"></vue-tinymce>
                        </el-form-item>

                        <el-form-item label="链接" class="content-mod">
                            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.link">
                            </el-input>
                        </el-form-item>

                        <p class="tit">背景</p>

                        <el-form-item label="底色">
                            <el-input type="color" v-model="form.backgroundColor" class="color-handle"></el-input>
                        </el-form-item>

                        <el-form-item label="PC端图片(1920 * 40)">
                            <div class="mod-photo">
                                <template v-if="form.pcBackgroundImage">
                                    <div class="ImageBox">
                                        <el-image fit="cover" class="Image" :src="form.pcBackgroundImage">
                                            <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                                        </el-image>
                                        <div class="mask_d5"></div>
                                        <el-button type="danger" icon="el-icon-delete" circle class="ImageDeleteButton" @click="form.pcBackgroundImage = null"></el-button>
                                    </div>
                                </template>
                                <i v-else class="el-icon-plus img-uploader-icon ImageAddButton" @click="openImageUploader( 'pc')"></i>
                            </div>
                        </el-form-item>

                        <el-form-item label="H5图片(750 * 40)">
                            <div class="mod-photo">
                                <template v-if="form.mpBackgroundImage">
                                    <div class="ImageBox">
                                        <el-image fit="cover" class="Image" :src="form.mpBackgroundImage">
                                            <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                                        </el-image>
                                        <div class="mask_d5"></div>
                                        <el-button type="danger" icon="el-icon-delete" circle class="ImageDeleteButton" @click="form.mpBackgroundImage = null"></el-button>
                                    </div>
                                </template>
                                <i v-else class="el-icon-plus img-uploader-icon ImageAddButton" @click="openImageUploader( 'm')"></i>
                            </div>
                        </el-form-item>
                    </div>

                    <div class="mod" v-show="step == 2">
                        <p class="tit">展示设置</p>
                        <el-form-item label="展示在" class="group">
                            <el-radio-group v-model="form.showPosition">
                                <el-radio :label="0">pc端和手机端</el-radio>
                                <el-radio :label="1">仅PC端</el-radio>
                                <el-radio :label="2">仅手机端</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="展示页面" class="group">
                            <el-radio-group v-model="form.showPageAll">
                                <el-radio :label="1">全部页面（不包含信息页和结账页）</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>

                    <div class="mod" v-show="step == 3">
                        <div class="df ai_fe">
                            <el-form-item label="生效时间" prop="startTime">
                                <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间" v-model="form.startTime">
                                </el-date-picker>
                                <i class="datetime-icon">~</i>
                                <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间" :disabled="addCampaignFullEndTime" v-model="form.endTime">
                                </el-date-picker>
                                <el-checkbox class="check-ml" v-model="addCampaignFullEndTime" @change="
                                ($event) => {
                                    if ($event) addCampaignFullForm.endTime = null;
                                }">长期</el-checkbox>
                            </el-form-item>
                        </div>

                        <!-- <p class="tit"></p> -->
                        <el-form-item label="公告名称(仅在后台展示)">
                            <el-input v-model="form.name" placeholder="请输入公告名称"></el-input>
                        </el-form-item>
                    </div>

                </el-form>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="visibleDialogInfo = false" v-show="step == 1">取消</el-button>
                <el-button @click="prevStep" v-show="step == 2 || step == 3">上一步</el-button>
                <el-button type="primary" @click="next" v-show="step != 3">下一步</el-button>
                <el-button type="primary" :loading="addLoading" @click="addInfo" v-show="step == 3">确认</el-button>

            </div>
        </el-dialog>

        <ImageUploader ref="uploadImage" :insertImage="dialogInsertImage" :insertType="insertType" :activeName="activeName" @getOpenState="getOpenState" @getImage="getImage">
        </ImageUploader>
    </div>
</template>


<script>
import ImageUploader from '@/components/ImageUploader';
export default {
    name: 'BulletinBoard',
    components: {
        ImageUploader,
    },
    computed: {
        getStatusTypes() {
            return function (value, keyName) {
                let stu = this.statusTypes.find((cur) => {
                    return cur.value == value;
                });
                return stu?.[keyName];
            };
        },
    },
    data: () => ({
        step: 1,
        status: '0',
        statusTypes: [
            {
                name: '全部',
                value: '0',
                type: '',
            },
            {
                name: '未开始',
                value: '1',
                type: 'warning',
            },
            {
                name: '进行中',
                value: '2',
                type: 'success',
            },
            {
                name: '已结束',
                value: '3',
                type: 'info',
            },
        ],

        /* 插入图片参数 */
        dialogInsertImage: false,
        activeName: 'first',
        insertType: null,
        insertIndex: null,
        /* 插入图片参数 end */

        visibleDialogInfo: false,
        addLoading: false,
        loading: false,

        // textare info
        // setting: {
        //     menubar: 'edit view format tools table',
        //     toolbar:
        //         'undo redo removeformat | fullscreen | imageUpload | videoUpload code | styleselect alignleft aligncenter alignright alignjustify | link | numlist bullist | fontselect fontsizeselect forecolor backcolor | table bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
        //     toolbar_mode: 'sliding',
        //     quickbars_insert_toolbar: 'quicktable',
        //     quickbars_selection_toolbar:
        //         'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
        //     plugins:
        //         'link image media code table textpattern lists fullscreen quickbars',
        //     language: 'zh_CN',
        //     height: 350,
        //     convert_urls: false,
        // },

        // textare info
        setting: {
            menubar: '',
            toolbar:
                'alignleft aligncenter alignright alignjustify  | numlist bullist | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough  ',
            toolbar_mode: 'sliding',
            quickbars_insert_toolbar: '',
            quickbars_selection_toolbar:
                'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
            plugins: 'textpattern lists fullscreen quickbars',
            language: 'zh_CN',
            height: 350,
            convert_urls: false,
        },

        tableData: [],
        total: 0,
        queryForm: {
            name: '',
            pageNo: 1,
            pageSize: 20,
        },
        form: {
            pcBackgroundImage: '',
            mpBackgroundImage: '',
            backgroundColor: '#000',
            link: '',
            content: '',
            showPosition: 0,
            showPageAll: 1,
            startTime: '',
            endTime: '',
            name: '',
        },
        addCampaignFullEndTime: false, // 不限结束时间
    }),
    mounted() {
        this.queryData();
    },
    methods: {
        queryData() {
            let url = '/setting/bulletin/getPage',
                params = {
                    ...this.queryForm,
                };

            if (this.status != 0) {
                params['status'] = this.status;
            }

            this.loading = true;
            this.$http
                .get(url, { params })
                .then((res) => {
                    let { code, data, count } = res.data;
                    if (code === 200) {
                        this.tableData = data;
                        this.total = count;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        bridgeAdd() {
            this.visibleDialogInfo = true;
        },

        bridgeRemoveItem({ id }) {
            this.$confirm('确定要删除当前这条公告栏吗？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.removeItem(id);
            });
        },

        bridgeEndEvent({ id }) {
            this.$confirm('是否结束公告栏活动？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.endEvent(id);
            });
        },

        addInfo() {
            let { id, startTime, endTime, ...args } = this.form,
                flag = id ? 'edit' : 'add',
                msg = id ? '编辑' : '新增',
                params = {
                    startTime,
                    id,
                    ...args,
                };

            if (!this.addCampaignFullEndTime) params['endTime'] = endTime;

            this.addLoading = true;
            this.$http
                .post(`/setting/bulletin/${flag}`, params)
                .then((res) => {
                    let { code } = res.data;
                    if (code === 200) {
                        this.visibleDialogInfo = false;
                        this.queryData();
                        this.$message.success(`${msg}成功~`);
                    }
                })
                .finally(() => {
                    this.addLoading = false;
                });
        },

        intoEdit(item) {
            if (!item.endTime) {
                this.addCampaignFullEndTime = true;
            }

            this.form = JSON.parse(JSON.stringify(item));
            this.visibleDialogInfo = true;
        },

        endEvent(id) {
            let params = {
                id,
            };
            this.$http
                .get(`/setting/bulletin/end`, {
                    params,
                })
                .then((res) => {
                    let { code } = res.data;
                    if (code === 200) {
                        this.$message.success('活动已提前结束');
                        this.queryData();
                    }
                });
        },

        removeItem(id) {
            let url = '/setting/bulletin/delete';
            this.$http
                .delete(url, {
                    params: { id },
                })
                .then((res) => {
                    let { code } = res.data;
                    if (code === 200) {
                        this.$message.success('删除成功');
                        this.queryData();
                    }
                });
        },

        setup(editor) {
            let that = this;
            editor.ui.registry.addButton('imageUpload', {
                tooltip: '插入图片',
                icon: 'image',
                onAction: () => {
                    that.setOpenInsert('tinymce');
                },
            });
            editor.ui.registry.addButton('videoUpload', {
                tooltip: '插入视频',
                icon: 'embed',
                onAction: () => {
                    that.openVideoUploader('tinymce');
                },
            });
        },
        next() {
            ++this.step;
        },
        prevStep() {
            --this.step;
        },
        changeTab() {
            this.queryData();
        },
        resetDialogInfo() {
            this.form = this.$options.data.call(this).form;
            // Object.assign(this.form, {}, this.$options.data.call(this).form);
            this.addCampaignFullEndTime = false;
            this.step = 1;
        },
        resetCondition() {
            Object.assign(
                this.queryForm,
                this.$options.data.call(this).queryForm
            );
            this.queryData();
        },

        // 插入图片方法
        openImageUploader(obj, index = 0) {
            this.insertType = obj;
            this.insertIndex = index;
            this.dialogInsertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.dialogInsertImage = result;
        },
        getImage(obj, image) {
            switch (obj) {
                case 'pc':
                    this.form.pcBackgroundImage = image[0];
                    break;
                case 'm':
                    this.form.mpBackgroundImage = image[0];
                    break;
                case 'tinymce':
                    this.form.content += `<img src="${
                        image[image.length - 1]
                    }"/>`;
                    break;
            }
            // this.dialogInsertImage = false;
        },
        setOpenInsert(obj) {
            this.insertType = obj;
            this.dialogInsertImage = true;
            this.$refs.uploadImage.openInsert();
        },
    },
};
</script>
<style scoped>
/* ::v-deep .dialogInfo .el-dialog {
    background: #ecedf3;
} */
/* ::v-deep .dialogInfo .el-dialog__body {
    background: #fff;
} */

.view-pc,
.view-m {
    height: 40px;
}

.view-pc {
    width: 100%;
}

.view-p {
    color: #fff;
}

::v-deep .view-p p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.view-m {
    width: 750px;
    /* margin-top: 120px; */
}

::v-deep .viewer p {
    margin: 0;
    line-height: 40px;
}

.layou-end {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
}
.info-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* background: #ecedf3; */
}
.viewer {
    width: 1000px;
    height: 200px;
    border: 1px dashed #ccc;
    background: #fff;
}
.info-form {
    background: #fff;
    width: 40%;
    margin-left: 100px;
}
::v-deep .color-handle input {
    width: 80px;
    height: 40px;
    padding: 0;
    margin: 0;
    border: 0 none;
}
.tit {
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    line-height: 32px;
    font-weight: bold;
    padding-left: 10px;
    margin: 0 0 20px 56px;
}

::v-deep .ml-none .el-form-item__content {
    margin-left: 60px !important;
}

::v-deep .group .el-form-item__label {
    width: 120px !important;
}
::v-deep .group .el-form-item__content {
    margin: 40px 0 0 70px !important;
}

::v-deep .tox .tox-menubar + .tox-toolbar,
::v-deep .tox .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary {
    border-top: 0 none;
}

.mod-photo {
    justify-content: flex-start;
}

.mod-board-list {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding-bottom: 100px;
}
.board-item {
    border: 1px solid #ccc;
}
.board-item p {
    margin: 0;
}
.board-item .top {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
::v-deep .board-item .icon i {
    font-size: 16px;
    margin-left: 10px;
}

.board-item .into-edit {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.board-item .into-edit:hover em {
    background: #409eff;
}
.board-item .into-edit em {
    background: #000;
    width: 6px;
    height: 6px;
    display: block;
    margin-left: 2px;
}
.board-item .view-content {
    height: 100px;
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.board-item .view-p {
    width: 100%;
}
.board-item .view-content img {
    width: 100%;
    height: 40px;
    display: block;
    object-fit: cover;
}
.board-item .info {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    padding-bottom: 30px;
}
.board-item .name {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
}
.board-item .date {
    font-size: 12px;
    margin-top: 4px;
}

.datetime-icon {
    margin: 0 10px;
}

.check-ml {
    margin-left: 20px;
}

::v-deep .tox-tinymce {
    height: 140px !important;
}

.main-wrap {
    height: auto;
}

/* .tox .tox-edit-area__iframe {
    height: 200px;
} */
</style>