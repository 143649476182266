<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>营销管理</el-breadcrumb-item>
            <el-breadcrumb-item>换购活动</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 查询表单 -->
        <el-tabs v-model="campaignStatus" @tab-click="querySearch">
            <el-tab-pane label="全部" name="0"></el-tab-pane>
            <el-tab-pane v-for="item in campaignStatusTypes" :key="item.value" :label="item.name" :name="item.value"></el-tab-pane>
        </el-tabs>
        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.campaignName" placeholder="活动名称"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="querySearch" :loading="loading">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-refresh-left" @click="resetCondition">重置</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="openAdd">新建</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="warning" icon="el-icon-s-data" @click="openStatic" :loading="loading">数据明细</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表 -->
        <el-table border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" :data="tableData" v-loading="loading">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="campaignName" label="活动名称" min-width="120"></el-table-column>
            <el-table-column prop="userName" label="创建人" min-width="120"></el-table-column>
            <el-table-column prop="discountType" label="换购类型" min-width="120">
                <template slot-scope="scope">
                    {{
            discountStatusTypes.find((v) => v.value == scope.row.discountType)
              .name
          }}
                </template>
            </el-table-column>
            <el-table-column label="优先级" width="150">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.sort" @change="changePriority($event, scope.row.id)" :min="1" label="请输入优先级" :precision="0"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="活动时间" width="160">
                <template slot-scope="scope">
                    {{ scope.row.startTime }}-{{
            scope.row.endTime ? scope.row.endTime : "不限"
          }}
                </template>
            </el-table-column>
            <el-table-column prop="maxReplaceNum" label="可换购件数" min-width="120"></el-table-column>
            <el-table-column label="活动状态" width="100">
                <template slot-scope="scope">
                    {{
            campaignStatusTypes.find((v) => v.value == scope.row.campaignStatus)
              .name
          }}
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="200" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="initEditReplace(scope.row.id)">编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-video-pause" v-if="scope.row.campaignStatus != 3" @click="stopReplace(scope.row.id)">提前结束</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" v-if="scope.row.campaignStatus == 3" @click="deleteReplace(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <!-- 新增满减活动 -->
        <el-dialog fullscreen :title="addReplaceForm.id ? '编辑满减活动' : '新增满减活动'" id="addReplaceDialog" :visible.sync="dialogAddReplace" :close-on-click-modal="false">
            <el-form ref="addReplaceForm" size="medium" label-position="top" status-icon :model="addReplaceForm" :rules="addReplaceRules">
                <!-- 创建活动 -->
                <section class="addReplaceSection">
                    <el-row>
                        <el-col :span="4" class="addReplaceLegend">基本信息</el-col>
                        <el-col :span="20">
                            <el-form-item label="活动名称" prop="campaignName" style="max-width: 650px">
                                <el-input placeholder="请输入活动名称" maxlength="20" show-word-limit v-model="addReplaceForm.campaignName"></el-input>
                            </el-form-item>
                            <div class="df ai_fe">
                                <el-form-item label="活动时间" prop="startTime">
                                    <el-date-picker type="datetime" :disabled="addStatus != 9" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间" v-model="addReplaceForm.startTime">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item class="px_1" style="color: #bbb">～</el-form-item>
                                <el-form-item prop="endTime">
                                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间" :disabled="addReplaceEndTime" v-model="addReplaceForm.endTime">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item prop="isDefault" class="ml_3">
                                    <el-checkbox v-model="addReplaceEndTime" @change="
                      ($event) => {
                        if ($event) addReplaceForm.endTime = null;
                      }
                    ">不限结束时间</el-checkbox>
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>
                </section>

                <section class="addReplaceSection">
                    <el-row>
                        <el-col :span="4" class="addReplaceLegend">换购规则</el-col>
                        <el-col :span="20">
                            <div class="df ai_fe">
                                <el-form-item label="换购类型" prop="discountType">
                                    <el-select :disabled="addStatus == 2" v-model="addReplaceForm.discountType" style="width: 240px">
                                        <el-option v-for="(item,index) in discountStatusTypes" :key="index" :label="item.name" :value="item.value"></el-option>
                                        <!-- <el-option label="购物车满金额" value="1"></el-option>
	                  <el-option label="购物车满件数" value="2"></el-option>
	                  <el-option label="购物车包含指定商品" value="3"></el-option> -->
                                    </el-select>
                                </el-form-item>

                                <el-form-item style="margin-left: 20px;">
                                    <el-input placeholder="请输入金额" :disabled="addStatus == 2" v-model="addReplaceForm.fullAmount" v-if="addReplaceForm.discountType == 1">
                                        <template slot="append">USD</template>
                                    </el-input>
                                    <el-input placeholder="请输入件数" :disabled="addStatus == 2" v-model="addReplaceForm.fullNum" v-if="addReplaceForm.discountType == 2">
                                        <template slot="append">件</template>
                                    </el-input>

                                </el-form-item>
                            </div>
                            <div class="df ai_fe jc_sb">
                                <el-form-item label="允许换购件数" prop="maxReplaceNum">
                                    <!-- <el-input placeholder="请输入件数" v-model="addReplaceForm.maxReplaceNum">
								    <template slot="append">件</template>
								  </el-input> -->
                                    <el-input-number :disabled="addStatus == 2" v-model="addReplaceForm.maxReplaceNum" :min="1" :max="15" label="请输入允许换购件数" :precision="0"></el-input-number> 件（最小1件，最大15件）
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-plus" v-if="addReplaceForm.discountType == 3 && addStatus != 2" @click="addNewAppointGoods()">新增一条</el-button>
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>
                </section>

                <section class="addReplaceSection" v-if="addReplaceForm.discountType == 3">
                    <div class="appointrowbox" v-for="(item,index) in appointData">

                        <div class="deleterow" @click="deleteRow(index)" v-if="addStatus != 2"><i class="el-icon-delete"></i> 删除</div>

                        <el-row>
                            <el-col :span="4" class="addReplaceLegend">
                                包含指定商品
                            </el-col>
                            <el-col :span="20">
                                <el-button type="primary" icon="el-icon-plus" v-if="addStatus != 2" @click="openSelectGoods('appointData', index, 'appoint', 'replace')">选择商品</el-button>

                                <!-- 数据表 -->
                                <el-table ref="appointData[index].appoint" class="mt_3" stripe v-if="appointData[index].appoint.length > 0" row-key="id" :data="appointData[index].appoint">
                                    <el-table-column label="商品图片" width="80">
                                        <template slot-scope="scope">
                                            <GoodsImageTemplate :images="scope.row.goodsImages" width="80" height="80"></GoodsImageTemplate>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="goodsTitle" label="商品名称" min-width="80"></el-table-column>
                                    <el-table-column label="售价/原价" min-width="80">
                                        <template slot-scope="scope">
                                            {{ scope.row.price }} / {{ scope.row.compareAtPrice }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center">
                                        <template slot-scope="scope">
                                            <el-button v-if="addStatus != 2" size="mini" type="text" @click="deleteSelectedGoods(scope.row, 'appointData', index, 'appoint')">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                        </el-row>
                        <br>

                        <el-row>
                            <el-col :span="4" class="addReplaceLegend">参与换购的商品</el-col>
                            <el-col :span="20">
                                <el-form-item>
                                    <el-button v-if="addStatus != 2" type="primary" icon="el-icon-plus" @click="openSelectGoods('appointData', index, 'replace', 'appoint')">选择商品</el-button>
                                </el-form-item>

                                <!-- 数据表 -->
                                <el-table ref="appointData[index].replace" class="mt_3" :class="'sorttable' + index" stripe v-if="appointData[index].replace.length > 0" row-key="id" :data="appointData[index].replace">
                                    <el-table-column label="商品图片" width="80">
                                        <template slot-scope="scope">
                                            <GoodsImageTemplate :images="scope.row.goodsImages" width="80" height="80"></GoodsImageTemplate>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="goodsTitle" label="商品名称" min-width="80"></el-table-column>
                                    <el-table-column label="售价/原价" min-width="80">
                                        <template slot-scope="scope">
                                            {{ scope.row.price }} / {{ scope.row.compareAtPrice }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="换购价(USD)" min-width="80">
                                        <template slot-scope="scope">
                                            <el-input-number v-model="scope.row.replaceGoodsPrice" :disabled="addStatus == 2" @input="changeReplacement($event, scope, scope.row.price)" :min="0" label="请输入换购价" :precision="2"></el-input-number>
                                        </template>
                                    </el-table-column>
                                    <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center">
                                        <template slot-scope="scope">
                                            <el-button v-if="addStatus != 2" size="mini" type="text" @click="deleteSelectedGoods(scope.row, 'appointData', index, 'replace')">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>

                            </el-col>
                        </el-row>

                    </div>
                </section>

                <section class="addReplaceSection" v-if="addReplaceForm.discountType != 3">
                    <el-row>
                        <el-col :span="4" class="addReplaceLegend">参与换购的商品</el-col>
                        <el-col :span="20">
                            <el-form-item>
                                <el-button v-if="addStatus != 2" type="primary" icon="el-icon-plus" @click="openSelectGoods('joinItems')">选择商品</el-button>
                            </el-form-item>

                            <!-- 数据表 -->
                            <el-table ref="joinItems" class="mt_3 joinItems" stripe v-if="joinItems.length > 0" row-key="id" :data="joinItems">
                                <el-table-column label="商品图片" width="80">
                                    <template slot-scope="scope">
                                        <el-image fit="contain" style="width: 40px; height: 40px" :src="scope.row.goodsImages[ 0 ]">
                                            <div slot="error" class="el-image__error" style="width: 40px; height: 40px; font-size: 10px">
                                                无图
                                            </div>
                                        </el-image>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="goodsTitle" label="商品名称" min-width="80"></el-table-column>
                                <el-table-column label="售价/原价" min-width="80">
                                    <template slot-scope="scope">
                                        {{ scope.row.price }} / {{ scope.row.compareAtPrice }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="换购价(USD)" min-width="80">
                                    <template slot-scope="scope">
                                        <el-input-number v-model="scope.row.replaceGoodsPrice" :disabled="addStatus == 2" @input="changeReplacement($event, scope, scope.row.price)" :min="0" label="请输入换购价" :precision="2"></el-input-number>
                                    </template>
                                </el-table-column>
                                <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center">
                                    <template slot-scope="scope">
                                        <el-button v-if="addStatus != 2" size="mini" type="text" @click="deleteSelectedGoods(scope.row, 'joinItems')">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>

                        </el-col>
                    </el-row>
                </section>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading" @click="addReplace">保存</el-button>
                <el-button @click="dialogAddReplace = false">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog fullscreen title="换购数据明细" :visible.sync="dialogDetail" :close-on-click-modal="false">
            <!-- <p>换购活动数据自2022年12月17日开始统计</p> -->
            <el-form :inline="true" :model="detailForm" class="demo-form-inline">
                <el-form-item>
                    <el-date-picker type="daterange" :clearable="false" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="detailDate" :picker-options="pickerOptionsA" :default-value="new Date()" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="searchDetail" :loading="detailLoading">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-refresh-right" @click="resetDetail">重置</el-button>
                </el-form-item>
            </el-form>
            <section class="detailitems between">
                <div class="item">
                    <div class="tit">订单量</div>
                    <div class="val">{{detailData.orderSuccessNum || 0}}单</div>
                </div>
                <div class="item">
                    <div class="tit">优惠金额</div>
                    <div class="val">${{detailData.discountPrice || 0}}</div>
                </div>
                <div class="item">
                    <div class="tit">订单金额</div>
                    <div class="val">${{detailData.total || 0}}</div>
                </div>
                <div class="item">
                    <div class="tit">换购商品数量<el-tooltip effect="dark" content="查询日期内,参加换购的换购商品数量" placement="top"><i class="el-icon-question"></i></el-tooltip></div>
                    <div class="val">{{detailData.quantity || 0}}</div>
                </div>
                <div class="item">
                    <div class="tit">连带率<el-tooltip effect="dark" content="平均每笔订单的商品数量" placement="top"><i class="el-icon-question"></i></el-tooltip></div>
                    <div class="val">{{detailData.relatedRate || 0}}</div>
                </div>
            </section>

            <!-- 数据表 -->
            <el-table border stripe ref="detailTable" :data="detailTable" v-loading="detailLoading" :default-sort="option" @sort-change="changeSort($event, 'detailForm')" element-loading-text="加载中" element-loading-spinner="el-icon-loading" max-height="600">
                <el-table-column prop="campaignName" label="活动名称"></el-table-column>
                <el-table-column prop="discountType" label="换购类型" min-width="120">
                    <template slot-scope="scope">
                        {{
			      discountStatusTypes.find((v) => v.value == scope.row.discountType)
			        .name
			    }}
                    </template>
                </el-table-column>
                <el-table-column prop="orderSuccessNum" label="订单量" sortable="1"></el-table-column>
                <el-table-column prop="discountPrice" label="优惠金额($)" sortable="2"></el-table-column>
                <el-table-column prop="total" label="订单金额($)" sortable="3"></el-table-column>
                <el-table-column prop="quantity" label="换购商品数量" sortable="4"></el-table-column>
                <el-table-column prop="relatedRate" label="连带率" sortable="5"></el-table-column>
            </el-table>
            <el-pagination background :current-page.sync="detailForm.pageNo" :page-sizes="[20, 50, 100]" :page-size.sync="detailForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="detailTotal" @size-change="getDetail" @current-change="getDetail">
            </el-pagination>
        </el-dialog>

        <!-- 选择商品 -->
        <SelectGoods ref="selectGoods" :showDialog="dialogSelectGoods" :containItem="false" :alreadyHas="alreadyHasGoods" :goodsIndex="goodsRowIndex" :selectedGoods="nowSelectgoods" @closeDialog="closeSelectGoods" @saveSelectedGoods="saveSelectGoods">
        </SelectGoods>

    </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader';
import SelectGoods from '@/components/SelectGoods';
import Sortable from 'sortablejs';
import vuedraggable from 'vuedraggable';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
export default {
    name: 'CampaignReplace',

    components: {
        ImageUploader: ImageUploader,
        SelectGoods: SelectGoods,
        vuedraggable,
        GoodsImageTemplate,
    },

    data() {
        return {
            dialogDetail: false,
            detailForm: {
                pageNo: 1,
                pageSize: 20,
                startDate: null,
                endDate: null,
                campaignName: null,
                campaignStatus: null,
                sort: 0,
                sortType: 0,
            },
            detailDate: [],
            pickerOptionsA: {
                disabledDate: (time) => {
                    // var lasttime = Date.parse('2022-12-16');
                    // return time.getTime() < lasttime;
                    // let futrue = time.getTime() > Date.now()
                    return time.getTime() > Date.now();
                },
            },
            detailData: {
                orderSuccessNum: null,
                quantity: null,
                discountPrice: null,
                relatedRate: null,
                total: null,
            },
            detailTable: [],
            detailTotal: 0,
            detailLoading: false,
            option: { prop: 'orderSuccessNum' },
            sortOptions: [
                { name: 'orderSuccessNum', value: 1 },
                { name: 'discountPrice', value: 2 },
                { name: 'total', value: 3 },
                { name: 'quantity', value: 4 },
                { name: 'relatedRate', value: 5 },
            ],
            btnLoading: false,

            // 选择商品参数
            dialogSelectGoods: false,
            // 选中的商品列表数据
            selectedGoodsTable: {
                data: [],
                total: 0,
            },
            nowSelectgoods: [],
            alreadyHasGoods: [],
            goodsRowIndex: 0,

            appointData: [],
            joinItems: [],

            discountStatusTypes: [
                { name: '购物满金额', value: '1' },
                { name: '购物满件数', value: '2' },
                { name: '购物包含指定商品', value: '3' },
            ],

            campaignStatusTypes: [
                { name: '未开始', value: '1' },
                { name: '进行中', value: '2' },
                { name: '已结束', value: '3' },
            ],
            campaignStatus: '0',
            queryForm: {
                campaignStatus: null,
                campaignName: null,
                pageNo: 1,
                pageSize: 10,
            },
            loading: false,
            tableData: [],
            total: 0,

            dialogAddReplace: false,
            addReplaceForm: {
                campaignName: '',
                startTime: '',
                endTime: '',
                discountType: '',
                fullAmount: '',
                fullNum: '',
                maxReplaceNum: '',
                appointGoodsIdList: [],
                replaceGoodsList: [],
            },
            addReplaceEndTime: false, // 不限结束时间
            addStatus: 9,
            addReplaceRules: {
                campaignName: [
                    {
                        required: true,
                        message: '请输入活动名称',
                        trigger: 'blur',
                    },
                ],
                maxReplaceNum: [
                    {
                        required: true,
                        message: '请输入允许换购件数',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    mounted() {
        this.queryData();
    },
    methods: {
        getToday() {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }
            let result = year + '-' + month + '-' + day;
            this.detailForm.startDate = result;
            this.detailForm.endDate = result;
            this.detailDate = [result, result];
        },
        openStatic() {
            this.dialogDetail = true;
            this.loading = true;
            this.getToday();
            this.detailForm.pageNo = 1;
            this.getStaticTotal();
            if (this.$refs.detailTable) {
                this.$refs.detailTable.clearSort();
            }
        },
        getStaticTotal() {
            this.detailForm.startDate = this.detailDate[0];
            this.detailForm.endDate = this.detailDate[1];
            this.detailForm.sort = null;
            this.detailForm.sortType = null;
            this.detailForm.campaignName = this.queryForm.campaignName;
            this.detailForm.campaignStatus = this.queryForm.campaignStatus;
            // this.detailForm.pageNo = null

            this.$http
                .get('/campaign/replace/getStatisticTotal', {
                    params: this.detailForm,
                })
                .then((r) => {
                    if (r.data.code === 200) {
                        let item = r.data.data;
                        // console.log(item)
                        this.detailData = item;

                        this.getDetail();
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getDetail() {
            // this.detailForm.startDate = this.detailDate[0]
            // this.detailForm.endDate = this.detailDate[1]
            this.detailLoading = true;
            await this.$http
                .get('/campaign/replace/getStatistic', {
                    params: this.detailForm,
                })
                .then((r) => {
                    if (r.data.code === 200) {
                        this.detailTable = r.data.data;
                        this.detailTotal = r.data.count;
                    }
                })
                .finally(() => {
                    this.detailLoading = false;
                });
        },
        searchDetail() {
            this.$nextTick(() => {
                this.$refs.detailTable.clearSort();
            });
            this.detailForm.pageNo = 1;
            this.getStaticTotal();
        },
        resetDetail() {
            this.detailForm.pageNo = 1;
            this.getToday();
            this.detailForm.sort = null;
            this.detailForm.sortType = null;
            this.getStaticTotal();
            this.$refs.detailTable.clearSort();
        },
        changeSort(column, form) {
            let name = column.prop;
            let order = column.order;
            for (let i = 0; i < this.sortOptions.length; i++) {
                let item = this.sortOptions[i];
                if (name == item.name) {
                    switch (order) {
                        case 'ascending':
                            this[form].sortType = 1;
                            this[form].sort = item.value;
                            break;
                        case 'descending':
                            this[form].sortType = 2;
                            this[form].sort = item.value;
                            break;
                        default:
                            this[form].sortType = null;
                            this[form].sort = null;
                            break;
                    }
                }
            }
            this[form].pageNo = 1;
            this.getDetail();
        },
        rowDrop(caller) {
            console.log(caller, typeof caller);
            if (typeof caller == 'string') {
                const tbody = document.querySelector(`.${caller} tbody`);
                const _this = this;
                Sortable.create(tbody, {
                    onEnd({ newIndex, oldIndex }) {
                        // console.log("拖动了行","当前序号: "+newIndex)
                        const currRow = _this[caller].splice(oldIndex, 1)[0];
                        _this[caller].splice(newIndex, 0, currRow);
                    },
                });
            } else {
                let arr = [...caller];
                const tbody = document.querySelector(
                    `.sorttable${caller[1]} tbody`
                );
                const _this = this;
                Sortable.create(tbody, {
                    onEnd({ newIndex, oldIndex }) {
                        // console.log("拖动了行","当前序号: "+newIndex)
                        const currRow = _this[arr[0]][arr[1]][arr[2]].splice(
                            oldIndex,
                            1
                        )[0];
                        _this[arr[0]][arr[1]][arr[2]].splice(
                            newIndex,
                            0,
                            currRow
                        );
                    },
                });
            }
        },
        openAdd() {
            this.addReplaceForm = {
                campaignName: '',
                startTime: '',
                endTime: '',
                discountType: '',
                fullAmount: '',
                fullNum: '',
                maxReplaceNum: '',
                // appointGoodsIdList: [],
                appointGoods: [],
                replaceGoodsList: [],
            };
            this.addStatus = 9;
            this.addReplaceEndTime = false;
            this.appointData = [];
            this.joinItems = [];
            this.alreadyHasGoods = [];

            this.dialogAddReplace = true;
        },
        initEditReplace(id) {
            this.loading = true;
            this.addReplaceForm = {
                campaignName: '',
                startTime: '',
                endTime: '',
                discountType: '',
                fullAmount: '',
                fullNum: '',
                maxReplaceNum: '',
                // appointGoodsIdList: [],
                appointGoods: [],
                replaceGoodsList: [],
            };
            this.appointData = [];
            this.alreadyHasGoods = [];
            this.joinItems = [];
            this.addReplaceEndTime = false;

            this.dialogAddReplace = true;

            this.$http
                .get('/campaign/replace/getDetail?id=' + id)
                .then((r) => {
                    if (r.data.code === 200) {
                        let item = r.data.data;

                        this.addStatus = item.campaignStatus;

                        this.addReplaceForm = {
                            id: item.id,
                            campaignName: item.campaignName,
                            startTime: item.startTime,
                            endTime: item.endTime,
                            discountType: String(item.discountType),
                            fullAmount: item.fullAmount,
                            fullNum: item.fullNum,
                            maxReplaceNum: item.maxReplaceNum,
                            // appointGoodsIdList: [],
                            appointGoods: [],
                            replaceGoodsList: [],
                        };

                        if (!item.endTime) {
                            this.addReplaceEndTime = true;
                        }

                        if (item.appointGoods.length > 0) {
                            for (let i = 0; i < item.appointGoods.length; i++) {
                                let data = item.appointGoods[i];
                                this.appointData[i] = {
                                    appoint: [],
                                    replace: [],
                                };
                                this.appointData[i].appoint = JSON.parse(
                                    JSON.stringify(data.appointGoodsList)
                                );
                                this.appointData[i].replace = JSON.parse(
                                    JSON.stringify(data.replaceGoodsList)
                                );

                                if (this.addStatus != 2) {
                                    let caller = ['appointData', i, 'replace'];
                                    this.$nextTick(() => {
                                        this.rowDrop(caller);
                                    });
                                }
                            }
                        }

                        // this.includeItems = JSON.parse(JSON.stringify(item.appointGoodsList))
                        this.joinItems = JSON.parse(
                            JSON.stringify(item.replaceGoodsList)
                        );
                        // if(this.joinItems.length > 0 && this.addStatus != 2){
                        //   this.$nextTick(()=>{
                        //     this.rowDrop('joinItems')
                        //   })
                        // }

                        this.$forceUpdate();
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addNewAppointGoods() {
            // console.log('123123')
            let json = {
                appoint: [],
                replace: [],
            };
            this.appointData.push(json);
        },
        deleteRow(index) {
            // console.log(this.appointData, index)
            this.appointData.splice(index, 1);
        },
        addReplace() {
            // console.log(this.selectedGoodsTable.data)
            let url;
            if (this.addReplaceForm.id) {
                url = '/campaign/replace/edit';
            } else {
                url = '/campaign/replace/add';
            }
            this.$refs.addReplaceForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;

                    // console.log('BBB',this.appointData)

                    this.addReplaceForm.appointGoods = [];
                    switch (this.addReplaceForm.discountType) {
                        case '1':
                            this.addReplaceForm.fullNum = null;
                            this.addReplaceForm.appointGoods = [];
                            break;
                        case '2':
                            this.addReplaceForm.fullAmount = null;
                            this.addReplaceForm.appointGoods = [];
                            break;
                        case '3':
                            this.addReplaceForm.fullNum = null;
                            this.addReplaceForm.fullAmount = null;
                            // if(this.includeItems.length < 1){
                            // 	this.$message.warning('请选择指定商品')
                            // 	this.btnLoading = false
                            // 	return false
                            // }
                            // for(let i = 0; i < this.includeItems.length; i++){
                            // 	let id = this.includeItems[i].id
                            // 	this.addReplaceForm.appointGoods.push(id)
                            // }

                            if (this.appointData.length < 1) {
                                this.$message.warning('请新增换购规则');
                                this.btnLoading = false;
                                return false;
                            }

                            let replaceSort = 0;

                            for (let i = 0; i < this.appointData.length; i++) {
                                let item = this.appointData[i];
                                console.log(item);

                                if (item.appoint.length < 1) {
                                    this.$message.warning('请选择指定商品');
                                    this.btnLoading = false;
                                    return false;
                                }

                                this.addReplaceForm.appointGoods[i] = {
                                    appointGoodsIds: [],
                                    replaceGoodsList: [],
                                };

                                for (let k = 0; k < item.appoint.length; k++) {
                                    let id = item.appoint[k].id;
                                    this.addReplaceForm.appointGoods[
                                        i
                                    ].appointGoodsIds.push(id);
                                }

                                if (item.replace.length < 1) {
                                    this.$message.warning('请选择换购的商品');
                                    this.btnLoading = false;
                                    return false;
                                }

                                for (let k = 0; k < item.replace.length; k++) {
                                    if (!item.replace[k].replaceGoodsPrice) {
                                        item.replace[k].replaceGoodsPrice = 0;
                                        // this.$message.warning('请填写换购价格，不能为0')
                                        // this.btnLoading = false
                                        // return false
                                    }
                                    let json = {
                                        sort: (replaceSort += 1),
                                        goodsId: item.replace[k].id,
                                        price: item.replace[k]
                                            .replaceGoodsPrice,
                                    };
                                    this.addReplaceForm.appointGoods[
                                        i
                                    ].replaceGoodsList.push(json);
                                }
                            }

                            break;
                        default:
                            break;
                    }

                    console.log('GGG', this.addReplaceForm.appointGoods);

                    if (this.addReplaceForm.discountType != 3) {
                        if (this.joinItems.length < 1) {
                            this.$message.warning('请选择换购商品');
                            this.btnLoading = false;
                            return false;
                        }

                        this.addReplaceForm.replaceGoodsList = [];
                        for (let i = 0; i < this.joinItems.length; i++) {
                            let item = this.joinItems[i];
                            if (!item.replaceGoodsPrice) {
                                item.replaceGoodsPrice = 0;
                                // this.$message.warning('请填写换购价格，不能为0')
                                // this.btnLoading = false
                                // return false
                            }
                            let json = {
                                sort: Number([i]) + 1,
                                goodsId: item.id,
                                price: item.replaceGoodsPrice,
                            };
                            this.addReplaceForm.replaceGoodsList.push(json);
                        }
                    }

                    this.$http
                        .post(url, this.addReplaceForm)
                        .then((r) => {
                            this.btnLoading = false;

                            if (r.data.code === 200) {
                                this.dialogAddReplace = false;

                                this.$message({
                                    message: `${
                                        this.addReplaceForm.id ? '编辑' : '新增'
                                    }成功`,
                                    type: 'success',
                                    duration: 1000,
                                    onClose: this.queryData,
                                });
                            }
                        })
                        .catch((err) => {
                            this.btnLoading = false;
                        });
                }
            });
        },
        changePriority(e, id) {
            console.log(e, id);
            this.$http
                .post('/campaign/replace/editSort', [{ id: id, sort: e }])
                .then((r) => {
                    this.loading = false;

                    if (r.data.code === 200) {
                        this.$message({
                            message: '排序成功',
                            type: 'success',
                            duration: 1000,
                            onClose: this.queryData,
                        });
                    } else {
                        this.$message({
                            message: '排序失败',
                            type: 'error',
                            duration: 1000,
                            onClose: this.queryData,
                        });
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    this.queryData();
                });
        },
        changeReplacement(e, scope, price) {
            // console.log(e, price)
            let s = price.split('~');
            // console.log(parseFloat(s[0]), parseFloat(s[1]))
            if (s[1]) {
                if (e > parseFloat(s[0])) {
                    this.$message.warning('换购价不能高于最低售价。');
                    scope.row.replaceGoodsPrice = undefined;
                }
            } else {
                if (e > parseFloat(s[0])) {
                    this.$message.warning('换购价不能高于售价。');
                    scope.row.replaceGoodsPrice = undefined;
                }
            }
        },
        // 选择商品方法
        openSelectGoods(type, index, name, nameB) {
            // console.log(type, index, name)
            if (name) {
                // console.log(this[type][index])
                this.nowSelectgoods = JSON.parse(
                    JSON.stringify(this[type][index][name])
                );
                this.alreadyHasGoods = JSON.parse(
                    JSON.stringify(this.appointData)
                );
                this.goodsRowIndex = index;
                this.$refs.selectGoods.caller = [type, index, name];
                // console.log('111')
            } else {
                this.nowSelectgoods = JSON.parse(JSON.stringify(this[type]));
                this.$refs.selectGoods.caller = type;
                // console.log('222')
            }
            // console.log('ccc', this.nowSelectgoods, this.$refs.selectGoods.caller)
            // this.$refs.selectGoods.caller = type
            this.$nextTick(() => {
                this.$refs.selectGoods.init();
                this.dialogSelectGoods = true;
            });
        },
        closeSelectGoods() {
            this.dialogSelectGoods = false;
        },
        saveSelectGoods(items, caller) {
            let arr = [...caller];
            // console.log(arr)
            switch (caller) {
                // case "includeItems":
                // 	this.includeItems = items
                // 	break
                case 'joinItems':
                    this.joinItems = items;
                    // this.$nextTick(()=>{
                    //   this.rowDrop(caller)
                    // })
                    break;
                default:
                    this[arr[0]][arr[1]][arr[2]] = items;
                    if ([arr[2]] == 'replace') {
                        this.$nextTick(() => {
                            this.rowDrop(caller);
                        });
                    }
                    break;
                // this.selectedGoodsTable.data = items;
                // this.selectedGoodsTable.total = this.selectedGoodsTable.data.length;
            }
        },
        // 删除选中的商品（退出活动）
        deleteSelectedGoods(item, type, i, name) {
            if (name) {
                let index = this[type][i][name].indexOf(item);
                if (index > -1) {
                    this[type][i][name].splice(index, 1);
                }
            } else {
                let index = this[type].indexOf(item);

                if (index > -1) {
                    this[type].splice(index, 1);
                    // this.selectedGoodsTable.total = this.selectedGoodsTable.data.length;
                }
            }
        },
        querySearch() {
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        // 获取列表数据
        queryData() {
            if (this.campaignStatus == '0')
                this.queryForm.campaignStatus = null;
            else this.queryForm.campaignStatus = this.campaignStatus;

            this.loading = true;

            this.$http
                .get('/campaign/replace/getPage', { params: this.queryForm })
                .then((r) => {
                    this.loading = false;

                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        this.total = r.data.count;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    this.loading = false;
                });
        },
        // 重置列表查询条件
        resetCondition() {
            this.queryForm.campaignName = null;
            this.queryForm.pageNo = 1;

            this.queryData();
        },
        deleteReplace(id) {
            this.$confirm('确定要删除换购活动吗？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.$http
                    .delete('/campaign/replace/delete', { params: { id: id } })
                    .then((r) => {
                        if (r.data.code === 200) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                                duration: 1000,
                                onClose: this.queryData,
                            });
                        }
                    })
                    .catch((err) => {
                        // this.$message.error(error);;
                    });
            });
        },
        stopReplace(id) {
            this.$confirm('确定要结束换购活动吗？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.$http
                    .get('/campaign/replace/end', { params: { id: id } })
                    .then((r) => {
                        if (r.data.code === 200) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1000,
                                onClose: this.queryData,
                            });
                        }
                    })
                    .catch((err) => {
                        // this.$message.error(error);;
                    });
            });
        },
    },
};
</script>

<style scope>
.detailitems {
    margin: 40px 0;
}
.detailitems .item {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    width: calc(100% / 5 - 20px);
    box-sizing: border-box;
}
.detailitems .item .tit {
    font-size: 18px;
}
.detailitems .item .val {
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
}
.el-input-number {
    width: 120px !important;
}
.addReplaceSection {
    margin-bottom: 16px;
    padding: 16px 32px;
    background-color: #fff;
}
.addReplaceSection .appointrowbox {
    background: #f3f3f3;
    position: relative;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 20px;
}
.addReplaceSection .appointrowbox .deleterow {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 5px;
    z-index: 2;
    cursor: pointer;
}

.addReplaceLegend {
    font-size: 20px;
    font-weight: bold;
}

/* 适用范围卡片 */
.addReplaceCard {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    box-sizing: border-box;
    border: solid 1px #ecedf3;
    border-radius: 6px;
    width: 200px;
    height: 100px;
    cursor: pointer;
    overflow: hidden;
}

.addReplaceCard > h4 {
    margin: 5px 16px;
}

.addReplaceCard > p {
    margin: 0 16px;
    color: #999;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
}

.addReplaceSelectedCard {
    border: solid 2px #2d67f3;
    background: #f3f7ff;
}

.addReplaceCardChecked {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 50px;
    height: 50px;
    background-color: #2d67f3;
    transform: rotate(45deg);
}

.addReplaceCardChecked > i {
    position: absolute;
    top: 31px;
    left: 16px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    transform: rotate(-45deg);
}
</style>