<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>系统日志</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.userName" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryForm.operType" placeholder="操作类型" clearable>
                    <el-option v-for="item in operTypeOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="queryForm.operTime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData" v-if="$auth($authData.system.logGet)">查询</el-button>
            </el-form-item>
        </el-form>
        <!-- 数据表 -->
        <el-table border stripe :data="tableData" ref="logTable" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="operator" label="操作人" width="200"></el-table-column>
            <el-table-column prop="operModule" label="操作模块" width="200"></el-table-column>
            <el-table-column prop="operType" label="操作类型" width="80" header-align="center" align="center" :formatter="operTypeFormatter"></el-table-column>
            <el-table-column prop="operDesc" label="操作描述" min-width="200"></el-table-column>
            <el-table-column prop="remoteAddr" label="请求地址" width="120"></el-table-column>
            <el-table-column prop="operTime" label="操作时间" width="160" sortable></el-table-column>
            <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-search" @click="queryDetail(scope.row)">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>
        <!-- 业务弹框-日志详情 -->
        <el-dialog title="日志详情" :visible.sync="dialogDetail" :close-on-click-modal="false" top="3%" width="35%">
            <el-descriptions border :column="1" direction="vertical" v-loading="detailLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading">
                <el-descriptions-item label="日志ID">
                    {{ detailData.id }}
                </el-descriptions-item>
                <el-descriptions-item label="操作人">
                    {{ detailData.operator }}
                </el-descriptions-item>
                <el-descriptions-item label="操作人角色">
                    {{ detailData.roleName }}
                </el-descriptions-item>
                <el-descriptions-item label="操作模块">
                    {{ detailData.operModule }}
                </el-descriptions-item>
                <el-descriptions-item label="操作类型">
                    {{ this.operTypeFormatter(detailData) }}
                </el-descriptions-item>
                <el-descriptions-item label="操作描述">
                    {{ detailData.operDesc }}
                </el-descriptions-item>
                <el-descriptions-item label="请求地址">
                    {{ detailData.remoteAddr }}
                </el-descriptions-item>
                <el-descriptions-item label="请求数据">
                    <json-viewer :value="
              JSON.parse(detailData.requestData ? detailData.requestData : '{}')
            "></json-viewer>
                </el-descriptions-item>
                <el-descriptions-item label="操作时间">
                    {{ detailData.operTime }}
                </el-descriptions-item>
            </el-descriptions>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'Log',
    data() {
        return {
            queryForm: {
                userName: null,
                operType: null,
                operTime: [],
                pageNo: 1,
                pageSize: 10,
            },
            operTypeOptions: [
                {
                    name: '查询',
                    value: 1,
                },
                {
                    name: '新增',
                    value: 2,
                },
                {
                    name: '编辑',
                    value: 3,
                },
                {
                    name: '删除',
                    value: 4,
                },
            ],
            tableData: [],
            total: 0,
            loading: false,
            dialogDetail: false,
            detailLoading: false,
            detailData: {},
        };
    },
    mounted() {
        this.queryData();
    },
    methods: {
        queryData() {
            this.loading = true;
            let queryData = {
                userName: this.queryForm.userName,
                operType: this.queryForm.operType,
                startTime: null,
                endTime: null,
                pageNo: this.queryForm.pageNo,
                pageSize: this.queryForm.pageSize,
            };
            if (this.queryForm.operTime && this.queryForm.operTime.length > 1) {
                queryData.startTime = this.queryForm.operTime[0];
                queryData.endTime = this.queryForm.operTime[1];
            }
            this.$http
                .get('/system/log/getPage', { params: queryData })
                .then((response) => {
                    if (response.data.code === 200) {
                        this.tableData = response.data.data;
                        this.total = response.data.count;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        operTypeFormatter(row) {
            for (let i = 0, len = this.operTypeOptions.length; i < len; i++) {
                let item = this.operTypeOptions[i];
                if (row.operType === item.value) {
                    return item.name;
                }
            }
            return null;
        },
        queryDetail(row) {
            this.detailLoading = true;
            this.$http
                .get('/system/log/get', { params: { id: row.id } })
                .then((response) => {
                    if (response.data.code === 200) {
                        this.detailData = response.data.data;
                    }
                })
                .finally(() => {
                    this.detailLoading = false;
                });
            this.dialogDetail = true;
        },
    },
};
</script>

<style scoped></style>
