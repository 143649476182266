
<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>博客管理</el-breadcrumb-item>
        </el-breadcrumb>

        <el-form :inline="true" :model="queryForm" :rules="rules">
            <div class="between">
                <div class="mod">
                    <el-form-item>
                        <el-input class="order-list-query-keyword" placeholder="博客标题" v-model="queryForm.title">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button icon="el-icon-refresh-left" @click="resetQuery">重置</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="success" icon="el-icon-plus" @click="bridageAdd">新增</el-button>
                    </el-form-item>
                </div>
                <a class="link-list" title="查看博客" :href="listUrl" target="_blank">查看博客</a>
            </div>
        </el-form>

        <el-table ref="table" border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" :data="tableData" v-loading="loading == 'table' ">
            <el-table-column label="ID" prop="id"></el-table-column>
            <el-table-column label="博客封面">
                <template #default="{row}">
                    <el-image style="width: 100px; height: 100px" :src="row.image" :preview-src-list="row.image ? [row.image] : []">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column label="博客标题" prop="title"></el-table-column>
            <el-table-column label="阅读时间（分钟）" prop="readTime"></el-table-column>
            <el-table-column label="是否发布">
                <template #default="{row, $index}">
                    <el-switch v-model="row.isPublish" :active-value="1" :inactive-value="0" @change="setColumnPublish(row.id, $index, row.isPublish)">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime"></el-table-column>
            <el-table-column label="发布日期" prop="sendDate"></el-table-column>
            <el-table-column label="操作">
                <template #default="{row}">
                    <el-button size="mini" type="text" icon="el-icon-view" @click="openView(row.seoUrl)">预览
                    </el-button>
                    <el-button size="mini" type="text" icon="el-icon-edit-outline" @click="intoEdit(row)">编辑
                    </el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="bridgeRemoveItem(row)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <blogInfo ref="blogInfo" @submitCallback="submitCallback" :seoUrl="seoUrl"></blogInfo>

    </div>
</template>
<script>
import blogInfo from './BlogsInfo.vue';
export default {
    name: 'blogs',
    components: {
        blogInfo,
    },
    data: () => ({
        queryForm: {
            title: '',
            pageNo: 1,
            pageSize: 10,
        },
        total: 0,
        loading: null,
        tableData: [],
        rules: {},
        listUrl: '',
        seoUrl: '',
    }),
    mounted() {
        this.queryData();

        this.$http.get('/blog/getUrl').then((res) => {
            let {
                code,
                data: { listUrl, seoUrl },
            } = res.data;
            if (code === 200) {
                this.listUrl = listUrl;
                this.seoUrl = seoUrl;
            }
        });
    },
    methods: {
        submitCallback() {
            this.queryData();
        },
        queryData() {
            this.loading = 'table';
            this.$http
                .get('/blog/getPage', { params: this.queryForm })
                .then((res) => {
                    let { code, data, count } = res.data;

                    if (code === 200) {
                        this.tableData = data;
                        this.total = count;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        resetQuery() {
            Object.assign(
                this.queryForm,
                this.$options.data.call(this).queryForm
            );
            this.queryData();
        },
        bridgeRemoveItem({ id }) {
            this.$confirm('确定要删除当前这条博客吗？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.removeItem(id);
            });
        },
        openView(url) {
            window.open(url);
        },
        setColumnPublish(id, index, state) {
            let url = '/blog/setIsPublish';
            this.$http
                .post(url, {
                    id,
                })
                .then(
                    (res) => {
                        let { code } = res.data;
                        if (code == 200) {
                            this.$message.success('设置成功');
                        }
                    },
                    () => {
                        this.tableData[index]['isPublish'] = !state;
                    }
                );
        },
        removeItem(id) {
            let url = '/blog/delete';
            this.$http
                .delete(url, {
                    params: { id },
                })
                .then((res) => {
                    let { code } = res.data;
                    if (code === 200) {
                        this.$message.success('删除成功');
                        this.queryData();
                    }
                });
        },
        bridageAdd() {
            this.$refs.blogInfo.show({
                url: this.seoUrl,
            });
        },
        intoEdit(row) {
            this.$refs.blogInfo.show({
                ...row,
                url: this.seoUrl,
            });
        },
    },
};
</script>
<style scoped>
.between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.link-list {
    color: #409eff;
    text-decoration: underline;
    font-size: 14px;
    margin-bottom: 22px;
}
</style>