<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商品管理</el-breadcrumb-item>
            <el-breadcrumb-item>商品列表</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm">
			<div class="flex">
				<el-form-item>
					<el-input v-model="queryForm.goodsTitle" placeholder="商品标题"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model="queryForm.sku" placeholder="sku"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model="queryForm.spu" placeholder="spu"></el-input>
				</el-form-item>
				<!-- <el-form-item>
					<el-input v-model="queryForm.vendor" placeholder="供应商"></el-input>
				</el-form-item> -->
				<el-form-item>
					<el-select v-model="queryForm.published" placeholder="是否上架商品" clearable>
						<el-option label="是" value="1"></el-option>
						<el-option label="否" value="0"></el-option>
					</el-select>
				</el-form-item>
				
				<el-form-item>
					<el-select v-model="queryForm.isSynGmc" placeholder="是否同步gmc查询" clearable>
						<el-option label="是" value="1"></el-option>
						<el-option label="否" value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="queryForm.isDiy" placeholder="是否DIY" clearable>
						<el-option label="是" value="1"></el-option>
						<el-option label="否" value="0"></el-option>
					</el-select>
				</el-form-item>
			</div>
			
			<div class="flex">
				<el-form-item prop="cascader" class="type-require" style="width: 500px;" v-if="classifyData.length > 0">
					<el-cascader v-model="categoryIdArr" :options="classifyData" :props="{
					  multiple: true,
					  value: 'id',
					  label: 'categoryNameCn',
					  children: 'categoryItemList',
					  leaf: 'isNodes',
					}" clearable placeholder="商品分类"></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="querySearch">查询</el-button>
					<el-button type="success" icon="el-icon-plus" @click="openAdd">新增</el-button>
				</el-form-item>
			</div>
        </el-form>

        <el-table border :data="tableData" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" ref="table">
            <el-table-column label="ID" prop="id" width="80"></el-table-column>
            <el-table-column label="商品图片" width="120" header-align="center" align="center">
                <template slot-scope="scope">
                    <GoodsImageTemplate :images="scope.row.goodsImages"></GoodsImageTemplate>
                </template>
            </el-table-column>
            <el-table-column prop="goodsTitle" label="商品标题" width="240"></el-table-column>
            <el-table-column label="售价" width="150" header-align="center" align="center">
                <template slot-scope="scope">
                    {{ scope.row.price }}
                </template>
            </el-table-column>
			
			<el-table-column prop="spu" label="SPU" width="120" header-align="center" align="center"></el-table-column>

            <el-table-column prop="published" label="是否同步gmc" width="130" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.isSynGmc" :inactive-value="0" :active-value="1" active-color="#ffb340" @change="changedGmcSwitch(scope.row, $event)" :disabled="!$auth(505)">
                    </el-switch>
                </template>
            </el-table-column>

            <el-table-column prop="published" label="是否DIY" width="100" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.isDiy" :inactive-value="0" :active-value="1" active-color="#13ce66" @change="changedDiySwitch(scope.row, $event)">
                    </el-switch>
                </template>
            </el-table-column>

            <el-table-column prop="published" label="是否上架" width="100" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.published" inactive-value="0" active-value="1" @change="changePublished(scope.row, $event)">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="inventoryQuantity" label="库存" width="100" header-align="center" align="center"></el-table-column>
            <el-table-column prop="compareAtPrice" label="原价" width="100" header-align="center" align="center"></el-table-column>
            <el-table-column prop="fitPerson" label="适用人群" width="100" header-align="center" align="center">
                <template slot-scope="scope">
                    {{ getFitPerson(scope.row.fitPerson) }}
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
            <el-table-column fixed="right" label="操作" width="220" header-align="center" align="center">
                <template slot-scope="scope">
                    <!-- <div class="table-handler"> -->
                    <el-button size="mini" type="text" icon="el-icon-view" @click="openView(scope.row.viewUrl)">预览
                    </el-button>
                    <el-button size="mini" type="text" icon="el-icon-edit-outline" @click="openEdit(scope.row.id)">编辑
                    </el-button>
                    <!-- <el-button size="mini" type="text" icon="el-icon-guide" @click="bridgeDiySeries(scope.row)">关联DIY</el-button> -->
                    <!-- <div class="perch-links3"> -->
                    <el-button size="mini" type="text" icon="el-icon-magic-stick" @click="openDialogDiy(scope.row)" v-if="scope.row.isDiy">编辑DIY</el-button>
                    <!-- </div> -->

                    <!-- </div> -->
                    <!-- <el-button
            type="text"
            size="mini"
            icon="el-icon-plus"
            @click="initAddGoodsComment(scope.row.id)"
            >新增评论</el-button
          > -->
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background :current-page.sync="queryForm.pageNo" :page-sizes="[10, 30, 50]" :page-size.sync="queryForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <!-- 新增 -->
        <el-dialog fullscreen title="新增商品" :visible.sync="dialogAdd" :close-on-click-modal="false">
            <el-form :model="dictAddForm" :rules="dictAddRules" status-icon ref="dictAddForm" label-position="top" size="medium">
                <el-steps :active="addActive" finish-status="success" align-center>
                    <el-step title="基本属性&商品描述"></el-step>
                    <el-step title="图片&款式设置"></el-step>
                </el-steps>
                <div class="addform" v-if="addActive == 0">
                    <div class="addleft">
                        <el-form-item label="商品标题" prop="goodsTitle">
                            <el-input v-model="dictAddForm.goodsTitle" placeholder="请输入商品标题"></el-input>
                        </el-form-item>
                        <el-form-item label="商品副标题">
                            <el-input v-model="dictAddForm.goodsBrief" placeholder="请输入商品副标题"></el-input>
                        </el-form-item>
                        <el-form-item label="商品描述">
                            <vue-tinymce v-model="dictAddForm.goodsDesc" :setup="setup" :setting="setting"></vue-tinymce>
                        </el-form-item>
                        <template v-if="isEdit == 1">
                            <el-form-item label="seo标题">
                                <el-input placeholder="请输入seo标题" v-model="dictAddForm.seoTitle"></el-input>
                            </el-form-item>
                            <el-form-item label="seo关键字">
                                <el-tag closable :disable-transitions="true" v-for="( tag, index ) in dictAddForm.seoKeywords" :key="index" @close="dictAddForm.seoKeywords.splice( index, 1 )">
                                    {{ tag }}
                                </el-tag>
                                <el-input ref="seo_saveTagInput" size="small" class="input-new-tag" v-if="seo_inputVisible" v-model="seo_inputValue" @blur="seo_inputValue && dictAddForm.seoKeywords.push( seo_inputValue ); seo_inputVisible = false; seo_inputValue = '';">
                                </el-input>
                                <el-button class="button-new-tag" size="small" v-else @click="seo_inputVisible = true; $nextTick( _ => $refs.seo_saveTagInput.$refs.input.focus() );">
                                    + 新增
                                </el-button>
                            </el-form-item>
                            <el-form-item label="seo描述">
                                <el-input placeholder="请输入seo描述" v-model="dictAddForm.seoDesc"></el-input>
                            </el-form-item>
                            <el-form-item label="seo链接">
                                <el-input placeholder="请输入seo链接" v-model="splitLink.href">
                                    <template slot="prepend">{{ splitLink.host }}</template>
                                    <template slot="append">{{ splitLink.html }}</template>
                                </el-input>
                            </el-form-item>
                        </template>
                    </div>
                    <div class="addright">
                        <el-form-item label="SPU">
                            <el-input v-model="dictAddForm.spu" placeholder="请输入最多100个字符"></el-input>
                        </el-form-item>
                        <el-form-item label="虚拟销量(最大六位自然数)">
                            <el-input-number v-model="dictAddForm.fakeSales" :min="0" :max="999999" label="请输入最大六位自然数"></el-input-number>
                        </el-form-item>
                        <el-form-item label="商品标签">
                            <el-tag :key="index" v-for="(tag, index) in dictAddForm.goodsTags" closable :disable-transitions="false" @close="handleClose(tag)">
                                {{ tag }}
                            </el-tag>
                            <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
                            </el-input>
                            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增</el-button>
                        </el-form-item>
                        <el-form-item label="款式属性">
                            <el-tag :key="index" v-for="(tag,index) in dictAddForm.tags" :disable-transitions="false">
                                {{ tag.valueText }}
                            </el-tag>
                            <el-button type="primary" icon="el-icon-edit" size="small" @click="openTag">编辑</el-button>
                        </el-form-item>
                        <el-form-item label="适用人群">
                            <el-radio-group v-model="dictAddForm.fitPerson">
                                <el-radio :label="1">两者皆可</el-radio>
                                <el-radio :label="2">黑人</el-radio>
                                <el-radio :label="3">白人</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <!-- <el-form-item label="供应商">
                            <el-input v-model="dictAddForm.vendor" placeholder="请输入供应商名称"></el-input>
                        </el-form-item>
                        <el-form-item label="URL">
                            <el-input v-model="dictAddForm.vendorUrl" placeholder="请输入供应商URL"></el-input>
                        </el-form-item> -->
                        <el-form-item label="上架商品">
                            <el-switch v-model="dictAddForm.published" active-text="是" inactive-text="否" active-value="1" inactive-value="0">
                            </el-switch>
                        </el-form-item>
                        <el-form-item label="需要物流">
                            <el-switch v-model="dictAddForm.requiresShipping" active-text="是" inactive-text="否" active-value="1" inactive-value="0">
                            </el-switch>
                        </el-form-item>
                        <!-- <el-form-item label="需要税收">
              <el-switch
                v-model="dictAddForm.taxable"
                active-text="是"
                inactive-text="否"
                active-value="1"
                inactive-value="0"
              >
              </el-switch>
            </el-form-item> -->
                    </div>
                </div>
                <div class="" v-if="addActive == 1">
                    <el-form-item label="商品图片(请使用尺寸一样的商品图)" class="bgitem">
                        <el-button type="primary" @click="setOpenInsert('dictAddForm.goodsImages')">新增图片</el-button>
                        <el-button type="primary" @click="openVideoUploader('dictAddForm'); ">新增视频</el-button>
                        <template v-if="dictAddForm.goodsImages && dictAddForm.goodsImages.length > 0">
                            <vuedraggable class="goodsImagesRow" v-model="dictAddForm.goodsImages">
                                <transition-group class="flex">
                                    <section class="goodsImageBox pr df jc_c ai_c" v-for="( item, index ) in dictAddForm.goodsImages" :key="item + index">
                                        <template v-if="checkFiles(item, 'image')">
                                            <el-image fit="cover" style="width: 120px; height: 120px" :src="item" :preview-src-list="dictAddForm.goodsImages" :z-index="99999999">
                                                <div slot="error" class="el-image__error">
                                                    <i class="el-icon-picture-outline"></i>
                                                </div>
                                            </el-image>
                                        </template>

                                        <template v-if="checkFiles(item, 'video')">
                                            <div class="mod-video">
                                                <video :src="item" loop="loop" autoplay muted></video>
                                            </div>
                                        </template>

                                        <el-button type="danger" class="delimg" size="mini" icon="el-icon-close" circle @click="deleteImage(index)"></el-button>

                                    </section>
                                </transition-group>
                            </vuedraggable>
                        </template>
                    </el-form-item>
                    <el-form-item label="库存方案">
                        <el-select v-model="dictAddForm.isZeroInventory">
                            <el-option label="库存为0时不允许购买" :value="0"></el-option>
                            <el-option label="库存为0时允许购买" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="款式设置(在这里你可以设置该商品多种款式的细节，切换款式将清空已有数据)" class="bgitem">
                        <el-radio-group v-model="dictAddForm.single" @change="changeDesign">
                            <el-radio :label="1">单一款式</el-radio>
                            <el-radio :label="0">新增多个款式</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="单一款式" class="bgitem" v-if="dictAddForm.single == 1">
                        <el-table :data="dictAddForm.goodsItems" border stripe>
                            <el-table-column v-for="info in singleData" :key="info.key" :property="info.key" :label="info.label" :width="info.width">
                                <template slot-scope="scope">
                                    <!-- <el-input
                    v-model="scope.row[scope.column.property]"
                    :placeholder="'请输入' + info.label"
                  ></el-input> -->
                                    <el-input-number v-if="info.type == 'price'" size="small" :precision="2" :step="1" :min="0" v-model="scope.row[scope.column.property]" :placeholder="'请输入' + info.label"></el-input-number>
                                    <el-input-number v-if="info.type == 'number'" size="small" :precision="2" :step="1" :min="0" v-model="scope.row[scope.column.property]" :placeholder="'请输入' + info.label"></el-input-number>
                                    <el-input v-if="info.type == 'text'" v-model="scope.row[scope.column.property]" :placeholder="'请输入' + info.label"></el-input>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item class="bgitem" v-if="dictAddForm.single == 0">
                        <h3>
                            新增你的商品款式<el-button type="primary" @click="openDrawer" style="margin-left: 20px">新增/编辑</el-button>
                        </h3>
                        <el-table :data="dictAddForm.goodsItems" v-if="multipleData.length > 0" border stripe>
                            <el-table-column label="操作" fixed="left" width="80">
                                <template slot-scope="scope">
                                    <el-button @click="deleteMultipleData(scope.row.code)" type="text" size="small">删除</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column v-for="info in multipleData" :key="info.key" :property="info.key" :label="info.label" :width="info.width">
                                <template slot-scope="scope">
                                    <span v-if="info.type == 'static'">{{
                                        scope.row[scope.column.property]
                                      }}</span>
                                    <div v-if="info.type == 'image'" style="cursor: pointer; display:flex; justify-content:center;">
                                        <template v-if="scope.row.image">
                                            <template v-if="isImage(scope.row.image)">
                                                <img v-if="scope.row.image" :src="scope.row.image" class="avatar" @click="getObj(scope.row)" />
                                            </template>
                                            <template v-else>
                                                <video loop="loop" class="avatar" webkit-playsinline="true" playsinline="true" x-webkit-airplay="true" x5-video-player-type="h5-page" x5-video-orientation="landscape|portrait" muted autoplay :src="scope.row.image" @click="getObj(scope.row)"></video>
                                            </template>
                                        </template>

                                        <i v-else class="el-icon-plus avatar-uploader-icon" @click="getObj(scope.row)"></i>
                                    </div>
                                    <el-input-number v-if="info.type == 'price'" :default-value="0" size="small" :precision="2" :step="1" :min="0" v-model="scope.row[scope.column.property]" :placeholder="'请输入' + info.label" @change="formatterNumberInput($event, scope.$index)">
                                    </el-input-number>
                                    <el-input-number v-if="info.type == 'number'" size="small" :precision="2" :step="1" :min="0" v-model="scope.row[scope.column.property]" :placeholder="'请输入' + info.label"></el-input-number>
                                    <el-input v-if="info.type == 'text'" v-model="scope.row[scope.column.property]" :placeholder="'请输入' + info.label"></el-input>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>

                    <section class="bgitem" v-if="dictAddForm.single == 0 && goodsSizeData_sizes.length">
                        <h2>模特/网红信息</h2>
                        <div class="panel">
                            <strong>模特信息</strong>
                            <div class="handler">
                                <el-link type="primary" @click="addModelInfo">新增模特</el-link>
                            </div>
                            <el-table border stripe :data="modelList">
                                <el-table-column label="模特名称">
                                    <template slot-scope="{row}">
                                        <el-input size="small" v-model="row.modelName"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="模特头像">
                                    <template slot-scope="{row, $index}">
                                        <section class="goodsImageBox pr df jc_c ai_c" v-if="row.portrait">
                                            <el-image fit="contain" class="wh100 db_i" :src="row.portrait">
                                                <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                                            </el-image>
                                            <div class="pa100 mask_d5">
                                                <el-button type="danger" class="pa_cen cp" icon="el-icon-delete" circle @click="row.portrait = null"></el-button>
                                            </div>
                                        </section>
                                        <i v-else class="el-icon-plus img-uploader-icon cp goodsImageBox" style="border: dotted 1px #909399; border-radius: 4px;" @click="proxyInsert({
                                            index: $index,
                                            obj: 'modelList',
                                            key: 'portrait',
                                            type: 'image'
                                        })">
                                        </i>
                                    </template>
                                </el-table-column>

                                <el-table-column label="模特试穿尺码">
                                    <template slot-scope="{row}">
                                        <el-select v-model="row.modelSizeAttempt">
                                            <el-option v-for="(item,index) of goodsSizeData_sizes" :key="index" :label="item" :value="item"></el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>

                                <el-table-column label="模特常用尺码">
                                    <template slot-scope="{row}">
                                        <el-input size="small" v-model="row.modelSize"></el-input>
                                    </template>
                                </el-table-column>

                                <el-table-column label="身高（cm）">
                                    <template slot-scope="{row}">
                                        <el-input-number size="small" :min="0" :max="9999" :precision="0" v-model="row.modelHeight"></el-input-number>
                                    </template>
                                </el-table-column>

                                <el-table-column label="胸围(cm)">
                                    <template slot-scope="{row}">
                                        <el-input-number size="small" :min="0" :max="9999" :precision="0" v-model="row.modelBust"></el-input-number>
                                    </template>
                                </el-table-column>

                                <el-table-column label="腰围(cm)">
                                    <template slot-scope="{row}">
                                        <el-input-number size="small" :min="0" :max="9999" :precision="0" v-model="row.modelWaist"></el-input-number>
                                    </template>
                                </el-table-column>

                                <el-table-column label="臀围(cm)">
                                    <template slot-scope="{row}">
                                        <el-input-number size="small" :min="0" :max="9999" :precision="0" v-model="row.modelHip"></el-input-number>
                                    </template>
                                </el-table-column>

                                <el-table-column label="模特试穿图">
                                    <template slot-scope="{row, $index}">
                                        <el-link type="primary" v-show="(row.modelPhoto && row.modelPhoto.length)" @click="viewImage(row.modelPhoto, $index, 'modelPhoto', 'modelList')">查看</el-link>

                                        <div v-show="!(row.modelPhoto && row.modelPhoto.length)">
                                            <i class="el-icon-plus img-uploader-icon cp goodsImageBox" style="border: dotted 1px #909399; border-radius: 4px;" @click="proxyInsert({
                                            index: $index,
                                            obj: 'modelList',
                                            key: 'modelPhoto',
                                            type: 'image'
                                        })"></i>
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column label="模特试穿视频">
                                    <template slot-scope="{row, $index}">
                                        <el-link type="primary" @click="viewVideo(row.modelVideo, $index, 'modelVideo', 'modelList')" v-show="(row.modelVideo && row.modelVideo.length)">查看</el-link>

                                        <div v-show="!(row.modelVideo && row.modelVideo.length)">
                                            <i class="el-icon-plus img-uploader-icon cp goodsImageBox" style="border: dotted 1px #909399; border-radius: 4px;" @click="proxyInsert({
                                            index: $index,
                                            obj: 'modelList',
                                            key: 'modelVideo',
                                            type: 'video'
                                        })"> </i>
                                        </div>

                                    </template>
                                </el-table-column>

                                <el-table-column label="操作">
                                    <template slot-scope="{$index}">
                                        <el-link type="primary" @click="deteleModelTable($index, 'modelList')">删除</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>

                        <!-- <div class="panel">
                            <strong>网红信息</strong>
                            <div class="handler">
                                <el-link type="primary" @click="addInsModelInfo">新增网红</el-link>
                            </div>
                            <el-table border stripe :data="influencerList">
                                <el-table-column label="网红昵称" width="200px">
                                    <template slot-scope="{row}">
                                        <el-input size="small" v-model="row.name"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="网红头像" width="180px">
                                    <template slot-scope="{row, $index}">
                                        <section class="goodsImageBox pr df jc_c ai_c" v-if="row.portrait">
                                            <el-image fit="contain" class="wh100 db_i" :src="row.portrait">
                                                <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                                            </el-image>
                                            <div class="pa100 mask_d5">
                                                <el-button type="danger" class="pa_cen cp" icon="el-icon-delete" circle @click="row.portrait = null"></el-button>
                                            </div>
                                        </section>
                                        <i v-else class="el-icon-plus img-uploader-icon cp goodsImageBox" style="border: dotted 1px #909399; border-radius: 4px; margin:0 auto 20px;" @click="proxyInsert({
                                            index: $index,
                                            obj: 'influencerList',
                                            key: 'portrait',
                                            type: 'image'
                                        })">
                                        </i>
                                    </template>
                                </el-table-column>

                                <el-table-column label="网红图片">
                                    <template slot-scope="{row, $index}">
                                        <el-link type="primary" v-show="(row.photoList && row.photoList.length)" @click="viewImage(row.photoList, $index, 'photoList', 'influencerList')">查看</el-link>

                                        <div v-show="!(row.photoList && row.photoList.length)">
                                            <i class="el-icon-plus img-uploader-icon cp goodsImageBox" style="border: dotted 1px #909399; border-radius: 4px;" @click="proxyInsert({
                                                index: $index,
                                                obj: 'influencerList',
                                                key: 'photoList',
                                                type: 'image'
                                            })"></i>
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column label="网红视频">
                                    <template slot-scope="{row, $index}">
                                        <el-link type="primary" v-if="(row.videoList && row.videoList.length)" @click="viewVideo(row.videoList, $index, 'videoList', 'influencerList')" >查看</el-link>

                                        <div v-else>
                                            <i class="el-icon-plus img-uploader-icon cp goodsImageBox" style="border: dotted 1px #909399; border-radius: 4px;" @click="proxyInsert({
                                                index: $index,
                                                obj: 'influencerList',
                                                key: 'videoList',
                                                type: 'video'
                                            })"> </i>
                                        </div>

                                    </template>
                                </el-table-column>


                                <el-table-column label="操作" width="120px">
                                    <template slot-scope="{$index}">
                                        <el-link type="primary" @click="deteleModelTable($index, 'influencerList')">删除</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>

                        </div> -->
                    </section>

                    <!-- 尺码信息 -->
                    <section class="bgitem" v-if="dictAddForm.single == 0 && goodsSizeData_sizes.length">

                        <!-- 尺码表 -->
                        <div class="df jc_sb">
                            <span>尺码表（单位：cm）</span>
                            <el-button type="text" @click="goodsSizeInfo_addColumn">增加一列</el-button>
                        </div>
                        <el-table border :show-header="false" :data="goodsSizeInfo_tableData">
                            <el-table-column prop="size" width="100"></el-table-column>
                            <el-table-column min-width="100" v-for="( item, index ) in goodsSizeInfo_tableHeader" :key="index">
                                <template slot-scope="scope">
                                    <el-form-item class="cen formItemOfTd" v-if="scope.$index == 0" :key="'header' + item + scope.row.size">
                                        <el-input maxlength="50" v-model="scope.row[ item ]">
                                            <el-button slot="append" type="primary" icon="el-icon-delete" @click="goodsSizeInfo_deleteColumn( index )"></el-button>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item class="cen formItemOfTd" v-else :key="'body' + item + scope.row.size">
                                        <el-input-number :min="0" :max="9999" :precision="0" style="width: 100%;" v-model="scope.row[ item ]"></el-input-number>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- 尺码表 end -->

                        <el-form-item label="量法图片">
                            <div class="flex">
                                <div>
                                    <p style="margin: 5px 0;">PC端</p>
                                    <section class="goodsImageBox pr df jc_c ai_c" v-if="goodsSizeInfo.measureImage">
                                        <el-image fit="contain" class="wh100 db_i" :src="goodsSizeInfo.measureImage">
                                            <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                                        </el-image>
                                        <div class="pa100 mask_d5">
                                            <el-button type="danger" class="pa_cen cp" icon="el-icon-delete" circle @click="delmeasure('measureImage')"></el-button>
                                        </div>
                                    </section>
                                    <i v-else class="el-icon-plus img-uploader-icon cp goodsImageBox" style="border: dotted 1px #909399; border-radius: 4px;" @click="setOpenInsert( 'goodsSizeInfo.measureImage' )">
                                    </i>
                                </div>

                                <div>
                                    <p style="margin: 5px 0;">移动端</p>
                                    <section class="goodsImageBox pr df jc_c ai_c" v-if="goodsSizeInfo.measureImageMobile">
                                        <el-image fit="contain" class="wh100 db_i" :src="goodsSizeInfo.measureImageMobile">
                                            <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                                        </el-image>
                                        <div class="pa100 mask_d5">
                                            <el-button type="danger" class="pa_cen cp" icon="el-icon-delete" circle @click="delmeasure('measureImageMobile')"></el-button>
                                        </div>
                                    </section>
                                    <i v-else class="el-icon-plus img-uploader-icon cp goodsImageBox" style="border: dotted 1px #909399; border-radius: 4px;" @click="setOpenInsert( 'goodsSizeInfo.measureImageMobile' )">
                                    </i>
                                </div>
                            </div>

                        </el-form-item>
                    </section>
                    <!-- 尺码信息 end -->

                    <el-form-item label="备注" class="bgitem">
                        <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="dictAddForm.goodsRemark">
                        </el-input>
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button v-if="addActive == 1" @click="addActive = 0">上一步</el-button>
                <el-button @click="dialogAdd = false">取消</el-button>
                <el-button type="primary" :disabled="dictAddForm.goodsTitle == ''" v-if="addActive == 0" @click="addActive = 1">下一步</el-button>
                <el-button type="primary" :disabled="dictAddForm.goodsTitle == ''" :loading="addLoading" v-if="addActive == 1 && isEdit == 0" @click="addData">保存</el-button>
                <el-button type="primary" :disabled="dictAddForm.goodsTitle == ''" :loading="addLoading" v-if="addActive == 1 && isEdit == 1" @click="addData">保存</el-button>
            </div>
        </el-dialog>

        <el-dialog width="1000px" title="编辑图片" :visible.sync="dialogModelImage" :close-on-click-modal="false">
            <div class="container">
                <div class="goodsImagesRow">
                    <section class="goodsImageBox pr df jc_c ai_c" v-for="(item, index) in modelImgList" :key="index">
                        <el-image fit="contain" class="wh100 db_i" :src="item">
                            <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                        </el-image>
                        <div class="pa100 mask_d5">
                            <el-button type="danger" class="pa_cen cp" icon="el-icon-delete" circle @click="deleteSeleckedImage(modelImgList, item)"></el-button>
                        </div>
                    </section>
                    <i class="el-icon-plus img-uploader-icon cp goodsImageBox" style="border: dotted 1px #909399; border-radius: 4px;" @click="proxyInsert({
                            obj: 'modelImgList',
                            type: 'image'
                        })">
                    </i>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="replaceModelPraram(modelKey, modelData)">确定</el-button>
                <el-button @click="dialogModelImage = false">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog width="1000px" title="编辑视频" :visible.sync="dialogModelVideo" :close-on-click-modal="false">
            <div class="container">
                <div class="goodsImagesRow">
                    <section class="goodsImageBox pr df jc_c ai_c" v-for="(item, index) in modelVideoList" :key="index">
                        <video width="100%" controls autoplay loop :src="item">
                        </video>
                        <div class="pa100 mask_d5">
                            <el-button type="danger" class="pa_cen cp" icon="el-icon-delete" circle @click="deleteSeleckedImage(modelVideoList, item)"></el-button>
                        </div>
                    </section>
                    <i class="el-icon-plus img-uploader-icon cp goodsImageBox" style="border: dotted 1px #909399; border-radius: 4px;" @click="proxyInsert({
                        obj: 'modelVideoList',
                        type: 'video'
                    })">
                    </i>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="replaceModelPraram(modelKey, modelData) ">确定</el-button>
                <el-button @click="dialogModelVideo = false">取消</el-button>
            </div>
        </el-dialog>

        <ImageUploader :insertImage="insertImage" :insertType="insertType" @getOpenState="getOpenState" @getImage="getImage" ref="uploadImage">
        </ImageUploader>

        <!--子商品 -->
        <el-drawer title="新增款式(最多新增三个商品属性)" :visible.sync="multipleDrawer" :wrapperClosable="false" class="drawer" :size="450" :direction="direction">
            <div class="demo-drawer__content">
                <div class="mulitplebox" v-for="(item, index) in dictMultipleAttrForm" :key="index">
                    <el-form :model="item" :rules="dictMultipleAttrRules" status-icon ref="item" label-position="top" size="medium">
                        <el-form-item label="款式名称">
                            <el-input disabled v-model="item.title" placeholder="请输入款式名称"></el-input>
                        </el-form-item>
                        <el-form-item label="款式值">
                            <!-- <el-tag
                :key="tag"
                v-for="tag in item.tags"
              >
                {{ tag }}
              </el-tag> -->

                            <template v-if="item.tags && item.tags.length > 0">
                                <vuedraggable class="" v-model="item.tags">
                                    <transition-group class="flex">
                                        <el-tag v-for="(itemB, indexB) in item.tags" :key="itemB + indexB">
                                            {{ itemB }}
                                        </el-tag>
                                    </transition-group>
                                </vuedraggable>
                            </template>

                            <!-- <el-tag
                :key="tag"
                v-for="tag in item.tags"
                closable
                :disable-transitions="false"
                @close="multipleHandleClose(tag, index)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                class="input-new-tag"
                v-if="item.multipleInputVisible"
                v-model="item.multipleInputValue"
                ref="saveMultipleTagInput"
                size="small"
                @keyup.enter.native="multipleHandleInputConfirm(index)"
                @blur="multipleHandleInputConfirm(index)"
              >
              </el-input>
              <el-button
                v-else
                class="button-new-tag"
                size="small"
                @click="multipleShowInput(index)"
                >+ 新增</el-button
              > -->
                        </el-form-item>
                        <el-button type="danger" @click="deleteThisMultiple(index)">删除</el-button>
                    </el-form>
                </div>
                <!-- <el-button
          type="primary"
          class="block"
          icon="el-icon-plus"
          @click="addMultiple"
          v-if="this.dictMultipleAttrForm.length < 3"
          >创建商品属性</el-button
        > -->
                <el-button type="primary" class="block" v-if="this.dictMultipleAttrForm.length < 3" @click="chooseMultiple">选择商品款式</el-button>
            </div>
            <div class="demo-drawer__footer">
                <el-button type="primary" @click="addMultipleData(true)">确定</el-button>
                <el-button @click="multipleDrawer = false">取消</el-button>
            </div>
        </el-drawer>

        <!-- 新增评论 -->
        <el-dialog top="3%" title="新增评论" width="70%" :visible.sync="dialogAddGoodsComment" :close-on-click-modal="false">
            <el-form ref="goodsCommentAddForm" size="medium" label-position="top" status-icon :model="goodsCommentAddForm" :rules="goodsCommentRules">
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item required label="评论分数" prop="grade">
                            <el-rate class="ol_n" v-model="goodsCommentAddForm.grade"></el-rate>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="初始点赞数" prop="likeNum">
                            <!-- <el-input-number
                :min="0"
                maxlength="100"
                placeholder="请输入初始点赞数"
                v-model="goodsCommentAddForm.likeNum"
              ></el-input-number> -->
                            <el-rate class="ol_n" v-model="goodsCommentAddForm.likeNum"></el-rate>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="评论日期" prop="commentTime">
                            <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择评论日期" style="width: 100%" v-model="goodsCommentAddForm.commentTime">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="国家/地区" prop="countryId">
                            <el-select v-model="goodsCommentAddForm.countryId">
                                <el-option v-for="item in country" :key="item.id" :label="item.cnName" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="15">
                        <el-form-item label="评论内容" prop="content">
                            <el-input type="textarea" maxlength="200" show-word-limit placeholder="请输入评论内容" :autosize="{ minRows: 6, maxRows: 12 }" v-model="goodsCommentAddForm.content">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9">
                        <el-form-item label="评论人" prop="commentator">
                            <el-input maxlength="20" show-word-limit placeholder="请输入评论人" v-model="goodsCommentAddForm.commentator"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="评论图片">
                    <main class="df fw_w ai_c">
                        <i class="el-icon-plus img-uploader-icon cp goodsCommentImageBox" style="border: dotted 1px #aaa; border-radius: 4px" @click="setOpenInsert('goodsCommentAddForm.images')">
                        </i>
                        <template v-if="
                goodsCommentAddForm.images &&
                goodsCommentAddForm.images.length > 0
              ">
                            <section class="goodsCommentImageBox pr df jc_c ai_c" v-for="item in goodsCommentAddForm.images" :key="item">
                                <el-image fit="contain" class="goodsCommentImage" :src="item">
                                    <div slot="error" class="el-image__error">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                                <div class="pa100 mask_d5">
                                    <el-button type="danger" class="pa_cen cp" icon="el-icon-delete" circle @click="
                      deleteSeleckedImage(goodsCommentAddForm.images, item)
                    ">
                                    </el-button>
                                </div>
                            </section>
                        </template>
                    </main>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :disabled="btnLoading" :loading="btnLoading" @click="addGoodsComment">确定</el-button>
                <el-button @click="dialogAddGoodsComment = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 选择商品款式 -->
        <el-dialog top="3%" title="选择商品款式" width="1000px" :visible.sync="dialogAddGoodsType" :close-on-click-modal="false">
            <el-form size="medium" label-position="top" v-if="typeData.length > 0 && goodsTypeAddForm.length > 0" status-icon>
                <el-form-item v-for="(item, index) in typeData" :key="index">
                    <div>{{ item.typeName }}</div>
                    <el-checkbox-group v-model="goodsTypeAddForm[index].tags" :disabled="goodsTypeAddForm[index].tags.length == 0 && hasrow >= 3">
                        <el-checkbox v-for="(val,indexB) in item.typeValues" :label="val.valueText" @change="getNowChoose(index)" :key="indexB">
                            {{ val.valueText }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="getGoodsType">确定</el-button>
                <el-button @click="dialogAddGoodsType = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 选择运营标签 -->
        <el-dialog top="3%" title="选择运营标签" width="600px" :visible.sync="dialogTagIds" :close-on-click-modal="false">
            <el-tabs v-model="tagIdsActive" v-loading="tagsloading">
                <el-tab-pane v-for="(item,index) in tagsData" :key="index" :label="item.labelName" :name="String(index)">
                    <el-tag v-for="(tag,indexB) in item.shopGoodsLabelValues" :key="indexB" closable :disable-transitions="false" @click="selectTag(tag.id, tag.valueText)" @close="closeTag(tag.id)">
                        {{ tag.valueText }}
                    </el-tag>
                    <el-input class="input-new-tag" v-if="item.inputVisible" v-model="item.inputValue" ref="item.saveTagInput" size="small" @input="refreshInput" @keyup.enter.native="tagsInputConfirm(item.id, item.inputValue)" @blur="tagsInputConfirm(item.id ,item.inputValue)">
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="tagsInput(index, item.saveTagInput)">新增</el-button>
                </el-tab-pane>
            </el-tabs>
            <el-divider></el-divider>
            <h3>已选标签：</h3>
            <div class="">
                <el-tag v-for="(item,index) in dictAddForm.tags" :key="index" closable :disable-transitions="false" @close="deleteTag(index)">
                    {{ item.valueText }}
                </el-tag>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="tagIdsData()">确定</el-button>
                <el-button @click="dialogTagIds = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 选择子商品图片 -->
        <el-dialog top="3%" title="选择子商品图片" width="600px" :visible.sync="dialogSelectImage" :close-on-click-modal="false">
            <div class="goodsImagesRow" v-if="dictAddForm.goodsImages && dictAddForm.goodsImages.length > 0">
                <div v-for="(item, index) in dictAddForm.goodsImages" :key="index" class="selectedGoodsImages">
                    <template v-if="isImage(item)">
                        <img :src="item" class="image" @click="selectImageData(item)" />
                    </template>
                    <template v-else>
                        <video loop="loop" webkit-playsinline="true" playsinline="true" x-webkit-airplay="true" x5-video-player-type="h5-page" x5-video-orientation="landscape|portrait" muted autoplay :src="item" @click="selectImageData(item)"></video>
                    </template>
                </div>
            </div>
            <!-- <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="selectImageData()">确定</el-button>
        <el-button @click="dialogSelectImage = false">取消</el-button>
      </div> -->
        </el-dialog>

        <!-- 视频 -->
        <VideoUploader ref="uploadVideo" :insertVideo="dialogInsertVideo" :insertVideoType="insertVideoType" :activeVideo="activeVideo" @getOpenVideo="getOpenVideo" @getVideo="getVideo">
        </VideoUploader>

        <el-dialog top="3%" :visible.sync="dialogVisibleVideo">
            <video width="100%" controls autoplay loop>
                <source :src="dialogVideoUrl">
            </video>
        </el-dialog>
        <!-- 视频 end -->

        <el-dialog title="关联DIY" width="1000px" :visible.sync="dialogDiyInfo" :close-on-click-modal="false" @closed="resetDialogDiy">
            <el-table border :data="diyTableData" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" ref="diyTable" @selection-change="handleSelectionChange" max-height="1000px">
                <el-table-column type="selection"></el-table-column>
                <el-table-column label="DIY标题" prop="materialName"></el-table-column>
                <el-table-column label="素材" width="610">
                    <template slot-scope="{row}">
                        <div class="list-mod">
                            <div class="images-list">
                                <div class="item" v-for="(item, index) in urilsFormatter(row.materialUrl)" :key="index">
                                    <el-image :src="item" :preview-src-list="row.materialUrl"></el-image>
                                </div>
                            </div>
                            <p v-if="row.materialUrl && row.materialUrl.length > 6">...</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="{row}">
                        <el-switch v-model="row.materialStatus" :active-value="1" :inactive-value="0" disabled>
                        </el-switch>
                    </template>
                </el-table-column>
            </el-table>
            <!-- :current-page.sync="diyForm.pageNo" -->
            <el-pagination background :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="diyForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="diyTotal" @size-change="proxyPagerDiyList" @current-change="proxyPagerDiyList">
            </el-pagination>

            <div slot="footer" class="dialog-footer" style="padding-top:60px;">
                <el-button type="primary" @click="submitDiyAllocation">确定</el-button>
                <el-button @click="dialogDiyInfo = false">取消</el-button>
            </div>
        </el-dialog>

        <GoodsDiyEdit ref="goodsDiyEdit"></GoodsDiyEdit>

    </div>
</template>

<script>
import '@/assets/fonts/fonts.css';
import FontFaceObserver from 'fontfaceobserver';
import _ from 'lodash';
import vuedraggable from 'vuedraggable';
import ImageUploader from '@/components/ImageUploader';
import VideoUploader from '@/components/VideoUploader';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
import GoodsDiyEdit from './GoodsDiyEdit.vue';

export default {
    name: 'GoodsList',
    components: {
        ImageUploader: ImageUploader,
        vuedraggable: vuedraggable,
        VideoUploader: VideoUploader,
        GoodsDiyEdit,
        GoodsImageTemplate,
    },
    data() {
        return {
            dialogInsertVideo: false,
            activeVideo: 'first',
            insertVideoType: null,
            dialogVisibleVideo: false,
            dialogVideoUrl: null,

            splitLink: {},
            // seo关键字
            seo_inputVisible: false,
            seo_inputValue: '',
            dialogSelectImage: false,
            dialogTagIds: false,
            tagIdsActive: 0,
            tagsloading: false,
            tagsData: [],

            dialogAddGoodsType: false,
            goodsTypeAddForm: [],
            typeData: [],
            nowChoose: null,
            hasrow: 0,
			
			categoryIdArr: [],

            queryForm: {
                goodsTitle: null,
                published: null,
                sku: null,
                spu: null,
                vendor: null,
                pageNo: 1,
                pageSize: 10,
				categoryIds: [],
				isSynGmc: null,
				isDiy: null,
            },
            tableData: [],
            total: 0,
            loading: false,
            tempItems: [],
            defaultGoodsItems: [
                {
                    barcode: '',
                    code: '',
                    compareAtPrice: '',
                    image: '',
                    inventoryQuantity: '',
                    price: '',
                    priceUnit: 'USD',
                    remark: '',
                    sku: '',
                    weighUnit: 'kg',
                    weight: '',
                },
            ],
            dictAddForm: {},
            goodsSizeInfo: {}, // 尺码信息
            goodsSizeInfo_tableData: [], // 尺码信息 - 尺码表
            goodsSizeInfo_tableHeader: [], // 尺码信息 - 表头
            goodsSizeInfo_fn: 0, // 尺码信息 - 表头自增数
            addLoading: false,
            dialogAdd: false,
            dictAddRules: {
                goodsTitle: [
                    {
                        required: true,
                        message: '请输入商品标题',
                        trigger: 'blur',
                    },
                ],
            },
            setting: {
                menubar: 'edit view format tools table',
                toolbar:
                    'undo redo removeformat | fullscreen | imageUpload | videoUpload code | styleselect alignleft aligncenter alignright alignjustify | link | numlist bullist | fontselect fontsizeselect forecolor backcolor | table bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
                toolbar_mode: 'sliding',
                quickbars_insert_toolbar: 'quicktable',
                quickbars_selection_toolbar:
                    'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
                plugins:
                    'link image media code table textpattern lists fullscreen quickbars',
                language: 'zh_CN',
                height: 350,
                convert_urls: false,
            },
            inputVisible: false,
            inputValue: '',
            addActive: 0,
            activeName: 'first',
            insertType: null,
            insertImage: false,
            singleData: [
                {
                    label: '售价(USD)',
                    key: 'price',
                    type: 'price',
                    width: 180,
                },
                {
                    label: '原价(USD)',
                    key: 'compareAtPrice',
                    type: 'price',
                    width: 180,
                },
                {
                    label: '重量(g)',
                    key: 'weight',
                    type: 'number',
                    width: 180,
                },
                {
                    label: 'SKU',
                    key: 'sku',
                    type: 'text',
                    width: 180,
                },
                {
                    label: '条形码',
                    key: 'barcode',
                    type: 'text',
                    width: 180,
                },
                {
                    label: '库存数量',
                    key: 'inventoryQuantity',
                    type: 'number',
                    width: 180,
                },
                {
                    label: '备注',
                    key: 'remark',
                    type: 'text',
                },
            ],
            multipleDrawer: false,
            direction: 'rtl',
            dictMultipleAttrForm: [
                {
                    title: null,
                    tags: [],
                    multipleInputVisible: false,
                    multipleInputValue: '',
                },
            ],
            dictMultipleAttrRules: {
                title: [
                    {
                        required: true,
                        message: '请输入款式名称',
                        trigger: 'blur',
                    },
                ],
                tags: [
                    {
                        required: true,
                        type: 'array',
                        message: '请输入款式值，多个用回车隔开',
                        trigger: 'blur',
                    },
                ],
            },
            defaultMultipleData: [
                {
                    label: '售价(USD)',
                    key: 'price',
                    type: 'price',
                    width: 180,
                },
                {
                    label: '原价(USD)',
                    key: 'compareAtPrice',
                    type: 'price',
                    width: 180,
                },
                {
                    label: '重量(kg)',
                    key: 'weight',
                    type: 'number',
                    width: 180,
                },
                {
                    label: 'SKU',
                    key: 'sku',
                    type: 'text',
                    width: 180,
                },
                {
                    label: '条形码',
                    key: 'barcode',
                    type: 'text',
                    width: 150,
                },
                {
                    label: '库存数量',
                    key: 'inventoryQuantity',
                    type: 'number',
                    width: 180,
                },
				{
				    label: '亚马逊链接',
				    key: 'amazonUrl',
				    type: 'text',
				    width: 180,
				},
                {
                    label: '备注',
                    key: 'remark',
                    type: 'text',
                },
            ],
            multipleData: [],
            tempOptionArr: [],
            isEdit: 0,
            nowSelectImage: null,
            modelList: [],
            modelIndex: null,
            modelKey: null,
            modelData: null,
            dialogModelImage: false,
            dialogModelVideo: false,
            modelImgList: [],
            modelVideoList: [],
            influencerList: [],

            // 新增评论 - 国家数据
            country: [],
            // 新增评论
            btnLoading: false,
            dialogAddGoodsComment: false,
            goodsCommentAddForm: {},
            goodsCommentRules: {
                grade: [
                    // { required: true, message: "请选择评论分数", trigger: "change" },
                    {
                        validator: (rule, value, callback) => {
                            if (value == 0) callback('请选择评论分数');
                            else callback();
                        },

                        trigger: 'change',
                    },
                ],
            },

            //
            diyForm: {
                pageNo: 1,
                pageSize: 10,
            },
            diyTotal: 0,
            dialogDiyInfo: false,
            isDiyPageFirst: true,
            diyTableData: [],
            multipleSelectionDiy: [],
            multipleSelectionDiyCopy: [],
            _diyIds: [],
			classifyData: [],
        };
    },
    computed: {
        insertDisabled() {
            return this.insertLoading || this.insertNoMore;
        },

        isImage() {
            return function (src) {
                return /(.*)\.(gif|jpg|jpeg|png|GIF|JPG|PNG|JPEG)$/.test(src);
            };
        },

        // 选择的尺码数组
        goodsSizeData_sizes() {
            let size = this.dictMultipleAttrForm.find((v) => v.title == 'Size');
            return size ? size.tags : [];
        },

        urilsFormatter() {
            return function (arr) {
                return JSON.parse(JSON.stringify(arr)).splice(0, 6);
            };
        },

        checkFiles() {
            return function (fileValue, type) {
                let index = fileValue.indexOf('.'),
                    fileValueSuffix = fileValue.substring(index);

                if (type == 'video') {
                    if (!/(.*)\.(mp4|rmvb|avi|ts)$/.test(fileValueSuffix)) {
                        return false;
                    }
                }

                if (type == 'image') {
                    if (
                        !/(.*)\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(
                            fileValueSuffix
                        )
                    ) {
                        return false;
                    }
                }

                return true;
            };
        },
    },
    watch: {
        goodsSizeData_sizes: function (sizes) {
            // 在这里更新尺码表的数据
            this.goodsSizeInfo_tableData.splice(1);

            sizes.forEach((size) => {
                let newRow = { size };
                this.goodsSizeInfo_tableHeader.forEach(
                    (col) => (newRow[col] = undefined)
                );
                this.goodsSizeInfo_tableData.push(newRow);
            });
        },
    },
    mounted() {
        this.queryData();
        this.queryCountry();
        this.loaderFonts();
		
		this.$http.get('/goods/category/getList').then((res) => {
		    let { code, data } = res.data;
		    if (code === 200) this.classifyData = data;
		});

        // this.$refs.goodsDiyEdit.show(4614);
    },
    methods: {
        loaderFonts(cb) {
            let fonts = [
                'Helvetica Neue',
                'Delicious Choice',
                'Verdana',
                'Comic Sans MS',
                'Impact',
                'Monaco',
                'Plaster Regular',
                'Ventus',
            ];

            Promise.all(
                fonts.map((family) => {
                    new FontFaceObserver(family).load();
                })
            ).then(function () {
                cb && cb();
            });
        },
        openTag() {
            this.getTagsData();
            this.dialogTagIds = true;
        },
        getTagsData() {
            this.tagsloading = true;
            this.$http
                .get('/goods/label/getList')
                .then((response) => {
                    if (response.data.code === 200) {
                        this.tagsloading = false;
                        this.tagsData = response.data.data;
                        for (let i = 0; i < this.tagsData.length; i++) {
                            this.tagsData[i].inputVisible = false;
                            this.tagsData[i].inputValue = null;
                            this.tagsData[i].saveTagInput = 'saveTagInput' + i;
                        }
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    this.loading = false;
                });
        },
        selectTag(id, valueText) {
            let alreadyHas = false;
            for (let i = 0; i < this.dictAddForm.tags.length; i++) {
                let item = this.dictAddForm.tags[i];
                if (item.id == id) {
                    alreadyHas = true;
                    break;
                }
            }
            if (alreadyHas) {
                this.$message.warning('标签已存在。');
                return false;
            }
            let json = {
                id: id,
                valueText: valueText,
            };
            this.dictAddForm.tags.push(json);
        },
        closeTag(id) {
            this.$http
                .delete('/goods/label/deleteValue?id=' + id)
                .then((response) => {
                    if (response.data.code == 200) {
                        this.getTagsData();
                    }
                });
        },
        deleteTag(index) {
            this.dictAddForm.tags.splice(index, 1);
        },
        tagsInput(index, saveTagInput) {
            this.tagsData[index].inputVisible = true;
            this.$forceUpdate();
            // this.$nextTick(() => {
            //     this.$refs.saveTagInput.$refs.input.focus();
            // });
        },
        refreshInput() {
            this.$forceUpdate();
        },

        formatterNumberInput(val, i) {
            if ((val ?? '') === '') {
                this.$nextTick(function () {
                    this.dictAddForm.goodsItems[i]['price'] = 0;
                });
            }
        },

        tagsInputConfirm(id, value) {
            this.tagsloading = true;
            let json = {
                goodsLabelId: id,
                valueText: value,
            };
            this.$http
                .post('/goods/label/addValue', json)
                .then((response) => {
                    if (response.data.code == 200) {
                        this.getTagsData();
                    }
                })
                .finally(() => {
                    this.tagsloading = false;
                });
        },
        tagIdsData() {
            this.dialogTagIds = false;
        },
        chooseMultiple() {
            this.dialogAddGoodsType = true;
            this.hasrow = 0;
            for (let k = 0; k < this.goodsTypeAddForm.length; k++) {
                this.goodsTypeAddForm[k].tags = [];
            }
            for (let i = 0; i < this.dictMultipleAttrForm.length; i++) {
                let item = this.dictMultipleAttrForm[i];
                for (let k = 0; k < this.goodsTypeAddForm.length; k++) {
                    if (
                        this.goodsTypeAddForm[k].title == item.title &&
                        item.tags.length > 0
                    ) {
                        this.goodsTypeAddForm[k].tags = JSON.parse(
                            JSON.stringify(item.tags)
                        );
                        this.hasrow += 1;
                    }
                }
            }
        },
        getGoodsType() {
            this.dictMultipleAttrForm = [];
            this.hasrow = 0;
            for (let i = 0; i < this.goodsTypeAddForm.length; i++) {
                let item = this.goodsTypeAddForm[i];
                if (item.tags.length > 0) {
                    this.dictMultipleAttrForm.push(
                        JSON.parse(JSON.stringify(item))
                    );
                    this.hasrow += 1;
                }
            }
            if (this.dictMultipleAttrForm.length < 1) {
                this.$message.warning('至少选择一种规则。');
                return false;
            }
            this.dialogAddGoodsType = false;
        },
        getNowChoose(index) {
            this.hasrow = 0;
            this.nowChoose = index;
            for (let i = 0; i < this.goodsTypeAddForm.length; i++) {
                let item = this.goodsTypeAddForm[i];
                if (item.tags.length > 0) {
                    this.hasrow += 1;
                }
            }
        },
        proxyInsert({ index, type, key, obj }) {
            if ((index ?? '') !== '') {
                this.modelIndex = index;
            }
            if (key) {
                this.modelKey = key;
            }
            if (type === 'image') {
                this.setOpenInsert(obj);
                return;
            }
            this.openVideoUploader(obj);
        },
        setOpenInsert(obj) {
            this.insertType = obj;
            this.insertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.insertImage = result;
        },
        getImage(obj, image) {
            if (obj == 'dictAddForm.goodsImages') {
                this.dictAddForm.goodsImages.push(...image);
            }
            if (obj == 'tinymce') {
                this.dictAddForm.goodsDesc += `<img src="${
                    image[image.length - 1]
                }"/>`;
            }

            if (obj == 'modelImgList') {
                this.modelImgList.push(...image);
            }

            //模特
            if (~obj.indexOf('modelList')) {
                let elment = this.modelList[this.modelIndex];
                if (this.modelKey === 'portrait') {
                    elment[this.modelKey] = image?.[0];
                    return;
                }
                if (!elment[this.modelKey]) {
                    elment[this.modelKey] = [];
                }
                elment[this.modelKey].push(...image);
            }

            if (~obj.indexOf('influencerList')) {
                let elment = this.influencerList[this.modelIndex];
                if (this.modelKey === 'portrait') {
                    elment[this.modelKey] = image?.[0];
                    return;
                }
                if (!elment[this.modelKey]) {
                    elment[this.modelKey] = [];
                }
                elment[this.modelKey].push(...image);
            }

            // 新增商品评论
            if (obj == 'goodsCommentAddForm.images') {
                if (!this.goodsCommentAddForm.images)
                    this.goodsCommentAddForm.images = [];
                this.goodsCommentAddForm.images.push(...image);
            }

            // if (obj == 'goodsSizeInfo.modelPhoto')
            //     this.goodsSizeInfo.modelPhoto = image[image.length - 1];
            // 模特照片
            if (obj == 'goodsSizeInfo.measureImage')
                this.goodsSizeInfo.measureImage = image[image.length - 1];
            // 量法图片
            else if (obj == 'goodsSizeInfo.measureImageMobile')
                this.goodsSizeInfo.measureImageMobile = image[image.length - 1];
        },
        /* 插入视频方法 */
        openVideoUploader(obj) {
            this.insertVideoType = obj;
            this.dialogInsertVideo = true;
            this.$refs.uploadVideo.openInsert();
        },
        getOpenVideo(result) {
            this.dialogInsertVideo = result;
        },
        getVideo(obj, video) {
            let elment;
            switch (obj) {
                case 'dictAddForm':
                    this.dictAddForm.goodsImages.push(...video);
                    break;
                case 'modelList':
                    elment = this.modelList[this.modelIndex];
                    if (!elment[this.modelKey]) {
                        elment[this.modelKey] = [];
                    }
                    elment[this.modelKey].push(...video);
                    break;
                case 'influencerList':
                    elment = this.influencerList[this.modelIndex];
                    if (!elment[this.modelKey]) {
                        elment[this.modelKey] = [];
                    }
                    elment[this.modelKey].push(...video);
                    break;
                case 'modelVideoList':
                    this.modelVideoList.push(...video);
                    break;
                case 'tinymce':
                    // if ( !this.editGoodsCommentForm.videos ) this.editGoodsCommentForm.videos = [];
                    // this.editGoodsCommentForm.videos.push( ...video );
                    this.dictAddForm.goodsDesc += `<video width="100%" controls autoplay loop><source src="${
                        video[video.length - 1]
                    }"/></video>`;
                    break;
                default:
                    break;
            }
        },
        /* end */
        doCombination(list) {
            var ret = [];
            return (function collect(depth, cache) {
                var tag = list[depth++];
                for (var i = 0; i < tag.length; ++i) {
                    var arr = cache.concat([tag[i]]);
                    if (depth < list.length) {
                        collect(depth, arr);
                    } else {
                        ret.push(arr);
                    }
                }
                return ret;
            })(0, []);
        },
        deleteImage(index) {
            this.dictAddForm.goodsImages.splice(index, 1);
        },
        setup(editor) {
            const that = this;
            editor.ui.registry.addButton('imageUpload', {
                tooltip: '插入图片',
                icon: 'image',
                onAction: () => {
                    that.setOpenInsert('tinymce');
                },
            });
            editor.ui.registry.addButton('videoUpload', {
                tooltip: '插入视频',
                icon: 'embed',
                onAction: () => {
                    that.openVideoUploader('tinymce');
                },
            });
        },
        async getTypeData() {
            this.goodsTypeAddForm = [];
            return await this.$http
                .get('/goods/type/getList')
                .then(async (response) => {
                    if (response.data.code === 200) {
                        this.typeData = response.data.data;
                        let data = JSON.parse(
                            JSON.stringify(response.data.data)
                        );
                        for (let i = 0; i < data.length; i++) {
                            let json = {
                                title: data[i].typeName,
                                tags: [],
                                multipleInputVisible: false,
                                multipleInputValue: '',
                            };
                            this.goodsTypeAddForm.push(json);
                        }
                        return true;
                    } else {
                        return false;
                    }
                })
                .finally(() => {});
        },
        querySearch() {
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        queryData() {
            this.loading = true;
			this.queryForm.categoryIds = this.categoryIdArr.toString()
            this.$http
                .get('/goods/getPage', { params: this.queryForm })
                .then((response) => {
                    if (response.data.code === 200) {
                        this.tableData = response.data.data.map((v) => {
                            v.published = v.published.toString();
                            return v;
                        });

                        this.total = response.data.count;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openDrawer() {
            this.multipleDrawer = true;
        },
        addMultiple() {
            if (this.dictMultipleAttrForm.length < 3) {
                let json = {
                    title: null,
                    tags: [],
                    multipleInputVisible: false,
                    multipleInputValue: '',
                };
                this.dictMultipleAttrForm.push(json);
            } else {
                this.$message.warning('已达到最多属性限制');
            }
        },
        deleteThisMultiple(index) {
            if (this.dictMultipleAttrForm.length > 1) {
                this.dictMultipleAttrForm.splice(index, 1);
            } else {
                this.$message.warning('至少要有一个属性');
            }
        },
        multipleHandleClose(tag, index) {
            this.dictMultipleAttrForm[index].tags.splice(
                this.dictMultipleAttrForm[index].tags.indexOf(tag),
                1
            );
        },
        multipleShowInput(index) {
            this.dictMultipleAttrForm[index].multipleInputVisible = true;
            // this.$nextTick(() => {
            //   this.$refs.saveMultipleTagInput.$refs.input.focus();
            // });
        },
        multipleHandleInputConfirm(index) {
            this.tempItems = [];
            let inputValue =
                this.dictMultipleAttrForm[index].multipleInputValue;
            if (inputValue) {
                let hasValue = false;
                for (
                    let i = 0;
                    i < this.dictMultipleAttrForm[index].tags.length;
                    i++
                ) {
                    if (
                        inputValue == this.dictMultipleAttrForm[index].tags[i]
                    ) {
                        hasValue = true;
                    }
                }
                if (hasValue == false) {
                    this.dictMultipleAttrForm[index].tags.push(inputValue);
                }
            }
            this.dictMultipleAttrForm[index].multipleInputVisible = false;
            this.dictMultipleAttrForm[index].multipleInputValue = '';
        },
        addMultipleData(isNew) {
            if (!isNew) {
                this.multipleData = [];
                this.dictAddForm.options = [];
                this.dictAddForm.goodsItems = [];
                // this.tempOptionArr = [];

                let len = this.dictMultipleAttrForm.length;
                for (let i = 0; i < len; i++) {
                    let item = this.dictMultipleAttrForm[i];
                    let json = {
                        label: item.title,
                        key: item.title,
                        type: 'static',
                        width: 80,
                    };
                    this.multipleData.push(json);
                    // this.tempOptionArr.push(item.tags);

                    this.dictAddForm.options[i] = {
                        name: item.title,
                        values: [],
                    };
                    for (let j = 0; j < item.tags.length; j++) {
                        let tag = item.tags[j];
                        this.dictAddForm.options[i].values.push(tag);
                    }
                }

                let json = {
                    label: '子商品图片',
                    key: 'image',
                    type: 'image',
                    width: 200,
                };
                this.multipleData.push(json);

                this.multipleData = this.multipleData.concat(
                    JSON.parse(JSON.stringify(this.defaultMultipleData))
                );

                for (let i = 0; i < this.tempItems.length; i++) {
                    let data = this.tempItems[i];
                    let json = {
                        barcode: data.barcode ? data.barcode : '',
                        code: data.code,
                        compareAtPrice: data.compareAtPrice,
                        image: data.image,
                        inventoryQuantity: data.inventoryQuantity,
                        price: data.price,
                        priceUnit: data.priceUnit,
                        remark: data.remark ? data.remark : '',
                        sku: data.sku ? data.sku : '',
                        weighUnit: data.weighUnit,
                        weight: data.weight,
                        goodsOption: {},
                        goodsTypeValueIds: [],
						amazonUrl: data.amazonUrl ? data.amazonUrl : ''
                    };

                    for (let m = 0; m < this.multipleData.length; m++) {
                        let key = this.multipleData[m].key;
                        let item = data.itemTypes;
                        for (let k = 0; k < item.length; k++) {
                            if (item[k].typeName == key) {
                                json[key] = item[k].typeValue['valueText'];
                                json.goodsOption[key] =
                                    item[k].typeValue['valueText'];
                            }
                        }
                    }

                    for (let i in json.goodsOption) {
                        for (let a = 0; a < this.typeData.length; a++) {
                            let itemA = this.typeData[a];
                            if (i == itemA.typeName) {
                                for (
                                    let b = 0;
                                    b < itemA.typeValues.length;
                                    b++
                                ) {
                                    let itemB = itemA.typeValues[b];
                                    if (
                                        json.goodsOption[i] == itemB.valueText
                                    ) {
                                        json.goodsTypeValueIds.push(itemB.id);
                                    }
                                }
                            }
                        }
                    }

                    this.dictAddForm.goodsItems.push(json);
                }
            } else {
                this.multipleData = [];
                this.dictAddForm.options = [];
                this.dictAddForm.goodsItems = [];
                this.tempOptionArr = [];

                for (let n = 0; n < this.goodsTypeAddForm.length; n++) {
                    this.goodsTypeAddForm[n].tags = [];
                }

                let len = this.dictMultipleAttrForm.length;
                for (let i = 0; i < len; i++) {
                    let item = this.dictMultipleAttrForm[i];
                    let json = {
                        label: item.title,
                        key: item.title,
                        type: 'static',
                        width: 80,
                    };
                    this.multipleData.push(json);
                    this.tempOptionArr.push(item.tags);

                    this.dictAddForm.options[i] = {
                        name: item.title,
                        values: [],
                    };
                    for (let j = 0; j < item.tags.length; j++) {
                        let tag = item.tags[j];
                        this.dictAddForm.options[i].values.push(tag);
                    }
                }

                let optionResult = this.doCombination(this.tempOptionArr);

                for (let i = 0; i < optionResult.length; i++) {
                    //重新选择商品款式后忽略原有数据
                    // let json = {};
                    // if (this.tempItems && this.tempItems.length > 0) {
                    //   let data = this.tempItems[i];
                    //   if(data){
                    //     json = {
                    //       barcode: data.barcode ? data.barcode : '',
                    //       code: data.code,
                    //       compareAtPrice: data.compareAtPrice,
                    //       image: data.image,
                    //       inventoryQuantity: data.inventoryQuantity,
                    //       price: data.price,
                    //       priceUnit: data.priceUnit,
                    //       remark: data.remark ? data.remark : '',
                    //       sku: data.sku ? data.sku : '',
                    //       weighUnit: data.weighUnit,
                    //       weight: data.weight,
                    //       goodsOption: {},
                    //       goodsTypeValueIds: [],
                    //     };
                    //   } else {
                    //     json = {
                    //       barcode: "",
                    //       code: "",
                    //       compareAtPrice: "",
                    //       image: "",
                    //       inventoryQuantity: "",
                    //       price: "",
                    //       priceUnit: "USD",
                    //       remark: "",
                    //       sku: "",
                    //       weighUnit: "kg",
                    //       weight: "",
                    //       goodsOption: {},
                    //       goodsTypeValueIds: [],
                    //     };
                    //   }
                    // } else {
                    //   json = {
                    //     barcode: "",
                    //     code: "",
                    //     compareAtPrice: "",
                    //     image: "",
                    //     inventoryQuantity: "",
                    //     price: "",
                    //     priceUnit: "USD",
                    //     remark: "",
                    //     sku: "",
                    //     weighUnit: "kg",
                    //     weight: "",
                    //     goodsOption: {},
                    //     goodsTypeValueIds: [],
                    //   };
                    // }

                    let json = {
                        barcode: '',
                        code: '',
                        compareAtPrice: '',
                        image: '',
                        inventoryQuantity: '',
                        price: '',
                        priceUnit: 'USD',
                        remark: '',
                        sku: '',
                        weighUnit: 'kg',
                        weight: '',
                        goodsOption: {},
                        goodsTypeValueIds: [],
                    };

                    for (let m = 0; m < this.multipleData.length; m++) {
                        let key = this.multipleData[m].key;
                        let item = optionResult[i];
                        let val = item[m];
                        json[key] = val;
                        json.goodsOption[key] = val;
                    }

                    for (let i in json.goodsOption) {
                        for (let a = 0; a < this.typeData.length; a++) {
                            let itemA = this.typeData[a];
                            if (i == itemA.typeName) {
                                for (
                                    let b = 0;
                                    b < itemA.typeValues.length;
                                    b++
                                ) {
                                    let itemB = itemA.typeValues[b];
                                    if (
                                        json.goodsOption[i] == itemB.valueText
                                    ) {
                                        json.goodsTypeValueIds.push(itemB.id);
                                    }
                                }
                            }
                        }
                    }

                    for (let n = 0; n < this.goodsTypeAddForm.length; n++) {
                        let itemC = this.goodsTypeAddForm[n];
                        for (let o in json.goodsOption) {
                            if (itemC.title == o) {
                                itemC.tags.push(json.goodsOption[o]);
                                itemC.tags = [...new Set(itemC.tags)];
                            }
                        }
                    }

                    this.dictAddForm.goodsItems.push(json);
                }

                let json = {
                    label: '子商品图片',
                    key: 'image',
                    type: 'image',
                    width: 200,
                };
                this.multipleData.push(json);

                this.multipleData = this.multipleData.concat(
                    JSON.parse(JSON.stringify(this.defaultMultipleData))
                );
            }

            this.multipleDrawer = false;
        },
        changeDesign() {
            this.dictAddForm.options = [];
            this.dictAddForm.goodsItems = [
                {
                    barcode: '',
                    code: '',
                    compareAtPrice: '',
                    image: '',
                    inventoryQuantity: '',
                    price: '',
                    priceUnit: 'USD',
                    remark: '',
                    sku: '',
                    weighUnit: 'kg',
                    weight: '',
                },
            ];
            this.multipleData = [];
            this.dictAddForm.goodsTypeValueIds = [];
        },
        baseSwitch(url, params) {
            this.$http
                .post(url, params)
                .then((r) => {
                    if (r.data.code === 200) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1000,
                            // onClose: this.queryData,
                        });
                    }
                })
                .catch((err) => {
                    this.btnListLoading = '';
                });
        },

        changedGmcSwitch(item, val) {
            this.baseSwitch('/goods/setGmc', {
                id: item.id,
                isSynGmc: val,
            });
        },

        changedDiySwitch(item, val) {
            this.baseSwitch('/goods/setDiy', {
                id: item.id,
                isDiy: val,
            });
        },
        changePublished(item, val) {
            // this.baseSwitch('/goods/published', {
            //     id: item.id,
            //     published: val,
            // });

            this.$confirm(`确认要${val == 0 ? '下架' : '上架'}吗？`, {
                type: 'warning',
            })
                .then(() => {
                    this.$http
                        .post('/goods/published', {
                            id: item.id,
                            published: val,
                        })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '设置成功',
                                    duration: 1000,
                                });
                            }
                        })
                        .catch((err) => {
                            item.published = val == 0 ? '1' : '0';
                        })
                        .finally(() => {
                            this.queryData();
                        });
                })
                .catch(() => (item.published = val == 0 ? '1' : '0'));
        },
        async openAdd() {
            let res = await this.getTypeData();
            this.isEdit = 0;
            this.addActive = 0;
            this.tempItems = [];
            this.modelList = [];
            this.influencerList = [];
            this.dictMultipleAttrForm = [
                {
                    title: null,
                    tags: [],
                    multipleInputVisible: false,
                    multipleInputValue: '',
                },
            ];

            this.splitLink = {
                host: '',
                href: '',
                html: '',
            };

            this.dictAddForm = {
                goodsTitle: '',
                goodsBrief: null,
                goodsDesc: '',
                goodsTags: [],
                spu: null,
                fakeSales: 0,
                vendor: null,
                vendorUrl: null,
                published: '0',
                requiresShipping: '1',
                // taxable: "0",
                fitPerson: 1,
                single: 1,
                goodsImages: [],
                options: [],
                isZeroInventory: 0,
                goodsItems: [
                    {
                        barcode: '',
                        code: '',
                        compareAtPrice: '',
                        image: '',
                        inventoryQuantity: '',
                        price: '',
                        priceUnit: 'USD',
                        remark: '',
                        sku: '',
                        weighUnit: 'kg',
                        weight: '',
                    },
                ],
                goodsTypeValueIds: [],
                tags: [],
                tagIds: [],
                goodsRemark: null,
            };

            // 尺码信息
            this.goodsSizeInfo = {
                modelName: null,
                modelSize: null,
                modelHeight: undefined,
                modelBust: undefined,
                modelWaist: undefined,
                modelHip: undefined,
                modelPhoto: null,
                measureImage: null,
                measureImageMobile: null,
            };
            // 尺码信息 - 尺码表
            this.goodsSizeInfo_tableData = [
                {
                    size: 'SIZE',
                    fn1: 'Height',
                    fn2: 'Bust',
                    fn3: 'Waist',
                    fn4: 'Hip',
                },
            ];

            // 尺码信息 - 表头
            this.goodsSizeInfo_fn = 5;
            this.goodsSizeInfo_tableHeader = ['fn1', 'fn2', 'fn3', 'fn4'];

            this.addLoading = false;
            this.dialogAdd = true;
        },

        /* 尺码信息 - 尺码表 - 增加一列 */
        goodsSizeInfo_addColumn() {
            let colName = 'fn' + this.goodsSizeInfo_fn++,
                data = _.cloneDeep(this.goodsSizeInfo_tableData);

            data.forEach((row) => (row[colName] = undefined));
            this.goodsSizeInfo_tableData = data;
            this.goodsSizeInfo_tableHeader.push(colName);
        },
        /* 尺码信息 - 尺码表 - 增加一列 end */

        /* 尺码信息 - 尺码表 - 删除列 */
        goodsSizeInfo_deleteColumn(index) {
            this.goodsSizeInfo_tableHeader.splice(index, 1);
        },
        /* 尺码信息 - 尺码表 - 删除列 end */

        /* 尺码信息 - 尺码表 - 收集提交数据 */
        goodsSizeInfo_getData() {
            let cmTableData = [],
                inTableData = [];

            this.goodsSizeInfo_tableData.forEach((row, rowIndex) => {
                let cmRow = [row.size],
                    inRow = [row.size];

                this.goodsSizeInfo_tableHeader.forEach((col) => {
                    let val = row[col],
                        val_inch = row[col];

                    if (!val) val = '';

                    if (!val_inch) val_inch = '';
                    if (rowIndex > 0) {
                        if (val_inch)
                            val_inch =
                                Math.round(parseFloat(val_inch) * 39.37) / 100;
                    }

                    cmRow.push(val);
                    inRow.push(val_inch);
                });

                cmTableData.push(cmRow);
                inTableData.push(inRow);
            });

            return { cmTableData, inTableData };
        },
        /* 尺码信息 - 尺码表 - 收集提交数据 end */

        handleClose(tag) {
            this.dictAddForm.goodsTags.splice(
                this.dictAddForm.goodsTags.indexOf(tag),
                1
            );
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick(() => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.dictAddForm.goodsTags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        addData() {
            if (
                this.dictAddForm.goodsImages &&
                this.dictAddForm.goodsImages.length < 1
            ) {
                this.$message.warning('商品图片至少上传1张。');
                return false;
            }
            this.dictAddForm.goodsTypeValueIds = [];
            for (let i = 0; i < this.goodsTypeAddForm.length; i++) {
                let itemA = this.goodsTypeAddForm[i].tags;
                for (let k = 0; k < itemA.length; k++) {
                    let itemB = itemA[k];
                    for (let m = 0; m < this.typeData.length; m++) {
                        for (
                            let s = 0;
                            s < this.typeData[i].typeValues.length;
                            s++
                        ) {
                            if (
                                itemB ==
                                this.typeData[i].typeValues[s].valueText
                            ) {
                                this.dictAddForm.goodsTypeValueIds.push(
                                    this.typeData[i].typeValues[s].id
                                );
                            }
                        }
                    }
                }
            }
            this.dictAddForm.goodsTypeValueIds = [
                ...new Set(this.dictAddForm.goodsTypeValueIds),
            ];

            this.dictAddForm.tagIds = [];
            for (let i = 0; i < this.dictAddForm.tags.length; i++) {
                let item = this.dictAddForm.tags[i].id;
                this.dictAddForm.tagIds.push(item);
            }

            this.dictAddForm.seoUrl =
                this.splitLink.host + this.splitLink.href + this.splitLink.html;

            let url = '';
            if (this.isEdit == 0) {
                url = '/goods/add';
            } else {
                url = '/goods/edit';
            }

            if (!this.modelPattern()) return;

            this.dictAddForm.modelList = this.modelList;
            this.dictAddForm.influencerList = this.influencerList;

            // 获取尺码信息
            if (this.goodsSizeData_sizes.length) {
                let goodsSizeData = this.goodsSizeInfo_getData();
                this.goodsSizeInfo.cmTableData = goodsSizeData.cmTableData;
                this.goodsSizeInfo.inTableData = goodsSizeData.inTableData;
                this.dictAddForm.goodsSizeInfo = this.goodsSizeInfo;
            }

            this.$refs['dictAddForm'].validate((valid) => {
                if (valid) {
                    this.addLoading = true;
                    this.$http
                        .post(url, this.dictAddForm)
                        .then((response) => {
                            if (response.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message.success('保存成功');
                                this.queryData();
                            }
                        })
                        .finally(() => {
                            this.addLoading = false;
                        });
                }
            });
        },
        modelPattern() {
            let self = this,
                strategy_pattern = {
                    isSizeAttempt() {
                        return self.modelList.every((cur) => {
                            return cur['modelSizeAttempt'];
                        });
                    },
                    isModelSize() {
                        return self.modelList.every((cur) => {
                            return cur.modelSize;
                        });
                    },
                    isPortrait() {
                        return self.modelList.every((cur) => {
                            return cur['portrait'];
                        });
                    },
                    isModelPhoto() {
                        return self.modelList.every((cur) => {
                            return cur.modelPhoto && cur.modelPhoto.length;
                        });
                    },
                    isInsName() {
                        return self.influencerList.every((cur) => {
                            return cur.name;
                        });
                    },
                    isInsPortrait() {
                        return self.influencerList.every((cur) => {
                            return cur['portrait'];
                        });
                    },
                    isInsPhoto() {
                        return self.influencerList.every((cur) => {
                            return cur.photoList && cur.photoList.length;
                        });
                    },
                    isInsVideo() {
                        return self.influencerList.every((cur) => {
                            return cur.videoList && cur.videoList.length;
                        });
                    },
                };

            if (!strategy_pattern.isPortrait()) {
                this.$message.warning('请选择模特头像');
                return false;
            }
            if (!strategy_pattern.isSizeAttempt()) {
                this.$message.warning('请选择模特试穿尺码');
                return false;
            }
            if (!strategy_pattern.isModelSize()) {
                this.$message.warning('请填写模特常用尺码');
                return false;
            }
            if (!strategy_pattern.isModelPhoto()) {
                this.$message.warning('请选择模特试穿图');
                return false;
            }
            if (!strategy_pattern.isInsName()) {
                this.$message.warning('请填写网红昵称');
                return false;
            }
            if (!strategy_pattern.isInsPortrait()) {
                this.$message.warning('请选择网红头像');
                return false;
            }
            if (!strategy_pattern.isInsPhoto()) {
                this.$message.warning('请选择网红图片');
                return false;
            }
            return true;
        },
        openView(url) {
            window.open(url);
        },
        splitMethod(url) {
            let arr = url.split('/');
            let host = arr[0] + '//' + arr[2] + '/' + arr[3] + '/';
            let temphtml = arr[4].split('-');
            let html = '-' + temphtml[temphtml.length - 1];
            let temphref = arr[4].split(html);
            let href = temphref[0];

            this.splitLink = {
                host: host,
                href: href,
                html: html,
            };
        },
        async openEdit(id) {
            let res = await this.getTypeData();
            this.isEdit = 1;
            this.tempItems = [];
            this.addActive = 0;
            this.modelList = [];
            this.influencerList = [];
            this.dictMultipleAttrForm = [
                {
                    title: null,
                    tags: [],
                    multipleInputVisible: false,
                    multipleInputValue: '',
                },
            ];
            this.loading = true;

            // 尺码信息
            // this.goodsSizeInfo = {
            //     modelName: null,
            //     modelSize: null,
            //     modelHeight: undefined,
            //     modelBust: undefined,
            //     modelWaist: undefined,
            //     modelHip: undefined,
            //     modelPhoto: null,
            //     measureImage: null,
            //     measureImageMobile: null,
            // };

            // 尺码信息 - 尺码表
            this.goodsSizeInfo_tableData = [
                {
                    size: 'SIZE',
                    fn1: 'Height',
                    fn2: 'Bust',
                    fn3: 'Waist',
                    fn4: 'Hip',
                },
            ];

            // 尺码信息 - 表头
            this.goodsSizeInfo_fn = 5;
            this.goodsSizeInfo_tableHeader = ['fn1', 'fn2', 'fn3', 'fn4'];

            this.$http
                .get('/goods/getDetail?id=' + id)
                .then((response) => {
                    if (response.data.code === 200) {
                        let { kolInfo, modelInfo } = response.data.data;

                        if (modelInfo) {
                            this.modelList = this.setProperty(
                                modelInfo,
                                'model'
                            );
                        }
                        if (kolInfo) {
                            this.influencerList = this.setProperty(
                                kolInfo,
                                'ins'
                            );
                        }

                        this.dictAddForm = response.data.data;

                        this.splitMethod(this.dictAddForm.seoUrl);

                        if (this.dictAddForm.seoKeywords == null)
                            this.dictAddForm.seoKeywords = [];

                        // 收集运营标签
                        let tags = [];
                        this.dictAddForm.tags.forEach((group) => {
                            group.shopGoodsLabelValues.forEach((tag) => {
                                tags.push({
                                    id: tag.id,
                                    valueText: tag.valueText,
                                });
                            });
                        });
                        this.dictAddForm.tags = tags;

                        this.dictAddForm.goodsImages = this.dictAddForm
                            .goodsImages
                            ? this.dictAddForm.goodsImages
                            : [];
                        this.dictAddForm.published = String(
                            this.dictAddForm.published
                        );
                        this.dictAddForm.requiresShipping = String(
                            this.dictAddForm.requiresShipping
                        );
                        // this.dictAddForm.taxable = String(this.dictAddForm.taxable);
                        this.dictAddForm.id = id;
                        this.dictAddForm.options = [];

                        if (this.dictAddForm.single == 0) {
                            if (this.dictAddForm.types) {
                                for (
                                    let i = 0;
                                    i < this.dictAddForm.types.length;
                                    i++
                                ) {
                                    let itemA = this.dictAddForm.types[i];
                                    let json = {
                                        name: itemA.typeName,
                                        values: [],
                                    };
                                    for (
                                        let k = 0;
                                        k < itemA.typeValues.length;
                                        k++
                                    ) {
                                        let itemB = itemA.typeValues[k];
                                        json.values.push(itemB.valueText);

                                        for (
                                            let m = 0;
                                            m < this.goodsTypeAddForm.length;
                                            m++
                                        ) {
                                            let itemC =
                                                this.goodsTypeAddForm[m];
                                            if (itemC.title == itemA.typeName) {
                                                itemC.tags.push(
                                                    itemB.valueText
                                                );
                                            }
                                        }
                                    }
                                    this.dictAddForm.options.push(json);
                                }
                            }
                        }
                        if (this.dictAddForm.options && this.dictAddForm.options.length > 0) {
                            this.tempItems = JSON.parse(
                                JSON.stringify(response.data.data.goodsItems)
                            );
                            this.dictMultipleAttrForm = [];
                            for (
                                let i = 0;
                                i < this.dictAddForm.options.length;
                                i++
                            ) {
                                let item = this.dictAddForm.options[i];
                                let json = {
                                    title: item.name,
                                    tags: item.values,
                                    multipleInputVisible: false,
                                    multipleInputValue: '',
                                };
                                this.dictMultipleAttrForm.push(json);
                            }
                            this.addMultipleData(false);
                        }

                        // 尺码信息
                        if (this.dictAddForm.goodsSizeInfo) {
                            this.goodsSizeInfo.measureImage =
                                this.dictAddForm.goodsSizeInfo.measureImage; // 设置量法图片
                            this.goodsSizeInfo.measureImageMobile =
                                this.dictAddForm.goodsSizeInfo.measureImageMobile;
                        }
                        if (
                            this.dictAddForm.goodsSizeInfo &&
                            this.dictAddForm.goodsSizeInfo.cmTableData
                        ) {
                            this.goodsSizeInfo = this.dictAddForm.goodsSizeInfo;

                            // 尺码信息 - 尺码表
                            let goodsSizeTableData =
                                    this.goodsSizeInfo.cmTableData,
                                firstRow = goodsSizeTableData[0];

                            // 尺码信息 - 表头
                            this.goodsSizeInfo_fn = firstRow.length;
                            this.goodsSizeInfo_tableHeader = [];
                            for (let i = 1; i < this.goodsSizeInfo_fn; i++)
                                this.goodsSizeInfo_tableHeader.push('fn' + i);

                            // 尺码信息 - 数据
                            let goodsSizeTempData = [];
                            goodsSizeTableData.forEach((item, rowIndex) => {
                                let row = { size: item[0] };
                                this.goodsSizeInfo_tableHeader.forEach(
                                    (col, colIndex) => {
                                        let val = item[colIndex + 1];

                                        if (rowIndex > 0 && val != '')
                                            val = parseInt(val);
                                        row[col] = val;
                                    }
                                );
                                goodsSizeTempData.push(row);
                            });

                            let that = this;
                            setTimeout(function () {
                                that.goodsSizeInfo_tableData =
                                    _.cloneDeep(goodsSizeTempData);
                            }, 3000);
                        }

                        this.addLoading = false;
                        this.dialogAdd = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getObj(event) {
            this.nowSelectImage = event;
            this.dialogSelectImage = true;
        },
        selectImageData(img) {
            this.nowSelectImage.image = img;
            this.dialogSelectImage = false;
        },
        delmeasure(type) {
            this.goodsSizeInfo[type] = null;
            this.$forceUpdate();
        },
        avatarUpload(params) {
            if (params) {
                let file = params.file;

                const isJPG =
                    file.type === 'image/jpeg' ||
                    file.type === 'image/png' ||
                    file.type === 'image/gif';
                const isLt2M = file.size / 1024 / 1024 < 1;
                if (!isJPG) {
                    this.$message.error('上传图片只能是jpg、png、gif格式');
                    return;
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过1MB');
                    return;
                }
                let form = new FormData();
                form.append('fileType', '1');
                form.append('files', file);
                this.$http
                    .post('/common/file/upload', form)
                    .then((response) => {
                        if (response.data.code === 200) {
                            let data = response.data.data;
                            if (data) {
                                this.nowSelectImage.image = data[0].url;
                            }
                        }
                    });
            }
        },

        // 新增评论 - 获取国家数据
        queryCountry() {
            this.$http
                .get('/common/country/getList')
                .then((r) => {
                    if (r.data.code === 200) {
                        this.country = r.data.data;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                });
        },

        // 新增评论 - 删除已选择的图片
        deleteSeleckedImage(images, item) {
            let index = images.indexOf(item);

            if (index > -1) images.splice(index, 1);
        },

        // 新增评论
        initAddGoodsComment(goodsId) {
            this.goodsCommentAddForm = {
                goodsId,
                grade: null,
                likeNum: null,
                commentTime: null,
                countryId: null,
                content: null,
                commentator: null,
                images: null,
            };

            this.dialogAddGoodsComment = true;
        },
        addGoodsComment() {
            this.$refs.goodsCommentAddForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;

                    this.$http
                        .post('/goods/addComment', this.goodsCommentAddForm)
                        .then((r) => {
                            this.btnLoading = false;

                            if (r.data.code === 200) {
                                this.dialogAddGoodsComment = false;
                                this.queryData();
                                this.$message.success('新增成功');
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = false;
                        });
                }
            });
        },
        getFitPerson(status) {
            switch (status) {
                case 1:
                    return '两者皆可';
                case 2:
                    return '黑人';
                case 3:
                    return '白人';
            }
        },
        deleteMultipleData(code) {
            let index = null;
            for (let i = 0; i < this.dictAddForm.goodsItems.length; i++) {
                if (this.dictAddForm.goodsItems[i].code == code) {
                    index = i;
                    break;
                }
            }
            this.dictAddForm.goodsItems.splice(index, 1);
        },
        setProperty(data, type) {
            let obj = {
                model: {
                    modelName: null,
                    portrait: null,
                    modelSizeAttempt: null,
                    modelSize: null,
                    modelHeight: null,
                    modelBust: null,
                    modelWaist: null,
                    modelHip: null,
                    modelPhoto: [],
                    modelVideo: [],
                },
                ins: {
                    name: null,
                    portrait: null,
                    photoList: [],
                    videoList: [],
                },
            };
            if (!obj[type]) return;
            let list = data.map((cur) => {
                return Object.assign(
                    JSON.parse(JSON.stringify(obj[type])),
                    cur
                );
            });
            return list;
        },
        addModelInfo() {
            let MODEL_ITEM = {
                portrait: null,
                modelSizeAttempt: null,
                modelSize: null,
                modelHeight: null,
                modelBust: null,
                modelWaist: null,
                modelHip: null,
                modelPhoto: [],
                modelVideo: [],
            };
            this.modelList.push(MODEL_ITEM);
        },
        addInsModelInfo() {
            let INS_ITEM = {
                name: null,
                portrait: null,
                photoList: [],
                videoList: [],
            };
            this.influencerList.push(INS_ITEM);
        },
        deteleModelTable(index, propertyTable) {
            this.$nextTick(() => {
                this[propertyTable].splice(index, 1);
            });
        },
        viewImage(modelPhoto, index, key, data) {
            this.dialogModelImage = true;
            this.modelIndex = index;
            this.modelKey = key;
            this.modelImgList = JSON.parse(JSON.stringify(modelPhoto));
            this.modelData = data;
        },
        viewVideo(modelVideo, index, key, data) {
            this.dialogModelVideo = true;
            this.modelIndex = index;
            this.modelKey = key;
            this.modelVideoList = [];
            this.modelData = data;
            this.$forceUpdate();
            this.modelVideoList = JSON.parse(JSON.stringify(modelVideo));
        },
        replaceModelPraram(key, datatable) {
            if (datatable == 'modelList') {
                let data =
                    key === 'modelPhoto'
                        ? this.modelImgList
                        : this.modelVideoList;

                this.modelList[this.modelIndex][key] = data;
                if (key === 'modelPhoto') {
                    this.dialogModelImage = false;
                    return;
                }
            }

            if (datatable == 'influencerList') {
                let data =
                    key === 'photoList'
                        ? this.modelImgList
                        : this.modelVideoList;

                this.influencerList[this.modelIndex][key] = data;
                if (key === 'photoList') {
                    this.dialogModelImage = false;
                    return;
                }
            }

            this.dialogModelVideo = false;
        },

        // Diy
        getDiyInfo(id) {
            let url = '/goods/diy/getShopDiyGoods';
            this.$http.get(`${url}?goodsId=${id}`).then((res) => {
                let {
                    code,
                    data: { diyIds = [] },
                } = res.data;

                if (code === 200) {
                    this._diyIds = diyIds;
                    let rows = this.diyTableData.filter((cur) => {
                        return diyIds.includes(cur.id);
                    });
                    this.toggleSelection(rows);
                }
            });
        },

        setSelectionCache() {
            this.multipleSelectionDiyCopy[this.diyForm.pageNo - 1] =
                this.multipleSelectionDiy.map((cur) => {
                    return cur.id;
                });
        },

        proxyPagerDiyList(curr) {
            this.setSelectionCache();

            this.diyForm.pageNo = curr;

            this.getDiyList((data) => {
                let _selection =
                        this.multipleSelectionDiyCopy[
                            this.diyForm.pageNo - 1
                        ] || [],
                    diyIds = _selection.length ? _selection : this._diyIds,
                    rows = data.filter((cur) => {
                        return diyIds.includes(cur.id);
                    });

                if (rows.length) {
                    this.$nextTick(() => {
                        this.toggleSelection(rows);
                    });
                }
            });
        },
        async getDiyList(cb) {
            let url = '/material/diy/getPage',
                {
                    data: { code, data, count },
                } = await this.$http.get(url, {
                    params: this.diyForm,
                });

            if (code === 200) {
                this.diyTableData = data;
                this.diyTotal = count;

                cb && cb(data);
            }
        },
        async bridgeDiySeries({ id }) {
            this.dialogDiyInfo = true;
            this._diyId = id;

            if (this.diyForm.pageNo !== 1 || this.isDiyPageFirst) {
                await this.getDiyList();
                this.isDiyPageFirst = false;
            }
            await this.getDiyInfo(id);
        },
        submitDiyAllocation() {
            this.setSelectionCache();

            let url = '/goods/diy/allocation',
                params = {
                    goodsId: this._diyId,
                    diyIds: this.multipleSelectionDiyCopy.flat(),
                };

            this.$http.post(url, params).then((res) => {
                let { code } = res.data;
                if (code === 200) {
                    this.dialogDiyInfo = false;
                    this.$message.success('成功分配DIY');
                }
            });
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.diyTable.toggleRowSelection(row);
                });

                this.multipleSelectionDiy = rows;
                this.$forceUpdate();
            } else {
                this.$refs.diyTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelectionDiy = val;
        },
        resetDialogDiy() {
            this.multipleSelectionDiy = [];
            this.multipleSelectionDiyCopy = [];
            this.$refs.diyTable.clearSelection();
        },

        //
        openDialogDiy({ id }) {
            this.$refs.goodsDiyEdit.show(id);
        },
    },
};
</script>

<style scoped>
.demo-drawer__content {
    display: flex;
    flex-direction: column;
    height: 90%;
}
.drawer /deep/ .el-form-item {
    margin-bottom: 10px;
}
.drawer /deep/ .el-form-item__label {
    padding: 0;
}
.demo-drawer__footer {
    display: flex;
    justify-content: center;
}
.bgitem {
    border: 2px solid #eef0f5;
    padding: 15px;
    margin-bottom: 20px;
}
.bgitem h2 {
    font-size: 18px;
    line-height: 36px;
    font-weight: normal;
}
.bgitem strong {
    font-size: 14px;
    color: #606266;
    font-weight: normal;
}
.bgitem .handler {
    display: flex;
    padding: 10px 0;
    justify-content: flex-end;
}
.bgitem /deep/ .el-form-item__label {
    font-size: 18px;
}
.addform {
    display: flex;
    justify-content: space-around;
}
.addleft {
    flex-basis: 60%;
}
.addright {
    flex-basis: 30%;
}
.img-uploader {
    border: 1px dashed #000;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.img-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 136px;
    height: 136px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.image {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.flex-image {
    display: flex;
    flex-wrap: wrap;
}
.insertbox {
    height: 410px;
    display: flex;
    flex-wrap: wrap;
}
.insertbox .item {
    width: 115px;
    height: 115px;
    flex-basis: 115px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
}
.insertbox .item.img-uploader {
    border: 1px dashed #000;
}
.insertbox .item img {
    max-width: 110px;
    max-height: 110px;
    border: 2px solid transparent;
}
.insertbox .item.selected {
    border: 2px solid #409eff;
}
.center {
    text-align: center;
}
.controlrow {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}
.goodsImagesRow {
    margin-top: 20px;
    display: flex;
    justify-content: flex;
    align-items: center;
    flex-wrap: wrap;
}
.selectedGoodsImages {
    width: 120px;
    height: 120px;
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
}
.selectedGoodsImages .image,
.selectedGoodsImages video {
    max-width: 100%;
    max-height: 100%;
    min-width: unset;
    min-height: unset;
}
.selectedGoodsImages video {
    background: #000;
}

.selectedGoodsImages .del {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    cursor: pointer;
}
.selectedGoodsImages .del:hover {
    color: #409eff;
}
.mulitplebox {
    padding: 15px;
    background: #f8f8f8;
    margin-bottom: 20px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 158px;
    height: 158px;
    line-height: 158px;
    text-align: center;
    border: 1px dashed currentColor;
    border-radius: 6px;
}
.avatar {
    max-width: 158px;
    max-height: 158px;
    display: block;
}

/* seo关键字 */
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
/* seo关键字 end */

/* 新增评论 - 图片 */
.goodsCommentImageBox {
    margin-right: 20px;
    margin-bottom: 20px;
    width: 120px !important;
    height: 120px !important;
}

.goodsCommentImage {
    max-width: 120px;
    max-height: 120px;
}

.goodsCommentImageBox > .mask_d5 {
    display: none;
}

.goodsCommentImageBox:hover > .mask_d5 {
    display: block;
}
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}

/* 商品图片 */
.goodsImageBox {
    margin-right: 20px;
    margin-bottom: 20px;
    width: 120px !important;
    height: 120px !important;
}

.goodsImageBox video {
    width: 120px;
    height: 120px;
    object-fit: contain;
}

.goodsImageBox > .mask_d5 {
    display: none;
}

.goodsImageBox .delimg {
    position: absolute;
    top: -12px;
    right: -12px;
    display: none;
}

.goodsImageBox:hover > .mask_d5,
.goodsImageBox:hover .delimg {
    display: block;
}

/* 商品图片 end */

::v-deep .goodsImage {
    max-width: 100%;
    max-height: 100%;
}

/* .perch-links3 {
    width: 90px;
}

.table-handler {
    width: 200px;
    display: flex;
    justify-content: center;
} */

.mod-video {
    background: #000;
    height: 120px;
}
</style>
