<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>素材中心</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-upload2" @click="initUploadForm">上传素材</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="initAddForm">新增素材</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="danger" icon="el-icon-delete" @click="deleteBatchData">批量删除</el-button>
            </el-form-item>
        </el-form>
        <!-- 数据表 -->
        <el-table border stripe :data="tableData" ref="materialTable" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000">
            <el-table-column fixed type="selection" width="40"></el-table-column>
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column label="素材图片" width="180" align="center">
                <template slot-scope="scope">
                    <el-popover placement="right" trigger="hover" v-if="isShowImage(scope.row.materialUrl)">
                        <el-image fit="contain" style="width: 300px; height: 300px" :src="scope.row.materialUrl" :preview-src-list="[scope.row.materialUrl]">
                            <div slot="error" class="el-image__error" style="width: 300px; height: 300px; font-size: 50px">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <el-image fit="contain" slot="reference" style="width: 80px; height: 80px" :src="scope.row.materialUrl">
                            <div slot="error" class="el-image__error" style="font-size: 20px">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </el-popover>
                    <div style="width: 80px; height: 80px; margin: 0 auto; display: grid; place-items: center;" @click="showVideo(scope.row.materialUrl)" v-else>
                        <video width="100%" autoplay loop>
                            <source :src="scope.row.materialUrl">
                        </video>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="materialName" label="素材名称" min-width="120"></el-table-column>
            <el-table-column label="素材url" width="500">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.materialUrl" :readonly="true">
                        <el-button slot="append" v-clipboard:copy="scope.row.materialUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</el-button>
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160" sortable></el-table-column>
            <el-table-column fixed="right" label="操作" width="100" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="deleteData(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>
        <!-- 业务弹框-新增素材 -->
        <el-dialog top="3%" title="新增素材" :visible.sync="dialogAdd" width="600px">
            <el-form :model="materialAddForm" :rules="materialRules" status-icon ref="materialAddForm" label-position="top" size="medium">
                <el-form-item label="素材类型">
                    <el-radio-group v-model="materialAddForm.materialType">
                        <el-radio :label="1">图片</el-radio>
                        <el-radio :label="2">视频</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="素材url" prop="materialUrls">
                    <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 16 }" placeholder="请输入素材url，如果为多个url则用英文逗号分开" v-model="materialAddForm.materialUrls"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                <el-button @click="dialogAdd = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 业务弹框-上传素材 -->
        <el-dialog title="上传素材" :visible.sync="dialogUpload" top="3%" width="800px" @close="handleClose">
            <section>
                选择类型：
                <el-button v-show="!uploadType" type="primary" icon="el-icon-picture" @click="changeType(1)">上传图片</el-button>
                <el-button v-show="!uploadType" type="primary" icon="el-icon-video-camera-solid" @click="changeType(2)">上传视频</el-button>
                <el-button v-show="uploadType != 0" type="primary" icon="el-icon-refresh" @click="changeType(0)">重新选择</el-button>
            </section>

            <section v-show="uploadType == 1">
                <h3>上传图片：</h3>
                <el-upload action="#" ref="upload" list-type="picture-card" multiple :limit="10" :on-exceed="handleExceed" :on-preview="handlePreview" :auto-upload="false" :http-request="upload">
                    <i slot="default" class="el-icon-plus"></i>
                    <div class="el-upload__tip" slot="tip">
                        只能上传jpg、png、gif文件，且每张图片不超过1mb
                    </div>
                </el-upload>
            </section>

            <section v-show="uploadType == 2">
                <h3>上传视频：</h3>
                <el-upload action="#" ref="uploadB" list-type="picture-card" multiple :limit="10" :on-exceed="handleExceed" :auto-upload="false" :http-request="uploadB">
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">
                        <div @click="handlePreviewB(file)">
                            <video width="100%" autoplay loop>
                                <source :src="file.url">
                            </video>
                        </div>
                    </div>
                    <div class="el-upload__tip" slot="tip">
                        只能上传主流视频格式文件，且每个视频不超过10mb
                    </div>
                </el-upload>
            </section>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="uploadLoading" @click="uploadMaterial">确定</el-button>
                <el-button @click="dialogUpload = false">取消</el-button>
            </div>
        </el-dialog>
        <el-dialog top="3%" :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
        <el-dialog top="3%" :visible.sync="dialogVisibleVideo">
            <video width="100%" controls autoplay loop>
                <source :src="dialogVideoUrl">
            </video>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'Material',
    data() {
        return {
            queryForm: {
                pageNo: 1,
                pageSize: 10,
            },
            popoverDisabled: false,
            tableData: [],
            total: 0,
            loading: false,
            materialRules: {
                materialUrls: [
                    {
                        required: true,
                        message: '请输入素材url',
                        trigger: 'blur',
                    },
                ],
            },
            dialogAdd: false,
            materialAddForm: {},
            addLoading: false,
            dialogUpload: false,
            uploadLoading: false,
            imageList: [],
            videoList: [],
            uploadType: 0,
            dialogVisible: false,
            dialogImageUrl: null,
            dialogVisibleVideo: false,
            dialogVideoUrl: null,
        };
    },
    mounted() {
        this.queryData();
    },
    methods: {
        queryData() {
            this.loading = true;
            this.$http
                .get('/material/centre/getPage', { params: this.queryForm })
                .then((response) => {
                    this.loading = false;
                    if (response.data.code === 200) {
                        this.tableData = response.data.data;
                        this.total = response.data.count;
                    }
                });
        },
        onCopy() {
            this.$message.success('复制成功');
        },
        onError() {
            this.$message.error('复制失败');
        },
        initAddForm() {
            this.materialAddForm = {
                materialUrls: null,
                materialType: 1,
            };
            this.addLoading = false;
            this.dialogAdd = true;
        },
        addData() {
            this.$refs['materialAddForm'].validate((valid) => {
                if (valid) {
                    this.addLoading = true;
                    let materialUrls =
                        this.materialAddForm.materialUrls.split(',');
                    this.$http
                        .post('/material/centre/add', this.materialAddForm)
                        .then((response) => {
                            this.addLoading = false;
                            if (response.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message({
                                    message: '新增成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: () => {
                                        this.queryData();
                                    },
                                });
                            }
                        });
                }
            });
        },
        isShowImage(url) {
            let arr = ['.jpg', '.jpeg', '.png'];
            let isImage = false;
            for (let i = 0; i < arr.length; i++) {
                let item = arr[i];
                if (url.endsWith(item) == true) {
                    isImage = true;
                    break;
                }
            }
            return isImage;
        },
        initUploadForm() {
            this.handleClose();
            this.uploadType = 0;
            this.dialogUpload = true;
        },
        handleExceed() {
            this.$message.warning('每次上传数量不能超过10');
        },
        handlePreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handlePreviewB(file) {
            this.dialogVideoUrl = file.url;
            this.dialogVisibleVideo = true;
        },
        showVideo(url) {
            this.dialogVideoUrl = url;
            this.dialogVisibleVideo = true;
        },
        handleClose() {
            this.imageList = [];
            this.videoList = [];
            if (this.$refs.upload) {
                this.$refs.upload.clearFiles();
            }
            if (this.$refs.uploadB) {
                this.$refs.uploadB.clearFiles();
            }
        },
        changeType(type) {
            this.uploadType = type;
            this.handleClose();
        },
        upload(params) {
            let file = params.file;
            const isFormat =
                file.type === 'image/jpeg' ||
                file.type === 'image/png' ||
                file.type === 'image/jpg';
            const isSize = file.size / 1024 / 1024 < 1;
            if (!isFormat) {
                this.$message.error('上传图片只能是jpg、png、gif格式');
                return;
            }
            if (!isSize) {
                this.$message.error('上传图片大小不能超过1MB');
                return;
            }
            this.imageList.push(file);
            this.videoList = [];
        },
        uploadB(params) {
            let file = params.file;
            const isFormat =
                file.type === 'video/mp4' ||
                file.type === 'video/avi' ||
                file.type === 'video/mkv' ||
                file.type === 'video/rmvb';
            const isSize = file.size / 1024 / 1024 < 10;
            if (!isFormat) {
                this.$message.error('上传视频只能是mp4、avi、mkv、rmvb格式');
                return;
            }
            if (!isSize) {
                this.$message.error('上传视频大小不能超过10MB');
                return;
            }
            this.videoList.push(file);
            this.imageList = [];
        },
        uploadMaterial() {
            this.uploadLoading = true;
            let form;
            if (this.uploadType == 1) {
                this.$refs.upload.submit();
                form = new FormData();
                form.append('fileType', '1');
                this.imageList.forEach((file) => {
                    form.append('files', file);
                });
            } else {
                this.$refs.uploadB.submit();
                form = new FormData();
                form.append('fileType', '2');
                this.videoList.forEach((file) => {
                    form.append('files', file);
                });
            }
            this.$http
                .post('/common/file/upload', form)
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data;
                        if (data) {
                            let urls = [];
                            data.forEach((value) => urls.push(value.url));
                            this.$http
                                .post('/material/centre/add', {
                                    materialUrls: urls,
                                    materialType: this.uploadType,
                                })
                                .then((response) => {
                                    this.uploadLoading = false;
                                    if (response.data.code === 200) {
                                        this.dialogUpload = false;
                                        this.$message({
                                            message: '上传成功',
                                            type: 'success',
                                            duration: 1000,
                                            onClose: () => {
                                                this.queryData();
                                            },
                                        });
                                    }
                                });
                        }
                    } else {
                        this.uploadLoading = false;
                    }
                })
                .finally(() => {
                    this.uploadLoading = false;
                });
        },
        deleteData(row) {
            this.$confirm('是否删除该素材？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .delete('/material/centre/delete', {
                        params: { id: row.id },
                    })
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                                duration: 1000,
                                onClose: () => {
                                    this.queryData();
                                },
                            });
                        }
                    });
            });
        },
        deleteBatchData() {
            const selectData = this.$refs.materialTable.selection;
            if (selectData.length > 0) {
                this.$confirm('是否删除素材？', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let ids = [];
                    selectData.forEach((row) => {
                        ids.push(row.id);
                    });
                    this.$http
                        .delete('/material/centre/deletes', {
                            params: { ids: ids + '' },
                        })
                        .then((response) => {
                            if (response.data.code === 200) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: () => {
                                        this.queryData();
                                    },
                                });
                            }
                        });
                });
            } else {
                this.$message({
                    message: '请选择一行数据',
                    type: 'warning',
                    duration: 1000,
                });
            }
        },
    },
};
</script>

<style scoped></style>
