<template>
    <div class="main-wrap">
        <h2>运费险设置</h2>

        <el-form ref="addLogisticsForm" size="medium" status-icon :model="settingForm">
            <el-form-item label="是否开启运费险">
                <el-switch v-model="settingForm.isEnabled" :active-value="1" :inactive-value="0">
                </el-switch>
            </el-form-item>

            <div class="mod">
                <p><b>运费险费率</b>（收取参与活动后的订单价格，不包含邮费）</p>
                <el-radio-group v-model="settingForm.type" @change="changeType">
                    <el-radio :label="0">
                        订单金额比例(%)
                    </el-radio>
                    <el-radio :label="1">
                        固定值($)
                    </el-radio>
                </el-radio-group>
                <p>
                    <el-input v-model="settingForm.pct" type="number" @focus="settingForm.type = 0" :min="1" :max="9999" class="number-input" @input="formatToFixed2($event, 'pct')" v-show="settingForm.type == 0" style="width:300px">
                        <template slot="append">%</template>
                    </el-input>
                    <el-input v-model="settingForm.num" type="number" @focus="settingForm.type = 1" :min="1" :max="9999" class="number-input" @input="formatToFixed2($event, 'num')" v-show="settingForm.type == 1" style="width:300px">
                        <template slot="append">$</template>
                    </el-input>
                </p>
            </div>

            <div class="mod">
                <p><b>默认值设置</b>（勾选后，顾客下单时默认购买运费险）</p>
                <el-form-item>
                    <el-checkbox v-model="settingForm.isDefault" :true-label="1" :false-label="0">默认购买运费险</el-checkbox>
                </el-form-item>
            </div>

            <div class="mod">
                <p><b>运费险文案</b>（在勾选处为顾客解释运费险的文案）</p>
                <el-form-item>
                    <el-input type="textarea" placeholder="请填写英文文案" v-model="settingForm.tips" maxlength="200" show-word-limit>
                    </el-input>
                </el-form-item>
            </div>

            <el-form-item>
                <el-button type="primary" v-loading="loading" @click="editInsureConfig">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'FreightInsurance',
    data: () => ({
        settingForm: {
            isDefault: 0,
            isEnabled: 0,
            tips: '',
            type: null,
            pct: '',
            num: '',
        },
        loading: false,
    }),
    mounted() {
        this.getInsureConfig();
    },
    methods: {
        getInsureConfig() {
            let url = '/logistics/insure/getInsureConfig';
            this.$http.get(url).then((res) => {
                let { code, data } = res.data;

                if (code === 200) {
                    let { insureNum, type, ...args } = data || {},
                        key = type ? 'num' : 'pct';

                    Object.assign(this.settingForm, {
                        type,
                        [key]: insureNum,
                        ...args,
                    });
                }
            });
        },
        editInsureConfig() {
            let url = '/logistics/insure/editInsureConfig',
                { type, pct, num, ...args } = this.settingForm,
                params = {
                    type,
                    insureNum: type ? num : pct,
                    ...args,
                };

            if ((params['insureNum'] ?? '') === '')
                return this.$message.warning('请填写运费险');

            this.loading = true;
            this.$http
                .post(url, params)
                .then((res) => {
                    let { code } = res.data;
                    if (code === 200) {
                        this.$message.success('保存成功~');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        formatToFixed2(value, gKey) {
            let str = value.replace('-', ''),
                reg =
                    /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;

            if (~value.indexOf('-')) {
                this.settingForm[gKey] = str;
            }

            if (!reg.test(+str)) {
                this.settingForm[gKey] = (+str).toFixed(2);
            }
        },
        changeType(value) {
            let key = value ? 'pct' : 'num';
            this.settingForm[key] = '';
        },
    },
};
</script>
<style scoped>
.mod {
    margin-top: 20px;
}

::v-deep .number-input input::-webkit-outer-spin-button,
::v-deep .number-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
::v-deep .number-input input {
    -moz-appearance: textfield;
}
</style>