<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商品管理</el-breadcrumb-item>
            <el-breadcrumb-item>商品款式</el-breadcrumb-item>
        </el-breadcrumb>

        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData">刷新</el-button>
                <el-button type="success" icon="el-icon-plus" v-if="$auth($authData.goods.goodsTypeAdd)" @click="openAdd($event)">新增商品款式</el-button>
            </el-form-item>
        </el-form>

        <el-collapse v-model="activeName">
            <el-collapse-item v-for="(item, index) in tableData" :key="index" :title="item.typeName" :name="index">
                <template slot="title">
                    <div class="between-center" style="width:95%;">
                        <span style="font-size:20px;">{{ item.typeName }}</span>
                        <div>
                            <el-button type="primary" size="mini" v-if="$auth($authData.goods.goodsTypeAdd)" icon="el-icon-edit" @click="openAdd($event, item)">编辑</el-button>
                            <el-button type="success" size="mini" v-if="$auth($authData.goods.goodsTypeEdit)" icon="el-icon-edit" @click="openAddType(item, $event)">新增商品款式值</el-button>
                        </div>
                    </div>
                </template>
                <el-table border :data="item.typeValues" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading">
                    <el-table-column label="ID" prop="id" width="80"></el-table-column>
                    <el-table-column label="款式值配图" width="110" header-align="center" align="center">
                        <template slot-scope="scope">
                            <el-image v-if="scope.row.valueImage && scope.row.valueImage.length > 0" fit="contain" style="width: 80px; height: 80px" :src="scope.row.valueImage">
                                <div slot="error" class="el-image__error" style="width: 80px; height: 80px; font-size: 10px">
                                    无图
                                </div>
                            </el-image>
                            <div v-else slot="error" class="el-image__error" style="width: 80px; height: 80px; font-size: 10px">
                                无图
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="valueText" label="款式值文本"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="100" header-align="center" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="text" icon="el-icon-edit-outline" v-if="$auth($authData.goods.goodsTypeEdit)" @click="openEditType(scope.row)">编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-collapse-item>
        </el-collapse>

        <!-- 新增商品款式 -->
        <el-dialog top="3%" :title="isAdding ? '新增商品款式' : '编辑商品款式'" width="500px" :visible.sync="dialogAdd" :close-on-click-modal="false">
            <el-form ref="dictAddForm" :model="dictAddForm" :rules="addRules">
                <el-form-item label="款式名称" prop="typeName">
                    <el-input maxlength="50" v-model="dictAddForm.typeName"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                <el-button @click="dialogAdd = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 新增商品款式值 -->
        <el-dialog top="3%" :title="isAddingType ? '新增商品款式值' : '编辑商品款式值'" width="500px" :visible.sync="dialogAddType" :close-on-click-modal="false">
            <el-form ref="dictAddTypeForm" :model="dictAddTypeForm" :rules="addTypeRules">
                <el-form-item label="款式值文本" prop="valueText">
                    <el-input maxlength="50" v-model="dictAddTypeForm.valueText"></el-input>
                </el-form-item>
                <el-form-item label="款式值配图" prop="valueImage">
                    <el-button type="primary" @click="setOpenInsert('dictAddTypeForm.valueImage')">选择...</el-button>
                </el-form-item>
                <el-form-item label="已选配图">
                    <img :src="dictAddTypeForm.valueImage" alt="">
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addTypeLoading" @click="addTypeData">确定</el-button>
                <el-button @click="dialogAddType = false">取消</el-button>
            </div>
        </el-dialog>

        <ImageUploader :insertImage="insertImage" :insertType="insertType" @getOpenState="getOpenState" @getImage="getImage" ref="uploadImage">
        </ImageUploader>

    </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader';
export default {
    name: 'GoodsType',
    components: {
        ImageUploader: ImageUploader,
    },
    data() {
        return {
            activeName: '1',
            insertImage: false,
            insertType: null,

            queryForm: {
                pageNo: 1,
                pageSize: 100,
            },
            tableData: [],
            loading: false,
            total: 0,

            dialogAdd: false,
            addLoading: false,
            isAdding: true,
            dictAddForm: {
                typeName: null,
            },
            addRules: {
                typeName: [
                    {
                        required: true,
                        message: '请输入款式名称',
                        trigger: 'blur',
                    },
                ],
            },

            dialogAddType: false,
            addTypeLoading: false,
            isAddingType: true,
            dictAddTypeForm: {
                goodsTypeId: null,
                valueText: null,
                valueImage: null,
            },
            addTypeRules: {
                valueText: [
                    {
                        required: true,
                        message: '请输入款式值文本',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    mounted() {
        this.queryData();
    },
    methods: {
        setOpenInsert(obj) {
            this.insertType = obj;
            this.insertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.insertImage = result;
        },
        getImage(obj, image) {
            if (obj == 'dictAddTypeForm.valueImage') {
                this.dictAddTypeForm.valueImage = image[image.length - 1];
            }
        },
        queryData() {
            this.loading = true;
            this.$http
                .get('/goods/type/getPage', { params: this.queryForm })
                .then((response) => {
                    if (response.data.code === 200) {
                        this.tableData = response.data.data;
                        this.total = response.data.count;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openAdd(event, row) {
            this.isAdding = row ? false : true;
            this.dictAddForm = {
                id: row ? row.id : null,
                typeName: row ? row.typeName : null,
            };
            this.dialogAdd = true;
            this.addLoading = false;
            event.stopPropagation();
        },
        addData() {
            console.log(this.dictAddForm);
            let url = '';
            if (this.isAdding) {
                url = '/goods/type/add';
            } else {
                url = '/goods/type/edit';
            }
            this.$refs['dictAddForm'].validate((valid) => {
                if (valid) {
                    this.addLoading = true;
                    this.$http
                        .post(url, this.dictAddForm)
                        .then((response) => {
                            if (response.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message.success('保存成功');
                                this.queryData();
                            }
                        })
                        .finally(() => {
                            this.addLoading = false;
                        });
                }
            });
        },
        openAddType(row, event) {
            this.isAddingType = true;
            this.dictAddTypeForm = {
                goodsTypeId: row.id,
                valueText: null,
                valueImage: null,
            };
            this.dialogAddType = true;
            this.addTypeLoading = false;
            event.stopPropagation();
        },
        openEditType(row) {
            this.isAddingType = false;
            this.dictAddTypeForm = {
                id: row.id,
                goodsTypeId: row.goodsTypeId,
                valueText: row.valueText,
                valueImage: row.valueImage,
            };
            this.dialogAddType = true;
            this.addTypeLoading = false;
        },
        addTypeData() {
            console.log(this.dictAddTypeForm);
            let url = '';
            if (!this.dictAddTypeForm.id) {
                url = '/goods/type/addTypeValue';
            } else {
                url = '/goods/type/editTypeValue';
            }
            this.$refs['dictAddTypeForm'].validate((valid) => {
                if (valid) {
                    this.addTypeLoading = true;
                    this.$http
                        .post(url, this.dictAddTypeForm)
                        .then((response) => {
                            if (response.data.code === 200) {
                                this.dialogAddType = false;
                                this.$message.success('保存成功');
                                this.queryData();
                            }
                        })
                        .finally(() => {
                            this.addTypeLoading = false;
                        });
                }
            });
        },
    },
};
</script>

<style scoped>
.main-wrap {
    height: auto;
}
</style>


