import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 引入echarts
import * as echarts from 'echarts';
import "./plugins/axios";
import "./plugins/clipboard2";
import "./plugins/element";
import "./plugins/jsonViewer";
import "./plugins/common";
import tinymce from "tinymce";
import VueTinymce from "@packy-tang/vue-tinymce";
import "tinymce/plugins/link"; //链接插件
import "tinymce/plugins/image"; //图片插件
import "tinymce/plugins/media"; //媒体插件
import "tinymce/plugins/table"; //表格插件
import "tinymce/plugins/lists"; //列表插件
import "tinymce/plugins/quickbars"; //快速栏插件
import Clipboard from 'clipboard';

Vue.prototype.Clipboard = Clipboard

Vue.prototype.$tinymce = tinymce; // 将全局tinymce对象指向给Vue作用域下
Vue.prototype.$echarts = echarts;
Vue.use(VueTinymce);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
