<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>顾客管理</el-breadcrumb-item>
            <el-breadcrumb-item>顾客列表</el-breadcrumb-item>
        </el-breadcrumb>

        <el-tabs v-model="campaignStatus" @tab-click="changeTab">
            <el-tab-pane label="全部" name="0"></el-tab-pane>
            <el-tab-pane v-for="item in campaignStatusTypes" :key="item.value" :label="item.name" :name="item.value"></el-tab-pane>
        </el-tabs>

        <!-- 查询表单 -->
        <el-form class="queryForm" :inline="true" :gutter="20" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.name" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="queryForm.email" placeholder="邮箱"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="queryForm.phone" placeholder="手机"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryForm.isRegister" placeholder="是否注册">
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryForm.isSubscribed" placeholder="是否订阅">
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <div class="df">
                    <el-input v-model="queryForm.minBuyAmount" placeholder="最低金额"></el-input>
                    <p class="mt_0 mb_0 px_1 tc" style="color: #bbb">~</p>
                    <el-input v-model="queryForm.maxBuyAmount" placeholder="最高金额"></el-input>
                </div>
            </el-form-item>
            <el-form-item>
                <div class="df">
                    <!-- <el-input v-model="queryForm.minBuyNum" placeholder="最小订单数" :disabled="buying"></el-input> -->
                    <el-input-number :min="0" :precision="0" placeholder="最小订单数" :disabled="buying" autocomplete="off" v-model="queryForm.minBuyNum"></el-input-number>
                    <p class="mt_0 mb_0 px_1 tc" style="color: #bbb">~</p>
                    <!-- <el-input v-model="queryForm.maxBuyNum" placeholder="最大订单数"></el-input> -->
                    <el-input-number :min="0" :precision="0" placeholder="最大订单数" autocomplete="off" v-model="queryForm.maxBuyNum"></el-input-number>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="querySearch">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-refresh-left" @click="resetCondition">重置</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="initAddCustomer">新增顾客</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-download" @click="outPut">导出</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表 -->
        <el-table border stripe :data="tableData" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="email" label="邮箱" min-width="160"></el-table-column>
            <el-table-column label="是否注册" min-width="160">
                <template slot-scope="scope">
                    {{ scope.row.isRegister == 1 ? "已注册" : "未注册" }}
                </template>
            </el-table-column>
            <el-table-column label="是否订阅" min-width="120">
                <template slot-scope="scope">
                    {{ scope.row.isSubscribed == 1 ? "已订阅" : "未订阅" }}
                </template>
            </el-table-column>
            <el-table-column label="姓名" min-width="120">
                <template slot-scope="scope">
                    {{ scope.row.firstName }} {{ scope.row.lastName }}
                </template>
            </el-table-column>
            <el-table-column label="电话" min-width="120">
                <template slot-scope="scope">
                    <p v-if="scope.row.phone">（+{{ scope.row.phoneCode }}）{{ scope.row.phone }}</p>
                    <p v-else>无</p>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="加入时间" min-width="160"></el-table-column>
            <el-table-column prop="buyNum" label="购买次数" min-width="120"></el-table-column>
            <el-table-column label="累计金额" min-width="120">
                <template slot-scope="scope">
                    {{ $yrFormatNumber(scope.row.buyAmount, ",", 2) }}USD
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="initEditCustomer(scope.row)">编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-document" @click="showDetail(scope.row.id)">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <!-- 新增顾客 -->
        <el-dialog top="3%" title="新增顾客" width="550px" :visible.sync="dialogAddCustomer" :close-on-click-modal="false">
            <el-form ref="addCustomerForm" label-position="top" size="medium" status-icon :model="addCustomerForm" :rules="addCustomerRules">
                <el-row :gutter="30">
                    <el-col :span="12">
                        <el-form-item label="姓" prop="lastName">
                            <el-input maxlength="20" show-word-limit placeholder="姓" v-model="addCustomerForm.lastName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="名" prop="firstName">
                            <el-input maxlength="20" show-word-limit placeholder="名" v-model="addCustomerForm.firstName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="邮箱" prop="email">
                    <el-input maxlength="50" show-word-limit placeholder="请输入邮箱" v-model="addCustomerForm.email"></el-input>
                </el-form-item>
                <el-row type="flex" align="bottom" :gutter="4">
                    <el-col :span="9">
                        <el-form-item label="手机号" prop="phoneCode">
                            <el-select v-model="addCustomerForm.phoneCode" placeholder="区号">
                                <el-option v-for="item in phoneCodeData" :key="item.id" :label="item.cnNameFormat" :value="item.phoneCode"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="15">
                        <el-form-item prop="phone" style="color: #fff">
                            <el-input maxlength="11" placeholder="手机号" v-model="addCustomerForm.phone"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="默认联系方式">
                    <el-radio label="1" v-model="addCustomerForm.defaultWay">邮箱</el-radio>
                    <el-radio label="2" v-model="addCustomerForm.defaultWay">手机号</el-radio>
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="addisSubscribed">已订阅</el-checkbox>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading" @click="addCustomer">确定</el-button>
                <el-button @click="dialogAddCustomer = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 编辑顾客 -->
        <el-dialog top="3%" title="编辑顾客" width="550px" :visible.sync="dialogEditCustomer" :close-on-click-modal="false">
            <el-form ref="editCustomerForm" label-position="top" size="medium" status-icon :model="editCustomerForm" :rules="editCustomerRules">
                <el-row :gutter="30">
                    <el-col :span="12">
                        <el-form-item label="姓" prop="lastName">
                            <el-input maxlength="20" show-word-limit placeholder="姓" v-model="editCustomerForm.lastName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="名" prop="firstName">
                            <el-input maxlength="20" show-word-limit placeholder="名" v-model="editCustomerForm.firstName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="邮箱" prop="email">
                    <el-input maxlength="50" show-word-limit placeholder="请输入邮箱" v-model="editCustomerForm.email"></el-input>
                </el-form-item>
                <el-row type="flex" align="bottom" :gutter="4">
                    <el-col :span="9">
                        <el-form-item label="手机号" prop="phoneCode">
                            <el-select v-model="editCustomerForm.phoneCode" placeholder="区号">
                                <el-option v-for="item in phoneCodeData" :key="item.id" :label="item.cnNameFormat" :value="item.phoneCode"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="15">
                        <el-form-item prop="phone" style="color: #fff">
                            <el-input maxlength="11" placeholder="手机号" v-model="editCustomerForm.phone"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="默认联系方式">
                    <el-radio label="1" v-model="editCustomerForm.defaultWay">邮箱</el-radio>
                    <el-radio label="2" v-model="editCustomerForm.defaultWay">手机号</el-radio>
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="editisSubscribed">已订阅</el-checkbox>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading" @click="editCustomer">确定</el-button>
                <el-button @click="dialogEditCustomer = false">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="顾客详情" fullscreen :visible.sync="dialogDetail" :close-on-click-modal="false">
            <el-tabs v-model="detailActive">
                <el-tab-pane label="顾客信息" name="first">
                    <section class="addCampaignCodeSection">
                        <el-row>
                            <el-col :span="4" class="addCampaignCodeLegend">基础信息</el-col>
                            <el-col :span="20">
                                <div style="margin-bottom: 10px;">
                                    <el-tag type="success">{{ detailForm.isSubscribed == '1' ? '已' : '未' }}订阅</el-tag>
                                    <el-tag>{{ detailForm.isRegister == '1' ? '已' : '未' }}注册</el-tag>
                                </div>
                                <div class="flexbox">
                                    <div class="flexrow">
                                        <p>姓名</p>
                                        <p></p>
                                    </div>
                                    <div class="flexrow">
                                        <p>{{ detailForm.firstName }} {{ detailForm.lastName }}</p>
                                        <p></p>
                                    </div>
                                </div>
                                <div class="flexbox">
                                    <div class="flexrow">
                                        <p>邮箱</p>
                                        <p>手机号</p>
                                    </div>
                                    <div class="flexrow">
                                        <p>{{ detailForm.email }}</p>
                                        <p v-if="detailForm.phone">(+{{ detailForm.phoneCode }}){{ detailForm.phone }}</p>
                                        <p v-else>无</p>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </section>

                    <section class="addCampaignCodeSection">
                        <el-row>
                            <el-col :span="4" class="addCampaignCodeLegend">详细信息</el-col>
                            <el-col :span="20">
                                <div class="drow">
                                    <p class="tit">注册时间</p>
                                    <p class="content">{{ detailForm.createTime }}</p>
                                </div>
                                <div class="drow">
                                    <p class="tit">首次下单时间</p>
                                    <p class="content">{{ detailForm.firstBuyTime }}</p>
                                </div>
                                <hr>
                                <div class="drow">
                                    <p class="tit">购买次数</p>
                                    <p class="content">{{ detailForm.buyNum }} 次</p>
                                </div>
                                <div class="drow">
                                    <p class="tit">购买商品</p>
                                    <p class="content">{{ detailForm.buyGoodsNum }} 件</p>
                                </div>
                                <div class="drow">
                                    <p class="tit">累计消费</p>
                                    <p class="content">$ {{ detailForm.buyAmount }}</p>
                                </div>
                            </el-col>
                        </el-row>
                    </section>
                </el-tab-pane>

                <el-tab-pane label="购买记录" name="second">
                    <p v-if="orderData.length == 0">暂无购买记录。</p>
                    <!-- 数据表 -->
                    <el-table border stripe v-if="orderData.length > 0" :data="orderData" v-loading="orderLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="750">
                        <el-table-column prop="orderNo" label="订单号"></el-table-column>
                        <el-table-column label="商品信息" min-width="120">
                            <template slot-scope="scope">
                                <p>共{{ scope.row.orderItems.length }}件商品</p>
                                <p>
                                    <el-button type="primary" @click="showItems(scope.row.orderItems)">查看</el-button>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="financialTime" label="支付时间" width="100"></el-table-column>
                        <el-table-column label="收货人">
                            <template slot-scope="scope">
                                {{ scope.row.shippingLastName }} {{ scope.row.shippingFirstName }}
                            </template>
                        </el-table-column>
                        <el-table-column label="支付状态" width="100">
                            <template slot-scope="scope">
                                {{ financialStatusTypes.find( v => v.value == scope.row.financialStatus ).name }}

                                <!-- {{ findKeywords('financialStatusTypes',scope.row.financialStatus)  && findKeywords('financialStatusTypes',scope.row.financialStatus).name  }} -->
                            </template>
                        </el-table-column>
                        <el-table-column prop="totalPrice" label="订单总价" width="100"></el-table-column>
                        <el-table-column prop="shippingCountryEn" label="国家/地区"></el-table-column>
                        <el-table-column label="订单来源" width="100">
                            <template slot-scope="scope">
                                {{ channelSourceData.find( v => v.value == scope.row.channelSource ).name }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="discountCode" label="优惠码"></el-table-column>
                        <el-table-column label="物流状态" width="100">
                            <template slot-scope="scope">
                                {{ fulfillmentStatusTypes.find((v) => v.value == scope.row.fulfillmentStatus).name }}
                            </template>
                        </el-table-column>
                        <!-- <el-table-column label="详情" width="80"></el-table-column> -->
                    </el-table>
                    <el-pagination background layout="total, sizes, prev, pager, next, jumper" v-if="orderData.length > 0" :current-page.sync="orderForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="orderForm.pageSize" :total="orderTotal" @size-change="getOrderPage" @current-change="getOrderPage">
                    </el-pagination>
                </el-tab-pane>

                <el-tab-pane label="收货地址" name="third">
                    <div class="addressbox" v-if="addressData.length > 0">
                        <div class="address" v-for="(item, index) in addressData" :key="index">
                            <div class="addressrow">
                                <p class="tit">收货人</p>
                                <p class="content">{{ item.shippingFirstName }} {{ item.shippingLastName }}</p>
                            </div>
                            <div class="addressrow">
                                <p class="tit">邮箱</p>
                                <p class="content">{{ item.shippingEmail }}</p>
                            </div>
                            <div class="addressrow">
                                <p class="tit">区号</p>
                                <p class="content">{{ item.shippingPhoneCode }}</p>
                            </div>
                            <div class="addressrow">
                                <p class="tit">手机号</p>
                                <p class="content">{{ item.shippingPhone }}</p>
                            </div>
                            <div class="addressrow">
                                <p class="tit">国家/地区</p>
                                <p class="content">{{ item.shippingCountryEn }}</p>
                            </div>
                            <div class="addressrow">
                                <p class="tit">省/州</p>
                                <p class="content">{{ item.shippingProvinceEn }}</p>
                            </div>
                            <div class="addressrow">
                                <p class="tit">城市</p>
                                <p class="content">{{ item.shippingCity }}</p>
                            </div>
                            <div class="addressrow">
                                <p class="tit">详细地址</p>
                                <p class="content">{{ item.shippingAddress }}</p>
                            </div>
                            <div class="addressrow">
                                <p class="tit">邮编</p>
                                <p class="content">{{ item.shippingZip }}</p>
                            </div>
                        </div>
                    </div>
                    <p v-else>尚无收货地址。</p>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>

        <el-dialog title="商品详情" top="3%" width="900px" :visible.sync="dialogItems" :close-on-click-modal="false">
            <el-table :show-header="false" :data="orderItems">
                <el-table-column type="index" label="序号" width="60"></el-table-column>
                <el-table-column label="商品图片" width="90">
                    <template slot-scope="scope">
                        <GoodsImageTemplate :images="scope.row.goodsImage" :isOrderItems="true" :orderMainImage="scope.row.goodsImages"></GoodsImageTemplate>
                        <el-tag type="primary" size="mini">{{
                    children_fulfillmentStatusTypes.find(
                      (v) => v.value == scope.row.fulfillmentStatus
                    ).name
                  }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="商品信息" min-width="120">
                    <template slot-scope="scope">
                        <div class="tl" style="line-height: 24px">
                            {{ scope.row.goodsTitle }}<br />
                            <span style="color: #909399">
                                <span v-if="scope.row.goodsOption">
                                    Style: {{ getGoodsOption(scope.row.goodsOption) }}
                                    <br />
                                </span>
                                SKU: {{ scope.row.sku }}<br />
                                <!-- 供应商: {{ scope.row.vendor }} -->
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="单价" width="200">
                    <template slot-scope="scope">
                        <div class="tr">
                            {{ $yrFormatNumber(scope.row.price, ",", 2) }} USD &times;
                            {{ scope.row.quantity }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="总价" width="140">
                    <template slot-scope="scope">
                        <div class="tr">
                            {{ $yrFormatNumber(scope.row.total, ",", 2) }} USD
                        </div>

                        <div class="tr ft-through" v-if="scope.row.isCampaignReplace">
                            {{ $yrFormatNumber(scope.row.compareAtPrice) }} USD
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogItems = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
export default {
    name: 'CustomerList',
    components: {
        GoodsImageTemplate,
    },
    data() {
        return {
            buying: false,
            btnLoading: false,
            phoneCodeData: [], // 区号数据集合

            dialogDetail: false,
            detailForm: {},
            orderData: [],
            orderLoading: false,
            orderForm: {
                id: null,
                pageNo: 1,
                pageSize: 20,
            },
            orderTotal: 0,
            addressData: [],
            detailActive: 'first',
            dialogItems: false,
            orderItems: [],

            financialStatusTypes: [
                { name: '待支付', value: '1' },
                { name: '已支付', value: '2' },
                { name: '已取消', value: '3' },
                { name: '支付失败', value: '4' },
                { name: '待退款', value: '5' },
                { name: '部分退款', value: '6' },
                { name: '已退款', value: '7' },
                { name: '退款失败', value: '8' },
            ],
            channelSourceData: [
                // 订单详情 - 渠道来源数据
                { name: '其它来源', value: 0 },
                { name: '直接访问', value: 1 },
                { name: 'Google', value: 2 },
                { name: 'Facebook', value: 3 },
                { name: 'Instagram', value: 4 },
                { name: 'Bing', value: 5 },
                { name: 'Baidu', value: 6 },
                { name: 'Snapchat', value: 7 },
                { name: 'pinterest', value: 8 },
                { name: 'youtube', value: 9 },
                { name: 'titok', value: 10 },
                { name: 'email', value: 11 },
            ],
            fulfillmentStatusTypes: [
                // 订单发货状态
                { name: '待发货', value: '1' },
                { name: '部分发货', value: '2' },
                { name: '已发货', value: '3' },
                { name: '部分完成', value: '4' },
                { name: '已完成', value: '5' },
                { name: '已退货', value: '6' },
            ],

            children_fulfillmentStatusTypes: [
                // 订单发货状态
                { name: '待发货', value: '1' },
                { name: '已发货', value: '2' },
                { name: '已完成', value: '3' },
                { name: '已退货', value: '4' },
                { name: '部分发货', value: '5' },
            ],

            // 列表查询条件
            queryForm: {
                name: null,
                email: null,
                phone: null,
                isRegister: null,
                isSubscribed: null,
                minBuyAmount: null,
                maxBuyAmount: null,
                minBuyNum: undefined,
                maxBuyNum: undefined,
                pageNo: 1,
                pageSize: 10,
                isBuy: null,
                reBuy: null,
            },

            campaignStatus: '0',
            /* 类型集合 */
            campaignStatusTypes: [
                { name: '已购顾客', value: '1' },
                { name: '复购顾客', value: '2' },
            ],

            // 列表数据
            loading: false,
            tableData: [],
            total: 0,

            // 新增顾客
            dialogAddCustomer: false,
            addCustomerForm: {},
            addCustomerRules: {
                firstName: [
                    { required: true, message: '请输入名', trigger: 'blur' },
                ],
                lastName: [
                    { required: true, message: '请输入姓', trigger: 'blur' },
                ],
            },
            addisSubscribed: true,

            // 编辑顾客
            dialogEditCustomer: false,
            editCustomerForm: {},
            editCustomerRules: {
                firstName: [
                    { required: true, message: '请输入名', trigger: 'blur' },
                ],
                lastName: [
                    { required: true, message: '请输入姓', trigger: 'blur' },
                ],
            },
            editisSubscribed: true,
        };
    },

    // computed: {
    //     findKeywords() {
    //         return (_globalData, value, keyName = 'value') => {
    //             return this[_globalData].find((v) => v[keyName] == value);
    //         };
    //     },
    // },

    mounted() {
        this.queryForm.minBuyNum = undefined;
        this.queryForm.maxBuyNum = undefined;
        this.queryData();
        this.queryCountry();
    },

    methods: {
        outPut() {
            this.$confirm('是否导出顾客列表？', '导出顾客列表', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            })
                .then(() => {
                    this.loading = true;

                    this.$http
                        .post(`/customer/export`, this.queryForm)
                        .then((r) => {
                            this.loading = false;
                            if (r.data.code === 200) {
                                this.$confirm(
                                    '操作成功，是否跳转到任务中心？',
                                    '导出成功',
                                    {
                                        type: 'warning',
                                        confirmButtonText: '确定',
                                        cancelButtonText: '取消',
                                    }
                                )
                                    .then(() => {
                                        this.$router.push('/task/center');
                                    })
                                    .catch(() => {});
                            }
                        })
                        .catch((err) => {});
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        changeTab(e) {
            console.log(e.name);
            switch (e.name) {
                case '0':
                    this.buying = false;
                    this.queryForm.isBuy = null;
                    this.queryForm.reBuy = null;
                    this.queryForm.minBuyNum = undefined;
                    break;
                case '1':
                    this.buying = true;
                    this.queryForm.isBuy = 1;
                    this.queryForm.reBuy = null;
                    this.queryForm.minBuyNum = 1;
                    break;
                case '2':
                    this.buying = true;
                    this.queryForm.isBuy = null;
                    this.queryForm.reBuy = 1;
                    this.queryForm.minBuyNum = 2;
                    break;
                default:
                    break;
            }
            this.queryData();
        },
        async showDetail(id) {
            this.loading = true;
            this.detailActive = 'first';
            this.orderForm.id = id;
            await this.getDetail(id);
            await this.getOrderPage(id);
            await this.getAddress(id);
            this.dialogDetail = true;
            this.loading = false;
        },
        async getDetail(id) {
            this.detailForm = {};
            await this.$http
                .get('/customer/getDetail?id=' + id)
                .then(async (response) => {
                    if (response.data.code === 200) {
                        this.detailForm = response.data.data;
                        return true;
                    }
                });
        },
        async getOrderPage(id, no = 1, size = 20) {
            this.orderData = [];
            this.orderItems = [];
            this.orderLoading = true;
            await this.$http
                .get('/customer/getOrderPage', { params: this.orderForm })
                .then(async (response) => {
                    if (response.data.code === 200) {
                        this.orderData = response.data.data;
                        this.orderTotal = response.data.count;
                        this.orderLoading = false;
                        return true;
                    }
                });
        },
        async getAddress(id) {
            this.addressData = [];
            await this.$http
                .get('/customer/getAddress?id=' + id)
                .then(async (response) => {
                    if (response.data.code === 200) {
                        this.addressData = response.data.data;
                        return true;
                    }
                });
        },
        showItems(items) {
            this.orderItems = items;
            this.dialogItems = true;
        },
        querySearch() {
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        queryData() {
            this.loading = true;

            this.$http
                .get('/customer/getPage', { params: this.queryForm })
                .then((r) => {
                    this.loading = false;

                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        this.total = r.data.count;
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    // this.$message.error(error);;
                });
        },

        // 获取国家数据，用于区号
        queryCountry() {
            this.$http
                .get('/common/country/getList')
                .then((r) => {
                    if (r.data.code === 200) {
                        let data = r.data.data;

                        this.phoneCodeData = data.map((item) => {
                            let cnNameFormat =
                                '+' + item.phoneCode + ' (' + item.cnName + ')';
                            return {
                                id: item.id,
                                phoneCode: item.phoneCode,
                                cnNameFormat: cnNameFormat,
                            };
                        });
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                });
        },

        // 重置列表查询条件
        resetCondition() {
            this.queryForm.name = null;
            this.queryForm.email = null;
            this.queryForm.phone = null;
            this.queryForm.isRegister = null;
            this.queryForm.isSubscribed = null;
            this.queryForm.minBuyAmount = null;
            this.queryForm.maxBuyAmount = null;
            this.queryForm.minBuyNum = undefined;
            this.queryForm.maxBuyNum = undefined;
            this.queryForm.isBuy = null;
            this.queryForm.reBuy = null;
            this.buying = false;
            this.campaignStatus = '0';

            this.queryData();
        },

        // 新增顾客
        initAddCustomer() {
            this.addisSubscribed = true;
            this.addCustomerForm = {
                firstName: null,
                lastName: null,
                email: null,
                phoneCode: null,
                phone: null,
                defaultWay: null,
                isSubscribed: true,
            };
            this.dialogAddCustomer = true;
        },
        addCustomer() {
            this.$refs.addCustomerForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;

                    this.addCustomerForm.isSubscribed = this.addisSubscribed
                        ? 1
                        : 0;

                    this.$http
                        .post('/customer/add', this.addCustomerForm)
                        .then((r) => {
                            this.btnLoading = false;

                            if (r.data.code === 200) {
                                this.dialogAddCustomer = false;

                                this.$message({
                                    message: '新增成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: this.queryData,
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = false;
                        });
                }
            });
        },

        // 编辑顾客
        initEditCustomer(item) {
            this.editisSubscribed = item.isSubscribed ? true : false;
            this.editCustomerForm = {
                id: item.id,
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.email,
                phoneCode: item.phoneCode,
                phone: item.phone,
                defaultWay: item.defaultWay.toString(),
                isSubscribed: 1,
            };
            this.dialogEditCustomer = true;
        },
        editCustomer() {
            this.$refs.editCustomerForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;

                    this.editCustomerForm.isSubscribed = this.editisSubscribed
                        ? 1
                        : 0;

                    this.$http
                        .post('/customer/edit', this.editCustomerForm)
                        .then((r) => {
                            this.btnLoading = false;

                            if (r.data.code === 200) {
                                this.dialogEditCustomer = false;

                                this.$message({
                                    message: '编辑成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: this.queryData,
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = false;
                        });
                }
            });
        },
        getGoodsOption(obj) {
            let str = '';
            for (let item in obj) {
                str += item + ':' + obj[item] + ' / ';
            }
            str = str.substring(0, str.length - 3);
            return str;
        },
    },
};
</script>

<style scoped>
p {
    margin: 5px 0;
}
hr {
    height: 1px;
    border: none;
    background: #ccc;
    margin: 20px 0;
}
.queryForm .el-input {
    width: 130px;
}

.queryForm .el-select {
    width: 110px;
}

.flexbox {
    margin-bottom: 10px;
}
.flexbox .flexrow,
.drow,
.addressbox .addressrow {
    display: flex;
    flex-wrap: wrap;
}
.flexbox .flexrow p {
    flex-basis: 40%;
    max-width: 40%;
}

.drow .tit,
.addressbox .addressrow .tit {
    flex-basis: 30%;
    max-width: 30%;
}
.drow .content,
.addressbox .addressrow .content {
    flex-basis: 70%;
    max-width: 70%;
}
.addressbox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-content: space-between;
}

.addressbox .address {
    border: 1px solid #eee;
    padding: 10px;
}
.addressbox .addressrow {
    margin-bottom: 10px;
}

.addCampaignCodeSection {
    margin-bottom: 16px;
    padding: 16px 32px;
    background-color: #fff;
}

.addCampaignCodeLegend {
    font-size: 20px;
    font-weight: bold;
}

.addCampaignFullSection {
    margin-bottom: 16px;
    padding: 16px 32px;
    background-color: #fff;
}

.addCampaignFullLegend {
    font-size: 20px;
    font-weight: bold;
}

/* 适用范围卡片 */
.addCampaignFullCard {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    box-sizing: border-box;
    border: solid 1px #ecedf3;
    border-radius: 6px;
    width: 200px;
    height: 100px;
    cursor: pointer;
    overflow: hidden;
}

.addCampaignFullCard > h4 {
    margin: 5px 16px;
}

.addCampaignFullCard > p {
    margin: 0 16px;
    color: #999;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
}

.addCampaignFullSelectedCard {
    border: solid 2px #2d67f3;
    background: #f3f7ff;
}

.addCampaignFullCardChecked {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 50px;
    height: 50px;
    background-color: #2d67f3;
    transform: rotate(45deg);
}

.addCampaignFullCardChecked > i {
    position: absolute;
    top: 31px;
    left: 16px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    transform: rotate(-45deg);
}
</style>
