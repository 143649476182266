<template>
    <div class="main-wrap" v-loading="searchloading" :class="isHandsome ? 'handsome' : ''">
        <div class="wrapNum">
            <p class="title between">
				数据
				<el-switch
					active-text="Dark"
					inactive-text=""
					v-model="isHandsome"
					@change="changeHandsome($event)">
				</el-switch>
			</p>
            <el-form :inline="true" :model="queryForm" class="demo-form-inline">
                <el-form-item>
                    <el-date-picker type="daterange" range-separator="至" :clearable="false" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="date" value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="changeTime" :default-value="new Date()"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    VS &nbsp;&nbsp;<el-date-picker type="daterange" :clearable="false" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="dateB" value-format="yyyy-MM-dd" :picker-options="pickerOptionsB" @change="changeTimeB" :default-value="new Date()"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="queryData" :loading="searchloading">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-delete" @click="clearBattle" :loading="searchloading">清除对比</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button :type="isHandsome ? 'info' : 'warning'" icon="el-icon-s-data" @click="initStatistic" v-if="$store.state.userAuth.roleId === 1">重新统计数据</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 核心指标 -->
        <div class="indicators">
            <p class="title">核心指标<router-link class="link golink" :to="{path: '/statistic/realTime'}">查看实时数据</router-link></p>
            <div class="mod-kpi">
                <el-row :gutter="20" class="indicatorsrow">
                    <el-col>
                        <div class="grid-content bg-purple">
                            <p class="cash">
                                访问用户
                                <el-tooltip class="item" effect="dark" content="访问网站的独立用户数" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                            </p>
                            <p class="number" v-if="visitUserNum != null || ''">{{visitUserNum}}</p>
                            <p class="none-data" v-else>暂无数据</p>
                            <span v-if="coreData" class="change">
                                <i class="el-icon-caret-top green" v-if="coreData.visitUserRateType == 1"><span class="ratenumber">{{ coreData.visitUserRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="coreData.visitUserRateType == 0"><span class="ratenumber">{{ coreData.visitUserRate }}</span></i>
                                <i v-if="!coreData.visitUserRate">-</i>
                            </span>

                        </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple">
                            <p class="cash">
                                下单成功总数
                                <el-tooltip class="item" effect="dark" content="下单成功总数" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                            </p>
                            <p class="number" v-if="orderSuccessNum != null || ''">{{orderSuccessNum}}</p>
                            <p class="none-data" v-else>暂无数据</p>
                            <span v-if="coreData" class="change">
                                <i class="el-icon-caret-top green" v-if="coreData.orderSuccessRateType == 1"><span class="ratenumber">{{ coreData.orderSuccessRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="coreData.orderSuccessRateType == 0"><span class="ratenumber">{{ coreData.orderSuccessRate }}</span></i>
                                <i v-if="!coreData.orderSuccessRate">-</i>
                            </span>

                        </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple">
                            <p class="cash">
                                转化率
                                <el-tooltip class="item" effect="dark" content="下单成功数占访问人数的百分比" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                            </p>
                            <p class="number" v-if="conversionRate != null || ''">{{conversionRate}}</p>
                            <p class="none-data" v-else>暂无数据</p>
                            <span v-if="coreData" class="change">
                                <i class="el-icon-caret-top green" v-if="coreData.conversionRateType == 1"><span class="ratenumber">{{ coreData.conversionRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="coreData.conversionRateType == 0"><span class="ratenumber">{{ coreData.conversionRate }}</span></i>
                                <i v-if="!coreData.conversionRate">-</i>
                            </span>

                        </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple">
                            <p class="cash">
                                成交额
                                <el-tooltip class="item" effect="dark" content="下单成功的订单总金额" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                            </p>
                            <p class="number" v-if="totalTurnover != null || ''">${{totalTurnover}}</p>
                            <p class="none-data" v-else>暂无数据</p>
                            <span v-if="coreData" class="change">
                                <i class="el-icon-caret-top green" v-if="coreData.totalTurnoverRateType == 1"><span class="ratenumber">{{ coreData.totalTurnoverRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="coreData.totalTurnoverRateType == 0"><span class="ratenumber">{{ coreData.totalTurnoverRate }}</span></i>
                                <i v-if="!coreData.totalTurnoverRate">-</i>
                            </span>

                        </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple">
                            <p class="cash">
                                退款
                                <el-tooltip class="item" effect="dark" content="退款订单的退款金额总和" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                            </p>
                            <p class="number" v-if="totalRefundAmount != null || ''">${{totalRefundAmount}}</p>
                            <p class="none-data" v-else>暂无数据</p>
                            <span v-if="coreData" class="change">
                                <i class="el-icon-caret-top green" v-if="coreData.totalRefundAmountRateType == 1"><span class="ratenumber">{{ coreData.totalRefundAmountRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="coreData.totalRefundAmountRateType == 0"><span class="ratenumber">{{ coreData.totalRefundAmountRate }}</span></i>
                                <i v-if="!coreData.totalRefundAmountRate">-</i>
                            </span>

                        </div>
                    </el-col>
                    <el-col class="grid-col">
                        <div class="grid-content bg-purple cash_item">
                            <p class="cash">
                                访问平均深度
                                <el-tooltip class="item" effect="dark" content="访问平均深度" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                            </p>
                            <p class="number" v-if="visitDepthAvgNum != null || ''">{{visitDepthAvgNum}}</p>
                            <p class="none-data" v-else>暂无数据</p>
                            <span v-if="coreData" class="change">
                                <i class="el-icon-caret-top green" v-if="coreData.visitDepthAvgRateType == 1"><span class="ratenumber">{{ coreData.visitDepthAvgRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="coreData.visitDepthAvgRateType == 0"><span class="ratenumber">{{ coreData.visitDepthAvgRate }}</span></i>
                                <i v-if="!coreData.visitDepthAvgRate">-</i>
                            </span>

                        </div>
                    </el-col>
                    <el-col class="grid-col">
                        <div class="grid-content bg-purple cash_item">
                            <p class="cash">
                                客单价
                                <el-tooltip class="item" effect="dark" content="总成交额/总订单量" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                            </p>
                            <p class="number" v-if="customerAvgPrice != null || ''">${{customerAvgPrice}}</p>
                            <p class="none-data" v-else>暂无数据</p>
                            <span v-if="customData" class="change">
                                <i class="el-icon-caret-top green" v-if="customData.customerAvgPriceRateType == 1"><span class="ratenumber">{{ customData.customerAvgPriceRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="customData.customerAvgPriceRateType == 0"><span class="ratenumber">{{ customData.customerAvgPriceRate }}</span></i>
                                <i v-if="!customData.customerAvgPriceRate"></i>
                            </span>

                        </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20" class="mt-20">
                    <el-col class="grid-col">
                        <div class="grid-content bg-purple cash_item">
                            <p class="cash">
                                支付取消率
                                <el-tooltip class="item" effect="dark" content="支付取消率=支付状态为已取消的订单/全部订单，去重按照同一个顾客去重，同一个顾客在所选时间段内产生多个订单，只记录为一个" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                            </p>
                            <div class="flex-purple">
                                <span class="number" v-if="topData.financialCancelRate != null">{{topData.financialCancelRate || '' }}</span>
                                <span class="none" v-else>暂无数据</span>
                                <span v-show="topData.financialCancelUserRate != null" class="ft-sub">&nbsp;&nbsp;&nbsp;&nbsp;(去重后{{ topData.financialCancelUserRate  }})</span>
                                <router-link :to="{path: '/order/list', query: { orderStatus: '0', financialStatus: '3', date  }}" class="link">查看详情</router-link>
                            </div>
                            <p v-if="coreData" class="change">
                                <i class="el-icon-caret-top green" v-if="coreData.financialCancelRateType == 1"><span class="ratenumber">{{ coreData.financialCancelRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="coreData.financialCancelRateType == 0"><span class="ratenumber">{{ coreData.financialCancelRate }}</span></i>
                                <i v-if="!coreData.financialCancelRate">-</i>
                            </p>

                            <div class="num-mod">
                                支付取消数<span class="ft-number">{{ topData.financialCancelNum }}</span>
                                <span v-show="topData.financialCancelUserNum != null">&nbsp;&nbsp;&nbsp;&nbsp;(去重后{{ topData.financialCancelUserNum  }})</span>
                            </div>
                        </div>
                    </el-col>

                    <el-col class="grid-col">
                        <div class="grid-content bg-purple cash_item">
                            <p class="cash">
                                支付失败率
                                <el-tooltip class="item" effect="dark" content="支付状态为支付失败的订单/全部订单，去重按照同一个顾客去重，同一个顾客在所选时间段内产生多个订单，只记录为一个" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                            </p>

                            <div class="flex-purple">
                                <span class="number" v-if="topData.fulfillmentFailRate != null || ''">{{topData.fulfillmentFailRate}}</span>
                                <span class="none-data" v-else>暂无数据</span>
                                <span v-show="topData.fulfillmentFailUserRate != null" class="ft-sub">&nbsp;&nbsp;&nbsp;&nbsp;(去重后{{ topData.fulfillmentFailUserRate  }})</span>
                                <router-link :to="{path: '/order/list', query: { orderStatus: '0', financialStatus: '4' ,date  }}" class="link">查看详情</router-link>
                            </div>
                            <p v-if="coreData" class="change">
                                <i class="el-icon-caret-top green" v-if="coreData.fulfillmentFailRateType == 1"><span class="ratenumber">{{ coreData.fulfillmentFailRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="coreData.fulfillmentFailRateType == 0"><span class="ratenumber">{{ coreData.fulfillmentFailRate }}</span></i>
                                <i v-if="!coreData.fulfillmentFailRate">-</i>
                            </p>

                            <div class="num-mod">
                                支付失败数<span class="ft-number">{{ topData.fulfillmentFailNum }}</span>
                                <span v-show="topData.fulfillmentFailUserNum != null">&nbsp;&nbsp;&nbsp;&nbsp;(去重后{{ topData.fulfillmentFailUserNum  }})</span>
                            </div>
                        </div>
                    </el-col>

                    <el-col class="grid-col">
                        <div class="grid-content bg-purple cash_item">
                            <p class="cash">
                                召回成功率
                                <el-tooltip class="item" effect="dark" content="召回成功的订单/(召回成功的订单+召回失败的订单)" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                            </p>

                            <div class="flex-purple">
                                <span class="number" v-if="topData.recallSuccessRate != null || ''">{{topData.recallSuccessRate}}</span>
                                <span class="none-data" v-else>暂无数据</span>
                                <span v-show="topData.fulfillmentFailUserRate != null" class="ft-sub">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <router-link :to="{path: '/order/recall', query: { recallStatus: '2' ,date }}" class="link">查看详情</router-link>
                            </div>
                            <p v-if="coreData" class="change">
                                <i class="el-icon-caret-top green" v-if="coreData.recallSuccessRateType == 1"><span class="ratenumber">{{ coreData.recallSuccessRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="coreData.recallSuccessRateType == 0"><span class="ratenumber">{{ coreData.recallSuccessRate }}</span></i>
                                <i v-if="!coreData.recallSuccessRate">-</i>
                            </p>

                            <div class="num-mod">
                                召回成功数<span class="ft-number">{{ topData.recallSuccessNum  }}</span>
                                <span v-show="topData.fulfillmentFailUserNum != null">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            </div>

                        </div>
                    </el-col>
                </el-row>
            </div>

        </div>
        <!-- 交易趋势 -->
        <el-tabs v-model="activeName" @tab-click="handleClick" :type="isHandsome ? 'card' : ''">
            <el-tab-pane label="交易趋势" name="1">
                <div class="trend mask-f">
                    <div class="trend_item">
                        <p class="topset">
                            <el-select v-model="seriesData.tradingTrend" placeholder="请选择" @change="setSeriesType('tradingTrend', $event)">
                                <el-option v-for="item in seriesType" :key="item.value" :label="item.name" :value="item.value"></el-option>
                            </el-select>
                        </p>
                        <div id="tradingTrend" style="height:100%;width:100%;"></div>
                        <!-- <p class="inline-block">
                            <span class=" inline-block w-px12 h-px8 bg-blue100 "></span>
                            <span style="margin-left: 8px;">{{statisticDate}}</span>
                        </p> -->
                    </div>
                    <div class="trend_item">
                        <p class="topset">
                            <el-select v-model="seriesData.turnoverTrend" placeholder="请选择" @change="setSeriesType('turnoverTrend', $event)">
                                <el-option v-for="item in seriesType" :key="item.value" :label="item.name" :value="item.value"></el-option>
                            </el-select>
                        </p>
                        <div id="turnoverTrend" style="height:100%;width:100%;"></div>
                        <!-- <p class="inline-block">
                            <span class=" inline-block w-px12 h-px8 bg-blue100 "></span>
                            <span style="margin-left: 8px;">{{statisticDate}}</span>
                        </p> -->
                    </div>
                </div>
                <div class="trend mask" v-if="turnoverTrendMask">暂无数据</div>

            </el-tab-pane>
            <el-tab-pane label="访问趋势" name="2">
                <div class="trend mask-f">
                    <div class="trend_item">
                        <p class="topset">
                            <el-select v-model="seriesData.visitTrend" placeholder="请选择" @change="setSeriesType('visitTrend', $event)">
                                <el-option v-for="item in seriesType" :key="item.value" :label="item.name" :value="item.value"></el-option>
                            </el-select>
                        </p>
                        <div id="visitTrend" style="height:100%;width:100%;"></div>
                        <!-- <p class="inline-block">
                            <span class=" inline-block w-px12 h-px8 bg-blue100 "></span>
                            <span style="margin-left: 8px;">{{statisticDate}}</span>
                        </p> -->
                    </div>
                    <div class="trend_item">
                        <p class="topset">
                            <el-select v-model="seriesData.visitNumTrend" placeholder="请选择" @change="setSeriesType('visitNumTrend', $event)">
                                <el-option v-for="item in seriesType" :key="item.value" :label="item.name" :value="item.value"></el-option>
                            </el-select>
                        </p>
                        <div id="visitNumTrend" style="height:100%;width:100%;"></div>
                        <!-- <p class="inline-block">
                            <span class=" inline-block w-px12 h-px8 bg-blue100 "></span>
                            <span style="margin-left: 8px;">{{statisticDate}}</span>
                        </p> -->
                    </div>
                </div>
                <div class="trend mask" v-if="visitNumMask">暂无数据</div>
            </el-tab-pane>
        </el-tabs>
        <!-- 综合数据 -->
        <p class="title">综合数据<span>最新统计时间：{{statisticDate}}</span></p>
        <div class="comprehensive">
            <div class="refund-thumb">
                <div class="integrated">
                    <p class="cash">客单价
                        <el-tooltip class="item" effect="dark" content="总成交额/总订单量" placement="top">
                            <i class="el-icon-question"></i>
                        </el-tooltip>
                    </p>
                    <p>平均客单价：
                        <span class="num_pirce">${{customerAvgPrice}}</span>
                        <span v-if="customData" class="change">
                            <i class="el-icon-caret-top green" v-if="customData.customerAvgPriceRateType == 1"><span class="ratenumber">{{ customData.customerAvgPriceRate }}</span></i>
                            <i class="el-icon-caret-bottom red" v-if="customData.customerAvgPriceRateType == 0"><span class="ratenumber">{{ customData.customerAvgPriceRate }}</span></i>
                            <i v-if="!customData.customerAvgPriceRate"></i>
                        </span>
                    </p>
                    <div id="customerLine" style="height:350px; width:100%;"></div>
                    <!-- <p class="inline-block">
                        <span class=" inline-block w-px12 h-px8 bg-blue100 "></span>
                        <span style="margin-left: 8px;">{{statisticDate}}</span>
                    </p> -->
                </div>
                <div class="refund-mask" v-if="customerLineMask">暂无数据</div>
            </div>

            <div class="refund-thumb">
                <div class="integrated">
                    <p class="cash">
                        历史复购率
                        <el-tooltip class="item" effect="dark" content="下单次数大于一次的用户占全部用户的百分比，与时间无关" placement="top">
                            <i class="el-icon-question"></i>
                        </el-tooltip>
                        ： <span class="number" v-if="totalRepurchaseRate != null || ''">{{totalRepurchaseRate}}</span>
                        <span class="number " v-else>暂无数据</span>
                    </p>

                    <p class="cash">当前复购率
                        <el-tooltip class="item" effect="dark" content="所选时间段内，购买次数大于1的用户占所有购买用户的百分比。" placement="top">
                            <i class="el-icon-question"></i>
                        </el-tooltip>：
                        <span class="num_pirce">{{reBuyAvgRate}}%</span>
                    </p>
                    <!-- <p>平均复购率:<span class="num_pirce">{{reBuyAvgRate}}</span> </p> -->
                    <div id="reBuyRate" style="height:350px; width:100%;"></div>
                    <!-- <p class="inline-block">
                        <span class=" inline-block w-px12 h-px8 bg-blue100 "></span>
                        <span style="margin-left: 8px;">{{statisticDate}}</span>
                    </p> -->
                </div>
                <div class="refund-mask" v-if="reBuyRateMask">暂无数据</div>
            </div>

        </div>

        <!--tubiao-->
        <div class="refund">
            <div class="refund_wrap">
                <!-- 统计 -->
                <div class="refund_item">
                    <p class="cash">转化率漏斗：
                        <span class="num_pirce" style="margin-left:10px">
                            <span v-if="rateData" class="change">
                                <i class="el-icon-caret-top green" v-if="rateData.vsOrderSuccessToVisitUserRateType == 1"><span class="ratenumber">{{ rateData.vsOrderSuccessToVisitUserRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="rateData.vsOrderSuccessToVisitUserRateType == 0"><span class="ratenumber">{{ rateData.vsOrderSuccessToVisitUserRate }}</span></i>
                                <i v-if="!rateData.vsOrderSuccessToVisitUserRate"></i>
                            </span>

                            {{orderSuccessToVisitUserRate }}
                        </span>
                    </p>
                    <div class="overview_bottom">
                        <span class="overview_bottom_data">
                            访问用户
                        </span><span>{{visitUserNum}}</span>
                        <div class="overview_bottom_title">
                            {{buyToVisitUserRate}}
                            <span v-if="rateData" class="change">
                                <i class="el-icon-caret-top green" v-if="rateData.vsBuyToVisitUserRateType == 1"><span class="ratenumber">{{ rateData.vsBuyToVisitUserRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="rateData.vsBuyToVisitUserRateType == 0"><span class="ratenumber">{{ rateData.vsBuyToVisitUserRate }}</span></i>
                                <i v-if="!rateData.vsBuyToVisitUserRate">-</i>
                            </span>

                        </div>
                    </div>
                    <div class="overview_bottom">
                        <span class="overview_bottom_data">
                            加入购物车
                        </span><span>{{buyNum}}</span>
                        <div class="overview_bottom_title">
                            {{checkoutToBuyRate}}
                            <span v-if="rateData" class="change">
                                <i class="el-icon-caret-top green" v-if="rateData.vsCheckoutToBuyRateType == 1"><span class="ratenumber">{{ rateData.vsCheckoutToBuyRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="rateData.vsCheckoutToBuyRateType == 0"><span class="ratenumber">{{ rateData.vsCheckoutToBuyRate }}</span></i>
                                <i v-if="!rateData.vsCheckoutToBuyRate">-</i>
                            </span>

                        </div>
                    </div>
                    <div class="overview_bottom">
                        <span class="overview_bottom_data">
                            发起结账
                        </span><span>{{checkoutNum}}</span>
                        <div class="overview_bottom_title">
                            {{orderToCheckoutRate}}
                            <span v-if="rateData" class="change">
                                <i class="el-icon-caret-top green" v-if="rateData.vsOrderToCheckoutRateType == 1"><span class="ratenumber">{{ rateData.vsOrderToCheckoutRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="rateData.vsOrderToCheckoutRateType == 0"><span class="ratenumber">{{ rateData.vsOrderToCheckoutRate }}</span></i>
                                <i v-if="!rateData.vsOrderToCheckoutRate">-</i>
                            </span>

                        </div>
                    </div>
                    <div class="overview_bottom">
                        <span class="overview_bottom_data">
                            新增支付信息
                        </span><span>{{orderNum}}</span>
                        <div class="overview_bottom_title">
                            {{orderSuccessToOrderRate}}
                            <span v-if="rateData" class="change">
                                <i class="el-icon-caret-top green" v-if="rateData.vsOrderSuccessToOrderRateType == 1"><span class="ratenumber">{{ rateData.vsOrderSuccessToOrderRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="rateData.vsOrderSuccessToOrderRateType == 0"><span class="ratenumber">{{ rateData.vsOrderSuccessToOrderRate }}</span></i>
                                <i v-if="!rateData.vsOrderSuccessToOrderRate">-</i>
                            </span>

                        </div>
                    </div>
                    <div class="overview_bottom">
                        <span class="overview_bottom_data">
                            下单成功
                        </span><span>{{orderSuccessNum}}</span>
                    </div>
                </div>
                <!-- 退款 -->
                <!-- <div class="refund_item mask-f">
                    <p class="cash">退款
                        <el-tooltip class="item" effect="dark" content="退款订单的退款金额总和" placement="top">
                            <i class="el-icon-question"></i>
                        </el-tooltip>
                    </p>
                    <div class="refund-container">
                        <p class="num_pirce">
                            ${{totalRefund}}
                            <span v-if="coreData" class="change">
                                <i class="el-icon-caret-top green" v-if="coreData.totalRefundAmountRateType == 1">{{ coreData.totalRefundAmountRate }}</i>
                                <i class="el-icon-caret-bottom red" v-if="coreData.totalRefundAmountRateType == 0">{{ coreData.totalRefundAmountRate }}</i>
                                <i v-if="!coreData.totalRefundAmountRate">-</i>
                            </span>
                        </p>
                        <div id="refundChart"></div>
                        <p class="inline-block">
                            <span class=" inline-block w-px12 h-px8 bg-blue100 "></span>
                            <span>{{statisticDate}}</span>
                        </p>
                    </div>
                    <div class="refund-mask" v-if="refundChartMask">
                        暂无数据
                    </div>
                </div> -->
                <!-- 转化率 -->
                <div class="refund_item mask-f">
                    <p class="cash">转化率
                        <!-- <el-tooltip class="item" effect="dark" content="退款订单的退款金额总和" placement="top">
				            <i class="el-icon-question"></i>
				        </el-tooltip> -->
                    </p>
                    <div class="refund-container">
                        <p class="num_pirce">
                            {{conversionRate}}
                            <span v-if="coreData" class="change">
                                <i class="el-icon-caret-top green" v-if="coreData.conversionRateType == 1"><span class="ratenumber">{{ coreData.conversionRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="coreData.conversionRateType == 0"><span class="ratenumber">{{ coreData.conversionRate }}</span></i>
                                <i v-if="!coreData.conversionRate">-</i>
                            </span>
                        </p>
                        <div id="conversion"></div>
                        <!-- <p class="inline-block">
				            <span class=" inline-block w-px12 h-px8 bg-blue100 "></span>
				            <span>{{statisticDate}}</span>
				        </p> -->
                    </div>
                    <div class="refund-mask" v-if="conversionMask">
                        暂无数据
                    </div>
                </div>
                <!-- 连带率 -->
                <div class="refund_item mask-f">
                    <p class="cash">连带率
                        <el-tooltip class="item" effect="dark" content="平均每笔订单的商品数量，所有已支付的订单的商品数量/所有已支付的订单的数量" placement="top">
                            <i class="el-icon-question"></i>
                        </el-tooltip>
                    </p>
                    <div class="refund-container">
                        <p class="num_pirce">
                            {{totalRelated}}
                            <span v-if="relatedData" class="change">
                                <i class="el-icon-caret-top green" v-if="relatedData.vsTotalRelatedRateType == 1"><span class="ratenumber">{{ relatedData.vsTotalRelatedRate }}</span></i>
                                <i class="el-icon-caret-bottom red" v-if="relatedData.vsTotalRelatedRateType == 0"><span class="ratenumber">{{ relatedData.vsTotalRelatedRate }}</span></i>
                                <i v-if="!relatedData.vsTotalRelatedRate"></i>
                            </span>
                        </p>
                        <div id="relatedChart"></div>
                        <!-- <p class="inline-block">
                            <span class=" inline-block w-px12 h-px8 bg-blue100 "></span>
                            <span>{{statisticDate}}</span>
                        </p> -->
                    </div>
                    <div class="refund-mask" v-if="relatedChartMask">
                        暂无数据
                    </div>
                </div>
                <!-- <div class="refund_item mask" v-else>暂无数据</div> -->
            </div>
            <div class="refund_wrap special">
                <!-- 下单国家排行列表 -->
                <div class="refund_item">
                    <p class="search_details">
                        <span>下单国家排行列表</span>
                        <a class="link" @click="countryDetails">查看详情</a>
                    </p>
                    <el-table :data="countryBuyData" v-loading="loading" element-loading-text="加载中">
                        <el-table-column prop="countryName" label="国家名称" min-width="110">
                            <template slot-scope="scope">
                                <div class="hidetext" :title="scope.row.countryName">
                                    {{scope.row.countryName}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="orderSuccessNum" label="成功订单数" align="center" min-width="110"></el-table-column>
                        <el-table-column label="成功订单占比" align="center" min-width="110">
                            <template slot-scope="scope">
                                {{ scope.row.orderSuccessRate }}%
                            </template>
                        </el-table-column>
                        <el-table-column prop="visitUserNum" label="用户访问数量" align="center" min-width="110"></el-table-column>
                        <el-table-column prop="visitUserRate" label="用户访问占比" align="center" min-width="110">
                            <template slot-scope="scope">
                                {{ scope.row.visitUserRate }}%
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 页面访问排行列表 -->
                <div class="refund_item">
                    <p>页面访问排行列表<router-link class="link" :to="{path: '/statistic/page', query: { sort: 2 ,date }}">查看详情</router-link>
                    </p>
                    <el-table :data="pageVisitData" v-loading="loading" element-loading-text="加载中">
                        <el-table-column align="left" prop="pagePath" label="页面路径" min-width="110">
                            <template slot-scope="scope">
                                <div class="hidetext" :title="scope.row.pagePath">
                                    {{scope.row.pagePath}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="visitUserNum" label="用户访问数量" min-width="110" align="right"></el-table-column>
                        <el-table-column prop="outNum" label="跳出数" min-width="80"></el-table-column>
                        <el-table-column prop="outRate" label="跳出率" min-width="80">
                            <template slot-scope="scope">
                                {{ scope.row.outRate }}%
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="refund_wrap">
                <!-- 搜索热词排行列表 -->
                <div class="refund_item">
                    <p class="search_details">
                        <span>搜索热词排行列表</span>
                        <a class="link" @click="searchDetails">查看详情</a>
                    </p>
                    <el-table :data="hotVisitData" v-loading="loading" element-loading-text="加载中">
                        <el-table-column prop="queryText" label="搜索内容" min-width="110">
                            <template slot-scope="scope">
                                <div class="hidetext" :title="scope.row.queryText">
                                    {{scope.row.queryText}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="visitUserNum" label="用户访问数量" min-width="110"></el-table-column>
                        <el-table-column prop="visitUserRate" label="用户访问占比" min-width="110">
                            <template slot-scope="scope">
                                {{ scope.row.visitUserRate }}%
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 商品加购排行列表 -->
                <div class="refund_item">
                    <p>商品加购排行列表<router-link class="link" :to="{path: '/statistic/goods', query: { sort: 9 ,date }}">查看详情</router-link>
                    </p>
                    <el-table :data="tableData" v-loading="loading" element-loading-text="加载中">
                        <el-table-column prop="goodsTitle" label="商品标题" min-width="110">
                            <template slot-scope="scope">
                                <div class="hidetext" :title="scope.row.goodsTitle" @click="openView( scope.row.goodsUrl )">
                                    {{scope.row.goodsTitle}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="buyNum" label="加购数量" min-width="110"></el-table-column>
                        <el-table-column prop="buyRate" label="加购占比" min-width="110">
                            <template slot-scope="scope">
                                {{ scope.row.buyRate }}%
                            </template>
                        </el-table-column>
                    </el-table>

                </div>
                <!-- 商品访问排行列表 -->
                <div class="refund_item">
                    <p>商品访问排行列表<router-link class="link" :to="{path: '/statistic/goods', query: { sort: 2 ,date }}">查看详情</router-link>
                    </p>
                    <el-table :data="goodsVisitData" element-loading-text="加载中">
                        <el-table-column prop="goodsTitle" label="商品标题" min-width="110">
                            <template slot-scope="scope">
                                <div class="hidetext" :title="scope.row.goodsTitle" @click="openView( scope.row.goodsUrl )">
                                    {{ scope.row.goodsTitle }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="visitUserNum" label="用户访问数量" min-width="110"></el-table-column>
                        <el-table-column prop="visitUserRate" label="用户访问占比" min-width="110">
                            <template slot-scope="scope">
                                {{ scope.row.visitUserRate }}%
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 下单支付方式排行列表 -->
                <div class="refund_item">
                    <p>下单支付方式排行列表 </p>
                    <el-table :data="payData" v-loading="loading" element-loading-text="加载中">
                        <el-table-column class="title" prop="paymentChannel" label="支付渠道" min-width="110">
                            <template slot-scope="scope">
                                <div class="hidetext" :title="scope.row.paymentChannel">
                                    {{scope.row.paymentChannel}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="orderSuccessNum" label="成功订单数" min-width="110"></el-table-column>
                        <el-table-column label="成功订单占比 " min-width="110">
                            <template slot-scope="scope">
                                {{ scope.row.orderSuccessRate }}%
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 下单来源平台排行列表 -->
                <div class="refund_item">
                    <p>下单来源平台排行列表 </p>
                    <el-table :data="platformBuyData" v-loading="loading" element-loading-text="加载中">
                        <el-table-column prop="platformName" label="来源平台名称" min-width="110">
                            <template slot-scope="scope">
                                <div class="hidetext" :title="scope.row.platformName">
                                    {{scope.row.platformName}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="orderSuccessNum" label="成功订单数" min-width="110"></el-table-column>
                        <el-table-column label="成功订单占比 " min-width="110">
                            <template slot-scope="scope">
                                {{ scope.row.orderSuccessRate }}%
                            </template>
                        </el-table-column>
                    </el-table>

                </div>
                <!-- 访问来源平台排行列表 -->
                <div class="refund_item">
                    <p>访问来源平台排行列表 </p>
                    <el-table :data="platformVisitData" v-loading="loading" element-loading-text="加载中">
                        <el-table-column prop="platformName" label="来源平台名称" min-width="110">
                            <template slot-scope="scope">
                                <div class="hidetext" :title="scope.row.platformName">
                                    {{scope.row.platformName}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="visitUserNum" label="用户访问数量" min-width="110"></el-table-column>
                        <el-table-column prop="visitUserRate" label="用户访问占比" min-width="110">
                            <template slot-scope="scope">
                                {{ scope.row.visitUserRate }}%
                            </template>
                        </el-table-column>
                    </el-table>

                </div>
                <!-- 下单设备排行列表 -->
                <div class="refund_item">
                    <p>下单设备排行列表 </p>
                    <el-table :data="deviceBuyData" v-loading="loading" element-loading-text="加载中">
                        <el-table-column prop="deviceName" label="访问设备名称" min-width="110">
                            <template slot-scope="scope">
                                <div class="hidetext" :title="scope.row.deviceName">{{ scope.row.deviceName }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="orderSuccessNum" label="成功订单数" min-width="110">
                        </el-table-column>
                        <el-table-column label="成功订单占比 " min-width="110">
                            <template slot-scope="scope">
                                {{ scope.row.orderSuccessRate }}%
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 访问来源国家排行列表 -->
                <!-- <div class="refund_item">
                    <p>访问来源国家排行列表 </p>
                    <el-table :data="countryVisitData" v-loading="loading" element-loading-text="加载中">
                        <el-table-column prop="countryName" label="国家名称" min-width="110">
                            <template slot-scope="scope">
                                <div class="hidetext" :title="scope.row.countryName">{{ scope.row.countryName }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="visitUserNum" label="用户访问数量" min-width="110"></el-table-column>
                        <el-table-column prop="visitUserRate" label="用户访问占比" min-width="110">
                            <template slot-scope="scope">
                                {{ scope.row.visitUserRate }}%
                            </template>
                        </el-table-column>
                    </el-table>
                </div> -->
                <!-- 访问设备排行列表 -->
                <div class="refund_item">
                    <p>访问设备排行列表 </p>
                    <el-table :data="deviceVisitData" v-loading="loading" element-loading-text="加载中">
                        <el-table-column prop="deviceName" label="访问设备名称" min-width="110">
                            <template slot-scope="scope">
                                <div class="hidetext" :title="scope.row.deviceName">{{ scope.row.deviceName }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="visitUserNum" label="用户访问数量" min-width="110"></el-table-column>
                        <el-table-column prop="visitUserRate" label="用户访问占比" min-width="110">
                            <template slot-scope="scope">
                                {{ scope.row.visitUserRate }}%
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 页面跳出排行列表 -->
                <!-- <div class="refund_item">
                    <p>页面跳出排行列表<router-link class="link" :to="{path: '/statistic/page', query: { sort: 3 ,date }}">查看详情</router-link>
                    </p>
                    <el-table :data="pageOutData" v-loading="loading" element-loading-text="加载中">
                        <el-table-column prop="pagePath" label="页面路径" min-width="110">
                            <template slot-scope="scope">
                                <div class="hidetext" :title="scope.row.pagePath">
                                    {{scope.row.pagePath}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="outNum" label="跳出数" min-width="110"></el-table-column>
                        <el-table-column prop="outRate" label="跳出率" min-width="110">
                            <template slot-scope="scope">
                                {{ scope.row.outRate }}%
                            </template>
                        </el-table-column>
                    </el-table>

                </div> -->

            </div>
        </div>
        <!-- 业务弹框-重新统计数据 -->
        <el-dialog title="重新统计数据" :visible.sync="dialogStatistic" :close-on-click-modal="false" top="3%" width="35%">
            <el-form :model="afreshStatisticForm" :rules="afreshStatisticRules" status-icon ref="afreshStatisticForm" size="medium">
                <el-form-item label="重新统计时间" prop="date">
                    <el-date-picker v-model="afreshStatisticForm.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="是否统计网站" prop="isWeb">
                    <el-radio v-model="afreshStatisticForm.isWeb" :label="1">是</el-radio>
                    <el-radio v-model="afreshStatisticForm.isWeb" :label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="是否统计页面" prop="isPage">
                    <el-radio v-model="afreshStatisticForm.isPage" :label="1">是</el-radio>
                    <el-radio v-model="afreshStatisticForm.isPage" :label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="是否统计商品" prop="isGoods">
                    <el-radio v-model="afreshStatisticForm.isGoods" :label="1">是</el-radio>
                    <el-radio v-model="afreshStatisticForm.isGoods" :label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="是否统计国家" prop="isCountry">
                    <el-radio v-model="afreshStatisticForm.isCountry" :label="1">是</el-radio>
                    <el-radio v-model="afreshStatisticForm.isCountry" :label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="是否统计来源平台" prop="isPlatform">
                    <el-radio v-model="afreshStatisticForm.isPlatform" :label="1">是</el-radio>
                    <el-radio v-model="afreshStatisticForm.isPlatform" :label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="是否统计设备" prop="isDevice">
                    <el-radio v-model="afreshStatisticForm.isDevice" :label="1">是</el-radio>
                    <el-radio v-model="afreshStatisticForm.isDevice" :label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="是否统计搜索热词" prop="isHot">
                    <el-radio v-model="afreshStatisticForm.isHot" :label="1">是</el-radio>
                    <el-radio v-model="afreshStatisticForm.isHot" :label="0">否</el-radio>
                </el-form-item>

                <el-form-item label="是否统计订单" prop="isOrder">
                    <el-radio v-model="afreshStatisticForm.isOrder" :label="1">是</el-radio>
                    <el-radio v-model="afreshStatisticForm.isOrder" :label="0">否</el-radio>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="afreshStatisticloading" @click="afreshStatisticData">确定</el-button>
                <el-button @click="dialogStatistic = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 搜索热词详情 -->
        <el-dialog title="搜索热词详情" :visible.sync="dialogSearch" :close-on-click-modal="false" fullscreen @closed="resetWordInfo">
            <el-form :inline="true" :model="searchForm" ref="searchForm">
                <el-form-item>
                    <el-input v-model="searchForm.queryText" placeholder="搜索词" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="dateA" value-format="yyyy-MM-dd" :picker-options="pickerOptionsA" :default-value="new Date()"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-select placeholder="是否有结果" clearable v-model="searchForm.isResultType">
                        <el-option v-for="item in isResultTypeOption" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" v-loading="searchLoading" @click="querySearch">查询</el-button>
                    <el-button icon="el-icon-refresh-left" @click="resetSearchForm">重置</el-button>
                </el-form-item>
            </el-form>
            <el-table class="search-table" border :data="searchData" style="width: 100%" v-loading="searchLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading">
                <el-table-column prop="queryText" label="搜索词">
                </el-table-column>
                <el-table-column prop="visitNum" label="搜索次数" sortable>
                </el-table-column>
                <el-table-column prop="visitUserNum" sortable label="搜索用户数">
                </el-table-column>
                <el-table-column prop="visitUserRate" sortable label="次数占比">
                    <template slot-scope="scope">
                        {{scope.row.visitUserRate ? scope.row.visitUserRate : 0}} %
                    </template>
                </el-table-column>
                <el-table-column prop="isResult" :formatter="isResultTypeFormatter" label="是否有结果">
                </el-table-column>
                <el-table-column label="搜索点击率">
                    <template slot-scope="scope">
                        {{scope.row.searchClickRate ? scope.row.searchClickRate : 0}} %
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="searchForm.pageNo" :page-sizes="[20, 50, 100]" :page-size.sync="searchForm.pageSize" :total="searchForm.total" @size-change="submitSearchFormForm" @current-change="submitSearchFormForm">
            </el-pagination>
        </el-dialog>

        <!-- 下单国家排行详情 -->
        <el-dialog title="下单国家排行详情" :visible.sync="dialogCountry" :close-on-click-modal="false" fullscreen @closed="resetWordInfo">
            <el-form :inline="true" :model="countryForm" ref="countryForm">
                <el-form-item>
                    <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="dateA" value-format="yyyy-MM-dd" :picker-options="pickerOptionsA" :default-value="new Date()"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-country" v-loading="countryLoading" @click="queryCountry">查询</el-button>
                    <el-button icon="el-icon-refresh-left" @click="resetCountryForm">重置</el-button>
                </el-form-item>
            </el-form>
            <el-table class="country-table" border :data="countryData" style="width: 100%" v-loading="countryLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" @sort-change="sortTable" :default-sort="{prop: 'orderSuccessNum', order: 'descending'}">
                <el-table-column prop="countryName" label="国家名称" min-width="110">
                    <template slot-scope="scope">
                        <div class="hidetext" :title="scope.row.countryName">
                            {{scope.row.countryName}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="orderSuccessNum" label="成功订单数" min-width="110" sortable="custom"></el-table-column>
                <el-table-column prop="orderSuccessRate" label="成功订单占比 " min-width="110" sortable="custom">
                    <template slot-scope="scope">
                        {{ scope.row.orderSuccessRate }}%
                    </template>
                </el-table-column>
                <el-table-column prop="visitUserNum" label="用户访问数量" min-width="110" sortable="custom"></el-table-column>
                <el-table-column prop="visitUserRate" label="用户访问占比" min-width="110" sortable="custom">
                    <template slot-scope="scope">
                        {{ scope.row.visitUserRate }}%
                    </template>
                </el-table-column>
                <el-table-column prop="orderSuccessAmount" label="订单金额" min-width="110" sortable="custom"></el-table-column>

            </el-table>
            <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="countryForm.pageNo" :page-sizes="[20, 50, 100]" :page-size.sync="countryForm.pageSize" :total="countryForm.total" @size-change="submitCountryFormForm" @current-change="submitCountryFormForm">
            </el-pagination>
        </el-dialog>

    </div>
</template>

<script>
import * as echarts from 'echarts';
// 引入基本模板
// let echarts = require('echarts/lib/echarts')
// 引入柱状图组件
require('echarts/lib/chart/bar');
// 引入提示框和title组件
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');
export default {
    name: 'Index',
    data() {
        return {
            globalColor: [
                '#0770FF',
                '#fd6e00',
                '#fac858',
                '#ee6666',
                '#73c0de',
                '#3ba272',
                '#fc8452',
                '#9a60b4',
                '#ea7ccc',
            ],
            reBuyRateMask: false,
            customerLineMask: false,
            turnoverTrendMask: false,
            refundChartMask: false,
            conversionMask: false,
            relatedChartMask: false,
            visitNumMask: false,
            date: new Date(),
            dateB: new Date(),
            dayRange: 0,
            queryForm: {
                startDate: null,
                endDate: null,
                vsStartDate: null,
                vsEndDate: null,
            },
            activeName: '1',
            total: 0,
            totalVisitUserNum: 0,
            totalOrderNum: 0,
            totalTurnover: 0,
            totalRefundAmount: 0,
            tradingTrendStatisticList: [],
            statisticDate: null,
            // 查询核心指标数据
            conversionRate: 0,
            orderSuccessNum: 0,
            totalTurnover: 0,
            visitUserNum: 0,
            visitDepthAvgNum: 0,
            totalRepurchaseRate: 0,
            //   转化率
            buyNum: 0,
            buyToVisitUserRate: 0,
            checkoutNum: 0,
            checkoutToBuyRate: 0,
            orderNum: 0,
            orderSuccessNum: 0,
            orderSuccessToOrderRate: 0,
            orderSuccessToVisitUserRate: 0,
            orderToCheckoutRate: 0,
            visitUserNum: 0,
            // 查询下单国家排行列表
            countryBuyData: [],
            // 访问来源国家排行列表
            countryVisitData: [],
            // 查询客单价图表

            // 下单来源平台排行列表
            platformBuyData: [],
            // 访问设备排行列表
            deviceVisitData: [],
            // 查询商品加购排行列表
            tableData: [],
            // 查询商品访问量排行列表
            goodsVisitData: [],
            // 查询
            hotVisitData: [],
            // 查询页面跳出排行列表
            pageOutData: [],
            // 查询页面访问排行列表
            pageVisitData: [],
            //查询下单支付方式排行列表
            payData: [],
            // 客单价
            customerAvgPrice: 0,
            // 查询复购率图表
            reBuyAvgRate: 0,
            // 查询退款图表
            refundStatisticList: 0,
            totalRefund: 0,
            totalRelated: 0,
            totalConversionRate: 0,
            // 查询交易趋势图表
            orderSuccessNumTotalList: [],
            turnoverTotalList: [],
            visitUserNumTotalList: [],
            visitNumTotalList: [],
            orderTotalData: [],
            turnoverTotalData: [],
            vistUserNumTotalData: [],
            visitNumTotalData: [],
            visitUserNumList: [],
            adPageVisitUserNum: [],
            visitNumList: [],
            adPageVisitNum: [],
            vsVisitNumList: [],
            vsVisitUserNumList: [],
            turnoverList: [],
            adPageTurnover: [],
            vsTurnoverList: [],
            orderSuccessNumList: [],
            adPageOrderSuccessNum: [],
            vsOrderSuccessNumList: [],
            // 查询访问来源平台排行列表
            platformVisitData: [],
            // 查询下单设备排行列表
            deviceBuyData: [],
            statisticTimeList: [],
            vsStatisticTimeList: [],

            topData: {
                financialCancelNum: 0,
                fulfillmentFailNum: 0,
                recallSuccessNum: 0,
                financialCancelRate: '0%',
                fulfillmentFailRate: '0%',
                recallSuccessRate: '0%',
            },

            coreData: null,
            customData: null,
            rateData: null,
            relatedData: null,
            loading: false,
            statisticDate: null,
            visitTrendStatisticList: [],
            // customerAvgPrice: null,
            customerPriceStatisticList: [],

            reBuyRateStatisticList: [],

            pickerOptions: {
                disabledDate: (time) => {
                    // var lasttime = Date.parse('2021-12-31');
                    // return time.getTime() < lasttime;
                    return time.getTime() > Date.now();
                },
            },
            pickerOptionsB: {
                disabledDate: (time) => {
                    return time.getTime() > Date.now();
                },
            },

            dialogStatistic: false,
            afreshStatisticForm: {},
            afreshStatisticRules: {
                date: [
                    {
                        required: true,
                        message: '请选择开始日期',
                        trigger: 'change',
                    },
                ],
            },
            afreshStatisticloading: false,
            orderSuccessNum: 0,
            searchloading: false,
            orderIsFirst: true,
            visitIsFirst: true,
            searchData: [],
            dialogSearch: false,
            dateA: new Date(),
            searchForm: {
                queryText: null,
                startDate: null,
                endDate: null,
                isResultType: null,
                pageNo: 1,
                pageSize: 20,
                total: 0,
            },
            countryLoading: false,
            countryData: [],
            dialogCountry: false,
            countryForm: {
                startDate: null,
                endDate: null,
                pageNo: 1,
                pageSize: 20,
                total: 0,
                sort: 2,
                sortType: 2,
            },
            sortKeyName: [
                ,
                'orderSuccessNum',
                'orderSuccessRate',
                'visitUserNum',
                'visitUserRate',
                'orderSuccessAmount',
            ],
            pickerOptionsA: {
                disabledDate: (time) => {
                    return time.getTime() > Date.now();
                },
            },
            isResultTypeOption: [
                {
                    value: 2,
                    name: '全部',
                },
                {
                    value: 1,
                    name: '是',
                },
                {
                    value: 0,
                    name: '否',
                },
            ],
            searchLoading: false,
            seriesType: [
                { name: '折线图', value: 1 },
                { name: '柱状图', value: 2 },
            ],
            seriesData: {
                tradingTrend: 2,
                turnoverTrend: 2,
                visitTrend: 2,
                visitNumTrend: 2,
            },
			
			isHandsome: false,
        };
    },
    mounted() {
        this.getToday();
        // this.lastData();
        this.start()
    },
	destroyed(){
		this.$emit('changeBlue', false)
	},
    methods: {
		start(){
			this.queryData();
			if (this.activeName == '1') {
			    this.queryTrading();
			    return;
			}
			if (this.activeName == '2') {
			    this.queryVist();
			    return;
			}
		},
		changeHandsome(e){
			if(e){
				this.$emit('changeBlue', true)
			} else {
				this.$emit('changeBlue', false)
			}
			// console.log(this.isHandsome)
			this.$nextTick(()=>{
				this.start()
			})
		},
        setSeriesType(chart, val) {
            console.log(chart, val);
            switch (chart) {
                case 'tradingTrend':
                    this.tradingDrawLine(
                        this.statisticTimeList,
                        this.orderSuccessNumList,
                        this.vsOrderSuccessNumList,
                        this.vsStatisticTimeList,
                        this.seriesData.tradingTrend,
                        this.adPageOrderSuccessNum,
                        this.orderTotalData,
                        this.orderSuccessNumTotalList
                    );
                    break;
                case 'turnoverTrend':
                    this.turnoverLine(
                        this.statisticTimeList,
                        this.turnoverList,
                        this.vsTurnoverList,
                        this.vsStatisticTimeList,
                        this.seriesData.turnoverTrend,
                        this.adPageTurnover,
                        this.turnoverTotalData,
                        this.turnoverTotalList
                    );
                    break;
                case 'visitTrend':
                    this.visitTrendLine(
                        this.statisticTimeList,
                        this.visitUserNumList,
                        this.vsVisitUserNumList,
                        this.vsStatisticTimeList,
                        this.seriesData.visitTrend,
                        this.adPageVisitUserNum,
                        this.vistUserNumTotalData,
                        this.visitUserNumTotalList
                    );
                    break;
                case 'visitNumTrend':
                    this.visitNumDrawLine(
                        this.statisticTimeList,
                        this.visitNumList,
                        this.vsVisitNumList,
                        this.vsStatisticTimeList,
                        this.seriesData.visitNumTrend,
                        this.adPageVisitNum,
                        this.visitNumTotalData,
                        this.visitNumTotalList
                    );
                    break;
                default:
                    break;
            }
        },
        clearBattle() {
            // this.getToday();
            // this.date = [new Date(), new Date()]
            this.dateB = [];
            this.queryForm.vsStartDate = null;
            this.queryForm.vsEndDate = null;
            this.dayRange = 0;
			this.queryData()
        },
        changeTime(e) {
            // console.log(e);
            this.dateA = e;

            let start = new Date(e[0]).getTime();
            let end = new Date(e[1]).getTime();

            let range = end - start;
            // console.log(this.date)
            this.dayRange = range;
            this.dateB = [];
            this.queryForm.vsStartDate = null;
            this.queryForm.vsEndDate = null;
        },
        changeTimeB(e) {
            // console.log(e)
            let start = new Date(e[0]).getTime();
            let end = new Date(e[1]).getTime();

            let range = end - start;
            let day = this.dayRange / 86400000;
            // console.log(range, this.dayRange, day)

            if (range != this.dayRange) {
                this.$message.warning(
                    `选择的时间范围长度需要一致,当前已选时间范围：${day}天`
                );
                this.dateB = [];
                this.queryForm.vsStartDate = null;
                this.queryForm.vsEndDate = null;
                return false;
            }

            if (e[0] == this.date[0] && e[1] == this.date[1]) {
                this.$message.warning(`不能选择一样的时间范围。`);
                this.dateB = [];
                this.queryForm.vsStartDate = null;
                this.queryForm.vsEndDate = null;
                return false;
            }

            this.queryForm.vsStartDate = e[0];
            this.queryForm.vsEndDate = e[1];
        },
        openView(url) {
            window.open(url);
        },
        getToday() {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }
            let result = year + '-' + month + '-' + day;
            this.queryForm.startDate = result;
            this.queryForm.endDate = result;
            this.date = [result, result];
            this.searchForm.startDate = result;
            this.searchForm.endDate = result;
        },
        async queryData() {
            if (this.date instanceof Array) {
                this.queryForm.startDate = this.date[0];
                this.queryForm.endDate = this.date[1];
            } else {
                this.date = [this.queryForm.startDate, this.queryForm.endDate];
            }
            this.loading = true;
            this.searchloading = true;
            this.statisticTimeList = [];
            // 查询复购率图表
            await this.$http
                .get('/index/getReBuyRateChart', { params: this.queryForm })
                .then(async (response) => {
                    if (response.data.code == 200) {
                        this.loading = false;
                        this.reBuyRateMask = false;
                        this.reBuyAvgRate = response.data.data.reBuyAvgRate;
                        this.reBuyRateStatisticList =
                            response.data.data.reBuyRateStatisticList;
                        if (this.reBuyRateStatisticList.length) {
                            const statisticTimeList = [];
                            const userFirstBuyNumList = [];
                            const userReBuyNumList = [];
                            const vsUserFirstBuyNumList = [];
                            const vsUserReBuyNumList = [];
                            const vsStatisticTimeList = [];
                            this.reBuyRateStatisticList.forEach((item) => {
                                statisticTimeList.push(
                                    item.statisticTime ? item.statisticTime : 0
                                );
                                userFirstBuyNumList.push(
                                    item.userFirstBuyNum
                                        ? item.userFirstBuyNum
                                        : 0
                                );
                                userReBuyNumList.push(
                                    item.userReBuyNum ? item.userReBuyNum : 0
                                );
                            });

                            if (response.data.data.vsReBuyRateStatisticList) {
                                response.data.data.vsReBuyRateStatisticList.forEach(
                                    (item) => {
                                        vsUserFirstBuyNumList.push(
                                            item.userFirstBuyNum
                                                ? item.userFirstBuyNum
                                                : 0
                                        );
                                        vsUserReBuyNumList.push(
                                            item.userReBuyNum
                                                ? item.userReBuyNum
                                                : 0
                                        );
                                        vsStatisticTimeList.push(
                                            item.statisticTime
                                                ? item.statisticTime
                                                : 0
                                        );
                                    }
                                );
                            }

                            this.reBuyRateLine(
                                statisticTimeList,
                                userFirstBuyNumList,
                                userReBuyNumList,
                                vsUserFirstBuyNumList,
                                vsUserReBuyNumList,
                                vsStatisticTimeList
                            );
                        } else {
                            this.reBuyRateMask = true;
                        }

                        return true;
                    } else {
                        return false;
                    }
                });
            //查询退款图表
            // await this.$http
            //     .get('/index/getRefundChart', { params: this.queryForm })
            //     .then(async (response) => {
            //         if (response.data.code == 200) {
            //             this.loading = false;
            //             this.refundStatisticList =
            //                 response.data.data.refundStatisticList;
            //             this.totalRefund = response.data.data.totalRefund;
            //             this.statisticDate = response.data.data.statisticDate;
            //             if (this.refundStatisticList.length) {
            //                 this.refundChartMask = false;
            //                 this.refundList = [];
            //                 this.statisticTimeList = [];
            //                 const vsRefundList = [];
            //                 const vsStatisticTimeList = [];

            //                 this.refundStatisticList.forEach((item) => {
            //                     this.refundList.push(
            //                         item.refund ? item.refund : 0
            //                     );
            //                     this.statisticTimeList.push(
            //                         item.statisticTime ? item.statisticTime : 0
            //                     );
            //                 });

            //                 if (response.data.data.vsRefundStatisticList) {
            //                     response.data.data.vsRefundStatisticList.forEach(
            //                         (item) => {
            //                             vsRefundList.push(
            //                                 item.refund ? item.refund : 0
            //                             );
            //                             vsStatisticTimeList.push(
            //                                 item.statisticTime
            //                                     ? item.statisticTime
            //                                     : 0
            //                             );
            //                         }
            //                     );
            //                 }

            //                 this.refundChartDrawLine(
            //                     this.statisticTimeList,
            //                     this.refundList,
            //                     vsRefundList,
            //                     vsStatisticTimeList
            //                 );
            //             } else {
            //                 this.refundChartMask = true;
            //             }

            //             return true;
            //         } else {
            //             return false;
            //         }
            //     });
            //查询转化率图表
            await this.$http
                .get('/index/getConversionRateChart', {
                    params: this.queryForm,
                })
                .then(async (response) => {
                    if (response.data.code == 200) {
                        this.loading = false;
                        let conversionRateStatistic =
                            response.data.data.conversionRateStatisticList;
                        this.totalConversionRate =
                            response.data.data.conversionRateTotal;
                        this.statisticDate = response.data.data.statisticDate;
                        if (conversionRateStatistic.length) {
                            this.conversionMask = false;
                            const conversionList = [];
                            this.statisticTimeList = [];
                            const vsConversionList = [];
                            const vsStatisticTimeList = [];

                            conversionRateStatistic.forEach((item) => {
                                conversionList.push(
                                    item.conversionRate
                                        ? item.conversionRate
                                        : 0
                                );
                                this.statisticTimeList.push(
                                    item.statisticTime ? item.statisticTime : 0
                                );
                            });

                            if (
                                response.data.data.vsConversionRateStatisticList
                            ) {
                                response.data.data.vsConversionRateStatisticList.forEach(
                                    (item) => {
                                        vsConversionList.push(
                                            item.conversionRate
                                                ? item.conversionRate
                                                : 0
                                        );
                                        vsStatisticTimeList.push(
                                            item.statisticTime
                                                ? item.statisticTime
                                                : 0
                                        );
                                    }
                                );
                            }

                            this.conversionDrawLine(
                                this.statisticTimeList,
                                conversionList,
                                vsConversionList,
                                vsStatisticTimeList
                            );
                        } else {
                            this.conversionMask = true;
                        }

                        return true;
                    } else {
                        return false;
                    }
                });
            //查询连带率图表
            await this.$http
                .get('/index/getRelatedChart', { params: this.queryForm })
                .then(async (response) => {
                    if (response.data.code == 200) {
                        this.loading = false;
                        const relatedStatisticList =
                            response.data.data.relatedStatisticList;
                        this.totalRelated = response.data.data.totalRelatedRate;
                        this.statisticDate = response.data.data.statisticDate;
                        this.relatedData = {
                            vsTotalRelatedRateType:
                                response.data.data.vsTotalRelatedRateType,
                            vsTotalRelatedRate:
                                response.data.data.vsTotalRelatedRate,
                        };
                        if (relatedStatisticList.length) {
                            this.relatedChartMask = false;
                            const relatedList = [];
                            this.statisticTimeList = [];
                            const vsRefundList = [];
                            const vsStatisticTimeList = [];

                            relatedStatisticList.forEach((item) => {
                                relatedList.push(
                                    item.relatedRate ? item.relatedRate : 0
                                );
                                this.statisticTimeList.push(
                                    item.statisticDate ? item.statisticDate : 0
                                );
                            });

                            if (response.data.data.vsRelatedStatisticList) {
                                response.data.data.vsRelatedStatisticList.forEach(
                                    (item) => {
                                        vsRefundList.push(
                                            item.relatedRate
                                                ? item.relatedRate
                                                : 0
                                        );
                                        vsStatisticTimeList.push(
                                            item.statisticDate
                                                ? item.statisticDate
                                                : 0
                                        );
                                    }
                                );
                            }

                            this.relatedChartDrawLine(
                                this.statisticTimeList,
                                relatedList,
                                vsRefundList,
                                vsStatisticTimeList
                            );
                        } else {
                            this.relatedChartMask = true;
                        }

                        return true;
                    } else {
                        return false;
                    }
                });

            //查询客单价图表
            await this.$http
                .get('/index/getCustomerPriceChart', { params: this.queryForm })
                .then(async (response) => {
                    if (response.data.code == 200) {
                        this.loading = false;
                        this.customerLineMask = false;
                        this.customerAvgPrice =
                            response.data.data.customerAvgPrice;
                        this.customerPriceStatisticList =
                            response.data.data.customerPriceStatisticList;
                        this.statisticDate = response.data.data.statisticDate;

                        this.customData = {
                            customerAvgPriceRate:
                                response.data.data.customerAvgPriceRate,
                            customerAvgPriceRateType:
                                response.data.data.customerAvgPriceRateType,
                        };
                        if (this.customerPriceStatisticList.length) {
                            const customerPriceList = [];
                            const statisticTimeList = [];
                            const vsList = [];
                            const vsStatisticTimeList = [];
                            this.customerPriceStatisticList.forEach((item) => {
                                customerPriceList.push(
                                    item.customerPrice ? item.customerPrice : 0
                                );
                                statisticTimeList.push(
                                    item.statisticTime ? item.statisticTime : 0
                                );
                            });

                            if (
                                response.data.data.vsCustomerPriceStatisticList
                            ) {
                                response.data.data.vsCustomerPriceStatisticList.forEach(
                                    (item) => {
                                        vsList.push(
                                            item.customerPrice
                                                ? item.customerPrice
                                                : 0
                                        );
                                        vsStatisticTimeList.push(
                                            item.statisticTime
                                                ? item.statisticTime
                                                : 0
                                        );
                                    }
                                );
                            }

                            this.customerPriceDrawLine(
                                statisticTimeList,
                                customerPriceList,
                                vsList,
                                vsStatisticTimeList
                            );
                        } else {
                            this.customerLineMask = true;
                        }

                        return true;
                    } else {
                        return false;
                    }
                });
            //查询核心指标数据
            await this.$http
                .get('/index/getCoreData', { params: this.queryForm })
                .then(async (response) => {
                    if (response.data.code == 200) {
                        this.loading = false;
                        this.searchloading = false;
                        this.conversionRate = response.data.data.conversionRate;
                        this.orderSuccessNum =
                            response.data.data.orderSuccessNum;
                        this.totalRefundAmount =
                            response.data.data.totalRefundAmount;
                        this.totalTurnover = response.data.data.totalTurnover;
                        this.visitUserNum = response.data.data.visitUserNum;
                        this.visitDepthAvgNum =
                            response.data.data.visitDepthAvgNum;
                        this.totalRepurchaseRate =
                            response.data.data.totalRepurchaseRate;

                        this.coreData = response.data.data.contrastCore;

                        let {
                            financialCancelNum,
                            financialCancelUserNum,
                            financialCancelRate,
                            financialCancelUserRate,
                            fulfillmentFailNum,
                            fulfillmentFailRate,
                            recallSuccessNum,
                            fulfillmentFailUserNum,
                            recallSuccessRate,
                            fulfillmentFailUserRate,
                        } = response.data.data;

                        this.topData = {
                            financialCancelNum,
                            financialCancelUserNum,
                            financialCancelRate,
                            financialCancelUserRate,
                            fulfillmentFailNum,
                            fulfillmentFailUserNum,
                            fulfillmentFailRate,
                            fulfillmentFailUserRate,
                            recallSuccessNum,
                            recallSuccessRate,
                        };

                        return true;
                    } else {
                        return false;
                    }
                })
                .finally(() => {
                    this.lastData();
                    this.queryTrading();
                    this.queryVist();
                });
        },
        async queryTrading() {
            if (this.date instanceof Array) {
                this.queryForm.startDate = this.date[0];
                this.queryForm.endDate = this.date[1];
            } else {
                this.date = [this.queryForm.startDate, this.queryForm.endDate];
            }

            //   this.searchloading = true;
            this.loading = true;
            this.statisticTimeList = [];
            //   查询交易趋势图表
            await this.$http
                .get('/index/getTradingTrendChart', { params: this.queryForm })
                .then(async (response) => {
                    this.loading = false;
                    // this.searchloading = false;
                    this.tradingTrendStatisticList = [];
                    if (response.data.code == 200) {
                        this.tradingTrendStatisticList =
                            response.data.data.tradingTrendStatisticList;
                        if (this.tradingTrendStatisticList.length) {
                            this.turnoverTrendMask = false;
                            this.statisticTimeList = [];
                            this.orderSuccessNumList = [];
                            this.orderSuccessNumTotalList = [];
                            this.adPageOrderSuccessNum = [];
                            this.vsOrderSuccessNumList = [];
                            this.turnoverList = [];
                            this.turnoverTotalList = [];
                            this.adPageTurnover = [];
                            this.vsTurnoverList = [];
                            this.vsStatisticTimeList = [];

                            this.orderTotalData = [];
                            this.turnoverTotalData = [];

                            let orderSuccessNum = 0,
                                adPageOrderSuccessNum = 0,
                                turnoverNum = 0,
                                adPageTurnoverNum = 0;

                            this.tradingTrendStatisticList.forEach((item) => {
                                this.statisticTimeList.push(
                                    item.statisticTime ? item.statisticTime : 0
                                );

                                orderSuccessNum += item.orderSuccessNum
                                    ? item.orderSuccessNum
                                    : 0;
                                this.orderSuccessNumList.push(
                                    item.orderSuccessNum
                                        ? item.orderSuccessNum
                                        : 0
                                );
                                this.orderSuccessNumTotalList.push(
                                    item.orderSuccessNumTotal
                                        ? item.orderSuccessNumTotal
                                        : 0
                                );

                                turnoverNum += item.turnover
                                    ? item.turnover
                                    : 0;
                                this.turnoverList.push(
                                    item.turnover ? item.turnover : 0
                                );
                                this.turnoverTotalList.push(
                                    item.turnoverTotal ? item.turnoverTotal : 0
                                );

                                adPageOrderSuccessNum +=
                                    item.adPageOrderSuccessNum
                                        ? item.adPageOrderSuccessNum
                                        : 0;
                                this.adPageOrderSuccessNum.push(
                                    item.adPageOrderSuccessNum
                                        ? item.adPageOrderSuccessNum
                                        : 0
                                );

                                adPageTurnoverNum += item.adPageTurnover
                                    ? item.adPageTurnover
                                    : 0;
                                this.adPageTurnover.push(
                                    item.adPageTurnover
                                        ? item.adPageTurnover
                                        : 0
                                );
                            });

                            this.orderTotalData = [
                                { value: orderSuccessNum, name: '直接来源' },
                                {
                                    value: adPageOrderSuccessNum,
                                    name: '广告来源',
                                },
                            ];

                            this.turnoverTotalData = [
                                { value: turnoverNum, name: '直接来源' },
                                { value: adPageTurnoverNum, name: '广告来源' },
                            ];

                            // console.log(this.orderTotalData, this.turnoverTotalData)

                            if (
                                response.data.data.vsTradingTrendStatisticList
                            ) {
                                response.data.data.vsTradingTrendStatisticList.forEach(
                                    (item) => {
                                        this.vsOrderSuccessNumList.push(
                                            item.orderSuccessNumTotal
                                                ? item.orderSuccessNumTotal
                                                : 0
                                        );
                                        this.vsTurnoverList.push(
                                            item.turnoverTotal
                                                ? item.turnoverTotal
                                                : 0
                                        );
                                        this.vsStatisticTimeList.push(
                                            item.statisticTime
                                                ? item.statisticTime
                                                : 0
                                        );
                                    }
                                );
                            }
                        } else {
                            this.turnoverTrendMask = true;
                        }

                        // console.log('111',this.statisticTimeList, this.orderSuccessNumList)
                        if (this.activeName == '1') {
                            this.tradingDrawLine(
                                this.statisticTimeList,
                                this.orderSuccessNumList,
                                this.vsOrderSuccessNumList,
                                this.vsStatisticTimeList,
                                this.seriesData.tradingTrend,
                                this.adPageOrderSuccessNum,
                                this.orderTotalData,
                                this.orderSuccessNumTotalList
                            );
                            this.turnoverLine(
                                this.statisticTimeList,
                                this.turnoverList,
                                this.vsTurnoverList,
                                this.vsStatisticTimeList,
                                this.seriesData.turnoverTrend,
                                this.adPageTurnover,
                                this.turnoverTotalData,
                                this.turnoverTotalList
                            );
                        }

                        return true;
                    } else {
                        return false;
                    }
                });
        },
        async queryVist() {
            if (this.date instanceof Array) {
                this.queryForm.startDate = this.date[0];
                this.queryForm.endDate = this.date[1];
            } else {
                this.date = [this.queryForm.startDate, this.queryForm.endDate];
            }
            this.loading = true;
            //   this.searchloading = true;
            this.statisticTimeList = [];
            //查询访问趋势图表
            let a = this.$http
                .get('/index/getVisitTrendChart', { params: this.queryForm })
                .then(async (response) => {
                    this.visitTrendStatisticList = [];
                    if (response.data.code == 200) {
                        this.loading = false;
                        // this.searchloading = false;
                        this.visitTrendStatisticList =
                            response.data.data.visitTrendStatisticList;
                        if (this.visitTrendStatisticList.length) {
                            this.visitNumMask = false;
                            this.statisticTimeList = [];
                            this.visitNumList = [];
                            this.visitNumTotalList = [];
                            this.adPageVisitNum = [];
                            this.visitUserNumList = [];
                            this.visitUserNumTotalList = [];
                            this.adPageVisitUserNum = [];
                            this.vsVisitNumList = [];
                            this.vsVisitUserNumList = [];
                            this.vsStatisticTimeList = [];

                            this.vistUserNumTotalData = [];
                            this.visitNumTotalData = [];

                            let visitUserNum = 0,
                                visitNum = 0,
                                adPageVisitUserNum = 0,
                                adPageVisitNum = 0;

                            this.visitTrendStatisticList.forEach((item) => {
                                this.statisticTimeList.push(
                                    item.statisticTime ? item.statisticTime : 0
                                );

                                visitUserNum += item.visitUserNum
                                    ? item.visitUserNum
                                    : 0;
                                this.visitUserNumList.push(
                                    item.visitUserNum ? item.visitUserNum : 0
                                );
                                this.visitUserNumTotalList.push(
                                    item.visitUserNumTotal
                                        ? item.visitUserNumTotal
                                        : 0
                                );

                                visitNum += item.visitNum ? item.visitNum : 0;
                                this.visitNumList.push(
                                    item.visitNum ? item.visitNum : 0
                                );
                                this.visitNumTotalList.push(
                                    item.visitNumTotal ? item.visitNumTotal : 0
                                );

                                adPageVisitUserNum += item.adPageVisitUserNum
                                    ? item.adPageVisitUserNum
                                    : 0;
                                this.adPageVisitUserNum.push(
                                    item.adPageVisitUserNum
                                        ? item.adPageVisitUserNum
                                        : 0
                                );

                                adPageVisitNum += item.adPageVisitNum
                                    ? item.adPageVisitNum
                                    : 0;
                                this.adPageVisitNum.push(
                                    item.adPageVisitNum
                                        ? item.adPageVisitNum
                                        : 0
                                );
                            });

                            this.vistUserNumTotalData = [
                                { value: visitUserNum, name: '直接来源' },
                                { value: adPageVisitUserNum, name: '广告来源' },
                            ];

                            this.visitNumTotalData = [
                                { value: visitNum, name: '直接来源' },
                                { value: adPageVisitNum, name: '广告来源' },
                            ];

                            if (response.data.data.vsVisitTrendStatisticList) {
                                response.data.data.vsVisitTrendStatisticList.forEach(
                                    (item) => {
                                        this.vsVisitNumList.push(
                                            item.visitNumTotal
                                                ? item.visitNumTotal
                                                : 0
                                        );
                                        this.vsVisitUserNumList.push(
                                            item.visitUserNumTotal
                                                ? item.visitUserNumTotal
                                                : 0
                                        );
                                        this.vsStatisticTimeList.push(
                                            item.statisticTime
                                                ? item.statisticTime
                                                : 0
                                        );
                                    }
                                );
                            }
                        } else {
                            this.visitNumMask = true;
                        }
                        if (this.activeName == '2') {
                            this.visitTrendLine(
                                this.statisticTimeList,
                                this.visitUserNumList,
                                this.vsVisitUserNumList,
                                this.vsStatisticTimeList,
                                this.seriesData.visitTrend,
                                this.adPageVisitUserNum,
                                this.vistUserNumTotalData,
                                this.visitUserNumTotalList
                            );
                            this.visitNumDrawLine(
                                this.statisticTimeList,
                                this.visitNumList,
                                this.vsVisitNumList,
                                this.vsStatisticTimeList,
                                this.seriesData.visitNumTrend,
                                this.adPageVisitNum,
                                this.visitNumTotalData,
                                this.visitNumTotalList
                            );
                        }

                        return true;
                    } else {
                        return false;
                    }
                });
            await a;
        },
        handleClick() {
            if (this.activeName == '1') {
                this.$nextTick(() => {
                    this.queryTrading();
                    this.tradingDrawLine(
                        this.statisticTimeList,
                        this.orderSuccessNumList,
                        this.vsOrderSuccessNumList,
                        this.vsStatisticTimeList,
                        this.seriesData.tradingTrend,
                        this.adPageOrderSuccessNum,
                        this.orderTotalData,
                        this.orderSuccessNumTotalList
                    );
                    this.turnoverLine(
                        this.statisticTimeList,
                        this.turnoverList,
                        this.vsTurnoverList,
                        this.vsStatisticTimeList,
                        this.seriesData.turnoverTrend,
                        this.adPageTurnover,
                        this.turnoverTotalData,
                        this.turnoverTotalList
                    );
                });
            } else {
                this.$nextTick(() => {
                    this.queryVist();
                    this.visitTrendLine(
                        this.statisticTimeList,
                        this.visitUserNumList,
                        this.vsVisitUserNumList,
                        this.vsStatisticTimeList,
                        this.seriesData.visitTrend,
                        this.adPageVisitUserNum,
                        this.vistUserNumTotalData,
                        this.visitUserNumTotalList
                    );
                    this.visitNumDrawLine(
                        this.statisticTimeList,
                        this.visitNumList,
                        this.vsVisitNumList,
                        this.vsStatisticTimeList,
                        this.seriesData.visitNumTrend,
                        this.adPageVisitNum,
                        this.visitNumTotalData,
                        this.visitNumTotalList
                    );
                });
            }
        },

        async lastData() {
            if (this.date instanceof Array) {
                this.queryForm.startDate = this.date[0];
                this.queryForm.endDate = this.date[1];
            } else {
                this.date = [this.queryForm.startDate, this.queryForm.endDate];
            }

            this.statisticTimeList = [];
            this.searchloading = true;
            this.loading = true;
            //查询转化率漏斗
            await this.$http
                .get('/index/getConversionRateFunnel', {
                    params: this.queryForm,
                })
                .then(async (response) => {
                    if (response.data.code == 200) {
                        this.loading = false;
                        this.buyNum = response.data.data.buyNum;
                        this.buyToVisitUserRate =
                            response.data.data.buyToVisitUserRate;
                        this.checkoutNum = response.data.data.checkoutNum;
                        this.checkoutToBuyRate =
                            response.data.data.checkoutToBuyRate;
                        this.orderNum = response.data.data.orderNum;
                        this.orderSuccessNum =
                            response.data.data.orderSuccessNum;
                        this.orderSuccessToOrderRate =
                            response.data.data.orderSuccessToOrderRate;
                        this.orderSuccessToVisitUserRate =
                            response.data.data.orderSuccessToVisitUserRate;
                        this.orderToCheckoutRate =
                            response.data.data.orderToCheckoutRate;
                        this.visitUserNum = response.data.data.visitUserNum;
                        this.rateData = response.data.data;
                        return true;
                    } else {
                        return false;
                    }
                });
            //查询下单国家排行列表
            await this.$http
                .get('/index/getCountryBuyList', { params: this.queryForm })
                .then(async (response) => {
                    if (response.data.code == 200) {
                        this.loading = false;
                        this.countryBuyData = response.data.data;
                        return true;
                    } else {
                        return false;
                    }
                });
            //访问来源国家排行列表
            // await this.$http
            //     .get('/index/getCountryVisitList', { params: this.queryForm })
            //     .then(async (response) => {
            //         if (response.data.code == 200) {
            //             this.loading = false;
            //             this.countryVisitData = response.data.data;

            //             return true;
            //         } else {
            //             return false;
            //         }
            //     });
            //   查询下单设备排行列表
            await this.$http
                .get('/index/getDeviceBuyList', { params: this.queryForm })
                .then(async (response) => {
                    if (response.data.code == 200) {
                        this.loading = false;
                        this.deviceBuyData = response.data.data;

                        return true;
                    } else {
                        return false;
                    }
                });
            //查询下单来源平台排行列表
            await this.$http
                .get('/index/getPlatformBuyList', { params: this.queryForm })
                .then(async (response) => {
                    if (response.data.code == 200) {
                        this.loading = false;
                        this.platformBuyData = response.data.data;

                        return true;
                    } else {
                        return false;
                    }
                });
            //查询访问设备排行列表
            await this.$http
                .get('/index/getDeviceVisitList', { params: this.queryForm })
                .then(async (response) => {
                    if (response.data.code == 200) {
                        this.loading = false;
                        this.deviceVisitData = response.data.data;

                        return true;
                    } else {
                        return false;
                    }
                });
            // 查询商品加购排行列表
            await this.$http
                .get('/index/getGoodsBuyList', { params: this.queryForm })
                .then(async (response) => {
                    if (response.data.code == 200) {
                        this.loading = false;
                        this.tableData = response.data.data;

                        return true;
                    } else {
                        return false;
                    }
                });
            //查询商品访问量排行列表
            await this.$http
                .get('/index/getGoodsVisitList', { params: this.queryForm })
                .then(async (response) => {
                    if (response.data.code) {
                        this.loading = false;
                        this.goodsVisitData = response.data.data;

                        return true;
                    } else {
                        return false;
                    }
                });
            //查询搜索热词排行列表
            await this.$http
                .get('/index/getHotVisitList', { params: this.queryForm })
                .then(async (response) => {
                    if (response.data.code == 200) {
                        this.loading = false;
                        this.hotVisitData = response.data.data;

                        return true;
                    } else {
                        return false;
                    }
                });
            //查询页面跳出排行列表
            // await this.$http
            //     .get('/index/getPageOutList', { params: this.queryForm })
            //     .then(async (response) => {
            //         this.loading = false;
            //         if (response.data.code == 200) {
            //             this.pageOutData = response.data.data;

            //             return true;
            //         } else {
            //             return false;
            //         }
            //     });
            //查询页面访问排行列表
            await this.$http
                .get('/index/getPageVisitList', { params: this.queryForm })
                .then(async (response) => {
                    if (response.data.code == 200) {
                        this.loading = false;
                        this.pageVisitData = response.data.data;

                        return true;
                    } else {
                        return false;
                    }
                });
            //查询下单支付方式排行列表
            await this.$http
                .get('/index/getPayList', { params: this.queryForm })
                .then(async (response) => {
                    if (response.data.code == 200) {
                        this.loading = false;
                        this.payData = response.data.data;

                        return true;
                    } else {
                        return false;
                    }
                });
            //   查询访问来源平台排行列表
            await this.$http
                .get('/index/getPlatformVisitList', { params: this.queryForm })
                .then(async (response) => {
                    this.loading = false;
                    this.searchloading = false;
                    if (response.data.code == 200) {
                        this.platformVisitData = response.data.data;

                        return true;
                    } else {
                        return false;
                    }
                });
        },

        // 订单量
        tradingDrawLine(x, y, vs, z, type, adNum, pieData, total) {
            // console.log(x, y, vs, z, type)
            // 基于准备好的dom，初始化echarts实例
            let tradingTrend = echarts.init(
                document.getElementById('tradingTrend')
            );
            window.onresize = function () {
                tradingTrend.resize();
            };
            //   let base = +new Date(1988, 9, 3);
            let oneDay = 24 * 3600 * 1000;
            let data = [[Math.random() * 300]];
            for (let i = 1; i < 20000; i++) {
                let now = new Date(oneDay);
                data.push([
                    +now,
                    Math.round((Math.random() - 0.5) * 20 + data[i - 1][1]),
                ]);
            }
            this.$nextTick(() => {
                // 绘制图表
                tradingTrend.setOption({
                    title: { 
						text: '订单量' ,
						textStyle: {
							fontSize: 18,
							fontWeight: 600,
							color: this.isHandsome ? '#fff' : '#000'
						}
					},
                    color: this.globalColor,
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
							label: {
								color: this.isHandsome ? '#fff' : '#000'
							}
                        },
                        // position: function (pt) {
                        //     return [pt[0], '10%'];
                        // },
                        // position: function (pos, params, dom, rect, size) {
                        //     var obj = {top: 60};
                        //     obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
                        //     return obj;
                        // },
                        // legend: {
                        //     data: ['sales'],
                        // },
                        formatter: function (param) {
                            // console.log(param)
                            let dataIndex = param[0].dataIndex;
                            let dom = param[0].name;

                            for (let i = 0; i < param.length; i++) {
                                let item = param[i];
                                dom += `<div><span style="color:${item.color}">`;
                                switch (item.seriesIndex) {
                                    case 0:
                                        dom +=
                                            '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #0770FF;"></i>';
                                        break;
                                    case 1:
                                        dom +=
                                            '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #fd6e00;"></i>';
                                        break;
                                    default:
                                        break;
                                }
                                dom += `${item.seriesName}<span>: ${item.value}</div>`;
                                if (type == 1) {
                                    if (i == 0 && z.length) {
                                        dom += z[dataIndex] ? z[dataIndex] : '';
                                        continue;
                                    }
                                    return dom;
                                }
                            }

                            if (type == 2) {
                                return dom;
                            }
                        },
                    },
                    // toolbox: {
                    //     feature: {
                    //     dataZoom: {
                    //         yAxisIndex: 'none'
                    //     },
                    //     restore: {},
                    //     saveAsImage: {}
                    //     }
                    // },
                    grid: {
                        top: 50,
                        right: 10,
                        left: 0,
                        bottom: 50,
                        containLabel: true,
                    },
                    legend: {
                        data:
                            type == 1
                                ? vs.length
                                    ? ['订单量', '对比订单量']
                                    : ['订单量']
                                : ['直接来源', '广告来源'],
						textStyle: {
							color: this.isHandsome ? '#fff' : '#000'
						}
                    },
                    xAxis: {
                        type: 'category',
                        data: x,
                        // axisLine: { onZero: true },
                        splitLine: {
                            show: this.isHandsome ? false : true,
                        },
                    },
                    yAxis: {
                        type: 'value',
                        boundaryGap: [0, '10%'],
                        // axisLine: { onZero: true },
                        minInterval: 1,
                        splitLine: {
                            show: true,
                        },
                    },
                    dataZoom: [
                        {
                            // type: 'inside',
                            start: 0,
                            end: 100,
							show: this.isHandsome ? false : true,
                            // orient: 'vertical',
                        },
                        {
                            start: 0,
                            end: 100,
							show: this.isHandsome ? false : true,
                        },
                        {
                            // type: 'slider',
                            show: this.isHandsome ? false : true,
                            handleSize: 0, //滑动条的 左右2个滑动条的大小
                            startValue: 0, // 初始显示值
                            endValue: 500000, // 结束显示值,自己定
                            height: 5, //组件高度
                            left: '10%', //左边的距离
                            right: '10%', //右边的距离
                            bottom: 15, //底边的距离
                            borderColor: '#ccc',
                            fillerColor: '#4cccfe',
                            borderRadius: 5,
                            backgroundColor: '#efefef', //两边未选中的滑动条区域的颜色
                            showDataShadow: false, //是否显示数据阴影 默认auto
                            showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
                            realtime: true, //是否实时更新
                            filterMode: 'filter',
                        },
                    ],
                    series: [
                        {
                            name: type == 1 ? '订单量' : '直接来源',
                            type: type == 1 ? 'line' : 'bar',
                            barMaxWidth: 20,
                            stack: type == 1 ? '' : 'Ad',
                            itemStyle: {
                                color: '#0770FF',
                            },
							lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                            data: type == 1 ? total : y,
                        },
                        {
                            name: '广告来源',
                            type: 'bar',
                            barMaxWidth: 20,
                            stack: 'Ad',
                            itemStyle: {
                                color: '#fd6e00',
                            },
                            data: type == 1 ? null : adNum,
                        },
                        {
                            name: '对比订单量',
                            type: 'line',
                            lineStyle: {
                                color: '#fd6e00',
                                // type: 'dashed',
                            },
                            symbol: 'rect',
                            itemStyle: {
                                borderWidth: 2,
                                borderColor: '#fd6e00',
                                color: '#fd6e00',
                            },
							lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                            data: type == 1 ? vs : [],
                        },
                        {
                            type: 'pie',
                            silent: true,
                            emptyCircleStyle: {
                                opacity: type == 1 ? 0 : 1,
                            },
                            itemStyle: {
                                opacity: type == 1 ? 0 : 0.6,
                            },
                            label: {
                                formatter: function (params) {
                                    if (params.percent) {
                                        return `${params.name}: ${params.percent}%`;
                                    } else {
                                        return `${params.name}: 0%`;
                                    }
                                },
								color: this.isHandsome ? '#fff' : '#000'
                            },
                            stillShowZeroSum: false,
                            radius: [0, '20%'],
                            center: ['75%', '27%'],
                            data: type == 1 ? null : pieData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                                },
                            },
                        },
                    ],
                });
            });
        },
        // 成交额
        turnoverLine(x, y, vs, z, type, adNum, pieData, total) {
            // 基于准备好的dom，初始化echarts实例
            let turnoverTrend = echarts.init(
                document.getElementById('turnoverTrend')
            );
            window.onresize = function () {
                turnoverTrend.resize();
            };
            let base = +new Date(1988, 9, 3);
            let oneDay = 24 * 3600 * 1000;
            let data = [[base, Math.random() * 300]];
            for (let i = 1; i < 20000; i++) {
                let now = new Date((base += oneDay));
                data.push([
                    +now,
                    Math.round((Math.random() - 0.5) * 20 + data[i - 1][1]),
                ]);
            }
            // 绘制图表
            turnoverTrend.setOption({
                title: { 
					text: '成交额',
					textStyle: {
						fontSize: 18,
						fontWeight: 600,
						color: this.isHandsome ? '#fff' : '#000'
					}
				},
                color: this.globalColor,
                tooltip: {
                    trigger: 'axis',
                    // position: function (pos, params, dom, rect, size) {
                    //     var obj = {top: 60};
                    //     obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
                    //     return obj;
                    // },
                    axisPointer: {
                        type: 'shadow',
                    },
                    // legend: {
                    //     data: ['sales'],
                    // },
                    formatter: function (param) {
                        let dataIndex = param[0].dataIndex;
                        let dom = param[0].name;
                        for (let i = 0; i < param.length; i++) {
                            let item = param[i];
                            dom += `<div><span style="color:${item.color}">`;
                            switch (item.seriesIndex) {
                                case 0:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #0770FF;"></i>';
                                    break;
                                case 1:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #fd6e00;"></i>';
                                    break;
                                default:
                                    break;
                            }
                            dom += `${item.seriesName}<span>: ${item.value}</div>`;
                            if (type == 1) {
                                if (i == 0 && z.length) {
                                    dom += z[dataIndex] ? z[dataIndex] : '';
                                    continue;
                                }
                                return dom;
                            }
                        }

                        if (type == 2) {
                            return dom;
                        }
                    },
                },
                grid: {
                    top: 50,
                    right: 10,
                    left: 0,
                    bottom: 50,
                    containLabel: true,
                },
                legend: {
                    // data: vs.length ? ['成交额', '对比成交额'] : ['成交额'],
                    data:
                        type == 1
                            ? vs.length
                                ? ['成交额', '对比成交额']
                                : ['成交额']
                            : ['直接来源', '广告来源'],
					textStyle: {
						color: this.isHandsome ? '#fff' : '#000'
					}		
                },
                xAxis: {
                    type: 'category',
                    data: x,
                    axisLine: { onZero: false },
                    splitLine: {
                        show: this.isHandsome ? false : true,
                    },
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, '10%'],
                    axisLine: { onZero: false },
                    splitLine: {
                        show: true,
                    },
                },
                dataZoom: [
                    {
                        // type: 'inside',
                        start: 0,
                        end: 100,
						show: this.isHandsome ? false : true,
                        // orient: 'vertical',
                    },
                    {
                        start: 0,
                        end: 100,
						show: this.isHandsome ? false : true,
                    },
                    {
                        type: 'slider',
                        show: this.isHandsome ? false : true,
                        handleSize: 0, //滑动条的 左右2个滑动条的大小
                        startValue: 0, // 初始显示值
                        endValue: 500000, // 结束显示值,自己定
                        height: 5, //组件高度
                        left: '10%', //左边的距离
                        right: '10%', //右边的距离
                        bottom: 15, //底边的距离
                        borderColor: '#ccc',
                        fillerColor: '#4cccfe',
                        borderRadius: 5,
                        backgroundColor: '#efefef', //两边未选中的滑动条区域的颜色
                        showDataShadow: false, //是否显示数据阴影 默认auto
                        showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
                        realtime: true, //是否实时更新
                        filterMode: 'filter',
                    },
                ],
                series: [
                    {
                        name: type == 1 ? '成交额' : '直接来源',
                        type: type == 1 ? 'line' : 'bar',
                        barMaxWidth: 20,
                        stack: type == 1 ? '' : 'Ad',
                        itemStyle: {
                            color: '#0770FF',
                        },
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                        data: type == 1 ? total : y,
                    },
                    {
                        name: '广告来源',
                        type: 'bar',
                        barMaxWidth: 20,
                        stack: 'Ad',
                        itemStyle: {
                            color: '#fd6e00',
                        },
                        data: type == 1 ? null : adNum,
                    },
                    {
                        name: '对比成交额',
                        type: 'line',
                        lineStyle: {
                            color: '#fd6e00',
                            // type: 'dashed',
                        },
                        symbol: 'rect',
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#fd6e00',
                            color: '#fd6e00',
                        },
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                        data: type == 1 ? vs : [],
                    },
                    {
                        type: 'pie',
                        silent: true,
                        emptyCircleStyle: {
                            opacity: type == 1 ? 0 : 1,
                        },
                        itemStyle: {
                            opacity: type == 1 ? 0 : 0.6,
                        },
                        label: {
                            formatter: function (params) {
                                if (params.percent) {
                                    return `${params.name}: ${params.percent}%`;
                                } else {
                                    return `${params.name}: 0%`;
                                }
                            },
							color: this.isHandsome ? '#fff' : '#000'
                        },
                        stillShowZeroSum: false,
                        radius: [0, '20%'],
                        center: ['75%', '27%'],
                        data: type == 1 ? null : pieData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                ],
            });
        },
        // 访问趋势
        visitTrendLine(x, y, vs, z, type, adNum, pieData, total) {
            // 基于准备好的dom，初始化echarts实例
            let visitTrend = echarts.init(
                document.getElementById('visitTrend')
            );
            window.onresize = function () {
                visitTrend.resize();
            };
            let base = +new Date(1988, 9, 3);
            let oneDay = 24 * 3600 * 1000;
            let data = [[base, Math.random() * 300]];
            for (let i = 1; i < 20000; i++) {
                let now = new Date((base += oneDay));
                data.push([
                    +now,
                    Math.round((Math.random() - 0.5) * 20 + data[i - 1][1]),
                ]);
            }
            // 绘制图表
            visitTrend.setOption({
                title: { 
					text: '访问用户',
					textStyle: {
						fontSize: 18,
						fontWeight: 600,
						color: this.isHandsome ? '#fff' : '#000'
					}
				},
                color: this.globalColor,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    legend: {
                        data: ['sales'],
                    },
                    // position: function (pos, params, dom, rect, size) {
                    //     var obj = {top: 60};
                    //     obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
                    //     return obj;
                    // },
                    formatter: function (param) {
                        let dataIndex = param[0].dataIndex;
                        let dom = param[0].name;
                        for (let i = 0; i < param.length; i++) {
                            let item = param[i];
                            dom += `<div><span style="color:${item.color}">`;
                            switch (item.seriesIndex) {
                                case 0:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #0770FF;"></i>';
                                    break;
                                case 1:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #fd6e00;"></i>';
                                    break;
                                default:
                                    break;
                            }
                            dom += `${item.seriesName}<span>: ${item.value}</div>`;
                            if (type == 1) {
                                if (i == 0 && z.length) {
                                    dom += z[dataIndex] ? z[dataIndex] : '';
                                    continue;
                                }
                                return dom;
                            }
                        }

                        if (type == 2) {
                            return dom;
                        }
                    },
                },
                grid: {
                    top: 50,
                    right: 10,
                    left: 0,
                    bottom: 50,
                    containLabel: true,
                },
                legend: {
                    data:
                        type == 1
                            ? vs.length
                                ? ['访问用户', '对比访问用户']
                                : ['访问用户']
                            : ['直接来源', '广告来源'],
					textStyle: {
						color: this.isHandsome ? '#fff' : '#000'
					},		
                },
                xAxis: {
                    type: 'category',
                    data: x,
                    axisLine: { onZero: false },
                    splitLine: {
                        show: this.isHandsome ? false : true,
                    },
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, '10%'],
                    axisLine: { onZero: false },
                    minInterval: 1,
                    splitLine: {
                        show: true,
                    },
                },
                dataZoom: [
                    {
                        // type: 'inside',
                        start: 0,
                        end: 100,
						show: this.isHandsome ? false : true,
                        // orient: 'vertical',
                    },
                    {
                        start: 0,
                        end: 100,
						show: this.isHandsome ? false : true,
                    },
                    {
                        type: 'slider',
                        show: this.isHandsome ? false : true,
                        handleSize: 0, //滑动条的 左右2个滑动条的大小
                        startValue: 0, // 初始显示值
                        endValue: 500000, // 结束显示值,自己定
                        height: 5, //组件高度
                        left: '10%', //左边的距离
                        right: '10%', //右边的距离
                        bottom: 15, //底边的距离
                        borderColor: '#ccc',
                        fillerColor: '#4cccfe',
                        borderRadius: 5,
                        backgroundColor: '#efefef', //两边未选中的滑动条区域的颜色
                        showDataShadow: false, //是否显示数据阴影 默认auto
                        showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
                        realtime: true, //是否实时更新
                        filterMode: 'filter',
                    },
                ],
                series: [
                    {
                        name: type == 1 ? '访问用户' : '直接来源',
                        type: type == 1 ? 'line' : 'bar',
                        barMaxWidth: 20,
                        stack: type == 1 ? '' : 'Ad',
                        itemStyle: {
                            color: '#0770FF',
                        },
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                        data: type == 1 ? total : y,
                    },
                    {
                        name: '广告来源',
                        type: 'bar',
                        barMaxWidth: 20,
                        stack: 'Ad',
                        itemStyle: {
                            color: '#fd6e00',
                        },
                        data: type == 1 ? null : adNum,
                    },
                    {
                        name: '对比访问用户',
                        type: 'line',
                        lineStyle: {
                            color: '#fd6e00',
                            // type: 'dashed',
                        },
                        symbol: 'rect',
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#fd6e00',
                            color: '#fd6e00',
                        },
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                        data: type == 1 ? vs : [],
                    },
                    {
                        type: 'pie',
                        silent: true,
                        emptyCircleStyle: {
                            opacity: type == 1 ? 0 : 1,
                        },
                        itemStyle: {
                            opacity: type == 1 ? 0 : 0.6,
                        },
                        label: {
                            formatter: function (params) {
                                if (params.percent) {
                                    return `${params.name}: ${params.percent}%`;
                                } else {
                                    return `${params.name}: 0%`;
                                }
                            },
							color: this.isHandsome ? '#fff' : '#000'
                        },
                        stillShowZeroSum: false,
                        radius: [0, '20%'],
                        center: ['75%', '27%'],
                        data: type == 1 ? null : pieData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                ],
            });
        },
        //访问量
        visitNumDrawLine(x, y, vs, z, type, adNum, pieData, total) {
            // 基于准备好的dom，初始化echarts实例
            let visitNumTrend = echarts.init(
                document.getElementById('visitNumTrend')
            );
            window.onresize = function () {
                visitNumTrend.resize();
            };
            let base = +new Date(1988, 9, 3);
            let oneDay = 24 * 3600 * 1000;
            let data = [[base, Math.random() * 300]];
            for (let i = 1; i < 20000; i++) {
                let now = new Date((base += oneDay));
                data.push([
                    +now,
                    Math.round((Math.random() - 0.5) * 20 + data[i - 1][1]),
                ]);
            }
            // 绘制图表
            visitNumTrend.setOption({
                title: { 
					text: '访问量',
					textStyle: {
						fontSize: 18,
						fontWeight: 600,
						color: this.isHandsome ? '#fff' : '#000'
					}
				},
                color: this.globalColor,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    // legend: {
                    //     data: ['sales'],
                    // },
                    formatter: function (param) {
                        let dataIndex = param[0].dataIndex;
                        let dom = param[0].name;
                        for (let i = 0; i < param.length; i++) {
                            let item = param[i];
                            dom += `<div><span style="color:${item.color}">`;
                            switch (item.seriesIndex) {
                                case 0:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #0770FF;"></i>';
                                    break;
                                case 1:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #fd6e00;"></i>';
                                    break;
                                default:
                                    break;
                            }
                            dom += `${item.seriesName}<span>: ${item.value}</div>`;
                            if (type == 1) {
                                if (i == 0 && z.length) {
                                    dom += z[dataIndex] ? z[dataIndex] : '';
                                    continue;
                                }
                                return dom;
                            }
                        }

                        if (type == 2) {
                            return dom;
                        }
                    },
                },
                grid: {
                    top: 50,
                    right: 10,
                    left: 0,
                    bottom: 50,
                    containLabel: true,
                },
                legend: {
                    // data: vs.length ? ['访问量', '对比访问量'] : ['访问量'],
                    data:
                        type == 1
                            ? vs.length
                                ? ['访问量', '对比访问量']
                                : ['访问量']
                            : ['直接来源', '广告来源'],
					textStyle: {
						color: this.isHandsome ? '#fff' : '#000'
					},		
                },
                xAxis: {
                    type: 'category',
                    data: x,
                    axisLine: { onZero: false },
                    splitLine: {
                        show: this.isHandsome ? false : true,
                    },
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, '10%'],
                    axisLine: { onZero: false },
                    minInterval: 1,
                    splitLine: {
                        show: true,
                    },
                },
                dataZoom: [
                    {
                        // type: 'inside',
                        start: 0,
                        end: 100,
						show: this.isHandsome ? false : true,
                        // orient: 'vertical',
                    },
                    {
                        start: 0,
                        end: 100,
						show: this.isHandsome ? false : true,
                    },
                    {
                        type: 'slider',
                        show: this.isHandsome ? false : true,
                        handleSize: 0, //滑动条的 左右2个滑动条的大小
                        startValue: 0, // 初始显示值
                        endValue: 500000, // 结束显示值,自己定
                        height: 5, //组件高度
                        left: '10%', //左边的距离
                        right: '10%', //右边的距离
                        bottom: 15, //底边的距离
                        borderColor: '#ccc',
                        fillerColor: '#4cccfe',
                        borderRadius: 5,
                        backgroundColor: '#efefef', //两边未选中的滑动条区域的颜色
                        showDataShadow: false, //是否显示数据阴影 默认auto
                        showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
                        realtime: true, //是否实时更新
                        filterMode: 'filter',
                    },
                ],
                series: [
                    {
                        name: type == 1 ? '访问量' : '直接来源',
                        type: type == 1 ? 'line' : 'bar',
                        barMaxWidth: 20,
                        stack: type == 1 ? '' : 'Ad',
                        itemStyle: {
                            color: '#0770FF',
                        },
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                        data: type == 1 ? total : y,
                    },
                    {
                        name: '广告来源',
                        type: 'bar',
                        barMaxWidth: 20,
                        stack: 'Ad',
                        itemStyle: {
                            color: '#fd6e00',
                        },
                        data: type == 1 ? null : adNum,
                    },
                    {
                        name: '对比访问量',
                        type: 'line',
                        lineStyle: {
                            color: '#fd6e00',
                            // type: 'dashed',
                        },
                        symbol: 'rect',
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#fd6e00',
                            color: '#fd6e00',
                        },
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                        data: type == 1 ? vs : [],
                    },
                    {
                        type: 'pie',
                        silent: true,
                        emptyCircleStyle: {
                            opacity: type == 1 ? 0 : 1,
                        },
                        itemStyle: {
                            opacity: type == 1 ? 0 : 0.6,
                        },
                        label: {
                            formatter: function (params) {
                                if (params.percent) {
                                    return `${params.name}: ${params.percent}%`;
                                } else {
                                    return `${params.name}: 0%`;
                                }
                            },
							color: this.isHandsome ? '#fff' : '#000',
                        },
                        stillShowZeroSum: false,
                        radius: [0, '20%'],
                        center: ['75%', '27%'],
                        data: type == 1 ? null : pieData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                ],
            });
        },
        // 综合数据
        customerPriceDrawLine(x, y, vs, z) {
            // 基于准备好的dom，初始化echarts实例
            let customerLine = echarts.init(
                document.getElementById('customerLine')
            );
            let base = +new Date(1988, 9, 3);
            let oneDay = 24 * 3600 * 1000;
            let data = [[base, Math.random() * 300]];
            for (let i = 1; i < 20000; i++) {
                let now = new Date((base += oneDay));
                data.push([
                    +now,
                    Math.round((Math.random() - 0.5) * 20 + data[i - 1][1]),
                ]);
            }
            // 绘制图表
            customerLine.setOption({
                legend: {
                    data: ['sales'],
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    legend: {
                        data: ['客单价'],
                    },
                    formatter: function (param) {
                        let dataIndex = param[0].dataIndex;
                        let dom = param[0].name;
                        for (let i = 0; i < param.length; i++) {
                            let item = param[i];
                            dom += `<div><span style="color:${item.color}">`;
                            switch (item.seriesIndex) {
                                case 0:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #0770FF;"></i>';
                                    break;
                                case 1:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #fd6e00;"></i>';
                                    break;
                                default:
                                    break;
                            }
                            dom += `${item.seriesName}<span>: ${item.value}</div>`;
                            if (i == 0 && z.length) {
                                dom += z[dataIndex] ? z[dataIndex] : '';
                                continue;
                            }
                        }
                        return dom;
                    },
                },
                grid: {
                    top: 50,
                    right: 10,
                    left: 0,
                    bottom: 50,
                    containLabel: true,
                },
                legend: {
                    data: vs.length ? ['客单价', '对比客单价'] : ['客单价'],
					textStyle: {
						color: this.isHandsome ? '#fff' : '#000'
					},
                },
                xAxis: {
                    type: 'category',
                    data: x,
                    axisLine: { onZero: false },
                    splitLine: {
                        show: this.isHandsome ? false : true,
                    },
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, '10%'],
                    axisLine: { onZero: false },
                    splitLine: {
                        show: true,
                    },
                },
                dataZoom: [
                    {
                        // type: 'inside',
                        start: 0,
                        end: 100,
						show: this.isHandsome ? false : true,
                        // orient: 'vertical',
                    },
                    {
                        start: 0,
                        end: 100,
						show: this.isHandsome ? false : true,
                    },
                    {
                        type: 'slider',
                        show: this.isHandsome ? false : true,
                        handleSize: 0, //滑动条的 左右2个滑动条的大小
                        startValue: 0, // 初始显示值
                        endValue: 500000, // 结束显示值,自己定
                        height: 5, //组件高度
                        left: '10%', //左边的距离
                        right: '10%', //右边的距离
                        bottom: 15, //底边的距离
                        borderColor: '#ccc',
                        fillerColor: '#4cccfe',
                        borderRadius: 5,
                        backgroundColor: '#efefef', //两边未选中的滑动条区域的颜色
                        showDataShadow: false, //是否显示数据阴影 默认auto
                        showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
                        realtime: true, //是否实时更新
                        filterMode: 'filter',
                    },
                ],
                series: [
                    {
                        name: '客单价',
                        type: 'line',
                        itemStyle: {
                            color: '#0770FF',
                        },
                        data: y,
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                    },
                    {
                        name: '对比客单价',
                        type: 'line',
                        lineStyle: {
                            color: '#fd6e00',
                            // type: 'dashed',
                        },
                        symbol: 'rect',
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#fd6e00',
                            color: '#fd6e00',
                        },
                        data: vs,
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                    },
                ],
            });
        },
        // 复购率ine
        reBuyRateLine(x, y, y1, z, z1, z2) {
            // 基于准备好的dom，初始化echarts实例
            let reBuyRate = echarts.init(document.getElementById('reBuyRate'));
            let base = +new Date(1988, 9, 3);
            let oneDay = 24 * 3600 * 1000;
            let data = [[base, Math.random() * 300]];
            for (let i = 1; i < 20000; i++) {
                let now = new Date((base += oneDay));
                data.push([
                    +now,
                    Math.round((Math.random() - 0.5) * 20 + data[i - 1][1]),
                ]);
            }
            // 绘制图表
            reBuyRate.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    // position: function (pos, params, dom, rect, size) {
                    //     var obj = {top: 60};
                    //     obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
                    //     return obj;
                    // },
                    formatter: function (param) {
                        // console.log(param)
                        let dataIndex = param[0].dataIndex;
                        let dom = param[0].name;
                        for (let i = 0; i < param.length; i++) {
                            let item = param[i];
                            dom += `<div><span style="color:${item.color}">`;
                            switch (item.seriesIndex) {
                                case 0:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #0770FF;"></i>';
                                    break;
                                case 1:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #fd6e00;"></i>';
                                    break;
                                case 2:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border: 2px solid #0770FF;background-color: #0770FF;"></i>';
                                    break;
                                case 3:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border: 2px solid #fd6e00;background-color: #fd6e00;"></i>';
                                    break;
                                default:
                                    break;
                            }
                            dom += `${item.seriesName}<span>: ${item.value}</div>`;
                            if (i == 1 && z2.length) {
                                dom += z2[dataIndex] ? z2[dataIndex] : '';
                                continue;
                            }
                        }
                        return dom;
                    },
                },
                grid: {
                    top: 50,
                    right: 10,
                    left: 0,
                    bottom: 50,
                    containLabel: true,
                },
                legend: {
                    data: z.length
                        ? [
                              '首次购买用户数',
                              '复购用户数',
                              '对比首次购买用户数',
                              '对比复购用户数',
                          ]
                        : ['首次购买用户数', '复购用户数'],
					textStyle: {
						color: this.isHandsome ? '#fff' : '#000'
					},	
                },
                // xAxis: {
                //     type: 'category',
                //     data: x,
                //     axisLine: { onZero: false },
                //     splitLine: {
                //         show: true,
                //     },
                // },
                xAxis: [
                    {
                        type: 'category',
                        data: x,
                        axisLine: { onZero: false },
                        splitLine: {
                            show: this.isHandsome ? false : true,
                        },
                    },
                    // {
                    //     type: 'category',
                    //     data: z2,
                    //     axisLine: {
                    //         onZero: false,
                    //         lineStyle: {
                    //             color: '#fd6e00',
                    //         },
                    //     },
                    //     splitLine: {
                    //         show: this.isHandsome ? false : true,
                    //     },
                    // },
                ],
                yAxis: {
                    type: 'value',
                    axisLine: { onZero: false },
                    boundaryGap: [0, '10%'],
                    minInterval: 1,
                    splitLine: {
                        show: true,
                    },
                },
                dataZoom: [
                    {
                        // type: 'inside',
                        start: 0,
                        end: 100,
						show: this.isHandsome ? false : true,
                        // orient: 'vertical',
                    },
                    {
                        start: 0,
                        end: 100,
						show: this.isHandsome ? false : true,
                    },
                    {
                        type: 'slider',
                        show: this.isHandsome ? false : true,
                        handleSize: 0, //滑动条的 左右2个滑动条的大小
                        startValue: 0, // 初始显示值
                        endValue: 500000, // 结束显示值,自己定
                        height: 5, //组件高度
                        left: '10%', //左边的距离
                        right: '10%', //右边的距离
                        bottom: 15, //底边的距离
                        borderColor: '#ccc',
                        fillerColor: '#4cccfe',
                        borderRadius: 5,
                        backgroundColor: '#efefef', //两边未选中的滑动条区域的颜色
                        showDataShadow: false, //是否显示数据阴影 默认auto
                        showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
                        realtime: true, //是否实时更新
                        filterMode: 'filter',
                    },
                ],
                series: [
                    {
                        name: '首次购买用户数',
                        type: 'line',
                        data: y,
                        itemStyle: {
                            color: '#0770FF',
                        },
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                        // tooltip: {
                        //     formatter: function (param) {
                        //         console.log(param)
                        //         return [
                        //             'param' + '<br/>',
                        //             'param' + '<br/>',
                        //             'param' + '<br/>',
                        //             'param' + '<br/>',
                        //             // 'Date: ' + param.name + '<hr size=1 style="margin: 3px 0">',
                        //             // 'Open: ' + param.data[0] + '<br/>',
                        //             // 'Close: ' + param.data[1] + '<br/>',
                        //             // 'Lowest: ' + param.data[2] + '<br/>',
                        //             // 'Highest: ' + param.data[3] + '<br/>'
                        //         ].join('');
                        //     }
                        // }
                    },
                    {
                        name: '复购用户数',
                        type: 'line',
                        itemStyle: {
                            color: '#fd6e00',
                        },
                        data: y1,
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                    },
                    {
                        name: '对比首次购买用户数',
                        type: 'line',
                        lineStyle: {
                            color: '#0770FF',
                            type: 'dashed',
                        },
                        symbol: 'rect',
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#0770FF',
                            color: '#0770FF',
                        },
                        data: z,
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                    },
                    {
                        name: '对比复购用户数',
                        type: 'line',
                        lineStyle: {
                            color: '#fd6e00',
                            type: 'dashed',
                        },
                        symbol: 'rect',
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#fd6e00',
                            color: '#fd6e00',
                        },
                        data: z1,
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                    },
                ],
            });
        },
        // 退款
        refundChartDrawLine(x, y, vs, z) {
            // 基于准备好的dom，初始化echarts实例
            let refundChart = echarts.init(
                document.getElementById('refundChart')
            );
            //   if(refundChart = 'undefined'){
            //       $(".refund-mask").hide()
            //   }
            window.onresize = function () {
                refundChart.resize();
            };
            let base = +new Date(1988, 9, 3);
            let oneDay = 24 * 3600 * 1000;
            let data = [[base, Math.random() * 300]];
            for (let i = 1; i < 20000; i++) {
                let now = new Date((base += oneDay));
                data.push([
                    +now,
                    Math.round((Math.random() - 0.5) * 20 + data[i - 1][1]),
                ]);
            }
            // 绘制图表
            refundChart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    legend: {
                        data: ['sales'],
                    },
                    formatter: function (param) {
                        let dataIndex = param[0].dataIndex;
                        let dom = param[0].name;
                        for (let i = 0; i < param.length; i++) {
                            let item = param[i];
                            dom += `<div><span style="color:${item.color}">`;
                            switch (item.seriesIndex) {
                                case 0:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #0770FF;"></i>';
                                    break;
                                case 1:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #fd6e00;"></i>';
                                    break;
                                default:
                                    break;
                            }
                            dom += `${item.seriesName}<span>: ${item.value}</div>`;
                            if (i == 0 && z.length) {
                                dom += z[dataIndex] ? z[dataIndex] : '';
                                continue;
                            }
                        }
                        return dom;
                    },
                },
                legend: {
                    data: vs.length
                        ? ['退款金额', '对比退款金额']
                        : ['退款金额'],
					textStyle: {
						color: this.isHandsome ? '#fff' : '#000'
					},	
                },
                grid: {
                    top: 50,
                    right: 10,
                    left: 0,
                    bottom: 50,
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    data: x,
                    axisLine: { onZero: false },
                    splitLine: {
                        show: this.isHandsome ? false : true,
                    },
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, '10%'],
                    axisLine: { onZero: false },
                    splitLine: {
                        show: true,
                    },
                },
                dataZoom: [
                    {
                        start: 0,
                        end: 100,
						show: this.isHandsome ? false : true,
                        // orient: 'vertical',
                    },
                    {
                        start: 0,
                        end: 100,
						show: this.isHandsome ? false : true,
                    },
                    {
                        type: 'slider',
                        show: this.isHandsome ? false : true,
                        handleSize: 0, //滑动条的 左右2个滑动条的大小
                        startValue: 0, // 初始显示值
                        endValue: 500000, // 结束显示值,自己定
                        height: 5, //组件高度
                        left: '10%', //左边的距离
                        right: '10%', //右边的距离
                        bottom: 15, //底边的距离
                        borderColor: '#ccc',
                        fillerColor: '#4cccfe',
                        borderRadius: 5,
                        backgroundColor: '#efefef', //两边未选中的滑动条区域的颜色
                        showDataShadow: false, //是否显示数据阴影 默认auto
                        showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
                        realtime: true,
                        filterMode: 'filter',
                    },
                ],
                series: [
                    {
                        name: '退款金额',
                        type: 'line',
                        //   type: 'Funnel',
                        itemStyle: {
                            color: '#0770FF',
                        },
                        //   showSymbol: false,
                        //   clip: true,
                        // areaStyle: {
                        //     color: new echarts.graphic.LinearGradient(
                        //         0,
                        //         0,
                        //         0,
                        //         1,
                        //         [
                        //             {
                        //                 offset: 0,
                        //                 color: 'rgba(58,77,233,0.8)',
                        //             },
                        //             {
                        //                 offset: 1,
                        //                 color: 'rgba(58,77,233,0.3)',
                        //             },
                        //         ]
                        //     ),
                        // },
                        data: y,
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                    },
                    {
                        name: '对比退款金额',
                        type: 'line',
                        lineStyle: {
                            color: '#E6A23C',
                            type: 'dashed',
                        },
                        symbol: 'rect',
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#fd6e00',
                            color: '#fd6e00',
                        },
                        data: vs,
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                    },
                ],
            });
        },
        // 转化率
        conversionDrawLine(x, y, vs, z) {
            // 基于准备好的dom，初始化echarts实例
            let conversion = echarts.init(
                document.getElementById('conversion')
            );
            //   if(refundChart = 'undefined'){
            //       $(".refund-mask").hide()
            //   }
            window.onresize = function () {
                conversion.resize();
            };
            let base = +new Date(1988, 9, 3);
            let oneDay = 24 * 3600 * 1000;
            let data = [[base, Math.random() * 300]];
            for (let i = 1; i < 20000; i++) {
                let now = new Date((base += oneDay));
                data.push([
                    +now,
                    Math.round((Math.random() - 0.5) * 20 + data[i - 1][1]),
                ]);
            }
            // 绘制图表
            conversion.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    legend: {
                        data: ['sales'],
                    },
                    formatter: function (param) {
                        let dataIndex = param[0].dataIndex;
                        let dom = param[0].name;
                        for (let i = 0; i < param.length; i++) {
                            let item = param[i];
                            dom += `<div><span style="color:${item.color}">`;
                            switch (item.seriesIndex) {
                                case 0:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #0770FF;"></i>';
                                    break;
                                case 1:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #fd6e00;"></i>';
                                    break;
                                default:
                                    break;
                            }
                            dom += `${item.seriesName}<span>: ${item.value}%</div>`;
                            if (i == 0 && z.length) {
                                dom += z[dataIndex] ? z[dataIndex] : '';
                                continue;
                            }
                        }
                        return dom;
                    },
                },
                legend: {
                    data: vs.length ? ['转化率', '对比转化率'] : ['转化率'],
					textStyle: {
						color: this.isHandsome ? '#fff' : '#000'
					},
                },
                grid: {
                    top: 50,
                    right: 10,
                    left: 0,
                    bottom: 50,
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    data: x,
                    axisLine: { onZero: false },
                    splitLine: {
                        show: this.isHandsome ? false : true,
                    },
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, '10%'],
                    axisLine: { onZero: false },
                    splitLine: {
                        show: true,
                    },
                },
                dataZoom: [
                    {
                        start: 0,
                        end: 100,
                        show: this.isHandsome ? false : true,
                    },
                    {
                        start: 0,
                        end: 100,
						show: this.isHandsome ? false : true,
                    },
                    {
                        type: 'slider',
                        show: this.isHandsome ? false : true,
                        handleSize: 0, //滑动条的 左右2个滑动条的大小
                        startValue: 0, // 初始显示值
                        endValue: 500000, // 结束显示值,自己定
                        height: 5, //组件高度
                        left: '10%', //左边的距离
                        right: '10%', //右边的距离
                        bottom: 15, //底边的距离
                        borderColor: '#ccc',
                        fillerColor: '#4cccfe',
                        borderRadius: 5,
                        backgroundColor: '#efefef', //两边未选中的滑动条区域的颜色
                        showDataShadow: false, //是否显示数据阴影 默认auto
                        showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
                        realtime: true,
                        filterMode: 'filter',
                    },
                ],
                series: [
                    {
                        name: '转化率',
                        type: 'line',
                        itemStyle: {
                            color: '#0770FF',
                        },
                        data: y,
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                    },
                    {
                        name: '对比转化率',
                        type: 'line',
                        lineStyle: {
                            color: '#fd6e00',
                            // type: 'dashed',
                        },
                        symbol: 'rect',
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#fd6e00',
                            color: '#fd6e00',
                        },
                        data: vs,
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                    },
                ],
            });
        },
        //连带率
        relatedChartDrawLine(x, y, vs, z) {
            // 基于准备好的dom，初始化echarts实例
            let relatedChart = echarts.init(
                document.getElementById('relatedChart')
            );
            //   if(refundChart = 'undefined'){
            //       $(".refund-mask").hide()
            //   }
            window.onresize = function () {
                relatedChart.resize();
            };
            let base = +new Date(1988, 9, 3);
            let oneDay = 24 * 3600 * 1000;
            let data = [[base, Math.random() * 300]];
            for (let i = 1; i < 20000; i++) {
                let now = new Date((base += oneDay));
                data.push([
                    +now,
                    Math.round((Math.random() - 0.5) * 20 + data[i - 1][1]),
                ]);
            }
            // 绘制图表
            relatedChart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    legend: {
                        data: ['sales'],
                    },
                    formatter: function (param) {
                        let dataIndex = param[0].dataIndex;
                        let dom = param[0].name;
                        for (let i = 0; i < param.length; i++) {
                            let item = param[i];
                            dom += `<div><span style="color:${item.color}">`;
                            switch (item.seriesIndex) {
                                case 0:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #0770FF;"></i>';
                                    break;
                                case 1:
                                    dom +=
                                        '<i style="width: 4px;height: 4px;display: inline-block;margin-right: 4px;border-radius: 50%;border: 2px solid #fd6e00;"></i>';
                                    break;
                                default:
                                    break;
                            }
                            dom += `${item.seriesName}<span>: ${item.value}</div>`;
                            if (i == 0 && z.length) {
                                dom += z[dataIndex] ? z[dataIndex] : '';
                                continue;
                            }
                        }
                        return dom;
                    },
                },
                legend: {
                    data: vs.length ? ['连带率', '对比连带率'] : ['连带率'],
					textStyle: {
						color: this.isHandsome ? '#fff' : '#000'
					},
                },
                grid: {
                    top: 50,
                    right: 10,
                    left: 0,
                    bottom: 50,
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    data: x,
                    axisLine: { onZero: false },
                    splitLine: {
                        show: this.isHandsome ? false : true,
                    },
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, '10%'],
                    axisLine: { onZero: false },
                    splitLine: {
                        show: true,
                    },
                },
                dataZoom: [
                    {
                        start: 0,
                        end: 100,
                        show: this.isHandsome ? false : true,
                    },
                    {
                        start: 0,
                        end: 100,
						show: this.isHandsome ? false : true,
                    },
                    {
                        type: 'slider',
                        show: this.isHandsome ? false : true,
                        handleSize: 0, //滑动条的 左右2个滑动条的大小
                        startValue: 0, // 初始显示值
                        endValue: 500000, // 结束显示值,自己定
                        height: 5, //组件高度
                        left: '10%', //左边的距离
                        right: '10%', //右边的距离
                        bottom: 15, //底边的距离
                        borderColor: '#ccc',
                        fillerColor: '#4cccfe',
                        borderRadius: 5,
                        backgroundColor: '#efefef', //两边未选中的滑动条区域的颜色
                        showDataShadow: false, //是否显示数据阴影 默认auto
                        showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
                        realtime: true,
                        filterMode: 'filter',
                    },
                ],
                series: [
                    {
                        name: '连带率',
                        type: 'line',
                        //   type: 'Funnel',
                        itemStyle: {
                            color: '#0770FF',
                        },
                        //   showSymbol: false,
                        //   clip: true,
                        // areaStyle: {
                        //     color: new echarts.graphic.LinearGradient(
                        //         0,
                        //         0,
                        //         0,
                        //         1,
                        //         [
                        //             {
                        //                 offset: 0,
                        //                 color: 'rgba(58,77,233,0.8)',
                        //             },
                        //             {
                        //                 offset: 1,
                        //                 color: 'rgba(58,77,233,0.3)',
                        //             },
                        //         ]
                        //     ),
                        // },
                        data: y,
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                    },
                    {
                        name: '对比连带率',
                        type: 'line',
                        lineStyle: {
                            color: '#fd6e00',
                            // type: 'dashed',
                        },
                        symbol: 'rect',
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#fd6e00',
                            color: '#fd6e00',
                        },
                        data: vs,
						lineStyle: {
							width: this.isHandsome ? 1 : 2
						},
						smooth: this.isHandsome ? true : false,
                    },
                ],
            });
        },
        initStatistic() {
            this.afreshStatisticForm = {
                isWeb: 0,
                isPage: 0,
                isGoods: 0,
                isCountry: 0,
                isPlatform: 0,
                isDevice: 0,
                isHot: 0,
                isOrder: 0,
                date: [],
            };
            this.afreshStatisticloading = false;
            this.dialogStatistic = true;
        },
        afreshStatisticData() {
            this.$refs['afreshStatisticForm'].validate((valid) => {
                if (valid) {
                    this.afreshStatisticloading = true;
                    let afreshData = {
                        isWeb: this.afreshStatisticForm.isWeb,
                        isPage: this.afreshStatisticForm.isPage,
                        isGoods: this.afreshStatisticForm.isGoods,
                        isCountry: this.afreshStatisticForm.isCountry,
                        isPlatform: this.afreshStatisticForm.isPlatform,
                        isDevice: this.afreshStatisticForm.isDevice,
                        isHot: this.afreshStatisticForm.isHot,
                        isOrder: this.afreshStatisticForm.isOrder,
                    };
                    if (
                        this.afreshStatisticForm.date &&
                        this.afreshStatisticForm.date.length > 1
                    ) {
                        afreshData.startDate = this.afreshStatisticForm.date[0];
                        afreshData.endDate = this.afreshStatisticForm.date[1];
                    }
                    this.$http
                        .post('/index/afreshStatistic', afreshData)
                        .then((response) => {
                            if (response.data.code === 200) {
                                this.dialogStatistic = false;
                                this.$message({
                                    message: '执行成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: () => {
                                        this.queryData();
                                    },
                                });
                            }
                        })
                        .finally(() => {
                            this.afreshStatisticloading = false;
                        });
                }
            });
        },
        resetWordInfo() {
            console.log('222');
            // Object.assign(
            //     this.searchForm,
            //     this.$options.data.call(this).searchForm
            // );
            // this.getToday();
            // this.dateA = [this.searchForm.startDate, this.searchForm.endDate];
        },
        searchDetails() {
            // this.getToday();
            // this.dateA = [this.searchForm.startDate, this.searchForm.endDate];

            this.dateA = JSON.parse(JSON.stringify(this.date));

            this.submitSearchFormForm();
            this.dialogSearch = true;
        },
        querySearch() {
            this.searchForm.pageNo = 1;
            this.submitSearchFormForm();
        },
        submitSearchFormForm() {
            this.searchForm.startDate = this.dateA?.[0];
            this.searchForm.endDate = this.dateA?.[1];
            this.searchLoading = true;

            this.$http
                .get('/index/getHotVisitPage', { params: this.searchForm })
                .then((response) => {
                    this.searchLoading = false;
                    if (response.data.code === 200) {
                        this.searchData = response.data.data.records;
                        this.searchForm.total = response.data.data.total;
                    }
                    console.log(this.searchData);
                })
                .catch((error) => {
                    this.searchLoading = false;
                    // this.$message.error(error);
                });
        },
        resetSearchForm() {
            // this.getToday();
            // if (this.dateA instanceof Array) {
            //    this.dateA = [this.searchForm.startDate, this.searchForm.endDate]
            // }
            this.dateA = JSON.parse(JSON.stringify(this.date));
            this.searchForm = this.$options.data().searchForm;
            this.submitSearchFormForm();
        },

        countryDetails() {
            // this.getToday();
            // this.dateA = [this.countryForm.startDate, this.countryForm.endDate];

            this.dateA = JSON.parse(JSON.stringify(this.date));

            this.submitCountryFormForm();
            this.dialogCountry = true;
        },
        queryCountry() {
            this.countryForm.pageNo = 1;
            this.submitCountryFormForm();
        },
        submitCountryFormForm() {
            this.countryForm.startDate = this.dateA?.[0];
            this.countryForm.endDate = this.dateA?.[1];
            this.countryLoading = true;

            this.$http
                .get('/index/getCountryBuyPage', { params: this.countryForm })
                .then((response) => {
                    this.countryLoading = false;
                    if (response.data.code === 200) {
                        this.countryData = response.data.data.records;
                        this.countryForm.total = response.data.data.total;
                    }
                    console.log(this.countryData);
                })
                .catch((error) => {
                    this.countryLoading = false;
                    // this.$message.error(error);
                });
        },
        resetCountryForm() {
            // this.getToday();
            // if (this.dateA instanceof Array) {
            //    this.dateA = [this.countryForm.startDate, this.countryForm.endDate]
            // }
            this.dateA = JSON.parse(JSON.stringify(this.date));
            this.countryForm = this.$options.data().countryForm;
            this.submitCountryFormForm();
        },
        sortTable(info) {
            let { prop = '', order } = info;

            console.log(info);

            if ((order ?? '') !== '') {
                let _key = prop.match(/([^.]+)$/)?.[0],
                    sort = this.sortKeyName.findIndex((cur) => {
                        return cur == _key;
                    }),
                    sortType = order == 'ascending' ? 1 : 2;

                this.countryForm.sort = sort;
                this.countryForm.sortType = sortType;
            } else {
                this.countryForm.sort = '';
                this.countryForm.sortType = '';
            }
            this.countryForm.pageNo = 1;
            this.queryCountry();
        },
        isResultTypeFormatter(row) {
            for (
                let i = 0, len = this.isResultTypeOption.length;
                i < len;
                i++
            ) {
                let item = this.isResultTypeOption[i];
                if (row.isResult === item.value) {
                    return item.name;
                }
            }
            return null;
        },
        pageTo({ path, query }) {
            this.$router.push({
                path,
                query,
            });
        },
    },
};
</script>

<style scoped>
.green {
    color: #08b508;
}
.red {
    color: #f00;
}
.change {
    font-size: 16px;
}
.link {
    text-decoration: none;
    color: #409eff;
    font-size: 16px;
}
.golink {
    margin-left: 15px;
}
.main-wrap /deep/ .el-table .cell {
    text-align: left !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}
.main-wrap /deep/ .el-table .visit .cell {
    text-align: right !important;
}
.main-wrap /deep/ .el-table--border::after,
.el-table--group::after,
.el-table::before {
    background-color: none !important;
    position: initial;
}
.main-wrap {
    /* background: #eef0f5; */
    max-height: 900px;
    overflow-x: auto;
    box-sizing: none;
    min-width: 1300px;
}
body /deep/ .el-main {
    background-color: #eef0f5;
}
.main-wrap /deep/ .el-row {
    display: flex;
}
.title {
    font-size: 20px;
    font-weight: 600;
}
.title span {
    font-size: 14px;
    margin-left: 20px;
    color: rgb(201, 199, 199);
}
.main-wrap /deep/ .el-form.demo-form-inline {
    /* padding: 20px; */
    background: #fff;
}
.main-wrap /deep/ .el-form.demo-form-inline .el-form-item {
    margin-bottom: 0px;
}
/* .indicators {
    height: 200px;
} */
.main-wrap /deep/ .el-row .el-col {
    padding-left: 0px !important;
    padding-right: 0px !important ;
    margin-left: 20px;
}
.el-col {
    border-radius: 4px;
    /* width: 18%; */
    /* height: 100px; */
}
.el-col:first-child {
    margin-left: 10px !important;
}
.el-col:last-child {
    margin-right: 10px !important;
}
.bg-purple-dark {
    background: #fff;
}
.bg-purple {
    background: #fff;
    padding: 10px 15px;
    /* min-height: 108px; */
}
.bg-purple-light {
    background: #fff;
}
.grid-content {
    border-radius: 4px;
    /* height: 100%; */
    position: relative;
}
.row-bg {
    padding: 10px 0;
    background-color: #fff;
}
.bg-purple .number {
    font-size: 22px;
    line-height: 32px;
    min-height: 32px;
}
.bg-purple .none-data {
    font-size: 16px;
    line-height: 32px;
    min-height: 32px;
}
.mask {
    line-height: 500px;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(8, 8, 8, 0.31) */
    font-size: 25px;
    color: #909399;
}
.el-tabs.el-tabs--top {
    padding: 20px;
    background: #fff;
    margin-top: 40px;
}
/* #tradingTrend {
  width: 100%;
  height:500px;
} */
.trend {
    display: flex;
    width: 100%;
    height: 500px;
    position: relative;
}
.trend .trend_item {
    width: 50%;
    /*margin: 0 20px;*/
    height: 450px;
    position: relative;
    padding-top: 20px;
}
.trend .trend_item .topset {
    position: absolute;
    right: 8px;
    top: 10px;
    margin: 0;
    width: 100px;
    z-index: 2;
}
p.inline-block {
    justify-content: center !important;
    align-items: center;
    display: flex;
}
.bg-blue100 {
    background-color: rgb(51, 51, 200);
    --bg-opacity: 1 !important;
    width: 10px;
    height: 10px;
    display: inline-block;
}
.el-tab-pane p {
    text-align: center;
}
.el-tab-pane p::before {
    width: 15px;
    height: 15px;
}
/*  */
.comprehensive {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    height: auto;
}
.integrated {
    background: #fff;
    height: 500px;
    padding: 0;
}
.refund {
    width: 100%;
    margin-top: 20px;
}
.refund_item {
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    min-width: 310px;
}
.num_pirce {
    font-size: 24px;
}
.refund_item p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    align-items: center;
}
.refund_item /deep/ .el-table {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    color: #606266;
    overflow-x: hidden;
    overflow-y: scroll;
    line-height: 30px;
    text-align: center;
    height: 400px;
}
.el-table::-webkit-scrollbar {
    display: none;
}
.el-table::before {
    background-color: none;
}
body /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    display: none;
}
.el-table .cell .el-progress .el-progress-bar /deep/ .el-progress-bar__outer {
    height: 10px !important;
    border-radius: 0px !important;
}
.refund_wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    height: auto;
}
.refund_wrap.special {
    grid-template-columns: 1fr 1fr;
}
.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
    display: none;
}
.el-table__header /deep/ .el-table th.el-table__cell > .cell {
    text-align: start !important;
}
.hidetext {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.chart_title_icon_right_1e09 {
    float: right;
    font-size: 14px;
    color: #cdd0d9 !important;
}
/* .el-icon-question:before{
  content: "\e696";
} */
#refundChart,
#relatedChart,
#conversion {
    height: 350px;
}
.overview_bottom {
    background: #f6f7f9;
    padding: 13px 13px;
    border-radius: 2px;
    margin: 32px 0;
    font-size: 16px;
    position: relative;
    color: #212121;
}
.overview_bottom_data {
    min-width: 150px;
    display: inline-block;
}
.overview_bottom_title {
    width: 60px;
    height: 29px;
    line-height: 34px;
    background: #6f96e8;
    box-shadow: 0 2px 4px 0 rgb(201 210 228 / 89%);
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    float: right;
    position: absolute;
    transform: translateY(-10px);
    right: 20px;
    top: 100%;
    z-index: 2;
}
.overview_bottom_title .change {
    position: absolute;
    left: -82px;
    top: 8px;
}
.overview_bottom_title::after {
    content: '';
    height: 42px;
    width: 42px;
    background-color: #6f96e8;
    z-index: -1;
    display: block;
    position: absolute;
    transform: rotateX(49deg) rotateZ(45deg);
    right: 9px;
    top: 8px;
    box-shadow: 2px 2px 2px 0 rgb(201 210 228 / 89%);
}
.mask-f {
    position: relative;
}
.refund-mask {
    position: absolute;
    width: 100%;
    box-sizing: content-box;
    height: 88%;
    bottom: 0px;
    background: #fff;
    padding-left: 0px;
    left: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: #cdd0d9;
    /* display: none; */
}
.mask {
    position: absolute;
    width: 100%;
    box-sizing: content-box;
    height: 100%;
    bottom: 0px;
    background: #fff;
    padding-left: 0px;
    left: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: #cdd0d9;
}
.refund-thumb {
    position: relative;
	padding: 10px;
}
.search_details {
    display: flex;
    justify-content: space-between;
}
.search_details_item {
    color: #6f96e8;
    cursor: pointer;
}
.search-table {
    height: auto;
}
/* .grid-col{
    height: 120px;
    margin-top: -25px !important;
}
.grid-col .cash_item{
    height: 50%;
    margin-bottom: 10px;
}
.grid-col .cash_item p{
    margin: 8px 0px;
}
.indicators .el-row{
    padding: 15px;
} */
.mt-20 {
    margin-top: 20px;
}

.mod-kpi p {
    margin: 8px 0;
}
.ft-sub {
    font-size: 14px;
    line-height: 32px;
    color: #999;
}
.num-mod {
    color: #999;
    border-top: 1px dashed #ccc;
    padding-top: 10px;
    display: flex;
    line-height: 22px;
}
.num-mod .ft-number {
    margin-left: 20px;
}
.flex-purple {
    /* min-height: 48px; */
    display: flex;
}
.flex-purple .link {
    line-height: 32px;
    margin-left: 30px;
}
.grid-content,
.refund-thumb,
.refund_item {
    box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
}

.main-wrap.handsome {
	background-color: unset;
}
.handsome .title,
.handsome .refund_item p{
	color: #c6d0d2;
}
.handsome .red {
	color: #ff3d46;
}
.handsome .green {
	color: #68ff8b;
}
.handsome .number,
.handsome .red, 
.handsome .green,
.handsome .ft-sub,
.handsome .num-mod,
.handsome .ratenumber,
.handsome .num_pirce,
.handsome .overview_bottom_title,
.handsome .el-table {
	font-family: 'Rajdhani';
	letter-spacing: 1px;
}
.handsome .link {
	background: #7d7b7b;
	padding: 0 10px;
	color: #fff;
	font-size: 14px;
	line-height: 32px;
	display: inline-block;
	font-weight: 500;
}
.handsome .el-form.demo-form-inline,
.handsome .el-tabs.el-tabs--top,
.handsome .grid-content,
.handsome .integrated,
.handsome .refund_item {
	background: none;
	color: #fff;
}
.handsome .bg-purple,
.handsome .integrated,
.handsome .refund_item {
	border: 2px solid #8e9ca0;
}
.handsome .grid-content,
.handsome .refund_item {
	box-shadow: unset;
}
.handsome .golink {
	transform: translateY(-2px);
}
.handsome .el-tabs.el-tabs--top {
	padding: 0;
}
.handsome .bg-purple .number {
	height: 26px;
	min-height: unset;
}
.handsome .flex-purple {
	margin-bottom: 10px;
}
.handsome .trend {
	height: 440px;
}
.handsome .integrated {
	height: 410px;
	padding: 0;
	border: none;
}
.handsome .change {
	margin-top: -10px !important;
}
.handsome .overview_bottom_title .change {
	top: 18px;
}
.handsome .overview_bottom {
	background: #172b36;
	color: #fff;
}
.handsome .overview_bottom_title, 
.handsome .overview_bottom_title:after {
	background-color: #275764;
	box-shadow: 5px 6px 5px 0px #0d191f;
}
.handsome .refund_item {
	padding: 0 10px;
}
.handsome .grid-content,
.handsome .refund-thumb,
.handsome .refund_item {
    box-shadow: unset;
}
.handsome .refund-thumb {
	padding: 0;
}
.handsome .trend_item /deep/ .el-select {
	transform: translateX(-20px);
}
.handsome .trend_item /deep/ .el-input__inner {
	background-color: transparent;
	color: #fff;
}
.handsome /deep/ .el-table {
	background-color: transparent;
}
.handsome /deep/ .el-table thead,
.handsome /deep/ .el-table tr,
.handsome /deep/ .el-table th.el-table__cell {
	background-color: #172b36;
	color: #fff;
}
.handsome /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
	background-color: #7f7f7f;
}
.handsome /deep/ .el-tabs__item {
	color: #fff;
}
.handsome /deep/ .el-tabs__item.is-active {
	color: #fff;
	background: #7d7b7b;
}
.handsome /deep/ .el-loading-mask {
	background-color: rgba(10,18,22,.6);
}

@media (max-width: 1440px) {
    .el-date-editor--daterange.el-input__inner {
        width: 260px;
    }
    .main-wrap .el-form.demo-form-inline .el-form-item {
        margin-bottom: 10px;
    }
    .main-wrap {
        min-width: unset;
    }
    .flex-purple,
    .num-mod {
        flex-wrap: wrap;
    }
    .indicatorsrow {
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .indicatorsrow::before,
    .indicatorsrow::after {
        content: none;
    }
    .main-wrap .el-row .el-col {
        margin-left: 10px;
    }
    .el-col:first-child {
        margin-left: 10px !important;
    }
    .indicatorsrow .el-col {
        flex-basis: 22%;
        width: 22%;
        margin-left: 0;
        margin: 0 10px;
        margin-bottom: 16px;
    }
    .trend {
        flex-wrap: wrap;
        height: auto;
    }
    .trend div {
        width: 100%;
        margin-bottom: 20px;
    }
    .comprehensive {
        grid-template-columns: 1fr;
    }
    .refund_wrap {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 1024px) {
    .indicatorsrow .el-col {
        flex-basis: 28%;
        width: 28%;
    }
    .refund_wrap {
        grid-template-columns: 1fr;
    }
}
@media (max-width: 886px) {
    .indicatorsrow .el-col {
        flex-basis: 45%;
        width: 45%;
    }
}
</style>
