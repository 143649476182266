import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Login from "../views/Login";
import Main from "../views/Main";
import Index from "../views/Index";
import GoodsList from "../views/goods/GoodsList";
import Category from "../views/goods/Category";
import GoodsCollection from "../views/goods/GoodsCollection";
import GoodsComment from "../views/goods/GoodsComment";
import GoodsMakeUp from "../views/goods/GoodsMakeUp";
import GoodsLabel from "../views/goods/GoodsLabel";
import GoodsType from "../views/goods/GoodsType";
import GoodsColor from "../views/goods/GoodsColor";
import Kolposts from "../views/goods/Kolposts";
import OrderList from "../views/order/OrderList";
import OrderAfterSale from "../views/order/OrderAfterSale";
import OrderRecall from "../views/order/OrderRecall"
import CustomerList from "../views/customer/CustomerList";
import CustomerNotice from "../views/customer/CustomerNotice";
import MailConfig from "../views/customer/CustomerMailConfig";
import LogisticsPlan from "../views/logistics/LogisticsPlan";
import LogisticsShipAddress from "../views/logistics/LogisticsShipAddress";
import CampaignFull from "../views/campaign/CampaignFull";
import CampaignCode from "../views/campaign/CampaignCode";
import CampaignBind from "../views/campaign/CampaignBind";
import CampaignSale from "../views/campaign/CampaignSale";
import CampaignReplace from "../views/campaign/CampaignReplace";
import Raffle from "../views/campaign/LuckDraw";
import StatisticPage from "../views/statistic/StatisticPage";
import StatisticGoods from "../views/statistic/StatisticGoods";
import RealTime from "../views/statistic/RealTime";
import GmcStatistic from "../views/statistic/GmcStatistic";
import Material from "../views/material/Material";
import MaterialDiy from "../views/material/Diy";
import TaskCenter from "../views/task/TaskCenter";
import User from "../views/system/User.vue";
import Role from "../views/system/Role.vue";
import Dict from "../views/system/Dict.vue";
import Log from "../views/system/Log.vue";
import AlarmLog from "../views/system/AlarmLog";
import AdPage from "../views/campaign/AdPage.vue";
import Gifts from "../views/campaign/Gifts.vue";
import SpecialTopic from "../views/setting/SpecialTopic.vue";
import Distribution from "../views/campaign/ExclusiveUrl.vue";
import CustomerNoticeCount from "../views/customer/CustomerNoticeCount.vue"; // 邮件数据看板
import Currency from "../views/shop/Currency"
import PaymentChannel from "../views/shop/PaymentChannel";
import gmcSetting from "../views/shop/gmcSetting.vue";
import AdPixel from "../views/shop/AdPixel.vue";
import SetBanner from "../views/shop/SetBanner.vue";
import CustomPage from "../views/shop/CustomPage.vue";
import CustomCode from "../views/shop/CustomCode.vue";
import SetTips from "../views/shop/SetTips.vue";
import Delivery from "../views/statistic/Delivery"
import Blogs from "../views/blogs/Blogs.vue";
import BulletinBoard from "../views/setting/BulletinBoard.vue";
import SysConfig from "../views/shop/SysConfig.vue";
import Giftwithpurchase from "../views/campaign/Giftwithpurchase.vue";
import YouTube from "../views/setting/YouTube.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        redirect: "/login",
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/main",
        name: "Main",
        component: Main,
        children: [{
                path: "/main/index",
                name: "Index",
                component: Index,
            },
            {
                path: "/goods/list",
                name: "GoodsList",
                component: GoodsList,
            },
            {
                path: "/goods/category",
                name: "Category",
                component: Category,
            },
            {
                path: "/goods/collection",
                name: "GoodsCollection",
                component: GoodsCollection,
            },
            {
                path: "/goods/color",
                name: "GoodsColor",
                component: GoodsColor,
            },
            {
                path: "/goods/comment",
                name: "GoodsComment",
                component: GoodsComment,
            },
            {
                path: "/goods/makeup",
                name: "GoodsMakeUp",
                component: GoodsMakeUp,
            },

            {
                path: "/goods/label",
                name: "GoodsLabel",
                component: GoodsLabel,
            },
            {
                path: "/goods/type",
                name: "GoodsType",
                component: GoodsType,
            },
            {
                path: "/order/list",
                name: "OrderList",
                component: OrderList,
            },
            {
                path: "/goods/kolposts",
                name: "Kolposts",
                component: Kolposts,
            },
            {
                path: "/order/after/sale",
                name: "OrderAfterSale",
                component: OrderAfterSale,
            },
            {
                path: "/order/recall",
                name: "OrderRecall",
                component: OrderRecall
            },
            {
                path: "/customer/list",
                name: "CustomerList",
                component: CustomerList,
            },
            {
                path: "/customer/notice",
                name: "CustomerNotice",
                component: CustomerNotice,
            },
            {
                path: "/customer/notice/mailConfig",
                name: "MailConfig",
                component: MailConfig,
            },
            {
                path: "/payment/channel",
                name: "PaymentChannel",
                component: PaymentChannel,
            },
            {
                path: "/logistics/plan",
                name: "LogisticsPlan",
                component: LogisticsPlan,
            },
            {
                path: "/logistics/ship/address",
                name: "LogisticsShipAddress",
                component: LogisticsShipAddress,
            },
            {
                path: "/campaign/full",
                name: "CampaignFull",
                component: CampaignFull,
            },
            {
                path: "/campaign/code",
                name: "CampaignCode",
                component: CampaignCode,
            },
            {
                path: "/campaign/luckDraw",
                name: "Raffle",
                component: Raffle,
            },
            {
                path: "/campaign/bind",
                name: "CampaignBind",
                component: CampaignBind,
            },
            {
                path: "/campaign/sale",
                name: "CampaignSale",
                component: CampaignSale,
            },
            {
                path: "/campaign/replace",
                name: "CampaignReplace",
                component: CampaignReplace,
            },
            {
                path: "/statistic/page",
                name: "StatisticPage",
                component: StatisticPage,
            },
            {
                path: "/statistic/goods",
                name: "StatisticGoods",
                component: StatisticGoods
            },
            {
                path: "/statistic/realTime",
                name: "RealTime",
                component: RealTime
            },
			{
			    path: "/statistic/gmc",
			    name: "GmcStatistic",
			    component: GmcStatistic
			},
            {
                path: "/material/centre",
                name: "Material",
                component: Material,
            },
            {
                path: "/material/diy",
                name: "Diy",
                component: MaterialDiy,
            },
            {
                path: "/task/center",
                name: "TaskCenter",
                component: TaskCenter,
            },
            {
                path: "/system/user",
                name: "User",
                component: User,
            },
            {
                path: "/system/role",
                name: "Role",
                component: Role,
            },
            {
                path: "/system/dict",
                name: "Dict",
                component: Dict,
            },
            {
                path: "/system/log",
                name: "Log",
                component: Log,
            },
            {
                path: "/system/alarm/log",
                name: "AlarmLog",
                component: AlarmLog,
            },
            {
                path: "/setting/adPage",
                name: "AdPage",
                component: AdPage,
            },
            {
                path: "/campaign/gift/card",
                name: "Gifts",
                component: Gifts,
            },
            {
                path: "/campaign/full/give",
                name: "Giftwithpurchase",
                component: Giftwithpurchase,
            },
            {
                path: "/setting/SpecialTopic",
                name: "SpecialTopic",
                component: SpecialTopic,
            },
            {
                path: "/setting/distribution/page",
                name: "Distribution",
                component: Distribution,
            },
            {
                path: "/shop/pixel",
                name: "AdPixel",
                component: AdPixel,
            },
            {
                path: "/shop/banner",
                name: "SetBanner",
                component: SetBanner,
            },
            {
                path: "/setting/bottom",
                name: "CustomPage",
                component: CustomPage,
            },
            {
                path: "/setting/code",
                name: "CustomCode",
                component: CustomCode,
            },
			{
			    path: "/setting/video",
			    name: "YouTube",
			    component: YouTube,
			},
            {
                path: "/shop/orderTip",
                name: "SetTips",
                component: SetTips,
            },
            {
                path: "/gmc/account",
                name: "gmcSetting",
                component: gmcSetting,
            },
            {
                path: "/customer/notice/count",
                name: "CustomerNoticeCount",
                component: CustomerNoticeCount,
            },
            {
                path: "/shop/currency",
                name: "Currency",
                component: Currency,
            },
            {
                path: "/statistic/user/adpage",
                name: "Delivery",
                component: Delivery,
            },
            {
                path: "/shop/blog",
                name: "Blogs",
                component: Blogs
            },
            {
                path: "/shop/bulletinBoard",
                name: "BulletinBoard",
                component: BulletinBoard
            },
            {
                path: "/sys/config",
                name: "SysConfig",
                component: SysConfig
            },
        ],
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.name !== "Login" && !store.state.userAuth) {
        next({
            name: "Login",
        });
    } else if (!to.name) {
        next({
            name: "Index",
        });
    } else {
        next();
    }
});

export default router;