<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>营销管理</el-breadcrumb-item>
            <el-breadcrumb-item>抽奖活动</el-breadcrumb-item>
        </el-breadcrumb>

        <el-tabs v-model="tabsActiveName" @tab-click="handleClick">
            <el-tab-pane label="全部" name="0"></el-tab-pane>
            <el-tab-pane label="未开始" name="1"></el-tab-pane>
            <el-tab-pane label="进行中" name="2"></el-tab-pane>
            <el-tab-pane label="已结束" name="3"></el-tab-pane>
        </el-tabs>

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.campaignName" placeholder="请输入活动名称" clearable></el-input>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData" :loading="loading">查询</el-button>
                <el-button icon="el-icon-refresh-left" @click="resetCondition">重置</el-button>
                <el-button type="success" icon="el-icon-plus" @click="queryAdd">新增</el-button>
            </el-form-item>
        </el-form>
        <!-- 数据列表 -->
        <template>
            <el-table border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" :data="tableData" v-loading="loading">
                <el-table-column prop="id" label="ID" width="80">
                </el-table-column>
                <el-table-column prop="campaignName" label="活动名称" min-width="180">
                </el-table-column>
                <el-table-column prop="createTime" label="活动时间" min-width="180">
                    <template slot-scope="{row}">
                        {{ row.createTime }} - {{ row.endTime || '不限' }}
                    </template>
                </el-table-column>
                <el-table-column prop="campaignStatus" label="活动状态" min-width="180">
                    <template slot-scope="scope">
                        {{ campaignStatusTypes.find((v) => v.value == scope.row.campaignStatus).name }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="200" header-align="center" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" icon="el-icon-edit" @click="initEditCampaignFull(scope.row.id)">编辑</el-button>
                        <el-button size="mini" type="text" icon="el-icon-video-pause" v-if="scope.row.campaignStatus != 3 " @click="stopCampaign(scope.row)">提前结束</el-button>
                        <el-button size="mini" type="text" icon="el-icon-delete" v-if="scope.row.campaignStatus == 3 " @click="deleteThis(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <!-- 新增 -->
        <el-dialog fullscreen :title="isAdding ? '抽奖盘设置' : '编辑抽奖活动'" :visible.sync="dialogAdd" :close-on-click-modal="false" @closed="resetInfo">
            <el-form :model="dialogAddForm" :rules="addRules" status-icon ref="dialogAddForm">
                <section class="basic_information">
                    <h2>基本信息</h2>
                    <el-form-item label="活动名称" prop="campaignName">
                        <el-col :span="11">
                            <el-input placeholder="请输入活动名称" v-model="dialogAddForm.campaignName" clearable></el-input>
                        </el-col>
                    </el-form-item>

                    <div class="ai_fe">
                        <el-form-item label="活动时间" prop="startTime">
                            <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间" v-model="dialogAddForm.startTime" :disabled="!isAdding">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item :span="3" class="px_1" style="color: #bbb"> ～ </el-form-item>
                        <el-form-item prop="endTime">
                            <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间" v-model="dialogAddForm.endTime" :disabled="addRaffleFullEndTime">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item prop="isDefault" class="ml_3">
                            <el-checkbox v-model="addRaffleFullEndTime" @change="
                                ($event) => {
                                    if ($event) dialogAddForm.endTime = null;
                                }
                                ">不限结束时间</el-checkbox>
                        </el-form-item>
                    </div>
                    <div class="frequency">
                        <el-form-item label="  每隔" prop="repeatLuckDrawDays">
                            <el-col :span="6">
                                <el-input-number placeholder="" v-model="dialogAddForm.repeatLuckDrawDays" clearable></el-input-number> 天可以再次抽奖
                            </el-col>
                            <el-form-item>

                            </el-form-item>
                            <el-col :span="6">
                                每周期最多抽 <el-input-number placeholder="" v-model="dialogAddForm.luckDrawNum" clearable :max="10000"></el-input-number> 次
                            </el-col>
                        </el-form-item>
                    </div>

                </section>
                <section class="Lottery_settings">
                    <div class="setting">
                        <h2>抽奖盘设置</h2>
                        <div class="setting_add">
                            <el-form-item label="展示页面" class="isShow">
                                <el-checkbox v-model="dialogAddForm.isShowHomePage">首页</el-checkbox>
                                <el-checkbox v-model="dialogAddForm.isShowListPage">列表页</el-checkbox>
                                <el-checkbox v-model="dialogAddForm.isShowDetailPage">详情页</el-checkbox>
                                <el-checkbox v-model="dialogAddForm.isShowLandingPage">落地页</el-checkbox>
                            </el-form-item>
                            <div class="add">
                                <el-button type="text" @click="addColumn">增加一行</el-button>
                            </div>
                        </div>
                    </div>

                    <el-table border v-loading="dialogLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" ref="dialogAddForm" class="mt_3" stripe :data="dialogAddForm.prizeList" style="width:100%;">
                        <el-table-column prop="prizeType" label="奖品类型" min-width="120px">
                            <template slot-scope="scope">
                                <el-form-item>
                                    <el-select v-model="dialogAddForm.prizeList[scope.$index].prizeType" @change="editChange(scope.$index)">
                                        <el-option v-for="item in prizeTypeOption" :key="item.value" :label="item.name" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </template>

                        </el-table-column>

                        <el-table-column prop="discountCondition" label="优惠门槛" min-width="300px">
                            <template slot-scope="scope">
                                <div v-if="dialogAddForm.prizeList[scope.$index].prizeType == 1 ">
                                    <el-form-item>
                                        <el-select v-model="dialogAddForm.prizeList[scope.$index].discountCondition" :disabled="!isAdding && !scope.row.isAdd && scope.row.initEdit" @change="changePrizeType($event,scope.$index)">
                                            <el-option v-for="(item,index) in discountConditionOption" :key="index" :label="item.name" :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-input :disabled="!isAdding && !scope.row.isAdd && scope.row.initEdit" v-model="scope.row.discountConditionNum" v-if="dialogAddForm.prizeList[scope.$index].discountCondition == 2 || dialogAddForm.prizeList[scope.$index].discountCondition == 3" @input="changeDiscountNum($event,scope)">
                                        <template slot="append" v-if="dialogAddForm.prizeList[scope.$index].discountCondition == 2">件</template>
                                        <template slot="append" v-if="dialogAddForm.prizeList[scope.$index].discountCondition == 3">USD</template>
                                    </el-input>
                                </div>
                                <div v-else></div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="discountType" label="优惠类型" min-width="120px">
                            <template slot-scope="scope">
                                <el-form-item>
                                    <el-select :disabled="!isAdding && !scope.row.isAdd && scope.row.initEdit" v-model="dialogAddForm.prizeList[scope.$index].discountType" v-if="dialogAddForm.prizeList[scope.$index].prizeType == 1 " @change="changeType($event,scope.$index)">
                                        <el-option v-for="item in thresholdOption" :key="item.value" :label="item.name" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="strength" label="优惠力度" min-width="140px">
                            <template slot-scope="scope" v-if="dialogAddForm.prizeList[scope.$index].prizeType == 1 ">
                                <div>
                                    <p v-if="dialogAddForm.prizeList[scope.$index].discountType == 2">固定金额 - </p>
                                    <p v-if="dialogAddForm.prizeList[scope.$index].discountType == 1">折扣 - </p>
                                    <el-input :disabled="!isAdding && !scope.row.isAdd && scope.row.initEdit" v-model="scope.row.discountNum" v-if="dialogAddForm.prizeList[scope.$index].discountType == 1 || dialogAddForm.prizeList[scope.$index].discountType == 2" @input="changeDiscountNum($event, scope)">

                                        <template slot="append" v-if="dialogAddForm.prizeList[scope.$index].discountType == 1">%off</template>
                                        <template slot="append" v-if="dialogAddForm.prizeList[scope.$index].discountType == 2">USD</template>

                                    </el-input>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="endDate" label="优惠码结束时间" min-width="240px">
                            <template slot-scope="scope" v-if="dialogAddForm.prizeList[scope.$index].prizeType == 1 ">
                                <div class="" v-if="dialogAddForm.prizeList[scope.$index].discountType == 1 || dialogAddForm.prizeList[scope.$index].discountType == 2">
                                    <el-form-item class="mb_10">
                                        <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间" :disabled="dialogAddForm.prizeList[scope.$index].addRaffleFullEndDate" v-model="dialogAddForm.prizeList[scope.$index].endTime">
                                        </el-date-picker>
                                    </el-form-item>

                                    <el-form-item prop="endTime" class="ml_3 mb_none">
                                        <el-checkbox v-model="dialogAddForm.prizeList[scope.$index].addRaffleFullEndDate" @change="
                                                ($event) => {
                                                    if ($event) dialogAddForm.prizeList[scope.$index].endTime = null;
                                                }
                                                ">不限结束时间</el-checkbox>
                                    </el-form-item>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column prop="prizeDesc" label="奖品文案" min-width="200px">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.prizeDesc"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="奖品图片" align="center" prop="prizeImage" min-width="140px">
                            <template slot-scope="scope">
                                <el-form-item class="mb_none">
                                    <main class="mod-photo">
                                        <template v-if="dialogAddForm.prizeList[scope.$index].prizeImage">
                                            <section class="ImageBox">
                                                <el-image fit="cover" class="Image" :src="dialogAddForm.prizeList[scope.$index].prizeImage">
                                                    <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                                                </el-image>
                                                <div class="mask_d5"></div>
                                                <el-button type="danger" icon="el-icon-delete" circle class="ImageDeleteButton" @click="dialogAddForm.prizeList[scope.$index].prizeImage = null"></el-button>
                                            </section>
                                        </template>
                                        <i v-else class="el-icon-plus img-uploader-icon ImageAddButton" @click="openImageUploader( 'dialogAddForm' , scope.$index)"></i>
                                    </main>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="weight" label="权重" min-width="120px">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.weight" oninput="value=value.replace(/[^\d]/g,'')" @change="weightChange()"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="probabilityStr" label="概率" min-width="120px">
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="200" header-align="center" align="center">
                            <template slot-scope="scope">
                                <el-button size="mini" type="text" icon="el-icon-delete" @click="deleteThisColumn(scope.row.id, scope.$index)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                </section>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogAdd = false">取 消</el-button>
                <el-button type="primary" :loading="campaignLoading" @click="dialogFormSendGoods">确 定</el-button>
            </div>
        </el-dialog>

        <el-pagination background :current-page.sync="queryForm.pageNo" :page-sizes="[10, 30, 50]" :page-size.sync="queryForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>
        <!-- 插入图片 -->
        <ImageUploader ref="uploadImage" :insertImage="dialogInsertImage" :insertType="insertType" :activeName="activeName" @getOpenState="getOpenState" @getImage="getImage">
        </ImageUploader>

    </div>
</template>
<script>
import ImageUploader from '@/components/ImageUploader';
export default {
    name: 'Raffle',
    components: { ImageUploader },
    data() {
        return {
            tabsActiveName: 0,

            loading: false,
            queryForm: {
                campaignStatus: '',
                dialogAddForm: null,
                pageNo: 1,
                pageSize: 10,
            },
            total: 0,
            tableData: [],
            campaignStatusTypes: [
                { name: '未开始', value: '1' },
                { name: '进行中', value: '2' },
                { name: '已结束', value: '3' },
            ],
            prizeTypeOption: [
                {
                    name: '优惠码',
                    value: 1,
                },
                {
                    name: '不中奖',
                    value: 0,
                },
            ],
            discountConditionOption: [
                {
                    name: '无门槛',
                    value: 1,
                },
                {
                    name: '消费满件数',
                    value: 2,
                },
                {
                    name: '消费满金额',
                    value: 3,
                },
            ],
            thresholdOption: [
                {
                    name: '折扣',
                    value: 1,
                },
                {
                    name: '减固定金额',
                    value: 2,
                },
            ],
            dialogAdd: false,
            isAdding: false,

            campaignLoading: false,
            dialogAddForm: {
                startTime: null,
                endTime: null,
                startDate: null,
                isShowHomePage: 0,
                isShowDetailPage: 0,
                isShowListPage: 0,
                isShowLandingPage: 0,
                campaignName: '',
                repeatLuckDrawDays: null,
                luckDrawNum: null,

                prizeList: [
                    {
                        endTime: null,
                        prizeType: null,
                        discountCondition: null,
                        discountConditionNum: null,
                        discountConditionUnit: null,
                        discountNum: null,
                        discountUnit: null,
                        prizeDesc: null,
                        prizeImage: null,
                        weight: null,
                        probabilityStr: null,
                        probability: null,
                    },
                ],
            },

            addRules: {},
            addRaffleFullEndTime: false,
            addRaffleFullEndDate: false,
            dialogLoading: false,
            selectedGoodsTable: {
                data: [],
                total: 0,
            },
            /* 插入图片参数 */
            dialogInsertImage: false,
            activeName: 'first',
            insertType: null,
            insertIndex: null,

            /* 插入图片参数 end */
            editId: null,
        };
    },

    mounted() {
        this.queryData();
    },
    methods: {
        // 插入图片方法
        openImageUploader(obj, index) {
            this.insertType = obj;
            this.insertIndex = index;
            this.dialogInsertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        getOpenState(result) {
            this.dialogInsertImage = result;
        },
        getImage(obj, image) {
            switch (obj) {
                case 'dialogAddForm':
                    this.dialogAddForm.prizeList[this.insertIndex].prizeImage =
                        image[image.length - 1];
                    break;
            }
            // this.dialogInsertImage = false;
        },
        /* 插入图片方法 end */

        handleClick() {
            this.queryForm.campaignStatus = +this.tabsActiveName || '';
            this.queryData();
        },

        resetInfo() {
            Object.assign(
                this.dialogAddForm,
                this.$options.data.call(this).dialogAddForm
            );
        },

        queryData() {
            this.loading = true;
            this.$http
                .get('/campaign/luckDraw/getPage', { params: this.queryForm })
                .then((response) => {
                    this.loading = false;
                    if (response.data.code === 200) {
                        this.tableData = response.data.data;
                        this.total = response.data.count;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    // this.$message.error(error);
                });
        },
        resetCondition() {
            this.queryForm.dialogAddForm = null;
            this.queryForm.campaignName = null;
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        queryAdd() {
            this.dialogAdd = true;
            this.isAdding = true;
            this.campaignLoading = false;
        },
        changeType(e, i) {
            this.dialogAddForm.prizeList[i]['discountUnit'] =
                e === 1 ? '%off' : 'USD';
        },
        changePrizeType(e, i) {
            this.dialogAddForm.prizeList[i]['discountConditionUnit'] =
                e === 2 ? '%off' : 'USD';
        },
        // 新增抽奖活动
        dialogFormSendGoods() {
            this.$refs.dialogAddForm.validate((valid) => {
                if (valid) {
                    if (
                        this.dialogAddForm.prizeList.length > 11 ||
                        this.dialogAddForm.prizeList.length < 2
                    ) {
                        return this.$message.error(
                            '数据不可少于2条且超过12条!'
                        );
                    }

                    if (this.dialogAddForm.prizeList.length % 2) {
                        return this.$message.warning('数据必须为偶数！');
                    }

                    if (
                        !this.dialogAddForm.isShowDetailPage &&
                        !this.dialogAddForm.isShowHomePage &&
                        !this.dialogAddForm.isShowListPage &&
                        !this.dialogAddForm.isShowLandingPage
                    ) {
                        return this.$message.warning('请至少勾选一个承载页');
                    }

                    if (
                        this.dialogAddForm.prizeList.reduce((prev, cur) => {
                            return prev + +cur?.weight;
                        }, 0) > 1e4
                    ) {
                        return this.$message.warning('累加的权重不能超过10000');
                    }

                    if (
                        !this.dialogAddForm.prizeList.reduce((prev, cur) => {
                            return prev + +cur?.weight;
                        }, 0)
                    ) {
                        return this.$message.warning('累加的权重不能等于0');
                    }

                    // console.log(this.dialogAddForm.prizeList.length)
                    this.campaignLoading = true;

                    let url;
                    let str;
                    if (this.dialogAddForm.id) {
                        url = '/campaign/luckDraw/edit';
                        str = '编辑';
                    } else {
                        url = '/campaign/luckDraw/add';
                        str = '新增';
                    }

                    let {
                            isShowHomePage,
                            isShowListPage,
                            isShowDetailPage,
                            isShowLandingPage,
                            ...args
                        } = this.dialogAddForm,
                        params = {
                            ...args,
                            isShowHomePage: +isShowHomePage,
                            isShowListPage: +isShowListPage,
                            isShowDetailPage: +isShowDetailPage,
                            isShowLandingPage: +isShowLandingPage,
                        };

                    this.$http
                        .post(url, params)
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message.success(str + '成功');
                                this.queryData();
                            }
                        })
                        .finally(() => {
                            this.campaignLoading = false;
                            // this.dialogAdd = false;
                        });
                }
            });
        },
        editChange(index) {
            this.dialogAddForm.prizeList[index].initEdit = false;
        },
        // 编辑
        initEditCampaignFull(id) {
            this.campaignLoading = true;
            // this.addRaffleFullEndTime = this.dialogAddForm.endTime
            //     ? false
            //     : true;
            // this.addRaffleFullEndDate = this.addRaffleFullEndDate
            //     ? false
            //     : true;
            // console.log(this.addRaffleFullEndTime, this.addRaffleFullEndDate);
            this.editId = id;
            this.$http
                .get('/campaign/luckDraw/detail?id=' + id)
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data;
                        let list = response.data.data.prizeList;
                        // console.log(list,this.dialogAddForm.isShowDetailPage)
                        list.forEach((row) => {
                            row.addRaffleFullEndDate = !row.endTime; // 不限结束时间
                            row.initEdit = true;
                            row.remark = null;
                        });

                        this.dialogAddForm = {
                            id: id,
                            campaignName: data.campaignName,
                            startTime: data.startTime,
                            endTime: data.endTime,
                            luckDrawNum: data.luckDrawNum,
                            repeatLuckDrawDays: data.repeatLuckDrawDays,
                            isShowHomePage:
                                data.isShowHomePage == 1 ? true : false,
                            isShowDetailPage:
                                data.isShowDetailPage == 1 ? true : false,
                            isShowListPage:
                                data.isShowListPage == 1 ? true : false,
                            prizeList: list,
                            isShowLandingPage: !!data.isShowLandingPage,
                        };

                        this.addRaffleFullEndTime = !data.endTime;
                        this.isAdding = false;
                        this.campaignLoading = false;
                        this.dialogAdd = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        // 提前结束
        stopCampaign(item) {
            this.$confirm('确定要结束活动吗？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.$http
                    .get('/campaign/luckDraw/end', { params: { id: item.id } })
                    .then((r) => {
                        if (r.data.code === 200) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1000,
                                onClose: this.queryData,
                            });
                            this.queryData();
                        }
                    })
                    .catch((err) => {
                        // this.$message.error(error);;
                    });
            });
        },
        // 删除满减活动
        deleteThis(id) {
            this.$confirm('确定要删除活动吗？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.$http
                    .delete('/campaign/luckDraw/delete?id=' + id)
                    .then((r) => {
                        if (r.data.code === 200) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                                duration: 1000,
                                // onClose: this.queryData,
                            });
                        }
                        this.queryData();
                    });
            });
        },
        // 新增一行
        addColumn() {
            if (this.dialogAddForm.prizeList.length > 11) {
                return this.$message.error('数据不可超过12条!');
            }

            this.dialogAddForm.prizeList.push({
                isAdd: 1,
                endTime: null,
                prizeType: null,
                discountCondition: null,
                discountConditionNum: null,
                discountConditionUnit: null,
                discountNum: null,
                discountUnit: null,
                prizeDesc: null,
                prizeImage: null,
                weight: null,
                probabilityStr: null,
                probability: null,
            });
        },
        // 删除一行

        deleteThisColumn(id, index) {
            this.$confirm('确定要删除奖品吗？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                // if (id != undefined) {
                //     this.$http
                //         .delete('/campaign/luckDraw/deletePrize?id=' + id)
                //         .then((r) => {
                //             if (r.status === 200) {
                //                 this.$message({
                //                     message: '删除成功',
                //                     type: 'success',
                //                     duration: 1000,
                //                     // onClose: this.initEditCampaignFull,
                //                 });
                //                 // this.initEditCampaignFull(this.editId);
                //                 this.dialogAddForm.prizeList.splice(index);
                //             }
                //         });
                // } else {
                this.dialogAddForm.prizeList.splice(index, 1);
                // }
            });
        },
        percentFormat(arg1, arg2 = 100) {
            var m = 0,
                s1 = arg1.toString(),
                s2 = arg2.toString();
            try {
                m += s1.split('.')[1].length;
            } catch (e) {}
            try {
                m += s2.split('.')[1].length;
            } catch (e) {}
            return (
                (
                    (Number(s1.replace('.', '')) *
                        Number(s2.replace('.', ''))) /
                        Math.pow(10, m) || 0
                ).toFixed(2) + '%'
            );
        },
        weightChange() {
            // let list = this.dialogAddForm.prizeList.map((v) => +v.weight || 0);
            this.addLoading = true;

            let total = this.dialogAddForm.prizeList.reduce((prev, cur) => {
                return prev + +cur.weight;
            }, 0);

            this.dialogAddForm.prizeList.forEach((item, i) => {
                item.probabilityStr = this.percentFormat(item.weight / total);
            });
            // this.$http
            //     .post('/campaign/luckDraw/getProbability', list)
            //     .then((response) => {
            //         if (response.data.code == 200) {
            //             this.dialogAddForm.prizeList.forEach(
            //                 (item, i) =>
            //                     (item.probabilityStr = response.data.data[i])
            //             );
            //         }
            //         this.$forceUpdate();
            //     });

            // this.$forceUpdate();
        },

        changeDiscountNum() {},
        // changeDiscountNum (e, scope) {
        //     console.log(e)
        //     if ((parseFloat(e).toString() == "NaN")) {
        //         this.$message.warning('请输入数字。')
        //         scope.row.discountNum = ''
        //         console.log(scope.row.discountNum)
        //     }
        //     if (this.dialogAddForm.discountType == 1) {
        //         if (parseInt(e) > 99 || parseInt(e) < 1) {
        //             this.$message.warning('折扣范围为1-99。')
        //             scope.row.discountNum = ''
        //         }
        //     }
        //     if (this.dialogAddForm.discountType == 2) {
        //         let s = source.split('~')
        //         console.log(s[0], s[1])
        //         if (s[1]) {
        //             if (e >= parseFloat(s[0])) {
        //                 this.$message.warning('折扣价不能大于等于最小原价。')
        //                 scope.row.discountNum = ''
        //             }
        //         } else {
        //             if (e >= parseFloat(s[0])) {
        //                 this.$message.warning('折扣价不能大于等于原价。')
        //                 scope.row.discountNum = ''
        //             }
        //         }
        //     }
        //     if (this.dialogAddForm.discountType == 3) {
        //         let s = source.split('~')
        //         console.log(s[0], s[1])
        //         if (s[1]) {
        //             if (e >= parseFloat(s[0])) {
        //                 this.$message.warning('折后价不能大于等于最小原价。')
        //                 scope.row.discountNum = ''
        //             }
        //         } else {
        //             if (e >= parseFloat(s[0])) {
        //                 this.$message.warning('折后价不能大于等于原价。')
        //                 scope.row.discountNum = ''
        //             }
        //         }
        //     }

        // },
    },
};
</script>

<style scoped>
.main-wrap /deep/ .el-dialog.is-fullscreen {
    background: #f2f2f2 !important;
}
/* .el-dialog p{
    font-size: 22px;
    font-weight: bold;
} */
.el-dialog .basic_information,
.Lottery_settings {
    background: #fff;
    padding: 20px;
}
.basic_information /deep/ .el-form-item__label {
    width: 130px;
}
.setting /deep/ .el-form-item__label {
    width: 130px;
}
.el-input-number {
    width: 100px;
    margin-right: 10px;
}
.frequency /deep/ .el-form-item__content {
    display: flex;
}
.Lottery_settings {
    margin-top: 20px;
}
.ai_fe {
    display: flex;
}
.ai_fe .el-form-item {
    display: flex;
}
.px_1 /deep/ .el-form-item__content {
    width: 20px !important;
}
.ImageBox {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    position: relative;
}
::v-deep .ImageBox img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.ImageDeleteButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

.ImageBox > .mask_d5 {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.ImageBox:hover > .mask_d5,
.ImageBox:hover .ImageDeleteButton {
    display: block;
    opacity: 1;
}
/* .el-button.is-circle{
    position: absolute;
    left: 0px;
} */
.setting_add {
    display: flex;
    justify-content: space-between;
}
.isShow {
    display: flex;
}
.add {
    color: rgb(145, 168, 245);
}

.mb_none {
    margin-bottom: 0;
}
.mb_10 {
    margin-bottom: 10px;
}
::v-deep .img-uploader-icon {
    width: 100px;
    height: 100px;
}

.mod-photo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
