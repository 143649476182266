<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商品管理</el-breadcrumb-item>
            <el-breadcrumb-item>商品分类</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 查询条件 -->
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item>
                <el-input v-model="queryForm.categoryNameCn" placeholder="分类中文名" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="queryForm.categoryNameEn" placeholder="分类英文名" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData" v-if="$auth($authData.goods.categoryAdd)">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="initAddCategory()" v-if="$auth($authData.goods.categoryAdd)">新增一级分类</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表 -->
        <el-table ref="tableData" border stripe :data="tableData" row-key="id" :tree-props="{ children: 'categoryItemList' }" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading">
            <el-table-column prop="id" label="ID" width="150"></el-table-column>
            <el-table-column label="分类中文名" prop="categoryNameCn" min-width="120"></el-table-column>
            <el-table-column label="分类英文名" prop="categoryNameEn" min-width="120"></el-table-column>
            <el-table-column label="排序" prop="sort" min-width="120"></el-table-column>
            <el-table-column fixed="right" label="操作" width="300">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" icon="el-icon-view" @click="openView( scope.row.viewUrl )">预览</el-button>
                    <el-button type="text" size="mini" icon="el-icon-plus" @click="initAddSubCategory( scope.row )">新增子分类</el-button>
                    <el-button type="text" size="mini" icon="el-icon-edit" @click="initEditCategory( scope.row )">编辑</el-button>
                    <el-button type="text" size="mini" icon="el-icon-edit" @click="initEditdistribute( scope.row.id )">分配商品</el-button>
                    <el-button type="text" size="mini" icon="el-icon-delete" @click="deleteThis( scope.row.id )">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 新增一级分类 -->
        <el-dialog title="新增一级分类" :visible.sync="dialogAdd" :close-on-click-modal="false" top="3%" width="500px">
            <el-form ref="dictAddForm" :rules="addRules" :model="dictAddForm" label-width="120px">
                <el-form-item label="分类中文名" prop="categoryNameCn">
                    <el-input v-model="dictAddForm.categoryNameCn"></el-input>
                </el-form-item>
                <el-form-item label="分类英文名" prop="categoryNameEn">
                    <el-input v-model="dictAddForm.categoryNameEn"></el-input>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="dictAddForm.sort"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                    <el-button @click="dialogAdd = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 新增子分类 -->
        <el-dialog title="新增子分类" :visible.sync="dialogAddSub" :close-on-click-modal="false" top="3%" width="500px">
            <el-form ref="dictAddSubForm" :rules="addSubRules" :model="dictAddSubForm" label-width="120px">
                <el-form-item label="分类中文名" prop="categoryNameCn">
                    <el-input v-model="dictAddSubForm.categoryNameCn"></el-input>
                </el-form-item>
                <el-form-item label="分类英文名" prop="categoryNameEn">
                    <el-input v-model="dictAddSubForm.categoryNameEn"></el-input>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="dictAddSubForm.sort"></el-input>
                </el-form-item>
                <el-form-item label="父id">
                    <el-input :disabled="true" v-model="dictAddSubForm.parentId"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :loading="addSubLoading" @click="addSubData">确定</el-button>
                    <el-button @click="dialogAddSub = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 编辑 -->
        <el-dialog title="编辑商品" :visible.sync="dialogEdit" :close-on-click-modal="false" top="3%" width="800px">
            <el-form ref="dictEditForm" :rules="editRules" :model="dictEditForm" label-position="top" size="medium" label-width="120px">
                <el-form-item label="分类中文名" prop="categoryNameCn">
                    <el-input v-model="dictEditForm.categoryNameCn"></el-input>
                </el-form-item>
                <el-form-item label="分类英文名" prop="categoryNameEn">
                    <el-input v-model="dictEditForm.categoryNameEn"></el-input>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="dictEditForm.sort"></el-input>
                </el-form-item>
                <el-form-item label="父级">
                    <el-select v-model="dictEditForm.parentId" filterable clearable placeholder="请选择">
                        <el-option v-for="item in getEditParentedOptions" :key="item.id" :label="item.categoryNameCn" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="SEO标题">
                    <div>标题是SEO最重要的部分，该标题会显示在搜索引擎的搜索结果中。</div>
                    <el-input type="textarea" :rows="8" placeholder="请输入SEO标题" v-model="dictEditForm.seoTitle" maxlength="5000" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="SEO描述">
                    <div>
                        这是该商品专辑的整体 SEO
                        描述，可以新增一些描述，使商品专辑在搜索引擎中获得更高的排名。
                    </div>
                    <el-input type="textarea" :rows="8" placeholder="请输入SEO描述" v-model="dictEditForm.seoDesc" maxlength="5000" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="SEO链接">
                    <el-input class="specil" placeholder="请输入seo链接" v-model="splitLink.href">
                        <template slot="prepend">{{ splitLink.host }}</template>
                        <template slot="append">{{ splitLink.html }}</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="SEO关键词">
                    <div>Enter新增关键词</div>
                    <el-tag :key="tag" v-for="tag in dictEditForm.seoKeywordsArr" closable :disable-transitions="false" @close="handleClose(tag)">
                        {{ tag }}
                    </el-tag>
                    <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增</el-button>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" :loading="editLoading" @click="editData">确定</el-button>
                    <el-button @click="dialogEdit = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 分配 -->
        <el-dialog title="分配商品" :visible.sync="dialogCation" top="3%" fullscreen :close-on-click-modal="false">

            <div>
                <el-button type="primary" icon="el-icon-plus" @click="openSelectGoods">选择商品
                </el-button>
            </div>

            <!-- 数据表 -->
            <!-- <el-table
                ref="selectedGoodsTable"
                class="mt_3"
                stripe
                :data="selectedGoodsTable.data">
                <el-table-column label="商品图片" width="80">
                    <template slot-scope="scope">
                        <el-image
                            v-if="scope.row.goodsImages && scope.row.goodsImages.length > 0"
                            fit="contain"
                            style="width: 40px; height: 40px"
                            :src="scope.row.goodsImages[0]"
                            :preview-src-list="scope.row.goodsImages"
                            >
                            <div
                                slot="error"
                                class="el-image__error"
                                style="width: 40px; height: 40px; font-size: 10px"
                                >
                                无图
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsTitle"
                    label="商品名称"
                    min-width="250px"
                ></el-table-column>
                <el-table-column label="售价/原价" min-width="80">
                    <template slot-scope="scope">
                        {{ scope.row.price }} / {{ scope.row.compareAtPrice }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="inventoryQuantity"
                    label="库存"
                    min-width="80"
                ></el-table-column>
                <el-table-column
                  label="是否上架"
                  min-width="80"
                >
                  <template slot-scope="scope">
                    {{ getPublished(scope.row.published) }}
                  </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120"
                    header-align="center"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-button
                        size="mini"
                        type="text"
                        @click="deleteSelectedGoods(scope.row)"
                        >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table> -->

            <div class="flex">
                <template v-if="selectedGoodsTable.data.length > 0">
                    <vuedraggable class="wrapper" v-model="selectedGoodsTable.data">
                        <div class="itembox" v-for="(item,indexB) in selectedGoodsTable.data" :key="indexB">
                            <div class="imgbox">
                                <GoodsImageTemplate :images="item.goodsImages" width="180" height="180" :hasHover="false"></GoodsImageTemplate>
                            </div>
                            <p class="tit">{{ item.goodsTitle }}</p>
                            <p class="tit">售价:{{ item.price }} / 原价:{{ item.compareAtPrice }}</p>
                            <p>库存: {{ item.inventoryQuantity }}</p>
                            <p class="between">
                                <span class="w50"><i class="el-icon-top" @click="toppingGoods(item)"></i></span>
                                <span class="w50" @click="deleteSelectedGoods(item)"><i class="el-icon-delete"></i></span>
                            </p>
                            <span class="published publishedA" v-if="item.published == 0">{{ getPublishedB(item.published) }}</span>
                            <span class="published publishedB" v-if="item.published == 1">{{ getPublishedB(item.published) }}</span>
                        </div>
                    </vuedraggable>
                </template>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="updateAllocation">确定</el-button>
                <el-button @click="dialogCation = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- <el-pagination
        background
        :current-page.sync="queryForm.pageNo"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size.sync="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="queryData"
        @current-change="queryData"
        @prev-click="queryData"
        @next-click="queryData"
        >
        </el-pagination> -->
        <!-- 业务弹框-日志详情 -->

        <!-- 选择商品 -->
        <SelectGoods ref="selectGoods" :showDialog="dialogSelectGoods" :containItem="false" :selectedGoods="selectedGoodsTable.data" @closeDialog="closeSelectGoods" @saveSelectedGoods="saveSelectGoods" :isSingleCategory="isSingleCategory">
        </SelectGoods>

    </div>
</template>

<script>
import SelectGoods from '@/components/SelectGoods';
import vuedraggable from 'vuedraggable';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
export default {
    name: 'Category',
    components: {
        SelectGoods: SelectGoods,
        vuedraggable,
        GoodsImageTemplate,
    },
    data() {
        return {
            splitLink: {},
            inputVisible: false,
            inputValue: '',
            // 选择商品参数
            dialogSelectGoods: false,
            isSingleCategory: '1',
            // 选中的商品列表数据
            selectedGoodsTable: {
                data: [],
                total: 0,
            },
            queryForm: {
                categoryNameCn: null,
                categoryNameEn: null,
            },
            total: 0,
            tableData: [],
            loading: false,
            dialogAdd: false,
            dictAddForm: {},
            addLoading: false,
            addRules: {
                categoryNameCn: [
                    {
                        required: true,
                        message: '请输入分类中文名',
                        trigger: 'blur',
                    },
                ],
                categoryNameEn: [
                    {
                        required: true,
                        message: '请输入分类英文名',
                        trigger: 'blur',
                    },
                ],
            },
            // disabled:false,
            dialogAddSub: false,
            dictAddSubForm: {},
            addSubRules: {
                categoryNameCn: [
                    {
                        required: true,
                        message: '请输入分类中文名',
                        trigger: 'blur',
                    },
                ],
                categoryNameEn: [
                    {
                        required: true,
                        message: '请输入分类英文名',
                        trigger: 'blur',
                    },
                ],
            },
            addSubLoading: false,
            dictEditForm: {},
            editRules: {
                categoryNameCn: [
                    {
                        required: true,
                        message: '请输入分类中文名',
                        trigger: 'blur',
                    },
                ],
                categoryNameEn: [
                    {
                        required: true,
                        message: '请输入分类英文名',
                        trigger: 'blur',
                    },
                ],
            },
            dialogEdit: false,
            editLoading: false,
            dialogCation: false,
            goodsCollectionForm: {},
            cationForm: {
                categoryId: '',
                goodsTitle: '',
                pageNo: 1,
                pageSize: 10,
                sku: '',
                spu: '',
                vendor: '',
            },
            cationFormTotal: 0,
            cationList: [], //查询商品列表
            multipleSelection: [], //查询商品列表选中项
            goodsCollection: [], //本身已有的分配商品
            cationGoodsIds: [], //提交的goodsids
            getEditParentedOptions: {},
        };
    },
    mounted() {
        this.queryData();
    },
    methods: {
        // refresh(){
        //     this.$http
        //     .get("/goods/category/getPage")
        //     .then((response) => {

        //         if(response.data.code === 200){
        //             this.tableData = response.data.data
        //             this.total = response.data.count
        //             console.log(this.tableData,this.total)
        //         }
        //     })
        // },
        // 选择商品方法
        openSelectGoods() {
            this.$refs.selectGoods.caller = 'notset';
            this.$refs.selectGoods.init();
            this.dialogSelectGoods = true;
        },
        closeSelectGoods() {
            this.dialogSelectGoods = false;
        },
        saveSelectGoods(items, caller) {
            switch (caller) {
                default:
                    this.selectedGoodsTable.data = items;
                    this.selectedGoodsTable.total =
                        this.selectedGoodsTable.data.length;
            }
        },
        // 置顶
        toppingGoods(item) {
            let tempitem = JSON.parse(JSON.stringify(item));
            tempitem.top = 1;
            this.selectedGoodsTable.data.unshift(tempitem);
            let index = this.selectedGoodsTable.data.indexOf(item);
            this.selectedGoodsTable.data.splice(index, 1);
        },
        // 删除选中的商品（删除）
        deleteSelectedGoods(item) {
            let index = this.selectedGoodsTable.data.indexOf(item);

            if (index > -1) {
                this.selectedGoodsTable.data.splice(index, 1);
                this.selectedGoodsTable.total =
                    this.selectedGoodsTable.data.length;
            }
        },
        queryData() {
            this.loading = true;
            this.$http
                .get('/goods/category/getList', { params: this.queryForm })
                .then((response) => {
                    if (response.data.code === 200) {
                        this.tableData = response.data.data.map((row) => {
                            row.loadingSetStatus = false;
                            return row;
                        });
                        this.total = response.data.count;
                    }
                    // 解决表格固定列错位
                    this.$refs.tableData.doLayout();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 新增一级分类
        initAddCategory() {
            (this.dialogAdd = true), (this.addLoading = false);
            this.dictAddForm = {
                categoryNameCn: null,
                categoryNameEn: null,
                sort: null,
                parentId: null,
            };
        },
        addData() {
            this.$refs['dictAddForm'].validate((valid) => {
                if (valid) {
                    this.addLoading = true;
                    this.$http
                        .post('/goods/category/add', this.dictAddForm)
                        .then((response) => {
                            this.addLoading = false;
                            if (response.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message({
                                    message: '新增成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: () => {
                                        this.queryData();
                                    },
                                });
                            }
                        })
                        .catch((error) => {
                            // this.$message.error(error);
                            this.addLoading = false;
                        });
                }
            });
        },
        openView(url) {
            window.open(url);
        },
        //新增子类
        initAddSubCategory(data) {
            (this.dialogAddSub = true), (this.addSubLoading = false);
            // this.dictAddSubForm.parentId = queryForm.parentId ,
            this.dictAddSubForm = {
                categoryNameCn: null,
                categoryNameEn: null,
                sort: null,
                parentId: data.id,
            };
        },
        addSubData() {
            this.$refs['dictAddSubForm'].validate((valid) => {
                if (valid) {
                    this.addSubLoading = true;
                    this.$http
                        .post('/goods/category/add', this.dictAddSubForm)
                        .then((response) => {
                            this.addSubLoading = false;
                            if (response.data.code === 200) {
                                this.dialogAddSub = false;
                                this.$message({
                                    message: '新增成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: () => {
                                        this.queryData();
                                    },
                                });
                            }
                        })
                        .catch((error) => {
                            // this.$message.error(error);
                            this.addSubLoading = false;
                        });
                }
            });
        },
        splitMethod(url) {
            // console.log(url)
            let arr = url.split('/');
            console.log(arr);

            let host = arr[0] + '//' + arr[2] + '/' + arr[3] + '/';

            let temphtml = arr[4].split('-');
            console.log(temphtml);
            let html = '-' + temphtml[temphtml.length - 1];

            let temphref = arr[4].split(html);
            let href = temphref[0];

            this.splitLink = {
                host: host,
                href: href,
                html: html,
            };
        },
        // 编辑
        initEditCategory(data) {
            let id = data.id;
            this.$http
                .get('/goods/category/getDetail?id=' + id)
                .then((response) => {
                    if (response.data.code === 200) {
                        this.dialogEdit = true;
                        this.editLoading = false;
                        this.getEditParentedOptions =
                            response.data.data.categoryList;
                        console.log(
                            this.getEditParentedOptions,
                            '000',
                            response.data.code
                        );
                        this.dictEditForm = {
                            id: data.id,
                            categoryNameCn: data.categoryNameCn,
                            categoryNameEn: data.categoryNameEn,
                            sort: data.sort ? data.sort : null,
                            subclassId: data.subclassId,
                            parentId: data.parentId ? data.parentId : null,
                            seoDesc: data.seoDesc,
                            seoKeywords: [],
                            seoKeywordsArr: data.seoKeywords || [],
                            seoTitle: data.seoTitle,
                            seoUrl: data.seoUrl,
                        };
                        this.splitMethod(this.dictEditForm.seoUrl);
                    }
                });
        },
        editData() {
            this.$refs['dictEditForm'].validate((valid) => {
                if (valid) {
                    this.editLoading = true;

                    this.dictEditForm.seoUrl =
                        this.splitLink.host +
                        this.splitLink.href +
                        this.splitLink.html;

                    this.$http
                        .post('/goods/category/edit', this.dictEditForm)
                        .then((response) => {
                            this.editLoading = false;
                            if (response.data.code === 200) {
                                this.editLoading = false;
                                this.$message({
                                    message: '编辑成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: () => {
                                        this.queryData();
                                    },
                                });
                                this.dialogEdit = false;
                            }
                        })
                        .catch((error) => {
                            // this.$message.error(error);
                            this.editLoading = false;
                        });
                }
            });
        },
        // 分配商品
        initEditdistribute(id) {
            this.cationForm.categoryId = id;
            this.cationForm.pageNo = 1;
            this.getGoodsCollection();
            this.dialogCation = true;
            this.cationForm.goodsTitle = '';
            this.cationForm.sku = '';
            this.cationForm.spu = '';
            this.cationForm.vendor = '';
            this.selectedGoodsTable.data = []; // 选中的商品
            this.selectedGoodsTable.total = 0;
        },
        goodsCollectionClose(tag) {
            console.log(tag.id);
            this.goodsCollection.forEach((row) => {
                this.$refs.multipleTable.toggleRowSelection(
                    this.cationList.find((item) => {
                        console.log(row.id, item.id);
                        return tag.id == item.id;
                    }),
                    false
                );
            });
            this.goodsCollection.splice(this.goodsCollection.indexOf(tag), 1);
            console.log('goodsCollection', this.goodsCollection);
        },
        getGoodsCollection() {
            this.$http
                .get(
                    '/goods/category/getDetail?id=' + this.cationForm.categoryId
                )
                .then((response) => {
                    if (response.data.code === 200) {
                        this.selectedGoodsTable.data =
                            response.data.data.goodsList;
                        this.selectedGoodsTable.data.total =
                            response.data.data.goodsList.length;
                    }
                })
                .catch((error) => {
                    // this.$message.error(error);
                });
        },
        getCationList(num) {
            if (num == 1) {
                this.cationForm.pageNo = 1;
            }
            this.loading = true;
            this.$http
                .get('/goods/getPage', { params: this.cationForm })
                .then((response) => {
                    this.loading = false;
                    if (response.data.code === 200) {
                        this.cationList = response.data.data;
                        this.cationFormTotal = response.data.count;
                        this.$nextTick(() => {
                            this.goodsCollection.forEach((row) => {
                                this.$refs.multipleTable.toggleRowSelection(
                                    this.cationList.find((item) => {
                                        return row.id == item.id;
                                    }),
                                    true
                                );
                                // console.log(this.$refs.multipleTable,this.cationList)
                            });
                        });
                        this.dialogCation = true;
                    }
                })
                .catch((error) => {
                    // this.$message.error(error);
                    this.loading = false;
                });
        },
        updateAllocation() {
            this.cationGoodsIds = [];
            for (let i = 0; i < this.selectedGoodsTable.data.length; i++) {
                this.cationGoodsIds.push(this.selectedGoodsTable.data[i].id);
            }
            // for (let i = 0; i < this.selectedGoodsTable.data.length; i++) {
            //     // this.cationGoodsIds.push(this.selectedGoodsTable.data[i].id);
            //     let item = this.selectedGoodsTable.data[i]
            //     let json = {
            //         categoryId: this.cationForm.categoryId,
            //         goodsId: item.id,
            //         top: item.top ? item.top : 0,
            //     }
            //     this.cationGoodsIds.push(json)
            // }
            console.log(this.cationGoodsIds);
            this.addLoading = true;
            this.$http
                .post('/goods/category/allocation', {
                    categoryId: this.cationForm.categoryId,
                    goodsIds: this.cationGoodsIds,
                })
                .then((response) => {
                    this.addLoading = false;
                    if (response.data.code === 200) {
                        this.$message({
                            message: '分配商品成功！',
                            type: 'success',
                        });
                        this.dialogCation = false;
                    }
                })
                .catch((error) => {
                    this.addLoading = false;
                    // this.$message.error(error);
                });
        },
        handleClose(tag) {
            this.dictEditForm.seoKeywordsArr.splice(
                this.dictEditForm.seoKeywordsArr.indexOf(tag),
                1
            );
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick(() => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.dictEditForm.seoKeywordsArr.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            // console.log('multipleSelection',this.multipleSelection)
        },
        getRowsDetail(select, row) {
            // console.log('getRowsDetail',row)
            let json = {
                goodsImages: row.goodsImages,
                goodsTitle: row.goodsTitle,
                id: row.id,
            };
            let alreadyHas = false;
            let indexId = null;
            for (let i = 0; i < this.goodsCollection.length; i++) {
                if (row.id == this.goodsCollection[i].id) {
                    alreadyHas = true;
                    indexId = i;
                }
            }
            if (alreadyHas) {
                this.goodsCollection.splice(indexId, 1);
            } else {
                this.goodsCollection.push(json);
            }
        },
        getAllRowsDetail(selection) {
            for (let i = 0; i < selection.length; i++) {
                if (selection[i] == undefined) {
                    selection.splice(i, 1);
                }
            }
            console.log(selection);
            if (selection.length > 0) {
                for (let i = 0; i < selection.length; i++) {
                    let row = selection[i];
                    if (row) {
                        let json = {
                            goodsImages: row.goodsImages,
                            goodsTitle: row.goodsTitle,
                            id: row.id,
                        };
                        let alreadyHas = false;
                        for (let k = 0; k < this.goodsCollection.length; k++) {
                            if (row.id == this.goodsCollection[k].id) {
                                alreadyHas = true;
                            }
                        }
                        if (!alreadyHas) {
                            this.goodsCollection.push(json);
                        }
                    }
                }
            } else {
                for (let i = 0; i < this.cationList.length; i++) {
                    let row = this.cationList[i];
                    let json = {
                        goodsImages: row.goodsImages,
                        goodsTitle: row.goodsTitle,
                        id: row.id,
                    };
                    let alreadyHas = false;
                    let indexId = null;
                    for (let k = 0; k < this.goodsCollection.length; k++) {
                        if (row.id == this.goodsCollection[k].id) {
                            alreadyHas = true;
                            indexId = k;
                        }
                    }
                    if (alreadyHas) {
                        this.goodsCollection.splice(indexId, 1);
                    }
                }
            }
        },
        getPublished(status) {
            switch (status) {
                case 0:
                    return '否';
                case 1:
                    return '是';
            }
        },
        getPublishedB(status) {
            switch (status) {
                case 0:
                    return '未上架';
                case 1:
                    return '已上架';
            }
        },
        deleteThis(id) {
            this.$confirm('是否删除分类？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .delete('/goods/category/delete?categoryId=' + id)
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message.success('删除成功');
                            this.queryData();
                        }
                    });
            });
        },
    },
};
</script>

<style scoped>
.wrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}
.wrapper .itembox {
    width: 180px;
    flex-basis: 180px;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    padding: 5px 5px 0 5px;
    border-radius: 10px;
    background-image: linear-gradient(315deg, #e2e2e2, #fff);
    position: relative;
}
.wrapper .itembox .imgbox {
    width: 100%;
    height: 220px;
}
.wrapper .itembox .tit {
    height: 32px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.wrapper .itembox .between {
    display: flex;
    border-top: 1px solid #ccc;
    margin: 0;
    height: 30px;
}
.wrapper .itembox .w50 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 50%;
    width: 50%;
    height: 100%;
    cursor: pointer;
    color: #409eff;
    font-size: 20px;
}
.wrapper .itembox .w50:first-child {
    border-right: 1px solid #ccc;
}
.wrapper .itembox .published {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    padding: 2px 5px;
    font-size: 14px;
}
.wrapper .itembox .published.publishedA {
    color: #333;
    background: #ddd;
}
.wrapper .itembox .published.publishedB {
    color: #fff;
    background: #409eff;
}
.main-wrap {
    display: block;
}
.specil /deep/ .el-input__inner {
    padding-right: 66px;
}
.img-uploader {
    border: 1px dashed #000;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.img-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 136px;
    height: 136px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.image {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.insertbox {
    height: 410px;
    display: flex;
    flex-wrap: wrap;
}
.insertbox .item {
    width: 115px;
    height: 115px;
    flex-basis: 115px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.insertbox .item img {
    max-width: 110px;
    max-height: 110px;
    border: 2px solid transparent;
}
.insertbox .item img.selected {
    border: 2px solid #409eff;
}
.controlrow {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}
.el-textarea /deep/ .el-textarea__inner {
    height: 140px;
}
.selectedMaterUrl {
    display: block;
    max-width: 110px;
    max-height: 110px;
    margin: 0 auto;
    margin-top: 20px;
}
.center {
    text-align: center;
}
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
.tagbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.tagbox /deep/ .el-tag {
    display: inline-flex;
    align-items: center;
    height: auto;
    margin-bottom: 10px;
}
.goodsTagBox {
    height: 60px;
    display: flex;
    align-items: center;
}
.goodsTagBox img {
    max-width: 40px;
    max-height: 40px;
    display: inline-block;
    margin-right: 10px;
}
</style>
