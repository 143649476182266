<template>
  <div class="login-bg">
    <div class="login-box">
      <div class="title">Tivisiy商城管理平台</div>
      <div class="ai-wrap"></div>
      <el-form
        :model="loginForm"
        :rules="rules"
        ref="loginForm"
        status-icon
        @keyup.enter.native="login"
      >
        <el-form-item prop="userName">
          <el-input
            placeholder="用户名"
            v-model="loginForm.userName"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="密码"
            v-model="loginForm.password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="verCode">
          <el-col :span="15"
            ><el-input
              placeholder="验证码"
              v-model="loginForm.verCode"
            ></el-input
          ></el-col>
          <el-col :span="8" :offset="1" style="line-height: 0"
            ><el-image :src="verCodeUrl" @click="initVerCode"></el-image
          ></el-col>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="login-button"
            :loading="loading"
            @click="login"
            >登录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        userName: null,
        password: null,
        verCode: null,
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        verCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      verCodeUrl: "",
      loading: false,
    };
  },
  mounted() {
    this.initVerCode();
  },
  methods: {
    initVerCode() {
      this.verCodeUrl = "/system/verCode/get?ts=" + new Date().getTime();
    },
    login() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let loginData = {
            userName: this.loginForm.userName,
            password: this.$encodePassword(this.loginForm.password),
            verCode: this.loginForm.verCode,
          };
          this.$http
            .post("/system/login", loginData)
            .then((response) => {
              if (response.data.code === 200) {
                this.$store.commit("setUserAuth", response.data.data);
                this.$message({
                  message: "登录成功",
                  type: "success",
                  duration: 1000,
                  onClose: () => {
                    this.$router.push({ name: "Index" });
                  },
                });
              }
            })
            .catch(() => {
              this.initVerCode();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.login-bg {
  width: 100vw;
  height: 100vh;
  background-image: url(../assets/bg.png);
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-box {
  width: 380px;
  height: 370px;
  padding: 20px 30px;
  box-sizing: border-box;
  border-radius: 5px;
  background: #fff;
}
.title {
  background: #409eff;
  margin: 0 0 0 -48px;
  padding: 16px 35px;
  color: #fff;
  font-size: 16px;
}
.ai-wrap {
  width: 18px;
  height: 10px;
  margin: 0 0 22px -48px;
  background: url(../assets/aiwrap.png);
}
.el-image {
  height: 40px;
  cursor: pointer;
}
.login-button {
  width: 100%;
}
</style>
