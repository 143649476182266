<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>售后订单</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 查询表单 -->
        <el-tabs v-model="afterSaleStatus" @tab-click="queryData">
            <el-tab-pane label="全部" name="0"></el-tab-pane>
            <el-tab-pane v-for="item in afterSaleStatusTypes" :key="item.value" :label="item.name" :name="item.value"></el-tab-pane>
        </el-tabs>
        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input class="order-list-query-keyword" placeholder="关键字" v-model="queryKeyword">
                    <el-select slot="prepend" placeholder="类型" clearable v-model="queryType">
                        <el-option v-for="item in queryTypes" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-input>
            </el-form-item>
            <el-form-item style="color: #606266">&nbsp;售后时间</el-form-item>
            <el-form-item>
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="开始时间" style="width: 140px" v-model="queryForm.startTime">
                </el-date-picker>
            </el-form-item>
            <el-form-item style="color: #bbb">～</el-form-item>
            <el-form-item>
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="结束时间" style="width: 140px" v-model="queryForm.endTime">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-refresh-left" @click="resetCondition">重置</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表 -->
        <el-table ref="orderListTable" border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" :data="tableData" v-loading="loading">
            <el-table-column fixed prop="afterSaleNo" label="售后单号" min-width="200"></el-table-column>
            <el-table-column prop="afterSaleTime" label="售后时间" min-width="160"></el-table-column>
            <el-table-column label="售后状态" min-width="120">
                <template slot-scope="scope">
                    {{
                        afterSaleStatusTypes.find(
                        (v) => v.value == scope.row.afterSaleStatus
                        ).name
                    }}
                </template>
            </el-table-column>
            <!-- <el-table-column label="收货人" min-width="120">
			<template slot-scope="scope">
				{{ scope.row.shippingFirstName }} {{ scope.row.shippingLastName }}
			</template>
		</el-table-column>
		<el-table-column prop="shippingCountryCn" label="国家/地区" min-width="120"></el-table-column> -->
            <el-table-column prop="orderSource" label="订单来源" min-width="200"></el-table-column>
            <el-table-column prop="logisticsFreightPlan" label="物流方案" min-width="120"></el-table-column>
            <!-- <el-table-column prop="orderItems[ 0 ].goodsTitle" label="商品名称" min-width="120"></el-table-column>
		<el-table-column prop="customerInfo.email" label="下单邮箱" min-width="120"></el-table-column>
		<el-table-column label="下单手机号" min-width="120">
			<template slot-scope="scope">
				+{{ scope.row.customerInfo.phoneCode }} {{ scope.row.customerInfo.phone }}
			</template>
		</el-table-column>
		<el-table-column prop="discountCode" label="优惠码" min-width="120"></el-table-column> -->
            <el-table-column label="订单总价" min-width="120">
                <template slot-scope="scope">
                    {{ $yrFormatNumber(scope.row.totalPrice, ",", 2) }}USD
                </template>
            </el-table-column>
            <!-- <el-table-column label="礼品卡卡号">
                <template #default="{row}">
                    <div v-html="row.shopOrderGifts && row.shopOrderGifts.map((item)=>{ return item['giftCardNo']}).join('<br>')"></div>
                </template>
            </el-table-column> -->
            <el-table-column label="操作" fixed="right" width="140" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" icon="el-icon-document" @click="getOrderDetail(scope.row)">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <!-- 订单详情 -->
        <el-dialog fullscreen id="orderDetailDialog" title="售后订单详情" :visible.sync="dialogOrderDetail" :close-on-click-modal="false">
            <div class="df ai_fs">
                <!-- 左侧 -->
                <main style="flex: 1 1 auto">
                    <!-- 售后订单信息 -->
                    <section class="px_4 pt_3 pb_5" style="background-color: #fff">
                        <div class="df ai_c mb_2">
                            <span style="font-szie: 14px">售后订单号:</span>
                            <h2 class="my_0 mr_3 ml_2">
                                {{ orderDetail.data ? orderDetail.data.afterSaleNo : "" }}
                            </h2>
                            <p class="my_0" style="color: #909399; font-size: 12px">
                                售后时间：{{
                  orderDetail.data ? orderDetail.data.afterSaleTime : ""
                }}
                            </p>
                            <div class="df ai_c ml_a">
                                <el-button type="text" size="mini" class="py_0_i" :loading="btnLoading == 'cancelAfterSaleOrder'" v-if="orderDetail.data" @click="cancelAfterSaleOrder(orderDetail.data.id)">
                                    取消售后
                                </el-button>
                                <el-button type="primary" class="ml_4_i" @click="initRefund()" v-if="orderDetail.data && orderDetail.data.financialStatus != 1 && orderDetail.data.financialStatus != 3 && orderDetail.data.financialStatus != 4">
                                    退款
                                </el-button>
                            </div>
                        </div>

                        <!-- 订单备注 -->
                        <div id="OrderDetailRemark" class="pb_3">
                            <template v-if="orderDetail.hideOrderRemarkForm">
                                <span style="font-size: 14px" v-if="orderDetail.data && orderDetail.data.afterSaleRemark">{{ orderDetail.data.afterSaleRemark }}</span>
                                <span style="color: #409eff; font-size: 12px" v-else>新增售后订单备注</span>
                                <i class="el-icon-edit ml_2 cp" style="color: #409eff; font-size: 20px" @click="initOrderRemark"></i>
                            </template>
                            <el-form class="df ai_fe" v-else :inline="true">
                                <el-form-item>
                                    <el-input type="textarea" style="width: 400px" :rows="5" v-model="orderDetail.orderRemarkForm.afterSaleRemark"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" :loading="btnLoading == 'saveOrderRemark'" @click="saveOrderRemark">确定</el-button>
                                    <el-button @click="orderDetail.hideOrderRemarkForm = true">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </section>

                    <!-- 订单信息 -->
                    <header class="py_2">
                        <h2>订单信息</h2>
                    </header>
                    <section class="px_4 pt_3 pb_5" style="background-color: #fff">
                        <div class="df ai_c mb_2">
                            <span style="font-szie: 14px">订单号:</span>
                            <h2 class="my_0 mr_3 ml_2">
                                {{ orderDetail.data ? orderDetail.data.orderNo : "" }}
                            </h2>
                            <p class="my_0" style="color: #909399; font-size: 12px">
                                创建时间：{{
                                orderDetail.data ? orderDetail.data.createTime : ""
                                }}
                                &nbsp;&nbsp;
                                <span v-if="orderDetail.data && orderDetail.data.financialTime">成功下单：{{ orderDetail.data.financialTime }}</span>
                            </p>
                        </div>
                        <div class="mb_2" v-if="orderDetail.data && orderDetail.data.transactionNo">
                            支付交易号: {{ orderDetail.data.transactionNo }}
                        </div>
                        <div v-if="orderDetail.data && orderDetail.data.refundNo">
                            交易退款号: {{ orderDetail.data.refundNo }}
                        </div>
                        <!-- 订单状态 -->
                        <div class="mb_2 py_2" v-if="orderDetail.data">
                            <!-- 订单状态 -->
                            <!-- <el-tag type="danger" size="mini">{{ orderStatusTypes.find( v => v.value == orderDetail.data.orderStatus ).name }}</el-tag> -->
                            <!-- 支付状态 -->
                            <!-- <el-tag type="success" size="mini">{{ financialStatusTypes.find( v => v.value == orderDetail.data.financialStatus ).name }}</el-tag> -->
                            <!-- 发货状态 -->
                            <el-tag type="primary" size="mini">{{
                                fulfillmentStatusTypes.find(
                                (v) => v.value == orderDetail.data.fulfillmentStatus
                                ).name
                            }}</el-tag>
                            <!-- 售后订单状态 -->
                            <el-tag type="warning" size="mini">售后{{
                                afterSaleStatusTypes.find(
                                    (v) => v.value == orderDetail.data.afterSaleStatus
                                ).name
                                }}</el-tag>
                        </div>

                        <!-- 订单价格详情 -->
                        <h4 class="my_0 pt_4" style="border-top: solid 1px #d3d8e9">
                            订单价格详情
                        </h4>
                        <div class="df ai_c mb_2" style="border-bottom: solid 1px #d3d8e9">
                            <p style="line-height: 28px">
                                商品总价<br />满减<br />优惠码<br />
                                <template v-if="orderDetail.data && orderDetail.data.shopOrderGifts">
                                    <span v-for="(item, index) in orderDetail.data.shopOrderGifts" :key="index">
                                        礼品卡（{{ item.giftCardNo }}）<br>
                                    </span>
                                </template>
                                捆绑折扣<br />物流费用<br>运费险<br>小费
                            </p>
                            <p class="tr" style="margin-left: 180px; line-height: 28px" v-if="orderDetail.data">
                                {{ $yrFormatNumber(orderDetail.data.goodsTotal, ",", 2) }} USD<br />
                                - {{ $yrFormatNumber(orderDetail.data.discountFullPrice, ",", 2) }} USD<br />
                                - {{ $yrFormatNumber(orderDetail.data.discountCodePrice, ",", 2) }} USD<br />
                                <template v-if="orderDetail.data && orderDetail.data.shopOrderGifts">
                                    <span v-for="(item, index) in orderDetail.data.shopOrderGifts" :key="index">
                                        - {{  $yrFormatNumber( item.subPrice, ",", 2) }} USD<br>
                                    </span>
                                </template>
                                - {{ $yrFormatNumber(orderDetail.data.discountBindPrice, ",", 2) }} USD<br />
                                {{ $yrFormatNumber(orderDetail.data.totalShipping, ",", 2) }} USD<br />
                                <!-- {{ $yrFormatNumber(orderDetail.data.totalTax, ",", 2) }} USD<br> -->
                                {{ $yrFormatNumber(orderDetail.data.insurePrice, ",", 2) }} USD<br>
                                {{ $yrFormatNumber(orderDetail.data.tipPrice, ",", 2) }} USD

                            </p>
                            <p style="margin-left: 100px; line-height: 28px" v-if="orderDetail.data">
                                <span class="bold">订单总金额：{{
                                        $yrFormatNumber(orderDetail.data.totalPrice, ",", 2)
                                    }}
                                    USD</span><br />
                                <span class="bold">实付总金额：{{
                                        $yrFormatNumber(orderDetail.data.cashTotal, ",", 2)
                                    }}
                                    USD</span><br />
                                <!-- <el-tag type="success" size="mini">{{ financialStatusTypes.find( v => v.value == orderDetail.data.financialStatus ).name }}</el-tag> -->
                            </p>
                        </div>

                        <!-- 订单商品列表 -->
                        <el-table v-if="orderDetail.data" :show-header="false" :data="orderDetail.data.orderItems">
                            <el-table-column type="index" label="序号" width="60"></el-table-column>
                            <el-table-column label="商品图片" width="90">
                                <template slot-scope="scope">
                                     <GoodsImageTemplate :images="scope.row.goodsImage" :isOrderItems="true" :orderMainImage="scope.row.goodsImages"></GoodsImageTemplate>
                                    <el-tag type="primary" size="mini">{{
                    fulfillmentGoodsStatusTypes.find(
                      (v) => v.value == scope.row.fulfillmentStatus
                    ).name
                  }}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="商品信息" min-width="120">
                                <template slot-scope="scope">
                                    <div class="tl" style="line-height: 24px">
                                        {{ scope.row.goodsTitle }}<br />
                                        <span style="color: #909399">
                                            <span v-if="scope.row.goodsOption">Style: {{ scope.row.goodsOption["1"] }}-{{
                          scope.row.goodsOption["2"]
                        }}<br /></span>
                                            SKU: {{ scope.row.sku }}<br />
                                            <!-- 供应商: {{ scope.row.vendor }} -->
                                        </span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="单价" width="200">
                                <template slot-scope="scope">
                                    <div class="tr">
                                        {{ $yrFormatNumber(scope.row.price, ",", 2) }} USD &times;
                                        {{ scope.row.quantity }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="总价" width="140">
                                <template slot-scope="scope">
                                    <div class="tr">
                                        {{ $yrFormatNumber(scope.row.total, ",", 2) }} USD
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </section>

                    <!-- 退货退款时间轴 -->
                    <header class="py_2" v-if="orderDetail.data && orderDetail.data.refundTimeLine">
                        <h2>退货退款时间轴</h2>
                    </header>
                    <section style="background-color: #fff" v-if="orderDetail.data && orderDetail.data.refundTimeLine">
                        <el-timeline class="pt_4">
                            <el-timeline-item :hide-timestamp="true" v-for="(item, index) in orderDetail.data.refundTimeLine" :key="index">
                                <span style="color: #c0c4cc">{{ item.time }}</span> &nbsp;<span style="color: #909399">{{ item.content }}</span>
                            </el-timeline-item>
                        </el-timeline>
                    </section>
                </main>

                <!-- 右侧 -->
                <aside class="ml_3 p_4" style="flex: 0 0 385px; background-color: #f7f8fa">
                    <h2 class="mt_0">购买人信息</h2>
                    <h3 class="mb_0">
                        {{
              orderDetail.data ? orderDetail.data.customerInfo.firstName : ""
            }}
                        {{ orderDetail.data ? orderDetail.data.customerInfo.lastName : "" }}
                        {{
              orderDetail.data ? orderDetail.data.customerInfo.buyNum : ""
            }}个订单
                    </h3>
                    <p class="mt_2">
                        {{ orderDetail.data ? orderDetail.data.customerInfo.email : "" }}
                    </p>
                    <div class="OrderDetail_AsideList df ai_c" v-if="orderDetail.data && orderDetail.data.paymentMethod">
                        <h3 class="pr_5">支付方式</h3>
                        <i class="ml_a mr_2 el-icon-postcard" style="font-size: 22px"></i>
                        <p>
                            {{
                paymentMethodTypes.find(
                  (v) => v.value == orderDetail.data.paymentMethod
                ).name
              }}
                        </p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c" v-if="orderDetail.data && orderDetail.data.paymentChannel">
                        <h3 class="mr_a pr_5">支付渠道</h3>
                        <p>{{ orderDetail.data.paymentChannel }}</p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c">
                        <h3 class="mr_a pr_5">物流方案</h3>
                        <p>
                            {{
                orderDetail.data ? orderDetail.data.logisticsFreightPlan : ""
              }}
                        </p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c">
                        <h3 class="mr_a pr_5">访问IP</h3>
                        <p>{{ orderDetail.data ? orderDetail.data.visitIp : "" }}</p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c">
                        <h3 class="mr_a pr_5">订单来源</h3>
                        <p>{{ orderDetail.data ? orderDetail.data.orderSource : "" }}</p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c">
                        <h3 class="mr_a pr_5">渠道来源</h3>
                        <p>{{ orderDetail.data ? channelSourceData.find( v => v.value == orderDetail.data.channelSource ).name : "" }}</p>
                    </div>
                    <div class="OrderDetail_AsideList df ai_c">
                        <h3 class="mr_a pr_5">订单访问设备</h3>
                        <p>
                            {{
                orderDetail.data
                  ? visitDeviceTypes[orderDetail.data.visitDevice]
                  : ""
              }}
                        </p>
                    </div>
                    <div class="OrderDetail_AsideList">
                        <header>
                            <h3>收货信息</h3>
                        </header>
                        <p class="mt_0" style="line-height: 36px">
                            姓：{{ orderDetail.data ? orderDetail.data.shippingLastName : ""
              }}<br />
                            名：{{ orderDetail.data ? orderDetail.data.shippingFirstName : ""
              }}<br />
                            电话：+{{
                orderDetail.data ? orderDetail.data.shippingPhoneCode : ""
              }}
                            {{ orderDetail.data ? orderDetail.data.shippingPhone : "" }}<br />
                            邮箱：{{ orderDetail.data ? orderDetail.data.shippingEmail : ""
              }}<br />
                            国家/地区：{{
                orderDetail.data ? orderDetail.data.shippingCountryEn : ""
              }}<br />
                            省份：{{
                orderDetail.data ? orderDetail.data.shippingProvinceEn : ""
              }}<br />
                            城市：{{ orderDetail.data ? orderDetail.data.shippingCity : ""
              }}<br />
                            地址：{{ orderDetail.data ? orderDetail.data.shippingAddress : ""
              }}<br />
                            邮编：{{ orderDetail.data ? orderDetail.data.shippingZip : "" }}
                        </p>
                    </div>
                </aside>
            </div>
        </el-dialog>

        <!-- 退款 -->
        <el-dialog id="RefundDialog" title="退款" fullscreen :visible.sync="refundDialog" :close-on-click-modal="false" @close="refundDialogClosed">
            <div class="dg ai_s" style="grid-template-columns: 1fr 385px; gap: 16px;">
                <!-- 左侧 -->
                <main>
                    <el-form ref="refundForm" size="medium" label-position="top" v-if="refundForm.post" :model="refundForm" :rules="refundRules">

                        <!-- 退款商品列表 -->
                        <section class="mb_3" style="background-color: #FFF;" v-show="refundForm.refundingGoodsData.length">
                            <el-table ref="refundGoodsTable" :class="{ HideSelectAll: isManualRefund == 1 }" :data="refundForm.refundingGoodsData" @select="selectRefundingGoods" @select-all="selectRefundingGoods">
                                <el-table-column type="selection" width="60" :selectable="() => isManualRefund == 0"></el-table-column>
                                <el-table-column min-width="160">
                                    <template slot="header">
                                        <div class="tl">商品</div>
                                    </template>
                                    <template slot-scope="scope">
                                        <div class="df ai_c">
                                            <div class="df fd_c ai_c">
                                                 <GoodsImageTemplate :images="scope.row.goodsImage" :isOrderItems="true" :orderMainImage="scope.row.goodsImages" width="60" height="60"></GoodsImageTemplate>
                                                <el-tag type="primary" size="mini" class="mt_2">
                                                    {{ fulfillmentGoodsStatusTypes.find( (v) => v.value == scope.row.fulfillmentStatus ).name }}
                                                </el-tag>
                                            </div>
                                            <div class="pl_3 tl" style="color: #909399; line-height: 24px">
                                                <span style="color: #303133">{{ scope.row.goodsTitle }}</span><br />
                                                <span v-if="scope.row.goodsOption">Style: {{ scope.row.goodsOption["1"] }}-{{ scope.row.goodsOption["2"] }}<br /></span>
                                                SKU: {{ scope.row.sku }}<br />
                                                {{ $yrFormatNumber(scope.row.price, ",", 2) }} USD
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="可退总数" min-width="120" prop="canRefundQuantity"></el-table-column>
                                <el-table-column label="退款数量" min-width="120" #default="{ row }">
                                    <el-form-item class="formItemOfTd">
                                        <el-input-number :min="1" :max="row.canRefundQuantity" :precision="0" :disabled="isManualRefund == 1" v-model="row.num" @blur="selectRefundingGoods"></el-input-number>
                                    </el-form-item>
                                </el-table-column>
                                <el-table-column label="税后可退款金额" min-width="120" #default="{ row }">
                                    {{ $yrFormatNumber( row.afterTaxRefundTotal, ",", 2 ) }} USD
                                </el-table-column>
                            </el-table>
                        </section>

                        <!-- 已退款商品列表 -->
                        <section v-if="refundForm.refundedGoodsData.length">
                            <h3 class="mt_0">已退款商品</h3>
                            <el-table :data="refundForm.refundedGoodsData">
                                <el-table-column width="24"></el-table-column>
                                <el-table-column min-width="160">
                                    <template slot="header">
                                        <div class="tl">商品</div>
                                    </template>
                                    <template slot-scope="scope">
                                        <div class="df ai_c">
                                            <div class="df fd_c ai_c">
                                                 <GoodsImageTemplate :images="scope.row.goodsImage" :isOrderItems="true" :orderMainImage="scope.row.goodsImages" width="60" height="60"></GoodsImageTemplate>
                                                <el-tag type="primary" size="mini" class="mt_2">
                                                    {{ (fulfillmentGoodsStatusTypes.find( v => v.value == scope.row.fulfillmentStatus ) || {}).name }}
                                                </el-tag>
                                            </div>
                                            <div class="pl_3 tl" style="color: #909399; line-height: 24px">
                                                <span style="color: #303133">{{ scope.row.goodsTitle }}</span><br />
                                                <span v-if="scope.row.goodsOption">Style: {{ scope.row.goodsOption["1"] }}-{{ scope.row.goodsOption["2"] }}<br /></span>
                                                SKU: {{ scope.row.sku }}<br />
                                                {{ $yrFormatNumber(scope.row.price, ",", 2) }} USD
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="已退款数量" min-width="120" prop="refundQuantity"></el-table-column>
                                <el-table-column label="已退款金额" min-width="120">
                                    <template slot-scope="scope">
                                        {{ $yrFormatNumber(scope.row.refundTotal, ",", 2) }} USD
                                    </template>
                                </el-table-column>
                            </el-table>
                        </section>

                        <!-- 退款表单 -->
                        <section class="df py_3 px_4" style="background-color: #FFF;" v-if="orderDetail.data">
                            <div class="pr_5" style="flex: 1 1 640px">
                                <div class="df jc_sb ai_c fs16" style="color: #606266">
                                    <span class="bold">退款金额</span>
                                    <el-checkbox v-show="orderDetail.data.tipPrice" :disabled="orderDetail.data.isTipRefund == 1 || !!isManualRefund" v-model="hasRefundTipPrice" @change="changeRefundTipPrice">是否需要小费退款</el-checkbox>
                                </div>
                                <div class="mod-has-checkbox">
                                    <el-checkbox :disabled="orderDetail.data.isManualRefund == 1" v-model="isManualRefund" @change="changeManualRefund">手动输入退款金额</el-checkbox>
                                </div>

                                <el-input class="pt_3" v-model="refundAmount" :disabled="isManualRefund == 0">
                                    <template slot="append">USD</template>
                                </el-input>
                                <div class="pt_2" style="height: 30px; color: #f56c6c; font-size: 12px" v-if="parseFloat( refundAmount ) > orderDetail.data.remainRefundTotal">
                                    <span>退款金额不能超过最大可退款金额</span>
                                </div>
                                <p>剩余可退金额{{ orderDetail.data.remainRefundCashTotal || 0 }} USD</p>

                                <div class="gift-refund" v-if="refundForm.shopOrderGifts">
                                    <div class="item" v-for="(item, index) in refundForm.shopOrderGifts" :key="index">
                                        <p>礼品卡 (****{{ item.giftCardNo.slice(-4) }})</p>
                                        <el-input v-model="item.giftRefundAmount" :disabled="isManualRefund == 0">
                                            <template slot="append">USD</template>
                                        </el-input>
                                        <div class="pt_2" style="height: 30px; color: #f56c6c; font-size: 12px" v-show=" +item.refundablePrice < +item.giftRefundAmount ">
                                            <span>退款礼品卡金额不能超过最大礼品卡金额</span>
                                        </div>
                                        <p>剩余可退金额{{ item.refundablePrice }} USD</p>
                                    </div>
                                </div>

                                <div class="df jc_sb ai_c fs16 pt_3" style="color: #606266;">
                                    <span>税后商品可退款金额</span>
                                    <span class="bold">{{ $yrFormatNumber(orderDetail.data.afterTaxTotal, ",", 2) }} USD</span>
                                </div>
                                <div class="df jc_sb ai_c" style="color: #909399">
                                    <p>小费金额</p>
                                    <p>
                                        {{ $yrFormatNumber(orderDetail.data.tipPrice, ",", 2) }} USD
                                    </p>
                                </div>
                                <div class="df jc_sb ai_c" style="color: #909399">
                                    <p>运费金额</p>
                                    <p>
                                        {{ $yrFormatNumber(orderDetail.data.totalShipping, ",", 2) }} USD
                                    </p>
                                </div>
                                <div class="df jc_sb ai_c" style="color: #909399">
                                    <p>运费险金额</p>
                                    <p>
                                        {{ $yrFormatNumber(orderDetail.data.insurePrice, ",", 2) }} USD
                                    </p>
                                </div>
                                <div class="df jc_sb ai_c" style="color: #909399">
                                    <span>订单剩余可退款金额</span>
                                    <span>{{ $yrFormatNumber(orderDetail.data.remainRefundTotal, ",", 2) }} USD</span>
                                </div>
                            </div>
                            <div class="pt_5 pl_5 tc" style="flex: 1 1 320px; border-left: dashed 1px #606266">
                                <div class="df jc_sb ai_c mt_5 mb_3" style="color: #909399">
                                    <p>退款方式</p>
                                    <p>{{ +orderDetail.data.cashTotal === 0 && refundForm.shopOrderGifts ? '礼品卡' : orderDetail.data.paymentChannel }}</p>
                                </div>
                                <!-- refundAmount == '0.00' || -->
                                <el-button type="primary" style="width: 70%; min-width: 130px;" :disabled=" parseFloat( refundAmount ) > orderDetail.data.remainRefundTotal || btnLoading == 'refund'" :loading="btnLoading == 'refund'" @click="refund">确认退款</el-button>
                            </div>
                        </section>

                    </el-form>
                </main>

                <!-- 右侧 -->
                <aside style="color: #606266;" v-if="orderDetail.data">
                    <div class="pt_3" style="background-color: #fff">
                        <h2 class="mt_0 mb_4 px_4">订单金额</h2>
                        <div class="df jc_sb ai_c px_4 fs16">
                            <p class="mt_0">商品总价</p>
                            <p class="mt_0">
                                {{ $yrFormatNumber(orderDetail.data.goodsTotal, ",", 2) }} USD
                            </p>
                        </div>
                        <div class="df jc_sb ai_c px_4 fs16">
                            <p class="mt_0">折扣</p>
                            <p class="mt_0">
                                {{ $yrFormatNumber(orderDetail.data.discountTotal, ",", 2) }}
                                USD
                            </p>
                        </div>
                        <div class="df jc_sb ai_c px_4 fs16 bold" style="background-color: #f7f8fa">
                            <p>商品税前实付价</p>
                            <p>
                                {{ $yrFormatNumber(orderDetail.data.preTaxTotal, ",", 2) }} USD
                            </p>
                        </div>
                        <!-- <div class="df jc_sb ai_c px_4 fs16">
                            <p>税费</p>
                            <p>
                                {{ $yrFormatNumber(orderDetail.data.totalTax, ",", 2) }} USD
                            </p>
                        </div> -->
                        <div class="df jc_sb ai_c px_4 fs16 bold" style="background-color: #f7f8fa">
                            <p>税后商品实付金额</p>
                            <p>
                                {{ $yrFormatNumber(orderDetail.data.afterTaxTotal, ",", 2) }}
                                USD
                            </p>
                        </div>
                        <div class="df jc_sb ai_c px_4 fs16">
                            <p>运费</p>
                            <p>
                                {{ $yrFormatNumber(orderDetail.data.totalShipping, ",", 2) }}
                                USD
                            </p>
                        </div>
                        <div class="df jc_sb ai_c px_4 fs16">
                            <p>运费险金额</p>
                            <p>
                                {{ $yrFormatNumber(orderDetail.data.insurePrice, ",", 2) }} USD
                            </p>
                        </div>
                        <div class="df jc_sb ai_c px_4 fs16" style="color: #909399">
                            <p>小费金额</p>
                            <p>
                                {{ $yrFormatNumber(orderDetail.data.tipPrice, ",", 2) }} USD
                            </p>
                        </div>
                        <div class="df jc_sb ai_c mb_4 px_4 fs16 bold" style="background-color: #f7f8fa">
                            <p>实际总付款金额</p>
                            <p>
                                {{ $yrFormatNumber(orderDetail.data.totalPrice, ",", 2) }} USD
                            </p>
                        </div>

                    </div>
                    <div class="df jc_sb ai_c mt_3 px_4 fs16 bold" style="background-color: #fff">
                        <p>已退款总金额</p>
                        <p>
                            {{ $yrFormatNumber(orderDetail.data.refundTotal, ",", 2) }} USD
                        </p>
                    </div>
                </aside>
            </div>
        </el-dialog>
        <!-- 退款 -->

    </div>
</template>

<script>
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
export default {
    name: 'OrderAfterSale',
    components: {
        GoodsImageTemplate,
    },
    data() {
        return {
            btnLoading: '',

            /* 类型集合 */
            afterSaleStatusTypes: [
                // 售后状态

                { name: '待处理', value: '1' },
                { name: '已处理', value: '2' },
                { name: '已完成', value: '3' },
                { name: '处理失败', value: '4' },
            ],
            queryTypes: [
                // 搜索关键字类型

                { name: '订单号', value: 'orderNo' },
            ],
            paymentMethodTypes: [
                // 支付方式

                { name: '线上支付', value: '1' },
                { name: '信用卡', value: '2' },
            ],
            orderStatusTypes: [
                // 订单状态

                { name: '未完成', value: '1' },
                { name: '已完成', value: '2' },
                { name: '已取消', value: '3' },
            ],
            visitDeviceTypes: {
                // 访问设备

                1: 'PC端',
                2: '移动端',
                3: '其它设备',
            },
            financialStatusTypes: [
                // 支付状态

                { name: '待支付', value: '1' },
                { name: '已支付', value: '2' },
                { name: '已取消', value: '3' },
                { name: '支付失败', value: '4' },
                { name: '待退款', value: '5' },
                { name: '部分退款', value: '6' },
                { name: '已退款', value: '7' },
                { name: '退款失败', value: '8' },
            ],
            fulfillmentStatusTypes: [
                // 订单发货状态

                { name: '待发货', value: '1' },
                { name: '部分发货', value: '2' },
                { name: '已发货', value: '3' },
                { name: '部分完成', value: '4' },
                { name: '已完成', value: '5' },
                { name: '已退货', value: '6' },
            ],
            fulfillmentGoodsStatusTypes: [
                // 物流商品发货状态

                { name: '待发货', value: '1' },
                { name: '已发货', value: '2' },
                { name: '已完成', value: '3' },
                { name: '已退货', value: '4' },
                { name: '部分发货', value: '5' },
            ],

            channelSourceData: [
                // 售后订单详情 - 渠道来源数据

                { name: '其它来源', value: 0 },
                { name: '直接访问', value: 1 },
                { name: 'Google', value: 2 },
                { name: 'Facebook', value: 3 },
                { name: 'Instagram', value: 4 },
                { name: 'Bing', value: 5 },
                { name: 'Baidu', value: 6 },
                { name: 'Snapchat', value: 7 },
                { name: 'pinterest', value: 8 },
                { name: 'youtube', value: 9 },
                { name: 'TikTok', value: 10 },
				{ name: 'Email', value: 11 },
            ],

            /* 列表查询参数 */
            afterSaleStatus: '1', // 售后状态
            queryType: null, // 搜索关键字类型
            queryKeyword: null, // 搜索关键字
            queryForm: {
                afterSaleStatus: null,
                startTime: null,
                endTime: null,
                pageNo: 1,
                pageSize: 10,
            },

            /* 列表数据 */
            loading: false,
            tableData: [],
            total: 0,

            /* 订单详情 */
            dialogOrderDetail: false,
            orderDetail: {
                data: null, // 订单详情数据

                // 订单备注
                hideOrderRemarkForm: true,
                orderRemarkForm: {
                    id: null,
                    afterSaleRemark: null,
                },
            },

            // 退款
            refundDialog: false,
            isManualRefund: false, // 标记操作中的是否手动
            hasRefundTipPrice: false,
            refundAmount: '0.00',
            refundRules: {},
            refundRulesEmpty: {
                required: true,
                message: '不能为空',
                trigger: 'blur',
            },
            refundForm: {},
        };
    },

    mounted() {
        this.queryData();
    },

    methods: {
        // 重置列表查询条件
        resetCondition() {
            this.queryType = null;
            this.queryKeyword = null;
            this.queryTypes.forEach(
                (item) => delete this.queryForm[item.value]
            );

            this.queryForm.afterSaleStatus = null;
            this.queryForm.startTime = null;
            this.queryForm.endTime = null;
            this.queryForm.pageNo = 1;

            this.queryData();
        },

        // 获取列表数据
        queryData() {
            // 查询条件
            this.queryForm.afterSaleStatus =
                this.afterSaleStatus == '0'
                    ? null
                    : parseInt(this.afterSaleStatus); // 售后状态
            let params = {
                ...this.queryForm,
            };
            params[this.queryType] = this.queryKeyword;

            this.loading = true;

            this.$http
                .get('/order/after/sale/getPage', { params })
                .then((r) => {
                    this.loading = false;

                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        this.total = r.data.count;
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    // this.$message.error(error);;
                });
        },

        // 获取订单详情
        getOrderDetail(item) {
            return new Promise((resolve) => {
                this.orderDetail.data = null;
                this.orderDetail.hideOrderRemarkForm = true;

                this.$http
                    .get('/order/after/sale/getDetail', {
                        params: { id: item.id },
                    })
                    .then((r) => {
                        if (r.data.code === 200) {
                            this.orderDetail.data = r.data.data;
                            resolve();
                        }
                    });

                this.dialogOrderDetail = true;
            });
        },

        // 编辑订单备注
        initOrderRemark() {
            this.orderDetail.orderRemarkForm = {
                id: this.orderDetail.data.id,
                afterSaleRemark: this.orderDetail.data.afterSaleRemark,
            };

            this.orderDetail.hideOrderRemarkForm = false;
        },
        saveOrderRemark() {
            this.btnLoading = 'saveOrderRemark';

            this.$http
                .post(
                    '/order/after/sale/remark',
                    this.orderDetail.orderRemarkForm
                )
                .then((r) => {
                    if (r.data.code === 200) {
                        this.btnLoading = '';
                        this.orderDetail.hideOrderRemarkForm = true;

                        this.$message({
                            type: 'success',
                            message: '保存成功',
                            duration: 1000,
                        });

                        this.orderDetail.data.afterSaleRemark =
                            this.orderDetail.orderRemarkForm.afterSaleRemark;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    this.btnLoading = '';
                });
        },

        // 取消售后订单
        cancelAfterSaleOrder(id) {
            this.$confirm('确认取消售后订单吗？', { type: 'warning' })
                .then(() => {
                    this.btnLoading = 'cancelAfterSaleOrder';

                    this.$http
                        .delete('/order/after/sale/cancel', { params: { id } })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.btnLoading = '';

                                this.$message({
                                    type: 'success',
                                    message: '操作成功',
                                    duration: 1000,
                                    onClose: () => {
                                        this.queryData();
                                        this.dialogOrderDetail = false;
                                    },
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = '';
                        });
                })
                .catch(() => {});
        },

        /* 退款 */
        initRefund() {
            let { data } = this.orderDetail,
                {
                    isManualRefund,
                    isTipRefund,
                    orderItems,
                    id,
                    shopOrderGifts,
                } = data;

            this.isManualRefund = isManualRefund;
            this.refundAmount = '0.00';
            this.hasRefundTipPrice = !!isTipRefund;

            let refundingGoodsData = [];

            if (data) {
                refundingGoodsData = orderItems
                    .filter((v) => v.isRefund == 0)
                    .map((row) => {
                        let newRow = { ...row };

                        newRow.num = row.canRefundQuantity;
                        return newRow;
                    });
            }

            this.refundForm = {
                post: { id },
                refundingGoodsData,
                refundedGoodsData: data
                    ? orderItems.filter((v) => v.isRefund == 1)
                    : [],
            };

            if (shopOrderGifts && shopOrderGifts.length) {
                // let _shopOrderGifts = [...shopOrderGifts];
                let _shopOrderGifts = shopOrderGifts;
                _shopOrderGifts.forEach((item) => {
                    this.$set(item, 'giftRefundAmount', '0.00');
                    // this.$set(item, '_refundablePrice', item.refundablePrice);
                });

                console.dir(_shopOrderGifts);

                this.refundForm['shopOrderGifts'] = _shopOrderGifts;
            }

            this.refundDialog = true;
        },
        refund() {
            this.btnLoading = 'refund';

            let hasGifts = !!this.refundForm?.shopOrderGifts,
                { ...requestData } = this.refundForm.post,
                selections = this.$refs.refundGoodsTable.selection;

            requestData.isTipRefund = +this.hasRefundTipPrice;
            requestData.isManualRefund = +this.isManualRefund;
            requestData.refundAmount = this.refundAmount;
            if (!this.isManualRefund) {
                requestData.refundOrderItems = selections.length
                    ? selections.map((v) => ({
                          orderItemId: v.id,
                          refundQuantity: v.num,
                      }))
                    : null;
            }

            if (hasGifts) {
                let giftsData = this.refundForm.shopOrderGifts.map((item) => {
                    let { id, giftRefundAmount } = item;
                    return {
                        id,
                        refundTotal: +giftRefundAmount,
                    };
                });
                console.dir(giftsData);
                requestData.shopManualOrderGifts = giftsData;
            }

            this.$http
                .post('/order/after/sale/confirmRefund', requestData)
                .then((r) => {
                    if (r.data.code === 200) this.$message.success('操作成功');
                    this.getOrderDetail({ id: this.orderDetail.data.id }).then(
                        () => this.initRefund()
                    );
                })
                .finally(() => (this.btnLoading = ''));
        },
        refundDialogClosed() {
            this.refundAmount = '0.00';
            this.$refs.refundForm?.resetFields();
            this.refundForm = {};
        },
        /* 退款 end */

        // selectRefundingGoodsAll(items) {
        //     if (this.isManualRefund) {
        //         this.isManualRefund = false;
        //         // this.$nextTick(() => {
        //         //     this.$refs.refundGoodsTable.toggleAllSelection();
        //         // });
        //     }
        // },

        getHasRefundInsurePrice(isCheckAll) {
            let items = this.$refs.refundGoodsTable.selection,
                _table = this.refundForm.refundingGoodsData,
                isFillFull = items.every(
                    (item) => item.num == item.canRefundQuantity
                ),
                isFulfillmentStatus1 = _table.every(
                    (item) => item.fulfillmentStatus == 1
                ),
                hasRefundInsurePrice =
                    isCheckAll && isFillFull && isFulfillmentStatus1;

            return hasRefundInsurePrice;
        },

        getTipPrice() {
            let { isTipRefund, tipPrice } = this.orderDetail.data,
                add_tipPrice = this.hasRefundTipPrice
                    ? isTipRefund
                        ? 0
                        : tipPrice
                    : 0;

            return add_tipPrice;
        },

        // 选择退款商品
        selectRefundingGoods() {
            let isAllRefund = true,
                { insurePrice, tipPrice, totalPrice, refundTotal } =
                    this.orderDetail.data,
                items = this.$refs.refundGoodsTable.selection,
                _table = this.refundForm.refundingGoodsData,
                isCheckAll = items.length == _table.length,
                hasRefundInsurePrice = this.getHasRefundInsurePrice(),
                sub_insurePrice = hasRefundInsurePrice ? 0 : insurePrice,
                sub_tipPrice = this.hasRefundTipPrice ? 0 : tipPrice,
                add_tipPrice = this.getTipPrice(),
                hasGifts = !!this.refundForm?.shopOrderGifts;

            let both_amout =
                    items.reduce((a, b) => {
                        let num = b.num ?? 0;
                        if (num < b.canRefundQuantity) isAllRefund = false;
                        return (
                            a +
                            (num / b.canRefundQuantity) * b.afterTaxRefundTotal
                        );
                    }, 0.0) || 0,
                price;

            if (isCheckAll && isAllRefund) {
                price = (
                    totalPrice -
                    refundTotal -
                    sub_insurePrice -
                    sub_tipPrice
                )
                    .toFixed(2)
                    .replace('-', '');
            } else {
                price = (both_amout + add_tipPrice).toFixed(2);
            }

            if (hasGifts) {
                this.setGiftPrice(price);
                return;
            }

            this.refundAmount = price;
        },
        setGiftPrice(price) {
            this.refundForm.shopOrderGifts.forEach(
                (item) => (item.giftRefundAmount = 0)
            );

            let sub = price,
                amount = 0;
            for (let item of this.refundForm.shopOrderGifts) {
                let { refundablePrice } = item;
                let _sub = (sub - refundablePrice).toFixed(2);

                if (_sub < 0) {
                    item.giftRefundAmount = Math.abs(sub);
                    amount = 0;
                    break;
                } else if (_sub == 0) {
                    item.giftRefundAmount = refundablePrice;
                    amount = 0;
                    break;
                } else {
                    item.giftRefundAmount = refundablePrice;
                    sub = _sub;
                    amount = _sub;
                }
            }
            this.refundAmount = amount;
        },

        // 是否手动输入退款金额
        changeManualRefund(seleced) {
            let hasGifts = !!this.refundForm?.shopOrderGifts;
            if (seleced) {
                this.clearRefundingGoodsSelect();
                this.refundForm.refundingGoodsData.forEach(
                    (row) => (row.num = undefined)
                );
            }

            if (hasGifts) {
                this.refundForm['shopOrderGifts'].forEach((item) => {
                    item.giftRefundAmount = '0.00';
                    item._refundablePrice = item.refundablePrice;
                });
            }

            this.refundAmount = '0.00';
        },

        // 是否需要小费退款
        changeRefundTipPrice(seleced) {
            // let { tipPrice } = this.orderDetail.data,
            // hasGifts = !!this.refundForm?.shopOrderGifts;

            // if (seleced) {

            //     this.refundAmount = (+this.refundAmount + tipPrice).toFixed(2);
            //     return;
            // }
            // let _refoundValue = +this.refundAmount - tipPrice;
            // this.refundAmount = (_refoundValue < 0 ? 0 : _refoundValue).toFixed(
            //     2
            // );
            this.selectRefundingGoods();
        },

        // 清除退款商品选中状态
        clearRefundingGoodsSelect() {
            this.$refs.refundGoodsTable?.clearSelection();
        },
    },
};
</script>

<style scoped>
#OrderDetailRemark > .el-icon-edit:hover {
    opacity: 0.8;
}
.HideSelectAll /deep/ th .el-checkbox {
    display: none;
}
</style>
<style>
.order-list-query-keyword .el-select .el-input {
    width: 90px;
    color: #606266;
}

.order-list-query-keyword .el-input-group__prepend {
    background-color: #fff;
}

/* 查看详情弹窗 */
#orderDetailDialog > .el-dialog {
    background: #ecedf3;
}

.OrderDetail_AsideList {
    border-top: solid 1px #d3d8e9;
}

.OrderDetail_AsideList > h3 {
    white-space: nowrap;
}

.mod-has-checkbox {
    display: flex;
    justify-content: flex-end;
    padding-top: 4px;
}

/* 退款 */
#RefundDialog .el-dialog {
    background-color: #ecedf3;
}
</style>