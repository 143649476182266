<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>营销管理</el-breadcrumb-item>
            <el-breadcrumb-item>满送优惠</el-breadcrumb-item>
        </el-breadcrumb>

        <el-tabs v-model="activeTabs" @tab-click="queryData">
            <el-tab-pane v-for="item in status" :key="item.value" :label="item.name" :name="item.value"></el-tab-pane>
        </el-tabs>

        <el-form :inline="true" :model="queryForm">
            <el-form-item>
                <el-input v-model="queryForm.campaignName" placeholder="活动名称"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="querySearch">查询</el-button>
                <el-button icon="el-icon-refresh-left" @click="resetQuery">重置</el-button>
                <el-button type="success" icon="el-icon-plus" @click="showDialog">新增</el-button>
            </el-form-item>
        </el-form>

        <el-table border stripe element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000" :data="tableData" v-loading="loading">
            <el-table-column label="ID" prop="id">
            </el-table-column>
            <el-table-column label="活动名称" prop="campaignName">
            </el-table-column>
            <el-table-column label="活动时间" prop="id" min-width="100px">
                <template #default="{row}">
                    {{ row.startTime}} - <br> {{ row.endTime || '不限结束时间' }}
                </template>
            </el-table-column>
            <el-table-column label="优惠项" prop="id" min-width="100px">
                <template #default="{row}">
                    <template>
                        满{{ row.giveType == 1? `$${row.giveNum}` : `${row.giveNum}件` }}赠送{{ row.givePriceType == 1 ? `按订单金额实付比例${row.givePrice}%金额` : `$${row.givePrice}` }}{{ row.giveWelfare == 1 ? '礼品卡' : '' }}
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="活动状态">
                <template #default="{row}">
                    {{ translateCampaignStatus(row.campaignStatus) }}
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime">
            </el-table-column>
            <el-table-column label="操作" min-width="140px">
                <template #default="{row}">
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="onlyViewItem(row)" v-if="row.campaignStatus == 3">详情</el-button>
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="viewItem(row)" v-else>编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="endEvent(row)" v-if="row.campaignStatus !=3">提前结束</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-dialog :title="`${isAdding? '新增' : '编辑'}满送优惠活动`" :visible.sync="visibleDialogInfo" :close-on-click-modal="false" top="3%" fullscreen class="dialog-info" @closed="resetDialogInfo">
            <el-form :inline="true" :model="infoForm" label-width="160px" class="info-form" :rules="rules" ref="infoForm" :disabled="justOnlyView">
                <div class="mod">
                    <strong>基本信息</strong>
                    <el-form-item label="活动名称" prop="campaignName">
                        <el-input v-model="infoForm.campaignName"></el-input>
                    </el-form-item>

                    <div class="date-mod">
                        <el-form-item label="活动时间" prop="startTime">
                            <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间" v-model="infoForm.startTime" :disabled="!isAdding">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item class="px_1" style="color: #bbb">～</el-form-item>
                        <el-form-item prop="endTime">
                            <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间" v-model="infoForm.endTime" :disabled="disabledDate">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item>
                            <el-checkbox v-model="infoForm.checked" style="margin-left:20px;" @change="dateChange">不限结束时间</el-checkbox>
                        </el-form-item>
                    </div>

                    <!-- <el-form-item label="活动时间" prop="giftPrice">
                        <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels v-model="infoForm.date" value-format="yyyy-MM-dd HH:mm:ss" :default-value="new Date()" :disabled="disabledDate">
                        </el-date-picker>
                        <el-checkbox v-model="infoForm.checked" style="margin-left:20px;" @change="dateChange">不限结束时间</el-checkbox>
                    </el-form-item> -->
                </div>

                <div class="mod">
                    <strong>优惠规则</strong>

                    <div class="fixer">
                        <el-form-item label="满送类型" prop="giveType">
                            <el-select v-model="infoForm.giveType">
                                <el-option label="满实付金额送" :value="1"></el-option>
                                <el-option label="满购买件数送" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <span>满</span>
                        <el-form-item prop="giveNum">
                            <el-input v-model="infoForm.giveNum" placeholder="" type="number" class="number-input"></el-input>
                        </el-form-item>
                        <span v-show="infoForm.giveType == 1">元</span>
                        <span v-show="infoForm.giveType == 2">件</span>
                    </div>

                    <el-form-item label="赠送福利" prop="giveWelfare">
                        <el-select v-model="infoForm.giveWelfare" placeholder="赠送福利">
                            <el-option label="礼品卡" :value="1"></el-option>
                        </el-select>
                    </el-form-item>

                    <div class="template" style="padding-left:160px;">
                        <el-radio-group v-model="infoForm.givePriceType" @change="changeType">
                            <el-radio :label="1">
                                按订单实付金额比例
                            </el-radio>
                            <el-radio :label="2">
                                固定金额
                            </el-radio>
                        </el-radio-group>
                        <p>
                            <el-form-item prop="pct" v-if="infoForm.givePriceType == 1">
                                <el-input v-model="infoForm.pct" type="number" @focus="infoForm.givePriceType = 1" :min="1" :max="9999" class="number-input" @input="formatToFixed2($event, 'pct')" style="width:300px">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="num" v-if="infoForm.givePriceType == 2">
                                <el-input v-model="infoForm.num" type="number" @focus="infoForm.givePriceType = 2" class="number-input" @input="formatToFixed2($event, 'num')" style="width:300px">
                                    <template slot="append">$</template>
                                </el-input>
                            </el-form-item>
                            <!-- <el-input v-model="infoForm.num" type="number" @focus="infoForm.givePriceType = 2" :min="1" :max="9999" class="number-input" @input="formatToFixed2($event, 'num')" v-show="infoForm.givePriceType == 2" style="width:300px">
                                <template slot="append">$</template>
                            </el-input> -->
                        </p>
                    </div>

                    <el-form-item label="生效时间 成功下单后" prop="orderSuccessDay">
                        <div class="fixer">
                            <!-- <span style="width:120px;"></span> -->
                            <el-input v-model="infoForm.orderSuccessDay" type="number" class="number-input" :disabled="!isAdding"></el-input>
                            <span>天</span>
                        </div>
                    </el-form-item>
                </div>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="loading == 'submit' " @click="bridge_submitInfo" :disabled="justOnlyView">确定</el-button>
                <el-button @click="visibleDialogInfo = false">取消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: 'Giftwithpurchase',
    data: () => ({
        queryForm: {
            campaignName: '',
            pageNo: 1,
            pageSize: 20,
        },
        infoForm: {
            campaignName: '',
            giveType: 1,
            giveWelfare: 1,
            givePriceType: 1,
            checked: false,
            pct: null,
            num: null,
            giveNum: null,
            orderSuccessDay: null,
            startTime: null,
            endTime: null,
        },
        rules: {
            campaignName: [
                {
                    required: true,
                    message: '请输入活动名称',
                    trigger: 'blur',
                },
            ],

            startTime: [
                {
                    required: true,
                    message: '请选择活动时间',
                    trigger: 'blur',
                },
                {
                    required: true,
                    message: '请选择活动时间',
                    trigger: 'change',
                },
            ],
            giveType: [
                {
                    required: true,
                    message: '请选择满送类型',
                    trigger: 'change',
                },
            ],
            giveNum: [
                {
                    required: true,
                    message: '请输入满送件/元',
                    trigger: 'blur',
                },
            ],

            giveWelfare: [
                {
                    required: true,
                    message: '请选择赠送福利',
                    trigger: 'change',
                },
            ],

            orderSuccessDay: [
                {
                    required: true,
                    message: '请输入成功下单天数',
                    trigger: 'blur',
                },
            ],

            pct: [
                {
                    required: true,
                    message: '请输入订单实付金额比例',
                    trigger: 'blur',
                },
            ],
            num: [
                {
                    required: true,
                    message: '请输入固定金额',
                    trigger: 'blur',
                },
            ],
        },
        status: [
            { name: '全部', value: '0' },
            { name: '未开始', value: '1' },
            { name: '进行中', value: '2' },
            { name: '已结束', value: '3' },
        ],

        activeTabs: '0',
        isAdding: true,
        visibleDialogInfo: false,
        loading: null,
        total: 0,
        tableData: [],
        disabledDate: false,
        justOnlyView: false,
    }),

    computed: {
        translateCampaignStatus() {
            return function (val) {
                let stu = this.status.find((item) => {
                    return item.value == val;
                });
                if (stu) {
                    return stu['name'];
                }
                return '';
            };
        },
    },

    mounted() {
        this.queryData();
    },

    methods: {
        querySearch() {
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        showDialog() {
            this.visibleDialogInfo = true;
        },
        resetQuery() {
            this.queryForm = this.$options.data.call(this).queryForm;
            this.queryData();
        },
        queryData() {
            const URL = '/campaign/full/give/getPage',
                params = {
                    ...this.queryForm,
                    campaignStatus: +this.activeTabs ? this.activeTabs : '',
                };

            this.loading = 'searching';
            this.$http
                .get(URL, { params })
                .then((res) => {
                    let { code, data, count } = res.data;

                    if (code === 200) {
                        this.tableData = data;
                        this.total = count;
                    }
                })
                .finally(() => {
                    this.loading = null;
                });
        },
        endEvent({ id }) {
            this.$confirm('确定要结束该满减优惠吗？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                let {
                    data: { code },
                } = await this.$http.get(`/campaign/full/give/end?id=${id}`);
                if (code === 200) {
                    this.$message.success('该满减优惠已结束~');
                    this.queryData();
                }
            });
        },
        onlyViewItem(row) {
            this.justOnlyView = true;
            this.viewItem(row);
        },
        viewItem(row) {
            let { endTime, givePriceType, givePrice, ...args } = row,
                params = {
                    givePriceType,
                    endTime,
                    ...args,
                };

            if (!endTime) {
                params['checked'] = true;
            }
            params[givePriceType == 1 ? 'pct' : 'num'] = givePrice;

            Object.assign(this.infoForm, params);
            this.isAdding = false;
            this.visibleDialogInfo = true;
        },
        resetDialogInfo() {
            let { infoForm, isAdding, justOnlyView } =
                this.$options.data.call(this);

            this.infoForm = infoForm;
            this.isAdding = isAdding;
            this.justOnlyView = justOnlyView;
            this.$refs['infoForm']?.clearValidate();
        },
        changeType() {},
        bridge_submitInfo() {
            this.$refs['infoForm'].validate((valid) => {
                if (valid) {
                    if (!this.infoForm.endTime && !this.infoForm.checked) {
                        return this.$message.warning('请选择结束时间~');
                    }

                    this.submitInfo();
                }
            });
        },
        submitInfo() {
            let { givePriceType, num, pct, checked, orderSuccessDay, ...args } =
                    this.infoForm,
                params = {
                    givePriceType,
                    givePrice: givePriceType == 1 ? pct : num,
                    orderSuccessDay: +orderSuccessDay,
                    ...args,
                },
                url = `/campaign/full/give/${this.isAdding ? 'add' : 'edit'}`;

            this.$http.post(url, params).then((res) => {
                let { code } = res.data;
                if (code === 200) {
                    this.$message.success(
                        `${this.isAdding ? '添加' : '编辑'}成功~`
                    );
                    this.queryData();
                    this.visibleDialogInfo = false;
                }
            });
        },
        dateChange(has) {
            this.disabledDate = has;
            if (has) {
                this.infoForm.endTime = null;
            }
        },
        formatToFixed2(value, gKey) {
            let str = value.replace('-', ''),
                reg =
                    /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;

            if (~value.indexOf('-')) {
                this.infoForm[gKey] = str;
            }

            // if (!reg.test(+str)) {
            //     console.log(value);
            //     if (gKey == 'pct' && ~value.indexOf('.')) {
            //         this.infoForm[gKey] = Math.floor(str);
            //         return;
            //     }
            //     this.infoForm[gKey] = +str;
            // }

            if (+value) {
                if (gKey == 'pct' && ~value.indexOf('.')) {
                    this.infoForm[gKey] = Math.floor(str);
                    return;
                }
                this.infoForm[gKey] = +str;
            }
        },
    },
};
</script>
<style scoped>
.dialog-info {
    background: #ecedf3;
}
::v-deep .dialog-info .el-dialog {
    background: transparent;
}
.mod {
    background: #fff;
    padding: 20px;
    margin-top: 20px;
}
.mod:nth-child(1) {
    margin-top: 0;
}
.mod strong {
    font-size: 18px;
    padding-bottom: 20px;
    display: block;
}
/* .info-form.el-form--inline .el-form-item {
    width: 100%;
} */

.fixer {
    display: flex;
}

.fixer span {
    margin: 0 10px;
    line-height: 38px;
}

::v-deep .el-form--inline .fixer .el-form-item {
    margin-right: 0;
}

.date-mod {
    display: flex;
    align-content: center;
}

::v-deep .number-input input::-webkit-outer-spin-button,
::v-deep .number-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
::v-deep .number-input input {
    -moz-appearance: textfield;
}
</style>