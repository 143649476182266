<!-- 选择商品/款式组件 Randy 2021-09-29
	属性说明:
	showDialog: bool，指示是否显示弹窗，默认：false，必填
	containItem: bool，指示列表是否包含款式，默认：false，选填
	selectedGoods: array，已选择的商品或款式的集合，必填
	closeDialog: function()， 关闭弹窗的回调函数，必填
	saveSelectedGoods: function( items, caller )，保存选中数据的回调函数，必填
		参数说明：
		items: 当包含款式（ containItem 为 true ）时，返回带有商品名称的款式集合；否则返回商品集合
		caller: 宿主标识，用于区分调用来源，单一调用可不处理

	使用前提：
	引入组件：import SelectGoods from '@/components/SelectGoods';
	设置组件：components:{ "SelectGoods": SelectGoods },

	调用示例：
	1、html
	// 插入组件
	<SelectGoods ref="selectGoods"
		:showDialog="dialogSelectGoods"
		:containItem="true"
		:selectedGoods="selectedGoodsTable.data"
		@closeDialog="closeSelectGoods"
		@saveSelectedGoods="saveSelectGoods">
	</SelectGoods>
	// 在需要的地方调用
	<el-button type="text" icon="el-icon-plus" @click="openSelectGoods">继续添加商品</el-button>
	2、vue - data
	// 指示是否显示商品列表弹窗
	dialogSelectGoods: false,
	3、vue - method
	// 打开商品列表弹窗
	openSelectGoods() {

		this.$refs.selectGoods.caller = "notset"; // 单一调用可不设置
		this.$refs.selectGoods.init();
		this.dialogSelectGoods = true;

	},
	// 关闭商品列表弹窗
	closeSelectGoods() {

		this.dialogSelectGoods = false;

	},
	// 保存选中数据的回调
	saveSelectGoods( items, caller ) {

		switch( caller ) { // 注：单一调用可不处理caller

			default:
				this.selectedGoodsTable.data = items;

		}

	}，
-->

<template>
    <div>
        <!-- 选择商品弹窗 -->
        <el-dialog top="3%" title="商品列表" width="850px" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="clearDialog" :data-render="forceRender">
            <!-- 查询条件 -->
            <el-form size="medium" :inline="true" :model="query">
                <el-form-item>
                    <el-input v-model="query.goodsTitle" placeholder="商品名称"></el-input>
                </el-form-item>
                <el-form-item style="width: 120px;">
                    <el-select v-model="query.fitPerson" clearable filterable placeholder="适用人群">
                        <el-option v-for="item in fitPersonOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="width: 120px;">
                    <el-select v-model="query.published" clearable filterable placeholder="是否上架">
                        <el-option v-for="item in publishedOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="width: 120px;">
                    <el-select v-model="query.inStock" clearable filterable placeholder="是否有库存">
                        <el-option v-for="item in inStockOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="type-require">
                    <el-input v-model="query.spus" placeholder="商品spu,多个值以英文逗号','隔开"></el-input>
                </el-form-item>
                <el-form-item prop="cascader" class="type-require">
                    <el-cascader v-model="categoryIdArr" :options="classifyData" :props="{
                      multiple: true,
                      value: 'id',
                      label: 'categoryNameCn',
                      children: 'categoryItemList',
                      leaf: 'isNodes',
                    }" clearable placeholder="商品分类"></el-cascader>
                </el-form-item>
                <el-form-item prop="cascader" class="type-require">
                    <el-cascader v-model="labelValueIdsArr" :options="typeData" :props="{
              multiple: true,
              value: 'id',
              label: 'typeName',
              children: 'typeValues',
              leaf: 'isNodes',
            }" clearable placeholder="商品款式"></el-cascader>
                </el-form-item>

                <el-form-item prop="cascader" class="type-require">
                    <el-cascader v-model="labelValueIds" :options="tagData" :props="{
                                                    multiple: true,
                                                    value: 'id',
                                                    label: 'labelName',
                                                    children: 'shopGoodsLabelValues',
                                                     leaf: 'isNodes',
                                                }" clearable placeholder="款式属性">
                    </el-cascader>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="queryData">查询</el-button>
                </el-form-item>
            </el-form>

            <!-- 商品数据表 -->
            <table class="List" element-loading-text="加载中" element-loading-spinner="el-icon-loading" v-loading="loading">
                <tr style="font-weight: bold">
                    <td style="width: 40px; height: 32px">
                        <el-checkbox v-model="allSelect" @change="selectAll($event)" v-if="!isRaio"></el-checkbox>
                    </td>
                    <td style="width: 60px" v-if="containItem"></td>
                    <td style="width: 250px">标题</td>
                    <td style="width: 120px">售价/原价</td>
                    <td style="width: 100px">库存</td>
                    <td style="width: 70px">是否上架</td>
                </tr>
                <template v-for="(row, index) in data">
                    <tr :class="{ expand: row.isExpand, checked: row.isChecked }" :key="row.id">
                        <td style="height: 56px">
                            <el-checkbox :indeterminate="row.isIndeterminate" :disabled="row.isGrey" v-model="row.isChecked" @change="select(row, index)"></el-checkbox>
                        </td>
                        <td v-if="containItem">
                            <div v-if="!tree">
                                <el-button type="text" icon="el-icon-arrow-down" v-if="row.isExpand" @click="expand(row, false)"></el-button>
                                <el-button type="text" icon="el-icon-arrow-right" v-else @click="expand(row, true)"></el-button>
                            </div>
                            <div v-else>
                                <div v-if="!row.single">
                                    <el-button type="text" icon="el-icon-arrow-down" v-if="row.isExpand" @click="expand(row, false)"></el-button>
                                    <el-button type="text" icon="el-icon-arrow-right" v-else @click="expand(row, true)"></el-button>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="df ai_c">
                                <GoodsImageTemplate :images="row.goodsImages" width="40" height="40"></GoodsImageTemplate>
                                <div style="width: 200px; height: 40px; display: flex;align-items: center; justify-content: center;">{{ row.goodsTitle }}</div>
                            </div>
                        </td>
                        <td>{{ row.price }} / {{ row.compareAtPrice }}</td>
                        <td>{{ row.inventoryQuantity }}</td>
                        <td>{{ getPublished(row.published) }}</td>
                    </tr>
                    <tr v-if="containItem" :key="row.id + '_c'" v-show="row.isExpand">
                        <td :colspan="6">
                            <!-- 款式数据表 -->
                            <table class="ListItems">
                                <tr :class="{ checked: item.isChecked }" v-for="item in row.goodsItems" :key="item.code">
                                    <td style="width: 80px; height: 36px">
                                        <el-checkbox v-model="item.isChecked" @change="itemSelect(row, item)"></el-checkbox>
                                    </td>
                                    <td v-if="tree" style="min-width: 120px">
                                        {{ item.goodsTypeStr }}
                                    </td>
                                    <td v-else style="min-width: 120px">
                                        {{ item.goodsTitle }}
                                    </td>
                                    <td style="min-width: 120px" v-if="item.goodsOption">
                                        <template>
                                            {{ item.goodsOption["1"] }}-{{ item.goodsOption["2"] }}
                                        </template>
                                    </td>
                                    <td v-if="tree" style="min-width: 120px">
                                        {{ item.priceStr }} / {{ item.compareAtPriceStr }}
                                    </td>
                                    <td v-else style="min-width: 120px">
                                        {{ item.price }} / {{ item.compareAtPrice }}
                                    </td>
                                    <td style="min-width: 120px">{{ item.inventoryQuantity }}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </template>
            </table>
            <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="query.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="query.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
            </el-pagination>

            <div slot="footer" class="dialog-footer">
                已选中：{{ selectedData.length }} &nbsp;&nbsp;
                <el-button @click="clearDialog">取消</el-button>
                <el-button type="primary" @click="save">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import GoodsImageTemplate from '@/components/GoodsImageTemplate';
export default {
    name: 'SelectGoods',
    components: {
        GoodsImageTemplate,
    },
    data() {
        return {
            allSelect: false,
            caller: null, // 宿主标识 *
            forceRender: 0, // 强制渲染
            clickAll: false,

            // 查询条件
            query: {
                goodsTitle: null,
                categoryIds: null,
                typeValueIds: null,
                fitPerson: null,
                published: null,
                inStock: null,
                spus: null,
                pageNo: 1,
                pageSize: 10,
                isItem: 0, // 是否包含款式
                excludeGoodsIds: null,
                isSingleCategory: null,
            },

            prev_index: null,

            fitPersonOptions: [
                // { value: 1, name: '两者皆可' },
                { value: 2, name: '黑人' },
                { value: 3, name: '白人' },
            ],
            publishedOptions: [
                { value: 0, name: '否' },
                { value: 1, name: '是' },
            ],
            inStockOptions: [
                { value: 0, name: '否' },
                { value: 1, name: '是' },
            ],
            classifyData: [],
            typeData: [],
            tagData: [],
            categoryIdArr: [],
            labelValueIdsArr: [],
            labelValueIds: [],
            excludeGoods: [],

            // 商品列表数据
            loading: false,
            data: [],
            selectedData: [], // 选中的款式集合
            total: 0,
        };
    },

    props: {
        //  是否单选
        isRaio: {
            type: Boolean,
            default: false,
        },

        // 弹框开关
        showDialog: {
            type: Boolean,
            default: false,
        },

        // 是否包含款式
        containItem: {
            type: Boolean,
            default: false,
        },

        //是否树状展示
        tree: {
            type: Boolean,
            default: false,
        },

        // 已选中的商品
        selectedGoods: {
            type: Array,

            default() {
                return [];
            },
        },

        // 已有商品
        alreadyHas: {
            type: Array,

            default() {
                return [];
            },
        },
		
		//置灰
		greyArray: {
			type: Array,
			default() {
			    return [];
			},
		},

        goodsIndex: {
            type: Number,
            default: 0,
        },

        isSingleCategory: {
            type: String,
            default: '',
        },
    },

    mounted() {},

    methods: {
        selectAll(e) {
            // console.log(e)
            this.clickAll = true;
            let len = this.data.length;
            if (len > 0) {
                if (e) {
                    for (let i = 0; i < len; i++) {
                        if (!this.data[i].isGrey) {
                            this.data[i].isChecked = true;
                            this.select(this.data[i]);
                        }
                    }
                } else {
                    for (let i = 0; i < len; i++) {
                        if (!this.data[i].isGrey) {
                            this.data[i].isChecked = false;
                            this.select(this.data[i]);
                        }
                    }
                }
            }

            this.clickAll = false;
        },
        // 设置商品弹窗数据 *
        init() {
            this.data = [];
            this.excludeGoods = [];
            this.selectedData = JSON.parse(
                JSON.stringify([...this.selectedGoods])
            );
            this.total = 0;
            this.query.goodsTitle = null;
            this.query.pageNo = 1;
            this.query.isItem = this.containItem ? 1 : 0;

            // console.log('B', this.alreadyHas)
            // console.log('C', this.alreadyHas[this.goodsIndex])

            // let allAppoint = []
            // let allReplace = []

            let allLen = this.alreadyHas.length;
            if (allLen > 0) {
                for (let i = 0; i < allLen; i++) {
                    let item = this.alreadyHas[i];

                    let appointArr = item.appoint;
                    for (let k = 0; k < appointArr.length; k++) {
                        // allAppoint.push(appointArr[k].id)
                        this.excludeGoods.push(appointArr[k].id);
                    }

                    let replaceArr = item.replace;
                    for (let k = 0; k < replaceArr.length; k++) {
                        // allReplace.push(replaceArr[k].id)
                        this.excludeGoods.push(replaceArr[k].id);
                    }
                }
            }

            // console.log('A', this.excludeGoods)

            // let localAppoint = []
            // let localReplace = []

            // let localGoods = this.alreadyHas[this.goodsIndex]

            // for(let k = 0; k < localGoods.appoint.length; k++){
            //     localAppoint.push(localGoods.appoint[k].id)
            // }

            // for(let k = 0; k < localGoods.replace.length; k++){
            //     localReplace.push(localGoods.replace[k].id)
            // }

            // console.log('B',localAppoint, localReplace)

            this.queryData();
            this.$http.get('/goods/category/getList').then((res) => {
                let { code, data } = res.data;
                if (code === 200) this.classifyData = data;
            });
            this.$http.get('/goods/type/getList').then((res) => {
                let { code, data } = res.data;
                if (code === 200) this.typeData = data;
                // console.log(this.typeData)
                for (let i = 0; i < this.typeData.length; i++) {
                    let itemA = this.typeData[i].typeValues;
                    for (let k = 0; k < itemA.length; k++) {
                        let itemB = itemA[k];
                        itemB.typeName = itemB.valueText;
                    }
                }
            });

            this.$http.get('/goods/label/getList').then((res) => {
                let { code, data } = res.data;
                if (code === 200) {
                    this.tagData = JSON.parse(
                        JSON.stringify(data).replace(/valueText/g, 'labelName')
                    );
                }
            });
        },

        // 获取弹窗商品列表数据
        queryData() {
            this.loading = true;

            let categoryIds = this.categoryIdArr
                .map((cur) => {
                    return cur.slice(-1);
                })
                .toString();

            this.query.categoryIds = categoryIds;

            let arr = [];
            for (let i = 0; i < this.labelValueIdsArr.length; i++) {
                arr.push(this.labelValueIdsArr[i][1]);
            }
            let _arr = [];
            for (let i = 0; i < this.labelValueIds.length; i++) {
                _arr.push(this.labelValueIds[i][1]);
            }
            this.query.labelValueIds = _arr.toString();
            this.query.typeValueIds = arr.toString();

            // console.log(this.excludeGoods)

            this.query.excludeGoodsIds = this.excludeGoods.toString();

            if (this.isSingleCategory == '1') {
                this.query.isSingleCategory = 1;
            }

            this.$http
                .get('/goods/getPage', { params: this.query })
                .then((r) => {
                    this.loading = false;

                    if (r.data.code === 200) {
                        this.data = r.data.data;
                        this.total = r.data.count;

                        this.prev_index = null;

                        // 设置勾选数据
                        this.setCheckbox();
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    // this.$message.error(error);;
                });
        },

        // 设置勾选状态
        setCheckbox() {
            this.allSelect = false;
            let totalItem = this.data.length;
            let checkedItem = 0;
			
			// console.log(this.selectedData, this.data)

            for (let i = 0; i < this.selectedData.length; i++) {
                let items = this.selectedData[i].goodsItems;
				if(items && items.length > 0){
					for (let k = 0; k < items.length; k++) {
						items[k].isChecked = true;
					}
				}
            }
			
			// console.log(this.greyArray)

            this.data.forEach((row) => {
                if (this.containItem) {
                    // 包含款式
                    if (this.tree) {
                        row.goodsItems.forEach((item) => {
                            for (let i = 0; i < this.selectedData.length; i++) {
                                let goods = this.selectedData[i].goodsItems;
                                for (let k = 0; k < goods.length; k++) {
                                    if (item.code == goods[k].code) {
                                        item.isChecked = true;
                                    }
                                }
                            }
                        });
                    } else {
                        row.goodsItems.forEach((item) => {
                            item.isChecked =
                                this.selectedData.findIndex(
                                    (v) => v.code == item.code
                                ) > -1;
                            item.goodsTitle = row.goodsTitle;
                        });
                    }

                    let total = row.goodsItems.length,
                        checked = row.goodsItems.filter(
                            (v) => v.isChecked
                        ).length;

                    row.isIndeterminate = checked > 0 && checked < total;
                    row.isExpand = checked > 0;
                    row.isChecked = checked == total;

                    checkedItem = checked;
                } else {
					// console.log(row)
                    // 不包含款式
                    row.isIndeterminate = false;
                    row.isChecked = this.selectedData.findIndex((v) => v.id == row.id) > -1;
                    if (row.isChecked) {
                        checkedItem += 1;
                    }
					for(let i = 0; i < this.greyArray.length; i++){
						let item = this.greyArray[i]
						if(item == row.id){
							// console.log(item,row.id)
							row.isGrey = true
						}
					}
                }
            });

            if (totalItem == checkedItem) {
                this.allSelect = true;
            }

            // console.log(this.selectedData);
        },

        // 展开/收缩子商品
        expand(row, val) {
            row.isExpand = val;
            this.forceRender++;
        },

        // 商品列表行复选框事件
        select(row, index) {
            // console.log(row, index)
            if (this.containItem) {
                let checked = row.isChecked;

                row.isIndeterminate = false;
                row.goodsItems.forEach((v) => (v.isChecked = checked));
            }

            if (this.isRaio) {
                if (this.prev_index != null) {
                    this.data[this.prev_index]['isChecked'] = false;
                }

                this.prev_index = index;
            }

            this.updateSelectedData(row, row.goodsItems);
        },

        // 商品列表款式复选框事件
        itemSelect(row, item) {
            // console.log('A', row)
            // console.log(item.goodsId, item.sku)
            let total = row.goodsItems.length,
                checked = row.goodsItems.filter((v) => v.isChecked).length;

            // console.log(total, checked)

            switch (checked) {
                case 0:
                    row.isChecked = false;
                    row.isIndeterminate = false;
                    break;

                case total:
                    row.isChecked = true;
                    row.isIndeterminate = false;
                    break;

                default:
                    row.isChecked = false;
                    row.isIndeterminate = true;
            }

            if (this.tree) {
                item.su = item.sku;
                let gid = item.goodsId;
                // console.log(111, item.goodsId, item.sku)

                let hasRow = false;
                for (let i = 0; i < this.selectedData.length; i++) {
                    if (this.selectedData[i].id == gid) {
                        hasRow = true;
                        break;
                    }
                }

                if (!hasRow) {
                    let copyrow = JSON.parse(JSON.stringify(row));
                    copyrow.goodsItems = [];
                    this.selectedData.push(copyrow);
                }

                if (item.isChecked) {
                    let hasItem = false;
                    let index = 0;
                    for (let i = 0; i < this.selectedData.length; i++) {
                        if (this.selectedData[i].id == gid) {
                            index = i;
                            for (
                                let k = 0;
                                k < this.selectedData[i].goodsItems.length;
                                k++
                            ) {
                                if (
                                    item.sku ==
                                    this.selectedData[i].goodsItems[k].sku
                                ) {
                                    hasItem = true;
                                    break;
                                }
                            }
                        }
                    }
                    if (!hasItem) {
                        this.selectedData[index].goodsItems.push(item);
                    }
                } else {
                    for (let i = 0; i < this.selectedData.length; i++) {
                        if (this.selectedData[i].id == gid) {
                            let goodlen =
                                this.selectedData[i].goodsItems.length;
                            for (let k = 0; k < goodlen; k++) {
                                let good = this.selectedData[i].goodsItems[k];
                                if (item.sku == good.sku) {
                                    // console.log('del', k)
                                    row.isChecked = false;
                                    this.selectedData[i].goodsItems[
                                        k
                                    ].isChecked = false;
                                    // this.selectedData[i].goodsItems[k] = null
                                    // this.selectedData[i].goodsItems.splice(k, 1)
                                    break;
                                }
                            }

                            // this.selectedData[i].goodsItems = this.selectedData[i].goodsItems.filter(Boolean)

                            if (this.selectedData[i].goodsItems.length == 0) {
                                this.selectedData.splice(i, 1);
                                break;
                            }
                        }
                    }

                    for (let i = 0; i < this.selectedData.length; i++) {
                        if (this.selectedData[i].id == gid) {
                            let goodlen =
                                this.selectedData[i].goodsItems.length;
                            let noCheck = 0;
                            for (let k = 0; k < goodlen; k++) {
                                let good = this.selectedData[i].goodsItems[k];
                                if (!good.isChecked) {
                                    noCheck += 1;
                                }
                            }

                            // console.log(goodlen, noCheck)
                            if (noCheck == goodlen) {
                                this.selectedData.splice(i, 1);
                                break;
                            }
                        }
                    }
                }

                this.$forceUpdate();

                if (total == checked) {
                    this.updateSelectedData(row, [item]);
                }
            } else {
                this.updateSelectedData(row, [item]);
            }

            console.log('CCC', this.selectedData);
        },
        // 存储到临时数据
        updateSelectedData(row, items) {
            // console.log(row, items)
            // console.log(this.selectedData)
            // console.log(row.goodsTitle, row)
            if (this.containItem) {
                if (this.tree) {
                    row.su = row.spu;
                    if (row.isChecked) {
                        // console.log('A')
                        if (
                            this.selectedData.find((old) => old.id == row.id) ==
                            undefined
                        ) {
                            console.log('AA', row.goodsTitle, row);
                            if (this.isRaio) {
                                this.selectedData = [row];
                            } else {
                                // console.log('AA', row.goodsTitle, row)
                                for (
                                    let i = 0;
                                    i < row.goodsItems.length;
                                    i++
                                ) {
                                    row.goodsItems[i].su =
                                        row.goodsItems[i].sku;
                                }
                                this.selectedData.push(row);
                            }
                        } else {
                            if (this.clickAll) {
                                console.log('FF', row.goodsTitle, row);
                                let copyrow = JSON.parse(JSON.stringify(row));
                                let index = 0;
                                for (
                                    let i = 0;
                                    i < this.selectedData.length;
                                    i++
                                ) {
                                    let item = this.selectedData[i];
                                    if (item.id == row.id) {
                                        index = i;
                                        item.goodsItems = [];
                                        break;
                                    }
                                }
                                for (
                                    let i = 0;
                                    i < copyrow.goodsItems.length;
                                    i++
                                ) {
                                    copyrow.goodsItems[i].su =
                                        copyrow.goodsItems[i].sku;
                                    this.selectedData[index].goodsItems.push(
                                        copyrow.goodsItems[i]
                                    );
                                }
                            } else {
                                console.log('AAA', row);
                                let copyrow = JSON.parse(JSON.stringify(row));
                                let hasRow = false;
                                let index = 0;
                                for (
                                    let i = 0;
                                    i < this.selectedData.length;
                                    i++
                                ) {
                                    let item = this.selectedData[i];
                                    if (item.id == row.id) {
                                        hasRow = true;
                                        index = i;
                                        item.goodsItems = [];
                                        break;
                                    }
                                }
                                if (hasRow) {
                                    // console.log('AAAA')
                                    for (
                                        let i = 0;
                                        i < copyrow.goodsItems.length;
                                        i++
                                    ) {
                                        copyrow.goodsItems[i].su =
                                            copyrow.goodsItems[i].sku;
                                        this.selectedData[
                                            index
                                        ].goodsItems.push(
                                            copyrow.goodsItems[i]
                                        );
                                    }
                                }
                            }
                        }
                    } else {
                        let index = this.selectedData.findIndex(
                            (v) => v.id == row.id
                        );

                        console.log('BBB', index);

                        if (index > -1) {
                            this.selectedData.splice(index, 1);
                        }
                    }
                    // console.log('D',this.selectedData)
                } else {
                    items.forEach((item) => {
                        if (item.isChecked) {
                            if (
                                this.selectedData.find(
                                    (old) => old.code == item.code
                                ) == undefined
                            ) {
                                if (this.isRaio) {
                                    this.selectedData = [item];
                                } else {
                                    this.selectedData.push(item);
                                }
                            }
                        } else {
                            let index = this.selectedData.findIndex(
                                (v) => v.code == item.code
                            );

                            if (index > -1) {
                                this.selectedData.splice(index, 1);
                            }
                        }
                    });
                }
            } else {
                if (row.isChecked) {
                    if (
                        this.selectedData.find((old) => old.id == row.id) ==
                        undefined
                    ) {
                        if (this.isRaio) {
                            this.selectedData = [row];
                        } else {
                            this.selectedData.push(row);
                        }
                    }
                } else {
                    let index = this.selectedData.findIndex(
                        (v) => v.id == row.id
                    );

                    if (index > -1) {
                        this.selectedData.splice(index, 1);
                    }
                }
            }

            this.$forceUpdate();
        },

        // 保存选中的商品
        save() {
            if (this.tree) {
                for (let i = 0; i < this.selectedData.length; i++) {
                    let items = this.selectedData[i].goodsItems;
                    for (let k = 0; k < items.length; k++) {
                        let good = items[k];
                        console.log(good.isChecked);
                        if (!good.isChecked) {
                            good.compareAtPrice = 0;
                        }
                    }
                }
            }

            // console.log(this.selectedData);

            // return

            this.$emit(
                'saveSelectedGoods',
                [...this.selectedData],
                this.caller
            );
            this.clearDialog();
        },

        // 关闭弹窗
        clearDialog() {
            this.$emit('closeDialog');
            this.caller = null;
        },
        getPublished(status) {
            switch (status) {
                case 0:
                    return '否';
                case 1:
                    return '是';
            }
        },
    },
};
</script>

<style scope>
.List {
    margin-bottom: 30px;
    border-collapse: collapse;
    width: 100%;
    color: #606266;
}

.List > tr > td {
    border-top: solid 1px #e8ebf3;
    border-bottom: solid 1px #e8ebf3;
}

.expand {
    background-color: #f9f9f9;
}

.checked {
    background-color: #ebf2fa;
}

.ListItems {
    border-collapse: collapse;
    width: 100%;
}

.ListItems > tr > td {
    border-top: solid 1px #f5f5f5;
    border-bottom: solid 1px #f5f5f5;
}
.el-cascader {
    width: 100%;
}
.type-require {
    width: 100%;
}
.type-require .el-form-item__content {
    width: 100%;
}
::v-deep .type-require .el-form-item__label:before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
}
.mr {
    margin-right: 30px;
}
::v-deep .sortable-ghost {
    /* border: 1px solid #ebeef5 !important; */
    background: rgba(188, 213, 249, 0.4) !important;
}
</style>
