<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>物流管理</el-breadcrumb-item>
            <el-breadcrumb-item>物流方案</el-breadcrumb-item>
        </el-breadcrumb>

        <el-tabs v-model="campaignStatus">
            <el-tab-pane label="物流方案" name="logistics">
                <!-- 标题 -->
                <el-form :inline="true" :model="form" class="demo-form-inline df jc_sb ai_c mb_3">
                    <div class="mod">
                        <el-form-item label="购物车包邮金额（$）">
                            <el-input-number v-model="form.value" placeholder="请填写包邮金额" clearable :min="0" :max="99999" :precision="2"></el-input-number>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="defineFreeShipping">保存</el-button>
                        </el-form-item>
                    </div>

                    <el-form-item>
                        <el-button icon="el-icon-refresh" @click="queryData(typeStatus)">刷新</el-button>
                        <el-button type="success" icon="el-icon-plus" @click="initAddLogistics">新增物流方案</el-button>
                    </el-form-item>
                </el-form>

                <el-tabs v-model="campaignType" type="card" @tab-click="handleTabs">
                    <el-tab-pane label="通用物流" name="normal">
                        <!-- 数据表 -->
                        <div class="oa">
                            <div class="List_Logistics mb_3" v-for="item in tableData" :key="item.id">
                                <div class="df ai_c m_3 mt_0 mb_0">
                                    <h3 class="mr_a" style="color: #303133">{{ item.areaPlanName }}</h3>
                                    <el-button type="success" icon="el-icon-plus" @click="initAddFreight(item)">新增运费方案
                                    </el-button>
                                    <el-button type="primary" icon="el-icon-document" v-if="item.isOther == 0" @click="initEditCountry(item)">分配区域</el-button>
                                    <el-button type="primary" icon="el-icon-edit" @click="initEditLogistics(item)">编辑
                                    </el-button>
                                    <el-button type="danger" icon="el-icon-delete" @click="deleteLogistics(item)">删除
                                    </el-button>
                                </div>
                                <el-table ref="freightPlanTable" element-loading-spinner="el-icon-loading" element-loading-text="加载中" :data="item.freightPlanList" v-loading="loading">
                                    <el-table-column prop="freightPlanName" label="运费方案名称" min-width="120">
                                    </el-table-column>
                                    <el-table-column label="运费" min-width="120">
                                        <template slot-scope="scope">
                                            {{ $yrFormatNumber(scope.row.rateAmount, ",", 2) }} USD
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="计算规则" min-width="240">
                                        <template slot-scope="scope">
                                            {{ optionRuleType.find((el) => el.value == scope.row.ruleType).label}}
                                            {{ scope.row.ruleType == "1" ? $yrFormatNumber(scope.row.ruleRangeMin, ",", 2) : scope.row.ruleRangeMin }}
                                            -
                                            {{ scope.row.ruleRangeMax ? ( scope.row.ruleType == "1" ? $yrFormatNumber(scope.row.ruleRangeMax, ",", 2) : scope.row.ruleRangeMax ) : "不限" }}
                                            {{ scope.row.ruleUnit }}
                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column label="货到付款" min-width="120">
										<template slot-scope="scope">
											{{ scope.row.supportCod == 1 ? "支持" : "不支持" }}
										</template>
									</el-table-column> -->
                                    <el-table-column fixed="right" label="操作" width="240" header-align="center" align="center">
                                        <template slot-scope="scope">
                                            <el-button size="mini" type="text" icon="el-icon-edit" @click="initEditFreight(scope.row, item.id)">编辑</el-button>
                                            <el-button size="mini" type="text" icon="el-icon-delete" @click="deleteFreight(scope.row)">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane label="自定义物流" name="custom">
                        <!-- 数据表 -->
                        <div class="oa">
                            <div class="List_Logistics mb_3" v-for="item in customData" :key="item.id">
                                <div class="df ai_c m_3 mt_0 mb_0">
                                    <h3 class="mr_a" style="color: #303133">{{ item.areaPlanName }}</h3>
                                    <el-button type="success" icon="el-icon-plus" @click="initAddFreight(item)">新增运费方案
                                    </el-button>
                                    <el-button type="primary" icon="el-icon-document" v-if="item.isOther == 0" @click="initEditCountry(item)">分配区域</el-button>
                                    <el-button type="primary" icon="el-icon-edit" @click="initEditLogistics(item)">编辑
                                    </el-button>
                                    <el-button type="danger" icon="el-icon-delete" @click="deleteLogistics(item)">删除
                                    </el-button>
                                </div>
                                <el-table ref="freightPlanTable" element-loading-spinner="el-icon-loading" element-loading-text="加载中" :data="item.freightPlanList" v-loading="loading">
                                    <el-table-column prop="freightPlanName" label="运费方案名称" min-width="120">
                                    </el-table-column>
                                    <el-table-column label="运费" min-width="120">
                                        <template slot-scope="scope">
                                            {{ $yrFormatNumber(scope.row.rateAmount, ",", 2) }} USD
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="计算规则" min-width="240">
                                        <template slot-scope="scope">
                                            {{ optionRuleType.find((el) => el.value == scope.row.ruleType).label}}
                                            {{ scope.row.ruleType == "1" ? $yrFormatNumber(scope.row.ruleRangeMin, ",", 2) : scope.row.ruleRangeMin }}
                                            -
                                            {{ scope.row.ruleRangeMax ? ( scope.row.ruleType == "1" ? $yrFormatNumber(scope.row.ruleRangeMax, ",", 2) : scope.row.ruleRangeMax ) : "不限" }}
                                            {{ scope.row.ruleUnit }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="适用范围" min-width="120">
                                        <template slot-scope="scope">
                                            {{ getCustomType(scope.row.type) }}
                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column label="货到付款" min-width="120">
										<template slot-scope="scope">
											{{ scope.row.supportCod == 1 ? "支持" : "不支持" }}
										</template>
									</el-table-column> -->
                                    <el-table-column fixed="right" label="操作" width="240" header-align="center" align="center">
                                        <template slot-scope="scope">
                                            <el-button size="mini" type="text" icon="el-icon-edit" @click="initCustomEditFreight(scope.row, item.id)">编辑</el-button>
                                            <el-button size="mini" type="text" icon="el-icon-delete" @click="deleteFreight(scope.row)">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>

            </el-tab-pane>

            <el-tab-pane label="运费险设置" name="freight">
                <FreightInsurance></FreightInsurance>
            </el-tab-pane>
        </el-tabs>

        <!-- 新增物流方案 -->
        <el-dialog top="3%" title="新增物流方案" width="500px" :visible.sync="dialogAddLogistics" :close-on-click-modal="false">
            <el-form ref="addLogisticsForm" size="medium" label-position="top" status-icon :model="addLogisticsForm" :rules="addLogisticsRules">
                <el-form-item label="物流方案名称" prop="areaPlanName">
                    <el-input placeholder="请输入物流方案名称" maxlength="100" show-word-limit v-model="addLogisticsForm.areaPlanName"></el-input>
                </el-form-item>
                <el-form-item label="其它国家区域方案">
                    <el-radio label="1" v-model="addLogisticsForm.isOther">是</el-radio>
                    <el-radio label="0" v-model="addLogisticsForm.isOther">否</el-radio>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading" @click="addLogistics">确定</el-button>
                <el-button @click="dialogAddLogistics = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 编辑物流方案 -->
        <el-dialog top="3%" title="编辑物流方案" width="500px" :visible.sync="dialogEditLogistics" :close-on-click-modal="false">
            <el-form ref="editLogisticsForm" size="medium" label-position="top" status-icon :model="editLogisticsForm" :rules="editLogisticsRules">
                <el-form-item label="物流方案名称" prop="areaPlanName">
                    <el-input placeholder="请输入物流方案名称" maxlength="100" show-word-limit v-model="editLogisticsForm.areaPlanName"></el-input>
                </el-form-item>
                <el-form-item label="其它国家区域方案">
                    <el-radio label="1" v-model="editLogisticsForm.isOther">是</el-radio>
                    <el-radio label="0" v-model="editLogisticsForm.isOther">否</el-radio>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading" @click="editLogistics">确定</el-button>
                <el-button @click="dialogEditLogistics = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 新增运费方案 -->
        <el-dialog top="3%" :title="addFreightForm.id ? '编辑运费方案' : '新增运费方案'" fullscreen :visible.sync="dialogAddFreight" :close-on-click-modal="false">
            <el-form ref="addFreightForm" size="medium" label-position="top" status-icon :model="addFreightForm" :rules="addFreightRules">
                <el-form-item label="方案名称" prop="freightPlanName">
                    <el-input placeholder="请输入运费方案名称" maxlength="100" show-word-limit v-model="addFreightForm.freightPlanName"></el-input>
                </el-form-item>
                <el-form-item label="方案说明" prop="freightPlanDesc">
                    <el-input type="textarea" maxlength="200" show-word-limit placeholder="请输入方案说明" :autosize="{ minRows: 3, maxRows: 5 }" v-model="addFreightForm.freightPlanDesc">
                    </el-input>
                </el-form-item>
                <el-row type="flex" align="bottom" :gutter="20">
                    <el-col :span="6">
                        <el-form-item label="规则设置" prop="ruleType">
                            <el-select v-model="addFreightForm.ruleType">
                                <el-option v-for="item in optionRuleType" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" class="df">
                        <el-form-item prop="ruleRangeMin">
                            <el-input placeholder="最小" maxlength="20" v-model="addFreightForm.ruleRangeMin"></el-input>
                        </el-form-item>
                        <p class="m_2">~</p>
                        <el-form-item prop="ruleRangeMax">
                            <el-input placeholder="最大" maxlength="20" :disabled="addFreightForm_unlimited" v-model="addFreightForm.ruleRangeMax"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4" class="mb_3">
                        <p class="mb_3" v-if="addFreightForm.ruleType == '1'">USD</p>
                        <p class="mb_3" v-else-if="addFreightForm.ruleType == '2'">件</p>
                        <el-select size="medium" style="margin-bottom: 6px" v-else v-model="addFreightForm.ruleUnit">
                            <el-option label="g" value="g"></el-option>
                            <el-option label="kg" value="kg"></el-option>
                            <el-option label="lb" value="lb"></el-option>
                            <el-option label="oz" value="oz"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item>
                            <el-checkbox v-model="addFreightForm_unlimited" @change="freight_setMax( 'addFreightForm' )">不限</el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-form-item label="" prop="supportCod">
                    <el-checkbox v-model="addFreightForm.supportCod">支持货到付款</el-checkbox>
                </el-form-item> -->
                <div class="mb_3">运费</div>
                <el-row type="flex" align="bottom" :gutter="20">
                    <el-col :span="21">
                        <el-form-item prop="rateAmount">
                            <el-input placeholder="0" maxlength="20" v-model="addFreightForm.rateAmount"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="mb_3">
                        <p class="mb_3">USD</p>
                    </el-col>
                </el-row>

                <section v-if="typeStatus == 2">
                    <div class="mb_3">适用范围</div>
                    <el-form-item>
                        <el-select v-model="addFreightForm.areaType" style="width: 150px;margin-right:10px;" @change="changeArea">
                            <el-option v-for="(item, index) in areaOptions" :key="index" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                        <el-button type="primary" v-if="addFreightForm.areaType == 1" @click="openSelectGoods">选择商品
                        </el-button>
                        <el-button type="primary" v-if="addFreightForm.areaType == 2" @click="openCollections">选择专辑</el-button>
                        <el-button type="primary" v-if="addFreightForm.areaType == 3" @click="openCategorys">选择分类</el-button>
                    </el-form-item>
                </section>

                <section v-if="typeStatus == 2">
                    <section v-if="addFreightForm.areaType == 1">
                        <!-- 数据表 -->
                        <el-table ref="selectedGoodsTable" class="mt_3" stripe :data="selectedGoodsTable.data">
                            <el-table-column label="商品图片" width="80">
                                <template slot-scope="scope">
                                    <el-image fit="contain" style="width: 40px; height: 40px" :src="scope.row.goodsImages[ 0 ]">
                                        <div slot="error" class="el-image__error" style="width: 40px; height: 40px; font-size: 10px">
                                            无图
                                        </div>
                                    </el-image>
                                </template>
                            </el-table-column>
                            <el-table-column prop="goodsTitle" label="商品名称" min-width="80"></el-table-column>
                            <el-table-column label="售价/原价" min-width="80">
                                <template slot-scope="scope">
                                    {{ scope.row.price }} / {{ scope.row.compareAtPrice }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="inventoryQuantity" label="库存" min-width="80"></el-table-column>
                            <el-table-column label="是否上架" min-width="80">
                                <template slot-scope="scope">
                                    {{ getPublished(scope.row.published) }}
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="text" @click="deleteSelectedGoods(scope.row)">移除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </section>

                    <section v-if="addFreightForm.areaType == 2">
                        <el-table stripe :data="collectionsSelection" element-loading-text="加载中" element-loading-spinner="el-icon-loading">
                            <el-table-column prop="id" label="ID" width="80"></el-table-column>
                            <el-table-column label="专辑封面" width="180" align="center">
                                <template slot-scope="scope">
                                    <el-popover placement="right" trigger="hover">
                                        <el-image fit="contain" style="width: 300px; height: 300px" :src="scope.row.collectionCover" :preview-src-list="[scope.row.collectionCover]">
                                            <div slot="error" class="el-image__error" style="width: 300px; height: 300px; font-size: 50px">
                                                <i class="el-icon-picture-outline"></i>
                                            </div>
                                        </el-image>
                                        <el-image fit="contain" slot="reference" style="width: 80px; height: 80px" :src="scope.row.collectionCover">
                                            <div slot="error" class="el-image__error" style="font-size: 20px">
                                                <i class="el-icon-picture-outline"></i>
                                            </div>
                                        </el-image>
                                    </el-popover>
                                </template>
                            </el-table-column>
                            <el-table-column prop="collectionName" label="专辑名称" min-width="120"></el-table-column>
                            <el-table-column prop="publishedNum" label="已上架商品数量" width="150"></el-table-column>
                            <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="text" @click="deleteSelectedCollections(scope.row)">移除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </section>

                    <section v-if="addFreightForm.areaType == 3">
                        <el-table stripe :data="categorysSelection" element-loading-text="加载中" element-loading-spinner="el-icon-loading">
                            <el-table-column prop="id" label="ID" width="150"></el-table-column>
                            <el-table-column label="分类中文名" prop="categoryNameCn" min-width="120"></el-table-column>
                            <el-table-column label="分类英文名" prop="categoryNameEn" min-width="120"></el-table-column>
                            <el-table-column prop="publishedNum" label="已上架商品数量" width="150"></el-table-column>
                            <el-table-column fixed="right" label="操作" width="120" header-align="center" align="center">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="text" @click="deleteSelectedCategorys(scope.row)">移除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </section>

                </section>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading" @click="addFreight">确定</el-button>
                <el-button @click="dialogAddFreight = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 分配区域 -->
        <el-dialog top="3%" title="分配区域" width="1200px" :visible.sync="dialogEditCountry" :close-on-click-modal="false">
            <el-form ref="editCountryForm" size="medium" label-position="top" status-icon :model="editCountryForm">
                <div class="m_3" v-for="item in continents" :key="item.id">
                    <div class="mb_3" style="padding: 15px 12px; color: #fff; background-color: #f0f0f0">
                        <el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll" @change="checkContinent($event, item)">{{ item.cnName }}</el-checkbox>
                    </div>
                    <el-checkbox-group class="dg" style="
              grid-template-columns: repeat(auto-fill, 180px);
              padding-left: 12px;
            " v-model="item.checkedIds" @change="checkCountry($event, item)">
                        <el-checkbox v-for="country in item.countryList" :key="country.id" :label="country.id">
                            {{ country.cnName }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading" @click="editCountry">确定</el-button>
                <el-button @click="dialogEditCountry = false">取消</el-button>
            </div>
        </el-dialog>

        <!-- 选择商品 -->
        <SelectGoods ref="selectGoods" :showDialog="dialogSelectGoods" :containItem="false" :selectedGoods="selectedGoodsTable.data" @closeDialog="closeSelectGoods" @saveSelectedGoods="saveSelectGoods" :greyArray="alreadyData"></SelectGoods>

        <!-- 选择专辑 -->
        <el-dialog class="diabox" top="3%" title="选择专辑" width="800px" :visible.sync="showCollections" :close-on-click-modal="false">
            <el-form :inline="true" :model="collectionsForm">
                <el-form-item>
                    <el-input v-model="collectionsForm.collectionName" placeholder="商品专辑名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="collectionsSearch">查询</el-button>
                </el-form-item>
            </el-form>

            <el-table stripe :data="collectionsData" ref="collectionsData" v-loading="collectionsLoading" @select="handleCollectionsSelection" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="600">
                <el-table-column type="selection" :selectable="collectionsSelect" width="50"></el-table-column>
                <el-table-column prop="id" label="ID" width="80"></el-table-column>
                <el-table-column label="专辑封面" width="180" align="center">
                    <template slot-scope="scope">
                        <el-popover placement="right" trigger="hover">
                            <el-image fit="contain" style="width: 300px; height: 300px" :src="scope.row.collectionCover" :preview-src-list="[scope.row.collectionCover]">
                                <div slot="error" class="el-image__error" style="width: 300px; height: 300px; font-size: 50px">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                            <el-image fit="contain" slot="reference" style="width: 80px; height: 80px" :src="scope.row.collectionCover">
                                <div slot="error" class="el-image__error" style="font-size: 20px">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="collectionName" label="专辑名称" min-width="120"></el-table-column>
                <el-table-column label="已上架商品数量" width="150">
                    <template slot-scope="scope">
                        {{ scope.row.publishedNum }}
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination background :current-page.sync="collectionsForm.pageNo" :page-sizes="[10, 20, 50]" :page-size.sync="collectionsForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="collectionsTotal" @size-change="getCollections" @current-change="getCollections">
            </el-pagination>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="collectionsLoading" @click="addCollections">确定</el-button>
                <!-- <el-button @click="showCollections = false">取消</el-button> -->
            </div>
        </el-dialog>

        <!-- 选择分类 -->
        <el-dialog class="diabox" top="3%" title="选择分类" width="800px" :visible.sync="showCategorys" :close-on-click-modal="false">
            <el-form :inline="true" :model="categorysForm">
                <el-form-item>
                    <el-input v-model="categorysForm.categoryNameCn" placeholder="分类中文名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="categorysForm.categoryNameEn" placeholder="分类英文名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="categorysSearch">查询</el-button>
                </el-form-item>
            </el-form>

            <el-table stripe :data="categorysData" ref="categorysData" v-loading="categorysLoading" @select="handleCategorysSelection" row-key="id" :tree-props="{ children: 'categoryItemList' }" default-expand-all element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="600">
                <el-table-column type="selection" :selectable="categorysSelect" width="50"></el-table-column>
                <el-table-column prop="id" label="ID" width="150"></el-table-column>
                <el-table-column label="分类中文名" prop="categoryNameCn" min-width="120"></el-table-column>
                <el-table-column label="分类英文名" prop="categoryNameEn" min-width="120"></el-table-column>
                <el-table-column label="已上架商品数量" width="150">
                    <template slot-scope="scope">
                        {{ scope.row.publishedNum }}
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination background :current-page.sync="categorysForm.pageNo" :page-sizes="[10, 20, 50]" :page-size.sync="categorysForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="categorysTotal" @size-change="getCategorys" @current-change="getCategorys">
            </el-pagination>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="categorysLoading" @click="addCategorys">确定</el-button>
                <!-- <el-button @click="showCategorys = false">取消</el-button> -->
            </div>
        </el-dialog>

    </div>
</template>

<script>
import FreightInsurance from './FreightInsurance.vue';
import SelectGoods from '@/components/SelectGoods';
export default {
    name: 'LogisticsPlan',
    components: {
        FreightInsurance,
        SelectGoods,
    },
    data() {
        return {
            // 选择商品参数
            dialogSelectGoods: false,
            // 选中的商品列表数据
            selectedGoodsTable: {
                data: [],
                total: 0,
            },

            btnLoading: false,

            campaignStatus: 'logistics',
            campaignType: 'normal',
            typeStatus: 1,
            // 列表数据
            loading: false,
            tableData: [],
            customData: [],

            // 新增物流方案
            dialogAddLogistics: false,
            addLogisticsForm: {},
            addLogisticsRules: {
                areaPlanName: [
                    {
                        required: true,
                        message: '物流方案名称',
                        trigger: 'blur',
                    },
                ],
            },

            // 编辑物流方案
            dialogEditLogistics: false,
            editLogisticsForm: {},
            editLogisticsRules: {
                areaPlanName: [
                    {
                        required: true,
                        message: '物流方案名称',
                        trigger: 'blur',
                    },
                ],
            },

            // 新增运费方案
            dialogAddFreight: false,
            addFreightForm: {},
            addFreightForm_unlimited: false,
            addFreightRules: {
                freightPlanName: [
                    {
                        required: true,
                        message: '运费方案名称',
                        trigger: 'blur',
                    },
                ],
            },
            optionRuleType: [
                {
                    label: '订单价格',
                    value: '1',
                },
                {
                    label: '商品数量',
                    value: '2',
                },
                {
                    label: '订单重量',
                    value: '3',
                },
            ],

            areaOptions: [
                {
                    name: '指定商品',
                    value: '1',
                },
                {
                    name: '指定专辑',
                    value: '2',
                },
                {
                    name: '指定分类',
                    value: '3',
                },
            ],

            // 分配区域
            continents: null,
            dialogEditCountry: false,
            editCountryForm: {},

            //
            form: {
                value: '',
            },
            freeCode: '',

            alreadyData: [],

            showCollections: false,
            collectionsLoading: false,
            collectionsData: [],
            collectionsTotal: 0,
            collectionsForm: {
                collectionName: '',
                pageNo: 1,
                pageSize: 10,
                queryGoods: 1,
            },
            collectionsSelection: [],

            showCategorys: false,
            categorysLoading: false,
            categorysData: [],
            categorysTotal: 0,
            categorysForm: {
                categoryNameCn: '',
                categoryNameEn: '',
                pageNo: 1,
                pageSize: 10,
                queryGoods: 1,
            },
            categorysSelection: [],
        };
    },

    mounted() {
        this.getFreeShipping();
        this.queryData(1);
        this.queryCountry();
    },

    methods: {
        clearData() {
            this.alreadyData = [];
            this.selectedGoodsTable = {
                data: [],
                total: 0,
            };
            this.collectionsSelection = [];
            this.categorysSelection = [];
        },
        changeArea() {
            this.clearData();
        },
        //分类
        async openCategorys() {
            this.showCategorys = true;
            this.categorysForm.categoryNameCn = '';
            this.categorysForm.categoryNameEn = '';
            this.categorysForm.pageNo = 1;
            await this.getAlready(3);
            this.getCategorys();
        },
        categorysSearch() {
            this.categorysForm.pageNo = 1;
            this.getCategorys();
        },
        getCategorys() {
            this.categorysLoading = true;
            this.$http
                .get('/goods/category/getList', { params: this.categorysForm })
                .then((response) => {
                    this.categorysLoading = false;
                    if (response.data.code === 200) {
                        this.categorysData = response.data.data;
                        this.categorysTotal = response.data.count;

                        // for(let i = 0; i < this.categorysData.length; i++){
                        // 	let item = this.categorysData[i]
                        // 	item.goodsLength = item.goodsList.length
                        // }

                        this.recursionDisabled(this.categorysData);
                    }
                })
                .catch((error) => {
                    this.categorysLoading = false;
                    // this.$message.error(error);
                });
        },
        recursionDisabled(data) {
            for (let i = 0; i < data.length; i++) {
                let item = data[i];

                for (let k = 0; k < this.alreadyData.length; k++) {
                    if (item.id == this.alreadyData[k]) {
                        item.isDisabled = true;
                    }
                }

                for (let k = 0; k < this.categorysSelection.length; k++) {
                    let good = this.categorysSelection[k];
                    if (item.id == good.id) {
                        this.$refs.categorysData.toggleRowSelection(item, true);
                        item.isDisabled = true;
                    }
                }

                if (item.categoryItemList && item.categoryItemList.length > 0) {
                    this.recursionDisabled(item.categoryItemList);
                }
            }
        },
        addCategorys() {
            // this.categorysSelection = [...new Set(this.categorysSelection)]
            this.showCategorys = false;
        },
        handleCategorysSelection(val, row) {
            if (!row.isSelect) {
                row.isSelect = true;
            } else {
                row.isSelect = false;
            }
            this.checkSaveCategorys(row);
        },
        checkSaveCategorys(row) {
            let hasItem = false;
            let itemIndex = null;
            let len = this.categorysSelection.length;
            for (let i = 0; i < len; i++) {
                let item = this.categorysSelection[i];
                if (row.id === item.id) {
                    hasItem = true;
                    itemIndex = i;
                    break;
                }
            }

            // console.log(hasItem, row.isSelect)

            if (!hasItem) {
                if (row.isSelect) {
                    this.categorysSelection.push(row);
                } else {
                    this.categorysSelection.splice(itemIndex, 1);
                }
            } else {
                if (row.isSelect) {
                    // this.categorysSelection.push(row)
                } else {
                    this.categorysSelection.splice(itemIndex, 1);
                }
            }
        },
        deleteSelectedCategorys(row) {
            let len = this.categorysSelection.length;
            for (let i = 0; i < len; i++) {
                let item = this.categorysSelection[i];
                if (item.id == row.id) {
                    this.categorysSelection.splice(i, 1);
                    break;
                }
            }
        },
        categorysSelect(row, index) {
            if (row.isDisabled) {
                return false;
            } else {
                return true;
            }
        },
        //专辑
        async openCollections() {
            this.showCollections = true;
            this.collectionsForm.pageNo = 1;
            await this.getAlready(2);
            this.getCollections();
        },
        collectionsSearch() {
            this.collectionsForm.pageNo = 1;
            this.getCollections();
        },
        getCollections() {
            this.collectionsLoading = true;
            this.$http
                .get('/goods/collection/getPage', {
                    params: this.collectionsForm,
                })
                .then((response) => {
                    this.collectionsLoading = false;
                    if (response.data.code === 200) {
                        this.collectionsData = response.data.data;
                        this.collectionsTotal = response.data.count;

                        for (let i = 0; i < this.collectionsData.length; i++) {
                            let item = this.collectionsData[i];

                            for (let k = 0; k < this.alreadyData.length; k++) {
                                if (item.id == this.alreadyData[k]) {
                                    item.isDisabled = true;
                                }
                            }

                            for (
                                let k = 0;
                                k < this.collectionsSelection.length;
                                k++
                            ) {
                                let good = this.collectionsSelection[k];
                                if (item.id == good.id) {
                                    this.$refs.collectionsData.toggleRowSelection(
                                        item,
                                        true
                                    );
                                    item.isDisabled = true;
                                }
                            }
                        }
                    }
                })
                .catch((error) => {
                    this.collectionsLoading = false;
                    // this.$message.error(error);
                });
        },
        addCollections() {
            // console.log(this.collectionsSelection)
            // this.collectionsSelection = [...new Set(this.collectionsSelection)]
            this.showCollections = false;
        },
        handleCollectionsSelection(val, row) {
            if (!row.isSelect) {
                row.isSelect = true;
            } else {
                row.isSelect = false;
            }
            this.checkSaveCollections(row);
        },
        checkSaveCollections(row) {
            let hasItem = false;
            let itemIndex = null;
            let len = this.collectionsSelection.length;
            for (let i = 0; i < len; i++) {
                let item = this.collectionsSelection[i];
                if (row.id === item.id) {
                    hasItem = true;
                    itemIndex = i;
                    break;
                }
            }

            // console.log(hasItem, row.isSelect)

            if (!hasItem) {
                if (row.isSelect) {
                    this.collectionsSelection.push(row);
                } else {
                    this.collectionsSelection.splice(itemIndex, 1);
                }
            } else {
                if (row.isSelect) {
                    // this.collectionsSelection.push(row)
                } else {
                    this.collectionsSelection.splice(itemIndex, 1);
                }
            }
        },
        deleteSelectedCollections(row) {
            let len = this.collectionsSelection.length;
            for (let i = 0; i < len; i++) {
                let item = this.collectionsSelection[i];
                if (item.id == row.id) {
                    this.collectionsSelection.splice(i, 1);
                    break;
                }
            }
        },
        collectionsSelect(row, index) {
            if (row.isDisabled) {
                return false;
            } else {
                return true;
            }
        },
        // 选择商品方法
        async openSelectGoods() {
            await this.getAlready(1);

            // for(let i = 0; i < this.selectedGoodsTable.data.length; i++){
            // 	let item = this.selectedGoodsTable.data[i]
            // 	for(let k = 0; k < this.alreadyData.length; k++){
            // 		if(item.id == this.alreadyData[k]){
            // 			item.isGrey = true
            // 		}
            // 	}
            // }

            this.$refs.selectGoods.caller = 'notset';
            this.$refs.selectGoods.init();
            this.dialogSelectGoods = true;
        },
        closeSelectGoods() {
            this.dialogSelectGoods = false;
        },
        saveSelectGoods(items, caller) {
            switch (caller) {
                default:
                    this.selectedGoodsTable.data = items;
                    this.selectedGoodsTable.total =
                        this.selectedGoodsTable.data.length;
            }
        },
        deleteSelectedGoods(item) {
            let index = this.selectedGoodsTable.data.indexOf(item);

            if (index > -1) {
                this.selectedGoodsTable.data.splice(index, 1);
                this.selectedGoodsTable.total =
                    this.selectedGoodsTable.data.length;
            }
        },
        async getAlready(type) {
            return await this.$http
                .get('/logistics/plan/scope/getByType?type=' + type)
                .then(async (response) => {
                    if (response.data.code === 200) {
                        this.alreadyData = response.data.data;
                    }
                })
                .catch((error) => {})
                .finally(() => {
                    return true;
                });
        },
        handleTabs(tab, event) {
            // console.log(this.campaignType)
            if (this.campaignType == 'normal') {
                this.typeStatus = 1;
            } else {
                this.typeStatus = 2;
            }
            this.queryData(this.typeStatus);
        },
        getFreeShipping() {
            let url = '/logistics/plan/getConfig';
            this.$http.get(url).then((res) => {
                let {
                    code,
                    data: { code: freeCode, value },
                } = res.data;
                if (code === 200) {
                    this.freeCode = freeCode;
                    this.form.value = value;
                }
            });
        },
        defineFreeShipping() {
            let url = '/logistics/plan/editConfig',
                params = {
                    code: this.freeCode,
                    ...this.form,
                };

            this.$http.post(url, params).then((res) => {
                let { code } = res.data;
                if (code === 200) {
                    this.$message.success('购物车包邮金额设置成功~');
                }
            });
        },

        formatToFixed2(value, gKey) {
            let str = value.replace('-', ''),
                reg =
                    /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;

            if (~value.indexOf('-')) {
                this.form[gKey] = str;
            }

            if (!reg.test(+str)) {
                this.form[gKey] = (+str).toFixed(2);
            }
        },

        // 设置表头行样式 ???
        tableHeadStyle() {
            return 'background-color: #F00;';
        },

        // 获取列表数据
        queryData(type) {
            this.loading = true;

            this.$http
                .get('/logistics/plan/getList?type=' + type)
                .then((r) => {
                    this.loading = false;

                    if (r.data.code === 200) {
                        if (type == 1) {
                            this.tableData = r.data.data;
                        } else {
                            this.customData = r.data.data;
                        }
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    // this.$message.error(error);;
                });
        },

        // 获取国家数据
        queryCountry() {
            this.$http
                .get('/common/continent/getList', {
                    params: {
                        isCountry: 1,
                    },
                })
                .then((r) => {
                    if (r.data.code === 200) {
                        let data = r.data.data;

                        data.map((item) => {
                            item.isIndeterminate = false;
                            item.checkAll = false;
                            item.checkedIds = [];
                        });

                        this.continents = data;
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                });
        },

        // 新增物流方案
        initAddLogistics() {
            this.addLogisticsForm = {
                areaPlanName: null,
                isOther: '0',
                type: this.typeStatus,
            };

            this.dialogAddLogistics = true;
        },
        addLogistics() {
            this.$refs.addLogisticsForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;

                    this.$http
                        .post('/logistics/plan/add', this.addLogisticsForm)
                        .then((r) => {
                            this.btnLoading = false;

                            if (r.data.code === 200) {
                                this.dialogAddLogistics = false;

                                this.$message({
                                    message: '新增成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: this.queryData(this.typeStatus),
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = false;
                        });
                }
            });
        },

        // 编辑物流方案
        initEditLogistics(item) {
            this.editLogisticsForm = {
                id: item.id,
                areaPlanName: item.areaPlanName,
                isOther: item.isOther.toString(),
                type: this.typeStatus,
            };

            this.dialogEditLogistics = true;
        },
        editLogistics() {
            this.$refs.editLogisticsForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;

                    this.$http
                        .post('/logistics/plan/edit', this.editLogisticsForm)
                        .then((r) => {
                            this.btnLoading = false;

                            if (r.data.code === 200) {
                                this.dialogEditLogistics = false;

                                this.$message({
                                    message: '编辑成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: this.queryData(this.typeStatus),
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = false;
                        });
                }
            });
        },

        // 删除物流方案
        deleteLogistics(item) {
            this.$confirm('是否删除该物流方案？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.$http
                    .delete('/logistics/plan/delete', {
                        params: {
                            id: item.id,
                        },
                    })
                    .then((r) => {
                        if (r.data.code === 200) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                                duration: 1000,
                                onClose: this.queryData(this.typeStatus),
                            });
                        }
                    });
            });
        },

        // 新增运费方案
        initAddFreight(item) {
            this.addFreightForm = {
                logisticsPlanId: item.id,
                freightPlanName: null,
                freightPlanDesc: null,
                ruleType: '1',
                ruleRangeMin: null,
                ruleRangeMax: null,
                supportCod: null,
                rateAmount: null,
                rateAmountUnit: 'USD',
                ruleUnit: null,
                areaType: '1',
            };

            this.clearData();

            this.addFreightForm_unlimited = false;

            this.dialogAddFreight = true;
        },
        // 编辑运费方案
        initEditFreight(item, fatherId) {
            this.addFreightForm = {
                id: item.id,
                logisticsPlanId: fatherId,
                freightPlanName: item.freightPlanName,
                freightPlanDesc: item.freightPlanDesc,
                ruleType: item.ruleType.toString(),
                ruleRangeMin: item.ruleRangeMin,
                ruleRangeMax: item.ruleRangeMax,
                supportCod: item.supportCod == 1 ? true : false,
                rateAmount: item.rateAmount,
                rateAmountUnit: 'USD',
                ruleUnit:
                    this.addFreightForm.ruleType == '3' ? item.ruleUnit : null,
            };

            this.addFreightForm_unlimited = false;

            this.dialogAddFreight = true;
        },
        initCustomEditFreight(item, fatherId) {
            this.loading = true;

            this.clearData();

            this.addFreightForm = {
                id: item.id,
                logisticsPlanId: fatherId,
                freightPlanName: item.freightPlanName,
                freightPlanDesc: item.freightPlanDesc,
                ruleType: item.ruleType.toString(),
                ruleRangeMin: item.ruleRangeMin,
                ruleRangeMax: item.ruleRangeMax,
                supportCod: item.supportCod == 1 ? true : false,
                rateAmount: item.rateAmount,
                rateAmountUnit: 'USD',
                ruleUnit:
                    this.addFreightForm.ruleType == '3' ? item.ruleUnit : null,
                // areaType: String(item.logisticsPlanScope.type),
                // type: item.logisticsPlanScope.type,
                // typeId: item.logisticsPlanScope.typeId,
            };
						
						if(item.ruleRangeMax){
							this.addFreightForm_unlimited = false
						} else {
							this.addFreightForm_unlimited = true
						}

            this.$http.get('/logistics/plan/scope/getTypeData?freightPlanId=' + item.id)
                .then((r) => {
                    this.loading = false;
                    if (r.data.code === 200) {
                        this.dialogAddFreight = true;

                        let data = r.data.data;
                        this.addFreightForm.areaType = String(data.type);

                        switch (data.type) {
                            case 1:
                                this.selectedGoodsTable.data = JSON.parse(
                                    JSON.stringify(data.shopGoods)
                                );
                                this.selectedGoodsTable.total =
                                    data.shopGoods.length;
                                break;
                            case 2:
                                this.collectionsSelection = JSON.parse(
                                    JSON.stringify(data.shopGoodsColls)
                                );
                                // for(let i = 0; i < this.collectionsSelection.length; i++){
                                // 	this.collectionsSelection[i].goodsLength = this.collectionsSelection[i].goodsList.length
                                // }
                                break;
                            case 3:
                                this.categorysSelection = JSON.parse(
                                    JSON.stringify(data.shopGoodsCategorys)
                                );
                                // for(let i = 0; i < this.categorysSelection.length; i++){
                                // 	this.categorysSelection[i].goodsLength = this.categorysSelection[i].goodsList.length
                                // }
                                break;
                            default:
                                break;
                        }
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    // this.$message.error(error);;
                });
        },
        addFreight() {
            this.$refs.addFreightForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;

                    if (this.addFreightForm.ruleType == '1')
                        this.addFreightForm.ruleUnit = 'USD';
                    else if (this.addFreightForm.ruleType == '2')
                        this.addFreightForm.ruleUnit = '件';

                    if (this.addFreightForm.supportCod)
                        this.addFreightForm.supportCod = 1;
                    else this.addFreightForm.supportCod = 0;

                    if (this.typeStatus == 1) {
                        this.addFreightForm.logisticsPlanScope = null;
                    } else {
                        this.addFreightForm.logisticsPlanScope = {
                            type: null,
                            typeId: [],
                        };

                        if (
                            this.addFreightForm.areaType == 1 &&
                            this.selectedGoodsTable.data.length == 0
                        ) {
                            this.$message.warning('请选择指定商品。');
                            this.btnLoading = false;
                            return false;
                        }

                        if (
                            this.addFreightForm.areaType == 2 &&
                            this.collectionsSelection.length == 0
                        ) {
                            this.$message.warning('请选择指定专辑。');
                            this.btnLoading = false;
                            return false;
                        }

                        if (
                            this.addFreightForm.areaType == 3 &&
                            this.categorysSelection.length == 0
                        ) {
                            this.$message.warning('请选择指定分类。');
                            this.btnLoading = false;
                            return false;
                        }

                        switch (this.addFreightForm.areaType) {
                            case '1':
                                this.addFreightForm.logisticsPlanScope.type = 1;
                                for (
                                    let i = 0;
                                    i < this.selectedGoodsTable.data.length;
                                    i++
                                ) {
                                    let item = this.selectedGoodsTable.data[i];
                                    this.addFreightForm.logisticsPlanScope.typeId.push(
                                        item.id
                                    );
                                }
                                break;
                            case '2':
                                this.addFreightForm.logisticsPlanScope.type = 2;
                                for (
                                    let i = 0;
                                    i < this.collectionsSelection.length;
                                    i++
                                ) {
                                    let item = this.collectionsSelection[i];
                                    this.addFreightForm.logisticsPlanScope.typeId.push(
                                        item.id
                                    );
                                }
                                break;
                            case '3':
                                this.addFreightForm.logisticsPlanScope.type = 3;
                                for (
                                    let i = 0;
                                    i < this.categorysSelection.length;
                                    i++
                                ) {
                                    let item = this.categorysSelection[i];
                                    this.addFreightForm.logisticsPlanScope.typeId.push(
                                        item.id
                                    );
                                }
                                break;
                            default:
                                break;
                        }
                    }

                    let url;
                    if (this.addFreightForm.id) {
                        url = '/logistics/plan/editFreightPlan';
                    } else {
                        url = '/logistics/plan/addFreightPlan';
                    }

                    this.$http
                        .post(url, this.addFreightForm)
                        .then((r) => {
                            this.btnLoading = false;

                            if (r.data.code === 200) {
                                this.dialogAddFreight = false;

                                this.$message({
                                    message: this.addFreightForm.id
                                        ? '编辑成功'
                                        : '新增成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: this.queryData(this.typeStatus),
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            this.btnLoading = false;
                        });
                }
            });
        },

        // 设置最大值的不限状态
        freight_setMax(form) {
            if (this[form + '_unlimited']) this[form].ruleRangeMax = null;
        },

        // 删除运费方案
        deleteFreight(item) {
            this.$confirm('是否删除该运费方案？', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.$http
                    .delete('/logistics/plan/deleteFreightPlan', {
                        params: {
                            id: item.id,
                        },
                    })
                    .then((r) => {
                        if (r.data.code === 200) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                                duration: 1000,
                                onClose: this.queryData(this.typeStatus),
                            });
                        }
                    });
            });
        },

        // 分配区域
        initEditCountry(item) {
            this.editCountryForm = {
                logisticsPlanId: item.id,
            };

            let checkedIds = item.countryIds; // 物流方案选中的所有国家id集合

            this.continents.forEach((el) => {
                let ids = []; // 单个洲选中的国家id集合

                el.countryList.forEach((country) => {
                    if (checkedIds.includes(country.id)) ids.push(country.id);
                });

                el.checkedIds = ids;
                el.checkAll = ids.length === el.countryList.length;
                el.isIndeterminate =
                    ids.length > 0 && ids.length < el.countryList.length;
            });

            this.dialogEditCountry = true;
        },
        editCountry() {
            this.editCountryForm.countryIds = this.continents.reduce(
                (a, b) => a.concat(b.checkedIds),
                []
            );

            this.btnLoading = true;

            this.$http
                .post('/logistics/plan/allotCountry', this.editCountryForm)
                .then((r) => {
                    this.btnLoading = false;

                    if (r.data.code === 200) {
                        this.dialogEditCountry = false;

                        this.$message({
                            message: '新增成功',
                            type: 'success',
                            duration: 1000,
                            onClose: this.queryData(this.typeStatus),
                        });
                    }
                })
                .catch((err) => {
                    // this.$message.error(error);;
                    this.btnLoading = false;
                });
        },
        // 选中洲
        checkContinent(val, item) {
            item.checkedIds = val ? item.countryList.map((v) => v.id) : [];
            item.isIndeterminate = false;
        },
        // 选中国家
        checkCountry(vals, item) {
            let checkedCount = vals.length;

            item.checkAll = checkedCount === item.countryList.length;
            item.isIndeterminate =
                checkedCount > 0 && checkedCount < item.countryList.length;
        },
        getPublished(status) {
            switch (status) {
                case 0:
                    return '否';
                case 1:
                    return '是';
            }
        },
        getCustomType(type) {
            for (let i = 0; i < this.areaOptions.length; i++) {
                if (type == this.areaOptions[i].value) {
                    return this.areaOptions[i].name;
                }
            }
        },
    },
};
</script>

<style scoped>
.main-wrap {
    height: auto;
}
.List_Logistics {
    background-color: #f5f5f5;
}
.diabox /deep/ thead .el-table-column--selection .cell {
    display: none;
}
</style>
