<template>
    <div class="main-wrap">
        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>店铺管理</el-breadcrumb-item>
            <el-breadcrumb-item>追踪像素管理</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 查询表单 -->
        <el-form class="queryForm" :inline="true" :gutter="20" :model="queryForm">
            <!-- <el-form-item>
		    <el-input style="width:80px;" v-model="queryForm.id" placeholder="ID"></el-input>
		  </el-form-item> -->
            <el-form-item>
                <el-input v-model="queryForm.pixel" placeholder="像素"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select style="width:200px;" v-model="queryForm.pixelType" filterable clearable placeholder="像素类型">
                    <el-option v-for="item in pixelOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select style="width:150px;" v-model="queryForm.pixelStatus" filterable clearable placeholder="像素状态">
                    <el-option v-for="item in pixelStatusOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="querySearch">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="success" icon="el-icon-plus" @click="openAdd">新增像素</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表 -->
        <el-table border stripe :data="tableData" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" height="1000">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="pixelName" label="像素名称" min-width="120">
            </el-table-column>
            <el-table-column prop="pixelType" label="类型" min-width="120">
                <template slot-scope="scope">
                    {{ getType(scope.row.pixelType) }}
                </template>
            </el-table-column>
            <el-table-column prop="pixel" label="像素编号" min-width="120">
            </el-table-column>
            <el-table-column label="启用状态" width="100">
                <template slot-scope="scope">
                    <el-switch active-value="1" inactive-value="0" v-model="scope.row.pixelStatus" @change="changeEnabled(scope.row, $event)"></el-switch>
                </template>
            </el-table-column>
			<el-table-column label="首选" width="100">
			    <template slot-scope="scope">
					<el-switch active-value="1" inactive-value="0" v-model="scope.row.firstChoice" @change="changeFirst(scope.row, $event)"></el-switch>
			    </template>
			</el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="100"></el-table-column>
            <el-table-column prop="updateTime" label="更新时间" width="100"></el-table-column>
            <el-table-column fixed="right" label="操作" width="150" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="editThis(scope.row.id)">编辑</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="deleteThis(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
        </el-pagination>

        <el-dialog top="3%" :title="dialogAddForm.id ? '编辑像素' : '添加像素'" :visible.sync="dialogAdd" width="500px" :close-on-click-modal="false">
            <el-form :model="dialogAddForm" :rules="dialogRules" status-icon ref="dialogAddForm" label-position="top" size="medium">
                <el-form-item label="像素类型" prop="pixelType">
                    <el-select style="width:200px;" v-model="dialogAddForm.pixelType" placeholder="请选择">
                        <el-option v-for="item in pixelOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="像素编号" prop="pixel">
                    <el-input placeholder="请输入像素编号" autocomplete="off" v-model="dialogAddForm.pixel"></el-input>
                </el-form-item>
                <el-form-item label="像素状态" prop="pixelStatus">
                    <el-select style="width:150px;" v-model="dialogAddForm.pixelStatus" placeholder="请选择">
                        <el-option v-for="item in pixelStatusOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="像素名称" prop="pixelName">
                    <el-input placeholder="请输入像素名称" autocomplete="off" v-model="dialogAddForm.pixelName"></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="addLoading" @click="addData">确定</el-button>
                <el-button @click="dialogAdd = false">取消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: 'AdPixel',
    data() {
        return {
            queryForm: {
                pixel: null,
                pixelStatus: null,
                pixelType: null,
                pageNo: 1,
                pageSize: 10,
            },
            loading: false,
            tableData: [],
            total: 0,

            dialogAdd: false,
            addLoading: false,
            dialogAddForm: {
                pixel: null,
                pixelType: null,
                pixelStatus: null,
                pixelName: null,
            },
            dialogRules: {
                pixel: [
                    {
                        required: true,
                        message: '请输入邮箱名称',
                        trigger: 'blur',
                    },
                ],
                pixelType: [
                    { required: true, message: '请选择类型', trigger: 'blur' },
                ],
                pixelStatus: [
                    { required: true, message: '请选择状态', trigger: 'blur' },
                ],
                pixelName: [
                    {
                        required: true,
                        message: '请输入像素名称',
                        trigger: 'blur',
                    },
                ],
            },

            pixelOptions: [
                { name: 'Facebook Pixel', id: 1 },
                { name: 'Tiktok Pixel', id: 2 },
                { name: 'Google Universal Analytics(UA-)', id: 3 },
                { name: 'Google Tag Manager', id: 4 },
				{ name: 'Google Ads(AW-)', id: 5 },
				{ name: 'Google Analytics 4(G-)', id: 6 },
            ],
            pixelStatusOptions: [
                { name: '禁用', id: 0 },
                { name: '启用', id: 1 },
            ],
        };
    },
    mounted() {
        this.queryData();
    },
    methods: {
        openAdd() {
            this.dialogAddForm = {
                pixel: null,
                pixelType: null,
                pixelStatus: null,
                pixelName: null,
            };
            this.addLoading = false;
            this.dialogAdd = true;
        },
        editThis(id) {
            this.loading = true;
            this.$http
                .get('/setting/pixel/getList', { params: { id: id } })
                .then((response) => {
                    if (response.data.code === 200) {
                        console.log(response.data.data[0]);
                        let data = response.data.data[0];
                        this.dialogAddForm = {
                            id: data.id,
                            pixel: data.pixel,
                            pixelType: data.pixelType,
                            pixelStatus: data.pixelStatus,
                            pixelName: data.pixelName,
                        };
                        this.addLoading = false;
                        this.dialogAdd = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addData() {
            this.$refs['dialogAddForm'].validate((valid) => {
                if (valid) {
                    this.addLoading = true;
                    let url;
                    if (this.dialogAddForm.id) {
                        url = '/setting/pixel/edit';
                    } else {
                        url = '/setting/pixel/add';
                    }
                    this.$http
                        .post(url, this.dialogAddForm)
                        .then((response) => {
                            this.addLoading = false;
                            if (response.data.code === 200) {
                                this.dialogAdd = false;
                                this.$message({
                                    message: this.dialogAddForm.id
                                        ? '编辑成功'
                                        : '添加成功',
                                    type: 'success',
                                    duration: 1000,
                                });
                                this.queryData();
                            }
                        })
                        .catch((err) => {
                            this.addLoading = false;
                        });
                }
            });
        },
        changeEnabled(scope, value) {
            console.log(scope);
            this.$confirm(`确认要${value == 0 ? '取消启用' : '启用'}吗？`, {
                type: 'warning',
            })
                .then(() => {
                    this.$http
                        .post('/setting/pixel/setPixelStatus', {
                            id: scope.id,
                            pixelStatus: value,
                        })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '设置成功',
                                    duration: 1000,
                                });
                            }
                        })
                        .catch((err) => {
                            // this.$message.error(error);;
                            scope.pixelStatus = value == 0 ? '1' : '0';
                        })
                        .finally(() => {
                            this.queryData();
                        });
                })
                .catch(() => (scope.pixelStatus = value == 0 ? '1' : '0'));
        },
		changeFirst(scope, value) {
            console.log(scope,value)
			// if(!scope.firstChoice){
			this.$confirm(`是否${value == 0 ? '不' : ''}设为首选？`, {type: 'warning',}).then(() => {
				this.$http
					.post('/setting/pixel/setFirstChoice', {
						id: scope.id,
						firstChoice: value
					})
					.then((r) => {
						if (r.data.code === 200) {
							this.$message({
								type: 'success',
								message: '设置成功',
								duration: 1000,
							});
						}
					}).catch((err) => {
						// this.$message.error(error);;
						scope.firstChoice = value == 0 ? '1' : '0';
					}).finally(() => {
						this.queryData();
					});
			}).catch(() => (
				scope.firstChoice = value == '0' ? '1' : '0'
			));
			// }
        },
        deleteThis(id) {
            this.$confirm('是否删除像素代码？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$http
                    .delete('/setting/pixel/delete?id=' + id)
                    .then((response) => {
                        if (response.data.code === 200) {
                            this.$message.success('删除成功');
                            this.queryData();
                        }
                    });
            });
        },
        querySearch() {
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        queryData() {
            this.loading = true;

            this.$http
                .get('/setting/pixel/getPage', { params: this.queryForm })
                .then((r) => {
                    this.loading = false;

                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        for (let i = 0; i < this.tableData.length; i++) {
                            let item = this.tableData[i];
                            item.pixelStatus = item.pixelStatus.toString();
							item.firstChoice = item.firstChoice ? String(item.firstChoice) : '0'
                        }
                        this.total = r.data.count;
						console.log(this.tableData)
                    }
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        getType(type) {
            for (let i = 0; i < this.pixelOptions.length; i++) {
                if (type == this.pixelOptions[i].id) {
                    return this.pixelOptions[i].name;
                }
            }
        },
    },
};
</script>

<style scoped>
</style>