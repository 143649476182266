<template>
    <div class="main-wrap">

        <!-- 导航栏 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商品管理</el-breadcrumb-item>
            <el-breadcrumb-item>商品评论</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 导航栏 end -->

        <!-- 商品和评论 -->
        <el-tabs v-model="pageType" @tab-click="changePageType">
            <el-tab-pane label="全部商品" name="1">

                <!-- 查询表单 -->
                <el-form :inline="true" :model="goodsQueryForm">
                    <el-form-item label="商品标题">
                        <el-input v-model="goodsQueryForm.goodsTitle"></el-input>
                    </el-form-item>
                    <el-form-item label="SPU">
                        <el-input v-model="goodsQueryForm.spu"></el-input>
                    </el-form-item>
                    <el-form-item label="SKU">
                        <el-input v-model="goodsQueryForm.sku"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" @click="goodsQuerySearch">查询</el-button>
                        <el-button icon="el-icon-refresh-left" @click="goodsResetQueryForm">重置</el-button>
                    </el-form-item>
                </el-form>
                <!-- 查询表单 end -->

                <!-- 数据列表 -->
                <el-table ref="goodsTableData" max-height="1000" border stripe :data="goodsTableData" v-loading="goodsLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading">
                    <el-table-column label="ID" prop="id" width="80"></el-table-column>
                    <el-table-column label="商品图片" width="180">
                        <template slot-scope="scope">
                            <GoodsImageTemplate :images="scope.row.goodsImages"></GoodsImageTemplate>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品名称" prop="goodsTitle" min-width="200"></el-table-column>
                    <el-table-column label="评论分数" min-width="180">
                        <template slot-scope="scope">
                            <el-rate class="ol_n" disabled show-score text-color="#606266" score-template="{ value }" v-model="scope.row.avgGrade"></el-rate>
                            <p style="margin-bottom: 0;">已发布/未发布: {{ scope.row.publishedNum }}/{{ scope.row.unpublishedNum }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="160">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" icon="el-icon-plus" @click="addGoodsComment( scope.row )">新增评论</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="goodsQueryForm.pageNo" :page-sizes="[10, 30, 50]" :page-size.sync="goodsQueryForm.pageSize" :total="goodsTotal" @size-change="goodsQueryData" @current-change="goodsQueryData">
                </el-pagination>
                <!-- 数据列表 end -->

            </el-tab-pane>
            <el-tab-pane label="全部评论" name="2">

                <!-- 查询表单 -->
                <el-form :inline="true" :model="queryForm">
                    <el-form-item label="商品标题">
                        <el-input v-model="queryForm.goodsTitle"></el-input>
                    </el-form-item>
                    <el-form-item label="评论内容">
                        <el-input v-model="queryForm.content"></el-input>
                    </el-form-item>
                    <el-form-item label="SPU">
                        <el-input v-model="queryForm.spu"></el-input>
                    </el-form-item>
                    <el-form-item label="评论分数">
                        <el-select v-model="queryForm.grade">
                            <el-option v-for="item of 5" :key="item" :label="item" :value="item"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="评论来源">
                        <el-select v-model="queryForm.source">
                            <el-option label="系统评论" value="0"></el-option>
                            <el-option label="顾客评论" value="1"></el-option>
                            <el-option label="自动评论" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" @click="querySearch">查询</el-button>
                        <el-button icon="el-icon-refresh-left" @click="resetQueryForm">重置</el-button>
                    </el-form-item>
                </el-form>
                <!-- 查询表单 end -->

                <!-- 数据列表 -->
                <el-table ref="tableData" max-height="1000" border stripe :data="tableData" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading">
                    <el-table-column label="ID" prop="id" width="80"></el-table-column>
                    <el-table-column label="商品图片" width="180">
                        <template slot-scope="scope">
                            <GoodsImageTemplate :images="scope.row.goodsImages"></GoodsImageTemplate>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品标题" prop="goodsTitle" min-width="200"></el-table-column>
                    <el-table-column label="spu" prop="spu" min-width="200"></el-table-column>
                    <el-table-column label="评论分数" width="160">
                        <template slot-scope="scope">
                            <el-rate class="ol_n" disabled show-score text-color="#606266" score-template="{ value }" v-model="scope.row.grade"></el-rate>
                        </template>
                    </el-table-column>
                    <el-table-column label="评论内容" prop="content" min-width="200"></el-table-column>
                    <el-table-column label="评论图片" width="180">
                        <template slot-scope="scope">
                            <el-image fit="contain" class="db_i cen" style="width: 80px; height: 80px" v-if="scope.row.images && scope.row.images.length" :src="scope.row.images[ 0 ]" :preview-src-list="scope.row.images">
                            </el-image>
                            <div v-else slot="error" class="el-image__error cen" style="width: 80px; height: 80px; font-size: 10px">无图</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="评论人" prop="commentator" min-width="120"></el-table-column>
                    <el-table-column label="国家/地区" prop="countryCnName" min-width="120"></el-table-column>
                    <el-table-column label="初始点赞数" prop="likeNum" min-width="80"></el-table-column>
                    <el-table-column label="用户点赞数" prop="userLikeNum" min-width="80"></el-table-column>
                    <el-table-column label="回复内容" prop="answer" min-width="120"></el-table-column>
                    <el-table-column label="是否发布" min-width="120">
                        <template slot-scope="scope">
                            <i class="el-icon-loading mr_1" v-if="scope.row.loadingSetStatus"></i>
                            <el-switch class="MySwitch" :active-value="1" :inactive-value="0" v-model="scope.row.isPublish" @change="setStatus( scope.row, $event )">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="评论来源" width="160">
                        <template slot-scope="scope">
                            <div v-if="scope.row.source == 0">系统评论</div>
                            <div v-else-if="scope.row.source == 1">顾客评论</div>
                            <div v-else>自动评论</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="评论日期" prop="commentTime" width="160" sortable></el-table-column>
                    <el-table-column label="操作日期" prop="updateTime" width="160" sortable></el-table-column>
                    <el-table-column label="操作" fixed="right" width="200">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" icon="el-icon-edit" @click="initEditGoodsComment( scope.row )">编辑</el-button>
                            <el-button type="text" size="mini" icon="el-icon-edit-outline" @click="initReplyGoodsComment( scope.row )">回复</el-button>
                            <el-button type="text" size="mini" icon="el-icon-delete" :loading="scope.row.loadingDeleteGoodsComment" @click="deleteGoodsComment( scope.row )">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page.sync="queryForm.pageNo" :page-sizes="[10, 30, 50]" :page-size.sync="queryForm.pageSize" :total="total" @size-change="queryData" @current-change="queryData">
                </el-pagination>
                <!-- 数据列表 end -->

            </el-tab-pane>
        </el-tabs>
        <!-- 商品和评论 end -->

        <!-- 编辑评论 -->
        <el-dialog title="编辑评论" top="3%" width="70%" :visible.sync="dialogEditGoodsComment" :close-on-click-modal="false" @closed="dialogEditGoodsCommentClosed">
            <el-form ref="editGoodsCommentForm" size="medium" label-position="top" status-icon :model="editGoodsCommentForm" :rules="editGoodsCommentRules" v-loading="loadingInitEditGoodsComment" element-loading-text="加载中" element-loading-spinner="el-icon-loading">
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item label="评论分数" prop="grade">
                            <el-rate class="ol_n" style="margin-top: 6px;" v-model="editGoodsCommentForm.grade"></el-rate>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="初始点赞数" prop="likeNum">
                            <el-input-number placeholder="请输入初始点赞数" :min="0" :precision="0" v-model="editGoodsCommentForm.likeNum"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="评论日期" prop="commentTime">
                            <el-date-picker type="datetime" placeholder="请选择评论日期" value-format="yyyy-MM-dd HH:mm:ss" v-model="editGoodsCommentForm.commentTime"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="国家/地区" prop="countryId">
                            <el-select v-model="editGoodsCommentForm.countryId">
                                <el-option v-for="item in countryData" :key="item.id" :label="item.cnName" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="15">
                        <el-form-item label="评论内容" prop="content">
                            <el-input type="textarea" maxlength="200" show-word-limit placeholder="请输入评论内容" :autosize="{ minRows: 6, maxRows: 12 }" v-model="editGoodsCommentForm.content">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9">
                        <el-form-item label="评论人" prop="commentator">
                            <el-input maxlength="20" show-word-limit placeholder="请输入评论人" v-model="editGoodsCommentForm.commentator"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="评论图片">
                    <main class="df fw_w ai_c">
                        <template v-if="editGoodsCommentForm.images && editGoodsCommentForm.images.length > 0">
                            <section class="ImageBox" v-for="( item, index ) of editGoodsCommentForm.images" :key="index">
                                <el-image fit="contain" class="Image" :src="item" :preview-src-list="editGoodsCommentForm.images">
                                    <div slot="error" class="el-image__error"><i class="el-icon-picture-outline"></i></div>
                                </el-image>
                                <div class="mask_d5"></div>
                                <el-button type="danger" icon="el-icon-delete" circle class="ImageDeleteButton" @click="editGoodsCommentForm.images.splice( index, 1 )"></el-button>
                            </section>
                        </template>
                        <i class="el-icon-plus img-uploader-icon ImageAddButton" @click="openImageUploader( 'editGoodsCommentForm.images' )"></i>
                    </main>
                </el-form-item>

                <el-form-item label="评论视频">
                    <main class="df fw_w ai_c">
                        <template v-if="editGoodsCommentForm.videos && editGoodsCommentForm.videos.length > 0">
                            <section class="ImageBox" v-for="( item, index ) of editGoodsCommentForm.videos" :key="index">
                                <div class="centergrid" @click="showVideo(item)">
                                    <video width="100%" autoplay loop>
                                        <source :src="item">
                                    </video>
                                </div>
                                <div class="mask_d5"></div>
                                <el-button type="danger" icon="el-icon-delete" circle class="ImageDeleteButton" @click="delVideo(index)"></el-button>
                            </section>
                        </template>
                        <i class="el-icon-plus img-uploader-icon ImageAddButton" @click="openVideoUploader( 'editGoodsCommentForm.videos' )"></i>
                    </main>
                </el-form-item>

                <main>
                    <h3>商品信息</h3>
                    <el-form-item label="Overall Fit">
                        <el-radio-group v-model="editGoodsCommentForm.overallFit">
                            <el-radio :label="2">True to Size</el-radio>
                            <el-radio :label="1">Small</el-radio>
                            <el-radio :label="3">Large</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="商品款式" v-if="editGoodsCommentForm_property.length > 0">
                        <el-popover placement="top-end" effect="light" df class="ol_n">
                            <main>
                                <div class="dg jc_s ai_c mb_2" style="grid-template-columns: auto auto; gap: 30px" v-for="( row, index ) of editGoodsCommentForm_property" :key="index">
                                    <span>{{ row.key }}</span>
                                    <el-radio-group v-model="row.value" @change="changeProperty">
                                        <el-radio v-for="( item, index2 ) of row.values" :key="index2" :label="item">{{ item }}</el-radio>
                                    </el-radio-group>
                                </div>
                            </main>
                            <el-input slot="reference" disabled style="width: 500px;" v-model="editGoodsCommentForm.goodsOption">
                                <el-button slot="append" type="primary">选择款式</el-button>
                            </el-input>
                        </el-popover>
                        <el-button class="ml_2_i" @click="clearProperty">清除</el-button>
                    </el-form-item>
                    <div class="dg ai_s" style="grid-template-columns: repeat( 5, 1fr ); gap: 50px">
                        <el-form-item label="臀围（Hips）">
                            <el-input maxlength="20" v-model="editGoodsCommentForm.hips"></el-input>
                        </el-form-item>
                        <el-form-item label="身高（Height）">
                            <el-input maxlength="20" v-model="editGoodsCommentForm.height"></el-input>
                        </el-form-item>
                        <el-form-item label="腰围（Waist）">
                            <el-input maxlength="20" v-model="editGoodsCommentForm.waist"></el-input>
                        </el-form-item>
                    </div>
                    <div class="dg ai_s" style="grid-template-columns: repeat( 5, 1fr ); gap: 50px">
                        <el-form-item label="体重（Weight）">
                            <el-input maxlength="20" v-model="editGoodsCommentForm.weight"></el-input>
                        </el-form-item>
                        <el-form-item label="胸围（Bust）">
                            <el-input maxlength="20" v-model="editGoodsCommentForm.bust"></el-input>
                        </el-form-item>
                        <el-form-item label="罩杯（Cup）">
                            <el-input maxlength="20" v-model="editGoodsCommentForm.cup"></el-input>
                        </el-form-item>
                        <p style="margin-top: 10px; color: #C0C4CC;">单位：cm、kg</p>
                    </div>
                </main>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="loadingEditGoodsComment" @click="editGoodsComment">确定</el-button>
                <el-button @click="dialogEditGoodsComment = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 编辑评论 end -->

        <!-- 回复评论 -->
        <el-dialog title="回复评论" top="3%" width="400px" :visible.sync="dialogReplyGoodsComment" :close-on-click-modal="false" @closed="dialogReplyGoodsCommentClosed">
            <el-form ref="replyGoodsCommentForm" size="medium" label-position="top" status-icon :model="replyGoodsCommentForm" :rules="replyGoodsCommentRules">
                <el-form-item label="回复内容" prop="answer">
                    <el-input type="textarea" maxlength="200" show-word-limit placeholder="请输入回复内容" :autosize="{ minRows: 6, maxRows: 12 }" v-model="replyGoodsCommentForm.answer">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="loadingReplyGoodsComment" @click="replyGoodsComment">确定</el-button>
                <el-button @click="dialogReplyGoodsComment = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 回复评论 end -->

        <!-- 新增评论 -->
        <AddGoodsComment ref="addGoodsComment" @success="addGoodsCommentSuccess"></AddGoodsComment>
        <!-- 新增评论 end -->

        <!-- 插入图片 -->
        <ImageUploader ref="uploadImage" :insertImage="dialogInsertImage" :insertType="insertType" :activeName="activeName" @getOpenState="getOpenState" @getImage="getImage">
        </ImageUploader>
        <!-- 插入图片 end -->

        <!-- 插入图片 -->
        <VideoUploader ref="uploadVideo" :insertVideo="dialogInsertVideo" :insertVideoType="insertVideoType" :activeVideo="activeVideo" @getOpenVideo="getOpenVideo" @getVideo="getVideo">
        </VideoUploader>

        <el-dialog top="3%" :visible.sync="dialogVisibleVideo">
            <video width="100%" controls autoplay loop :src="dialogVideoUrl">
            </video>
        </el-dialog>
        <!-- 插入图片 end -->

    </div>
</template>

<script>
import _ from 'lodash';
import ImageUploader from '@/components/ImageUploader';
import VideoUploader from '@/components/VideoUploader';
import AddGoodsComment from '@/views/goods/AddGoodsComment';
import GoodsImageTemplate from '@/components/GoodsImageTemplate';

export default {
    name: 'GoodsComment',

    components: {
        ImageUploader,
        AddGoodsComment,
        VideoUploader,
        GoodsImageTemplate,
    },

    data() {
        return {
            countryData: [], // 国家数据

            /* 插入图片参数 */
            dialogInsertImage: false,
            activeName: 'first',
            insertType: null,
            /* 插入图片参数 end */

            dialogInsertVideo: false,
            activeVideo: 'first',
            insertVideoType: null,
            dialogVisibleVideo: false,
            dialogVideoUrl: null,

            pageType: '2', // 页面类型

            /* 列表查询参数 */
            queryForm: {
                goodsTitle: null,
                content: null,
                grade: null,
                source: null,
                pageNo: 1,
                pageSize: 10,
                spu: null,
            },
            /* 列表查询参数 end */

            /* 数据列表 */
            loading: false,
            tableData: [],
            total: 0,
            /* 数据列表 end */

            /* 编辑评论 */
            dialogEditGoodsComment: false,
            loadingInitEditGoodsComment: false,
            loadingEditGoodsComment: false,
            editGoodsCommentForm_property: [], // 商品款式
            editGoodsCommentForm: {
                id: null,
                grade: undefined,
                likeNum: undefined,
                commentTime: null,
                countryId: null,
                content: null,
                commentator: null,
                images: null,
                overallFit: null,
                goodsOption: null,
                hips: undefined,
                height: undefined,
                waist: undefined,
                weight: undefined,
                bust: null,
                cup: null,
            },
            editGoodsCommentRules: {
                grade: [
                    {
                        required: true,
                        message: '请选择评论分数',
                        trigger: 'change',
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (value === 0) callback('请选择评论分数');
                            else callback();
                        },

                        trigger: 'change',
                    },
                ],
                likeNum: {
                    required: true,
                    message: '请输入初始点赞数',
                    trigger: 'blur',
                },
                commentTime: {
                    required: true,
                    message: '请选择评论日期',
                    trigger: 'change',
                },
                countryId: {
                    required: true,
                    message: '请选择国家/地区',
                    trigger: 'change',
                },
                content: {
                    required: true,
                    message: '请输入评论内容',
                    trigger: 'blur',
                },
                commentator: {
                    required: true,
                    message: '请输入评论人',
                    trigger: 'blur',
                },
                overallFit: {
                    required: true,
                    message: '请选择Overall Fit',
                    trigger: 'change',
                },
            },
            /* 编辑评论 end */

            /* 回复评论 */
            dialogReplyGoodsComment: false,
            loadingReplyGoodsComment: false,
            replyGoodsCommentForm: {
                id: null,
                answer: null,
            },
            replyGoodsCommentRules: {
                answer: {
                    required: true,
                    message: '回复内容不能为空，请输入',
                    trigger: 'blur',
                },
            },
            /* 回复评论 end */

            /* 全部商品 - 列表查询参数 */
            goodsQueryForm: {
                goodsTitle: null,
                spu: null,
                sku: null,
                isComment: 1,
                pageNo: 1,
                pageSize: 10,
            },
            /* 全部商品 - 列表查询参数 end */

            /* 全部商品 - 数据列表 */
            goodsLoading: false,
            goodsTableData: [],
            goodsTotal: 0,
            /* 全部商品 - 数据列表 end */
        };
    },

    mounted() {
        console.log('---------- cache flag: 1 ----------');
        this.$getData('countryData', '/common/country/getList'); // 获取国家数据
        this.queryData(); // 获取列表数据
    },

    methods: {
        showVideo(url) {
            this.dialogVideoUrl = url;
            this.dialogVisibleVideo = true;
        },
        delVideo(index) {
            console.log(index);
            this.editGoodsCommentForm.videos.splice(index, 1);
            console.log(this.editGoodsCommentForm.videos);
            this.$forceUpdate();
        },
        /* 插入图片方法 */
        openImageUploader(obj) {
            this.insertType = obj;
            this.dialogInsertImage = true;
            this.$refs.uploadImage.openInsert();
        },
        /* 插入视频方法 */
        openVideoUploader(obj) {
            this.insertVideoType = obj;
            this.dialogInsertVideo = true;
            this.$refs.uploadVideo.openInsert();
        },
        getOpenState(result) {
            this.dialogInsertImage = result;
        },
        getOpenVideo(result) {
            this.dialogInsertVideo = result;
        },
        getImage(obj, image) {
            switch (obj) {
                case 'editGoodsCommentForm.images':
                    if (!this.editGoodsCommentForm.images)
                        this.editGoodsCommentForm.images = [];
                    this.editGoodsCommentForm.images.push(...image);
                    break;
            }
        },
        getVideo(obj, video) {
            switch (obj) {
                case 'editGoodsCommentForm.videos':
                    if (!this.editGoodsCommentForm.videos)
                        this.editGoodsCommentForm.videos = [];
                    this.editGoodsCommentForm.videos.push(...video);
                    break;
            }
        },
        /* 插入图片方法 end */

        /* 切换页面类型 */
        changePageType() {
            if (this.pageType == '2') this.querySearch();
            else this.goodsQuerySearch();
        },
        /* 切换页面 end */

        /* 查询表单 */
        querySearch() {
            this.queryForm.pageNo = 1;
            this.queryData();
        },
        /* 查询表单 end */

        /* 重置查询表单 */
        resetQueryForm() {
            this.queryForm.goodsTitle = null;
            this.queryForm.content = null;
            this.queryForm.grade = null;
            this.queryForm.spu = null;
            this.queryForm.source = null;
            this.querySearch();
        },
        /* 重置查询表单 end */

        /* 获取列表数据 */
        queryData() {
            this.loading = true;

            this.$http
                .get('/goods/comment/getPage', { params: this.queryForm })
                .then((r) => {
                    this.loading = false;

                    if (r.data.code === 200) {
                        this.tableData = r.data.data;
                        this.total = r.data.count;

                        this.$refs.tableData.doLayout();
                    }
                });
        },
        /* 获取列表数据 */

        /* 设置是否发布 */
        setStatus(row, value) {
            this.$confirm(`确认要${value == 0 ? '取消' : ''}发布吗？`, {
                type: 'warning',
            })
                .then(() => {
                    row.loadingSetStatus = true;
                    row.isPublished = value;

                    this.$http
                        .post('/goods/comment/publish', {
                            id: row.id,
                            isPublish: value,
                        })
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '操作成功',
                                    duration: 1000,
                                });
                            }
                        })
                        .catch(() => {
                            row.isPublish = +!value;
                            row.isPublished = value == 0 ? 1 : 0;
                        })
                        .finally(() => (row.loadingSetStatus = false));
                })
                .catch(() => {
                    row.isPublish = +!value;
                    row.isPublished = value == 0 ? 1 : 0;
                });
        },
        /* 设置是否发布 end */

        /* 改变商品款式 */
        changeProperty() {
            this.editGoodsCommentForm.goodsOption =
                this.editGoodsCommentForm_property
                    .filter((v) => v.value != undefined)
                    .map((item) => `${item.key}: ${item.value}`)
                    .join(' ');
        },
        /* 改变商品款式 end */

        /* 清除商品款式 */
        clearProperty() {
            this.editGoodsCommentForm.goodsOption = null;
            this.editGoodsCommentForm_property.forEach(
                (item) => (item.value = undefined)
            );
        },
        /* 清除商品款式 end */

        /* 编辑评论 */
        initEditGoodsComment(row) {
            this.loadingInitEditGoodsComment = true;

            this.$http
                .get('/goods/comment/getDetail?id=' + row.id)
                .then((r) => {
                    if (r.data.code === 200) {
                        this.editGoodsCommentForm.id = r.data.data.id;
                        this.editGoodsCommentForm.grade = r.data.data.grade;
                        this.editGoodsCommentForm.likeNum = r.data.data.likeNum;
                        this.editGoodsCommentForm.commentTime =
                            r.data.data.commentTime;
                        this.editGoodsCommentForm.countryId =
                            r.data.data.countryId;
                        this.editGoodsCommentForm.content = r.data.data.content;
                        this.editGoodsCommentForm.commentator =
                            r.data.data.commentator;
                        this.editGoodsCommentForm.images = r.data.data.images;
                        this.editGoodsCommentForm.videos = r.data.data.videos;
                        this.editGoodsCommentForm.overallFit =
                            r.data.data.overallFit;
                        this.editGoodsCommentForm.goodsOption =
                            r.data.data.goodsOption;
                        this.editGoodsCommentForm.hips = r.data.data.hips;
                        this.editGoodsCommentForm.height = r.data.data.height;
                        this.editGoodsCommentForm.waist = r.data.data.waist;
                        this.editGoodsCommentForm.weight = r.data.data.weight;
                        this.editGoodsCommentForm.bust = r.data.data.bust;
                        this.editGoodsCommentForm.cup = r.data.data.cup;
                    }
                })
                .finally(() => (this.loadingInitEditGoodsComment = false));

            // 商品款式
            this.$getData(
                null,
                '/goods/type/getGoodsType?goodsId=' + row.goodsId
            ).then((d) => {
                this.editGoodsCommentForm_property = d.map((item) => {
                    let newItem = {
                        key: item.typeName,
                        value: undefined,
                        values: item.typeValues.map((v) => v.valueText),
                    };

                    return newItem;
                });
            });

            this.dialogEditGoodsComment = true;
        },
        editGoodsComment() {
            this.$refs.editGoodsCommentForm.validate((valid) => {
                if (valid) {
                    this.loadingEditGoodsComment = true;
                    if (this.editGoodsCommentForm.goodsOption == null)
                        this.editGoodsCommentForm.goodsOption = '';

                    this.$http
                        .post('/goods/comment/edit', this.editGoodsCommentForm)
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.dialogEditGoodsComment = false;

                                this.$message({
                                    message: '编辑成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: this.queryData,
                                });
                            }
                        })
                        .finally(() => (this.loadingEditGoodsComment = false));
                }
            });
        },
        dialogEditGoodsCommentClosed() {
            this.loadingEditGoodsComment = false;
            this.$refs.editGoodsCommentForm.resetFields();

            this.editGoodsCommentForm = {
                id: null,
                grade: undefined,
                likeNum: undefined,
                commentTime: null,
                countryId: null,
                content: null,
                commentator: null,
                images: null,
                overallFit: null,
                goodsOption: null,
                hips: undefined,
                height: undefined,
                waist: undefined,
                weight: undefined,
                bust: null,
                cup: null,
            };

            this.editGoodsCommentForm_property = []; // 商品款式
        },
        /* 编辑评论 end */

        /* 回复评论 */
        initReplyGoodsComment(row) {
            this.replyGoodsCommentForm.id = row.id;
            this.replyGoodsCommentForm.answer = row.answer;
            this.dialogReplyGoodsComment = true;
        },
        replyGoodsComment() {
            this.$refs.replyGoodsCommentForm.validate((valid) => {
                if (valid) {
                    this.loadingReplyGoodsComment = true;

                    this.$http
                        .post(
                            '/goods/comment/answer',
                            this.replyGoodsCommentForm
                        )
                        .then((r) => {
                            if (r.data.code === 200) {
                                this.dialogReplyGoodsComment = false;

                                this.$message({
                                    message: '回复成功',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: this.queryData,
                                });
                            }
                        })
                        .catch(() => (this.loadingReplyGoodsComment = false));
                }
            });
        },
        dialogReplyGoodsCommentClosed() {
            this.loadingReplyGoodsComment = false;
            this.$refs.replyGoodsCommentForm.resetFields();

            this.replyGoodsCommentForm.id = null;
            this.replyGoodsCommentForm.answer = null;
        },
        /* 回复评论 end */

        /* 删除评论 */
        deleteGoodsComment(row) {
            this.$confirm('是否删除该评论？', { type: 'warning' }).then(() => {
                row.loadingDeleteGoodsComment = true;

                this.$http
                    .delete('/goods/comment/delete', { params: { id: row.id } })
                    .then((r) => {
                        if (r.data.code === 200) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                                duration: 1000,
                                onClose: this.queryData,
                            });
                        }
                    })
                    .finally(() => (row.loadingDeleteGoodsComment = false));
            });
        },
        /* 删除评论 end */

        /* 全部商品 - 查询表单 */
        goodsQuerySearch() {
            this.goodsQueryForm.pageNo = 1;
            this.goodsQueryData();
        },
        /* 全部商品 - 查询表单 end */

        /* 全部商品 - 重置查询表单 */
        goodsResetQueryForm() {
            this.goodsQueryForm.goodsTitle = null;
            this.goodsQueryForm.spu = null;
            this.goodsQueryForm.sku = null;
            this.goodsQuerySearch();
        },
        /* 全部商品 - 重置查询表单 end */

        /* 全部商品 - 获取列表数据 */
        goodsQueryData() {
            this.goodsLoading = true;

            this.$http
                .get('/goods/getPage', { params: this.goodsQueryForm })
                .then((r) => {
                    this.goodsLoading = false;

                    if (r.data.code === 200) {
                        this.goodsTableData = r.data.data;
                        this.goodsTotal = r.data.count;

                        this.$refs.goodsTableData.doLayout();
                    }
                });
        },
        /* 全部商品 - 获取列表数据 */

        /* 新增评论 */
        addGoodsComment(row) {
            this.$refs.addGoodsComment.init(row.id);
        },
        /* 新增评论 end */

        /* 新增评论成功 */
        addGoodsCommentSuccess() {
            this.goodsQueryData();
        },
        /* 新增评论成功 end */
    },
};
</script>

<style scoped>
.main-wrap {
    height: auto;
}
.centergrid {
    max-width: 100%;
    max-height: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

/* 评论图片 */
.ImageAddButton {
    margin-bottom: 20px;
    box-sizing: border-box;
    border: dotted 1px #aaa;
    border-radius: 4px;
    width: 120px;
    height: 120px;
    cursor: pointer;
}

.ImageBox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 120px;
    height: 120px;
}

.Image {
    max-width: 100%;
    max-height: 100%;
}

.ImageBox > .mask_d5 {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    pointer-events: none;
}

.ImageDeleteButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: none;
}

.ImageBox:hover > .mask_d5,
.ImageBox:hover > .ImageDeleteButton {
    display: block;
}
/* 评论图片 end */
</style>